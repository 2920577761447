import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor() { 
    window.scrollTo(0,0);

  }

  ngOnInit() {
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if(footer.style.display=="none"){
        footer.style.display = "block";
      }
    }, 1000);
  }

}
