import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { WorkflowStageService } from 'src/app/Shared/WorkflowStage/workflow-stage.service';

@Component({
  selector: 'app-work-flow-stage',
  templateUrl: './work-flow-stage.component.html',
  styleUrls: ['./work-flow-stage.component.css']
})
export class WorkFlowStageComponent implements OnInit {

  Workflows: any;
  wid:any;
  workfolwtype: string;
  roleDesc: any;
  rolid: number;
  showSave:any;
  showUpdate:any;
  p:number = 1
  public dismissalert = false;
  notify: string
  createdby: any;
  createdon: any;
  GUID: string;
  workfolwstage: string;
  deleteid: any;
  constructor(private workflowstageservice: WorkflowStageService,private spinner: NgxSpinnerService,
    private auditortransactionservice:AuditorTransactionService
    ) {
    this.GUID = localStorage.getItem("GUID");

   }

 
  ngOnInit() {
    this.GetWorkflowstages();
    this.workfolwtype = "";
    this.workfolwstage = "";
    this.roleDesc = "";
  }
  Updateworkflow(form: NgForm) {
    this.spinner.show();
    const inputRequest1= {
      WorkflowMasterId: this.wid ,
      WorkflowName: form.controls["workfolwtype"].value,
      WorkflowDescription: form.controls["workfolwstage"].value,
      HostId:"",
      CreatedBy:  this.createdby,
      CreatedOn:  this.createdon,
      GUID:this.GUID 

    }
    this.workflowstageservice.UpdateworkflowStageRow(inputRequest1).subscribe(
      (data: any) => {
          if (data != null) {
            this.notify = "Workflow updated successfully"
            const audit = {
              TransactionName:"Master",
              TransactionType:"Update",
              TransactionDesc:"Workflow Stage master",
  
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit); 
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetWorkflowstages();
            this.spinner.hide();
        
        }
        else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflowstages();
          this.spinner.hide();
        }
      }
    );
  }
  SaveworkflowD(form: NgForm) {
    this.spinner.show();
    const inputRequest = {
      WorkflowMasterId: 0,
      WorkflowName: form.controls["workfolwtype"].value,
      WorkflowDescription: form.controls["workfolwstage"].value,
      HostId:"",
      CreatedBy:"",
      CreatedOn:"",
      GUID:this.GUID 
 
    }
    this.workflowstageservice.saveworkflowstage(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Workflow saved successfully"
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Workflow Stage master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflowstages();
          this.spinner.hide();
         
        }
        else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflowstages();
          this.spinner.hide();
        }
      }
    );
  }
  GetWorkflowstages() {
    this.spinner.show();
    this.workflowstageservice.getworkflowstages(this.GUID ).subscribe((response: any) => {
      this.Workflows = response;
      this.spinner.hide();
    });
  }
  editRole(data:any){
    this.showSave = false;
    this.showUpdate = true;
    this.wid = data.id;
    this.workfolwtype = data.workflowType;
    this.workfolwstage = data.workflowStage;
    this.createdby=data.createdBy;
    this.createdon=data.createdOn;
  }
  addClick(){
    this.showSave = true;
    this.showUpdate = false;
    if (this.workfolwstage != "") {
      this.workfolwstage = "";
    }
    if (this.workfolwtype != "") {
      this.workfolwtype = "";
    }
  }
  deleteWorkflows(data:any){
    this.deleteid = data.id;
  }
  deleteWorkflowsRow(){  
    this.spinner.show();
      this.workflowstageservice.deleteWorkflowstagedata(this.deleteid).subscribe(
        (data: any) => {
          if (data != null) {
            this.notify = "Workflow deleted successfully"
            const audit = {
              TransactionName:"Master",
              TransactionType:"Delete",
              TransactionDesc:"Workflow Stage master",
  
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetWorkflowstages();
            this.spinner.hide();
           
          }else{
            this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflowstages();
          this.spinner.hide();
          } 
        });
    }
}
