<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<app-user-sidebar *ngIf="(roleid == '2' || roleid == '9') && mobileview"></app-user-sidebar>

<div class="ng-scope register">
  <div class="container">
    <div class="card">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <div class="panel-heading">
            <i class="icon-comments"></i>
            <div class="panel panel-primary" style="background: linear-gradient(270deg, #7100aa, #000)">
              <br />
              <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <h3 style="color: white">Project Registration</h3>
                </div>
              </div>
              <br />
            </div>
            <br /><br />
            <div class="col-md-12 row">
              <div class="col-md-9"></div>
              <div class="col-md-3">
                <button class="btn btn-dark" pageScroll href="#showhidevalue" (click)="viewallprojects()">
                  View All Projects
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br /><br />
        <form #projectform="ngForm">
          <div class="panel-body">
            <div class="form-group">
              <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4" style="font-weight: 500">
                  Project
                  <input type="text" id="projectname" [(ngModel)]="projectname" class="form-control"
                    name="projectname" />
                </div>
                <div>&nbsp;</div>
                <div class="col-md-4" style="font-weight: 500">
                  Project Description
                  <input type="text" id="projectdesc" [(ngModel)]="projectdesc" class="form-control"
                    name="projectdesc" />
                </div>
              </div>
            </div>
            <div>&nbsp;</div>

            <div class="form-group">
              <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4" style="font-weight: 500">
                  Purpose of Project
                  <input type="text" id="purpose" [(ngModel)]="purpose" class="form-control" name="purpose" />
                </div>
                <div>&nbsp;</div>
                <div class="col-md-4" style="font-weight: 500">
                  Priority
                  <select (change)="onSelectPriority($event.target.value)" class="form-control dropdownsize">
                    <option value="">Select Priority</option>
                    <option *ngFor="let do of prioritylist" value="{{ do.PriorityId }}">
                      {{ do.PriorityName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div>&nbsp;</div>

            <div class="form-group">
              <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4" style="font-weight: 500">
                  Users
                  <select (change)="onSelectUser($event.target.value)" class="form-control dropdownsize">
                    <option value="">Select Lead</option>
                    <option *ngFor="let do of UserList" value="{{ do.guid }}">
                      {{ do.userName }}
                    </option>
                  </select>
                </div>
                <div>&nbsp;</div>
                <div class="col-md-4" style="font-weight: 500">
                  Project Code
                  <input type="text" id="projectcode" [(ngModel)]="projectcode" class="form-control"
                    name="projectcode" />
                </div>
              </div>
            </div>
            <div>&nbsp;</div>

            <div class="form-group">
              <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4" style="font-weight: 500">
                  Start Date
                  <input type="date" id="startdate" [(ngModel)]="startdate" class="form-control" name="startdate" />
                </div>
                <div>&nbsp;</div>
                <div class="col-md-4" style="font-weight: 500">
                  Objectives
                  <input type="text" id="objectives" [(ngModel)]="objectives" class="form-control" name="objectives" />
                </div>
              </div>
            </div>
            <div>&nbsp;</div>

            <div class="form-group">
              <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4" style="font-weight: 500">
                  Estimated Time(in Hrs)
                  <input type="text" id="estimatedtime" [(ngModel)]="estimatedtime" class="form-control"
                    name="estimatedtime" />
                </div>
                <div>&nbsp;</div>
                <div class="col-md-4" style="font-weight: 500">
                  Estimated Cost(in Rs)
                  <input type="text" id="estimatedcost" [(ngModel)]="estimatedcost" class="form-control"
                    name="estimatedcost" />
                </div>
              </div>
            </div>
            <div>&nbsp;</div>

            <div class="form-group">
              <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4" style="font-weight: 500">
                  Client
                  <select (change)="onSelectClient($event.target.value)" class="form-control dropdownsize">
                    <option value="">Select Client</option>
                    <option *ngFor="let do of ClientList" value="{{ do.id }}">
                      {{ do.clientName }}
                    </option>
                  </select>
                </div>
                <div>&nbsp;</div>
                <div class="col-md-4" style="font-weight: 500">
                  Upload Object<br /><br />
                  <label class="dropdown-item">
                    <b for="excel-upload" style="color: #0000ff; cursor: pointer; font-size: 20px"><i
                        class="fas fa-file"></i>&nbsp;&nbsp;Upload Objects</b>
                    <input #fileInput (click)="fileInput.value = null" type="file" id="excel-upload" multiple="true"
                      style="display: none" (change)="onChangeUploadDoc($event)" />
                  </label>
                </div>
              </div>
            </div>
            <br /><br />
            <div class="col-md-12 row">
              <div class="col-md-6">&nbsp;</div>
              <div class="col-md-4">
                <button class="btn btn-success" (click)="SaveProject(projectform)">
                  <span class="glyphicon glyphicon-cloud-upload"></span>Save
                </button>
              </div>
            </div>
            <br /><br />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<br /><br />
<br />
<div class="col-md-12 row" id="showhidevalue">
  <div class="col-md-12" *ngIf="showhidevalue">
    <div class="col-md-12 row">
      <div class="col-md-4"></div>
      <div class="col-md-5">
        <h3 style="text-align: center">Projects List</h3>
      </div>
    </div>
    <br /><br />
    <br />
    <div class="col-md-12 row">
      <div class="col-md-1"></div>
      <br /><br />
      <div class="col-md-10">
        <table class="table table-responsive" style="border-collapse: collapse">
          <tr class="thead-dark" style="font-size: 17px">
            <th class="text-center">SI No</th>
            <th class="text-center">Name</th>
            <th class="text-center">Description</th>
            <th class="text-center">Purpose</th>
            <th class="text-center">Priority</th>
            <th class="text-center">Project Code</th>
            <th class="text-center">Lead</th>
            <th class="text-center">Start Date</th>
            <th class="text-center">Objectives</th>
            <th class="text-center">Estimsted Time(in Hrs)</th>
            <th class="text-center">Estimated Cost(in Rs)</th>
            <th class="text-center">Client</th>
            <th>View Documents</th>
            <th>Track</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
          <tr *ngFor="
              let aa of ProjectList
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro">
            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
            <td>{{ aa.projectName }}</td>
            <td>{{ aa.projectDescription }}</td>
            <td>{{ aa.purposeOfProject }}</td>
            <td>{{ aa.priority }}</td>
            <td>{{ aa.projectCode }}</td>
            <td>{{ GetUser(aa.projectLead) }}</td>
            <td>{{ aa.startDate | date: "short" }}</td>
            <td>{{ aa.objectives }}</td>
            <td>{{ aa.estimstedTime }}</td>
            <td>{{ aa.estimatedCost }}</td>
            <td>{{ GetClient(aa.client) }}</td>
            <td>
              <button class="btn btn-info" pageScroll href="#showfiles" (click)="ViewProjectFiles(aa)">
                View Objects
              </button>
            </td>
            <td>
              <button class="btn btn-primary" (click)="Track(aa)">Track</button>
            </td>
            <td>
              <button class="btn btn-success" (click)="EditProject(aa, updateproject)">
                Edit
              </button>
            </td>
            <td>
              <button class="btn btn-danger" (click)="deleteProject(aa, deleteproject)">
                Delete
              </button>
            </td>
          </tr>
        </table>
        <br />
        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
        <br /><br />
        <br /><br />
      </div>
    </div>
  </div>
</div>
<br />

<div class="col-md-12 row" style="margin-left: 30px" *ngIf="ViewDoc">
  <div class="col-md-12 row">
    <div class="col-md-4"></div>
    <div class="col-md-6">
      <h3>Documents For Reference</h3>
      <br /><br />
      <table class="table table-responsive" id="showfiles">
        <tr class="thead-dark">
          <th style="display: none">ID</th>
          <th>Document</th>
          <th>Download</th>
        </tr>
        <tr *ngFor="let ret of ProjectFileList; let i = index">
          <td>
            <div *ngIf="
                ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png' ||
                ret.documentType == 'image/jpg'
              ">
              <img [src]="ret.file" style="
                  width: 50px;
                  height: 50px;
                  margin-left: 15px;
                  cursor: pointer;
                " (click)="gotopdfViewForAssignTask(ret)" />
              <span style="cursor: pointer">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </div>
            <div *ngIf="
                ret.documentType == 'application/pdf' ||
                ret.documentType == 'pdf'
              ">
              <img src="./assets/img/pdficon.png" style="
                  width: 35px;
                  height: 34px;
                  cursor: pointer;
                  margin-left: 15px;
                " (click)="gotopdfViewForAssignTask(ret)" />
              <span style="cursor: pointer">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </div>
            <div *ngIf="
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx' ||
                ret.documentType == 'xls' ||
                ret.documentType == 'application/vnd.ms-excel'
              " (click)="gotopdfViewForAssignTask(ret)">
              <img src="./assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer" />
              <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
            </div>
            <div *ngIf="
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx'
              " (click)="gotopdfViewForAssignTask(ret)">
              <img src="./assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer" />
              <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
            </div>

            <div *ngIf="
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'docx'
              " (click)="gotopdfViewForAssignTask(ret)">
              <img src="./assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer" />
              <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
            </div>
            <div *ngIf="ret.documentType == 'text/plain'">
              <img src="./assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer"
                (click)="gotopdfViewForAssignTask(ret)" />

              <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
            </div>
          </td>
          <td>
            <a (click)="DownloadFile(ret)" download><button class="btn btn-primary">
                <i class="fa fa-download"></i>Download
              </button></a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<br />
<ng-template #updateproject let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Project</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">Project Name:</h5>
    <input type="text" id="editprojectname" [(ngModel)]="editprojectname" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Project Description:</h5>
    <input type="text" id="editprojectdesc" [(ngModel)]="editprojectdesc" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Purpose of Project:</h5>
    <input type="text" id="editpurpose" [(ngModel)]="editpurpose" class="form-control" name="dp" /><br />
    <select (change)="onSelectPriority($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Priority</option>
      <option *ngFor="let do of prioritylist" value="{{ do.PriorityId }}" [selected]="do.PriorityId == editpriority">
        {{ do.PriorityName }}
      </option>
    </select>

    <h5 class="modal-title" id="modal-basic-title">Project Code:</h5>
    <input type="text" id="editprojectcode" [(ngModel)]="editprojectcode" class="form-control" name="dp" />
    <br /><select (change)="onSelectUser($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Lead</option>
      <option *ngFor="let do of UserList" value="{{ do.guid }}" [selected]="do.guid == edituserid">
        {{ do.userName }}
      </option>
    </select>
    <h5 class="modal-title" id="modal-basic-title">Start Date:</h5>
    <input type="date" id="converteddate" [(ngModel)]="converteddate" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Objectives:</h5>
    <input type="text" id="editobjectives" [(ngModel)]="editobjectives" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Estimated Time(in Hrs):</h5>
    <input type="text" id="editestimatedtime" [(ngModel)]="editestimatedtime" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Estimated Cost(in Rs):</h5>
    <input type="text" id="editestimatedcost" [(ngModel)]="editestimatedcost" class="form-control" name="dp" /><br />
    <select (change)="onSelectClient($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Client</option>
      <option *ngFor="let do of ClientList" value="{{ do.id }}" [selected]="do.id == editclentid">
        {{ do.clientName }}
      </option>
    </select><br />
    <div class="col-md-5">
      <label class="dropdown-item">
        <b for="excel-upload" style="color: #0000ff; cursor: pointer; font-size: 20px"><i
            class="fas fa-file"></i>&nbsp;&nbsp;Upload Objects</b>
        <input #fileInput (click)="fileInput.value = null" type="file" id="excel-upload" multiple="true"
          style="display: none" (change)="onChangeUploadDocForUpdate($event)" />
      </label>
    </div>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="UpdateProject()" (click)="d('Cross click')"
      style="cursor: pointer">
      Update
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deleteproject let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Project</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="DeleteProjectRow()" (click)="d('Cross click')"
      style="cursor: pointer">
      Ok
    </button>
    <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="cursor: pointer">
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
      <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b></span>
    </div>
  </div>
</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>