
<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<app-user-sidebar *ngIf="(roleid=='2' || roleid=='9')&& mobileview"></app-user-sidebar>
<br><br><br><br><br>
<div class="col-md-12 text-right">
    <video #onlymyvideo style="width: 200px;height: 200px;" muted></video>
</div>
<br>


<div class="col-md-12">
    <div id="videos" class="col-md-12 row" style="display: flex;"></div>
</div>