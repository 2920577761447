<app-nav-bar></app-nav-bar>
<ngx-spinner></ngx-spinner> <br /><br /><br /><br />

<div class="col-md-12 row">
    <div class="col-md-3">&nbsp;</div>
    <div class="col-md-4">
        <b *ngIf="Enterprise" style="font-size: 20px; color: black;">Enterprise Account</b>
        <b *ngIf="IsEnterprise" style="font-size: 20px; color: black;">Personal Account</b>

    </div>
    <div class="col-md-2">
        <!-- <button type="submit" class="btn btn-primary" [routerLink]="['/refer']">
      Refer &amp; Earn Points
    </button> -->
    </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>

<div class="col-md-12 row">
    <div class="col-md-3">&nbsp;</div>
    <div class="col-md-6 row">
        <!-- <div class="col-md-12 text-center"> <input type="file" id="filePicker" (change)="handleFileSelect($event)"></div> -->
        <div class="table-responsive">
            <table class="table table-hover">
                <tbody style="color: black;">
                    <tr *ngIf="logoexist">
                        <td style="color: black;font-weight: 500">
                            Company Logo </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td *ngIf="logoexist"> <img src="data:image/png;base64,{{profile.companyLogo}}"
                                class="img-responsive" height="120px" width="120px" alt=""
                                style="float: right;"></td>

                                <div
                                id="edit"
                                data-toggle="modal"
                                data-target="#EditProfilePhoto"
                                style="padding-left: 7%; padding-bottom: 2%"
                              >
                                EDIT
                              </div>


                                <td>&nbsp;</td>
                    </tr>
                    <tr *ngIf="photoexist">
                        <td style="color: black;font-weight: 500">
                           Profile Picture </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td *ngIf="photoexist"> <img src="data:image/png;base64,{{profile.userImage}}"
                                class="img-responsive" height="120px" width="120px" alt=""
                                style="float: right;"></td>
                                <div
                                id="edit"
                                data-toggle="modal"
                                data-target="#EditProfilePhoto"
                                style="padding-left: 7%; padding-bottom: 2%"
                              >
                                EDIT
                              </div>
                                <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td style="color: black; font-weight: 500;">
                            Name
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <b style="float: right; font-weight: 500;">{{
                profile.userName
              }}</b>
                        </td>
                    </tr>

                    <tr>
                        <td *ngIf="emailexist" style="color: black; font-weight: 500;">
                             Email
                        </td>
                        <td *ngIf="phoneexist" style="color: black; font-weight: 500;">
                            Phone Number
                       </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td *ngIf="emailexist">
                            <b style="float: right; font-weight: 500;">{{ profile.email }}</b>
                        </td>
                        <td *ngIf="phoneexist">
                            <b style="float: right; font-weight: 500;">{{ profile.phoneNumber }}</b>
                        </td>
                    </tr>

                    <tr>
                        <td style="color: black; font-weight: 500;">
                            ********
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <button class="btn btn-outline-primary waves-effect"
                                (click)="ViewDocument('nothing', pdfcontent1)"
                                style="float: right; padding: 6px; text-transform: capitalize;">
                                Change Password
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td style="color: black; font-weight: 500;">
                            Storage
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <b style="float: right; font-weight: 500;">{{ storagedata   }}</b>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</div>
<div
  class="modal fade"
  id="EditProfilePhoto"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Profile Photo</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container fileUploadWrapper">
          <div class="col-md-12">
            <ul style="list-style: none">
              <div class="modal-body">
                <label for="file">Choose Profile Photo</label> <br />
                <input
                  type="file"
                  id="file"
                  (change)="handleFileInput2($event.target.files)"
                />
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="cancelUpload"
          class="btn btn-secondary"
          data-dismiss="modal"
          style="cursor: pointer"
        >
          Cancel
        </button>
        <button
          type="button"
          data-dismiss="modal"
          class="btn btn-primary"
          (click)="saveprofilepic()"
          style="cursor: pointer"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>

<ng-template #pdfcontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 100%; background-color: white;">
        <div>&nbsp;</div>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="width: 100%; background-color: white;">
        <div class="col-md-12 row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <input type="text" id="Name" placeholder="Name" />
                <br /><br />
                <div class="col-md-12 row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <button style="
                background-color: orange;
                float: right;
                padding: 6px;
                text-transform: capitalize;
                color: black;
                border: solid 1px;
              ">
                            Save
                        </button>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                        <button style="
                background-color: green;
                float: right;
                padding: 6px;
                text-transform: capitalize;
                color: black;
                border: solid 1px;
              " (click)="d('Cross click')">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #pdfcontent1 let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 100%; background-color: white;">
        <div>&nbsp;</div>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="width: 100%; background-color: white;">
        <div class="col-md-12 row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <form #forms="ngForm" (ngSubmit)="ChangePassword(forms)">
                    <input type="password" class="form-control" name="passwordone" id="Password"
                        placeholder="New Password" ngModel />
                    <br /><br />
                    <input type="password" class="form-control" name="passwordtwo" id="ConfirmPassword"
                        placeholder="Confirm Password" ngModel />
                    <br /><br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <button type="submit" (click)="ChangePassword(forms)" aria-label="Close"
                                (click)="d('Cross click')" style="
                  background-color: orange;
                  float: right;
                  padding: 6px;
                  text-transform: capitalize;
                  color: black;
                  border: solid 1px;
                ">
                                Save
                            </button>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <button type="button" #closebutton style="
                            background-color: green;
                            float: right;
                            padding: 6px;
                            text-transform: capitalize;
                            color: black;
                            border: solid 1px;
                          " aria-label="Close" (click)="d('Cross click')">
                                Close
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<style>
    ::placeholder {
        color: black;
        opacity: 0.7;
    }

    .form-control {
        background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #704040 1px, white 1px);
        border-radius: 5px;
    }

    .input {
        background-color: white;
    }
</style>

<style>
    .dropzone {
        min-height: 400px;
        min-width: 400px;
        display: table;
        width: 100%;
        background-color: #eee;
        border: dotted 1px #aaa;
    }

    .text-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .centered {
        font-family: sans-serif;
        font-size: 1.3em;
        font-weight: bold;
        text-align: center;
    }
</style>

<style>
    .modal-backdrop {
        display: none;
    }

    .modal-open .modal {
        width: 300px;
        margin: 0 auto;
    }

    .modal-backdrop {
        display: none !important;
    }

    .modal-open .modal {
        width: 300px;
        margin: 0 auto;
    }
</style>