<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div>&nbsp;</div>
<h2 style="text-align: center">Projects List</h2>
<div>&nbsp;</div>
<div class="row col-sm-12">
  <div class="col-sm-3">
    <button class="btn btn-primary" (click)="addProject(addproject)">
      Add
    </button>
  </div>
  <div class="col-sm-9"></div>
</div>
<br />
<div class="col-md-12 row">
  <div class="col-md-12">
    <div>
      <div style="overflow-x: visible; height: 300px">
        <table class="table table-striped" style="border-collapse: collapse">
          <tr class="thead-dark" style="font-size: 17px">
            <th class="text-center">SI No</th>
            <th class="text-center">Name</th>
            <th class="text-center">Description</th>
            <th class="text-center">Purpose</th>
            <th class="text-center">Priority</th>
            <th class="text-center">Project Code</th>
            <th class="text-center">Lead</th>
            <th class="text-center">StartDate</th>
            <th class="text-center">Objectives</th>
            <th class="text-center">Estimsted Time(in Hrs)</th>
            <th class="text-center">Estimated Cost(in Rs)</th>
            <th class="text-center">Client</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
          <tr
            *ngFor="
              let aa of ProjectList
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            "
            style="font-size: 16px; font-family: DINNextRoundedLTPro"
          >
            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
            <td>{{ aa.projectName }}</td>
            <td>{{ aa.projectDescription }}</td>
            <td>{{ aa.purposeOfProject }}</td>
            <td>{{ aa.priority }}</td>
            <td>{{ aa.projectCode }}</td>
            <td>{{ GetUser(aa.projectLead) }}</td>
            <td>{{ aa.startDate | date: "short" }}</td>
            <td>{{ aa.objectives }}</td>
            <td>{{ aa.estimstedTime }}</td>
            <td>{{ aa.estimatedCost }}</td>
            <td>{{ GetClient(aa.client) }}</td>
            <td>
              <button
                class="btn btn-success"
                (click)="EditProject(aa, updateproject)"
              >
                Edit
              </button>
            </td>
            <td>
              <button
                class="btn btn-danger"
                (click)="deleteProject(aa, deleteproject)"
              >
                Delete
              </button>
            </td>
          </tr>
        </table>
        <br />
        <pagination-controls
          (pageChange)="p = $event"
          style="float: right"
        ></pagination-controls>
        <br /><br />
        <br /><br />
      </div>
    </div>
  </div>
</div>
<br /><br />

<ng-template #addproject let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Project</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">Project Name:</h5>
    <input
      type="text"
      id="projectname"
      [(ngModel)]="projectname"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Project Description:</h5>
    <input
      type="text"
      id="projectdesc"
      [(ngModel)]="projectdesc"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Purpose of Project:</h5>
    <input
      type="text"
      id="purpose"
      [(ngModel)]="purpose"
      class="form-control"
      name="dp"
    />
    <br />
    <select
      (change)="onSelectPriority($event.target.value)"
      class="form-control dropdownsize"
    >
      <option value="">Select Priority</option>
      <option
        *ngFor="let do of prioritylist"
        value="{{ do.PriorityId }}"
        [selected]="do.PriorityId == editpriority"
      >
        {{ do.PriorityName }}
      </option></select
    ><br />
    <select (change)="onSelectUser($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Lead</option>
      <option *ngFor="let do of UserList" value="{{ do.userId }}">
        {{ do.userName }}
      </option>
    </select>
    <h5 class="modal-title" id="modal-basic-title">Project Code:</h5>
    <input
      type="text"
      id="projectcode"
      [(ngModel)]="projectcode"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Start Date:</h5>
    <input
      type="date"
      id="startdate"
      [(ngModel)]="startdate"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Objectives:</h5>
    <input
      type="text"
      id="objectives"
      [(ngModel)]="objectives"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Estimated Time(in Hrs):</h5>
    <input
      type="text"
      id="estimatedtime"
      [(ngModel)]="estimatedtime"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Estimated Cost(in Rs):</h5>
    <input
      type="text"
      id="estimatedcost"
      [(ngModel)]="estimatedcost"
      class="form-control"
      name="dp"
    />
    <select (change)="onSelectClient($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Client</option>
      <option *ngFor="let do of ClientList" value="{{ do.id }}">
        {{ do.clientName }}
      </option>
    </select>
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="SaveProject()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Save
    </button>
  </div>
  <br />
</ng-template>
<ng-template #updateproject let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Project</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">Project Name:</h5>
    <input
      type="text"
      id="editprojectname"
      [(ngModel)]="editprojectname"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Project Description:</h5>
    <input
      type="text"
      id="editprojectdesc"
      [(ngModel)]="editprojectdesc"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Purpose of Project:</h5>
    <input
      type="text"
      id="editpurpose"
      [(ngModel)]="editpurpose"
      class="form-control"
      name="dp"
    /><br />
    <select
      (change)="onSelectPriority($event.target.value)"
      class="form-control dropdownsize"
    >
      <option value="">Select Priority</option>
      <option
        *ngFor="let do of prioritylist"
        value="{{ do.PriorityId }}"
        [selected]="do.PriorityId == editpriority"
      >
        {{ do.PriorityName }}
      </option>
    </select>

    <h5 class="modal-title" id="modal-basic-title">Project Code:</h5>
    <input
      type="text"
      id="editprojectcode"
      [(ngModel)]="editprojectcode"
      class="form-control"
      name="dp"
    />
    <select (change)="onSelectUser($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Lead</option>
      <option
        *ngFor="let do of UserList"
        value="{{ do.userId }}"
        [selected]="do.userId == edituserid"
      >
        {{ do.userName }}
      </option>
    </select>
    <h5 class="modal-title" id="modal-basic-title">Start Date:</h5>
    <input
      type="date"
      id="converteddate"
      [(ngModel)]="converteddate"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Objectives:</h5>
    <input
      type="text"
      id="editobjectives"
      [(ngModel)]="editobjectives"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Estimated Time(in Hrs):</h5>
    <input
      type="text"
      id="editestimatedtime"
      [(ngModel)]="editestimatedtime"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Estimated Cost(in Rs):</h5>
    <input
      type="text"
      id="editestimatedcost"
      [(ngModel)]="editestimatedcost"
      class="form-control"
      name="dp"
    /><br />
    <select (change)="onSelectClient($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Client</option>
      <option
        *ngFor="let do of ClientList"
        value="{{ do.id }}"
        [selected]="do.id == editclentid"
      >
        {{ do.clientName }}
      </option>
    </select>
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="UpdateProject()"
      (click)="d('Cross click')"
      style="color: black; cursor: pointer"
    >
      Update
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deleteproject let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Project</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="DeleteProjectRow()"
      (click)="d('Cross click')"
      style="color: black; cursor: pointer"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="d('Cross click')"
      style="color: black; cursor: pointer"
    >
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div
      class="alert alert-info"
      style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      "
    >
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="closealert()"
      >
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>
