<app-nav-bar></app-nav-bar>

<!-- Start Section -->
<section class="section pb-40">
    <div class="container">
        <div class="col-md-12 row dashboardmedia">
            <div class="col-lg-3" id="card" *ngFor="let item of Menus">
                <div style="cursor: pointer" (click)="gotorespectivepage(item)">
                    <span class="center" style="cursor: pointer">
            <img
              class="center"
              [src]="item.image"
              style="width: 50px; height: 50px; cursor: pointer"
              (click)="gotorespectivepage(item)"
            />
          </span>
                    <div>&nbsp;</div>
                    <h4 style="text-align: center">{{ item.menuName }}</h4>
                    <p style="text-align: center">
                        {{ item.menuFileName }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<br />
<br /><br /><br />