import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  readonly baseAppUrl: string = 'https://blocdrive.in/blocdriveapi'

  constructor(private http:HttpClient) { }
  getUsers(){
    return this.http.get(this.baseAppUrl + '/api/GeneralTransaction/GetUsers/');
  }
  saveOutWard(data){
    return this.http.post(this.baseAppUrl + '/api/OutWard/PostOutwardTransactionForm',data);

  }
}
