import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MailService } from 'src/app/Shared/Mail/mail.service';
import { MessageService1 } from 'src/app/Shared/MessageService/message.service';

@Component({
  selector: 'app-extsentmails',
  templateUrl: './extsentmails.component.html',
  styleUrls: ['./extsentmails.component.css']
})
export class ExtsentmailsComponent implements OnInit {
  uid: string;
  SentItemsList: any;

  constructor(private mailboxservice: MessageService1, private mailservice:MailService, private route:Router) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem("userId");
    this.GetSentItems();
  }
  
  GetSentItems() {
    this.mailboxservice.getSentitemslist(this.uid).subscribe((respose: any) => {
      this.SentItemsList = respose;
      console.log(respose);
    });
  }

  ReadMessage(data){
    localStorage.setItem('fromuser', data.from);
    localStorage.setItem('touser', data.to);
    localStorage.setItem('attachments', data.attachments);   
    localStorage.setItem('mailid', data.masId);
    localStorage.setItem('transid', data.transId);
    localStorage.setItem('maildate', data.dateforRM);
    localStorage.setItem('firstletter', data.firstLetter);
    localStorage.setItem('docname', data.documentName);
    localStorage.setItem('emailbody', data.emailBody);
    localStorage.setItem('sub', data.subject);
    this.route.navigate(['readextmsg']);
}
inbox() {
  this.route.navigate(['extinboxmails']);
}
composemail() {
  this.route.navigate(['composeextmsg']);
}
deleteitems() {
  this.route.navigate(['delextmails']);
}
sentitems() {
  this.route.navigate(['sentextmails']);
}
archiveitems() {
  this.route.navigate(['extarchive']);
}

// singleDelete(data)
// {
//   this.mailboxservice.deletemail(data.transId).subscribe(data=>
//     {
//       alert("Deleted Successfully");
//       this.GetSentItems();
//     });
// }

}
