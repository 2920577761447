<!-- <header id="header" class="fixed-top d-flex align-items-center bg-dark">
    <div class="container d-flex align-items-center">
        <div class="logo mr-auto">

            <a href="index.html"><span><img src="assets/img/blocdriverz.png" style="width:90px;height: 80px;margin-top: 10px;" /></span>  </a>

            <h6 style="color: white;font-size: 10px;
            margin-left: 10px;
            padding-top: 5px;
        ">Securing Data</h6>

                  </div>


        <nav class="nav-menu d-lg-block">
            <ul>
                <li class="active"><a href="#/home" class="text-white">Home</a></li>
                <li class="active" *ngIf="isLoggedIn && (roleid=='1' || roleid=='7' || roleid=='8'|| roleid=='2')"><a href="#/dashboard" class="text-white">MySpace</a></li>

                <li *ngIf="!isLoggedIn"><a href="#/registration" class="text-white">Enterprise</a></li>
                <li *ngIf="!isLoggedIn"><a href="#/indregister" class="text-white">Individual</a></li>

                <li *ngIf="!isLoggedIn">
                    <a href="#/login" class="text-white">Sign In</a>
                </li>

                <li class="nav-item dropdown" *ngIf="isLoggedIn && roleid=='1'">
                    <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer;">
                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Masters</a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary" style="background-color: #f3f3f3;padding-right: 14px">
                            <a *ngFor="let menu2 of MasterList" href=#{{menu2.menuURL}} class="dropdown-item">{{menu2.menuFileName}}</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown" *ngIf="isLoggedIn && roleid=='9'">
                    <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer;">
                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Audit Reports</a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary" style="background-color: #f3f3f3;padding-right: 14px">
                            <a *ngFor="let menu2 of MenuList" (click)="ShowDetails(menu2.menuURL)" class="dropdown-item">{{menu2.menuFileName}}</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown" *ngIf="isLoggedIn">
                    <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer;">
                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Safe</a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary" style="background-color: #f3f3f3;padding-right: 14px">
                            <a *ngFor="let menu3 of BsafeMenus" href=#{{menu3.menuURL}} class="dropdown-item">{{menu3.menuFileName}}</a>
                        </div>
                    </div>
                </li>

                <li style="cursor: pointer;" data-tooltip=" Srichid Drive : Install let’s try on your Windows system by downloading the EXE file here.&#xa;  &#xa;   Srichid Drive helps you to upload / download objects and folders and sync them with blocdrive from your desktop."
                    data-tooltip-location="bottom">
                    <a (click)="DownloadFile()" *ngIf="isLoggedIn" class="text-white">Srichid Drive</a>
                </li>
                <li style="cursor: pointer;" data-tooltip=" SP-SSD : Srichid Portable SSD. &#xa;This drive helps you to upload / download objects and folders From the window system and into a block chain repository contained in the portable drive . " data-tooltip-location="bottom">
                    <a (click)="DownloadFilePortable()" *ngIf="isLoggedIn" class="text-white">SP-SSD</a>
                </li>
                <li class="nav-item dropdown" *ngIf="isLoggedIn">
                    <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer;">
                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">{{username}}</a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary" style="background-color: #f3f3f3;padding-right: 14px">
                            <a href="#/profile" class="dropdown-item">My Account</a>
                            <a class="dropdown-item" (click)="Logout()">Sign Out</a>

                        </div>
                    </div>
                </li>

            </ul>

        </nav>

    </div>
</header> -->

<div class = "abc">
<nav class="navbar navbar-expand-md navbar-dark sticky-top" style="background: black">
  <a class="navbar-brand" href="#" style="padding-left: 4%">
    <img src="assets/img/blocdriverz.png" class="img-fluid"
      alt="" style="width: 90px" />
    <br />
    <h6 style="color: white; font-size: 10px; margin-left: 12px">
      Securing Data
    </h6>
  </a>
  
  <li class="active">
    <a class="text-white" style="font-size: 23px;">{{Enterprise}}</a>
  </li>
  <div class="navbar-toggler-right">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar"
      aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div class="collapse navbar-collapse flex-column" id="navbar" >
    <ul class="navbar-nav w-100 justify-content-end px-3">
      <li class="active">
        <a href="#/home" class="text-white">Home</a>
      </li>
      <li class="active" *ngIf="
          isLoggedIn
          
        ">
        <a href="#/dashboard" class="text-white">MySpace</a>
      </li>
      <li class="active" *ngIf="isLoggedIn && roleid == '1049'">
        <a href="#/Superadmin" class="text-white">Dashboard</a>
      </li>
      <li class="active" *ngIf="isLoggedIn && roleid == '1050'">
        <a href="#/Gateway" class="text-white">Gateway Dashboard</a>
      </li>
      <li class="active" *ngIf="isLoggedIn && roleid == '1049'">
        <a href="#/auditortransactiondetails" class="text-white">Auditor Dashboard</a>
     </li>
     <li class="active" *ngIf="isLoggedIn && showaudit">
      <a href="#/auditorview" class="text-white">Auditor Dashboard</a>
   </li>
      <!-- <li *ngIf="!isLoggedIn">
                <a href="#/registration" class="text-white">Enterprise</a>
            </li>
            <li *ngIf="!isLoggedIn">
                <a href="#/indregister" class="text-white">Individual</a>
            </li> -->

      <li *ngIf="!isLoggedIn" class="get-started">
        <a href="#/login" class="text-white">Sign In</a>
      </li>

      <li class="nav-item dropdown" *ngIf="isLoggedIn && roleid == '1'">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Masters</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a *ngFor="let menu2 of MasterList" href="#{{ menu2.menuURL }}"
              class="dropdown-item">{{ menu2.menuFileName }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn && roleid == '1049' ">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Masters</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a *ngFor="let menu2 of MasterList" href="#{{ menu2.menuURL }}"
              class="dropdown-item">{{ menu2.menuFileName }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn && roleid == '9'">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Audit Reports</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a *ngFor="let menu2 of MenuList" (click)="ShowDetails(menu2.menuURL)"
              class="dropdown-item">{{ menu2.menuFileName }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn && !apiconsumer">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Safe</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a *ngFor="let menu3 of BsafeMenus" href="#{{ menu3.menuURL }}"
              class="dropdown-item">{{ menu3.menuFileName }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn && !apiconsumer">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Project</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a *ngFor="let menu3 of PMTMenus" href="#{{ menu3.menuURL }}"
              class="dropdown-item">{{ menu3.menuName }}</a>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf="isLoggedIn && !apiconsumer">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white">Mail</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a *ngFor="let menu3 of mailMenus" href="#{{ menu3.menuURL }}"
              class="dropdown-item">{{ menu3.menuFileName }}</a>
          </div>
        </div>
      </li>

      <div class="dropdown nav-item dropdown" style="padding-right: 14px" *ngIf="isLoggedIn && !apiconsumer" >
        <button class="dropbtn">Srichid Drive<i class="fa fa-caret-down" aria-hidden="true"></i></button>
        <div  class="dropdown-content" >
          <a (click)="DownloadFile()" >Srichid Drive</a>
          <a (click)="DownloadMigrateFile()" >Google Drive Migration</a>
          <a (click)="DownloadSSDNasdrivefile()">SSD/NAS Drive</a>
          <a (click)="DownloadGmailMigratefile()">Gmail Migration</a>
        </div>
      </div>
      <!-- <li style="cursor: pointer"
        data-tooltip=" Srichid Drive : Install let’s try on your Windows system by downloading the EXE file here.&#xa;  &#xa;   Srichid Drive helps you to upload / download objects and folders and sync them with blocdrive from your desktop."
        data-tooltip-location="bottom">
        <a (click)="DownloadFile()" *ngIf="isLoggedIn" class="text-white">Srichid Drive</a>
      </li>
      <li style="cursor: pointer"
      data-tooltip=" Srichid Migration : Install and migrate your google drive files to blocdrive.&#xa;  &#xa; "
      data-tooltip-location="bottom">
      <a (click)="DownloadMigrateFile()" *ngIf="isLoggedIn" class="text-white">Google drive Migration</a>
    </li> -->
      <!-- <li style="cursor: pointer"
        data-tooltip=" Srichid Drive : Install let’s try on your Windows system by downloading the EXE file here.&#xa;  &#xa;   Srichid Drive helps you to upload / download objects and folders and sync them with blocdrive from your desktop."
        data-tooltip-location="bottom">
        <a (click)="DownloadGoogleDriveFiles()" *ngIf="isLoggedIn" class="text-white">Google Drive</a>
      </li> -->
      <!-- <li style="cursor: pointer"
        data-tooltip=" SP-SSD : Srichid Portable SSD. &#xa;This drive helps you to upload / download objects and folders From the window system and into a block chain repository contained in the portable drive . "
        data-tooltip-location="bottom">
        <a (click)="DownloadFilePortable()" *ngIf="isLoggedIn" class="text-white">SP-SSD</a>
      </li> -->

      <li style="cursor: pointer">
      <a href="#/friends" *ngIf="isLoggedIn && !apiconsumer" class="text-white">Chat</a>
    </li>

      <li class="nav-item dropdown" *ngIf="isLoggedIn">
        <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer">
          <!-- <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white" style="text-transform: capitalize;">{{username}}</a> -->
          <a *ngIf="roleid != 1" class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white" style="text-transform: capitalize;font-size: 15px;
          font-weight: 900;">{{username}}</a>
          <a *ngIf="roleid == 1" class="nav-link" id="dropdownBasic1" ngbDropdownToggle class="text-white" style="text-transform: capitalize;font-size: 15px;
          font-weight: 900;">{{firstname}}</a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
            style="background-color: #f3f3f3; padding-right: 14px">
            <a [routerLink]="['/profile']" class="dropdown-item" style="cursor: pointer;">My Account</a>
            <a (click)="Logout()" class="dropdown-item" style="cursor: pointer;">Logout</a>
          </div>
        </div>
      </li>


    </ul>
  </div>
</nav>
</div>