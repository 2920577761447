import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChooseESign, DrawnESign, ESign } from 'src/app/ViewModels/E_Sign';

@Injectable({
  providedIn: 'root'
})

export class SignatureService {
    readonly  url="https://blocdrive.in/blocdriveapi/api/";
    //url = environment.url;
    constructor(private http:HttpClient) { }
  
    postESignature(customerid: string,fileupload: File) {
      const url = this.url+'Signature/PostESignatures/';
      const formData: FormData = new FormData();
       formData.append('Image', fileupload, fileupload.name);
       formData.append('UserAliasId', customerid);
       formData.append('FileType', fileupload.type);
      //  formData.append('gra', c);
      // formData.append('cou', d);
      return this.http.post(url, formData);
    }
  
    postEInitials(customerid: string,fileupload: File) {
      const url = this.url+'Signature/PostEInitials/';
      const formData: FormData = new FormData();
       formData.append('Image', fileupload, fileupload.name);
       formData.append('UserAliasId', customerid);
       formData.append('FileType', fileupload.type);
      //  formData.append('gra', c);
      // formData.append('cou', d);
      return this.http.post(url, formData);
    }
  
    UpdateCustomerESignsData(data:ESign){
      return this.http.put(this.url +'/Signature/PutCstomerESIgnData', data);
    }
  
    
      PostCustomerSelectedSign(data:ChooseESign){
      return this.http.post(this.url +'/Signature/PostSelectedESIgnData', data);
    }
  
    PostCustomerDrawSign(data:DrawnESign){
      return this.http.post(this.url +'/Signature/PostDrawESIgnData', data);
    }
    
    getUploadedSign(id:any){
      return this.http.get(this.url + '/api/Signature/GetUploadedSign/' + id);
    }
    
    getUploadedInitial(id:any){
      return this.http.get(this.url + '/api/Signature/GetUploadedInitial/' + id);
    }
  
  
  }