import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';
import { OutwardService } from 'src/app/Shared/Outward/outward.service';

@Component({
  selector: 'app-outward-preview',
  templateUrl: './outward-preview.component.html',
  styleUrls: ['./outward-preview.component.css']
})
export class OutwardPreviewComponent implements OnInit {
  printdoc: any;
  gotfile: any;
  uid: string;
  username: string;
  Role: string;
  OutwardPreviewData: any;
  selecteduserlist: any;
  address: any;
  attachmentdetails: any;
  dispatchMode: any;
  docType: any;
  Inwardno: any;
  OutwardNumber: any;
  outwardRefNumber: any;
  outwardtype: any;
  pages: any;
  replyAwaited: any;
  sensitivity: any;
  divisionName: any;
  status: any;
  filenumber: any;
  folderid: number;
  mailid: any;
  remarks: any;
  InwardFolderId: any;
  InwardFileId: any;
  one: any;
  FilePlanList: any;
  date: any;
  WorkflowStageList: any;
  DocumentTypeList: any;
  sensitivitylist: any;
  dispatchmodelist: any;
  replyawaitedlist: any;
  outwardtypelist: any;
  converttostring: any;

  constructor(private spinner:NgxSpinnerService,private inwardservice:InwardServiceService,private router:Router,
    private outwardService:OutwardService,private datepipe:DatePipe) { 
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.username = localStorage.getItem("UserFullName");
        this.Role = localStorage.getItem("role");
      }
      if (localStorage.getItem("OutwardPreviewData") != null || localStorage.getItem("OutwardPreviewData") != undefined) {
        var data = JSON.parse(localStorage.getItem("OutwardPreviewData"))
        this.OutwardPreviewData = data
        this.AssignData();
  
      }
      var currentdate = new Date();
      this.converttostring = this.datepipe.transform(currentdate, 'yyyy-MM-dd');
      this.date = this.converttostring.toString();
    }

  ngOnInit(): void {
   
    this.FilePlan()
    this.WorkflowStage();
    this.DocumentTypes();
    this.Priority();
    this.GetReplyAwaited();
    this.GetOutwardType();
  }
  AssignData() {   

    this.selecteduserlist = this.OutwardPreviewData.ToUsers
    this.address = this.OutwardPreviewData.WO_Address
    this.attachmentdetails = this.OutwardPreviewData.WO_Attachment
    this.dispatchMode = this.OutwardPreviewData.WO_DispatchMode
    this.docType = this.OutwardPreviewData.WO_DocumentType
    this.Inwardno = this.OutwardPreviewData.WO_InwordNo
    this.OutwardNumber = this.OutwardPreviewData.WO_OutwardNo
    this.outwardRefNumber = this.OutwardPreviewData.WO_OutwardRefNo
    this.outwardtype = this.OutwardPreviewData.WO_OutwardTypeId
    this.pages = this.OutwardPreviewData.WO_Pages
    this.replyAwaited = this.OutwardPreviewData.WO_ReplyAwaited
    this.sensitivity = this.OutwardPreviewData.WO_Sensitivity
    this.divisionName = this.OutwardPreviewData.Subject
    this.attachmentdetails = this.OutwardPreviewData.AttachmentDetails
    this.status = this.OutwardPreviewData.Statusid
    this.filenumber = this.OutwardPreviewData.FileNumber
    this.folderid = this.OutwardPreviewData.FolderNumber
    this.mailid = this.OutwardPreviewData.Massid
    this.remarks = this.OutwardPreviewData.Remarks
    this.InwardFolderId = this.OutwardPreviewData.InwardFolderId
    this.InwardFileId = this.OutwardPreviewData.InwardFileId
    this.one=this.OutwardPreviewData.one
  }
  sndpdftoprint(){
    var data = document.getElementById('print-section');
    this.spinner.show();
    html2canvas(data).then(canvas => {
      var imgWidth = 100;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpg')
      var data45 = {
        htmlString: contentDataURL,
        FormName:"Outward"

      }
      this.inwardservice.DownloadInwardForm(data45).subscribe((data1: any) => {
        var aa = data1
        this.printdoc=data1
        var data45 = {
          Pdffile:  this.printdoc,
          File: this.printdoc,
        }
        this.spinner.show();
    
        this.inwardservice.PrintInwardForm(data45).subscribe((data1: any) => {
          var aa = data1
          this.gotfile=data1
          this.spinner.hide();
    
          var bs64data = this.gotfile.split(',')[1];
          var blnk = this.base64ToBlob(bs64data);
          const blobUrl = URL.createObjectURL(blnk);
      
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
    
        })

      })
    });

   
      
 }
 DownloadForm() {
   
  var data = document.getElementById('print-section');
  this.spinner.show();
  html2canvas(data).then(canvas => {
    var imgWidth = 100;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    const contentDataURL = canvas.toDataURL('image/jpg')
    var data45 = {
      htmlString: contentDataURL,
      FormName:"Outward"
    }
    this.inwardservice.DownloadInwardForm(data45).subscribe((data1: any) => {
      var aa = data1
      this.printdoc=data1
   
      window.open(data1);

   
      this.spinner.hide();

    })
  });
}
 base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};
goBack(){
  this.router.navigate(['outward/']);
}
FilePlan() {
  this.spinner.show();
  this.inwardservice.GetFilePlanList().subscribe((Data: any) => {
    this.FilePlanList = Data;
    this.spinner.hide();
  })
}
GetFileName(id){
  for (let i = 0; i < this.FilePlanList.length; i++) {
    if (id == this.FilePlanList[i].fileUniqueId) {
      return this.FilePlanList[i].filename
    }
  }
}
WorkflowStage() {
  this.spinner.show();
  this.inwardservice.GetWorkflowStage("OUT").subscribe((Data: any) => {
    this.WorkflowStageList = Data;
    this.spinner.hide();

  })
}
GetWorkflowStageName(id){
  for (let index = 0; index < this.WorkflowStageList.length; index++) {
    if(id==this.WorkflowStageList[index].id){
      return this.WorkflowStageList[index].workflowStage
    }
  }
}
DocumentTypes() {
  this.spinner.show();
  this.inwardservice.GetDocumentTypeList().subscribe((Data: any) => {
    this.DocumentTypeList = Data;
    this.spinner.hide();

  })
}
GetDocumentTypeName(id){
  for (let index = 0; index < this.DocumentTypeList.length; index++) {
    if(id==this.DocumentTypeList[index].documentTypeID){
      return this.DocumentTypeList[index].documentTypeName
    }
  }
}

Priority() {
  this.spinner.show();
  this.inwardservice.GetPriority().subscribe((Data: any) => {
    this.sensitivitylist = Data;
    this.spinner.hide();

  })
}
GetPriorityName(id){
  for (let index = 0; index < this.sensitivitylist.length; index++) {
    if(id==this.sensitivitylist[index].id){
      return this.sensitivitylist[index].sensitivityType
    }
  }
}

GetReplyAwaited(){
  this.spinner.show();
  this.outwardService.getReplyAwaited().subscribe((respose: any) => {
    this.replyawaitedlist = respose;
    this.spinner.hide();
  });
}
GetReplayAwaitedName(id){
  for (let index = 0; index < this.replyawaitedlist.length; index++) {
    if(id==this.replyawaitedlist[index].id){
      return this.replyawaitedlist[index].replyAwaited1
    }
  }
}
GetOutwardType() {
  this.spinner.show();
  this.outwardService.getOutwardType().subscribe((respose: any) => {
    this.outwardtypelist = respose;
    this.spinner.hide();
  });
}
GetOutwardTypeName(id){
  for (let index = 0; index < this.replyawaitedlist.length; index++) {
    if(id==this.replyawaitedlist[index].id){
      return this.replyawaitedlist[index].replyAwaited1
    }
  }
}
}
