import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService1 } from 'src/app/Shared/MessageService/message.service';

@Component({
  selector: 'app-ext-delete',
  templateUrl: './ext-delete.component.html',
  styleUrls: ['./ext-delete.component.css']
})
export class ExtDeleteComponent implements OnInit {
  uid: string;
  deleteListItems: any;

  constructor(private route:Router, private mailboxservice:MessageService1) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem("userId");
    this.deletedList();
  }

  
  deletedList()
  {
    this.mailboxservice.getDeleteitemslist(this.uid).subscribe((respose: any) => {
      this.deleteListItems = respose;
      console.log(respose);
    });
  }

  ReadMessage(data){
    localStorage.setItem('fromuser', data.from);
    localStorage.setItem('touser', data.to);
    localStorage.setItem('attachments', data.attachments);   
    localStorage.setItem('mailid', data.masId);
    localStorage.setItem('transid', data.transId);
    localStorage.setItem('maildate', data.dateforRM);
    localStorage.setItem('firstletter', data.firstLetter);
    localStorage.setItem('docname', data.documentName);
    localStorage.setItem('emailbody', data.emailBody);
    localStorage.setItem('sub', data.subject);
    this.route.navigate(['readextmsg']);
}
inbox() {
  this.route.navigate(['extinboxmails']);
}
composemail() {
  this.route.navigate(['composeextmsg']);
}
deleteitems() {
  this.route.navigate(['delextmails']);
}
sentitems() {
  this.route.navigate(['sentextmails']);
}


}
