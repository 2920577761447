import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnterPriseRegister } from 'src/app/ViewModels/EnterPriseRegister';
import { EmailuserValidation, EmailValidation } from 'src/app/ViewModels/EmailValidation';

@Injectable({
  providedIn: 'root'
})
export class EnterPriseRegisterService {

  readonly url="https://blocdrive.in/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  EnterpriseRegister(data:EnterPriseRegister){
    return this.http.post(this.url +"EnterPriseRegistration/PostEnterPriseRegistration",data);
  }
  validateemail(mail:EmailValidation){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidMail", mail);
  }
  validateemailormobile(data){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidMailorMobile/" , data);

  }
  ValidateOTPRegistration(data){
    return this.http.post(this.url + "EnterPriseRegistration/OTPValidationRegistration/" , data);

  }
  validateusername(mail:EmailValidation){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidUserName", mail);
  }
  validateusernamedata(mail:EmailuserValidation){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidUserNamedata", mail);
  }
  validatewebsite(website){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidWebsite", website);
  }

  Getimgverifieddet(id){
    return this.http.get(this.url + "User/Getimageverifieddetails/"+id);
  }

  Getsignverifieddet(id){
    return this.http.get(this.url + "/api/User/Getsignverifieddetails/"+id);
  }
  validateusernameclient(mail:EmailuserValidation){
    return this.http.post(this.url + "EnterPriseRegistration/PostValidUserNameClient", mail);
  }
}
