<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<app-user-sidebar *ngIf="(roleid=='2' || roleid=='9')&& mobileview"></app-user-sidebar>

<br>
<div class="col-md-12 text-right">
    <video #onlymyvideo style="width: 200px;height: 200px;" muted></video>

</div>

<div class="col-md-12 text-center">
    <video #myvideo></video>
</div>