import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MailService } from 'src/app/Shared/Mail/mail.service';
import { MessageService1 } from 'src/app/Shared/MessageService/message.service';

@Component({
  selector: 'app-ext-inbox',
  templateUrl: './ext-inbox.component.html',
  styleUrls: ['./ext-inbox.component.css']
})
export class ExtInboxComponent implements OnInit {
  uid: string;
  userName: string; InboxList: any;
  UsersList: any; touser: any;
  p: number = 1
  FromUser: any; Subject: any;
  Note: any; MDate: any;
  sMsg: any; allFolders: any;
  Description: string; FriendId: string;
  compose: boolean; openmail: boolean;
  folders: boolean; subfolders: boolean;
  FileFoldID: any; Pid: any;
  allSubFolders: any; fid: any;
  currentfoldername: any;
  selected = []; CurrentFiles: any;
  public remove: any = false;
  doccount: number = 0; toid: any;
  MailRespone: any; ToUser: any;
  showattachment: boolean;
  docname = []; val: any;
  imgdata: any; pdfdata: any;
  xlsdata: any; xlsDownloadpath: any;
  pdfdocname: any; videodata: any;
  showimage: boolean; showpdf: boolean;
  showxls: boolean; showvideo: boolean; showMailItems: boolean;
  DownloadSaved: any;
  dropdownSettings = {}; tempArr: any = [];
  tempArr1: any = []; alluserss: any;
  requiredalluserss: any;
  deletedata: string; userandmail: string;
  pdffile: any; pptdata: any;
  pptfile: any; zipdata: any;
  txtdata: any; txtfile: any;
  anyfiledata: any;
  worddata: any; wordfile: any;
  wordsignfile: any; xlssignfile: any;
  showMails: boolean; uname: string;
  isLoggedIn: boolean;
  public dismissalert = false;
  notify: string; showreply: boolean;
  To: any; subj: any; filecount: any;
  files1: any; msgbody: any;
  mailid: any; ToUsersList: any;
  usermail: string; replystatus: string;
  showto: boolean; showall: boolean;
  replayall: any; replytype: string;
  fm: any; ToList: any;
  LinkTransid: any; LinkMailid: any;
  showoriginalinbox: boolean;
  displayxls: any;
  xlsiframedata: string;
  //xlssantizedisplay: import("c:/Rajeshwari Budur/Reply Mail(Malleswari 02-10-2019)/haspit-6/node_modules/@angular/platform-browser/platform-browser").SafeResourceUrl;
  dontshowAllFileDisplay: boolean;
  xlssantizedisplay: any;
  DateTime: Date;
  showper: boolean;
  Creatingmsg: string;
  createdmsg: string;
  generalprogressbar: boolean;
  folderCreating: boolean;
  foldercreated: boolean;
  public n: any = 20; num: any; 
  width= 0;
  width1=0;  
  width2 = 0;
  width3 = 0;
  width4 = 0;
  cupage: boolean;
  checkenable: boolean;
  multi: boolean;
  morefiles: boolean;
  selectedSelected: any = [];
  isnotificationpage: string;
  toSendEmailIDtoMarkUp: string;
  notificationsresponse: any;
  selectedfile: boolean;
  test: any;
  selectedAll: any;
  toggleButton: any;
  sidebarVisible: boolean;
  mobile_menu_visible: number;
  DeletedItemsList: any;
  showBreadcrumb: boolean;
  SearchEnabledone: boolean;
  constructor(private mailboxservice: MessageService1, private mailservice:MailService, private route:Router) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem("userId");
    this.GetInboxList();
  }

  GetInboxList()
  {
    this.mailboxservice.getInboxlist(this.uid).subscribe((respose: any) => {
      this.InboxList = respose;
      console.log(respose);
      this.showMails = true;
      this.showMailItems = false;
      this.showreply = false;
      this.showoriginalinbox = false;
    });
  }

  // ReadMessage(data) {

  //   localStorage.setItem('fromuser', data.From);
  //   localStorage.setItem('touser', data.To);
  //   localStorage.setItem('attachments', data.Attachments);
  //   localStorage.setItem('mailid', data.MasId);
  //   localStorage.setItem('transid', data.TransId);
  //   localStorage.setItem('maildate', data.DateforRM);
  //   localStorage.setItem('profilepic', data.UserProfilePic);
  //   localStorage.setItem('firstletter', data.FirstLetter);
  //   localStorage.setItem('docname', data.DocumentName);
  //   localStorage.setItem('emailbody', data.EmailBody);
  //   localStorage.setItem('sub', data.Subject); 
  //   var info ={
  //     UID  :data.mailId
  //   }
  //   this.mailservice.postupdatemailread(info).subscribe((respose: any) => {
  //     var aa = respose;
  //     this.route.navigate(['readextmsg/'+ data.mailId]);
  //   });
   

  // }
  ReadMessage(data){
    localStorage.setItem('fromuser', data.from);
    localStorage.setItem('touser', data.to);
    localStorage.setItem('attachments', data.attachments);   
    localStorage.setItem('mailid', data.masId);
    localStorage.setItem('transid', data.transId);
    localStorage.setItem('maildate', data.dateforRM);
    localStorage.setItem('firstletter', data.firstLetter);
    localStorage.setItem('docname', data.documentName);
    localStorage.setItem('emailbody', data.emailBody);
    localStorage.setItem('sub', data.subject);
    this.route.navigate(['readextmsg']);
}
inbox() {
  this.route.navigate(['extinboxmails']);
}
composemail() {
  this.route.navigate(['composeextmsg']);
}
deleteitems() {
  this.route.navigate(['delextmails']);
}
sentitems() {
  this.route.navigate(['sentextmails']);
}

singleDelete(data)
{
  this.mailboxservice.deletemail(data.transId).subscribe(data=>
    {
      alert("Deleted Successfully");
      this.GetInboxList();
    });
}
}
