


<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div class="register">

    <h3 style="text-align: center;color: black;">Menu List</h3>
    <div>&nbsp;</div>
    <div class="row col-sm-12 menumasbtnmedia">
        <div class="col-sm-1"></div>
        <div class="col-sm-2">
            <button class="btn btn-primary" (click)="addMenu(addmenu)">
            Add
        </button>
        </div>
        <div class="col-sm-9"></div>
    </div>
    <br />
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div class="table-responsive" style="overflow-x: visible; height: 300px;">
                    <table class="table table-responsive" style="border-collapse: collapse;">
                        <tr class="thead-dark" style="font-size: 17px;">
                            <th>Sl No</th>
                            <th>Menu ID</th>
                            <th>MenuName</th>
                            <th>Parent_MenuID</th>
                            <th>User Role</th>
                            <th>Menu FileName</th>
                            <th>Menu URL</th>
                            <th>Created By</th>
                            <th>Created On</th>
                            <th>Modified By</th>
                            <th>Modified On</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                        <tr *ngFor="
              let aa of MenuList
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            " style="font-size: 16px;color:rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.menuID }}</td>
                            <td>{{ aa.menuName }}</td>
                            <td>{{ aa.parent_MenuID }}</td>
                            <td>{{ GetUserRole(aa.user_Role) }}</td>
                            <td>{{ aa.menuFileName }}</td>
                            <td>{{ aa.menuURL }}</td>
                            <td>{{ aa.createdBy }}</td>
                            <td>{{ aa.createdOn | date: "short" }}</td>
                            <td>{{ aa.modifiedBy }}</td>
                            <td>{{ aa.modifiedOn | date: "short" }}</td>
                            <td>
                                <button class="btn btn-success" (click)="EditMenu(aa, updatemenu)">
                                Edit
                            </button>
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteMenu(aa, deletemenu)">
                                Delete
                            </button>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<br /><br />

<ng-template #addmenu let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Add Menu</h3>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Menu ID:</h5>
        <input type="text" id="menuid" [(ngModel)]="menuid" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Menu Name:</h5>
        <input type="text" id="menuname" [(ngModel)]="menuname" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Parent_MenuID:</h5>
        <input type="text" id="parentmenuid" [(ngModel)]="parentmenuid" class="form-control" name="dp" /><br/>
        <select (change)="OnselectUserRole($event.target.value)" class="form-control dropdownsize">
            <option value="">Select Role</option>
            <option *ngFor="let do of RoleList" value={{do.id}}>
              {{do.roleName}}
            </option>
          </select>
          
        <h5 class="modal-title" id="modal-basic-title">MenuFileName:</h5>
        <input type="text" id="menufilename" [(ngModel)]="menufilename" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">MenuURL:</h5>
        <input type="text" id="menuurl" [(ngModel)]="menuurl" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="SaveMenu()" (click)="d('Cross click')" style="cursor: pointer;">
            Save
        </button>
    </div>
    <br />
</ng-template>
<ng-template #updatemenu let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Menu</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Menu ID:</h5>
        <input type="text" id="editmenuid" [(ngModel)]="editmenuid" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Menu Name:</h5>
        <input type="text" id="editmenuname" [(ngModel)]="editmenuname" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Parent_MenuID:</h5>
        <input type="text" id="editparentmenuid" [(ngModel)]="editparentmenuid" class="form-control" name="dp" />
        <br/><select (change)="OnselectUserRole($event.target.value)" class="form-control dropdownsize">
            <option value="">Select Role</option>
            <option *ngFor="let do of RoleList" value={{do.id}} [selected]="do.id == roleid">
              {{do.roleName}}
            </option>
          </select>
        <h5 class="modal-title" id="modal-basic-title">MenuFileName:</h5>
        <input type="text" id="editmenufilename" [(ngModel)]="editmenufilename" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">MenuURL:</h5>
        <input type="text" id="editmenuurl" [(ngModel)]="editmenuurl" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="UpdateMenu()" (click)="d('Cross click')" style=" cursor: pointer;">
            Update
        </button>
    </div>
    <br />
</ng-template>
<ng-template #deletemenu let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Menu</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="DeleteMenuRow()" (click)="d('Cross click')" style=" cursor: pointer;">
            Ok
        </button>
        <button type="button" class="btn btn-danger" (click)="d('Cross click')" style=" cursor: pointer;">
            Cancel
        </button>
    </div>
    <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
                <i class="material-icons" style="color: white;">close</i>
            </button>
            <span style="color: white; margin-top: 6px;">
                <b style="text-transform: capitalize; font-size: 14px;">
                    {{ notify }}</b></span>
        </div>
    </div>
</div>