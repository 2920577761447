<div>&nbsp;</div>
<div>&nbsp;</div>

<div id="mySidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <a class="nav-item dropdown" *ngIf="isLoggedIn && roleid=='9'">
    <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer;">
      <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>Audit Reports</a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
        style="background-color: #f3f3f3;padding-right: 14px">
        <a *ngFor="let menu2 of MenuList" (click)="ShowDetails(menu2.menuURL)"
          class="dropdown-item">{{menu2.menuFileName}}</a>
      </div>
    </div>
  </a>
  <a class="nav-item dropdown" *ngIf="isLoggedIn && mobileview">
    <div ngbDropdown class="d-inline-block dropdown" style="cursor: pointer;">
      <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>Safe</a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-primary"
        style="background-color: #f3f3f3;padding-right: 14px">
        <a *ngFor="let menu3 of BsafeMenus" style="color: #2a0e40;font-weight: 500;" href=#{{menu3.menuURL}}
          class="dropdown-item">{{menu3.menuFileName}}</a>
      </div>
    </div>
  </a>
  <a *ngIf="isLoggedIn && roleid=='2'" href="#/userdashboard" style="color: white;">Dashboard</a>

  <a *ngIf="mobileview && isLoggedIn" href="#" (click)="DownloadFile()">Srichid Drive</a>
  <a *ngIf="mobileview && isLoggedIn" href="#" (click)="DownloadFilePortable()">SP-SSD</a>
  <a *ngIf="mobileview && isLoggedIn"  href="#/hostevent" >Video Call</a>

  <a *ngIf="isLoggedIn" href="#" style="color: white;" (click)="Logout()">Logout</a>

  <!--  <a href="#">Delete Items</a>
    <a href="#">Inward</a>
    <a href="#">Outward</a>
    <a href="#">Task </a>
    <a href="#">Acknowledgement </a>
    <a href="#">General Trans</a>
    <a href="#">Work Allotment</a>
    <a href="#">Leave</a>
    <a href="#">Circulation </a> -->
</div>

<div id="main">
  <span style="font-size: 30px; cursor: pointer;" (click)="openNav()"><button type="button"
      class="btn btn-outline-secondary waves-effect" *ngIf="sidebaropen" style="color: black;">
      <i class="fas fa-bars" style="font-size: 25px;"></i>
    </button></span>
</div>