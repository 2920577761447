import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoService } from '../Shared/Video/video.service';
import * as $ from 'jquery';

declare var Peer: any;

@Component({
  selector: 'app-host-join',
  templateUrl: './host-join.component.html',
  styleUrls: ['./host-join.component.css']
})
export class HostJoinComponent implements OnInit {

  @ViewChild('myvideo') myvideo: any;
  userid;
  peer: any;
  anotherid;
  mypeerid;
  refid;
  hostid;
  qid;
  mobileview: boolean;
  isLoggedIn: boolean;
  uid: string;
  username: string;
  roleid: string;
  constructor(private route: ActivatedRoute, private sevice: VideoService, private router: Router) {
    this.userid = localStorage.userId;
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
    }
    this.isMobileMenu()
    window.scrollTo(0, 0);
    if (localStorage.userId == null || localStorage.userId == undefined) {
      this.router.navigate(['/newsignin']);
      return;
    }
  }

  ngOnInit(): void {
    var remove = document.getElementById("footerremove");
    if (remove) {
      remove.style.display = "none";
    }
    let parent;
    let myonlyvideo;
    setTimeout(() => {
      parent = document.getElementById("videos");
      myonlyvideo = this.onlymyvideo.nativeElement;

    }, 5000);
    this.peer = new Peer();
    this.peer.on('open', function (id) {
      this.peer.id = id
      console.log('My peer ID is: ' + id);
    });
    setTimeout(() => {
      this.mypeerid = this.peer.id;
      console.log("here");
      if (this.mypeerid == null || this.mypeerid == undefined) {
        location.reload();
      }
      else {
        this.sevice.storePeerHost(this.userid, this.qid, this.mypeerid).subscribe((data: any) => {
          if (data == 0 || data == 1) {
            alert("You don't have any meetings scheduled today");
          }
          else {

          }
          console.log(data);
        });
      }
    }, 3000);

    this.peer.on('connection', function (conn) {
      conn.on('data', function (data) {
        // Will print 'hi!'
        console.log(data);
      });
    });

    var n = <any>navigator;

    n.getUserMedia = (n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia);

    this.peer.on('call', function (call) {
      this.userid = this.userid + 1;
      let divclass = document.createElement("div");
      divclass.setAttribute("class", "col-md-3 text-center");
      let video = document.createElement("video");
      video.setAttribute("style", "width:100%;height:100%");
      divclass.append(video);
      parent.appendChild(divclass);

      n.webkitGetUserMedia({ video: true, audio: true }, function (stream) {
        myonlyvideo.srcObject = stream;
        myonlyvideo.play();
        myonlyvideo.muted = true;

        console.log(stream);
        call.answer(stream);
        call.on('stream', function (remotestream) {

          console.log("adding stream");

          video.srcObject = remotestream;
          video.play();
          console.log(remotestream)
          //document.getElementById("videos").appendChild(document.createElement("video").srcObject=remotestream) ;


        })
      }, function (err) {
        console.log('Failed to get stream other', err);
      })
    })
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  addstream() {
    console.log("adding stream");
    document.getElementById("videos").appendChild(document.createElement("video").srcObject = localStorage.userid);


  }
  @ViewChild('onlymyvideo') onlymyvideo: any;

  connect() {
    var conn = this.peer.connect(this.anotherid);
    conn.on('open', function () {
      conn.send('Message from that id');
    });
  }

  // videoconnect(){
  //   let video = this.myvideo.nativeElement;
  //   var localvar = this.peer;
  //   var fname = this.anotherid;

  //   // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

  //   var n = <any>navigator;

  //    n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );

  //    n.getUserMedia({video: true, audio: true}, function(stream) {
  //     var call = localvar.call(fname, stream);
  //     call.on('stream', function(remotestream) {
  //       video.srcObject = remotestream;
  //       video.play();
  //     })
  //   }, function(err){
  //     console.log('Failed to get stream', err);
  //   })


  // }
}
