<!-- Search -->
<app-nav-bar></app-nav-bar>
<div class="col-md-12 row" *ngIf="SearchEnabled" style="padding: 0; margin-left: 0; margin-right: 0;">
    <div class="col-md-12 row" style="padding: 0; margin-left: 0; margin-right: 0;">


        <div class="col-md-3" style="justify-content: center;">
            <br /><br />

            <!-- <div class="card" style="    padding: 17px 0px 32px 38px;">
                <div class="card-body">
                    <h5 class="mb-0 text-primary font-weight-bold">45.5 GB <span class="float-end text-secondary">50
                            GB</span></h5>
                    <p class="mb-0 mt-2"><span class="text-secondary">Used</span><span
                            class="float-end text-primary">Upgrade</span>
                    </p>
                    <div class="progress mt-3" style="height:7px;">
                        <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 30%" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-danger" role="progressbar" style="width: 20%" aria-valuenow="20"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                </div>
            </div> -->


            <div style=" text-align: center;">

                <div style="width: 100%;">
                    <div class="btn btn-warning">
                        <span data-toggle="modal" data-target="#exampleModal" (click)="openmodal()">

                            Upload Object
                        </span>
                    </div><br><br>

                    <label class="btn btn-primary">
                        <b class="temp-upload-icon" for="excel-upload">
                            Upload Folder</b>

                        <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                            (change)="filesPicked(folderInput.files)" webkitDirectory />

                    </label>
                </div>
                <div><br>
                    <div id="moveid">
                        <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;"
                            (click)="MoveDash1()">
                            Move Here
                        </button>
                        <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i
                                class="fa fa-window-close" title="Cancel"
                                style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                        <br />
                    </div>
                    <div class="btn btn-info">
                        <div style="border: none; background: transparent; display: block;"
                            (click)="addSubFolder('allSubFolders', subfoldercontent)">
                            <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; "
                                (click)="addSubFolder(allSubFolders, subfoldercontent)">Create Sub Folder</b>
                        </div>
                    </div>
                    <div style="border: none; background: transparent; display: block;"
                        (click)="addDueDeligence('folderviewdeligence', adddeligence)">
                        <div class="btn btn-success" style="width:40%;"> <b class="temp-upload-icon" for="excel-upload"
                                style="cursor: pointer; padding: 0px;">Add Due Diligence</b>
                        </div>
                    </div>
                    <!-- <div *ngIf="role == 11" style="border: none; background: transparent; display: block;"
                        (click)="addnewFolder('folderview', addclient)">
                        <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;"
                            (click)="addnewFolder('folderview', addclient)">&nbsp;Add New Client</b>
                        <br>
                        <div class="btn btn-success"> <b class="temp-upload-icon" for="excel-upload"
                                style="cursor: pointer; " (click)="addnewFolder('folderview', addclient)">&nbsp;Add New
                                Client</b>
                        </div>
                    </div> -->

                </div>

            </div>


            <div id="treeview" *ngIf="!mobile" style="
          background-color: #ffffff;
          height: 50vh;
         
          margin-top: 30px;
          z-index: 1;
          overflow-y: scroll;
          overflow-x: hidden;
        ">
                <br />

                <div *ngIf="ppopup" style="margin-left: 20px;">
                    <div *ngFor="let a of SearchResult.folders" id="{{ a.folderID }}" style="
          white-space: nowrap;
          width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgb(0, 0, 0);
        ">
                        <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                                style="cursor: pointer;"></i>&nbsp;
                            <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                                style="cursor: pointer;" />
                            <span (click)="GetSubfolder(a)"
                                style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                                title="{{ a.FolderName }}">&nbsp;{{ a.FolderName }}</span></span>
                    </div>
                </div>
            </div>
        </div>





        <div class="col-md-9">


            <!-- ------my------ -->

            <div class="fm-search">
                <div class="mb-0">
                    <div class="input-group input-group-lg"> <span class="input-group-text bg-transparent"><i
                                class="fa fa-search"></i></span>
                        <input type="text" class="form-control" placeholder="Search..."
                            (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                    </div>
                </div>
            </div>

            <!-- ------my------ -->






            <div style="
      padding: 0;
    
      height: 70vh;
     
      vertical-align: top;
    ">

                <div class="table-responsive mt-3" style="  overflow-y: scroll;height: 66vh;">
                    <table class="table table-striped table-hover table-sm mb-0">

                        <thead style="color: #33383e;">

                            <th *ngIf="mob"></th>
                            <th *ngIf="!mob" style="width: 20px;"></th>
                            <th *ngIf="mob" style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                                Name
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th *ngIf="!mob" style="font-weight: bold; cursor: pointer; width: 30%;" (click)="sortby()">
                                Name
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th style="font-weight: bold; cursor: pointer;" *ngIf="mob" (click)="sortbydate()">
                                Modified
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th style="font-weight: bold;" *ngIf="mob">Type</th>
                            <th style="font-weight: bold;">Operations</th>

                            <!-- <th>Delete</th> -->
                        </thead>
                        <tbody *ngFor="let folderview of SearchResult.folders">
                            <tr>
                                <td *ngIf="mob" style="text-align: center;">
                                    <input type="checkbox" (click)="toggleSelect(folderview)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <td *ngIf="!mob" style="text-align: center; width: 20px;">
                                    <input type="checkbox" (click)="toggleSelect(folderview)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <td *ngIf="mob" style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
">
                                    <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                    <span (click)="GetSubfolder(folderview)" style="cursor: pointer;"
                                        title="{{ folderview.FolderName }}">{{ folderview.FolderName }}</span>
                                </td>
                                <td *ngIf="!mob" style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
">
                                    <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                    <span (click)="GetSubfolder(folderview)" style="cursor: pointer;"
                                        title="{{ folderview.FolderName }}">{{ folderview.FolderName }}</span>
                                </td>
                                <td *ngIf="mob">{{ folderview.CreatedOn | date: "short" }}</td>
                                <td *ngIf="mob">Object Folder</td>


                                <td *ngIf="!multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle
                                            style="cursor: pointer;"><i class="fa fa-gear" aria-hidden="true"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <a class="dropdown-item"
                                                (click)="ShareFolder(folderview, shareSingleFolder)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="DownloadFolder(folderview)"
                                                style="cursor: pointer;">Download</a>
                                            <a class="dropdown-item"
                                                (click)="UpdateSubFolder(folderview, editsubfolder)"
                                                style="cursor: pointer;">Edit</a>
                                            <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="Delete(folderview)"
                                                style="cursor: pointer;">Delete</a>

                                        </div>
                                    </div>

                                </td>

                                <td *ngIf="multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                                            <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)"
                                                style="cursor: pointer;">Delete</a>
                                            <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="CheckDownloadMultiple()"
                                                style="cursor: pointer;">Download</a>
                                            <!-- <a class="dropdown-item" (click)="EsignDocumentView(ret)" style="cursor: pointer;">E_Signature</a> -->

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngFor="let ret of SearchResult.files">
                            <tr>
                                <td *ngIf="mob">
                                    <input type="checkbox" (click)="toggleSelect(ret)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <td *ngIf="!mob" style="width: 18px;">
                                    <input type="checkbox" (click)="toggleSelect(ret)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>

                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'image/jpeg' ||
ret.DocumentType == 'image/png') &&
mob
">
                                    <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                                        (click)="ViewDoc(ret)" />
                                    <span (click)="ViewDoc(ret)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'application/pdf' ||
ret.DocumentType == 'pdf') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.DocumentType == 'xlsx') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.DocumentType == 'ppt' ||
ret.DocumentType == 'pptx') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/ppticon2.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'video/mp4' ||
ret.DocumentType == 'mp4') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.DocumentType == 'doc' ||
ret.DocumentType == 'docx') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/docx.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'application/x-zip-compressed' ||
ret.DocumentType == 'zip') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'text/plain' ||
ret.DocumentType == 'txt') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>

                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'audio/mp3' ||
ret.DocumentType == 'mp3') &&
mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>

                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'image/jpeg' ||
ret.DocumentType == 'image/png') &&
!mob
">
                                    <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                                        (click)="ViewDoc(ret)" />
                                    <span (click)="ViewDoc(ret)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'application/pdf' ||
ret.DocumentType == 'pdf') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.DocumentType == 'xlsx') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.DocumentType == 'ppt' ||
ret.DocumentType == 'pptx') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/ppticon2.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'video/mp4' ||
ret.DocumentType == 'mp4') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.DocumentType == 'doc' ||
ret.DocumentType == 'docx') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/docx.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'application/x-zip-compressed' ||
ret.DocumentType == 'zip') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'text/plain' ||
ret.DocumentType == 'txt') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>

                                <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'audio/mp3' ||
ret.DocumentType == 'mp3') &&
!mob
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDoc(ret)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td [hidden]="
ret.DocumentType == 'text/plain' ||
ret.DocumentType == 'txt' ||
ret.DocumentType == 'application/x-zip-compressed' ||
ret.DocumentType == 'zip' ||
ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.DocumentType == 'doc' ||
ret.DocumentType == 'docx' ||
ret.DocumentType == 'video/mp4' ||
ret.DocumentType == 'mp4' ||
ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.DocumentType == 'ppt' ||
ret.DocumentType == 'pptx' ||
ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.DocumentType == 'xlsx' ||
ret.DocumentType == 'application/pdf' ||
ret.DocumentType == 'image/jpeg' ||
ret.DocumentType == 'image/png' ||
ret.DocumentType == 'pdf' ||
ret.DocumentType == 'audio/mp3' ||
ret.DocumentType == 'mp3'
" (click)="ViewDoc(ret)">
                                    <img src="assets/img/anyf1.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span> &nbsp;&nbsp;{{ ret.DocumentName }} </span>
                                </td>

                                <td *ngIf="mob">{{ ret.date | date: "short" }}</td>
                                <td *ngIf="mob">{{ ret.DocumentType }}</td>
                                <td *ngIf="!multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle
                                            style="cursor: pointer;"><i class="fa fa-gear" aria-hidden="true"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">

                                            <a class="dropdown-item" (click)="MoveDoc(ret, 'Img')"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="version(ret)"
                                                style="cursor: pointer;">Version</a>
                                            <a class="dropdown-item" (click)="Delete(ret)"
                                                style="cursor: pointer;">Delete</a>
                                            <a class="dropdown-item" (click)="ShareoneFile(ret, shareSingleFile)"
                                                style="cursor: pointer;">Share</a>


                                        </div>
                                    </div>

                                </td>

                                <td *ngIf="multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                                                style="cursor: pointer;" aria-hidden="true"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)"
                                                style="cursor: pointer;">Delete</a>
                                            <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)"
                                                style="cursor: pointer;">Move</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>




    </div>
</div>

<!-- Notify -->
<div class="col-md-12 row" *ngIf="foladdednotify1">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 245px;
        position: fixed;
        top: 500px;
        width: 350px;
        height: 50px;
        border-radius: 10px;
        text-align: center;
        z-index: 10000;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: rgb(0, 0, 0);">close</i>
            </button>
            <span style="color: rgb(0, 0, 0); margin-top: 7px;">
                <b style="
            text-transform: capitalize;
            font-size: 14px;
            line-height: 50px;
          ">
                    {{ notify }}</b></span>
        </div>
    </div>
</div>


<div class="col-md-12" *ngIf="!SearchEnabled && gridvalue" style="padding: 0;">

    <div class="col-md-12 row" style="margin: 0; padding: 0;">
        <div class="col-md-3" style="justify-content: center;">
            <br /><br />
            <!-- 
            <div class="card" style="    padding: 17px 0px 32px 38px;">
                <div class="card-body">
                    <h5 class="mb-0 text-primary font-weight-bold">45.5 GB <span class="float-end text-secondary">50
                            GB</span></h5>
                    <p class="mb-0 mt-2"><span class="text-secondary">Used</span><span
                            class="float-end text-primary">Upgrade</span>
                    </p>
                    <div class="progress mt-3" style="height:7px;">
                        <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 30%" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-danger" role="progressbar" style="width: 20%" aria-valuenow="20"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                </div>
            </div> -->
            <div style=" text-align: center;">
                <br />

                <div style="z-index: 5;">
                    <div id="view">
                        <div class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" style="width:42%">

                            <b>Upload Object</b>
                        </div>
                        <br /><br />
                        <div id="moveid">
                            <button type="button" class="btn" style="padding: 7px; cursor: pointer;"
                                (click)="MoveDash1()">
                                Move Here
                            </button>
                            <span (click)="cancel()" style="margin-top: 10px;"><i class="fa fa-window-close"
                                    title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                        </div>

                        <div class="btn btn-info" style="width:42%">

                            <label>
                                <b class="temp-upload-icon" for="excel-upload">Upload
                                    Folder</b>

                                <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                                    (change)="filesPicked(folderInput.files)" webkitDirectory />

                            </label>
                        </div>

                        <br />
                        <br />


                        <div class="btn btn-success" (click)="addSubFolder(allSubFolders, subfoldercontent)">
                            Create Sub Folder
                        </div> <br />
                        <br />
                        <!-- <div class="btn btn-danger" *ngIf="role == 1">
                            <div  (click)="addnewFolder('folderview', addclient)">
                                Add New Client
                            </div>
                        </div> -->
                        <div style="border: none; background: transparent; display: block;"
                            (click)="addDueDeligence('folderviewdeligence', adddeligence)">
                            <div class="btn btn-success" style="width:40%;"> <b class="temp-upload-icon"
                                    for="excel-upload" style="cursor: pointer; padding: 0px;">Add Due Diligence</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /> <br />
            <div id="treeview" *ngIf="!mobile" style="
        background-color: #ffffff;
        height: 64vh;
        
        z-index: 1;
        overflow-y: scroll;
        overflow-x: hidden;
      " id="treeview">
                <div *ngIf="ppopup" style="margin-left: 5px;">
                    <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
            white-space: nowrap;
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgb(0, 0, 0);
          ">
                        <span><i class="fa fa-caret-right" (click)="getfolders1(a)" style="cursor: pointer;"
                                id="{{ a.id }}"></i>
                            <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)" />
                            <span (click)="GetSubfolder(a)"
                                style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                                title="{{ a.folderName }}">{{ a.folderName }}</span></span>
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="mobile" >
                <div style="width: 100%;">
                    <div style="width: 60%;float: left;">
                
                        <span data-toggle="modal" data-target="#exampleModal" style="
              z-index: 100;
              border: none;
              background: transparent;
              display: block;
              font-size: 16px;
            " (click)="openmodal()">

            <b>Upload Object</b>
          </span>
                        <label style="
              z-index: 100;
              font-size: 16px;
              border: none;
              background: transparent;
              display: block;
            ">
            <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">

              Upload Folder</b>

            <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
              (change)="filesPicked(folderInput.files)" webkitDirectory />
         
          </label>
                    </div>
                    <div style="width: 40%; float: right;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
              Move Here
            </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>

                        <div style="border: none; background: transparent; display: block;">
                            <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;" (click)="addSubFolder(allSubFolders, subfoldercontent)">&nbsp;Create Sub Folder</b>
                        </div>
                        <div *ngIf="role == 1" style="border: none; background: transparent; display: block;">
                            <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;" (click)="addnewFolder('folderview', addclient)">&nbsp;Add New Client</b>
                        </div>

                    </div>
                </div>
            </div> -->

        </div>
        <div class="col-md-9">
            <div class="card">
                <div class="card-body">

                    <div class="fm-search">
                        <div class="mb-0">
                            <div class="input-group input-group-lg"> <span class="input-group-text bg-transparent"><i
                                        class="fa fa-search"></i></span>
                                <input type="text" class="form-control" placeholder="Search..."
                                    (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <div class="d-flex align-items-center">
                        <div style="
                      
                        height: 30px;
                        margin-top: 20px;
                        padding: 0;
                        padding-left: 15px;
                      ">
                            <i class="fa fa-folder" style="color: rgb(84 118 254);"></i><span style="cursor: pointer;"
                                (click)="gotoallfolders()">&nbsp;My Objects&nbsp;
                            </span>
                            <span *ngFor="let d of pathdt">
                                <i class="fa fa-folder" style="color: rgb(84 118 254);"></i>
                                <span (click)="GetDetails(d.folderid)" style="cursor: pointer;">&nbsp;{{ d.foldername
                                    }}</span>
                            </span>
                        </div>


                    </div>
                    <div style="padding: 0; overflow-y: scroll; height: 120vh;">

                        <div class="col-md-12 text-right" style="height: 20px;">
                            <button (click)="open(shareall)" type="submit" class="btn btn-primary">
                                Share All
                            </button> &nbsp;
                            <button (click)="sortby()" style="background: transparent; border: none; cursor: pointer;">
                                Name
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </button> &nbsp;
                            <button (click)="sortbydate()"
                                style="background: transparent; border: none; cursor: pointer;">
                                Date
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </button> &nbsp;
                            <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);"
                                title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                            <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);" (click)="gridview(1)"
                                aria-hidden="true" title="Grid View"></i>
                        </div> <br><br><br>
                        <div class="col-md-12 row" style=" margin-top: 20px;  overflow: hidden;
                       ">
                            <div class="column col-md-3" *ngFor="let folderview of allSubFolders.SubFolders">
                                <div class="show-image" style="    text-align: center;">


                                    <div id="menuContainer">
                                        <div class="menuItem first">
                                            <div class="dropdown">

                                                <i class="fa-4x fa fa-folder-open" aria-hidden="true"
                                                    style="left: 0; cursor: pointer; color: rgb(84 118 254);"
                                                    (click)="GetSubfolderdetails(folderview)"></i>



                                                <div id="foldername" style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
          
                text-overflow: ellipsis;
                cursor: pointer;
              " title="{{ folderview.folderName }}" (click)="GetSubfolderdetails(folderview)">
                                                    {{ folderview.folderName }}
                                                </div>


                                            </div>
                                        </div>



                                        <div id="settingsMenu">
                                            <div ngbDropdown class="dropdown" placement="bottom-right">
                                                <a class="nav-link" id="dropdownBasic1"
                                                    style="cursor: pointer;    text-align: right;" ngbDropdownToggle><i
                                                        class="fa fa-gear" aria-hidden="true"></i></a>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                    class="dropdown-danger">

                                                    <a class="dropdown-item"
                                                        (click)="UpdateSubFolder(folderview, editsubfolder)"
                                                        style="cursor: pointer;" data-target="#basicExample"
                                                        style="cursor: pointer;" data-toggle="modal">Edit</a>
                                                    <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')"
                                                        style="cursor: pointer;">Move</a>

                                                    <a class="dropdown-item" (click)="Delete(folderview)"
                                                        style="cursor: pointer;" data-toggle="modal"
                                                        data-target="#deleteExample">Delete</a>
                                                    <a class="dropdown-item"
                                                        (click)="Details(folderview, folderdetails)"
                                                        data-target="#Details" data-toggle="modal"
                                                        style="cursor: pointer;">Details</a>



                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <br />
                                <br />
                            </div>

                            <div class="column col-md-3" *ngFor="let ret of allSubFolders.RetData"
                                style="    margin-bottom: 42px;">
                                <input type="checkbox" style="height: 16px;" *ngIf="shareitem" (click)="toggle(ret)" />
                                <input type="checkbox" style="height: 16px;" *ngIf="deleteitem"
                                    (click)="toggleDelete(ret)" />
                                <div class="show-image" style="cursor: pointer;text-align: center;">

                                    <div id="menuContainer">
                                        <div class="menuItem first">
                                            <div *ngIf="
                                            ret.DocumentType == 'image'
                        " (click)="ViewImageDoc(ret,content)">
                                                <img src="{{ ret.file }}" style="cursor: pointer;     width: 20%;
                                        height: 50px;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                                            ret.DocumentType == 'bak' 
                                          " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/bak.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                                              white-space: nowrap;
                                              width: auto;
                                              overflow: hidden;
                                              text-overflow: ellipsis;
                                              cursor: pointer;
                                            " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                                                              ret.DocumentType == 'exe' 
                                                            " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/exe.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                                                                white-space: nowrap;
                                                                width: auto;
                                                                overflow: hidden;
                                                                text-overflow: ellipsis;
                                                                cursor: pointer;
                                                              " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                                                                                ret.DocumentType == 'sql.jpg' 
                                                                              " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/bak.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                                                                                  white-space: nowrap;
                                                                                  width: auto;
                                                                                  overflow: hidden;
                                                                                  text-overflow: ellipsis;
                                                                                  cursor: pointer;
                                                                                " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                                            ret.DocumentType == 'html' 
                                          " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/html.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                                              white-space: nowrap;
                                              width: auto;
                                              overflow: hidden;
                                              text-overflow: ellipsis;
                                              cursor: pointer;
                                            " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                                            ret.DocumentType == 'other' 
                                          " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/bak11.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                                              white-space: nowrap;
                                              width: auto;
                                              overflow: hidden;
                                              text-overflow: ellipsis;
                                              cursor: pointer;
                                            " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType == 'application/pdf' ||
                          ret.DocumentType == 'pdf'
                        " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/pdf.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType == 'audio/mp3' || ret.DocumentType == 'mp3'
                        " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/video.png" style="cursor: pointer;  width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType ==
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                          ret.DocumentType == 'xlsx' ||ret.DocumentType =='application/octet-stream'
                           || ret.DocumentType == 'xls'
                        " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/Excel.png" style="cursor: pointer; width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType ==
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                          ret.DocumentType == 'pptx' ||
                          ret.DocumentType == 'ppt'
                        " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/ppt.png" style="cursor: pointer;  width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType == 'video/mp4' || ret.DocumentType == 'mp4'
                        " (click)="ViewVideo(ret,videocontent)">
                                                <img src="assets/img/nvideo.png"
                                                    style="cursor: pointer;  width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType ==
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          ret.DocumentType == 'application/octet-stream' ||
                          ret.DocumentType == 'doc' ||
                          ret.DocumentType == 'docx'
                        " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/word.jpg" style="cursor: pointer;  width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                            <div *ngIf="
                          ret.DocumentType == 'text/plain' || ret.DocumentType == 'txt'
                        " (click)="ViewDocument(ret,pdftesting)">
                                                <img src="assets/img/textt.png" style="cursor: pointer;  width: 26%;" />

                                                <div id="foldername" style="
                            white-space: nowrap;
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                          " title="{{ ret.DocumentName }}">
                                                    {{ ret.DocumentName }}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="settingsMenu">
                                            <div ngbDropdown class="dropdown" placement="bottom-right">
                                                <a class="nav-link" id="dropdownBasic1"
                                                    style="cursor: pointer;    text-align: right;" ngbDropdownToggle><i
                                                        class="fa fa-gear" aria-hidden="true"></i></a>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                                    class="dropdown-danger">
                                                    <div class="dropdown-content">
                                                        <a class="dropdown-item" (click)="MoveDoc(ret, 'Img')"
                                                            style="cursor: pointer;">Move</a>
                                                        <a class="dropdown-item" (click)="version(ret)"
                                                            style="cursor: pointer;">Version</a>
                                                        <a class="dropdown-item" (click)="Delete(ret)"
                                                            style="cursor: pointer;" data-toggle="modal"
                                                            data-target="#deleteExample">Delete</a>
                                                        <a class="dropdown-item"
                                                            (click)="Details(ret, folderdetails)">Details</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- LIST VIEW -->
<div class="col-md-12 row" *ngIf="!SearchEnabled && listvalue" style="padding: 0; margin-left: 0; margin-right: 0;">
    <div class="col-md-12 row" style="padding: 0; margin-left: 0; margin-right: 0;">

        <div class="col-md-3" style="justify-content: center;">
            <br /><br />

            <!-- <div class="card" style="    padding: 17px 0px 32px 38px;">
                <div class="card-body">
                    <h5 class="mb-0 text-primary font-weight-bold">45.5 GB <span class="float-end text-secondary">50
                            GB</span></h5>
                    <p class="mb-0 mt-2"><span class="text-secondary">Used</span><span
                            class="float-end text-primary">Upgrade</span>
                    </p>
                    <div class="progress mt-3" style="height:7px;">
                        <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 30%" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar bg-danger" role="progressbar" style="width: 20%" aria-valuenow="20"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                </div>
            </div> -->
            <div style=" text-align: center;">

                <div style="z-index: 5;">
                    <div id="view">
                        <div class="btn btn-primary" style="width: 42%;">
                            <label (click)="openmodal()">
                                <span style="cursor: pointer; padding: 0px; z-index: 100;">Upload Object</span> </label>
                        </div>

                        <div id="moveid">
                            <button type="button" class="btn" style="padding: 7px; cursor: pointer;"
                                (click)="MoveDash1()">
                                Move Here
                            </button>
                            <span (click)="cancel()" style="margin-top: 10px;"><i class="fa fa-window-close"
                                    title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                        </div>
                        <br /> <br />

                        <div class="btn btn-info" style="width: 42%;">

                            <label>
                                <b class="temp-upload-icon" for="excel-upload">Upload
                                    Folder</b>

                                <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                                    (change)="filesPicked(folderInput.files)" webkitDirectory />

                            </label>
                        </div>




                        <br /> <br />

                        <div class="btn btn-success">
                            <div (click)="addSubFolder(allSubFolders, subfoldercontent)">
                                Create Sub Folder
                            </div>
                        </div> <br /> <br />

                        <!-- <div class="btn btn-danger" *ngIf="role == 1" style="width: 42%;color: white;">
                            <div  (click)="addnewFolder('folderview', addclient)">
                                Add New Client
                            </div>

                        </div> -->
                        <div style="border: none; background: transparent; display: block;"
                            (click)="addDueDeligence('folderviewdeligence', adddeligence)">
                            <div class="btn btn-success" style="width:40%;"> <b class="temp-upload-icon"
                                    for="excel-upload" style="cursor: pointer; padding: 0px;">Add Due Diligence</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br />
            <div id="treeview" *ngIf="!mobile" style="
        background-color: #ffffff;
        height:70vh;
        margin-top: -5px;
        z-index: 1;
        overflow-y: scroll;
        overflow-x: hidden;
      ">
                <br />

                <div *ngIf="ppopup" style="margin-left: 20px;">
                    <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
            white-space: nowrap;
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgb(0, 0, 0);
          ">
                        <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                                style="cursor: pointer;"></i>&nbsp;
                            <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                                style="cursor: pointer;" />
                            <span (click)="GetSubfolder(a)"
                                style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                                title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                    </div>
                </div>
            </div>



        </div>

        <div class="col-md-9">
            <br>
            <br>
            <!-- ------my------ -->

            <div class="fm-search">
                <div class="mb-0">
                    <div class="input-group input-group-lg"> <span class="input-group-text bg-transparent"><i
                                class="fa fa-search"></i></span>
                        <input type="text" class="form-control" placeholder="Search..."
                            (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                    </div>
                </div>
            </div>

            <!-- ------my------ -->




            <div class="col-md-12 row">&nbsp;</div>
            <i class="fa fa-folder" style="color: rgb(84 118 254);"></i><span style="cursor: pointer;"
                (click)="gotoallfolders()">&nbsp;My Objects&nbsp;
            </span>
            <span *ngFor="let d of pathdt">
                <i class="fa fa-folder" style="color: rgb(84 118 254);"></i>
                <span (click)="GetDetails(d.folderid)" style="cursor: pointer;">&nbsp;{{ d.foldername }}</span>
            </span>
            <div style="
        padding: 0;
        /* overflow-y: scroll; */
        height: 95vh;
        white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
        vertical-align: top;
      ">

                <div class="table-responsive mt-3" style="height:95vh">
                    <table class="table table-striped table-hover table-sm mb-0">
                        <thead style="color: #33383e;">
                            <!-- <th *ngIf="shareitem"><i class="fa fa-times" style="font-size: 33px;color: red;margin-left: -13px;" (click)="CancelShare()"></i></th>
                    <th *ngIf="deleteitem"><i class="fa fa-times" style="font-size: 33px;color: red;margin-left: -13px;" (click)="CancelDelete()"></i></th> -->
                            <th *ngIf="mob" style="text-align: center;"><button (click)="open(shareall)" type="submit"
                                    class="btn btn-primary">Share All</button></th>
                            <th *ngIf="!mob" style="width: 20px;text-align: center;"><button (click)="open(shareall)"
                                    type="submit" class="btn btn-primary text-center">Share All</button></th>

                            <th *ngIf="mob" style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                                Name
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th *ngIf="!mob" style="font-weight: bold; cursor: pointer; width: 60%;" (click)="sortby()">
                                Name
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th style="font-weight: bold; cursor: pointer;" *ngIf="mob" (click)="sortbydate()">
                                Modified
                                <span class="fa fa-stack">
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                                </span>
                            </th>
                            <th style="font-weight: bold;" *ngIf="mob">Type</th>
                            <th style="font-weight: bold;">Operations</th>

                            <!-- <th>Delete</th> -->
                        </thead>
                        <tbody *ngFor="let folderview of allSubFolders.SubFolders">
                            <tr>
                                <td *ngIf="mob" style="text-align: center;">
                                    <input type="checkbox" (click)="toggleSelect(folderview)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <td *ngIf="!mob" style="text-align: center; width: 20px;">
                                    <input type="checkbox" (click)="toggleSelect(folderview)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <td *ngIf="mob" style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                ">
                                    <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> &nbsp;&nbsp;
                                    <span (click)="GetSubfolder(folderview)" style="cursor: pointer;"
                                        title="{{ folderview.FolderName }}">{{ folderview.FolderName }}</span>
                                </td>
                                <td *ngIf="!mob" style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                ">
                                    <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> &nbsp;&nbsp;
                                    <span (click)="GetSubfolder(folderview)" style="cursor: pointer;"
                                        title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                </td>
                                <td *ngIf="mob">{{ folderview.createdOn | date: "short" }}</td>
                                <td *ngIf="mob">Object Folder</td>

                                <!-- <td><input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(folderview)" />
                                </td> -->
                                <td *ngIf="!multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle
                                            style="cursor: pointer;"><i class="fa fa-gear" aria-hidden="true"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <a class="dropdown-item"
                                                (click)="ShareFolder(folderview, shareSingleFolder)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="DownloadFolder(folderview)"
                                                style="cursor: pointer;">Download</a>
                                            <a class="dropdown-item"
                                                (click)="UpdateSubFolder(folderview, editsubfolder)"
                                                style="cursor: pointer;">Edit</a>
                                            <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="Delete(folderview)"
                                                style="cursor: pointer;">Delete</a>
                                            <!-- <div class="dropdown-divider"></div>
                                    <a class="dropdown-item">Separated link</a> -->
                                        </div>
                                    </div>
                                    <!-- <input class="update btn btn-info" type="button" value="Edit"
                                (click)="UpdateSubFolder(folderview,editsubfolder)" /> -->
                                </td>

                                <td *ngIf="multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                                            <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)"
                                                style="cursor: pointer;">Delete</a>
                                            <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="CheckDownloadMultiple()"
                                                style="cursor: pointer;">Download</a>
                                            <!-- <a class="dropdown-item" (click)="EsignDocumentView(ret)" style="cursor: pointer;">E_Signature</a> -->

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngFor="let ret of allSubFolders.RetData">
                            <tr>
                                <td *ngIf="mob" style="text-align: center;">
                                    <input type="checkbox" (click)="toggleSelect(ret)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <td *ngIf="!mob" style="width: 18px;text-align: center;">
                                    <input type="checkbox" (click)="toggleSelect(ret)"
                                        style="height: 16px; width: 16px; cursor: pointer;" />
                                </td>
                                <input type="checkbox" *ngIf="shareitem" (click)="toggle(ret)" style="
                  height: 16px;
                  width: 16px;
                  margin-top: 28px;
                  cursor: pointer;
                " />
                                <input type="checkbox" *ngIf="deleteitem" (click)="toggleDelete(ret)" style="
                  height: 16px;
                  width: 16px;
                  margin-top: 28px;
                  cursor: pointer;
                " />
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                 (ret.DocumentType == 'image') &&
                  mob
                ">
                                    <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                                        (click)="ViewImageDoc(ret,content)" />
                                    <span (click)="ViewImageDoc(ret,content)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'bak') &&
                                mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/bak.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'other') &&
                                mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/bak11.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'exe') &&
                                mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/exe.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'sql') &&
                                mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/sql.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'html') &&
                                mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/html.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType == 'application/pdf' ||
                    ret.DocumentType == 'pdf') &&
                  mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span style="cursor: pointer;" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    ret.DocumentType == 'xlsx' ||ret.DocumentType =='application/octet-stream' || 
                    ret.DocumentType == 'xls') &&
                  mob
                " (click)="ViewxlsDoc(ret,pdftesting)">
                                    <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewxlsDoc(ret,pdftesting)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                    ret.DocumentType == 'ppt' ||
                    ret.DocumentType == 'pptx') &&
                  mob
                " (click)="ViewPpt(ret,pdftesting)">
                                    <img src="assets/img/ppticon2.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewPpt(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType == 'video/mp4' ||
                    ret.DocumentType == 'mp4') &&
                  mob
                " (click)="ViewVideo(ret,videocontent)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewVideo(ret,videocontent)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    ret.DocumentType == 'doc' ||
                    ret.DocumentType == 'docx') &&
                  mob
                " (click)="ViewDocumentword(ret,pdftesting)">
                                    <img src="assets/img/docx.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocumentword(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType == 'application/x-zip-compressed' ||
                    ret.DocumentType == 'zip') &&
                  mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType == 'text/plain' ||
                    ret.DocumentType == 'txt') &&
                  mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>

                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                " *ngIf="
                  (ret.DocumentType == 'audio/mp3' ||
                    ret.DocumentType == 'mp3') &&
                  mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>

                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'image') &&
                  !mob
                ">
                                    <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                                        (click)="ViewImageDoc(ret,content)" />
                                    <span (click)="ViewImageDoc(ret,content)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'bak') &&
                                !mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/bak.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'other') &&
                                !mob
                              " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/bak11.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                                white-space: nowrap;
                                width: auto;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                width: 70%;
                              " *ngIf="
                                (ret.DocumentType == 'exe') &&
                                !mob
                              " (click)="ViewDocument(ret,pdftesting)">
                      <img src="assets/img/exe.png" style="width: 35px; height: 34px; cursor: pointer;" />
                      <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                          &nbsp;&nbsp;{{ ret.DocumentName }}
                      </span>
                  </td>
                  <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'sql') &&
                  !mob
                " (click)="ViewDocument(ret,pdftesting)">
                      <img src="assets/img/sql.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                      <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                          &nbsp;&nbsp;{{ ret.DocumentName }}
                      </span>
                  </td>
                  <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'html') &&
                  !mob
                " (click)="ViewDocument(ret,pdftesting)">
                      <img src="assets/img/html.png" style="width: 35px; height: 34px; cursor: pointer;" />
                      <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                          &nbsp;&nbsp;{{ ret.DocumentName }}
                      </span>
                  </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'application/pdf' ||
                    ret.DocumentType == 'pdf') &&
                  !mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    ret.DocumentType == 'xlsx' ||ret.DocumentType =='application/octet-stream' ||
                    ret.DocumentType == 'xls') &&
                  !mob
                " (click)="ViewxlsDoc(ret,pdftesting)">
                                    <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewxlsDoc(ret,pdftesting)" style="cursor: pointer;"
                                        title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                    ret.DocumentType == 'ppt' ||
                    ret.DocumentType == 'pptx') &&
                  !mob
                " (click)="ViewPpt(ret,pdftesting)">
                                    <img src="assets/img/ppticon2.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewPpt(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'video/mp4' ||
                    ret.DocumentType == 'mp4') &&
                  !mob
                " (click)="ViewVideo(ret,videocontent)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewVideo(ret,videocontent)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                    ret.DocumentType == 'doc' ||
                    ret.DocumentType == 'docx') &&
                  !mob
                " (click)="ViewDocumentword(ret,pdftesting)">
                                    <img src="assets/img/docx.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocumentword(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'application/x-zip-compressed' ||
                    ret.DocumentType == 'zip') &&
                  !mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'text/plain' ||
                    ret.DocumentType == 'txt') &&
                  !mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>

                                <td style="
                  white-space: nowrap;
                  width: auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  width: 70%;
                " *ngIf="
                  (ret.DocumentType == 'audio/mp3' ||
                    ret.DocumentType == 'mp3') &&
                  !mob
                " (click)="ViewDocument(ret,pdftesting)">
                                    <img src="assets/img/video.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
                                        &nbsp;&nbsp;{{ ret.DocumentName }}
                                    </span>
                                </td>
                                <!--...... <td [hidden]="
                  ret.DocumentType == 'text/plain' ||
                  ret.DocumentType == 'txt' ||
                  ret.DocumentType == 'application/x-zip-compressed' ||
                  ret.DocumentType == 'zip' ||
                  ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  ret.DocumentType == 'doc' ||
                  ret.DocumentType == 'docx' ||
                  ret.DocumentType == 'video/mp4' ||
                  ret.DocumentType == 'mp4' ||
                  ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  ret.DocumentType == 'ppt' ||
                  ret.DocumentType == 'pptx' ||
                  ret.DocumentType ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  ret.DocumentType == 'xlsx' || ret.DocumentType =='application/octet-stream' ||
                  ret.DocumentType == 'application/pdf' ||
                  ret.DocumentType == 'image/jpeg' ||
                  ret.DocumentType == 'image/png' ||
                  ret.DocumentType == 'pdf' ||
                  ret.DocumentType == 'audio/mp3' ||
                  ret.DocumentType == 'mp3'
                ">
                                    <img src="assets/img/anyf1.png"
                                        style="width: 35px; height: 34px; cursor: pointer;" />
                                    <span> &nbsp;&nbsp;{{ ret.DocumentName }} </span>
                                </td> ......-->

                                <td *ngIf="mob">{{ ret.date | date: "short" }}</td>
                                <td *ngIf="mob">{{ ret.DocumentType }}</td>
                                <td *ngIf="!multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle
                                            style="cursor: pointer;"><i class="fa fa-gear" aria-hidden="true"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <!-- <a class="dropdown-item"  (click)="UpdateSubFolder(folderview,editsubfolder)">Edit</a> -->
                                            <a class="dropdown-item" (click)="MoveDoc(ret, 'Img')"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="version(ret)"
                                                style="cursor: pointer;">Version</a>
                                            <a class="dropdown-item" (click)="Delete(ret)"
                                                style="cursor: pointer;">Delete</a>
                                            <a class="dropdown-item" (click)="ShareoneFile(ret, shareSingleFile)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="DownloadFile(ret)"
                                                style="cursor: pointer;">Download</a>
                                            <a class="dropdown-item" (click)="EsignDocumentView(ret)"
                                                style="cursor: pointer;">E_Signature</a>
                                            <a class="dropdown-item" style="cursor: pointer;">File
                                                Size:{{ret.DocumentSize}}KB</a>
                                            <!-- <div class="dropdown-divider"></div>
                                    <a class="dropdown-item">Separated link</a> -->
                                        </div>
                                    </div>
                                    <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
                                </td>

                                <td *ngIf="multi">
                                    <div ngbDropdown class="dropdown" placement="bottom-left">
                                        <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                                                style="cursor: pointer;" aria-hidden="true"></i></a>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                            <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)"
                                                style="cursor: pointer;">Delete</a>
                                            <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)"
                                                style="cursor: pointer;">Share</a>
                                            <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)"
                                                style="cursor: pointer;">Move</a>
                                            <a class="dropdown-item" (click)="CheckDownloadMultiple()"
                                                style="cursor: pointer;">Download</a>
                                            <a class="dropdown-item" style="cursor: pointer;">File
                                                Size:{{ret.DocumentSize}}KB</a>

                                            <!-- <a class="dropdown-item" (click)="EsignDocumentView(ret)" style="cursor: pointer;">E_Signature</a> -->

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    </div>
</div>

<button type="button" id="openm" data-toggle="modal" data-target="#exampleModal" style="display: none;">
    <span style="
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #edf3ff;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 25px;
    "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
</button>

<button type="button" id="ppup" data-toggle="modal" data-target="#exampleModal213" style="display: none;">
    <span style="
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #edf3ff;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 25px;
    "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
</button>


<ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Select Members to Share</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
        <div>
            <div id="aboveTagInp">
                <!--...... <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test"
                    [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                    [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">

                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                        [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'"
                        [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.friendName }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input> ......-->
                <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="frndslistnames"
                [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
              </ng-multiselect-dropdown>

            </div>
        </div>
        <br />
    </div>
    <!-- <div class="modal-body" style="font-size: 16px;">
        <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
            {{ mem.friendName }}<br />
        </div>
        <div class="col-lg-12 row">
            <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
                <option value=""> Select Friend </option>
                <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
                    {{ I.friendName }}
                </option>
            </select>
        </div>
        <br />
    </div> -->
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()"
            style="cursor: pointer;" (click)="d('Cross click')">
            Share
        </button>
    </div>
    <br />
</ng-template>
<ng-template #folderdetails let-d="dismiss" let-c="dismiss">
    <div *ngIf="Hash">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600; width: 100%;">
                <b>File Details</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="font-size: 16px;">
            <div class="" *ngIf="Hash" style="text-align: left;">
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row">
                    <div class="col-md-6">DocumentName:</div>
                    <div class="col-md-6" style="color: blue; cursor: pointer;">
                        {{ DocumentName }}
                    </div>
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-6">Document Type:</div>
                    <div class="col-md-6" style="color: blue;">{{ ImageType }}</div>
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-6">Document Size:</div>
                    <div class="col-md-6" style="color: blue;">{{ docsize1 }}&nbsp;</div>
                </div>
                <!-- <div class="col-md-12 row">
              <div class="col-md-6">CustomerId: </div>
              <div class="col-md-6" style="color: blue;"> {{CustId}}</div>
            </div> -->
                <div class="col-md-12 row">
                    <div class="col-md-6">Created On:</div>
                    <div class="col-md-6" style="color: blue;">
                        {{ DateCreated | date: "short" }}
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-12 row">
        <div class="col-md-6">CustomerId : </div>
        <div class="col-md-6" style="color: blue;"> {{CusId}}</div>
      </div> -->
            <br />
        </div>
    </div>
    <div *ngIf="!Hash">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Folder Details</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="font-size: 16px;">
            <div class="" *ngIf="!Hash">
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-6">Folder Name :</div>
                    <div class="col-md-6" style="color: blue;" style="cursor: pointer;">
                        {{ FolderName }}
                    </div>
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-6">Created On :</div>
                    <div class="col-md-6" style="color: blue;">
                        {{ CreatedOn | date: "short" }}
                    </div>
                </div>
                <!-- <div class="col-md-12 row">
                  <div class="col-md-2"></div>
                  <div class="col-md-4">CustomerId : </div>
                  <div class="col-md-6" style="color: blue;"> {{CusId}}</div>
                </div> -->
            </div>
            <!-- <div class="col-md-12 row">
            <div class="col-md-6">CustomerId : </div>
            <div class="col-md-6" style="color: blue;"> {{CusId}}</div>
          </div> -->
            <br />
        </div>
    </div>
    <br />

    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="d('Cross click')">
            Close
        </button>
    </div>
    <br />
</ng-template>

<ng-template #subfoldercontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Sub Folder</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
        <input type="text" id="msg" [(ngModel)]="msg" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="savenewSubFolder()" (click)="d('Cross click')"
            style="cursor: pointer;">
            Add
        </button>
    </div>
    <br />
</ng-template>
<ng-template #adddeligence let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Due Diligence</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" *ngIf="isLoggedIn">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <h5 class="modal-title" id="modal-basic-title"> Enter the Name:</h5>
        <input type="text" id="ddname" [(ngModel)]="dname" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="saveduedeligence()" (click)="d('Cross click')"
            style="cursor: pointer;">
            Add Due Diligence
        </button>
    </div>
    <br />
</ng-template>
<ng-template #sharefileviamailcontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Share</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">From Mail:</h5>
        <input type="text" id="usermail" [(ngModel)]="usermail" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Password:</h5>
        <input type="password" id="frmmailpwd" [(ngModel)]="frmmailpwd" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">To Mail:</h5>
        <input type="text" id="tomail" [(ngModel)]="tomail" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Message</h5>
        <input type="text" id="mailmsg" [(ngModel)]="mailmsg" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="ShareFileViaMail()" style="color: black;">
            Send
        </button>
    </div>
    <br />
</ng-template>

<ng-template #importmailcontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Import</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Mail Id:</h5>
        <input type="text" id="usermail" [(ngModel)]="usermail" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Password:</h5>
        <input type="password" id="importmailpwd" [(ngModel)]="importmailpwd" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="ImportFromMail()" style="color: black;">
            View
        </button>
    </div>
    <br />
</ng-template>

<!-- EDIT FOLDER -->
<ng-template #editsubfolder let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
        <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="UpdateSubFolderName()" (click)="d('Cross click')"
            style=" cursor: pointer;">
            Update
        </button>
    </div>
    <br />
</ng-template>

<ng-template #shareSingleFile let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Select Members to Share</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- <div class="modal-body" style="font-size: 16px;">
        <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
            {{ mem.friendName }}<br />
        </div>
        <div class="col-lg-12 row">
            <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
                <option value=""> Select Friend </option>
                <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}">
                    {{ I.friendName }}
                </option>
            </select>
        </div>
        <br />
    </div> -->


    <div class="modal-body" style="font-size: 16px">
        <div>
            <div id="aboveTagInp">
                <!--...... <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test"
                    [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                    [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">

                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                        [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'"
                        [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.friendName }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input> ......-->
                <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="frndslistnames"
                [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
        </div>
        <br />
    </div>
    <!-- <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
    {{ mem.friendName }}<br />
  </div>
  <div class="col-lg-12 row">
    <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
      <option value="" hidden> Select Friend </option>
      <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
        {{ I.friendName }}
      </option>
    </select>
  </div> -->
    <br />
    <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list? &nbsp;</span>
        <button type="button" class="btn btn-primary" (click)="addfrnd()">
            Add Friend
        </button>
    </div>
    <div *ngIf="frndrqst">
        <br>
        <div>
            <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                                                            Email:
                                                        </h5> -->
            <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email/Mobile"
                name="dp" />
        </div>
        <br />
        <div>

            <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
                style="color: black; color: white;padding: 5px;margin-left: 10px;">
                Send Request
            </button>
        </div>
    </div>
    <br />
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="SharOnlyFileToUsers()" (click)="d('Cross click')">
            Share
        </button>
    </div>
    <br />
</ng-template>

<ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Select Members to Share</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
        <div>
            <div id="aboveTagInp">
                <!--...... <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test"
                    [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                    [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">

                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                        [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'"
                        [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.friendName }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input> ......-->
                <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="frndslistnames"
                [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
              </ng-multiselect-dropdown>
            </div>
        </div>
        <br />
    </div>
    <!-- <div class="modal-body" style="font-size: 16px;">


        <br />
    </div>

    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
        {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
        <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
            <option value="" hidden> Select Friend </option>
            <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}">
                {{ I.friendName }}
            </option>
        </select>
    </div> -->
    <br />
    <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list? &nbsp;</span>
        <button type="button" class="btn btn-primary" (click)="addfrnd()">
            Add Friend
        </button>
    </div>
    <div *ngIf="frndrqst">
        <br>
        <div>
            <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                                                            Email:
                                                        </h5> -->
            <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email/Mobile"
                name="dp" />
        </div>
        <br />
        <div>

            <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
                style="color: black; color: white;padding: 5px;margin-left: 10px;">
                Send Request
            </button>
        </div>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ShareExsistingSubFolderToUsers()"
            (click)="d('Cross click')">
            Share
        </button>
    </div>
    <br />
</ng-template>

<ng-template #shareall let-d="dismiss" let-c="dismiss">
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Select Members to Share</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <!-- <div class="modal-body" style="font-size: 16px;">

            <br />
        </div> -->
        <!-- 
        <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
            {{ mem.friendName }}<br />
        </div>
        <div class="col-lg-12 row">
            <select *ngIf="shareitem" class="form-control" (change)="ShareFileMembers($event.target.value)">
                <option value="" hidden> Select Friend </option>
                <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
                    {{ I.friendName }}
                </option>
            </select>
        </div> -->

        <div class="modal-body" style="font-size: 16px">
            <div>
                <div id="aboveTagInp">
                    <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test"
                        [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                        [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                        [separatorKeyCodes]="[13,188,186,32]">

                        <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                            [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'"
                            [autocompleteItems]="frndslist">
                            <ng-template let-item="item" let-index="index">
                                {{ item.friendName }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <br />
        </div>
        <br />

        <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list?
                &nbsp;</span>
            <button type="button" class="btn btn-primary" (click)="addfrnd()">
                Add Friend
            </button>
        </div>
        <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Do you wish to cancel shared documents?
                &nbsp;</span>

            <button type="button" class="btn btn-primary" (click)="cancelshare()">
                Cancel Shared Documents
            </button>
        </div>

        <div *ngIf="frndrqst">
            <div>
                <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;">
                                                                                Email:
                                                                            </h5> -->
                <br>
                <input type="text" placeholder="Email/Mobile" id="accid" [(ngModel)]="accid" class="form-control"
                    name="dp" />
            </div>
            <br />
            <div>
                <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
                    style="color: black; color: white;padding: 5px;margin-left: 10px;">
                    Send Request
                </button>
            </div>
        </div>
        <br />
        <div *ngIf="cancelshareall">

            <div class="col-lg-12 row">
                <select *ngIf="shareitem1" class="form-control" (change)="ShareFileMembers1($event.target.value)">
                    <option value="" hidden> Select Friend </option>
                    <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
                        {{ I.friendName }}
                    </option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" *ngIf="shareitem1" class="btn btn-primary" (click)="shareallallcancel()"
                (click)="d('Cross click')">
                Cancel Shared Documents
            </button> &nbsp; &nbsp;
            <button type="button" *ngIf="!shareitem1" class="btn btn-primary" (click)="shareallall()"
                (click)="d('Cross click')">
                Share
            </button>
        </div>
    </div>
    <br />
</ng-template>


<ng-template #deleteMultiConformation let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Delete Data </b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
        <div class="col-lg-12 row">
            <h4 class="modal-title w-100" id="myModalLabel">
                <b>Do You want to Delete {{ deletemulticontent }} &nbsp; ?</b>
            </h4>
        </div>
        <br />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="Deleteselected()" (click)="d('Cross click')">
            Delete
        </button>
        <button type="button" class="btn btn-warning" (click)="d('Cross click')">
            Close
        </button>
    </div>
    <br />
</ng-template>
<ng-template #addclient let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Client Name:</h5>
        <input type="text" id="client" [(ngModel)]="client" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="savenewClient()" (click)="d('Cross click')"
            style="cursor: pointer;">
            Add
        </button>
    </div>
    <br />
</ng-template>

<!-- IMAGE VIEW -->
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.DocumentName }}</h6>
        <h6 style="float: left;">
            Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <!-- <a [href]="imgdisplay.file" style="cursor: pointer;">
                    <button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a> -->
                <button class="btn btn-info" (click)="abc(imgdisplay)">
                    <i class="fa fa-download"></i>Download
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ zzz.DocumentName }} <br /></h6>
        <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <button type="button" class="btn btn-primary" (click)="wanttosearch()">Want to Search</button>
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <!-- <a [href]="pdfUrl">
                    <button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a> -->
                <button class="btn btn-info" (click)="abc(zzz)">
                    <i class="fa fa-download"></i>Download
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float: left;">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <video controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">
    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <audio controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>

<button type="button" id="ppup12" data-toggle="modal" data-target="#Movetofold" style="visibility: hidden;">
    <span style="
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #edf3ff;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 25px;
    "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
</button>
<div class="modal fade" id="Movetofold" *ngIf="mvlfd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 4%;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Select Folder to Move
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="col-md-12">
                        <div>
                            <!-- <div *ngFor="let a of allFolders1" id="{{a.folderID}}">

                                <span><i class="fa fa-caret-right"  (click)="getfolders1(a)"  id="{{a.id}}" ></i> <img width="30px" height="30px" src="./assets/img/folder.svg"  (click)="gotoFolder(a,Movetofold)">
                                 <span  (click)="gotoFolder(a,Movetofold)">{{a.folderName}}</span></span>

                            </div> -->
                            <div *ngFor="let a of allFolders1" id="{{ a.folderID }}">
                                <span><i class="fa fa-caret-right" (click)="getfolders(a)" id="{{ a.id }}"></i>
                                    <img width="30px" height="30px" src="./assets/img/folder.svg"
                                        (click)="gotoFolder(a)" />
                                    <span (click)="gotoFolder(a)" style="cursor: pointer;">
                                        {{ a.folderName }}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="MoveMultiFolder(destinationFold)">
                    Move
                </button>
                <button type="button" id="closemove" class="btn btn-warning" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- OTHER PROGRESS BAR Ansuha-->
<div class="col-lg-12 row" *ngIf="showper">
    <div class="col-lg-3"></div>
    <div class="col-lg-7" style="position: fixed; top: 86%; left: 20%;">
        <p *ngIf="errormsg">
            <b style="color: black;">{{ notify }} </b>
        </p>
        <p class="alert alert-info" style="box-shadow: white; background-color: white;" *ngIf="generalprogressbar">
            <b *ngIf="folderCreating && !foldercreated" style="color: black;">{{ Creatingmsg }} :{{ n }}%</b>

            <img src="./assets/img/tick.png" height="20px;" width="20px;"
                *ngIf="foldercreated && !folderCreating" />&nbsp;&nbsp;
            <b style="color: black;" *ngIf="foldercreated && !folderCreating">&nbsp;&nbsp; {{ createdmsg }}
            </b>
            <br />
            <progress value="{{ n }}" max="100" style="width: 100%; height: 7px;">
            </progress>&nbsp;&nbsp;{{ n }}%
        </p>
    </div>
</div>

<div class="modal fade" id="exampleModal213" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 4%;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload Folder</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="canceltree()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="col-md-12">
                        <div>
                            <div *ngFor="let a of allFolders1" id="{{ a.folderID }}">
                                <span><i class="fa fa-caret-right" style="cursor: pointer;" (click)="getfolders2(a)"
                                        id="{{ a.id }}"></i>
                                    <img width="30px" height="30px" src="./assets/img/folder.svg"
                                        (click)="gotoFolder12(a)" />
                                    <span (click)="gotoFolder12(a)" style="cursor: pointer;">{{
                                        a.folderName
                                        }}</span></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf='showbutton'>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12" style="text-align: center;">Or</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12" id="Uploadhere" style="text-align: center; cursor: pointer;"
                            (click)="uploadhere()">
                            Upload Here
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" id="cancelUpload1" class="btn btn-secondary" (click)="cancelupload()"
                    data-dismiss="modal">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="Uploaddocs1()">
                    Upload
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" style=" top: 25vh;" role="document">
        <div class="modal-content" style="width: 50vw;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Upload Object</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="display: flex; justify-content: center;">
                <div class="container fileUploadWrapper">
                    <div class="col-md-12">
                        <div class="fileupload" style="background-image:  url('/assets/img/upload-icon.png');"
                            appDragDropFileUpload (click)="fileField.click()" (fileDropped)="onDrop($event)">
                            <span class="ddinfo">Choose an object or drag here</span>
                        </div>
                        <input type="file" name="avatars" #fileField (change)="onChange($event)" multiple />
                    </div>

                    <div class="col-md-12">
                        <ul style="list-style: none;">
                            <li *ngFor="let file of files1">
                                <div *ngIf="file.type == 'image/png'">
                                    <img src="./assets/img/image.png" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="
                    file.type ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  ">
                                    <img src="./assets/img/xls.png" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="file.type == 'video/mp4'">
                                    <img src="./assets/img/nvideo.png" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="file.type == 'application/x-zip-compressed'">
                                    <img src="./assets/img/zip.jpg" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="file.type == 'text/plain'">
                                    <img src="./assets/img/text.png" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="file.type == 'application/pdf'">
                                    <img src="./assets/img/pdficon.png" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="
                    file.type ==
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                  ">
                                    <img src="./assets/img/ppt.png" width="30px" height="30px" />{{ file.name }}
                                </div>
                                <div *ngIf="
                    file.type ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ">
                                    <img src="./assets/img/word.jpg" width="30px" height="30px" />{{ file.name }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="role == 1 || role ==8" type="button" class="btn btn-primary" (click)="workflowupload()"
                    style="cursor: pointer;">
                    Upload to Workflow
                </button>
                <button type="button" id="cancelUpload" class="btn btn-secondary" data-dismiss="modal"
                    style="cursor: pointer;">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="Uploaddocslarge()" style="cursor: pointer;">
                    Upload
                </button>
                <!-- <button type="button" class="btn btn-primary" (click)="Uploaddocs()" style="cursor: pointer;">
                    Upload
                </button> -->
            </div>
        </div>
    </div>
</div>