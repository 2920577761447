<app-nav-bar></app-nav-bar>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row" id="four">
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-4">
        <img src="./assets/img/backimage.png" style="cursor: pointer;" title="Back" height="21px" (click)=goBack() />
    </div>
</div>
<div class="col-md-12 row">
    <div class="col-md-8"></div>
    <div class="col-md-2">
        <button class="btn btn-primary" (click)="DownloadForm()">Download</button>
    </div>
    <div class="col-md-2">
        <button class="btn btn-info" (click)="sndpdftoprint()">Print</button>
    </div>
</div>
<div id="print-section">
    <div class="col-md-12">&nbsp;</div>
<h2 style="text-align: center;"> OUTWARD DOCUMENT</h2>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div *ngIf="!one" class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Object Number:</label>&nbsp;{{GetFileName(filenumber) }}
    </div>
    <div *ngIf="one" class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Object Number:</label>&nbsp;{{filenumber }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">To Users:</label>&nbsp;<p *ngFor="let item of selecteduserlist">{{item.display }},</p>

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Outward Number:</label>&nbsp;{{OutwardNumber }}

    </div>
</div>
<div class="col-md-12 row ">&nbsp;</div>
<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Date (MM-DD-YYYY):</label>&nbsp;{{date }}
    </div>
    
</div>
<div class="col-md-12 row">
    <div class="col-md-5"></div>
    <div class="col-md-4">
        <h4>Document Details</h4>
    </div>
</div>
<div>&nbsp;</div>
<div class="col-md-12 row ">&nbsp;</div>
<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Outward Type:</label>&nbsp;{{GetOutwardTypeName(outwardtype) }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Pages:</label>&nbsp;{{pages }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row " *ngIf="!one">
        <label for="Email " id="emaillabel"> Inward Number:</label>&nbsp;{{Inwardno }}
    </div>
    <div class="col-md-3 row " *ngIf="one">
        <label for="Email " id="emaillabel"> Inward Number:</label>&nbsp;{{Inwardno }}
    </div>
</div>
<div class="col-md-12">&nbsp;</div>

<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Address:</label>&nbsp;{{address }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Sensitivity:</label>&nbsp;{{GetPriorityName(sensitivity) }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
  
</div>
<div class="col-md-12 row">
    <div class="col-md-5"></div>
    <div class="col-md-4">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <h4>Dispatch Details</h4>
    </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Outward Reference Number:</label>&nbsp;{{outwardRefNumber }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">Dispatch Mode:</label>&nbsp;Email

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Reply Awaited:</label>&nbsp;{{GetReplayAwaitedName(replyAwaited) }}

    </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Document Type:</label>&nbsp;{{GetDocumentTypeName(docType)}}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">Status:</label>&nbsp;{{GetWorkflowStageName(status) }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Office Notes/Remarks/Comments:</label>&nbsp;{{remarks }}

    </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div><div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
</div>
