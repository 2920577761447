import { Component, OnInit } from '@angular/core';
import { WorkAllotmentService } from 'src/app/Shared/WorkAllotment/work-allotment.service';
import { MenuMasterService } from 'src/app/Shared/MenuMaster/menu-master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'src/app/Shared/MessageService';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  sidebaropen:any
  SideBarMenus: any;
  roleid: string;
  uid: string;
  message: any;
  isLoggedIn: boolean;
  username: string;
  subscription: Subscription;
  Menus: any;
  MenuList: any;
  MasterList: any;
  mobileview: boolean=false;
  BsafeMenus: any;
  downpath: any;
  seleteddownload: boolean;
  constructor(private workallotmentservice:WorkAllotmentService,private menuservice:MenuMasterService,
    private spinner:NgxSpinnerService,private messageService:MessageService,private router:Router) { 
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.roleid = localStorage.getItem("role");
        this.uid = localStorage.getItem("userId");
        this.message = true;
        this.isLoggedIn = true;
        this.username = localStorage.getItem("UserFullName");
        this.GetAllMenus();
      }
      this.subscription = this.messageService.getMessage().subscribe(message => {
        this.message = message;
        if (this.message.text == "true") {
          this.isLoggedIn = true;
        }
      });
      this.isMobileMenu();
    }

  ngOnInit(): void {
    this.openNav();
    this.closeNav();
    this.GetSideBarMenus();
    this.GetBsafeMenus();
  }
 
  GetBsafeMenus() {
    this.spinner.show();
    this.menuservice.getbsafemenus().subscribe((response: any) => {
      this.BsafeMenus = response;
          this.spinner.hide();
    })
  }
  DownloadFile() {
    this.menuservice.downloadFile().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      });
  
  }
  DownloadFilePortable() {
    this.menuservice.downloadFileportable().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      });

    }
  openNav() {
    this.isMobileMenu();
    this.sidebaropen=false;
    document.getElementById('mySidenav').style.width = '250px';
    document.getElementById('main').style.marginLeft = '250px';
  }
  closeNav() {
    this.isMobileMenu();
    this.sidebaropen=true;
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('main').style.marginLeft = '0';
  }
  GetSideBarMenus(){
    this.workallotmentservice.GetAllSidebarMenus().subscribe((data:any)=>{
      this.SideBarMenus=data;
    })
  }
  GetAllMenus() {
    this.spinner.show();
    this.menuservice.getusermenus(this.roleid).subscribe((response: any) => {
      this.Menus = response;
      this.MenuList = this.Menus.menusList;
      this.MasterList = this.Menus.masterList;
      this.spinner.hide();

    })
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };
  Logout() {
    this.isLoggedIn = false;
    localStorage.setItem("IsLoggedIn", "false");
    localStorage.removeItem("employee")
    localStorage.removeItem('userToken');
    this.spinner.show();
    this.menuservice.logoutsession(this.uid).subscribe((data: any) => {
      this.spinner.hide();
    });
    this.router.navigate(['/home']);
  }
}
