import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  readonly baseAppUrl: string = 'https://blocdrive.in/blocdriveapi'

  constructor(private http:HttpClient) { }
  saveFolderData(data){
    return this.http.post(this.baseAppUrl +"/api/Inward/SaveInwardRegistration",data);
  }
}
