<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div class="register">
    <h3 style="text-align: center; color: black">Project Modules List</h3>
    <div>&nbsp;</div>
    <div class="row col-sm-12 promodbtnmedia">
        <div class="col-sm-3"></div>
        <div class="col-sm-2">
            <button class="btn btn-primary" (click)="addModule(addmodule)">
        Add
      </button>
        </div>
        <div class="col-sm-9"></div>
    </div>
    <br />
    <div class="col-md-12 row">
        <div class="col-md-3"></div>
        <div class="col-md-8">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse">
                        <tr class="thead-dark" style="font-size: 17px; text-align: center">
                            <th>Sl No</th>
                            <th>Project Id</th>
                            <th>Module Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                        <tr *ngFor="
                let aa of ModuleList
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              " style="
                font-size: 16px;
                color: rgb(0, 0, 0);
                font-family: DINNextRoundedLTPro;
                text-align: center;
              ">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ GetProjectName(aa.projectId) }}</td>
                            <td>{{ aa.moduleName }}</td>

                            <td>
                                <button class="btn btn-success" (click)="EditModule(aa, updatemodule)">
                  Edit
                </button>
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteModule(aa, deletemodule)">
                  Delete
                </button>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<br /><br />

<ng-template #addmodule let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Module</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
      <option value="">Select Project</option>
      <option
        *ngFor="let project of ProjectList"
        value="{{ project.uniqueProjectId }}"
      >
        {{ project.projectName }}
      </option></select
    ><br />
    <h5 class="modal-title" id="modal-basic-title">Module Name:</h5>
    <input
      type="text"
      id="moduleName"
      [(ngModel)]="moduleName"
      class="form-control"
      name="dp"
    />
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="SaveModule()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Save
    </button>
  </div>
  <br />
</ng-template>
<ng-template #updatemodule let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Module</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <select
      class="form-control dropdownsize"
      (change)="onSelectedProject($event.target.value)"
    >
      <option value="">Select Project</option>
      <option
        *ngFor="let project of ProjectList"
        value="{{ project.uniqueProjectId }}"
        [selected]="project.uniqueProjectId == editprojectId"
      >
        {{ project.projectName }}
      </option></select
    ><br />
    <h5 class="modal-title" id="modal-basic-title">Module Name:</h5>
    <input
      type="text"
      id="editmoduleName"
      [(ngModel)]="editmoduleName"
      class="form-control"
      name="dp"
    />
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-info"
      (click)="UpdateModule()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Update
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deletemodule let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete module</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="DeleteModuleRow()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div
      class="alert alert-info"
      style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      "
    >
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="closealert()"
      >
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>