import { Component, OnInit } from '@angular/core';
import { SupportchatService } from '../Shared/SupportChat/supportchat.service';
import { IndividualChatDetails } from '../ViewModels/IndividualChatDetails';
import { SupportChatVM } from '../ViewModels/Supportchat';
import { Observable, interval, Subscription } from 'rxjs';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';
@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.css']
})

export class SupportChatComponent implements OnInit {
    chatSend: any;
        messa: string;
        GUID: string;
        supportchatdetails: any;
        ChatGuid:any;
    chatdetail: any;
    chat:any
    MailId: string;
    id: number;
    private updateSubscription: Subscription;
    constructor(private supportchat:SupportchatService,private auditortransactionservice:AuditorTransactionService) { 
        if (localStorage.getItem('IsLoggedIn') == 'true') {
      
              this.GUID = localStorage.getItem("GUID");
              this.MailId = localStorage.getItem("MailId");
            }
    }

    ngOnInit(): void {
        this.loadChatDetail();
        this.updateSubscription = interval(10000).subscribe(
          (val) => { this.loadChatDetail()
        }
    );
      hideChat(0);
  
  $('#prime').click(function() {
    toggleFab();
  });
  
  
  //Toggle chat and links
  function toggleFab() {
    $('.prime').toggleClass('zmdi-comment-outline');
    $('.prime').toggleClass('zmdi-close');
    $('.prime').toggleClass('is-active');
    $('.prime').toggleClass('is-visible');
    $('#prime').toggleClass('is-float');
    $('.chat').toggleClass('is-visible');
    $('.fab').toggleClass('is-visible');
    
  }
  
    $('#chat_first_screen').click(function(e) {
          hideChat(1);
    });
  
    $('#chat_second_screen').click(function(e) {
          hideChat(2);
    });
  
    $('#chat_third_screen').click(function(e) {
          hideChat(3);
    });
  
    $('#chat_fourth_screen').click(function(e) {
          hideChat(4);
    });
  
    $('#chat_fullscreen_loader').click(function(e) {
        $('.fullscreen').toggleClass('zmdi-window-maximize');
        $('.fullscreen').toggleClass('zmdi-window-restore');
        $('.chat').toggleClass('chat_fullscreen');
        $('.fab').toggleClass('is-hide');
        $('.header_img').toggleClass('change_img');
        $('.img_container').toggleClass('change_img');
        $('.chat_header').toggleClass('chat_header2');
        $('.fab_field').toggleClass('fab_field2');
        $('.chat_converse').toggleClass('chat_converse2');
        //$('#chat_converse').css('display', 'none');
       // $('#chat_body').css('display', 'none');
       // $('#chat_form').css('display', 'none');
       // $('.chat_login').css('display', 'none');
       // $('#chat_fullscreen').css('display', 'block');
    });
  
  function hideChat(hide) {
      switch (hide) {
        case 0:
              $('#chat_converse').css('display', 'none');
              $('#chat_body').css('display', 'none');
              $('#chat_form').css('display', 'none');
              $('.chat_login').css('display', 'block');
              $('.chat_fullscreen_loader').css('display', 'none');
               $('#chat_fullscreen').css('display', 'none');
              break;
        case 1:
              $('#chat_converse').css('display', 'block');
              $('#chat_body').css('display', 'none');
              $('#chat_form').css('display', 'none');
              $('.chat_login').css('display', 'none');
              $('.chat_fullscreen_loader').css('display', 'block');
              break;
        case 2:
              $('#chat_converse').css('display', 'none');
              $('#chat_body').css('display', 'block');
              $('#chat_form').css('display', 'none');
              $('.chat_login').css('display', 'none');
              $('.chat_fullscreen_loader').css('display', 'block');
              break;
        case 3:
              $('#chat_converse').css('display', 'none');
              $('#chat_body').css('display', 'none');
              $('#chat_form').css('display', 'block');
              $('.chat_login').css('display', 'none');
              $('.chat_fullscreen_loader').css('display', 'block');
              break;
        case 4:
              $('#chat_converse').css('display', 'none');
              $('#chat_body').css('display', 'none');
              $('#chat_form').css('display', 'none');
              $('.chat_login').css('display', 'none');
              $('.chat_fullscreen_loader').css('display', 'block');
              $('#chat_fullscreen').css('display', 'block');
              break;
      }
  }
  
  
    }
  
  
    loadChatDetail() {
      const inputRequest: IndividualChatDetails = {
        Chatid: this.id,
        ChatFrom: this.GUID,
        ChatTo: "c485c2f6-946d-4928-880b-c8a25deeee1d",
      };
        this.supportchat
          .GetChatDetails(inputRequest)
          .subscribe((chat: any) => {
            this.chatdetail = chat;
          });
      }
    SendIndividualMessage() {
  
        let MailId=localStorage.getItem("MailId");
        let UserFullName=localStorage.getItem("UserFullName");
        let GUID = localStorage.getItem("GUID");
        let uid = localStorage.getItem("userId");
    
        const inputRequest: SupportChatVM = {        
          ChatId: 0,
          ChatGuid: "c485c2f6-946d-4928-880b-c8a25deeee1d",
          ChatFrom: MailId,
          ChatFromName: UserFullName,
          ChatTo: "support@blocdrive.com",
          ChatToName: "pp",
          ChatMessage: this.chatSend,
          ChatFromUserId: uid,
          ChatToUserId: "CGD2020092500001",
          GUID: GUID,
          IsEnabled: true,
        }
     
        this.supportchat.savesupportChat(inputRequest).subscribe((data: any) => {
          if (data != null) {
           
            this.chatSend = '';
            const audit = {
              TransactionName:"Support Chat",
              TransactionType:"Chat",
              TransactionDesc:"Support chat",
    
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit);
            // alert('Message sent Successfully!');
            this.loadChatDetail();
          }
          this.loadChatDetail();
        });
      }
  
  }
  
