import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { WorkFlowService } from '../Shared/WorkFlow/work-flow.service';
import { Friend } from '../ViewModels/Friend';

@Component({
  selector: 'app-friend-requests',
  templateUrl: './friend-requests.component.html',
  styleUrls: ['./friend-requests.component.css']
})
export class FriendRequestsComponent implements OnInit {
  RecievedFriendRequests: any;
  GUID: string;
  p: number = 1;
  q: number = 1;
  r: number = 1;

  SentFriendRequests: any;
  Friends: any;
  closeResult: string;
  accid: string;
  userid: string;
  frndslist: any;
  uid: string;
  constructor(private workflowservice: WorkFlowService, private spinner: NgxSpinnerService, private modalService: NgbModal,
    private auditortransactionservice:AuditorTransactionService,private userservice:UserDetailsService) {
    this.GUID = localStorage.getItem("GUID");
    this.userid = localStorage.getItem("userId");
    this.uid = localStorage.getItem("userId");

  }

  ngOnInit(): void {
    this.GetFriendRequests();
    this.GetRecievedFriendRequest();
    this.GetSentFriendRequest();
  }

  async GetFriendRequests() {
    this.spinner.show();
    this.workflowservice.GetFriendRequests(this.GUID).subscribe((data: any) => {
      // this.RecievedFriendRequests = data.incomingRequest;
      // this.SentFriendRequests = data.sentRequest;
      this.Friends = data;
      this.spinner.hide();
    })
  }
  async GetRecievedFriendRequest() {
    this.spinner.show();
    this.workflowservice.GetRecievedFriendRequest(this.GUID).subscribe((data: any) => {
      this.RecievedFriendRequests = data;
      this.spinner.hide();
    })
  }
  async GetSentFriendRequest() {
    this.spinner.show();
    this.workflowservice.GetSentFriendRequest(this.GUID).subscribe((data: any) => {
      this.SentFriendRequests = data;
      this.spinner.hide();
    })
  }
  Accept(data) {
    var info = {
      FriendId: data.friendId,
      UserId: this.GUID,
      Id:data.id
    }
    this.spinner.show();
    this.workflowservice.AcceptFriendequest(info).subscribe((data: any) => {
      if(data=="Successfull"){
        alert("Friend request accepted successfully!!!")
        const audit = {
          TransactionName:"Friend Request",
          TransactionType:"accept",
          TransactionDesc:"Friend Request",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      this.GetFriendRequests();
      this.spinner.hide();
    })
  }
  AddFriend(addfriend) {
    this.modalService.open(addfriend).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID: this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please enter your friend mailid!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using Blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "Cannot") {
        alert("You cannot send friend request!!");

      }
      else if (data == "1") {
        alert("Friend request sent successfully!!!");
        const audit = {
          TransactionName:"Friend Request",
          TransactionType:"Send",
          TransactionDesc:"Friend Request",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
      }
      else {
        alert("Something went wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
      this.GetFriendRequests();
      this.GetRecievedFriendRequest();
      this.GetSentFriendRequest();
    });
  }
}
