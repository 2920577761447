import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import WebViewer from '@pdftron/webviewer';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.css']
})
export class PdfviewerComponent  implements OnInit {
    @ViewChild('viewer') viewerRef  : ElementRef;
    blobURL: any;
    
    constructor( private router:ActivatedRoute){
      // this.router.params.subscribe(params => {
      //   if (params["id"]) {
      //     this.blobURL = params["id"];
      //   }
      // })
    
    }
    ngOnInit(): void {
      WebViewer({
    path:'../assets/lib',
    initialDoc:this.blobURL
      },this.viewerRef.nativeElement).then(instance =>{
    
      })
    }
}
