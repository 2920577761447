// auth.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    public isLoggedIn: boolean = false;
  constructor( private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    if (localStorage.getItem("IsLoggedIn") == "true") {
        this.isLoggedIn = true;
    }
    else
    this.isLoggedIn = false;

    if (this.isLoggedIn == true) {
      return true; // Allow access to the route
    } else {
        alert("Please login to your Blocdrive account")
      this.router.navigate(['/login']); // Redirect to the login page or another route
      return false; // Deny access to the route
    }
  }
}
