<style>
    :host ::ng-deep span {
        color: black !important;
        opacity: 1;
    }

    .button {
        background-color: #4CAF50;
        /* Green */
        border: none;
        color: white;
        padding: 10px 7px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 10%;
    }

    .btn11 {
        background-color: #008CBA;
    }

    .btn22 {
        background-color: #f44336;
    }
</style>
<div class="col-md-12 row" id="four">
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-4">
        <img src="./assets/img/backimage.png" style="cursor: pointer;" title="Back" height="21px" (click)=goBack() />
    </div>
</div>
<div class="col-md-12 ">&nbsp;</div>
<div class="col-md-12 ">&nbsp;</div>
<div class="col-md-1">&nbsp;</div>
<!-- <button (click)="sndpdftoprint()" class="btn btn-primary" >Print</button> -->

<div id="print-section" class="col-md-12 row" style="display: flex;">
    <div class="col-md-4" style="width: 50%;">
        <pdf-viewer src="data:application/pdf;base64,{{gotfile}}" [render-text]="true" [original-size]="false">
        </pdf-viewer>
    </div>
    <div class="col-md-4 sample" style="width: 50%;border-left: 10px solid black;">
        <h2>Document Editor</h2>
        <div [froalaEditor]="imgOptions" [(froalaModel)]="content"></div>
    </div>
    <div class="col-md-4 sample" style="width: 50%;border-left: 10px solid black;word-wrap: break-word;">
        <h4>Result Content:</h4>
        <button class="button btn11" (click)="saveMeAsDraft()">SAVE AS DRAFT</button> &nbsp;
        <button class="button btn22" (click)="saveMeAsPdf()">SAVE AS PDF</button>

        <!-- <div [froalaView]="resultcontent"></div> -->
        <div  *ngFor="let item of allcomments">
            
            <div [innerHTML]="item.htmlString"></div>
        </div>
    </div>
</div>
<br />