<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="container register">
    <div>
        <div class="row col-sm-12">
            <div class="col-sm-4">&nbsp;</div>
            <div class="col-sm-4">
                <h2 style="text-align: center; color: rgb(0, 0, 0)">Inbox</h2>
            </div>
            <br />
            <br />
            <br />
        </div>
        <br />
        <div class="row col-sm-12">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th></th>
                            <th>From</th>
                            <th>Form Code</th>
                            <th>Exhibits</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tr *ngFor="
              let inbox of Inboxs
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            ">
                        <td>
                            <i class="fas fa-user"></i>
                        </td>
                        <td>
                            <a class="fakelink" (click)="ShowMailForm(inbox)">
                {{ inbox.from }}</a
              >
            </td>
            <td>
              <a class="fakelink" (click)="ShowMailForm(inbox)">{{
                inbox.formCode
              }}</a>
                        </td>
                        <td>
                            <a class="fakelink" (click)="ShowMailForm(inbox)">
                {{ inbox.subject }}</a
              >
            </td>
            <td>
              <a class="fakelink" (click)="ShowMailForm(inbox)">{{
                inbox.date
              }}</a>
                        </td>
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
            </div>
        </div>
    </div>
</div>

<button id="openModalButton" class="btn btn-success" [hidden]="true" (click)="openmodal(acceptance)">
  Edit
</button>
<button id="openUnauthModalButton" class="btn btn-success" [hidden]="true" (click)="openUnauthmodal(unauthorized)">
  Edit
</button>
<ng-template #acceptance let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h2 class="modal-title text-capitalize" align="center">
            Acceptance Of Exhibits
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-10" *ngFor="
        let exi of Exhibit.ExhibitsName.examinationdata
          | paginate: { itemsPerPage: 5, currentPage: p };
        let i = index
      ">
            <h5>{{ i + 1 }}{{ exi.name }}</h5>
        </div>
        <br />
        <br />
    </div>
    <br />
    <div class="modal-footer">
        <div class="col-xs-12">
            <button class="btn btn-primary" (click)="Accept(Exhibit.AckInfo.caseid, Exhibit.AckInfo.ackid)">
        <span class="glyphicon glyphicon-cloud-upload"></span>YES
      </button>
            <button class="btn btn-default" (click)="Rejectone(Exhibit.AckInfo.caseid, Exhibit.AckInfo.ackid)">
        <span class="glyphicon glyphicon-remove"></span>NO
      </button>
        </div>
        <div class="col-lg-12">
            <textarea [(ngModel)]="comment" style="width: 800px; height: 50px" placeholder="Comment Required"></textarea>
            <br />
            <br />
            <button class="btn btn-info" (click)="Rejecttwo(Exhibit.AckInfo.caseid, Exhibit.AckInfo.ackid, exi)">
        Done
      </button>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #unauthorized let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h2 class="modal-title text-capitalize" align="center">Warning...</h2>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <h2>{{ exi.name }}</h2>

        <h3 style="color: red; text-align: center">
            You Are UnAuthorized To Access this Information!!!
        </h3>

        <br />
        <br />
    </div>
    <br />
    <div class="modal-footer">
        <div class="col-xs-12">
            <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="cursor: pointer">
        Cancel
      </button>
        </div>
    </div>
    <br />
</ng-template>
<div>&nbsp;</div>
<div>&nbsp;</div>