<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="ng-scope register">
    <div class="container">
        <div class="card">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <div class="panel-heading">
                        <i class="icon-comments"></i>
                        <div class="panel panel-primary" align="center" style="background: linear-gradient(270deg, #7100aa, #000)">
                            <br />
                            <h3 style="color: white">Assign Tasks</h3>
                            <br />
                        </div>
                    </div>
                    <div>&nbsp;</div>
                </div>
                <br />
                <form #taskassign="ngForm">
                    <div class="panel-body">
                        <div class="form-group">
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Project
                                    <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
                    <option value="">Select Project</option>
                    <option
                      *ngFor="let projectlist of Projects"
                      value="{{ projectlist.uniqueProjectId }}"
                    >
                      {{ projectlist.projectName }}
                    </option>
                  </select>
                                </div>
                                <div>&nbsp;</div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Task Type
                                    <select class="form-control dropdownsize" (change)="onSelectedTaskType($event.target.value)">
                    <option value="">Select Task Type</option>
                    <option
                      *ngFor="let tasktype of tasktypelist"
                      value="{{ tasktype.TaskTypeId }}"
                    >
                      {{ tasktype.TaskTypeName }}
                    </option>
                  </select>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="form-group">
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Task
                                    <select class="form-control dropdownsize" (change)="onSelectedtaskassign($event.target.value)">
                    <option value="">Select Task</option>
                    <option
                      *ngFor="let task of ProjectTasksDropDown"
                      value="{{ task.id }}"
                    >
                      {{ task.detailedDescription }}
                    </option>
                  </select>
                                </div>
                                <div>&nbsp;</div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Assign To
                                    <select class="form-control dropdownsize" (change)="onSelectUser($event.target.value)">
                    <option value="">Select User</option>
                    <option
                      *ngFor="let user of userList"
                      value="{{ user.userId }}"
                    >
                      {{ user.userName }}
                    </option>
                  </select>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="form-group">
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Assign Date
                                    <input type="date" class="form-control" name="assigndate" id="assigndate" [(ngModel)]="assigndate" />
                                </div>
                                <div>&nbsp;</div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Assign Time
                                    <input type="text" class="form-control" name="assigntime" id="assigntime" [(ngModel)]="assigntime" />
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="form-group">
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Comments
                                    <input type="text" class="form-control" name="property" id="property" [(ngModel)]="property" />
                                </div>
                                <div>&nbsp;</div>
                                <div class="col-md-4" style="font-weight: 500">
                                    Upload Object<br /><br />
                                    <label class="dropdown-item">
                    <b
                      for="excel-upload"
                      style="color: #0000ff; cursor: pointer; font-size: 20px"
                      ><i class="fas fa-file"></i>&nbsp;&nbsp;Upload Objects</b
                    >
                    <input
                      #fileInput
                      (click)="fileInput.value = null"
                      type="file"
                      id="excel-upload"
                      multiple="true"
                      style="display: none"
                      (change)="onChangeUploadDoc($event)"
                    />
                  </label>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-6">&nbsp;</div>
                            <div class="col-md-4">
                                <button class="btn btn-primary" (click)="addTaskassign(taskassign)">
                  <span class="glyphicon glyphicon-cloud-upload"></span>Save
                </button>
                            </div>
                        </div>
                        <br /><br />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>