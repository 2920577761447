<app-nav-bar></app-nav-bar>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>

<div class="row">
    <div class="col-md-1"></div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats" pageScroll href="#showhidevalue" (click)="getuserdetails()">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon">

                    <i class="fas fa-users"></i>
                </div>
                <p class="card-category">Session Details</p>
                <h3 class="card-title">User Logs

                </h3>
            </div>
            <!-- <div class="card-footer">
                <div class="stats">
                    <i class="material-icons text-danger">warning</i>
                    <a href="javascript:void(0)">Get More Space...</a>
                </div>
            </div> -->
        </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats" pageScroll href="#enterprisevalue" (click)="GetEnterpriseUserdetails()">
            <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                    <i class="fas fa-university"></i>
                </div>
                <p class="card-category">Principal Account</p>
                <h3 class="card-title">Enterprise Account Details</h3>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-1"></div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats" pageScroll href="#individualvalue" (click)="GetIndividualsdetails()">
            <div class="card-header card-header-danger card-header-icon">
                <div class="card-icon">
                    <i class="fas fa-user"></i>
                </div>
                <p class="card-category">Individual Details</p>
                <h3 class="card-title">Individual Account Details</h3>
            </div>

        </div>
    </div>
    <div class="col-md-1"></div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats" pageScroll href="#alluservalue" (click)="GetAllUserdetails()">
            <div class="card-header card-header-info card-header-icon">
                <div class="card-icon">
                    <i class="fas fa-server"></i>
                </div>
                <p class="card-category">User Details</p>
                <h3 class="card-title">All User Details</h3>
            </div>

        </div>
    </div>
</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<br />

<div class="col-md-12 row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
            placeholder="Search Here " />
        <br />
    </div>
</div>
<br />


<div class="col-md-12 row" id="showhidevalue">
    <div class="col-md-12 row" *ngIf="showhidevalue">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse;display: table;">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th>Login Time</th>
                            <th>Logout Time</th>

                        </tr>

                        <tr *ngFor="   let aa of logindetails  | filter: searchText   | paginate: { itemsPerPage: 10, currentPage: p }; let i = index  "
                            style=" font-size: 16px;
            color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.userId }}</td>
                            <td>{{ aa.userName }}</td>
                            <!-- <td>{{ aa.userRole }}</td> -->
                            <td>{{ aa.loginTime | date: "short" }}</td>
                            <td>{{ aa.logoutTime | date: "short" }}</td>

                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 row" id="enterprisevalue">
    <div class="col-md-12 row" *ngIf="enterprisevalue">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse;display: table;">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>Enterprise Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Location</th>
                            <th>Company Website</th>
                            <th>Created On</th>
                            <th>Delete</th>
                        </tr>




                        <tr *ngFor="   let aa of enterprisedetails  | filter: searchText   | paginate: { itemsPerPage: 10, currentPage: p }; let i = index  "
                            style=" font-size: 16px;
                color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.enterPriseId }}</td>
                            <td>{{ aa.userName }}</td>
                            <td>{{ aa.email }}</td>
                            <td>{{ aa.location }}</td>
                            <td>{{ aa.companyWebsite }}</td>
                            <td>{{ aa.createdOn | date: "short" }}</td>
                            <td> <i style="cursor: pointer" class="fa fa-trash" (click)="delete(aa.userId)"></i></td>

                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 row" id="individualvalue">
    <div class="col-md-12 row" *ngIf="individualvalue">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse;display: table;">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>User Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Created On</th>
                            <th>Delete</th>

                        </tr>

                        <tr *ngFor="   let aa of individualdetails | filter: searchText  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index  "
                            style=" font-size: 16px;
                    color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.userId }}</td>
                            <td>{{ aa.userName }}</td>
                            <td>{{ aa.email }}</td>
                            <td>{{ aa.createdOn | date: "short" }}</td>
                            <td> <i style="cursor: pointer" class="fa fa-trash" (click)="delete(aa.userId)"></i></td>

                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>




<div class="col-md-12 row" id="alluservalue">
    <div class="col-md-12 row" *ngIf="alluservalue">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse;display: table;">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>UserId</th>
                            <th>Enterprise Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Location</th>
                            <th>Company Website</th>
                            <th>Created On</th>
                            <th>Delete</th>
                        </tr>

                        <tr *ngFor="   let aa of userdetails | filter: searchText  | paginate: { itemsPerPage: 10, currentPage: p }; let i = index  "
                            style=" font-size: 16px;
                        color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.userId }}</td>
                            <td>{{ aa.enterPriseId }}</td>
                            <td>{{ aa.userName }}</td>
                            <td>{{ aa.email }}</td>
                            <td>{{ aa.location }}</td>
                            <td>{{ aa.companyWebsite }}</td>
                            <td>{{ aa.createdOn | date: "short" }}</td>
                            <td> <i style="cursor: pointer" class="fa fa-trash" (click)="delete(aa.userId)"></i></td>


                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>