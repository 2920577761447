<app-nav-bar></app-nav-bar>
<br>
<br><br><br> <br>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="row clientcardmedia">
    <div class="col-md-2"></div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats" pageScroll href="#clientdetails" (click)="GetALLClientsDetails()">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon" >
            
                    <i class="fas fa-users"></i>
                </div>
                <p class="card-category">Used Space</p>
                <h3 class="card-title">Client Details
                   
                </h3>
            </div>
            <!-- <div class="card-footer">
                <div class="stats">
                    <i class="material-icons text-danger">warning</i>
                    <a href="javascript:void(0)">Get More Space...</a>
                </div>
            </div> -->
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats"  pageScroll href="#allclientsharedvalue" (click)="GetsharedClientsDetails()">
            <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                    <i class="fas fa-university"></i>
                </div>
                <p class="card-category">Revenue</p>
                <h3 class="card-title">Shared Files Details</h3>
            </div>
        </div>
    </div>
</div>


<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>


    <div class="col-md-12 row" id="clientdetails">
        <div class="col-md-12 row" *ngIf="clientdetails">
            <div class="col-md-2"></div>
            <div class="col-md-10 clienttable">
                <div>
                    <div style="overflow-x: visible; height: 300px">
                        <table class="table table-responsive" style="border-collapse: collapse;">
                        <!--....... <table class="table table-responsive" style="border-collapse: collapse;display: table;"> ......-->
                            <tr class="thead-dark" style="font-size: 17px">
                                <th>Id</th>
                                <th>UserId</th>
                                <th>GuId</th>
                                <th>Client Name</th>
                                <th>Client Email</th>
                                <th>Created On</th>
                                <th>View</th>
                            </tr>
        
                            <tr *ngFor="   let aa of clientdatalist    | paginate: { itemsPerPage: 5, currentPage: p }; let i = index  "
                                style=" font-size: 16px;
                            color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                                <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                                <td>{{ aa.userId }}</td>
                                <td>{{ aa.guid }}</td>
                                <td>{{ aa.userName }}</td>
                                <td>{{ aa.email }}</td>
                                <td>{{ aa.createdOn }}</td>
                                <td>
                                    <button class="btn btn-success"  [routerLink]="['/clientdetails',aa.userId]" >
                      View
                    </button>
                                </td>
        
                            </tr>
                        </table>
                        <br />
                        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                        <br /><br />
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="col-md-12 row">&nbsp;</div>
        <div class="col-md-12 row">&nbsp;</div>
        


        <div class="col-md-12 row" id="allclientsharedvalue">
            <div class="col-md-12 row" *ngIf="allclientsharedvalue">

                <div class="col-lg-12 row">
                    <div class="col-lg-3 row"></div>
                    <div class="col-lg-4 row">
                    <select class="form-control dropdownsize" (change)="onSelectedclient($event.target.value)" style="padding: 0px;">
                        <option value=""> Select Friend </option>
                        <option *ngFor="let I of clientdatalist" value="{{ I.guid }}" style="cursor: pointer;">
                            {{ I.userName }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 row">&nbsp;</div>
            <div class="col-md-12 row">&nbsp;</div> 
                <div class="col-md-2"></div>
                <div class="col-md-10">
                    <div>
                        <div style="overflow-x: visible; height: 300px">
                            <table class="table table-responsive" style="border-collapse: collapse;display: table;">
                                <tr class="thead-dark" style="font-size: 17px">
                                    <th>Id</th>
                                    <th>UserId</th>
                                    <!-- <th>GuId</th> -->
                                    <th>Document Name</th>
                                    <th>Document type</th>
                                    <th>Shared On</th>
                                    <th>View</th>
                                    
            
                                </tr>
            
                                <tr *ngFor="   let aa of SharedFiles    | paginate: { itemsPerPage: 5, currentPage: p }; let i = index  "
                                    style=" font-size: 16px;
                                color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                                    <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                                    <td>{{ aa.userID }}</td>
                                    <!-- <td>{{ aa.guid }}</td> -->
                                    <td>{{ aa.documentName }}</td>
                                    <td>{{ aa.documentType }}</td>
                                    <td>{{ aa.date | date:"short" }}</td>
                         
                                    <td>
                                        <button class="btn btn-primary"  *ngIf="aa.documentType == 'pdf'"
          (click)="ViewDocument(aa, pdftesting)"
          style="background-color: purple; color: white;" >View</button>
          <button class="btn btn-primary"  *ngIf="aa.documentType == 'txt'"
          (click)="ViewDocumenttxt(aa, txttesting)"
          style="background-color: purple; color: white;" >View</button>
          <button class="btn btn-primary"  
          *ngIf="aa.documentType == 'jpeg' || aa.documentType == 'png' || aa.documentType == 'jpg'" 
          (click)="ViewImageDoc(aa, content)" style="   
          background-color: purple;
          color: white;" >View</button>
          <button class="btn btn-primary"  *ngIf="aa.documentType == 'ppt' || aa.documentType == 'pptx'"
          (click)="ViewPpt(aa, ppttesting)"
          style="background-color: purple; color: white;" >View</button>
          <button class="btn btn-primary"  *ngIf="aa.documentType == 'doc' || aa.documentType == 'docx' "
          (click)="ViewDocx(aa, doctest)" 
          style="background-color: purple; color: white;">View</button>
          <button class="btn btn-primary"  *ngIf="aa.documentType == 'xlsx' || aa.documentType == 'xls'"
          style="background-color: purple; color: white;"
          (click)="ViewExcel(aa, excelview)">View</button>
                                    </td>
                                </tr>


                            </table>
                            <br />
                            <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                            <br /><br />
                            <br /><br />

 <!-- -----------------pdfview------------------ -->
                <ng-template #pdftesting let-d="dismiss" let-c="dismiss">
                    <div class="modal-header" style="background-color: white;">
                        <div>&nbsp;</div>
                        <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
                        <!-- <h6 style="float: left;">
                            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zz.date | date: "short" }}
                        </h6> -->
                
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                
                    <div class="modal-body" style="background-color: white;">
                        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                        <br /><br />
                              
                        <div class="col-lg-12 row">
                            <div class="col-lg-5"></div>
                            <!-- <div class="col-lg-6">
                                <a [href]="pdfUrl"><button class="btn btn-info">
                                        <i class="fa fa-download"></i>Download
                                    </button>
                                </a>
                            </div> -->
                        </div>
                    </div>
                </ng-template>
  
  
  <!-------------------------pptview--------------------------- -->
  
  <ng-template #ppttesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
        <!-- <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ ppt.date | date: "short" }}
        </h6> -->
  
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
  
    <div class="modal-body" style="background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />
              
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <!-- <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div> -->
        </div>
    </div>
  </ng-template>
  
  <!-- -------------------------textview--------------- -->
                <ng-template #txttesting let-d="dismiss" let-c="dismiss">
                  <div class="modal-header" style="background-color: white;">
                      <div>&nbsp;</div>
                      <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
                      <!-- <h6 style="float: left;">
                          &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzzz.date | date: "short" }}
                      </h6> -->
              
                      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
              
                  <div class="modal-body" style="background-color: white;">
                      <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                      <br /><br />
                            
                      <div class="col-lg-12 row">
                          <div class="col-lg-5"></div>
                          <!-- <div class="col-lg-6">
                              <a [href]="pdfUrl"><button class="btn btn-info">
                                      <i class="fa fa-download"></i>Download
                                  </button>
                              </a>
                          </div> -->
                      </div>
                  </div>
              </ng-template>
         
  <!-- -----------------imageview------------------ -->
  
                <ng-template #content let-d="dismiss" let-c="dismiss">
                  <div class="modal-header">
                      <div>&nbsp;</div>
                      <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
                      <!-- <h6 style="float: left;">
                          Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
                      </h6> -->
                      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />
              
                      <br /><br />
                      <div class="col-lg-12 row">
                          <div class="col-lg-5"></div>
                          <div class="col-lg-6">
                              <!-- <a [href]="imgdisplay.file" style="cursor: pointer;"><button class="btn btn-info">
                                      <i class="fa fa-download"></i>Download
                                  </button>
                              </a> -->
                          </div>
                      </div>
                  </div>
              </ng-template>
  
  
  <!-- ---------------------docview--------------------- -->
  <ng-template #doctest let-d="dismiss" let-c="dismiss">
      <div class="modal-header" style="background-color: white;">
          <div>&nbsp;</div>
          <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
          <!-- <h6 style="float: left;">
              &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ doc.date | date: "short" }}
          </h6> -->
  
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
  
      <div class="modal-body" style="background-color: white;">
          <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
          <br /><br />
                
          <div class="col-lg-12 row">
              <div class="col-lg-5"></div>
              <!-- <div class="col-lg-6">
                  <a [href]="pdfUrl"><button class="btn btn-info">
                          <i class="fa fa-download"></i>Download
                      </button>
                  </a>
              </div> -->
          </div>
      </div>
  </ng-template>
  
  
  <!-- ---------------------excelview--------------------- -->
  <ng-template #excelview let-d="dismiss" let-c="dismiss">
      <div class="modal-header" style="background-color: white;">
          <div>&nbsp;</div>
          <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
          <!-- <h6 style="float: left;">
              &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ excel.date | date: "short" }}
          </h6> -->
  
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
  
      <div class="modal-body" style="background-color: white;">
          <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
          <br /><br />
                
          <div class="col-lg-12 row">
              <div class="col-lg-5"></div>
              <!-- <div class="col-lg-6">
                  <a [href]="pdfUrl"><button class="btn btn-info">
                          <i class="fa fa-download"></i>Download
                      </button>
                  </a>
              </div> -->
          </div>
      </div>
  </ng-template>

                        </div>
                    </div>
                </div>
            </div>
       
