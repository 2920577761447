<app-nav-bar></app-nav-bar>

<div id="backGradient" align="center" (window:resize)="onResize($event)">
    <fieldset id="container" *ngIf="!AllDone">
        <br />
        <legend align="center" style="width: 50%;"><img src="./assets/img/blocdrivelogo1.png" id="logo"></legend>
        <div id="matter">
            {{RequestedFileDetails.fromUserName}} is requesting files for: <br>
            "{{RequestedFileDetails.requestingFor}}"
        </div>
        <div>
            <img src="./assets/img/FRImage.png" style="width: 54%;">

        </div>
        <br>
        <div id="matter1">
            You are signed in as
            <!-- <b>{{currentuser}}</b> -->
            {{username}}.<br>
            Upload files safely and securely from your<br>
            computer.
            <!--...... computer or Haspit below. -->
            <br>
            <label id="checkStyle">
                <span id="checktext">
                    <input type="checkbox" [(ngModel)]="theCheckbox" style="cursor: pointer;"
                        (click)="OnClickCheckbox($event)" />
                    &nbsp;With this upload, all files requested have been uploaded! </span>
            </label>

        </div>
        <br>
        <div class="col-md-12 row" id="chooseFooter">
            <!-- (mouseover)="hover=true" (mouseleave)="hover=false" -->
            <div class="col-md-12">
                <label>
                    <!-- [ngStyle]="{background: hover1==true  ? '#9BAAB6' : '#10C7DF' }" -->
                    <b for="excel-upload" id="FromComputerBtn" class="temp-upload-icon btn btn-sm waves-light"
                        mdbWavesEffect >
                        Upload From Computer </b>
                    <input #fileInput (click)="fileInput.value = null" type="file" id='excel-upload' multiple=true
                        style="display: none" (change)="onChange($event)" />
                </label>

            </div>
            <!-- <div class="col-md-6" >
                
                <button type="submit" id="FromHaspitBtn" class="btn btn-sm waves-light" mdbWavesEffect
                    data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspit">Upload From Haspit
                </button>
            </div> -->
        </div>
        <br> <br>
    </fieldset>
    <!--Upload PROGRESS BAR Malleswari-->
    <!-- <div class="col-lg-12 row" *ngIf="showper">
    <div class="col-lg-3"></div>
    <div class="col-lg-7" style="left: 20%;position: fixed;top: 86%">
      <p *ngIf="errormsg"><b style="color: black;">{{notify}} </b></p>
      <p class="alert alert-info" style="box-shadow: white;background-color: white;" *ngIf="showprogressbar">
        <b *ngIf="showuploading && !showuploaded" style="color: black;">{{uploadingmsg}} &nbsp;&nbsp;
          {{remainingtime}} sec left&nbsp;&nbsp;</b>

        <img src="./assets/img/tick.png" height="20px;" width="20px;"
          *ngIf="showuploaded && !showuploading">&nbsp;&nbsp;
        <b style="color: black;" *ngIf="showuploaded &&  !showuploading">&nbsp;&nbsp;{{uploadedmsg}} </b>
        <br />
        <progress value={{percent}} max="100" style="width: 95%;height: 7px;"></progress>
      </p>
    </div>
  </div> -->

    <!-- Create Account Page -->

    <fieldset id="container" *ngIf="AllDone">
        <br />
        <legend align="center" style="width: 50%;"><img src="./assets/img/blocdrivelogo1.png" id="logo"></legend>

        <div>
            <h5 id="heading">Thanks! You're all done.</h5>
            <h5 id="heading1"><span style="text-transform: capitalize;">{{RequestedFileDetails.FromUserName}}</span>
                will be notified of your submission.</h5>
        </div>
        <br>
        <div>
            <img src="./assets/img/FileRequest_Thankyou.png" id="thankyouimg">
        </div>
        <br>
        <div style="display: flex;justify-content: center;">
            <button id="CloseAllDone" (click)="AllDoneClose()">Close</button>
        </div>
        <br />
    </fieldset>

    <br />
    <br />
    <br />
    <br />
    <br />

</div>






<!-- choosefromhaspit modal -->
<div class="modal modal-angular fade" id="choosefromhaspit" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document" id="ModelSize">
        <div class="modal-content">
            <div class="modal-header">
                <b style="font-size:20px;" class="modal-title w-100" id="myModalLabel" id="UploadfromMPF">
                    Upload from My Personal Files</b>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <!-- <span aria-hidden="true">&times;</span> -->
                    <img src="./assets/img/close mark.png" style="cursor: pointer;" />
                </button>
            </div>
            <div class="modal-body">
                <div class="main-content" *ngIf="folderdisaply" id="Mainid">
                    <div class="col-md-12 row" *ngIf="folderdisaply" id="folderdiv">
                        <div class="col-md-2">&nbsp;</div>
                        <span (click)="GetFolderdetails()" id="Breadcrumb" style="cursor: pointer;"><mark id="Breadcrumb">
                                Home </mark> <img src="./assets/img/Vector122.png" />&nbsp;<mark id="Breadcrumbone">
                                My Personal Files </mark></span>
                            <span *ngFor="let d of foldernamelist" id="Breadcrumb"> <img id="arrowhide" src="./assets/img/Vector122.png" />
                                <span (click)="GetSubfoldersforBC(d)"
                                    style="cursor: pointer;">{{d.FolderName}}</span>
                            </span>
                        <span id="Breadcrumbone" style="cursor: pointer;"> <img id="arrowhide" src="./assets/img/Vector122.png" />
                            &nbsp;{{currentpopuppageone}}</span>
                    </div>
                    <div class="card" style="box-shadow: none; border: none;margin-top: -5%; ">
                        <div class="card-body table-responsive" style="box-shadow: none;">
                            <table class="table table-hover">
                                <thead style="color: #4F44C5;">
                                    <th></th>
                                    <th style="padding-left: 3%;cursor: pointer;" (click)="sortby()">Name</th>
                                    <th style="padding-left: 12%;cursor: pointer;" (click)="sortbydate()">Modified</th>
                                </thead>
                                <tbody *ngFor="let folderview of allFolders.SubFolders">
                                    <tr>
                                        <td style="padding-top: 4%;">
                                            <input type="checkbox" (click)="toggleSelect(folderview)"
                                                id="checkboxstyle" />
                                        </td>
                                        <td (click)="GetDetails(folderview)" id="foldernamedisplay">
                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/Vector 111.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{folderview.FolderName}}
                                                </div>
                                            </div>
                                            <!-- <img src="./assets/img/Vector 111.png" style="cursor: pointer;" />
                                                        &nbsp;{{folderview.FolderName}} -->
                                        </td>
                                        <td (click)="GetDetails(folderview)" id="modifiedonn">
                                            {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>

                                <tbody *ngFor="let ret of allFolders.RetData">
                                    <tr id="hoverlink" *ngIf="!ret.FolderName">
                                        <td style="padding-top: 3%;width: 1%;">
                                            <input type="checkbox" (click)="toggle(ret)" />
                                        </td>
                                        <td *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'||ret.DocumentType=='png' ||ret.DocumentType=='jpeg'||ret.DocumentType=='jpg'
                                                              ||ret.DocumentType=='PNG'" (click)="gotopdfView(ret)"
                                            id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img [src]="ret.file"
                                                            style="width:50px;height:40px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='application/pdf' ||ret.DocumentType=='pdf'"
                                            (click)="gotopdfView(ret)" id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/upload files icon.png"
                                                            style="padding: 6px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  ||ret.DocumentType=='application/vnd.ms-excel' ||ret.DocumentType=='xlsx' ||ret.DocumentType=='xls'"
                                            (click)="gotopdfView(ret)" id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/xls.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='application/vnd.openxmlformats-officedocument.presentationml.presentation' ||ret.DocumentType=='ppt' ||ret.DocumentType=='pptx'"
                                            (click)="gotopdfView(ret)" id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/ppticon2.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='video/mp4' ||ret.DocumentType=='mp4'"
                                            (click)="ViewVideo(ret)" id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/video.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||ret.DocumentType=='docx'"
                                            (click)="gotopdfView(ret)" id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/docx.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='application/x-zip-compressed'  ||ret.DocumentType=='zip'"
                                            (click)="Viewzip(ret)" id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.jpg" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/zip.jpg"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span style="cursor: pointer;"> {{ret.DocumentName}}</span> -->
                                        </td>
                                        <td *ngIf="ret.DocumentType=='text/plain' ||ret.DocumentType=='txt'"
                                            (click)="gotopdfView(ret)" data-toggle="modal" data-target="#Viewtxt"
                                            id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/txt.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}}</span> -->
                                        </td>
                                        <td [hidden]="ret.DocumentType == 'text/plain' || ret.DocumentType == 'application/x-zip-compressed' || ret.DocumentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                          ret.DocumentType =='video/mp4' || ret.DocumentType =='application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                          || ret.DocumentType =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||ret.DocumentType=='application/vnd.ms-excel' || ret.DocumentType =='application/pdf' || ret.DocumentType =='image/jpeg'|| ret.DocumentType =='image/png'||
                                          ret.DocumentType=='png' ||ret.DocumentType=='jpeg' ||ret.DocumentType=='pdf' ||ret.DocumentType=='xls' ||ret.DocumentType=='xlsx' || ret.DocumentType=='zip'
                                          ||ret.DocumentType=='ppt' ||ret.DocumentType=='pptx' ||ret.DocumentType=='docx' ||ret.DocumentType=='txt'||ret.DocumentType=='jpg' ||ret.DocumentType=='PNG'"
                                            (click)="Viewanyfile(ret)" data-toggle="modal" data-target="#Viewanyfile"
                                            id="foldernamedisplay">

                                            <div class="col-md-12 row" style="margin-left: -5%;">
                                                <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay1">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>

                                            <!-- <img src="./assets/img/anyf1.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span style="cursor: pointer;">{{ret.DocumentName}} </span> -->
                                        </td>
                                        <td style="cursor: pointer;" id="modifiedonn">
                                            {{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                                        <td></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <br />
                    <br />
                </div>
                <div class="main-content" *ngIf="subfolderdisaply">
                    <div class="col-md-12 row" id="subfolderdiv">
                        <div class="col-md-2">&nbsp;</div>
                        <span (click)="GetFolderdetails()" id="Breadcrumb" style="cursor: pointer;"><mark
                                style="color: #9BAAB6;background-color: white;">
                                Home </mark> <img src="./assets/img/Vector122.png" />&nbsp;<mark id="Breadcrumb">
                                My Personal Files </mark></span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">&nbsp; <img src="./assets/img/Vector122.png" />&nbsp;
                            <span (click)="GetSubfoldersforBC(d)"
                                style="cursor: pointer;">{{d.FolderName}}</span>
                        </span>
                        <span id="Breadcrumbone" style="cursor: pointer;">&nbsp; <img src="./assets/img/Vector122.png" /> &nbsp;{{currentpopuppageone}}</span>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <!-- <img *ngIf="subfolderdisaply" src="./assets/img/back.png"
                                    style="width:23px;cursor: pointer;" (click)="GetBack(allSubFolders)" />
                                <span *ngIf="subfolderdisaply"
                                    style="text-align: center;font-size: 15px;font-family:  'DINNextRounded LT Pro',  ;color: black;">
                                    &nbsp;&nbsp;&nbsp;&nbsp;<b>{{currentfoldername}} > </b>
                                </span>
                            </div> -->

                            <div class="card" style="overflow-y: auto;height: 50vh;box-shadow: none; border: none;">
                                <div class="card-body table-responsive">
                                    <table class="table table-hover" *ngIf="allSubFolders">
                                        <thead style="color: #4F44C5;">
                                            <th></th>
                                            <th style="cursor: pointer;padding-left: 4%;" (click)="sortbyforsubfolder()">Name</th>
                                            <th style="cursor: pointer;padding-left: 0%;" (click)="sortbydateforsubfolder()">Modified
                                            </th>
                                        </thead>
                                        <tbody *ngFor="let folderview of allSubFolders.SubFolders">
                                            <tr>
                                                <td style="padding-top: 4%;">
                                                    <input type="checkbox" (click)="toggleSelect(folderview)"
                                                        id="checkboxstyle" />
                                                </td>
                                                <td (click)="GetSubfolderdetails(folderview)" id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/Vector 111.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{folderview.FolderName}}
                                                        </div>
                                                    </div>

                                                    <!-- <i
                                                            class="fas fa-folder" style="color: orange"></i>
                                                        &nbsp;{{folderview.FolderName}} -->
                                                </td>
                                                <td (click)="GetSubfolderdetails(folderview)" id="modifiedonn">
                                                    {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngFor="let ret of allSubFolders.RetData">
                                            <tr *ngIf="!ret.FolderName">
                                                <td style="padding-top: 4%;">
                                                    <input type="checkbox" (click)="toggle(ret)" id="checkboxstyle" />
                                                </td>
                                                <td *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'"
                                                    id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" /> </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>

                                                    <!-- <img [src]="ret.file"
                                                            style="width:50px;height:40px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="ViewPdf(ret)" data-toggle="modal"
                                                        data-target="#ViewPdf" -->
                                                <td *ngIf="ret.DocumentType=='application/pdf'" id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>

                                                    <!-- <img src="./assets/img/upload files icon.png"
                                                            style="padding: 6px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="ViewXls(ret)" data-toggle="modal"
                                                        data-target="#Viewxls" -->
                                                <td *ngIf="ret.DocumentType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||ret.DocumentType=='application/vnd.ms-excel'"
                                                    id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>


                                                    <!-- <img src="./assets/img/xls.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="ViewPpt(ret)" data-toggle="modal"
                                                        data-target="#ViewPpt" -->
                                                <td *ngIf="ret.DocumentType=='application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                                                    id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>
                                                    <!-- <img src="./assets/img/ppticon2.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="ViewVideo(ret)"
                                                        data-toggle="modal" data-target="#Viewvideo" -->
                                                <td *ngIf="ret.DocumentType=='video/mp4'" id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>

                                                    <!-- <img src="./assets/img/video.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="Viewword(ret)" data-toggle="modal"
                                                        data-target="#Viewword" -->
                                                <td *ngIf="ret.DocumentType=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                                    id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>
                                                    <!-- <img src="./assets/img/docx.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="Viewzip(ret)" data-toggle="modal"
                                                        data-target="#Viewzip" -->
                                                <td *ngIf="ret.DocumentType=='application/x-zip-compressed'"
                                                    id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.jpg"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>

                                                    <!-- <img src="./assets/img/zip.jpg"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="Viewtxt(ret)"
                                                        data-toggle="modal" data-target="#Viewtxt" -->
                                                <td *ngIf="ret.DocumentType=='text/plain'" id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>


                                                    <!-- <img src="./assets/img/txt.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>
                                                <!-- (click)="Viewanyfile(ret)" data-toggle="modal"
                                                        data-target="#Viewanyfile" -->
                                                <td [hidden]="ret.DocumentType == 'text/plain' || ret.DocumentType == 'application/x-zip-compressed' || ret.DocumentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                                        ret.DocumentType =='video/mp4' || ret.DocumentType =='application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                                        || ret.DocumentType =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  ||ret.DocumentType=='application/vnd.ms-excel' || ret.DocumentType =='application/pdf' || ret.DocumentType =='image/jpeg'|| ret.DocumentType =='image/png'"
                                                    id="foldernamedisplay1">

                                                    <div class="col-md-12 row" style="margin-left: -5%;">
                                                        <div class="col-md-1">
                                                            <img src="./assets/img/uploa_files.png"
                                                                style="cursor: pointer;" />
                                                        </div>
                                                        <div class="col-md-10" id="datadisplay2">
                                                            {{ret.DocumentName}}
                                                        </div>
                                                    </div>

                                                    <!-- <img src="./assets/img/anyf1.png"
                                                            style="width:35px;height:34px;cursor: pointer;" />
                                                        <span>
                                                            {{ret.DocumentName}}
                                                        </span> -->
                                                </td>

                                                <td id="modifiedonn"> {{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                                                <td></td>
                                                <!-- <td><a class="nav-link dropdown" href="javascript:void(0)" id="navbarDropdownMenuLink"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                              style="margin-top: 12px;">
                              <span style="font-family: DIN Next Rounded LT Pro;color:white;font-size: 15px;"><i
                                  class="fas fa-ellipsis-h" style="color: black"></i></span>

                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                              <a class="dropdown-item" (click)="ShareFileandFolders()">Share</a>
                              <a class="dropdown-item" (click)="MoveDoc(ret,'Img')">Move</a>
                              <a class="dropdown-item" (click)="Delete(ret)" data-toggle="modal"
                                data-target="#deleteExample">Delete</a>
                            </div>
                          </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer" id="footer">
                <div>
                    <button type="submit" id="SendBtn" class="btn btn-sm waves-light" mdbWavesEffect
                        data-dismiss="modal" data-toggle="modal" (click)="OnSelecttoShareFiles()">UPLOAD</button>
                    <button type="button" class="btn btn-sm waves-light" id="CancelBtn" data-dismiss="modal"
                        mdbWavesEffect>CANCEL</button>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- NEW UPLOAD FILES PROGRESS BAR MALLESHWARI-->

<!-- <div class="col-lg-12 row" *ngIf="uploadshowper">
    <div class="col-lg-3"></div>
    <div class="col-lg-7" id="mainprogressbardiv">
        <p *ngIf="errormsg"><b style="color: #4F44C5;">{{notify}} </b></p>
        <p id="mainprogress">
            <b id="msgstyle" *ngIf="showuploading && !showuploaded">{{uploadMsg}} </b>
            <b id="progressbarcancel" style="cursor: pointer;" (click)="CloseProgressbar()">Cancel</b>
            <b id="msgstyle" *ngIf="showuploaded &&  !showuploading">{{UploaddoneMsg}} </b>
            <br />
            <progress value={{n}} max="100" id="progressbarstyle"> </progress>
        </p>
        <p style="text-align: center;margin-top: -2%;" id="percentcolor">{{n}}%</p>
    </div>
</div> -->

<!-- <div class="col-lg-12 row" *ngIf="uploadshowper">
        <div class="col-lg-3"></div>
        <div class="col-lg-7" id="mainprogressbardiv">
            <img id="expandprogressbar" src="./assets/img/expandbtn.png" (click)="minimizeprogressbar()" />
    
            <p style="box-shadow: white;background-color: white;margin-top: 5%;">
                <b id="msgstyle1" *ngIf="!showuploaded &&  showuploading">{{uploadMsg}} </b>
                <b id="msgstyle1" *ngIf="showuploaded &&  !showuploading">{{UploaddoneMsg}} </b>
                <br />
                <progress value={{percent}} max="100" id="progressbarstyle"> </progress>
            </p>
            <p style="text-align: center;margin-top: -2%;">{{percent}}%</p>
        </div>
    </div> -->


<!-- MINIMIZED BAR FILE BY MALLESWARI -->
<!-- <div class="col-lg-12 row" *ngIf="minimizedprogressbar">
        <div class="col-lg-10"></div>
        <div class="col-lg-2" style="position: fixed;top: 89%;left: 84%;">
            <p id="footerprogressbar">
                <a id="uploadingtxt"> Uploading Files...</a>
                <img src="./assets/img/minimize.png" style="margin-top:2%;margin-right: 5%;cursor: pointer;
                        float: right;" (click)="viewmainprogressbar()" />
            </p>
        </div>
    </div> -->



<style>
    progress {
        border: none;
        background: crimson;
        border-radius: 5px;
    }

    progress::-webkit-progress-value {
        background: #4F44C5;
        border-radius: 5px;
    }

    progress::-webkit-progress-bar {
        background: #D7E1EB;
        border-radius: 5px;
    }
</style>