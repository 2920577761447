<div *ngIf="Unregister">
<div class="container">
    <header>
        <h1 id="title">SRICHID BLOCKCHAIN SERVICES PRIVATE LIMITED</h1>
        <p id="description">Creating new client for the Blocdrive users</p>
    </header>
    <form id="survey-form" action=""  (ngSubmit)="OnSubmit()">


        <!-- <form name="register" action="" > -->
        <label id="name-label" for="name">Client Name</label>
        <input id="name" type="text" placeholder="Enter your name" name="Username" value="clientName" [(ngModel)]="clientName" />

        <br />

        <label id="email-label" for="email">Client Email</label>
        <input id="email" type="email" placeholder="Enter your Email" *ngIf="!onlyphone" value="clientEmail"   name="Email"  [(ngModel)]="clientEmail" />

        <br />
   
        <label id="password-label" for="password">Password</label>
        <input id="password" type="password" placeholder="Enter Password" name="Password" [(ngModel)]="Password" />


        <br />
        <div *ngIf="otp && !onlyphone">
            <label id="otp-label" for="otp">Otp</label>
            <input type="number" id="otp" name="otp" placeholder="Verify otp" [(ngModel)]="otp" />
        </div>
        <div *ngIf="otp && onlyphone">
            <label id="otp-label" for="otp">Otp</label>
            <input type="number" id="otp" name="otpp" placeholder="Verify otp" [(ngModel)]="otpp" />
        </div>
        <br />
        
        <div>
          <mark> Sign Up</mark> &nbsp;to accept and agree to the
            <a [routerLink]="['/termsofservice']">Terms and Conditions</a> and
            <a [routerLink]="['/privacy']">Privacy Policy</a>
        </div>
        <br />
        <div *ngIf="!otp">
            <button id="submit" *ngIf="!expired">SIGN UP</button>
        </div>
        <br />
        <div *ngIf="otp">
            <button id="submit" *ngIf="!expired">SIGN UP</button>
        </div>
    </form>
</div>
</div>
<div *ngIf="registered">
 
    
    <!--Modal: modalDiscount-->
    <div >
        <div >
            <!--Content-->
            <div class="container">
                <!--Header-->
                <div  style="    justify-content: center;">
                    <h4 class="heading">
                        <strong>Approval..</strong>
                    </h4>
                </div>
    
                <!--Body-->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <img src="assets/img/welcome.svg" alt="" width="600px"><br>
                            <h4 style="text-align: center;">
                                Thank you! you are being registered as client of {{username}} with your existing <u>blocdrive</u> account.
                            </h4>
                        </div>
                    </div>
                </div>
    
                <!--Footer-->
                <div class="modal-footer flex-center">
                    <a (click)="savenewClient()" class="btn btn-success">Ok
                    </a>
                </div>
            </div>
            <!--/.Content-->
        </div>
    </div>
    <!--Modal: modalDiscount-->
</div>
