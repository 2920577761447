import { Component, OnInit } from '@angular/core';
import { AdminDashboardService } from '../Shared/AdminDashboard/admin-dashboard.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  projectsSummary: any
  showhidevalue: boolean;
  Teamprojects: any;
  p: number = 1;
  ProjectCompletionPercentage: any;
  roleid: string;
  mobileview: boolean;
  uid: string;
  constructor(private admindashboardservice: AdminDashboardService,private router:Router,private spinner:NgxSpinnerService) { 
    this.roleid = localStorage.getItem("role");
    this.uid = localStorage.getItem("userId");

    this.isMobileMenu();

  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  ngOnInit(): void {
    this.GetProjectsSummary();
  }
  GetProjectsSummary() {
    this.spinner.show();
    this.admindashboardservice.getdashboardProjectsSummary(this.uid).subscribe((respose: any) => {
      this.projectsSummary = respose;
      this.spinner.hide();

    });
  }
  GetCurrentProjects(id) {
    if (id == 1) {
      this.GetAllProjectsList();
      this.showhidevalue = true;
    }
    else if (id == 2) {
      this.GetProjectsList(4);
      this.showhidevalue = true;
    }
    else if (id == 3) {
      this.GetProjectsList(2);
      this.showhidevalue = true;
    }
    else if (id == 4) {
      this.GetProjectsList(5);
      this.showhidevalue = true;
    }
  }
  GetAllProjectsList() {

    this.spinner.show();
    this.admindashboardservice.getdashboardAllProjects(this.uid).subscribe((respose: any) => {
      this.Teamprojects = respose;
      this.spinner.hide();

    });
  }
  GetProjectsList(id) {
    var Statusuid=id + "," +  this.uid
    this.spinner.show();
    this.admindashboardservice.getdashboardProjects(Statusuid).subscribe((respose: any) => {
      this.Teamprojects = respose;
      this.spinner.hide();

    });
  }
  ProjPassdata(list, percentage) {
        this.ProjectCompletionPercentage = percentage;
        this.spinner.show();
    this.admindashboardservice.getProjData(list.id)
    .subscribe((respose: any) => {
      localStorage.setItem("ProjectData",respose) ;
      localStorage.setItem("ProjectList",list);
      localStorage.setItem("ProjectData", JSON.stringify(respose)); 
      this.spinner.hide();
      this.router.navigate(['/projectview']);          
        });
};
}
