<app-nav-bar></app-nav-bar>

<br />
<br />
<br />
<br />


<div class="col-md-12 row">

    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-2">
        <div style="width: 100%;">
            <div style="width: 50%;float: left;">
                <i *ngIf="mobile" class="fa fa-bars" (click)="treeviewba()" style="margin-left: 10%;"> </i>
            </div>
        </div>
    </div>

    <div class="col-md-12 row">
        <div class="col-md-2" style="overflow: auto;padding-left: 35px;" id="treeview">


            <div *ngIf="ppopup">
                <div *ngFor="let a of allFolders1" id="{{a.folderID}}">

                    <span><i class="fa fa-caret-right"  style="cursor: pointer;" (click)="getfolders1(a)"
                            id="{{a.id}}"></i> <img width="30px" height="30px" src="./assets/img/folder.svg"
                            (click)="GetSubfolder(a)">
                        <span (click)="GetSubfolder(a)" style="cursor: pointer;">{{a.folderName}}</span></span>

                </div>
            </div>



        </div>
        <div>&nbsp;</div>
        <div class="col-md-9" style="display: flex;justify-content: center;  ">
  
            <table  class="table table-striped table-hover table-sm mb-0" style="overflow-x: unset;width: auto;">
                <thead style="color: #33383e;">
                    <th>Name </th>
                    <th>Modified</th>
                    <th>Members</th>
                    <th>Operations</th>
                </thead>
                <tbody *ngFor="let folderview of allFolders">
                    <tr>
                        <td *ngIf="folderview.folderID!=null" style="text-align: center;"> <input type="checkbox" (click)="toggleSelect(folderview)"
                                style="height: 16px;width:16px;">  &nbsp;&nbsp;
                        </td>
                        <td *ngIf="folderview.folderID!=null"><i class="fa fa-folder"  style="cursor: pointer;color: rgb(84 118 254);"></i>
                            &nbsp;&nbsp;
                            <b (click)="GetSubfolder(folderview)" style="cursor: pointer;">{{folderview.folderName}}</b>
                        </td>
                        <td *ngIf="folderview.folderID!=null" style="font-size: small;">{{folderview.createdOn | date:'short'}}</td>
                        <td *ngIf="folderview.folderID!=null">{{folderview.totalMembers}} &nbsp;members</td>
                        <td *ngIf="!multi && folderview.folderID!=null">
                            <div ngbDropdown class="dropdown" placement="bottom-left">
                                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                                        aria-hidden="true" style="cursor: pointer;"></i></a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                    <a class="dropdown-item" (click)="Delete(folderview)">Delete</a>
                                    <a class="dropdown-item" *ngIf="folderview.isOwner" (click)="ShareFolder(folderview, shareSingleFolder)"
                                    style="cursor: pointer;">Share</a>
                                    <a class="dropdown-item" (click)="filestosaveinMPF(folderview)">Save in My Files</a>

                                </div>
                            </div>
                        </td>
                        <td *ngIf="multi && folderview.folderID!=null">
                            <div ngbDropdown class="dropdown" placement="bottom-left">
                                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                                        aria-hidden="true" style="cursor: pointer;"></i></a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                    <a class="dropdown-item" (click)="Deleteselected()">Delete</a>
                                    <a *ngIf="enablemultishare" class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)"
                                    style="cursor: pointer;">Share</a>
                                    <a class="dropdown-item" (click)="saveinmyfiles()">Save in My Files</a>

                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngFor="let ret of SharedFiles">
                    <tr>
                        <td style="text-align: center;"> <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px;width:16px;">

                            <input type="checkbox" *ngIf="shareitem" (click)="toggle(ret, 'doc')"
                                style="height: 16px;width:16px;margin-top: 28px;">
                            <input type="checkbox" *ngIf="deleteitem" (click)="toggleDelete(ret, 'doc')"
                                style="height: 16px;width:16px;margin-top: 28px;">  &nbsp;&nbsp;
                                </td>
                        <td *ngIf="ret.documentType=='image/jpeg'||ret.documentType=='image/png'">
                            <img src="{{ret.file}}" style="width:50px;height:40px;cursor: pointer;"
                                (click)="ViewImageDoc(ret,content)" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/pdf'||ret.documentType=='pdf'"
                            (click)="ViewDocument(ret,pdftesting)" data-toggle="modal" data-target="#ViewPdf">
                            <img src="assets/img/pdf.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || ret.documentType=='xlsx'"
                            (click)="ViewDocument(ret,pdftesting)">
                            <img src="assets/img/xls.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/vnd.openxmlformats-officedocument.presentationml.presentation' || ret.documentType=='ppt' ||ret.documentType=='pptx'"
                            (click)="ViewDocument(ret,pdftesting)">
                            <img src="assets/img/ppticon2.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='video/mp4' || ret.documentType=='mp4'" (click)="ViewDoc(ret)">
                            <img src="assets/img/video.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || ret.documentType=='doc' || ret.documentType=='docx'"
                            (click)="ViewDocument(ret,pdftesting)">
                            <img src="assets/img/docx.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='application/x-zip-compressed' ||ret.documentType=='zip' ">
                            <img src="assets/img/zip.jpg" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td *ngIf="ret.documentType=='text/plain' || ret.documentType=='txt'"
                            (click)="ViewDocument(ret,pdftesting)">
                            <img src="assets/img/txt.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>
                        <td
                            [hidden]="ret.documentType == 'text/plain' || ret.documentType == 'txt' || ret.documentType == 'application/x-zip-compressed' || ret.documentType == 'zip' || ret.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||ret.documentType == 'doc' || ret.documentType == 'docx' ||
                    ret.documentType =='video/mp4'|| ret.documentType =='mp4' || ret.documentType =='application/vnd.openxmlformats-officedocument.presentationml.presentation'|| ret.documentType =='ppt'|| ret.documentType =='pptx'
                    || ret.documentType =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || ret.documentType =='xlsx' || ret.documentType =='application/pdf' || ret.documentType =='image/jpeg'|| ret.documentType =='image/png'||ret.documentType=='pdf'">
                            <img src="assets/img/anyf1.png" style="width:35px;height:34px;cursor: pointer;" />
                            <span style="cursor: pointer;">
                                &nbsp;&nbsp;{{ret.documentName}}
                            </span>
                        </td>

                        <td style="font-size: small;"> {{ret.date | date:'short'}}</td>
                        <td>{{ret.documentType}}</td>
                        <td *ngIf="!multi">
                            <div ngbDropdown class="dropdown" placement="bottom-left">
                                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                                        aria-hidden="true" style="cursor: pointer;"></i></a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                    <a class="dropdown-item" (click)="Delete(ret)">Delete</a>
                                    <a class="dropdown-item" *ngIf="ret.isOwner" (click)="ShareoneFile(ret, shareSingleFile)"
                                    style="cursor: pointer;">Share</a>
                                    <a class="dropdown-item" (click)="filestosaveinMPF(ret)">Save in My Files</a>

                                </div>
                            </div>
                        </td>
                        <td *ngIf="multi">
                            <div ngbDropdown class="dropdown" placement="bottom-left">
                                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                                        aria-hidden="true" style="cursor: pointer;"></i></a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                    <a class="dropdown-item" (click)="Deleteselected()">Delete</a>
                                    <a *ngIf="enablemultishare" class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)"
                                    style="cursor: pointer;">Share</a>

                                    <a class="dropdown-item" (click)="saveinmyfiles()">Save in My Files</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="col-md-1"></div>

    </div>
</div>


<br />
<br />
<br />
<!-- IMAGE VIEW -->
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
        <h6 style="float: left;font-size: small;">
            Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="imgdisplay.file" style="cursor: pointer;"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left;font-size: small;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float: left;font-size: small;">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <video controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">
    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <audio controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>
<div class="modal modal-angular fade" id="deletemoreinshare" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content" id="popupdisp">
            <div class="modal-header">
                <!-- <h4 class="modal-title w-100" id="myModalLabel" style="float: left;">{{shareitemname}}
                </h4> -->
                <button type="button" id="closeinto" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div>
                <p class="modal-title w-100" id="folderText">Delete Files</p>
            </div>
            <div class="modal-body">
                <p id="deletetext">Are you sure you want to delete {{deletemulticontent}} ?</p>
            </div>

            <div class="modal-footer" id="footer">
                <button type="submit" id="DeleteSendBtn" class="btn btn-success btn-sm waves-light" data-dismiss="modal"
                    (click)="Deleteselected()" mdbWavesEffect style="color:white">DELETE</button>
                <button type="button" id="DeleteCancelBtn" class="btn btn-warning btn-sm waves-light"
                   data-dismiss="modal" mdbWavesEffect>CANCEL</button>
            </div>
        </div>
    </div>
</div>
<ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
      <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
        {{ mem.friendName }}<br />
      </div>
      <div class="col-lg-12 row">
        <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
          <option value=""> Select Friend </option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
            {{ I.friendName }}
          </option>
        </select>
      </div>
      <br />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()" style="cursor: pointer;"
        (click)="d('Cross click')">
        Share
      </button>
    </div>
    <br />
  </ng-template>
  <ng-template #shareSingleFile let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
      <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
        {{ mem.friendName }}<br />
      </div>
      <div class="col-lg-12 row">
        <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
          <option value=""> Select Friend </option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}">
            {{ I.friendName }}
          </option>
        </select>
      </div>
      <br />
    </div>
  
    <!-- <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
        <option value="" hidden> Select Friend </option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
          {{ I.friendName }}
        </option>
      </select>
    </div> -->
    <br />
 
   
    <br />
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="SharOnlyFileToUsers()" (click)="d('Cross click')">
        Share
      </button>
    </div>
    <br />
  </ng-template>
  
  <ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
  
  
      <br />
    </div>
  
    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
        <option value="" hidden> Select Friend </option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />
  
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ShareExsistingSubFolderToUsers()" (click)="d('Cross click')">
        Share
      </button>
    </div>
    <br />
  </ng-template>
