import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { branch } from 'src/app/ViewModels/Role';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  readonly url="https://blocdrive.in/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
    saveBranch(data:branch){
      return this.http.post(this.url +"BranchMaster/PostSaveBranch",data);
    }
  
    getAllBranchs(id){
      return this.http.get(this.url +"BranchMaster/GetAllBranch/" + id);
    }
    getAllBranchdata(id){
      return this.http.get(this.url +"BranchMaster/GetAllBranchdata/" + id);
    }
    deleteBranch(id: number){
      return this.http.get(this.url +"BranchMaster/GetDeleteBranchData/"+ id);
    }
  
    UpdateBranch(data:branch){
      return this.http.post(this.url +"BranchMaster/PutBranch",data);
    }
}
