import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { AnalyticsService } from 'src/app/Shared/Analytics/analytics.service';
import { LoginService } from 'src/app/Shared/Login/login.service';

@Component({
  selector: 'app-file-upload-option',
  templateUrl: './file-upload-option.component.html',
  styleUrls: ['./file-upload-option.component.css']
})
export class FileUploadOptionComponent implements OnInit {
  UserId: any;
  EmailId: any;
  Role: any;
  Username: any;
  fileselect: boolean = true;
  excel: boolean = false;
  csv: boolean = false;
  mssql: boolean = false;
  mysql: boolean = false;
  dbname:any;
  Files: any;
  selectedfiletype:any;
  files1: any;
  SelectedFiles: string="";
  masterid: any;
  constructor(private loinservice: LoginService,private httpService: HttpClient, private router: Router, 
    private analyticsService: AnalyticsService,private _urls: AppGlobals) {
    if (localStorage.getItem("IsLoggedIn") == 'true') {
      this.UserId = localStorage.getItem("userId");
      this.EmailId = localStorage.getItem("Email");
      this.Role = localStorage.getItem("role");
      this.Username = localStorage.getItem("UserName");
      this.dbname= localStorage.getItem("projname");
    }

  }
  ngOnInit(): void {
    this.getFile();
  }
  selectExcelfile() {
    this.getFile();
    this.fileselect = false;
    this.excel = true;
    this.selectedfiletype="Excel";
    this.csv = false;
    this.mssql = false;
    this.mysql = false;
    this.getFile();
  }
  selectCsvfile() {
    this.fileselect = false;
    this.excel = false;
    this.csv = true;
    this.selectedfiletype="CSV";
    this.mssql = false;
    this.mysql = false;
  }
  selectSqlfile() {
    this.fileselect = false;
    this.excel = false;
    this.csv = false;
    this.mssql = true;
    this.selectedfiletype="Excel";
    this.mysql = false;
  }
  selectMysqlfile() {
    this.fileselect = false;
    this.excel = false;
    this.csv = false;
    this.mssql = false;
    this.mysql = true;
    this.selectedfiletype="Excel";
  }
    onChange(event: any) {
    var fileslist = "";
    this.files1 = [].slice.call(event.target.files);
    console.log(this.files1);
    fileslist = this.files1[0];
    this.SelectedFiles = fileslist;
  }
  getFile(){
    this.analyticsService.getFiles(this.dbname+","+this.selectedfiletype).subscribe(
      (res: any) => {
        this.Files = res;
        console.log(this.Files);
      }
    )
  }
  Uploadexcel(){
    
    // this.spinner.show(); 
    const frmData = new FormData();
    this.masterid = this.dbname + "," + "1";
    // frmData.append("RegData", JSON.stringify(inputRequest));
    frmData.append("picture", this.SelectedFiles);
    frmData.append("masterid",this.masterid);

    // frmData.append("logo", inputRequest);   
    this.httpService.post(this._urls.ANAURL+'fileUpload/UploadFiles', frmData).subscribe(

      data => {
        this.getFile();
        // this.spinner.hide();
        // this.router.navigate(['/login']);
      });
  }

  uploadexceldata(data:any){
    this.analyticsService.excelUpload(data+","+this.dbname).subscribe(
      (res: any) => {
        this.Files = res;
        alert("Uploaded successfully!!!");
        this.getFile();
        console.log(this.Files);
      }
    )
  }
  getFilecsv(){
    this.analyticsService.getCsvfiles(this.dbname+","+this.selectedfiletype).subscribe(
      (res: any) => {
        this.Files = res;
        console.log(this.Files);
      }
    )
  }
  Uploadcsv(){
  // this.spinner.show(); 
  const frmData = new FormData();
  this.masterid = this.dbname + "," + "4";
  // frmData.append("RegData", JSON.stringify(inputRequest));
  frmData.append("picture", this.SelectedFiles);
  frmData.append("masterid",this.masterid);

  // frmData.append("logo", inputRequest);   
  this.httpService.post(this._urls.ANAURL+'fileUpload/UploadFiles', frmData).subscribe(

    data => {
      this.getFile();
      // this.spinner.hide();
      // this.router.navigate(['/login']);
    });
  }
  viewExcel(aa:any){
    localStorage.setItem("filename",aa);
    this.router.navigate(['/viewRawDataset']);
    // this.analyticsService.getValue(aa+","+this.dbname).subscribe(
    //   (res: any) => {
    //     this.Files = res;
    //     console.log(this.Files);
    //   }
    // )
  }
  Uploadmssql(){
    // this.spinner.show(); 
    const frmData = new FormData();
    this.masterid = this.dbname + "," + "2";
    // frmData.append("RegData", JSON.stringify(inputRequest));
    frmData.append("picture", this.SelectedFiles);
    frmData.append("masterid",this.masterid);
  
    // frmData.append("logo", inputRequest);   
    this.httpService.post(this._urls.ANAURL+'fileUpload/UploadFiles', frmData).subscribe(
  
      data => {
        this.getFile();
        // this.spinner.hide();
        // this.router.navigate(['/login']);
      });
    }
    Uploadmysql(){
      // this.spinner.show(); 
      const frmData = new FormData();
      this.masterid = this.dbname + "," + "3";
      // frmData.append("RegData", JSON.stringify(inputRequest));
      frmData.append("picture", this.SelectedFiles);
      frmData.append("masterid",this.masterid);
    
      // frmData.append("logo", inputRequest);   
      this.httpService.post(this._urls.ANAURL+'fileUpload/UploadFiles', frmData).subscribe(
    
        data => {
          this.getFile();
          // this.spinner.hide();
          // this.router.navigate(['/login']);
        });
      }
}