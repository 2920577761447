import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrls: ['./termsofservice.component.scss']
})
export class TermsofserviceComponent implements OnInit {

  constructor() {
    window.scrollTo(0,0);

   }

  ngOnInit() {
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if(footer.style.display=="none"){
        footer.style.display = "block";
      }
    }, 1000);
    
  }

}
