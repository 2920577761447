import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { MessageService } from 'src/app/Shared/MessageService';
import { WorkFlowMasterService } from 'src/app/Shared/WorkFloMaster/work-flow-master.service';

@Component({
  selector: 'app-workflow-master',
  templateUrl: './workflow-master.component.html',
  styleUrls: ['./workflow-master.component.css']
})
export class WorkflowMasterComponent implements OnInit {
  Workflows: any;
  wid:any;
  workfolwname: string;
  roleDesc: any;
  rolid: number;
  showSave:any;
  showUpdate:any;
  p:number = 1
  public dismissalert = false;
  notify: string
  createdby: any;
  createdon: any;
  workfolwdesc:string;
  GUID: string;
  roleid: string;
  username: string;
  uid: string;
  constructor(private messageService: MessageService,private auditortransactionservice:AuditorTransactionService
    ,private workflowservice: WorkFlowMasterService,private spinner: NgxSpinnerService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }

   }

 
  ngOnInit() {
    this.GetWorkflows();
    this. GetWorkflowdata();
    this.workfolwname = "";
    this.roleDesc = "";
  }
  Updateworkflow(form: NgForm) {
    this.spinner.show();
    const inputRequest1= {
      WorkflowMasterId: this.wid ,
      WorkflowName: form.controls["workfolwname"].value,
      WorkflowDescription: form.controls["workfolwdesc"].value,
      HostId:"",
      CreatedBy:  this.createdby,
      CreatedOn:  this.createdon,
      GUID:this.GUID 

    }
    this.workflowservice.UpdateworkflowRow(inputRequest1).subscribe(
      (data: any) => {
          if (data != null) {
            this.notify = "Workflow updated successfully"
            const audit = {
              TransactionName:"Master",
              TransactionType:"Update",
              TransactionDesc:"Workflow master",
  
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetWorkflows();
            this.spinner.hide();
        
        }
        else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflows();
          this.spinner.hide();
        }
      }
    );
  }
  SaveworkflowD(form: NgForm) {
    this.spinner.show();
    const inputRequest = {
      WorkflowMasterId: 0,
      WorkflowName: form.controls["workfolwname"].value,
      WorkflowDescription: form.controls["workfolwdesc"].value,
      HostId:"",
      CreatedBy:"",
      CreatedOn:"",
      GUID:this.GUID 
 
    }
    this.workflowservice.saveworkflow(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Workflow saved successfully"
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Workflow master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflows();
          this.spinner.hide();
         
        }
        else{
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflows();
          this.spinner.hide();
        }
      }
    );
  }
  GetWorkflows() {
    this.spinner.show();
    this.workflowservice.getworkflows(this.GUID ).subscribe((response: any) => {
      this.Workflows = response;
      this.spinner.hide();
    });
  }

  GetWorkflowdata() {
    this.spinner.show();
    this.workflowservice.getworkflowdata(this.roleid).subscribe((response: any) => {
      this.Workflows = response;
      this.spinner.hide();
    });
  }
  editRole(data:any){
    this.showSave = false;
    this.showUpdate = true;
    this.wid = data.workflowMasterId;
    this.workfolwname = data.workflowName;
    this.workfolwdesc = data.workflowDescription;
    this.createdby=data.createdBy;
    this.createdon=data.createdOn;
  }
  addClick(){
    this.showSave = true;
    this.showUpdate = false;
    if (this.workfolwname != "") {
      this.workfolwname = "";
    }
  }
  deleteWorkflows(data:any){
    this.rolid = data.workflowMasterId;
  }
  deleteWorkflowsRow(){  
    this.spinner.show();
      this.workflowservice.deleteWorkflowsdata(this.rolid).subscribe(
        (data: any) => {
          if (data != null) {
            this.notify = "Workflow deleted successfully"
            const audit = {
              TransactionName:"Master",
              TransactionType:"Delete",
              TransactionDesc:"Workflow master",
  
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetWorkflows();
            this.spinner.hide();
           
          }else{
            this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetWorkflows();
          this.spinner.hide();
          } 
        });
    }
}
