import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from '../Shared/MessageService';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../Shared/Login/login.service';
import { FormControl, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
@Component({
  selector: 'app-gotorouting',
  templateUrl: './gotorouting.component.html',
  styleUrls: ['./gotorouting.component.css']
})
export class GotoroutingComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });
  email: any;
  password: any;
  isLoginError: boolean;
  result: any;
  mobileview: boolean;
  redirectlink:any;
  extra:any
  constructor(private route:ActivatedRoute,private spinner: NgxSpinnerService, private messageService: MessageService, private router: Router, private loginService: LoginService) {
    this.isMobileMenu();
    this.route.queryParams.subscribe((redirect:any)=>{
      this.redirectlink=redirect['redirect'];
      this.extra = redirect['action'];
    })
    if(localStorage.userId){
      if(this.extra!=undefined && this.extra!=null)
      this.router.navigate(['/'+this.redirectlink],{ queryParams: { action: this.extra } });
      else{
        this.router.navigate(['/'+this.redirectlink]);
      }
    }
  }

  ngOnInit(): void {
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  OnSubmit() {
    this.email = this.loginForm.controls["email"].value;
    this.password = this.loginForm.controls["password"].value;
    this.spinner.show();
    if (this.email == null || this.email == "") {
      alert("Please enter Your Email");
    }
    else if (this.password == null || this.password == "") {
      alert("Please enter Your Password");
    }
    else {
      this.loginService.userAuthentication(this.email, this.password).subscribe((data: any) => {
        this.result = data;
        localStorage.setItem('IsLoggedIn', "true");
        localStorage.setItem('userToken', data.access_token);
        localStorage.setItem('authorizationData', data);
        localStorage.setItem('Token_type', data.token_type);
        localStorage.setItem("UserFullName", data.fullName);
        localStorage.setItem("role", data.roleName);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("MailId", data.mailId);
        //   localStorage.setItem('authdata', JSON.stringify(movies));
        this.spinner.hide();


        // send message to subscribers via observable subject
        this.messageService.sendMessage('true');
        this.messageService.sendName(data.userName);
        if(localStorage.role!=11 || localStorage.role!=9){
          console.log(localStorage.role)
          this.loginService.wheretogo(localStorage.userId).subscribe((response)=>{
            if(this.extra!=undefined && this.extra!=null)
            this.router.navigate(['/'+this.redirectlink],{ queryParams: { action: this.extra } });
            else{
              this.router.navigate(['/'+this.redirectlink]);
            }          })
        }
        //this.router.navigate(['/bonlinedashboard']);

      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isLoginError = true;
        }

      )
    }
  }
}
