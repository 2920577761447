<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="register">
    <h2 style="text-align: center">Designation List</h2>
    <div>&nbsp;</div>
    <div class="row col-sm-12">
        <div class="col-sm-1"></div>
        <div class="col-sm-2">
            <button class="btn btn-primary" (click)="addDesignation(addesignation)">
        Add
      </button>
        </div>
        <div class="col-sm-9"></div>
    </div>
    <br />
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>Role</th>
                            <th>Department</th>
                            <th>Designation Name</th>
                            <th>Created By</th>
                            <th>Created On</th>
                            <th>Modified By</th>
                            <th>Modified On</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                        <tr *ngFor="
                let aa of DesignationList
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              " style="
                font-size: 16px;
                color: rgb(0, 0, 0);
                font-family: DINNextRoundedLTPro;
              ">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ GetRole(aa.roleId) }}</td>
                            <td>{{ GetDepartment(aa.departmentId) }}</td>
                            <td>{{ aa.designationName }}</td>
                            <td>{{ aa.createdBy }}</td>
                            <td>{{ aa.createdOn | date: "short" }}</td>
                            <td>{{ aa.modifiedBy }}</td>
                            <td>{{ aa.modifiedOn | date: "short" }}</td>
                            <td>
                                <button class="btn btn-success" (click)="EditDesignation(aa, updatedesignation)">
                  Edit
                </button>
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteDesignation(aa, deletedesignation)">
                  Delete
                </button>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<br /><br />

<ng-template #addesignation let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Designation</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <select class="form-control  dropdownsize" (change)="onSelectedRole($event.target.value)">
      <option value="">Select Role</option>
      <option *ngFor="let role of RoleList" value="{{ role.id }}">
        {{ role.roleName }}
      </option></select
    ><br />
    <select
      class="form-control dropdownsize"
      (change)="onSelectedDepartment($event.target.value)"
    >
      <option value="">Select Department</option>
      <option *ngFor="let dep of DepartmentList" value="{{ dep.id }}">
        {{ dep.majorDepartmentName }}- {{ dep.code }}
      </option>
    </select>
        <br />
        <h5 class="modal-title" id="modal-basic-title">Designation Name:</h5>
        <input type="text" id="designationname" [(ngModel)]="designationname" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="SaveDesignation()" (click)="d('Cross click')" style="cursor: pointer">
      Save
    </button>
    </div>
    <br />
</ng-template>
<ng-template #updatedesignation let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Designation</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <select class="form-control dropdownsize" (change)="onSelectedRole($event.target.value)">
      <option value="">Select Role</option>
      <option
        *ngFor="let role of RoleList"
        value="{{ role.id }}"
        [selected]="role.id == editrole"
      >
        {{ role.roleName }}
      </option></select
    ><br />
    <select
      class="form-control dropdownsize"
      (change)="onSelectedDepartment($event.target.value)"
    >
      <option value="">Select Department</option>
      <option
        *ngFor="let dep of DepartmentList"
        value="{{ dep.id }}"
        [selected]="dep.id == editdepid"
      >
        {{ dep.departmentName }}
      </option></select
    ><br />
    <h5 class="modal-title" id="modal-basic-title">Designation Name:</h5>
    <input
      type="text"
      id="editdesignationName"
      [(ngModel)]="editdesignationName"
      class="form-control"
      name="dp"
    />
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-info"
      (click)="UpdateDesignation()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Update
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deletedesignation let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Designation</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="DeleteDesignationRow()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div
      class="alert alert-info"
      style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      "
    >
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="closealert()"
      >
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>