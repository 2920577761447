import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { addresslist } from 'src/app/ViewModels/addresslist';
import { SavepaymentDetails } from 'src/app/ViewModels/SavePaymentDet';
import { OtpViewModel } from 'src/app/ViewModels/OtpViewModel';
import { Signup } from 'src/app/ViewModels/Signup';
import { PayU } from 'src/app/ViewModels/Payment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  readonly url = "https://blocdrive.in/blocdriveapi";
  readonly cmsurl = "https://blocdrive.in/blocdriveapi/api/Account/Savesresponse";
  readonly portableurl = "https://blocdrive.in/blocdriveapi/api/DownloadFolderorFile/GetDownloadBSAFEPortableFile";

  constructor(private http: HttpClient) { }
  userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded;', 'No-Auth': 'True' });
    return this.http.post(this.url + '/token', data, { headers: reqHeader });
  }
  Savesresponse(responce)
  {
    return this.http.post(this.cmsurl,responce);
  }
  downloadFile(){
    return this.http.get(this.url);
  }
  downloadFileportable(){
    return this.http.get(this.portableurl);
  }
  wheretogo(uid){
    return this.http.get(this.url+"/api/Account/wheretogo/"+uid);
  }
  postVerifyOTP(data:OtpViewModel){
    return this.http.post(this.url + "/api/Account/OTPValidation", data);
  }
  getroles(){
    return this.http.get(this.url+"/api/Account/getroles");
  }
  saveDetails(data: Signup) {
    return this.http.post(this.url + "/api/account/register", data);
  }
  LoginVerification(data){
    return this.http.post(this.url + "/api/LoginTest/LoginVerification", data);
  }
  //payu
  getpaymentstatus(aa: PayU) {
    return this.http.post(this.url + "/api/Payu/GetPaymentStatus", aa);
  }  
  getorderpayment(mm: addresslist) {
    return this.http.post(this.url + "/api/Payu/GetOrderDetailsforPayment", mm);
  }
  getPayuPlans() {
    return this.http.get(this.url + "/api/Payu/GetPlanList");
  }
  SaveOrderDetails(aaa: SavepaymentDetails) {
    return this.http.post(this.url + "/api/Payu/GetOrderPayments", aaa);
  }
  getextratSeqlink(data){
    return this.http.post(this.url + "/api/Recipients/PostUserlandingPage" , data);
  }

}
