import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MailService } from '../Shared/Mail/mail.service';
import { accountactivate, gateway } from '../ViewModels/clientcreation';

@Component({
  selector: 'app-mail-gate-way',
  templateUrl: './mail-gate-way.component.html',
  styleUrls: ['./mail-gate-way.component.css']
})
export class MailGateWayComponent implements OnInit {
  InboxList: any;
    GUID: string;
    usermail: string;
    userName: string;
    roleid: string;
    uid: string;
    isLoggedIn: boolean;
    submit: boolean;
    data: any;
    logindetails: any;
    cusid: any;
    searchText: any;
    p:number=1
    activestate: boolean=false;
    deactivestate: boolean=false;
    status: any;
    values: boolean;
    constructor(  private spinner: NgxSpinnerService,private mailservice: MailService, private route: Router,) {
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.isLoggedIn = true;
        this.uid = localStorage.getItem("userId");
        this.roleid = localStorage.getItem("role");
        this.userName = localStorage.getItem("UserName");
        this.usermail = localStorage.getItem("MailId");
        this.GUID = localStorage.getItem("GUID");
  
      }
     }
  
    ngOnInit(): void {
      this.GetInboxList();
    }
    GetInboxList() {
      this.spinner.show();
      var info ={
        UID  :this.uid
      }
      this.mailservice.Getgatewaymaillist(info).subscribe((respose: any) => {
        this.InboxList = respose;
        this.spinner.hide(); 
        
       this.status = this.InboxList.externalmail;
        console.log(respose);
        this.spinner.hide(); 
      });
    }
    
    onItemChange(value){
      this.data = value;
      this.submit = true;
    }
    Deactiveusers(data:any) {
      if(data.mailstatus == "Enable"){
        this.values = false;
      }
      else{
        this.values = true;
      }
      const inputRequest: gateway = {
        userid:data.fromId,
        status:this.values, 
        mailid :data.mailId 
      }
      this.mailservice.deactiveateuser(inputRequest).subscribe((respose: any) => {
        this.logindetails = respose;
        if (respose == "false") {
          alert('Disabled Successfully!');
          this.GetInboxList();
         }
         else{
          alert('Enabled Successfully!');
          this.GetInboxList();
         }
         this.GetInboxList();
         window.location.reload();
      });
      
    }
  
    ReadMessage(data) {
  
      localStorage.setItem('fromuser', data.From);
      localStorage.setItem('touser', data.To);
      localStorage.setItem('attachments', data.Attachments);
      localStorage.setItem('mailid', data.MasId);
      localStorage.setItem('transid', data.TransId);
      localStorage.setItem('maildate', data.DateforRM);
      localStorage.setItem('profilepic', data.UserProfilePic);
      localStorage.setItem('firstletter', data.FirstLetter);
      localStorage.setItem('docname', data.DocumentName);
      localStorage.setItem('emailbody', data.EmailBody);
      localStorage.setItem('sub', data.Subject); 
      this.spinner.show();
      var info ={
        UID  :data.mailId
      }
      this.mailservice.postupdatemailread(info).subscribe((respose: any) => {
        var aa = respose;
        this.spinner.hide();
        this.route.navigate(['readmsg/'+ data.mailId]);
      });
     
    }
  
  
  }
  
