<app-nav-bar></app-nav-bar>
<div class="row">
    <div class="col-lg-12">
        <div class="box">
            <!--mail inbox start-->
            <div class="mail-box">
                <aside class="sm-side">
                    <div class="user-head">

                        <div class="user-name">
                            <h5><a href="#">NAME</a></h5>
                            <span><a href="#">EMAIL</a></span>
                        </div>

                    </div>
                    <div class="inbox-body">
                        <a class="btn btn-compose" data-toggle="modal" (click)="composemail()" data-original-title=""
                            title="">
                            Compose
                        </a>


                    </div>

                    <ul class="inbox-nav inbox-divider">
                        <li class="active">
                            <a (click)="inbox()"><i class="fa fa-inbox"></i> Inbox </a>
                        </li>
                        <li>
                            <a (click)="sentitems()"><i class="fa fa-envelope-o"></i> Sent Mail</a>
                        </li>

                        <!-- <li>
                            <a (click)="archiveitems()"><i class="fas fa-download"></i> Archive</a>
                        </li> -->
                        <li>
                            <a (click)="deleteitems()"><i class=" fa fa-trash-o"></i> Trash</a>
                        </li>
                    </ul>


                </aside>
                <aside class="lg-side">
                    <div class="inbox-head">

                    </div>
                    <div class="inbox-body" *ngFor="let mail of maillist; let i = index;">
                        <div class="col-md-10" style=" padding: 30px 140px 50px 140px;">
                            <div class="col-lg-12">
                                <div class="col-lg-12 row">
                                    <div class="col-lg-6">
                                        <span>
                                            <h5>From: {{mail.from}}</h5>
                                        </span>
                                        <h5>To: 
                                                {{mail.to}}</h5>
                                    </div>
                                    <div class="col-lg-6">
                                        <span>
                                            <p style="float: right;">{{mail.sortdate}}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 row">
                                <div class="col-lg-12">
                                    <h5>Sub: {{mail.subject}}</h5>
                                </div>
                            
                                <div class="col-lg-12">
                                    <h5>{{mail.emailBody}}</h5><br>
                                </div>
                                <div class="col-lg-12" *ngIf="mail.file">
                                    <a href="{{mail.file}}">
                                    <div class="col-lg-4" id="document" >
                                        <img src="./assets/img/attachments.png" style="cursor: pointer;">
                                        {{mail.documentName}}

                                    </div>
                                </a>

                                </div>

                                <br /> <br />
                                <div class="col-md-12 row">

                                    <div class="col-md-3"></div>
                                    <div class="col-md-3" style="text-align: end;">
                                        <br /> <br />
                                        <!-- (click)="sendmail()" -->
                                        <button type="submit" id="SendBtn" class="btn btn-info"
                                            (click)="replay(mail,'single')">Reply</button>
                                        <br /> <br />
                                    </div>
                                    <div class="col-md-3" style="text-align: end;">
                                        <br /> <br />
                                        <button type="submit" id="SendBtn" class="btn btn-primary"
                                            (click)="replay(mail,'all')">Reply All</button>
                                        <br /> <br />
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>


                        </div>
                        <hr />
                    </div>
                </aside>
            </div>
            <!--mail inbox end-->
        </div>
    </div>
</div>

<div class="col-md-12 row" *ngIf="replayenable">
    <div class="col-md-4">
    </div>

    <div class="col-md-8" style="background-color: white;    padding: 30px 140px 50px 0px;">

        <label><b> To User</b></label>
        <div class="col-lg-12 row" style="cursor: pointer;">
            {{ replytouser}}<br />
        </div>
        <br />
        <label>Message :</label>

        <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="4" cols="50"> </textarea>
        <br /> <br />

        <label>Upload Documents</label>

        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)">

        <br><br>
        <div style="text-align: center;">

            <button type="submit" id="SendBtn" style="text-align: left;" class="btn btn-success"
                (click)="sendmail()">SEND</button>
        </div>
    </div>


</div>



<!-- <ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
        <h6 style="float: right;">
            Date &nbsp;: &nbsp;{{date | date: "short"}}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{imgdisplay.doclink}}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="imgdisplay.doclink" target="blank" style="cursor: pointer;"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #newmember let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
            <b>Add Frineds</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">
        <div>
            <div id="aboveTagInp">
                <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test"
                    [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                    [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">
                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                        [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'"
                        [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.email }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input>
            </div>
        </div>
        <br />
    </div>
    <br />
    <div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="forword()"
            style="color: black; color: white;padding: 5px;margin-left: 10px;">
            Forword
        </button>
    </div>
    <br />
</ng-template> -->

