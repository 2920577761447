<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="register">
    <div class="col-md-12 row">
        <div class="col-md-2"></div>
        <div class="col-md-4">
            <div class="container-fluid">
                <div class="card text-white bg-info" style="max-width: 24rem">
                    <div class="card-body">
                        <h4 class="card-title" (click)="GetCurrentProjects(1)">
                            Total Projects: <b>{{ projectsSummary.total }}</b>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div class="col-md-4">
            <div class="container-fluid">
                <div class="card text-white bg-primary" style="max-width: 24rem">
                    <div class="card-body">
                        <h4 class="card-title" (click)="GetCurrentProjects(2)">
                            Projects Completed:<b>{{ projectsSummary.completed }}</b>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>

    <div class="col-md-12 row">
        <div class="col-md-2"></div>
        <div class="col-md-4">
            <div class="container-fluid">
                <div class="card text-white bg-warning" style="max-width: 24rem">
                    <div class="card-body">
                        <h4 class="card-title" (click)="GetCurrentProjects(3)">
                            Projects In-Progress:{{ projectsSummary.inProgress }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div class="col-md-4">
            <div class="container-fluid">
                <div class="card text-white bg-success" style="max-width: 24rem">
                    <div class="card-body">
                        <h4 class="card-title" (click)="GetCurrentProjects(4)">
                            Projects On-Hold:{{ projectsSummary.onHold }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br /><br />

<div *ngIf="showhidevalue">
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="table-responsive" style="padding: 15px 15px 15px 15px">
                <table class="table table-bordered table-striped" style="border-collapse: collapse">
                    <tbody>
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>SI No</th>
                            <th>Project</th>
                            <th>Started On</th>
                            <th>Estimated Cost(Rs. )</th>
                            <th>Estimated Time(Hrs.)</th>
                            <th>No of Tasks</th>
                            <th>Utilized Time(Hrs.)</th>
                            <th>Utilized Cost(Rs.)</th>
                            <th>No of Resources</th>
                            <th>Completion Percentage(%)</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="
                let project of Teamprojects
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              ">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ project.projectName }}</td>
                            <td>{{ project.startedOn }}</td>
                            <td>{{ project.estimatedCost | currency: "Rs. " }}</td>
                            <td>{{ project.estimstedTime }}</td>
                            <td>{{ project.noofTasks }}</td>
                            <td>{{ project.utilizedTime }}</td>
                            <td>{{ project.utilizedCost | currency: "Rs. " }}</td>
                            <td>{{ project.noofResources }}</td>
                            <td>{{ project.completionPercentage }}</td>
                            <td>
                                <button type="button" class="btn btn-default btn-xs">
                  <span
                    class="glyphicon glyphicon-expandssss text-success"
                    (click)="
                      ProjPassdata(project, project.completionPercentage)
                    "
                    >View</span
                  >
                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
        </div>
    </div>
</div>
<br /><br /><br />