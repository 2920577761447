<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<!DOCTYPE html>
<html>
  <head>
    <title></title>
    <!--...... <meta charset="utf-8" /> -->

    <meta charset="utf-8" name="description" content="This is inboxfile for object creation."/>
  </head>

  <body>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div
        class="col-md-8"
        style="background: linear-gradient(270deg, #7100aa, #000);"
      >
        <br /><br /><br />
        <h2 style="text-align: center;color: white;" >Object CREATION</h2>
        <br />
        <div >
          <h3 style="text-align: center;color: white;">Current Stage: {{ CurrentStatus }}</h3>
        </div>
          <br /><br />
        <!-- <div class="hidden">{{FileNumber.mailId}}</div> -->
        <div class="col-md-12 row">
          <div class="col-md-6 form-group">
            <label for="to">To</label>
            <div
              ng-dropdown-multiselect=""
              search-filter="customFilter"
              style="width: 120px"
              options="example15data"
              selected-model="example15model"
              extra-settings="example15settings"
            ></div>
          </div>

          <div class="col-md-6 form-group">
            <label for="to">Object</label>
            <div>
              <h3>:{{ FileNumber.fileNumber }}</h3>
            </div>
          </div>
        </div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label>Financial Year</label>
            <select
              (change)="onSelectYear($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Priority</option>
              <option *ngFor="let do of yearlist" value="{{ do.id }}">
                {{ do.year }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Location</label>
            <select
              (change)="onSelectLocation($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Location</option>
              <option *ngFor="let do of locationlist" value="{{ do.id }}">
                {{ do.locationName }}
              </option>
            </select>
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label>Department</label>
            <select
              (change)="onSelectDepartment($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Department</option>
              <option *ngFor="let do of departmentlist" value="{{ do.id }}">
                {{ do.departmentName }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Division</label>

            <select
              (change)="onSelectDivision($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Division</option>
              <option *ngFor="let do of divisionlist" value="{{ do.id }}">
                {{ do.divisionName }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label>Section</label>
            <select
              (change)="onSelectSection($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Section</option>
              <option *ngFor="let do of sectionlist" value="{{ do.id }}">
                {{ do.sectionName }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Groups</label>

            <select
              (change)="onSelectGroups($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Groups</option>
              <option *ngFor="let do of subtypelist" value="{{ do.id }}">
                {{ do.subFileType }}
              </option>
            </select>
          </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label>Subject</label>

            <select
              (change)="onSelectSubject($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Subject</option>
              <option *ngFor="let do of subjectlist" value="{{ do.id }}">
                {{ do.subjectName }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Minor Subject</label>

            <select
              (change)="onSelectMinorSubject($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Minor Subject</option>
              <option *ngFor="let do of minorsublist" value="{{ do.id }}">
                {{ do.minorSubName }}
              </option>
            </select>
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label>Type</label>

            <select
              (change)="onSelectType($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Type</option>
              <option *ngFor="let do of typelist" value="{{ do.id }}">
                {{ do.typeName }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Form Code</label>
            <select
              (change)="onSelectFormCode($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">Select Form Code</option>
              <option *ngFor="let do of formcodelist" value="{{ do.value }}">
                {{ do.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12 row">
          <div class="col-md-6 col-lg-6 col-xs-6 col-sm-6">
            <label for="section">Preservation Period</label>
            <select
              (change)="onSelectPreservation($event.target.value)"
              class="form-control dropdownsize"
            >
              <option selected="selected" value="">Select</option>
              <option selected="selected" value="1">Peramanent</option>
              <option selected="selected" value="2">1</option>
              <option selected="selected" value="3">2</option>
              <option selected="selected" value="4">3</option>
              <option selected="selected" value="5">4</option>
              <option selected="selected" value="6">5</option>
              <option selected="selected" value="7">6</option>
              <option selected="selected" value="8">7</option>
              <option selected="selected" value="9">8</option>
              <option selected="selected" value="10">9</option>
              <option selected="selected" value="11">10</option>
              <option selected="selected" value="12">11</option>
              <option selected="selected" value="13">12</option>
            </select>
          </div>
          <br />
          <div class="col-md-6">
            <label for="section">Period</label>

            <select
              class="form-control dropdownsize"
              (change)="onSelectPeriod($event.target.value)"
            >
              <option selected="selected" value="">Select</option>
              <option selected="selected" value="1">Day(s)</option>
              <option selected="selected" value="2">Month(s)</option>
              <option selected="selected" value="3">Year(s)</option>
            </select>
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label for="FileplanName">Object Name</label>
            <input
              type="text"
              class="form-control"
              name="FileplanName"
              [(ngModel)]="fileplanName"
              autofocus
            />
          </div>
          <div class="col-md-6">
            <label for="Description">Note(s)</label>
            <input
              type="text"
              class="form-control"
              name="Description"
              [(ngModel)]="description"
              autofocus
            />
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <label>Status</label>
            <select
              (change)="onSelectStatus($event.target.value)"
              class="form-control dropdownsize"
            >
              <option value="">SelectStatus</option>
              <option *ngFor="let do of workflowStages" value="{{ do.id }}">
                {{ do.workflowStage }}
              </option>
            </select>
          </div>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
          <div class="col-md-4"></div>
          <div class="col-md-4 col-lg-offset-4" style="text-align: center">
            <input
              type="submit"
              value="Send"
              class="form-control btn btn-primary"
              (click)="saveFileCode()"
              style="    padding: 9px 30px;"
            />
          </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
      <div>&nbsp;</div>
    </div>
  </body>
</html>
