import { Injectable } from '@angular/core';
import { Designation, teams } from 'src/app/ViewModels/Designation';
import { Department } from 'src/app/ViewModels/Department';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  readonly url="https://blocdrive.in/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  saveDesignation(data:Designation){
    return this.http.post(this.url +"Designation/PostSaveDesignation",data);
  }

  getAllDesignations(id){
    return this.http.get(this.url +"Designation/GetAllDesignations/" + id);
  }

  getDesignationsdata(id){
    return this.http.get(this.url +"Designation/GetDesignationdata/" + id);
  }
  deleteDesignation(id: number){
    return this.http.get(this.url +"Designation/GetDeleteDesignationData/"+ id);
  }

  UpdateDesignation(data:Designation){
    return this.http.post(this.url +"Designation/PutDesignation",data);
  }
  getParticularDesignations(id){
    return this.http.get(this.url +"Designation/GetParticularDesignation/"+ id);

  }

    //team


    saveteam(data:teams){
      return this.http.post(this.url +"Project_Team/Postsaveteams",data);
    }
    
    Updateteams(data:teams){
      return this.http.post(this.url +"Project_Team/PutProjectTeams",data);
    }
    deleteteams(id: number){
      return this.http.get(this.url +"Project_Team/GetDeleteProjectChangesData/"+ id);
    }
    getteams(id){
      return this.http.get(this.url +"Project_Team/GetProjectteamsforAssign/" + id);
    }
}
