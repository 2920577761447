import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MovesService } from '../Shared/MoveService/moves.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';

@Component({
  selector: 'app-doc-version',
  templateUrl: './doc-version.component.html',
  styleUrls: ['./doc-version.component.css']
})
export class DocVersionComponent implements OnInit {
  verBlocid: any;
  verlist: any;
  zzz: any;
  pdfUrl: any;
  videodate: any;
  videoplay: any;
  audioplay: any;
  audiodate: any;
  closeResult: string;
  msg: any;
  m: any;
  current_url: any;
  imgdisplay: any;

  constructor(private router: ActivatedRoute,private userservice: UserDetailsService,
    private moveService: MovesService,
   private route: Router, private httpService: HttpClient,
   private modalService: NgbModal, private domSanitizer: DomSanitizer, private spinner: NgxSpinnerService,
   private http: HttpClient) {
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.verBlocid = params['id'];
        this.getversion();

      }
    });
   }

  ngOnInit(): void {
    
  }
  
  sort = "1";

  sortby() {
    if (this.sort == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
       this.verBlocid.sort((a, b) => a.documentName.toLowerCase() < b.documentName.toLowerCase() ? -1 : a.documentName.toLowerCase() > b.documentName.toLowerCase() ? 1 : 0)

      this.sort = "2";

    }
    else if (this.sort == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

       this.verBlocid.sort((a, b) => a.documentName.toLowerCase() > b.documentName.toLowerCase() ? -1 : a.documentName.toLowerCase() < b.documentName.toLowerCase() ? 1 : 0)

      this.sort = "1";

    }

  }
  sortname = "1";
  sortbydate() {
    if (this.sortname == "1") {
      // this.userservice.getfoldersbydate(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });
       this.verBlocid.sort((a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0)

      this.sortname = "2";
    }
    else if (this.sortname == "2") {
      // this.userservice.getfoldersbyname(this.uid).subscribe((respose: any) => {
      //   this.allFolders = respose;
      // });

       this.verBlocid.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0)

      this.sortname = "1";

    }

  }

  mob = false;

  resize() {
    if (window.innerWidth > 500) {
      this.mob = true;
    }
    else {
      this.mob = false;
    }
  }

  ViewDoc(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSFILE");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSFILE");


    this.route.navigate(['fileview/'])
  }


  ViewPpt(aa, pptcontent) {
    let guid = aa.fguid;
    localStorage.setItem("guid",guid);
    this.route.navigate(["msview"]);
  }

  ViewxlsDoc(aa, xlscontent) {
    let guid = aa.fguid;
    localStorage.setItem("guid",guid);
    this.route.navigate(["msview"]);
  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }
  ViewDocument(aa, pdftesting) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.file;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
  }
  ViewVideo(cc, videocontent) {
    this.videoplay = cc.file
    this.videodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  ViewDocumentword(aa, wordcontent) {
    let guid = aa.fguid;
    localStorage.setItem("guid",guid);
    this.route.navigate(["msview"]);
  
  }
  Viewaudio(cc, videocontent) {
    this.audioplay = cc.file
    this.audiodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  getversion(){
    var dd ={
      BID:this.verBlocid
    };
    this.userservice.gelallver(dd).subscribe((respose: any) => {
      this.verlist = respose; 
      for(var i = 0;i<this.verlist.length;i++){
        if(this.verlist[i].documentName.includes('.png') || this.verlist[i].documentName.includes('.PNG') ||
        this.verlist[i].documentName.includes('.jpg') || this.verlist[i].documentName.includes('.JPG') ||
        this.verlist[i].documentName.includes('.jpeg') || this.verlist[i].documentName.includes('.JPEG')){
          this.verlist[i].documentType="image";
        }
       }
      console.log(this.verlist)
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
