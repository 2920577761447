import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';
import { WorkAllotmentService } from 'src/app/Shared/WorkAllotment/work-allotment.service';
import { TagInputModule } from 'ngx-chips';
import { Router } from '@angular/router';
import { ExaminationService } from 'src/app/Shared/ExaminationService/examination.service';
import { examination } from 'src/app/ViewModels/examination';
import { document } from 'src/app/ViewModels/document';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';
import { AddNewFolder } from 'src/app/ViewModels/NewFolder';
import { Friend } from 'src/app/ViewModels/Friend';
import { DownloadFolderVM } from 'src/app/ViewModels/DownloadFolderVM';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-work-allotment',
  templateUrl: './work-allotment.component.html',
  styleUrls: ['./work-allotment.component.css']
})
export class WorkAllotmentComponent implements OnInit {

  FilePlanList: any
  public selecteduserlist: any = [];
  errorMessages: string;
  selectedusername: any;
  serchfind: any;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  UsersList: any;
  count: any;
  username: string;
  uid: string;
  InwardTypeList: any;
  sectionlist: any;
  WorkflowStageList: any;
  sensitivitylist: any;
  selectedworkflowstage: any;
  processtypelist: any;
  inwardMailId: any
  allotmentMailId: any
  inwardData: any
  Role: any
  remarks: any
  attachmentdetails: any
  DocumentTypeList: any;
  wI_Pages: any
  wI_InwardNo: any
  wI_Date: any
  wI_InwardFrom: any
  wI_Address: any
  inwards: any
  formTitle: string = "WORK ALLOTMENT";
  FileName: any;
  users: any;
  userswithindept:any
  codestatus: boolean = false
  filenumber: any;
  inwarsdoclist: any;
  ExhibitDetails: any;
  ExhibhitFileName: any;
  AllinwardData: any;
  wI_Sensitivity: any;
  wI_DocID: void;
  selectedDocumentType: any;
  selectedpriority: any;
  selectedInwardtype: any;
  selectedprocesstype: any;
  wI_InwardTypeId: any;
  wI_DocumentType: any;
  Exhibitlist: any;
  public dismissalert = false;
  notify: string
  ExaminationWindowStatus = false
  p: number = 1;
  exhibit: { Exhibits: any; Inwarddata: any; };
  addExaminationStatus: boolean;
  showOpinionTable: boolean;
  MessageShow: boolean;
  ExaminationDetails: any;
  Opinions: any;
  Message: string;
  opinion: string;
  ExaminationsList: any = [];
  exid: any;
  qdslno: string;
  Exhibits: string;
  observation: string;
  Response: any;
  docdescription: any;
  UploadDocuments: any = [];
  docdetails: string;
  docqdslno: any;
  savedocResponse: any;
  WorkAllotment: any;
  inwardNumber: any;
  id: any;
  attachmentDetails: any;
  wI_Documents: void;
  transactionHistory: any;
  workflowStage: any;
  section: any;
  inwardNum: any;
  workallotmentdetails: string;
  inwarddataid: any;
  inwarddatainwardnum: any;
  inwarddataallotmentid: any;
  selectedexaminationexhibhitid: any;
  files: any;
  CurrentFileName: any;
  selectedexifordoc: any;
  savedocumentResponse: Object;
  roleid: string;
  mobileview: boolean;
  subf: any;
  AllFolders: any;
  AllFiles: any;
  InwardFolderId: string;
  InwardFileId: string;
  InwardTypeFile: string;
  closeResult: string;
  UserRights: any;
  EnableShare: boolean = false;
  EnableDelete: boolean = false;
  EnableEdit: boolean = false;
  EnableInward: boolean = false;
  statuschange: string;
  ShareOnlyDoc: any;
  BlockId: any;
  FolderID: any;
  ShareOnlyDocName: any;
  m: any;
  msg: any;
  shareitem: boolean;
  deleteitem: boolean;
  Friends: any;
  frndslist: any;
  accid: any;
  FileFoldID: any;
  allSubFolders: any;
  fid: any;
  CurrentFiles: any;
  checkenable: boolean;
  multi: boolean;
  sfiles: number;
  sfolder: number;

  selectedSelected: any = [];
  deletemulticontent: string;
  foladdednotify: boolean;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  Res: any;
  createdmsg: string;
  pid: any;
  foldercreated: boolean;
  n: number;
  num: any;
  selectedFriendcancel: any;
  selectedFriend: any = [];
  shareUniqueID: any;
  userid: string;
  frndrqst: boolean;
  sendFID: any;
  sendBID: any;
  shareSubFolderFolderType: any;
  shareSubFolderFolderName: any;
  shareSubFolderParentID: any;
  shareSubFolderFolderId: any;
  pathdt: any;
  EnableDownload: boolean = false;
  details: any;
  updatemsg: any;
  u: any;
  downpath: any;
  shareSubFolderId: any;
  InwardedDocumentId: any;
  WorkPreviewData: any;
  GUID: string;
  UsersListWithinSameDepartment: any;
  public selecteddeptuserlist: any = [];

  constructor(private spinner: NgxSpinnerService, private inwardservice: InwardServiceService, private workallotmentservice: WorkAllotmentService,
    private router: Router, private examinatinservice: ExaminationService, private httpService: HttpClient, private userservice: UserDetailsService,
    private modalService: NgbModal,private datepipe:DatePipe) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.Role = localStorage.getItem("role");
      this.roleid = localStorage.getItem("role");
      this.GUID=localStorage.getItem("GUID");
      this.InwardFolderId = localStorage.getItem("InwardFolderId")
      this.InwardFileId = localStorage.getItem("InwardFileId")
      this.InwardTypeFile = localStorage.getItem("InwardTypeFile")
      if (this.InwardFolderId == "null") {
        this.InwardTypeFile = "true"
      }
      if (this.InwardTypeFile == "true" || this.InwardFolderId == "null") {
        this.Getfiles((+this.InwardFileId));
      }
      else {
        this.GetFoldersandFiles(this.InwardFolderId);
      }

    }
    if (localStorage.getItem("WorkAllotementPreviewData") != null || localStorage.getItem("WorkAllotementPreviewData") != undefined) {
      var data = JSON.parse(localStorage.getItem("WorkAllotementPreviewData"))
      this.WorkPreviewData = data
      this.AssignData();

    }
    this.isMobileMenu();
    TagInputModule.withDefaults({
      tagInput: {
        placeholder: 'Add UserName ',
        secondaryPlaceholder: 'Add UserName ',
        // add here other default values for tag-input
      },
    });
    this.workallotmentdetails = localStorage.getItem("employee")

    if (this.workallotmentdetails != null) {
      this.WorkAllotment = JSON.parse(this.workallotmentdetails);
      if (this.WorkAllotment.wrkallotmenttransaction != undefined) {

        this.formTitle = "WORK PROCESSING";
        this.codestatus = true;
        this.filenumber = this.WorkAllotment.wrkallotmenttransaction.fileCode
        this.inwardNumber = this.WorkAllotment.wrkallotmenttransaction.inwardNumber
        this.wI_InwardFrom = this.WorkAllotment.wrkallotmenttransaction.recievedFrom
        this.wI_Date =this.datepipe.transform(this.WorkAllotment.wrkallotmenttransaction.date, 'dd-MM-yyyy'); 
        this.id = this.WorkAllotment.wrkallotmenttransaction.id
        this.wI_InwardNo = this.WorkAllotment.wrkallotmenttransaction.inwardNumber
        this.wI_InwardTypeId = this.WorkAllotment.wrkallotmenttransaction.inwardType
        this.wI_DocID = this.WorkAllotment.wrkallotmenttransaction.documentType
        this.attachmentDetails = this.WorkAllotment.wrkallotmenttransaction.attachmentDetails
        this.wI_Sensitivity = this.WorkAllotment.wrkallotmenttransaction.sensitivity
        this.remarks = this.WorkAllotment.wrkallotmenttransaction.remarks
        this.wI_Pages = this.WorkAllotment.wrkallotmenttransaction.pages
        this.wI_Address = this.WorkAllotment.wrkallotmenttransaction.subject
        this.wI_Documents = this.GetUploadedDocuments(this.WorkAllotment.wrkallotmenttransaction.inwardNumber)
        this.allotmentMailId = this.WorkAllotment.wrkallotmenttransaction.mailId
        this.transactionHistory = this.WorkAllotment.two
        this.workflowStage = this.WorkAllotment.wrkallotmenttransaction.workflowStage
        this.section = this.WorkAllotment.wrkallotmenttransaction.section
        this.inwardNum = this.WorkAllotment.wrkallotmenttransaction.inwardedDoc

        this.GetUploadedDocuments(this.WorkAllotment.wrkallotmenttransaction.inwardNumber);
        this.WorkflowStage();
      }
    }

  }

  ngOnInit(): void {
    this.GetUsers();
    this.GetUsersWithinSameDepartment();
    this.FilePlan();
    this.WorkflowStage();
    this.Priority();
    this.GetInwardedDocuments();
    this.InwardType();
    this.DocumentTypes();
    this.getuserrights()

  }
  AssignData() {
    this.users = this.WorkPreviewData.ToUsers
    this.selecteduserlist = this.WorkPreviewData.ToUsers
    this.selecteddeptuserlist= this.WorkPreviewData.ToUsersWithinDept
    this.userswithindept=this.WorkPreviewData.ToUsersWithinDept
    this.filenumber = this.WorkPreviewData.Filecode
    this.wI_Address = this.WorkPreviewData.Subject
    this.wI_InwardFrom = this.WorkPreviewData.RecievedFrom
    this.wI_Date =this.WorkPreviewData.Date
    this.inwarddataid = this.WorkPreviewData.InwardNumber
    this.wI_InwardNo = this.WorkPreviewData.OriginalReferenceNumber
    this.inwarddatainwardnum = this.WorkPreviewData.InwardedDoc
    this.wI_InwardTypeId = this.WorkPreviewData.InwardType
    this.wI_Pages = this.WorkPreviewData.Pages
    this.wI_DocumentType = this.WorkPreviewData.DocumentType
    this.wI_DocID = this.WorkPreviewData.DocumentType
    this.wI_Sensitivity = this.WorkPreviewData.Sensitivity
    this.remarks = this.WorkPreviewData.Remarks
    this.attachmentdetails = this.WorkPreviewData.AttachmentDetails
    this.inwarddataallotmentid = this.WorkPreviewData.MailId
    this.selectedworkflowstage = +(this.WorkPreviewData.Status)
    this.ExhibhitFileName = this.WorkPreviewData.ProcessType
    this.InwardFolderId = this.WorkPreviewData.InwardFolderId
    this.InwardFileId = this.WorkPreviewData.InwardFileId,
    this.InwardedDocumentId = this.WorkPreviewData.InwardedDocumentId
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };

  FilePlan() {
    this.spinner.show();
    this.inwardservice.GetFilePlanList().subscribe((Data: any) => {
      this.FilePlanList = Data;
      this.spinner.hide();
    })
  }
  // multiple input filed adding and removing 
  onItemRemoved(value: any) {
    this.selecteduserlist.pop(value);
    this.errorMessages = "";
  }
  onItemRemovedWithihDept(value: any){
    this.selecteddeptuserlist.pop(value);
    this.errorMessages = "";
  }
  onItemAdded(value: any) {
    if (value.Email == undefined) {
      this.selectedusername = value.value;
    }
    else {
      this.selectedusername = value.Email;
    }

    this.selecteduserlist.push(value);
  }
  onItemAddedWithinDept(value: any) {
    if (value.Email == undefined) {
      this.selectedusername = value.value;
    }
    else {
      this.selectedusername = value.Email;
    }

    this.selecteddeptuserlist.push(value);
  }
  GetUsers() {
    this.spinner.show();
    this.workallotmentservice.getUsers(this.uid).subscribe((respose: any) => {
      this.UsersList = respose;
      this.count = this.UsersList.length;
      this.spinner.hide();
    });
  }
  GetUsersWithinSameDepartment(){
    this.spinner.show();
    this.workallotmentservice.getUsersWithinDepartment(this.GUID).subscribe((respose: any) => {
      this.UsersListWithinSameDepartment = respose;
      this.spinner.hide();
    });
  }
  GetInwardedDocuments() {
    this.spinner.show();
    this.workallotmentservice.getinwardedDocuments().subscribe((respose: any) => {
      this.inwards = respose;
      this.count = this.UsersList.length;
      this.spinner.hide();
    });
  }
  InwardType() {
    this.spinner.show();
    this.inwardservice.GetInwardTypeList().subscribe((Data: any) => {
      this.InwardTypeList = Data;
      this.spinner.hide();
    })
  }

  WorkflowStage() {
    this.spinner.show();
    this.inwardservice.GetWorkflowStage("Allotment").subscribe((Data: any) => {
      this.WorkflowStageList = Data;
      this.spinner.hide();
    })
  }

  Priority() {
    this.spinner.show();
    this.inwardservice.GetPriority().subscribe((Data: any) => {
      this.sensitivitylist = Data;
      this.spinner.hide();

    })
  }

  OnselectWorkflowStage(id) {
    this.selectedworkflowstage = id
  }
  OnselectDocumentType(id) {
    this.selectedDocumentType = id
  }
  OnselectPriority(id) {
    this.selectedpriority = id;
  }
  OnselectInwardType(id) {
    this.selectedInwardtype = id
  }
  GetInwardDataforAllotment(inwardId, filenumber) {
    this.InwardedDocumentId = inwardId
    var WData = {
      mailid: inwardId,
      WorkflowType: "1"
    }
    this.spinner.show();
    this.workallotmentservice.GetInwardData(WData).subscribe((Data: any) => {
      this.AllinwardData = Data;
      this.inwardData = this.AllinwardData.three;
      this.wI_InwardFrom = this.inwardData.wI_InwardFrom
      this.wI_Date = this.inwardData.wI_Date
      this.wI_InwardNo = this.inwardData.wI_InwardNo
      this.wI_Pages = this.inwardData.wI_Pages
      this.wI_Sensitivity = this.inwardData.wI_Sensitivity
      this.wI_DocID = this.inwardData.wI_DocID
      this.wI_InwardTypeId = this.inwardData.wI_InwardTypeId
      this.wI_DocumentType = this.inwardData.wI_DocumentType
      this.remarks = this.inwardData.remarks
      this.inwardData.transactionHistory = null;
      this.GetUploadedDocuments(this.AllinwardData.three.wI_InwardNo)
      this.spinner.hide();

    })
  }
  GetUploadedDocuments(val) {
    this.spinner.show();
    this.workallotmentservice.GetDocumentsByDocumentID(val).subscribe((Data: any) => {
      this.inwarsdoclist = Data;
      this.ExhibhitFileName = this.inwarsdoclist[0].fileName
      this.spinner.hide();
    })
  }
  OnselectFilename(id) {
    this.filenumber = id;
    for (let i = 0; i < this.FilePlanList.length; i++) {
      if (id == this.FilePlanList[i].fileUniqueId) {
        this.FileName = this.FilePlanList[i].filename
      }
    }
    const data: Exibhits = {
      Caseid: id,
      Role: this.Role,
      UserId: this.uid
    }
    this.spinner.show();
    this.inwardservice.GetExhibitsFiles(id).subscribe((Data: any) => {
      this.Exhibitlist = Data;
      this.ExhibhitFileName = this.Exhibitlist.filename
      this.spinner.hide();
    })
  }
  DocumentTypes() {
    this.spinner.show();
    this.inwardservice.GetDocumentTypeList().subscribe((Data: any) => {
      this.DocumentTypeList = Data;
      this.spinner.hide();

    })
  }
  GetProcessTypeList() {
    this.spinner.show();
    this.inwardservice.GetPriority().subscribe((Data: any) => {
      this.processtypelist = Data;
      this.spinner.hide();
    })
  }

  OnselectProcessType(id) {
    this.selectedprocesstype = id
  }
  PreviewWorkAllotment() {
    if (this.inwardData == null || this.inwardData == undefined) {
      this.inwarddataid = this.id
    }
    else {
      this.inwarddataid = this.inwardData.id
    }
    if (this.inwardData == null || this.inwardData == undefined) {
      this.inwarddatainwardnum = this.inwardNum
    }
    else {
      this.inwarddatainwardnum = this.inwardData.inwardNum
    }
    if (this.inwardData == null || this.inwardData == undefined) {
      this.inwarddataallotmentid = this.allotmentMailId == "" ? 0 : this.allotmentMailId
    }
    else {
      this.inwarddataallotmentid = this.inwardData.allotmentMailId == "" ? 0 : this.inwardData.allotmentMailId
    }
    var data = {
      ToUsers: this.selecteduserlist,
      ToUsersWithinDept:this.selecteddeptuserlist,
      Filecode: this.filenumber,
      Subject: this.wI_Address,
      RecievedFrom: this.wI_InwardFrom,
      Date: this.wI_Date,
      InwardNumber: this.inwarddataid,
      OriginalReferenceNumber: this.wI_InwardNo,
      InwardedDoc: this.inwarddatainwardnum,
      InwardType: this.wI_InwardTypeId,
      Pages: this.wI_Pages,
      DocumentType: this.wI_DocumentType,
      //Address: this.inwardData.address,
      Sensitivity: this.wI_Sensitivity,
      Remarks: this.remarks,
      AttachmentDetails: this.attachmentdetails,
      FromUser: this.uid,
      MailId: this.inwarddataallotmentid,
      Status: this.selectedworkflowstage,
      ProcessType: this.ExhibhitFileName,
      ExhibitId: this.filenumber,
      InwardFolderId: this.InwardFolderId,
      InwardFileId: +this.InwardFileId,
      InwardedDocumentId: this.InwardedDocumentId,
      formTitle : this.formTitle,
     codestatus: this.codestatus
    }
    localStorage.setItem("WorkAllotementPreviewData", JSON.stringify(data));
    this.router.navigate(['/workallotmentpreview'])
  }
  SendWork() {
    for (let index = 0; index < this.selecteddeptuserlist.length; index++) {
     
      this.selecteduserlist.push(this.selecteddeptuserlist[index]);
    }
    if (this.inwardData == null || this.inwardData == undefined) {
      this.inwarddataid = this.id
    }
    else {
      this.inwarddataid = this.inwardData.id
    }
    if (this.inwardData == null || this.inwardData == undefined) {
      this.inwarddatainwardnum = this.inwardNum
    }
    else {
      this.inwarddatainwardnum = this.inwardData.inwardNum
    }
    if (this.inwardData == null || this.inwardData == undefined) {
      this.inwarddataallotmentid = this.allotmentMailId == "" ? 0 : this.allotmentMailId
    }
    else {
      this.inwarddataallotmentid = this.inwardData.allotmentMailId == "" ? 0 : this.inwardData.allotmentMailId
    }
    var data = {
      ToUsers: this.selecteduserlist,
      Filecode: this.filenumber,
      Subject: this.wI_Address,
      RecievedFrom: this.wI_InwardFrom,
      Date: this.wI_Date,
      InwardNumber: this.inwarddataid,
      OriginalReferenceNumber: this.wI_InwardNo,
      InwardedDoc: this.inwarddatainwardnum,
      InwardType: this.wI_InwardTypeId,
      Pages: this.wI_Pages,
      DocumentType: this.wI_DocumentType,
      //Address: this.inwardData.address,
      Sensitivity: this.wI_Sensitivity,
      Remarks: this.remarks,
      AttachmentDetails: this.attachmentdetails,
      FromUser: this.uid,
      MailId: this.inwarddataallotmentid,
      Status: this.selectedworkflowstage,
      ProcessType: this.ExhibhitFileName,
      ExhibitId: this.filenumber,
      InwardFolderId: this.InwardFolderId,
      InwardFileId: +this.InwardFileId
    }
    this.spinner.show();
    this.workallotmentservice.SendWork(data).subscribe((Data: any) => {
      this.notify = "Sent successfully";
      localStorage.removeItem("WorkAllotementPreviewData");
      setTimeout(() => this.dismissalert = true, 100);
      setTimeout(function () {
        this.dismissalert = false;
      }.bind(this), 3000);
      this.spinner.hide();
    })
  }
  closealert() {
    this.dismissalert = false;
  }
  gotopdfViewForAssignTask(file) {
    if (file.blockId == null || file.blockId == undefined) {
      file.blockId = file.caseId
    }
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.document);
    localStorage.setItem('FileNameView', file.fileName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.document);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.fileType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "false");
    localStorage.setItem('IsAssignTaskDoc', "true");
    this.router.navigate(['projectdocs/'])
  }
  AddExaminations(data) {
    if (this.ExaminationWindowStatus) {
      this.ExaminationWindowStatus = false;
    }
    else {
      this.ExaminationWindowStatus = true;
      var fid = {
        Caseid: this.filenumber,
        Role: this.Role,
        UserId: this.uid
      }
      this.examinatinservice.GetExhibit(fid).subscribe((response: any) => {
        this.exhibit = {
          Exhibits: response,
          Inwarddata: data
        };
      })
    }
  }
  showExamination() {
    if (this.addExaminationStatus == true) {
      this.addExaminationStatus = false;
    }
    else {
      this.addExaminationStatus = true;
    }
  }
  showOpinion(pd) {
    if (this.showOpinionTable == true) {
      this.showOpinionTable = false;
      this.MessageShow = false;
    }
    else {
      this.ExaminationDetails = pd;
      this.spinner.show();
      this.examinatinservice.getOpinions(pd.id).subscribe((response: any) => {
        this.spinner.hide();

        if (response.length == 0) {
          this.notify = "No Opinions are found!!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.showOpinionTable = false;
          this.MessageShow = true;

        } else {
          this.Opinions = response;
          this.showOpinionTable = true;
          this.MessageShow = false;
        }
      })
    }
  }
  AddOpinion(examination) {
    if (this.opinion === "") {
      alert('Please Specify the Opinion');
    } else {
      var data = {
        ExaminationId: examination.id,
        Opinion: this.opinion,
        GivenBy: this.uid,
        CaseId: examination.caseId
      }
      this.spinner.show();
      this.examinatinservice.saveOpinion(data).subscribe((response: any) => {
        if (response) {
          this.opinion = "";
          this.examinatinservice.getOpinions(examination.id).subscribe((response1: any) => {
            this.notify = "Opinion added successfully!"
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.Opinions = response1;
            this.showOpinionTable = true;
            this.MessageShow = false;
            this.spinner.hide();

          });
        } else {
          this.notify = "Something went wrong"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();

        }
      })
    }
  }
  RemoveExamination(index) {
    //var name = this.ExaminationsList[index].Name;
    this.ExaminationsList.splice(index, 1);
  }
  onSelectexidExhibit(id) {
    this.exid = id
  }
  onSelectExaminationExhibit(id) {
    this.selectedexaminationexhibhitid = id
  }

  AddExamination() {

    var len = this.ExaminationsList.length;
    const data: examination = {
      qdslno: len + 1,
      ExhibitId: this.exid,
      CaseID: this.exhibit.Exhibits.examinationdata[0].caseId,
      Observations: this.observation,
      DoneBy: this.uid,
      InwardNumber: this.inwardNumber,
      DivisionName: this.wI_Address,
    }

    this.ExaminationsList.push(data);

    //Clear the TextBoxes.
    this.qdslno = "";
    this.Exhibits = "";
    this.observation = "";
  }
  SaveExamination1() {
    if (this.ExaminationsList.length == 0) {
      alert("Please add atleast one Examination")
    }
    else {
      this.spinner.show();
      this.examinatinservice.SaveExamss(this.ExaminationsList).subscribe((response: any) => {
        this.Response = response.data;
        this.spinner.hide();
        alert("Added succesfully!!");
      });
    }
  }
  AddDocument() {
    const docdata: document = {
      CaseId: this.exhibit.Exhibits.examinationdata[0].caseId,
      DoneBy: this.uid,
      InwardNumber: this.inwardNumber,
      DocumentDescription: this.docdescription,
      ExhibitId: this.selectedexifordoc,
      docdetails: "Added",
      docqdslno: this.UploadDocuments.length + 1,
      FileName: this.CurrentFileName
    }
    this.UploadDocuments.push(docdata);
    this.docdescription = "";
    this.docdetails = "";
    this.exid = "";
    this.docqdslno = null;
    this.selectedexifordoc = null;
  }
  savedocuments() {
    const frmData = new FormData();
    if (this.files.length == 0) {
      alert("Please Select atleast One File!!")
    }
    else {
      for (var i = 0; i < this.files.length; i++) {
        frmData.append("fileUpload", this.files[i]);
      }

      frmData.append("OtherDetails", JSON.stringify(this.UploadDocuments));
      this.spinner.show();

      this.httpService.post('https://blocdrive.in/blocdriveapi/api/Examinations/PostExamDocuments/', frmData).subscribe(
        data => {
          this.savedocumentResponse = data;
          if (this.savedocumentResponse != "false") {
            alert("Saved Successfully");
            this.examinatinservice.uploadForwardedDocument(this.savedocumentResponse + "," + this.UploadDocuments[0].CaseId).subscribe((response: any) => {
            })
            this.UploadDocuments = [];
            this.spinner.hide();
            this.docqdslno = null;
            this.selectedexifordoc = null;
          }
          else {
            alert("No Documents Found!!!");
            this.spinner.hide();
            this.UploadDocuments = [];
            this.docqdslno = null;
            this.selectedexifordoc = null;
          }
        });
    }
  }

  SaveExamination(er) {
    var exam = {
      CaseID: er.caseId,
      InwardNumber: this.exhibit.Inwarddata.inwardNumber,
      DivisionName: this.exhibit.Inwarddata.wI_Address,
      ExhibitId: er.exhibitId,
      DoneBy: this.uid,
      Observations: er.observation,
      DateTime: new Date(),
      IsEnabled: false
    };
    this.examinatinservice.SaveExam(exam).subscribe((response: any) => {
      this.Response = response.data;
    })
  }
  ShowMailForm(inbox) {

  }
  getTheotherFiles3(event: any) {
    this.files = event.target.files
    this.CurrentFileName = this.files[0].File.name
  }
  onSelectexid(id) {
    this.selectedexifordoc = id;
  }
  RemoveDocuments(m) {

  }
  onSelectmExhibitId(id) {

  }
  gotofile(filepopup) {
    this.modalService.open(filepopup).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  Getfiles(fileid) {
    this.spinner.show();
    this.inwardservice.GetInwardFiles(fileid).subscribe((Data: any) => {
      this.AllFiles = Data;
      this.spinner.hide();

    })
  }
  GetFoldersandFiles(folderid) {
    this.spinner.show();
    this.inwardservice.GetInwardFolders(folderid).subscribe((Data: any) => {
      this.AllFolders = Data;
      this.spinner.hide();

    })
  }

  GetSubfolder(data) {

    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.AllFolders = respose;
      this.subf = this.AllFolders.folderName;
      var Pid = this.AllFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.AllFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.AllFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.AllFolders.parentId));

      // console.log("user", this.allSubFolders);
      //this.route.navigate(['allsubfolders/' + data.folderID]);
    });
  }
  mob = false;


  resize() {
    if (window.innerWidth > 500) {
      this.mob = true;
    }
    else {
      this.mob = false;
    }
  }
  ViewDoc(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');


    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSALLOT");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSALLOT");


    this.router.navigate(['fileview/'])
  }
  ShareoneFile(data, Sharecontent) {
    this.ShareOnlyFile(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }

  ShareOnlyFile(data: any) {
    this.statuschange = "";
    this.statuschange = data;
    this.ShareOnlyDoc = data.file;
    this.BlockId = data.blockId;
    this.FolderID = data.folderId;
    this.ShareOnlyDocName = data.documentName;

  }

  GetFriendDetails() {
    this.userservice.friendlist(this.uid).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      // console.log(this.frndslist);
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }

  Share() {
    this.shareitem = true;
  }
  CancelShare() {
    this.shareitem = false;
  }
  Deleteitems() {
    this.deleteitem = true;
  }
  CancelDelete() {
    this.deleteitem = false;
  }
  Delete(data) {
    var res;
    if (data.folderName == null) {
      res = confirm("Do You Want to Delete " + data.documentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.folderName + "!!!");
    }
    if (res == true) {
      if (data.hash) {
        var info = {
          Id: data.id,
          FolderID: data.folderId,
          FolderName: data.folderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.folderId,
          RoleId: this.roleid,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    this.userservice.deletefolder(info).subscribe((data: any) => {
      if (data != null) {
        alert("Deleted Successfully")
        this.GetFoldersandFiles(this.InwardFolderId);

      }
    });
  }
  GetDetails(id) {
    this.spinner.show();
    this.getpath(id);
    this.FileFoldID = id;
    this.userservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.fileName;
      this.fid = this.allSubFolders.parentId;
      // console.log("user", this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
    this.spinner.hide();

  }
  getpath(id) {
    this.userservice.getpath(id).subscribe((respose: any) => {
      this.pathdt = respose;
    });
  }
  SharewithMoreFriends(Sharecontent) {
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  DeleteMorecoin(Deletecontent) {
    this.modalService.open(Deletecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  toggleSelect(data) {
    this.CurrentFiles = data;
    this.checkenable = true;
    this.multi = true;
    this.sfiles = 0;
    this.sfolder = 0;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedSelected.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.checkenable = false;
        this.multi = false;
      }
    }

    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].folderName != null) {
        this.sfolder = this.sfolder + 1;
      }
      else {
        this.sfiles = this.sfiles + 1;
      }
    }


    if (this.sfolder == 0) {
      this.deletemulticontent = this.sfiles + " Files"
    }
    else if (this.sfiles == 0) {
      this.deletemulticontent = this.sfolder + " Folders"
    }
    else {
      this.deletemulticontent = this.sfolder + " Folders " + this.sfiles + " Files"
    }
  }
  Deleteselected() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Deleting selected items";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Deleting selected items";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const inputRequest: DeleteMulit = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      UserId: this.uid,
      ModifiedOn: null,
      GUID:this.GUID
    }
    // console.log(inputRequest);
    this.userservice.DeleteMore(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Deleted selected items successfully!"
        alert("Deleted selected items successfully!")
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        this.GetFoldersandFiles(this.InwardFolderId);



        this.selectedSelected = [];
        this.multi = false;
        this.checkenable = false;
        setTimeout(() => {
        }, 500);
        //this.GetAllFolders();
        //this.spinner.hide();
      }
      else {
        this.checkenable = false;
        this.multi = false;
        this.notify = "Something went wrong"
        setTimeout(() => this.foladdednotify = true, 100);
        setTimeout(function () {
          this.foladdednotify = false;
        }.bind(this), 3000);

        this.GetFoldersandFiles(this.InwardFolderId);
      }
    });

  }
  getPercent(per) {
    return 10;
  }
  shareallallcancel() {
    if (this.selectedFriendcancel == null || this.selectedFriendcancel == undefined || this.selectedFriendcancel == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: 0,
        ToUsers: null,
        USerId: this.uid,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.selectedFriendcancel,
        ModifiedOn: null,
        GUID:this.GUID

      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Withdrawing Shared Items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Withdrawing Shared Items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.withdraw(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Withdraw of Shared files successful!"
            alert("Withdraw of Shared files successful!")
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            this.GetFoldersandFiles(this.InwardFolderId);

            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }
  shareallall() {
    if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
        ToUsers: this.selectedFriend,
        USerId: this.uid,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: null,
        ModifiedOn: null,
        GUID:this.GUID

      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.shareall(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            alert("Shared items successfully");
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
            this.GetFoldersandFiles(this.InwardFolderId);

          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }

  ShareFileMembers1(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        if (this.frndslist[index].userId == this.uid) {
          this.selectedFriendcancel = this.frndslist[index].friendId;
          break;
        }
        this.selectedFriendcancel = this.frndslist[index].userId;
        break;
      }
    }

  }
  cancelshareall = false;
  shareitem1 = false;
  cancelshare() {
    if (this.cancelshareall == true) {
      this.cancelshareall = false;
      this.shareitem1 = false;

    }
    else {
      this.cancelshareall = true;
      this.shareitem1 = true;

    }
  }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID:this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
    });
  }

  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    }
    else {
      this.frndrqst = true;
    }
  }
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  SharOnlyFileToUsers() {
    this.sendFID = this.FolderID;
    this.sendBID = this.BlockId;
    const inputRequest: ShareOnlyFileVM = {
      FolderID: this.FolderID,
      File: this.ShareOnlyDoc,
      BlockID: this.BlockId,
      FromUserId: this.uid,
      ToUsers: this.selectedFriend,
      DocumentName: this.ShareOnlyDocName,
      UserId: this.uid,
      AccessType: null,
      UserName: this.username,
      BodyContent: null,
      UserEmail: null,
      CreatedOn: null,
      FromUserGUID:this.GUID
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
      }
    }, 3000);
    this.userservice.shareOnlyFile(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared item successfully within blockchain!";
          alert("Shared item successfully within blockchain!")
          this.pid = data;

          this.folderCreating = false;
          this.foldercreated = true;
          this.selectedFriend = [];
          clearInterval(this.num);
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.GetFoldersandFiles(this.InwardFolderId);

          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedFriend = [];
        }
      });
  }
  ShareExsistingSubFolderToUsers() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest: FolderShareToUers = {
      Id: "0",
      ToUsers: this.selectedFriend,
      BodyContent: "",
      FolderType: this.shareSubFolderFolderType,
      FolderName: this.shareSubFolderFolderName,
      Code: "SubFolder",
      ParentID: this.shareSubFolderParentID,
      FolderID: this.shareSubFolderFolderId,
      UserId: this.uid,
      AccessType: null,
      UserName: this.username,
      UserEmail: "",
      CreatedOn: "",
      GUID:this.GUID
    }
    //this.spinner.show();

    // console.log(inputRequest);
    this.userservice.shareExsistingSubFolder(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {

          this.n = 100;
          this.createdmsg = "Shared items successfully"
          alert("Shared items successfully");
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);

          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.GetFoldersandFiles(this.InwardFolderId);


          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);

          this.selectedFriend = [];
          this.accid = "";
        }
      });
  }
  ShareExsistingMultiFolderToUsers() {

    const inputRequest: MultiFolderShareVM = {
      Id: this.shareUniqueID,
      ToUsers: this.selectedFriend,
      USerId: this.uid,
      Code: "MainFolder",
      SelectedFolders: this.selectedSelected,
      AccessType: null,
      BodyContent: null,
      UserName: this.username,
      ModifiedOn: null,
      GUID:this.GUID

    }
    //this.spinner.show();
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Sharing selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    this.userservice.addMultigMainFolderAndShare(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared items successfully!"
          alert("Shared items successfully");
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);
          // this.notify = "Folder Shared Succesfully";
          // setTimeout(() => this.dismissalert = true, 100);
          // setTimeout(function () {
          //   this.dismissalert = false;
          // }.bind(this), 3000);
          // this.spinner.hide();
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedSelected = [];
          this.multi = false;
          this.GetFoldersandFiles(this.InwardFolderId);


        }
        else {
          //alert("Something went wrong!!please try again");
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        this.spinner.hide();
      });
  }

  UpdateSubFolder(folderview, editfolder) {
    this.details = folderview;
    this.updatemsg = folderview.folderName;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u = this.updatemsg;
    });
  }

  UpdateSubFolderName() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    const inputRequest: AddNewFolder = {
      Id: this.details.id,
      FolderID: this.details.folderID,
      RoleId: this.roleid,
      FolderName: this.updatemsg,
      UserId: this.uid,
      Code: "Folder",
      ParentID: this.details.parentID,
      Click: null,
      date: nd,
      GUID:this.GUID

    }
    this.userservice.UpdateFolder(inputRequest).subscribe((data: any) => {
      if (data == true) {
        this.n = 100;
        this.createdmsg = "Updated Successfully";
        alert("Updated Successfully");
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        clearInterval(this.num);
        this.GetFoldersandFiles(this.InwardFolderId);
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        // alert("Updated Successfully");


      }
      else {
        alert("Something went Wrong");
      }
    });
  }
  // Download Folder
  DownloadFolder(data) {
    const inputRequest: DownloadFolderVM = {
      Id: data.id,
      AUID: this.uid,
      FolderId: data.folderID,
      FolderName: data.folderName,
      GUID:this.GUID

    }
    this.userservice.downloadFolder(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
      }
    });

  }
  getSubFolderdata(data) {
    this.shareSubFolderFolderId = data.folderID;
    this.shareSubFolderFolderName = data.folderName;
    this.shareSubFolderFolderType = data.folderType;
    this.shareSubFolderParentID = data.parentID;
    this.shareSubFolderId = data.id;
  }
  ShareFolder(data, Sharecontent) {
    this.getSubFolderdata(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  getuserrights() {
    this.spinner.show();
    this.userservice.getuserRights(this.uid).subscribe((Data: any) => {
      this.UserRights = Data;
      for (let i = 0; i < this.UserRights.length; i++) {
        if (this.UserRights[i].type == 1) {
          this.EnableShare = true;
        }
        else if (this.UserRights[i].type == 2) {
          this.EnableDelete = true;
        }
        else if (this.UserRights[i].type == 3) {
          this.EnableEdit = true;
        }
        else if (this.UserRights[i].type == 4) {
          this.EnableInward = true;
        }
        else if (this.UserRights[i].type == 5) {
          this.EnableDownload = true;
        }
      }
      this.spinner.hide();
    })
  }
}
export class Exibhits {
  Caseid: any
  Role: any
  UserId: any
}


export class inwardData {

}

