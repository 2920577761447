<!-- ---- -->






<nav class="navbar navbar-expand-md navbar-dark"
    style="background: black;border-bottom: #393939 1px solid;z-index: 1021;">
    <a class="navbar-brand" href="#" style="padding-left:10%;">
        <img src="../../assets/img/blocdriverz.png" class="img-fluid" alt="" width="100" style="    height: 54px;
        width: 118px;" /> <br>
        <h6 style="color: white;font-size: 10px;letter-spacing: 1px;padding-left: 10px;">Securing Data</h6>
    </a>
    <div class="navbar-toggler-right">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar"
            aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>

    <div class="collapse navbar-collapse flex-column" id="navbar" style="margin-right: 4%">
        <ul class="navbar-nav w-100 justify-content-end px-3">
            <li class="nav-item active">
                <a class="nav-link"  [routerLink]="['/']">Home &nbsp; </a>
                <!--...... <a class="nav-link" href="" [routerLink]="['/']">Home &nbsp; </a> -->

            </li>



            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/howto']" >Contact Us &nbsp; </a>
                <!--...... <a class="nav-link" href="" [routerLink]="['/howto']">Contact Us &nbsp; </a> -->

            </li>

            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/faq']">FAQ</a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/help']">Help </a>
            </li>
            <li class="nav-item active" *ngIf="!isLoggedIn">
                <a class="nav-link" [routerLink]="['/login']">Sign In </a>
            </li>
            <li class="nav-item dropdown" style="z-index: 10;" *ngIf="isLoggedIn">
                <a class="nav-link dropdown-toggle text-white" href="" id="navbarDropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{name}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" [routerLink]="['/profile']">My Account</a>
                    <a class="dropdown-item" (click)="logout()">Sign Out</a>
                </div>
            </li>

        </ul>

    </div>
</nav>
<nav class="navbar navbar-expand-md navbar-dark sticky-top" style="background: black">
    <!-- <div class="navbar-toggler-right">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    </div> -->
    <div class="collapse navbar-collapse flex-column" id="navbar" style="padding-left: 10%">

        <ul class="navbar-nav w-100 justify-content-start px-3 bg-pattern">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/dashboard']">MySpace</a>

            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/gotorouting']"
                    [queryParams]="{ redirect: 'allfolders' }">Storing</a>


            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/gotorouting']" [queryParams]="{ redirect: 'shared' }">Sharing</a>

            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/gotorouting']" [queryParams]="{ redirect: 'chat' }">Chatting</a>

            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/gotorouting']"
                    [queryParams]="{ redirect: 'allfolders',action:'upload' }">Uploading</a>

            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/gotorouting']" [queryParams]="{ redirect: 'filerequests' }">Object
                    Requesting</a>

            </li>
            <!-- <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/gotorouting']"
                    [queryParams]="{ redirect: 'chat',action:'addfriend' }">Smart Contract</a>

            </li> -->
           
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/inboxmails']">Mail</a>

            </li>
<!--            
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/friendrequest']">Friends</a>

            </li> -->
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/gotorouting']"
                    [queryParams]="{ redirect: 'friendrequest' }">Friends</a>


            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <a class="nav-link" [routerLink]="['/userdashboard']">Projects</a>

            </li>
      

            <li class="nav-item active" *ngIf="!apiconsumer">
                <a  class="nav-link" (click)="DownloadFile()" >Srichid Drive</a>
                <!--...... <a class="nav-link" (click)="DownloadFile()" href="">Srichid Drive</a> -->

            </li>
            <li class="nav-item active" *ngIf="!apiconsumer">
                <!--...... <a  (click)="DownloadMigrateFile()" href="">Google drive Migration</a> -->
                 <a class="nav-link" (click)="DownloadMigrateFile()" >Google drive Migration</a>

            </li>

        </ul>
    </div>
</nav>
<!-- ---------------------hero section----------------- -->

<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">

<div>
    <div class="row">
     <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
         <ol class="carousel-indicators">
           <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
           <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
           <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
         </ol>
         <div class="carousel-inner">
           <div class="carousel-item active" style="height:200vh;">
             <img src="assets/img/new/e.png" class="d-block w-100" alt="...">
            <div class="carousel-caption d-flex">
             <div class="vstack gap-3 align-self-end">
               <h5 style="padding-bottom: 75%;font-size: 40px;">Blocdrive</h5>
               <!-- <p>Some representative placeholder content for the first slide.</p> -->
             </div>
           </div>
         </div>
           <div class="carousel-item">
             <img src="assets/img/new/b.png" class="d-block w-100" alt="...">
             <div class="carousel-caption d-flex">
                 <div class="vstack gap-3 align-self-end">
                   <h5 style="padding-bottom: 15%;font-size: 40px;">Internet of privacy has arrived!</h5>
                   <!-- <p>Some representative placeholder content for the first slide.</p> -->
                 </div>
               </div>
           </div>
           <div class="carousel-item">
             <img src="assets/img/new/purple.png" class="d-block w-100" alt="...">
             <div class="carousel-caption d-flex">
                 <div class="vstack gap-3 align-self-end">
                   <h5 style="padding-bottom: 15%;font-size:  40px;">Secure Data</h5>
                   <!-- <p>Some representative placeholder content for the first slide.</p> -->
                 </div>
               </div>
           </div>
         </div>
         <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
           <span class="carousel-control-prev-icon" aria-hidden="true"></span>
           <span class="sr-only">Previous</span>
         </a>
         <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
           <span class="carousel-control-next-icon" aria-hidden="true"></span>
           <span class="sr-only">Next</span>
         </a>
       </div>
     
   </div>
 </div>
<br><br><br><br><br><br>
<div class="container">
  <div class="row align-items-center g-5">
    <div class="col-lg-7">
        <h2>Secured by Smart Contracts</h2>
        The blocdrive system protects both personal and business data. Every piece of data is saved in a block. As a result, there is no need for data protection or assurance. Overall, the system will be beneficial in all sectors.
     
    
  </div>
    <div class="col-lg-5">
        <img src="assets/img/new/d.png" alt="..." class="img-fluid">
    </div>
  </div>
  <div class="row align-items-center g-5 banner">
    <div class="col-lg-10 offset-lg-1 p-5">
      <h2>Access to all of your data is simple and confidential.</h2>
      <p>Individuals can store and organise their personal data in one place. They can easily upload, modify, download, share, and request objects to their friends. Within the blocdrive, they can communicate privately. The account can only be monitored by its owner only. Blocdrive does not have a super user authority to manipulate users data.</p>
    </div>
  </div>

  <div class="row align-items-center gx-5 banner-img">
    <div class="col-lg-8 offset-lg-1 p-5">
      <h2>Blockchain</h2>
      <p>The new era of storage solution with blockchain which is the technology of security, trust and immutability!</p>
    </div>
  </div>
</div>


<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>

<script src="http://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js"></script>

<!-- ------plans---------- -->
<div class="page-content page-container" id="page-content">
    <div class="padding">
        <div class="text-center p-5">
            <h2>Plans</h2>
        </div>
        <div class="text-center">
            <div class="block d-inline-flex">
                <div class="p-4 p-sm-5 b-r"> <sup class="text-sm" style="top: -0.5em">₹</sup><span class="h1">1</span>
                    <div class="text-muted">Individuals Plan</div>
                    <div class="py-4"><a [routerLink]="['/payment']" class="btn btn-sm btn-rounded btn-primary" data-abc="true">Purchase</a></div> <small class="text-muted">Start <strong>for</strong>  free</small>
                </div>
                <div class="p-4 p-sm-5"> <sup class="text-sm" style="top: -0.5em">₹</sup><span class="h1">4500</span>
                    <div class="text-muted">Enterprise Plan</div>
                    <div class="py-4"><a [routerLink]="['/payment']" class="btn btn-sm btn-rounded btn-primary" data-abc="true">Purchase</a></div> <small class="text-muted">Buy the Plan</small>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- -----first------------------- -->
<section class="py-6 bg-light-primary">
    <div class="container">
        <div class="row justify-content-center text-center mb-4">
            <div class="col-xl-6 col-lg-8 col-sm-10">
                <h2>Features <br></h2>
            </div>
        </div>

        <br>
        <div class="col-md-12 row">
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <svg width="60" height="60" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z" fill="currentColor" opacity="0.3"></path>
                                    <path d="M12.6706167,18.7881514 L15.9697449,13.8394592 C16.1995092,13.4948127 16.1063788,13.0291607 15.7617323,12.7993963 C15.6385316,12.7172626 15.4937759,12.673434 15.3457071,12.673434 L12.659208,12.673434 L12.659208,9.69999981 C12.659208,9.28578625 12.3234215,8.94999981 11.909208,8.94999981 C11.6584431,8.94999981 11.4242696,9.07532566 11.2851703,9.28397466 L7.98604212,14.2326669 C7.75627777,14.5773134 7.84940818,15.0429654 8.19405469,15.2727297 C8.31725533,15.3548635 8.4620111,15.398692 8.61007984,15.398692 L11.296579,15.398692 L11.296579,18.3721263 C11.296579,18.7863398 11.6323654,19.1221263 12.046579,19.1221263 C12.2973439,19.1221263 12.5315174,18.9968004 12.6706167,18.7881514 Z" fill="currentColor" opacity="0.3"></path>
                                </g>
                            </svg>
                        </div>
                        <h6 class="font-weight-bold mb-3">Store</h6>
                        <p class="text-muted mb-0">blocdrive provides a service to store your folder with high security.</p>
                        <br></div>
                </div>
            </div>
     
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <svg width="60" height="60" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z" fill="currentColor"></path>
                                    <path d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z" fill="currentColor" opacity="0.3"></path>
                                    <path d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z" fill="currentColor" fill-rule="nonzero" transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508) "></path>
                                </g>
                            </svg>
                        </div>
                        <h6 class="font-weight-bold mb-3">Restore</h6>
                        <p class="text-muted mb-0">If you delete a folder or object by mistake, No worries you can restore it.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <svg width="60" height="60" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="currentColor" opacity="0.3"></path>
                                    <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="currentColor"></path>
                                    <rect fill="currentColor" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"></rect>
                                    <rect fill="currentColor" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"></rect>
                                    <rect fill="currentColor" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"></rect>
                                    <rect fill="currentColor" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"></rect>
                                    <rect fill="currentColor" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"></rect>
                                    <rect fill="currentColor" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"></rect>
                                </g>
                            </svg>
                        </div>
                        <h6 class="font-weight-bold mb-3">Organise</h6>
                        <p class="text-muted mb-0">Blocdrive user can also organise his Folder/Object as he wishes.</p>
                        <br> </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <svg width="60" height="60" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="currentColor"></path>
                                    <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="currentColor" opacity="0.3"></path>
                                </g>
                            </svg>
                        </div>
                        <h6 class="font-weight-bold mb-3">Chat</h6>
                        <p class="text-muted mb-0">Your friend and you are connected! Now you can share objects/folders or chat with them!</p>
                    </div>
                </div>
            </div>
            </div>
<br><br>
            <div class="col-md-12 row">
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <img src="https://img.icons8.com/color/48/000000/share--v2.png" alt="Share"/>
                            <!--...... <img src="https://img.icons8.com/color/48/000000/share--v2.png" /> -->

                        </div>
                        <h6 class="font-weight-bold mb-3">Share</h6>
                        <p class="text-muted mb-0">Just click and share your objects and folders.</p>
                        <br> </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <img src="https://img.icons8.com/fluency/48/000000/download.png" alt="Download"/>
                            <!--...... <img src="https://img.icons8.com/fluency/48/000000/download.png"/> -->

                        </div>
                        <h6 class="font-weight-bold mb-3">Download</h6>
                        <p class="text-muted mb-0">Download all your files and chat backup</p>
                        <br></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <img src="https://img.icons8.com/color/48/000000/edit-property.png" alt="Edit"/>
                            <!--...... <img src="https://img.icons8.com/color/48/000000/edit-property.png"/> -->

                        </div>
                        <h6 class="font-weight-bold mb-3">Edit</h6>
                        <p class="text-muted mb-0">Edit your object name and also edit your document by editor</p>
                        <br></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-hover-primary hover-scale">
                    <div class="card-body">
                        <div class="text-primary mb-5">
                            <img src="https://img.icons8.com/color-glass/48/000000/share-3.png" alt="Object Request"/>
                            <!--...... <img src="https://img.icons8.com/color-glass/48/000000/share-3.png"/> -->

                        </div>
                        <h6 class="font-weight-bold mb-3">Object Requests</h6>
                        <p class="text-muted mb-0">Invite anyone to upload files to your blocdrive,
                            even if they don't have an account.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br><br><br><br>
<!-- --------------company--------------- -->
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

<section class="ftco-section ftco-faqs">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 order-md-last" style="padding: 30px 1px 1px 70px;">
                <div class="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0" style="background-image: url(assets/img/new/c.png);">
                 
                </div>
           
            </div>
            <div class="col-lg-6">
                <div class="heading-section mb-5 mt-5 mt-lg-0">
                    <h2>Enterprise Benefits</h2>
                    <p>It is a framework which allows initiating, planning, implementing, prioritizing duties, monitoring tasks, influencing, and completing a team's work to achieve specific goals and specific requirements.</p>
                </div>
                <div id="accordion" class="myaccordion w-100" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header p-0" id="headingOne">
                            <h2 class="mb-0">
                                <button href="#collapseOne" class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link" data-parent="#accordion" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                    <p class="mb-0">Mail System</p>
                                    <i class="fa" aria-hidden="true"></i>
                                </button>
                            </h2>
                        </div>
                        <div class="collapse show" id="collapseOne" role="tabpanel" aria-labelledby="headingOne" >
                            <div class="card-body py-3 px-0">
                                <ol>
                                    <li>A mailing system is a piece of software that automates the planning, computation, outcome, and distribution of your company's outgoing mail.</li>
                                 
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-0" id="headingTwo" role="tab">
                            <h2 class="mb-0">
                                <button
                                    href="#collapseTwo"
                                    class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link collapsed"
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    <p class="mb-0">E-Signature</p>
                                    <i class="fa" aria-hidden="true"></i>
                                </button>
                            </h2>
                        </div>
                        <div class="collapse" id="collapseTwo" role="tabpanel" aria-labelledby="headingTwo" >
                            <div class="card-body py-3 px-0">
                                <ol>
                                    <li>It offers the best PDF tools for quickly preparing documents and collecting electronic signatures digitally.</li>
                              
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-0" id="headingThree" role="tab">
                            <h2 class="mb-0">
                                <button
                                    href="#collapseThree"
                                    class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link collapsed"
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    <p class="mb-0">Srichid Drive</p>
                                    <i class="fa" aria-hidden="true"></i>
                                </button>
                            </h2>
                        </div>
                        <div class="collapse" id="collapseThree" role="tabpanel" aria-labelledby="headingTwo" >
                            <div class="card-body py-3 px-0">
                                <ol>
                                    <li>Windows Based Drive can be used offline by downloading to your local system. So any changes made in a local system it will reflect as it is in the Blocdrive.</li>
                             
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-0" id="headingFour" role="tab">
                            <h2 class="mb-0">
                                <button
                                    href="#collapseFour"
                                    class="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link collapsed"
                                    data-parent="#accordion"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    <p class="mb-0">Google Drive Migration</p>
                                    <i class="fa" aria-hidden="true"></i>
                                </button>
                            </h2>
                        </div>
                        <div class="collapse" id="collapseFour" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="card-body py-3 px-0">
                                <p>
                                    Migrating data from Googledrive.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<br><br><br><br>
<!-- ----enterprise----- -->
<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css">
<section data-stellar-background-ratio="0.3" class="counter_feature">
	<div class="container">
		<div class="row text-center">
			<div class="col-lg-3 col-sm-6 col-xs-12 no-padding wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0">
				<div class="single-project">
					<span class="ti-email"></span>
					<h2 class="counter-num">Mail System</h2>
					<h3>Security guaranteed!</h3>
				</div>							
			</div>
			<div class="col-lg-3 col-sm-6 col-xs-12 no-padding wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
				<div class="single-project">
					<span class="ti-pencil-alt"></span>
					<h2 class="counter-num">E-Signature</h2>
                    <h3>Privacy assured!</h3>
				</div>
			</div><!-- END COL -->
			<div class="col-lg-3 col-sm-6 col-xs-12 no-padding wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
				<div class="single-project">
					<span class="ti-layout-media-center-alt"></span>
					<h2 class="counter-num">Srichid Drive</h2>
                    <h3>Portability enabled!</h3>
				</div>
			</div><!-- END COL -->
			<div class="col-lg-3 col-sm-6 col-xs-12 no-padding wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s" data-wow-offset="0">
				<div class="single-project">
					<span class="ti-cloud-down"></span>
					<h2 class="counter-num">Google Drive</h2>
                    <h3>Convenience created!</h3>
				</div>					
			</div><!-- END COL -->
		</div><!--- END ROW -->
		<div class="row text-center">						
			<div class="col-lg-8 offset-lg-2 col-sm-12 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">

                <h3 style="padding-top: 10%;padding-bottom: 10%;
    color: white;">Create a team to assist you in assigning tasks and dividing responsibilities.</h3>
				
			</div>						
		</div><!--- END ROW -->				
	</div><!--- END CONTAINER -->		
</section>
<app-support-chat></app-support-chat>