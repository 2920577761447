<app-nav-bar></app-nav-bar>
<br><br><br><br><br>
<div class="col-md-12 text-center">
    <h1>Frequently Asked Questions !</h1>
    <br>
</div>
<div class="col-md-12" style="text-align: center;">


    <div id="div">

        <button class="collapsible">Why should I go for blocdrive?</button>
        <div class="content">

            <p> blocdrive is a method of storing the objectS in a blockchain based object system. It provides customizable object archiving system to organize objects and folders in a systematic way. It also ensures the high level privacy and security of the customer's
                data. the system is impossible to manipulate because of the built in blockchain system feature and it also has no single point of failure.</p>



        </div>
        <button class="collapsible">How blocdrive is different from others?</button>
        <div class="content">
            <p> blocdrive is different from others because of its underlying blockchain technology. blocdrive provides high level privacy to the customers data with PKI enabled system.</p>

        </div>
        <button class="collapsible">  Is my data secure while sharing my object? </button>
        <div class="content">
            <p> Yes, blocdrive uses a dedicated transaction blockchain layer for sharing purpose. the system exchanges keys between the users for encrypting and decrypting the shared data. And moreover the sharing consensus algorithm in the transaction blockchain
                layer makes sure that the shared data is unchanged and safe.

        </div>
        <button class="collapsible">     If my object or folder gets deleted, can I restore my object or folder?</button>
        <div class="content">
            <p> When you delete your object or folder knowingly or unknowingly in blocdrive, you can restore by using restore option in deleted objects in the menu section.
            </p>
        </div>
        <button class="collapsible">Is my data used for any surveillance ?</button>
        <div class="content">
            <p> No, the specific data which is stored, uploaded and shared can only be monitored by its owner only. blocdrive does not have a superuser authority to manipulate users data.
            </p>

        </div>
        <button class="collapsible">Is my stuff safe with blocdrive?</button>
        <div class="content">
            <p> The objects and folders in your blocdrive are private by default until you decide to share them.</p>

        </div>
        <button class="collapsible">Can I migrate object from other cloud platform to my blocdrive? </button>
        <div class="content">
            <p> yes, it can be done with auth service provided by other platform. object can only be imported from other platform to blocdrive not other way around.
            </p>

        </div>
        <button class="collapsible">  Can I store very big objects in blocdrive?</button>
        <div class="content">
            <p> Yes, blocdrive allows users to upload object of any size.
            </p>
        </div>
        <button class="collapsible"> Am I anonymous to others in the system?</button>
        <div class="content">
            <p> Yes, until you become friend of others in the system.</p>
        </div>
        <button class="collapsible">   Does blocdrive use my data for any unofficial works?</button>
        <div class="content">
            <p> No, the data in blocdrive cannot be used for any unofficial purposes because of the premissioned blockchain architecture and PKI.</p>
        </div>



    </div>
</div>