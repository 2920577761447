<app-nav-bar></app-nav-bar>
<br>
<div class="col-md-12 row">
    <div class="col-md-2">
        <app-composemail></app-composemail>
    </div>
    <div class="col-md-10">

        <aside class="lg-side">
            <div class="inbox-head">
                <div class="col-md-8">
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Here " />
                    <br />
                </div>

            </div>
            <button class="btn btn-primary" style="height: 48px;" data-toggle="modal" data-target="#deleteExample"
            mdbWavesEffect >Delete All</button>
            <br>
            <br>
            <div  style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                Name
                <span class="fa fa-stack">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                </span>
            </div>
            <div class="inbox-body">
                <table class="table table-hover">
                    <div *ngIf="showcheckbox">
                        <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                        <td (click)="Deletecheck()">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </td>
                        <td (click)="archiveselected()">
                            <img src="assets/img/download.png" alt="" width="18px" />
                        </td>
                    </div>

                    <tbody style="background: #ecf0f1">
                        <tr class="unread" id="{{ ret.Id }}" style="cursor: pointer" *ngFor="
                    let ret of InboxList
                      | filter: searchText
                      | paginate: { itemsPerPage: 10, currentPage: p };
                    let i = index
                  ">
                  <!-- <td >
                    <input type="checkbox" style="height: 16px;" class="mail-checkbox" (click)="toggleSelect(ret)" >
                </td> -->
                <td >
                    <input type="checkbox" style="height: 16px;" class="mail-checkbox" value="{{ret.id}}" [(ngModel)]="InboxList[i].checked" (click)="toggleSelect(ret)" >
                </td>
                            <td class="view-message dont-show" (click)="ReadMessage(ret)">
                                {{ ret.fromName }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.subject }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.date }}
                            </td>
                            <td (click)="singlearchive(ret)">
                                <img src="assets/img/download.png" alt="" width="18px" />
                            </td>
                            <td (click)="singleDelete(ret)">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </td>
                            <td></td>

                        </tr>
                    </tbody>
                </table>

                <br /><br />
                <div class="padginationmedia">
                <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                </div>
                <br /><br />
                <br /><br />
            </div>
        </aside>
    </div>
</div>


<div class="modal modal-angular fade" id="deleteExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete Right</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body text-center">
                <h3 style="text-align: center">Are you sure to delete All Mails....??</h3>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning" data-dismiss="modal" 
                    mdbWavesEffect>Close</button>
                <button  data-dismiss="modal" type="submit" style="background-color: red" class="btn btn-danger" (click)="deleteallmails()"
                    mdbWavesEffect>Delete</button>
            </div>
        </div>
    </div>
</div>