import { Component, OnInit } from '@angular/core';
import { SavepaymentDetails } from 'src/app/ViewModels/SavePaymentDet';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/Shared/Login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { addresslist } from 'src/app/ViewModels/addresslist';
import { Subscription, interval } from 'rxjs';
import { PayU } from 'src/app/ViewModels/Payment';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  subscription: Subscription;
  orderid: any;
  continue: boolean;
  UserName:string;
  Detailss:any;
  CID:string;
 CardDetails:any;
  constructor(private router:ActivatedRoute,private routerr : Router,private registrationService: LoginService,) {
    this.router.params.subscribe(params=>{
      if(params["id"]){
        this.orderid= params["id"];
      }
    });
    window.scrollTo(0,0);

   }

   paymentMessage :any;
   timeWithInterval=0;
   myInterval:number;
   Amountpaid:any;
   Details:any;

  ngOnInit() {
    this.GetOrderPayments(this.orderid);
    this.continue=false;
  }

  StartTimer(){
    const source = interval(20000);
    this.subscription = source.subscribe(val => {
      this.GetPaymentStatus();
    });
  }


  GetOrderPayments(OID){
    const aa:addresslist = {
      CustomerId:OID,
    }
    this.registrationService.getorderpayment(aa).subscribe((data:any)=>{
      this.CardDetails = data;
      console.log("dataa", this.CardDetails);
    });
    
  }

  GetPaymentStatus(){
    const aa:PayU = {
      OrderId:this.orderid,
    }
    this.registrationService.getpaymentstatus(aa).subscribe((data:any)=>{
      if(data!=null && data.transactionStatus != ''){
        this.Details = data;
        if(this.Details.transactionStatus == "success"){
          this.continue = true;
          this.CancelTimer();
            this.paymentMessage = "The payment transaction with Transaction ID: "+" " + this.Details.transactionId +" " +" and the Amount of Rs." +" "+ this.Details.amount + " "+" has been successfully received.";                             
        }
        else if(this.Details.transactionStatus == "failure"){
          this.continue = true;
          this.CancelTimer();
           this.paymentMessage = "The payment transaction with Transaction ID: " + " " +this.Details.transactionId +" " +" and the Amount of Rs." +" "+this.Details.amount +" "+" failed. Please try again!";
        }
        else if(this.Details.transactionStatus == null){
          this.GetPaymentStatus();
        }
      }      
    });
  }
  gotopost(){
    this.routerr.navigate(['/jobposting']);    

  }
  gotologin(){
      this.routerr.navigate(['/login']);    
  }


  CancelTimer(){
    this.subscription.unsubscribe();
  }

}
