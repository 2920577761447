import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SuperadmindashboardService } from '../Shared/SuperAdmin-Dashboard/superadmindashboard.service';

@Component({
  selector: 'app-superadmindashboard',
  templateUrl: './superadmindashboard.component.html',
  styleUrls: ['./superadmindashboard.component.css']
})
export class SuperadmindashboardComponent implements OnInit {
  logindetails: any;
  p: number = 1;
  showhidevalue: boolean = false;
  enterprisedetails: any;
  enterprisevalue: boolean = false;
  individualvalue: boolean= false;
  individualdetails: any;
  alluservalue: boolean= false;
  userdetails: any;
  searchText:any;
  constructor(private router:Router,private spinner:NgxSpinnerService,private superadmindashboard: SuperadmindashboardService,) { }

  ngOnInit(): void {
    this.GetAlllogindetails();
    this.GetAllEnterprisedetails();
    this.GetAllindividualdetails();
    this.GetAllregistereddetails();
  }
  GetAlllogindetails() {
    this.spinner.show();
    this.superadmindashboard.getuserlogindetails().subscribe((respose: any) => {
      this.logindetails = respose;
      this.spinner.hide();

    });
  }
  getuserdetails(){
    this.showhidevalue=true;
    this.enterprisevalue=false;
    this.individualvalue=false;
    this.alluservalue=false;
    this.GetAlllogindetails();
  }

  GetEnterpriseUserdetails(){
    this.enterprisevalue=true;
    this.showhidevalue=false;
    this.individualvalue=false;
    this.alluservalue=false;
    this.  GetAllEnterprisedetails();
  }

  GetAllEnterprisedetails() {
    this.spinner.show();
    this.superadmindashboard.getenterpriseuserdetails().subscribe((respose: any) => {
      this.enterprisedetails = respose;
      this.spinner.hide();

    });
  }
  GetIndividualsdetails(){
    this.individualvalue=true;
    this.enterprisevalue=false;
    this.showhidevalue=false;
    this.alluservalue=false;
    this.GetAllindividualdetails();
  }
  GetAllindividualdetails() {
    this.spinner.show();
    this.superadmindashboard.getindividualuserdetails().subscribe((respose: any) => {
      this.individualdetails = respose;
      this.spinner.hide();
    });
  }

  GetAllUserdetails(){
    this.alluservalue=true;
    this.individualvalue=false;
    this.enterprisevalue=false;
    this.showhidevalue=false;

    this.GetAllregistereddetails();
  }

  GetAllregistereddetails() {
    this.spinner.show();
    this.superadmindashboard.getalluesrsdetails().subscribe((respose: any) => {
      this.userdetails = respose;
      this.spinner.hide();
   
    });
  }

  delete(userId) {
    this.superadmindashboard.delete(userId).subscribe((data: any) => {
      if (data == null) {
        alert("Deleted successfully");
        location.reload();
      } else {
        alert("Something went wrong!");
      }
    });
  }




  getent(enterPriseId) {
    this.spinner.show();
    this.superadmindashboard.getentdetails(enterPriseId).subscribe((respose: any) => {
      this.enterprisedetails = respose;
      this.spinner.hide();

    });
  }


}

