import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentsForTask } from 'src/app/ViewModels/DocumentsForTask';
import { DownloadFileVM } from 'src/app/ViewModels/DownloadFileVM';
import { TaskDetails } from 'src/app/ViewModels/TaskDetails';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {
  readonly rooturl1 = "https://blocdrive.in/blocdriveapi";

  constructor(private http:HttpClient) { }
  getuserTasksSummary(id){
    return this.http.get(this.rooturl1 + '/api/UserDashboard/GetUserDashboardSummary/'+id);
  }
  getUserTasks(request){
    return this.http.post(this.rooturl1 + '/api/UserDashboard/PostUserTasks', request);
  }
  getUserProjects(id){
    return this.http.get(this.rooturl1 + '/api/UserDashboard/GetUserProjects/' + id );
  }
  getUserTotalTasks(request){
    return this.http.post(this.rooturl1 + '/api/UserDashboard/PostUserTotalTasks', request);
  }
  getProjData(pid){
    return this.http.get(this.rooturl1 + '/api/EmployeeData/GetProjectData/' + pid);
  }
  getadminDashboard(request){
    return this.http.post(this.rooturl1 + '/api/Issue/PostUserTasksDashboard', request);
  }
  getstatus() {
    return this.http.get(this.rooturl1 + '/api/tblProjectStatus/GettblStatus');
  }
  updateTask(addTask){
    return this.http.post(this.rooturl1 + '/api/Issue/UpdateTask/', addTask);
  }
  getTaskData(id:TaskDetails){
    return this.http.post(this.rooturl1 + '/api/UserDashboard/PostCustomerEntireData' , id);
  }
  getalldocuments(id:DocumentsForTask){
    return this.http.post(this.rooturl1 + '/api/Documents/PostDocumentsForTasks' , id);
  }
  getTaskstatus(taskId) {
    return this.http.get(this.rooturl1 + '/api/TaskStatus/GetCurrentStatus/' + taskId);
  }
  getCurrentTaskComments(taskId) {
    return this.http.get(this.rooturl1 + '/api/TaskComments/GetCurrentTaskComments/' + taskId);
  }
  GetProject(projectId) {
    return this.http.get(this.rooturl1 + '/api/EmployeeData/GetProjectName/' + projectId);
  }
  savestatustask(status){
    return this.http.post(this.rooturl1 + '/api/TaskStatus/PosttblTaskStatu',status );
  }
  saveTaskcomments(comment){
    return this.http.post(this.rooturl1 + '/api/TaskComments/PosttblTaskComment',comment );
  }
  downloadFile(data:DownloadFileVM){
    return this.http.post(this.rooturl1 + '/api/Documents/PostDownloadTaskFiles', data);
  }
  downloadFileForAssignTask(data:DownloadFileVM){
        return this.http.post(this.rooturl1 + '/api/Documents/PostDownloadTaskAssignedFiles', data);

  }
}
