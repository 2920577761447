import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-based-dashboard',
  templateUrl: './role-based-dashboard.component.html',
  styleUrls: ['./role-based-dashboard.component.css']
})
export class RoleBasedDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
