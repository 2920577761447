import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { SupportVM } from 'src/app/ViewModels/SupportVM';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  issueType: any;
  isLoggedIn: boolean;
  uid: string;
  accid: string;
  focus1=false;
  focus=false;
  constructor(private router: Router, private user: UserDetailsService,private spinner:NgxSpinnerService) {
    window.scrollTo(0,0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.accid = localStorage.getItem("uid");

    }
  }

  ngOnInit() {
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if(footer.style.display=="none"){
        footer.style.display = "block";
      }
    }, 1000);
    
  }

  onSelectType(data) {
    this.issueType = data;
  }


  SaveSupportQuery(form: NgForm) {
    if(this.isLoggedIn==true){
      const input: SupportVM =
      {
        Id: 0,
        UserId: this.uid,
        AliasId: this.accid,
        Issue: this.issueType,
        Name: form.controls["firstname"].value,
        Email: form.controls["email"].value,
        IssueDescription: form.controls["msg"].value,
        ComplaintId:null,
        LastUpdateddate: null,
        RequestDate: null
      }
      this.spinner.show();
      this.user.SaveSupportRequest(input).subscribe((response: any) => {
        alert("Your Request Submitted Successfully,We will come back to you soon");
        this.router.navigate(['/']);
        this.spinner.hide();
  
      });
    }
    else{
      this.router.navigate(['/login']);

    }
   
  }
}
