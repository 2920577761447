<app-nav-bar></app-nav-bar>
<div class="container">
    <div class="table-responsive mt-3" style="height:95vh">
        <table class="table table-striped table-hover table-sm mb-0">

            <thead>
                <th>File Name</th>
                <th>Current Version</th>
                <th>Date</th>
                <th>Doc Type</th>
            </thead>
            <tbody *ngFor="let ret of verlist">
                <tr>

                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'image') &&
mob
">
                        <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                            (click)="ViewImageDoc(ret,content)" />
                        <span (click)="ViewImageDoc(ret,content)" style="cursor: pointer;"
                            title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'application/pdf' ||
ret.documentType == 'pdf') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/pdficon.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span style="cursor: pointer;" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.documentType == 'xlsx' ||ret.documentType =='application/octet-stream') &&
mob
" (click)="ViewxlsDoc(ret,pdftesting)">
                        <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewxlsDoc(ret,pdftesting)" style="cursor: pointer;"
                            title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.documentType == 'ppt' ||
ret.documentType == 'pptx') &&
mob
" (click)="ViewPpt(ret,pdftesting)">
                        <img src="assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewPpt(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'video/mp4' ||
ret.documentType == 'mp4') &&
mob
" (click)="ViewVideo(ret,videocontent)">
                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewVideo(ret,videocontent)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.documentType == 'doc' ||
ret.documentType == 'docx') &&
mob
" (click)="ViewDocumentword(ret,pdftesting)">
                        <img src="assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocumentword(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'application/x-zip-compressed' ||
ret.documentType == 'zip') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'text/plain' ||
ret.documentType == 'txt') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>

                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'audio/mp3' ||
ret.documentType == 'mp3') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>

                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'image') &&
!mob
">
                        <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                            (click)="ViewImageDoc(ret,content)" />
                        <span (click)="ViewImageDoc(ret,content)" style="cursor: pointer;"
                            title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'application/pdf' ||
ret.documentType == 'pdf') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/pdficon.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;"
                            title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.documentType == 'xlsx' ||ret.documentType =='application/octet-stream') &&
!mob
" (click)="ViewxlsDoc(ret,pdftesting)">
                        <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewxlsDoc(ret,pdftesting)" style="cursor: pointer;"
                            title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.documentType == 'ppt' ||
ret.documentType == 'pptx') &&
!mob
" (click)="ViewPpt(ret,pdftesting)">
                        <img src="assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewPpt(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'video/mp4' ||
ret.documentType == 'mp4') &&
!mob
" (click)="ViewVideo(ret,videocontent)">
                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewVideo(ret,videocontent)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.documentType == 'doc' ||
ret.documentType == 'docx') &&
!mob
" (click)="ViewDocumentword(ret,pdftesting)">
                        <img src="assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocumentword(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'application/x-zip-compressed' ||
ret.documentType == 'zip') &&
!mob" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'text/plain' ||
ret.documentType == 'txt') && !mob" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>

                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'audio/mp3' ||
ret.documentType == 'mp3') &&
!mob" (click)="ViewDocument(ret,pdftesting)">
                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
                            &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                    </td>
                    <!-- <td [hidden]="
ret.documentType == 'text/plain' ||
ret.documentType == 'txt' ||
ret.documentType == 'application/x-zip-compressed' ||
ret.documentType == 'zip' ||
ret.documentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.documentType == 'doc' ||
ret.documentType == 'docx' ||
ret.documentType == 'video/mp4' ||
ret.documentType == 'mp4' ||
ret.documentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.documentType == 'ppt' ||
ret.documentType == 'pptx' ||
ret.documentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.documentType == 'xlsx' || ret.documentType =='application/octet-stream' ||
ret.documentType == 'application/pdf' ||
ret.documentType == 'image/jpeg' ||
ret.documentType == 'image/png' ||
ret.documentType == 'pdf' ||
ret.documentType == 'audio/mp3' ||
ret.documentType == 'mp3'
">
                        <img src="assets/img/anyf1.png" style="width: 35px; height: 34px; cursor: pointer;" />
                        <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
                    </td> -->
                    <td>{{ret.currentVerion}}</td>

                    <td>{{ ret.date | date: "short" }}</td>
                    <td>{{ ret.documentType }}</td>

                </tr>
            </tbody>
        </table>
    </div>

    
<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>
<!-- IMAGE VIEW -->
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
        <h6 style="float: left;">
            Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <a [href]="imgdisplay.file" style="cursor: pointer;"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white;">
        <div>&nbsp;</div>
        <h6 style="float: left;">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <video controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">
    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white;">
        <audio controls #videoPlayer style="height: 350px; width: 400px;">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>
</div>

