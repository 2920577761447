import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MsserviceService } from './msservice.service';

@Component({
  selector: 'app-mslogin',
  templateUrl: './mslogin.component.html',
  styleUrls: ['./mslogin.component.css']
})
export class MsloginComponent implements OnInit {
  @ViewChild('officeFrameholder') officeFrameholder: ElementRef;
  @ViewChild('officeForm') officeForm: ElementRef;

  accessToken: any;
  accessTokenTTL:any;
  actionUrl: any;

  constructor(private msservice:MsserviceService) {

  }
  ngOnInit(): void {
 

    this.getActionUrlForDocument();
  }


  login() {

  }


  loadDocument() {
    const existingOfficeframe = document.getElementById('officeFrame');

    if (existingOfficeframe) {
      existingOfficeframe.parentNode.removeChild(existingOfficeframe);
    }
 //https://accountdatastorage.blob.core.windows.net/pictures/Signature and Face Recognition Documentation.docx
     // this.actionUrl = this.getActionUrlForDocument("https://wopi.onedrive.com/wopi/files/4FECA7F0B7E2C607!27887");
    // this.httpservice.get("http://localhost:44300/wopi/files").subscribe((data)=>
    // {
    //   this.actionUrl = data;
    // });
//    this.actionUrl = '<action name="edit" ext="docx" requires="locks,update"urlsrc="https://word-edit.officeapps.live.com/we/wordeditorframe.aspx?<ui=UI_LLCC&><rs=DC_LLCC&><showpagestats=PERFSTATS&>"/>'
    this.actionUrl = "https://word-edit.officeapps.live.com/we/wordeditorframe.aspx?WOPISrc=http://localhost:60385/wopi/Srichid%20Drive%20Documentation.docx?access_token="+this.accessToken;
  // this.actionUrl = '<action name="edit" ext="docx" requires="locks,update" urlsrc="https://FFC-word-edit.officeapps.live.com/we/wordeditorframe.aspx?<ui=UI_LLCC&><rs=DC_LLCC&><dchat=DISABLE_CHAT&><hid=HOST_SESSION_ID&><sc=SESSION_CONTEXT&><wopisrc="http://localhost:60385/wopi/Srichid%20Drive%20Documentation.docx"&><showpagestats=PERFSTATS&><IsLicensedUser=BUSINESS_USER&><actnavid=ACTIVITY_NAVIGATION_ID&>"/>' 
   const officeFrame = document.createElement('iframe');
      officeFrame.name = 'officeFrame';
      officeFrame.style.width = '100%';
      officeFrame.style.height = '100%';
      officeFrame.id = 'officeFrame';
      officeFrame.title = 'Office Online Frame';
      officeFrame.setAttribute('allowfullscreen', 'true');
      officeFrame.setAttribute(
        'sandbox',
        'allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox'
      );
     // let action = '<action name="edit" ext="docx" requires="locks,update"urlsrc="https://word-edit.officeapps.live.com/we/wordeditorframe.aspx?<ui=UI_LLCC&><rs=DC_LLCC&><showpagestats=PERFSTATS&>"/>';
      this.officeFrameholder.nativeElement.appendChild(officeFrame);
      this.officeForm.nativeElement.action = this.actionUrl;
      this.officeForm.nativeElement.submit();
    
  }

test()
{
 // let url = "https://word-edit.officeapps.live.com/we/wordeditorframe.aspx?src=http://localhost:60385/wopi/Srichid%20Drive%20Documentation.docx?access_token="+this.accessToken;
 // let url = '<action name="edit" ext="docx" requires="locks,update"urlsrc="https://word-edit.officeapps.live.com/we/wordeditorframe.aspx?<ui=UI_LLCC&><rs=DC_LLCC&><showpagestats=PERFSTATS&>"/>';

  const officeFrame = document.createElement('iframe');
      officeFrame.name = 'officeFrame';
      officeFrame.id = 'officeFrame';
      officeFrame.style.width = '100%';
      officeFrame.style.height = '100%';
      officeFrame.title = 'Office Online Frame';
      officeFrame.setAttribute('allowfullscreen', 'true');
      officeFrame.setAttribute(
        'sandbox',
        'allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox'
      );
     // let action = '<action name="edit" ext="docx" requires="locks,update"urlsrc="https://word-edit.officeapps.live.com/we/wordeditorframe.aspx?<ui=UI_LLCC&><rs=DC_LLCC&><showpagestats=PERFSTATS&>"/>';
      this.officeFrameholder.nativeElement.appendChild(officeFrame);
      this.officeForm.nativeElement.action = this.actionUrl+"?access_token="+this.accessToken+"&access_token_ttl="+this.accessTokenTTL;
      this.officeForm.nativeElement.submit();
    

}
  
  getActionUrlForDocument() {
    //   var data={
    //     id:"BLK2021081800001",
    // action:"edit"
    //   }
    var data = "c082428c-7644-4bec-8bf6-957fdcc32f56";
      this.msservice.getActionUrl(data).subscribe((data:any)=>
        {
          this.accessToken = data.access_token;
          this.accessTokenTTL = data.access_token_ttl;
          this.actionUrl = data.wopi_urlsrc;
          this.test();
        });
  
  }

  getWordEditorActionUrlForWOPISrc(WOPISrc: string) {
    const baseUrl =
      'https://word-edit.officeapps.live.com/we/wordeditorframe.aspx';

    const httpParams = new HttpParams({
      fromObject: {
        ui: 'en-US',
        rs: 'en-US',
        hid: 'vTpeDoBDxU+J0HQbEq12cg.0',
        WOPISrc,
        wdo: '1',
        wde: 'docx',
        sc: 'host%3D%26qt%3DFolders',
        mscc: '1',
        wdp: '0'
      }
    });

    return `${baseUrl}?${httpParams.toString()}`;
  }

}
