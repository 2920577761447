<app-nav-bar></app-nav-bar>

<!-- <app-user-sidebar
  *ngIf="(roleid == '2' || roleid == '9') && mobileview"
></app-user-sidebar> -->

<p class="promobar">
    We are here to help during this challenging time.See our tips for working from home with blocdrive
</p>
<div class="how-section1">
    <div class="row">
        <div class="col-lg-6 mb-40" *ngIf="lap">
            <img src="/assets/img/Bonlineimg1.svg" *ngIf="lap" class="img-fluid" alt="Using Software" width="100%" />
        </div>

        <div class="col-md-6" style="padding-top: 3%; padding-bottom: 3%">
            <h4>blocdrive</h4>
            <h4 class="subheading">
                Engage employees. Have meaningful discussions. Stay blocdrive — together.
            </h4>
            <p class="my-3 text-secondary">
                Work towards a common goal with blocdrive. Build a productive community where everyone stays on the same page.
            </p>
        </div>
        <div class="col-lg-6 mb-40" *ngIf="mob">
            <img src="/assets/img/Bonlineimg1.svg" *ngIf="mob" class="img-fluid" alt="Using Software" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-6" style="padding-top: 3%; padding-bottom: 3%">
            <h4>Get Registered Your Project</h4>
            <h4 class="subheading">
                Register Your Project in blocdrive with the Blockchain Technology.
            </h4>
            <p class="my-3 text-secondary">
                Verify your project with the higher authorities. Assign task to your employees and get work done
            </p>
        </div>
        <div class="col-lg-6 mb-40" *ngIf="lap">
            <img src="/assets/img/Bonlineimg3.svg" *ngIf="lap" class="img-fluid" alt="Using Software" width="100%" />
        </div>
        <div class="col-lg-6 mb-40" *ngIf="mob">
            <img src="/assets/img/Bonlineimg3.svg" *ngIf="mob" class="img-fluid" alt="Using Software" />
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 mb-40" *ngIf="lap">
            <img src="/assets/img/Bonlineimg2.svg" *ngIf="lap" class="img-fluid" alt="Using Software" width="100%" />
        </div>

        <div class="col-md-6" style="padding-top: 3%; padding-bottom: 3%">
            <h4>Track the status of your Project</h4>
            <h4 class="subheading">
                Track the status of your project after Registration
            </h4>
            <p class="my-3 text-secondary">
                Go through the project history and get the person details who has changed it!!
            </p>
        </div>
        <div class="col-lg-6 mb-40" *ngIf="mob">
            <img src="/assets/img/Bonlineimg2.svg" *ngIf="mob" class="img-fluid" alt="Using Software" />
        </div>
    </div>

    <div class="container my-5">
        <section>
            <div class="text-center mb-5">
                <h2 class="font-weight-bold display-4"></h2>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="bg-light position-relative px-3 mt-5 text-center py-5">
                        <div class="my-2"></div>
                        <p class="my-3">blocdrive is with Blockchain</p>
                        <div class="px-3 text-center pb-3">
                            <p class="my-3 text-secondary">
                                Your data is secure with us. Data is secured with Blockchain technology,Register your confidential Projects and assign tasks to your employees
                            </p>
                            <br />
                        </div>
                    </div>
                    <div class="ml-3">
                        <div class="font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center position-relative border border-white mt-4" style="width: 60px; height: 60px"></div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="text-white position-relative px-3 mt-5 text-center py-5" style="background-color: #7100aa">
                        <div class="my-2"></div>
                        <div class="px-3 text-center pb-3">
                            <p class="my-3">Get history of the Approved projects</p>
                            <p class="my-3">
                                The Registered projects will be verified by the Higher authorities and will be approved.check the history of the project and get details like when and who has changed it
                            </p>
                            <br />
                        </div>
                    </div>
                    <div class="ml-3">
                        <div class="font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center position-relative border border-white mt-4" style="width: 60px; height: 60px"></div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bg-light position-relative px-3 mt-5 text-center py-5">
                        <div class="my-2"></div>
                        <p class="my-3">Get your projects verified by higher authorities</p>
                        <div class="px-3 text-center pb-3">
                            <p class="my-3 text-secondary">
                                The Registered projects will be Inwarded, examined and verified by the Higher authorities. If the projects seems faultless then it will be approved.
                            </p>
                            <br />
                        </div>
                    </div>
                    <div class="ml-3">
                        <div class="font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center position-relative border border-white mt-4" style="width: 60px; height: 60px"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="row">
        <div class="col-lg-5 mb-40" *ngIf="lap">
            <img src="/assets/img/Bonlineimg4.svg" *ngIf="lap" class="img-fluid" alt="Using Software" width="100%" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6" style="
        padding-top: 10%;
        padding-bottom: 10%;
        font-size: 26px;
        line-height: 150%;
        text-align: justify;
      ">
            <p class="my-3 text-secondary">
                blocdrive has helped us connect ideas and employees across our company. The upcoming features that we are testing make it even easier to get information to those in other regions and functions, and engage in conversation at all levels of our company.
                We look forward to seeing these roll out.
            </p>
        </div>
        <div class="col-lg-6 mb-40" *ngIf="mob">
            <img src="/assets/img/Bonlineimg4.svg" *ngIf="mob" class="img-fluid" alt="Using Software" />
        </div>
    </div>

    <div class="container my-5">
        <section>
            <div class="text-center mb-5">
                <h4>Top questions about blocdrive</h4>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="bg-light position-relative px-3 mt-5 text-center py-5">
                        <div class="my-2"></div>
                        <div class="px-3 text-center pb-3">
                            <p class="my-3 text-secondary">
                                Who has access to blocdrive? Can I interact with people outside of my organization?
                            </p>
                            <p class="my-3 text-secondary">
                                All blocdrive users have access, if permitted by their blocdrive administrator. Users can interact with people outside of their organization through private communities that are external to the company domain.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-white position-relative px-3 mt-5 text-center py-5" style="background-color: #7100aa">
                        <div class="my-2"></div>
                        <div class="px-3 text-center pb-3">
                            <p class="my-3">
                                How do I manage and moderate the use of blocdrive?
                            </p>
                            <p class="my-3">
                                All blocdrive users have access, if permitted by their blocdrive administrator. Users can interact with people outside of their organization through private communities that are external to the company domain.
                            </p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<app-support-chat></app-support-chat>