<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="col-md-12">
  <div class="col-md-2">&nbsp;</div>
  <form [formGroup]="inwarddetails">
    <div class="container register">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 row">
            <div class="col-md-1">&nbsp;</div>
            <div class="col-md-4">
              <button class="btn btn-primary" (click)="gotofile(filepopup)">
                View Object/Folder
              </button>
            </div>
          </div>
          <div class="col-md-12 row" style="color: white">
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              &nbsp;
              <label class="dropdown-item">
                <b (click)="AddDocuments(adddocuments)" style="color: #0000ff; cursor: pointer; font-size: 20px"><i
                    class="fas fa-file"></i>&nbsp;&nbsp;Upload Files</b>
              </label>
            </div>
            <br />
          </div>
          <div>&nbsp;</div>
          <div class="col-md-12 row" style="color: white">
            <div class="col-md-6">
              <label for="section">Object Number</label>
              <select (change)="OnselectFilename($event.target.value)" class="form-control dropdownsize">
                <option value="">Select Object Name</option>
                <option *ngFor="let do of FilePlanList" value="{{ do.fileUniqueId }}" [selected]="do.fileUniqueId==selectedfilename">
                  {{ do.filename }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="section">Folder</label>
              <input type="text" class="form-control" formControlName="foldername" name="foldername"
                [(ngModel)]="foldername" autofocus readonly />
            </div>
            <div class="col-md-6">
              <label for="recievedfrom">Registered By</label>
              <input type="text" class="form-control" name="fileusername" [(ngModel)]="fileusername" autofocus
                formControlName="fileusername" [ngClass]="{
                  'is-invalid': submitted && c.fileusername.errors
                }" />
              <div *ngIf="submitted && c.fileusername.errors" class="invalid-feedback">
                <div *ngIf="c.fileusername.errors.required" style="color: #fff687; font-size: 18px">
                  Registered By is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="recievedfrom">Exhibits</label>
              <input type="text" class="form-control" name="exhibitfilename" [(ngModel)]="exhibitfilename" autofocus
                formControlName="exhibitfilename" [ngClass]="{
                  'is-invalid': submitted && c.exhibitfilename.errors
                }" />
              <div *ngIf="submitted && c.exhibitfilename.errors" class="invalid-feedback">
                <div *ngIf="c.exhibitfilename.errors.required" style="color: #fff687; font-size: 18px">
                  Exhibits is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="inwarddate">Inward Date(DD-MM-YYYY)</label>
              <input type="date" class="form-control" name="inwarddate" [(ngModel)]="inwarddate" autofocus
                formControlName="inwarddate" [ngClass]="{
                  'is-invalid': submitted && c.inwarddate.errors
                }" />
              <div *ngIf="submitted && c.inwarddate.errors" class="invalid-feedback">
                <div *ngIf="c.inwarddate.errors.required" style="color: #fff687; font-size: 18px">
                  Inward Date is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="inwardno">Inward No</label>
              <input type="text" class="form-control" name="InwardNumber" [(ngModel)]="InwardNumber" autofocus readonly
                formControlName="InwardNumber" />
            </div>
            <div class="col-md-6">
              <label for="for">Document Type</label>
              <select (change)="OnselectDocumentType($event.target.value)" class="form-control dropdownsize">
                <option value="">Select Document Type</option>
                <option *ngFor="let do of DocumentTypeList" value="{{ do.documentTypeID }}" [selected]=" do.documentTypeID ==selecteddocumenttype">
                  {{ do.documentTypeName }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="circulation">Circulation Group</label>
              <input type="text" class="form-control" name="circulation" [(ngModel)]="circulation" autofocus
                formControlName="circulation" [ngClass]="{
                  'is-invalid': submitted && c.circulation.errors
                }" />
              <div *ngIf="submitted && c.circulation.errors" class="invalid-feedback">
                <div *ngIf="c.circulation.errors.required" style="color: #fff687; font-size: 18px">
                  Circulation Group is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="inward">Inward Type</label>
              <select (change)="OnselectInwardType($event.target.value)" class="form-control dropdownsize">
                <option value="">Select Inward Type</option>
                <option *ngFor="let do of InwardTypeList" value="{{ do.id }}" [selected]="do.id==selectedInwardType">
                  {{ do.inwardType1 }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="sensitivity">Priority</label>
              <select (change)="OnselectPriority($event.target.value)" class="form-control dropdownsize">
                <option value="">Select Sensitivity</option>
                <option *ngFor="let do of sensitivitylist" value="{{ do.id }}" [selected]=" do.id==selectedpriority">
                  {{ do.sensitivityType }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="page">Pages</label>
              <input type="text" class="form-control" name="page" [(ngModel)]="page" autofocus formControlName="page"
                [ngClass]="{ 'is-invalid': submitted && c.page.errors }" />
              <div *ngIf="submitted && c.page.errors" class="invalid-feedback">
                <div *ngIf="c.page.errors.required" style="color: #fff687; font-size: 18px">
                  Pages is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="for">Note(s)</label>
              <textarea type="text" class="form-control" name="note" [(ngModel)]="note" autofocus formControlName="note"
                [ngClass]="{ 'is-invalid': submitted && c.note.errors }"></textarea>
              <div *ngIf="submitted && c.note.errors" class="invalid-feedback">
                <div *ngIf="c.note.errors.required" style="color: #fff687; font-size: 18px">
                  Note(s) is required
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="section">Workflow Stage</label>
              <select (change)="OnselectWorkflowStage($event.target.value)" class="form-control dropdownsize">
                <option value="">Select Workflow Stage</option>
                <option *ngFor="let do of WorkflowStageList" value="{{ do.id }}" [selected]="do.id==selectedworkflowstage">
                  {{ do.workflowStage }}
                </option>
              </select>
            </div>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>

          <div class="col-md-12 row">
            <div class="col-md-3">&nbsp;</div>
            <div class="col-md-4">
              <button type="submit" class="btn btn-primary form-control" style="
                  background-color: rgb(255, 255, 255);
                  border-color: rgb(255, 255, 255);
                  color: black;
                  padding: 9px 30px;
                " (click)="PreviewInward()">
                PREVIEW & DOWNLOAD
              </button>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-primary form-control" style="
                  background-color: rgb(255, 255, 255);
                  border-color: rgb(255, 255, 255);
                  color: black;
                  padding: 9px 30px;
                " (click)="SaveInward()">
                SAVE & CIRCULATE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<br /><br />

<ng-template class="modalsize" #filepopup let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Select File or Folder to View
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table" *ngIf="InwardTypeFile == 'false'">
      <thead style="color: #33383e">
        <th></th>
        <th>File/Folder</th>
        <th>Setting</th>
      </thead>
      <tbody *ngFor="let folderview of AllFolders.subFolders">
        <tr>
          <td *ngIf="mob">
            <input type="checkbox" (click)="toggleSelect(folderview)"
              style="height: 16px; width: 16px; cursor: pointer" />
          </td>
          <td *ngIf="!mob" style="width: 18px">
            <input type="checkbox" (click)="toggleSelect(folderview)"
              style="height: 16px; width: 16px; cursor: pointer" />
          </td>
          <td *ngIf="mob" style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            ">
            <i class="fa fa-folder" style="color: orange"></i> &nbsp;&nbsp;
            <span (click)="GetSubfolder(folderview)" style="cursor: pointer"
              title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
          </td>
          <td *ngIf="!mob" style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            ">
            <i class="fa fa-folder" style="color: orange"></i> &nbsp;&nbsp;
            <span (click)="GetSubfolder(folderview)" style="cursor: pointer"
              title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
          </td>
          <td *ngIf="!multi">
            <div ngbDropdown class="dropdown" placement="bottom-left">
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer"><i class="fa fa-gear"
                  aria-hidden="true"></i></a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareFolder(folderview, shareSingleFolder)"
                  (click)="c('Close click')" style="cursor: pointer">Share</a>
                <a class="dropdown-item" *ngIf="EnableDownload" (click)="DownloadFolder(folderview)"
                  style="cursor: pointer">Download</a>
                <a class="dropdown-item" *ngIf="EnableEdit" (click)="UpdateSubFolder(folderview, editsubfolder)"
                  (click)="c('Close click')" style="cursor: pointer">Edit</a>
                <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(folderview)" (click)="c('Close click')"
                  style="cursor: pointer">Delete</a>
              </div>
            </div>
          </td>

          <td *ngIf="multi">
            <div ngbDropdown class="dropdown" placement="bottom-left">
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer"></i></a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                  (click)="c('Close click')" style="cursor: pointer">Delete</a>
                <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                  (click)="c('Close click')" style="cursor: pointer">Share</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngFor="let ret of AllFolders.retData">
        <tr>
          <td *ngIf="mob">
            <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
          </td>
          <td *ngIf="!mob" style="width: 18px">
            <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png') &&
              mob
            ">
            <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
              (click)="c('Close click')" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'application/pdf' ||
                ret.documentType == 'pdf') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'video/mp4' || ret.documentType == 'mp4') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'doc' ||
                ret.documentType == 'docx') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'application/x-zip-compressed' ||
                ret.documentType == 'zip') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'text/plain' || ret.documentType == 'txt') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'audio/mp3' || ret.documentType == 'mp3') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png') &&
              !mob
            ">
            <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
              (click)="c('Close click')" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'application/pdf' ||
                ret.documentType == 'pdf') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'video/mp4' || ret.documentType == 'mp4') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'doc' ||
                ret.documentType == 'docx') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'application/x-zip-compressed' ||
                ret.documentType == 'zip') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'text/plain' || ret.documentType == 'txt') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'audio/mp3' || ret.documentType == 'mp3') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td [hidden]="
              ret.documentType == 'text/plain' ||
              ret.documentType == 'txt' ||
              ret.documentType == 'application/x-zip-compressed' ||
              ret.documentType == 'zip' ||
              ret.documentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              ret.documentType == 'doc' ||
              ret.documentType == 'docx' ||
              ret.documentType == 'video/mp4' ||
              ret.documentType == 'mp4' ||
              ret.documentType ==
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
              ret.documentType == 'ppt' ||
              ret.documentType == 'pptx' ||
              ret.documentType ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              ret.documentType == 'xlsx' ||
              ret.documentType == 'application/pdf' ||
              ret.documentType == 'image/jpeg' ||
              ret.documentType == 'image/png' ||
              ret.documentType == 'pdf' ||
              ret.documentType == 'audio/mp3' ||
              ret.documentType == 'mp3'
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/anyf1.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
          </td>
          <td *ngIf="!multi">
            <div ngbDropdown class="dropdown" placement="bottom-left">
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer"><i class="fa fa-gear"
                  aria-hidden="true"></i></a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(ret)" (click)="c('Close click')"
                  style="cursor: pointer">Delete</a>
                <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareoneFile(ret, shareSingleFile)"
                  (click)="c('Close click')" style="cursor: pointer">Share</a>
              </div>
            </div>
            <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
          </td>

          <td *ngIf="multi">
            <div ngbDropdown class="dropdown" placement="bottom-left">
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear" style="cursor: pointer"
                  aria-hidden="true"></i></a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                  (click)="c('Close click')" style="cursor: pointer">Delete</a>
                <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                  (click)="c('Close click')" style="cursor: pointer">Share</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table" *ngIf="InwardTypeFile == 'true' && AllFiles">
      <thead style="color: #33383e">
        <th>File</th>
        <th></th>
        <th style="font-weight: bold">Setting</th>
      </thead>

      <tbody *ngFor="let ret of AllFiles.retData">
        <tr>
          <td *ngIf="mob">
            <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
          </td>
          <td *ngIf="!mob" style="width: 18px">
            <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png') &&
              mob
            ">
            <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
              (click)="c('Close click')" class="close" aria-label="Close" (click)="d('Cross click')" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" class="close" aria-label="Close"
              (click)="d('Cross click')" style="cursor: pointer" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'application/pdf' ||
                ret.documentType == 'pdf') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'video/mp4' || ret.documentType == 'mp4') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'doc' ||
                ret.documentType == 'docx') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'application/x-zip-compressed' ||
                ret.documentType == 'zip') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'text/plain' || ret.documentType == 'txt') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            " *ngIf="
              (ret.documentType == 'audio/mp3' || ret.documentType == 'mp3') &&
              mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png') &&
              !mob
            ">
            <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
              (click)="c('Close click')" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'application/pdf' ||
                ret.documentType == 'pdf') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
              title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'video/mp4' || ret.documentType == 'mp4') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'doc' ||
                ret.documentType == 'docx') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'application/x-zip-compressed' ||
                ret.documentType == 'zip') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'text/plain' || ret.documentType == 'txt') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>

          <td style="
              white-space: nowrap;
              width: auto;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              width: 40%;
            " *ngIf="
              (ret.documentType == 'audio/mp3' || ret.documentType == 'mp3') &&
              !mob
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
              &nbsp;&nbsp;{{ ret.documentName }}
            </span>
          </td>
          <td [hidden]="
              ret.documentType == 'text/plain' ||
              ret.documentType == 'txt' ||
              ret.documentType == 'application/x-zip-compressed' ||
              ret.documentType == 'zip' ||
              ret.documentType ==
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              ret.documentType == 'doc' ||
              ret.documentType == 'docx' ||
              ret.documentType == 'video/mp4' ||
              ret.documentType == 'mp4' ||
              ret.documentType ==
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
              ret.documentType == 'ppt' ||
              ret.documentType == 'pptx' ||
              ret.documentType ==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              ret.documentType == 'xlsx' ||
              ret.documentType == 'application/pdf' ||
              ret.documentType == 'image/jpeg' ||
              ret.documentType == 'image/png' ||
              ret.documentType == 'pdf' ||
              ret.documentType == 'audio/mp3' ||
              ret.documentType == 'mp3'
            " (click)="ViewDoc(ret)" (click)="c('Close click')">
            <img src="assets/img/anyf1.png" style="width: 65px; height: 60px; cursor: pointer" />
            <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
          </td>
          <td *ngIf="!multi">
            <div ngbDropdown class="dropdown" placement="bottom-left">
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer"><i class="fa fa-gear"
                  aria-hidden="true"></i></a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(ret)" (click)="c('Close click')"
                  style="cursor: pointer">Delete</a>
                <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareoneFile(ret, shareSingleFile)"
                  (click)="c('Close click')" style="cursor: pointer">Share</a>

                <!-- <div class="dropdown-divider"></div>
                                            <a class="dropdown-item">Separated link</a> -->
              </div>
            </div>
            <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
          </td>

          <td *ngIf="multi">
            <div ngbDropdown class="dropdown" placement="bottom-left">
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear" style="cursor: pointer"
                  aria-hidden="true"></i></a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                  (click)="c('Close click')" style="cursor: pointer">Delete</a>
                <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                  (click)="c('Close click')" style="cursor: pointer">Share</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')" style="color: black; cursor: pointer">
      Close
    </button>
  </div>
  <br />
</ng-template>
<ng-template #adddocuments let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload Documents</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">Object Number:</h5>
    <select (change)="OnselectFilename($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Object Name</option>
      <option *ngFor="let do of FilePlanList" value="{{ do.fileUniqueId }}">
        {{ do.filename }}
      </option>
    </select>
    <h5 class="modal-title" id="modal-basic-title">Folder:</h5>
    <input type="text" id="foldername" [(ngModel)]="foldername" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Object:</h5>
    <input type="text" id="exhibitfilename" [(ngModel)]="exhibitfilename" class="form-control" name="dp" />
    <h5 class="modal-title" id="modal-basic-title">Enter Description:</h5>
    <input type="text" id="filedescription" placeholder="Enter Description" [(ngModel)]="filedescription"
      class="form-control" name="dp" />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="SaveDocuments()" (click)="d('Cross click')"
      style="color: black; cursor: pointer">
      Save
    </button>
    <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="cursor: pointer">
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div class="alert alert-info" style="
        background-color: #d64950;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
      <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b></span>
    </div>
  </div>
</div>

<ng-template #shareSingleFile let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
      <b>Select Members to Share</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px">
    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="margin-left: 10px">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)"
        style="margin-left: 10px">
        <option value="">Select Friend</option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SharOnlyFileToUsers()" (click)="d('Cross click')">
      Share
    </button>
  </div>
  <br />
</ng-template>

<ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
      <b>Select Members to Share</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px">
    <br />
  </div>

  <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="margin-left: 10px">
    {{ mem.friendName }}<br />
  </div>
  <div class="col-lg-12 row">
    <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)"
      style="margin-left: 10px">
      <option value="" hidden>Select Friend</option>
      <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
        {{ I.friendName }}
      </option>
    </select>
  </div>
  <br />
  <div class="col-lg-12 text-center">
    <span style="font-size: 12px">Don't find your friend in the list? &nbsp;</span>
    <button type="button" class="btn btn-primary" (click)="addfrnd()">
      Add Friend
    </button>
  </div>
  <div *ngIf="frndrqst">
    <br />
    <div>
      <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                                                            Email:
                                                        </h5> -->
      <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email" name="dp" />
    </div>
    <br />
    <div>
      <button type="button" class="btn btn-primary" (click)="savefrienddetails()"
        style="color: white; padding: 5px; margin-left: 10px">
        Send Request
      </button>
    </div>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ShareExsistingSubFolderToUsers()" (click)="d('Cross click')">
      Share
    </button>
  </div>
  <br />
</ng-template>

<ng-template #shareall let-d="dismiss" let-c="dismiss">
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
      <br />
    </div>

    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer; margin-left: 10px">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select *ngIf="shareitem" class="form-control dropdownsize" style="margin-left: 10px"
        (change)="ShareFileMembers($event.target.value)">
        <option value="" hidden>Select Friend</option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />

    <div class="col-lg-12 text-center">
      <span style="font-size: 12px">Don't find your friend in the list? &nbsp;</span>
      <button type="button" class="btn btn-primary" (click)="addfrnd()">
        Add Friend
      </button>
    </div>
    <div class="col-lg-12 text-center">
      <span style="font-size: 12px">Do you wish to cancel shared documents? &nbsp;</span>

      <button type="button" class="btn btn-primary" (click)="cancelshare()">
        Cancel Shared Documents
      </button>
    </div>

    <div *ngIf="frndrqst">
      <div>
        <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;">
                                                                                Email:
                                                                            </h5> -->
        <br />
        <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
      </div>
      <br />
      <div>
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
          style="color: black; color: white; padding: 5px; margin-left: 10px">
          Send Request
        </button>
      </div>
    </div>
    <br />
    <div *ngIf="cancelshareall">
      <div class="col-lg-12 row">
        <select *ngIf="shareitem1" class="form-control dropdownsize" (change)="ShareFileMembers1($event.target.value)">
          <option value="" hidden>Select Friend</option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer">
            {{ I.friendName }}
          </option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="shareitem1" class="btn btn-primary" (click)="shareallallcancel()"
        (click)="d('Cross click')">
        Cancel Shared Documents
      </button>
      &nbsp; &nbsp;
      <button type="button" *ngIf="!shareitem1" class="btn btn-primary" (click)="shareallall()"
        (click)="d('Cross click')">
        Share
      </button>
    </div>
  </div>
  <br />
</ng-template>

<ng-template #deleteMultiConformation let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
      <b>Delete Data </b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px">
    <div class="col-lg-12 row">
      <h4 class="modal-title w-100" id="myModalLabel">
        <b>Do You want to Delete {{ deletemulticontent }} &nbsp; ?</b>
      </h4>
    </div>
    <br />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="Deleteselected()" (click)="d('Cross click')">
      Delete
    </button>
    <button type="button" class="btn btn-warning" (click)="d('Cross click')">
      Close
    </button>
  </div>
  <br />
</ng-template>

<ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
      <b>Select Members to Share</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px">
    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer; margin-left: 10px">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" style="margin-left: 10px"
        (change)="ShareFileMembers($event.target.value)">
        <option value="">Select Friend</option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()" style="cursor: pointer"
      (click)="d('Cross click')">
      Share
    </button>
  </div>
  <br />
</ng-template>
<!-- EDIT FOLDER -->
<ng-template #editsubfolder let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
    <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="UpdateSubFolderName()" (click)="d('Cross click')"
      style="color: black; cursor: pointer; width: 15%">
      Update
    </button>
  </div>
  <br />
</ng-template>

<style>
  .modalsize {
    width: 950px;
    max-width: 950px;
    overflow-y: 50vh;
  }

  @media (max-width: 960px) {
    .modalsize {
      width: 450px;
      max-width: 650px;
      overflow-y: 50vh;
    }
  }

  @media (max-width: 575px) {
    .modalsize {
      width: 480px;
      max-width: 550px;
      overflow-y: 50vh;
    }
  }

  @media (max-width: 460px) {
    .modalsize {
      width: 380px;
      max-width: 450px;
      overflow-y: 50vh;
    }
  }

  @media (max-width: 390px) {
    .modalsize {
      width: 350px;
      max-width: 450px;
      overflow-y: 50vh;
    }
  }

  @media (max-width: 320px) {
    .modalsize {
      width: 310px;
      max-width: 450px;
      overflow-y: 50vh;
    }
  }
</style>