import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-helppage',
  templateUrl: './helppage.component.html',
  styleUrls: ['./helppage.component.css']
})
export class HelppageComponent implements OnInit {
id:any;
signup :any;
login :any;
uploadfolder :any;
uploadfile :any;
sharesingle :any;
sharemulti :any;
movesingle :any;
movemulti :any;
deletesingle :any;
deletemulti :any;
deleterestore :any;
viewsort :any;
viewgrid :any;
addfriend :any;
acceptfriend :any;
newchat :any;
  offlinebsafe: any;
  downloadmultiple: any;
  downloadsingle: any;
  Default = true;

  constructor() {
    window.scrollTo(0,0);

   }

  ngOnInit() {
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if(footer.style.display=="none"){
        footer.style.display = "block";
      }
    }, 1000);
    
  }

  signup1(){
    this.signup = true;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;
  }
  login1(){
    this.signup = false;
    this.login = true;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  uploadfolder1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = true;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  uploadfile1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = true;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  sharesingle1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = true;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  sharemulti1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = true;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  movesingle1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = true;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  movemulti1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = true;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  deletesingle1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = true;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  deletemulti1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = true;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  deleterestore1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = true;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  viewsort1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = true;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  viewgrid1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = true;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  addfriend1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =true;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  acceptfriend1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = true;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  newchat1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = true;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }

  Bsafe1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = true;
    this.downloadmultiple = false;
    this.downloadsingle = false;
    this.Default = false;

  }
  downloadmultiple1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = true;
    this.downloadsingle = false;
    this.Default = false;

  }
  downloadsingle1(){
    this.signup = false;
    this.login = false;
    this.uploadfolder = false;
    this.uploadfile = false;
    this.sharesingle = false;
    this.sharemulti = false;
    this.movesingle = false;
    this.movemulti = false;
    this.deletesingle = false;
    this.deletemulti = false;
    this.deleterestore = false;
    this.viewsort = false;
    this.viewgrid = false;
    this.addfriend =false;
    this.acceptfriend = false;
    this.newchat = false;
    this.offlinebsafe = false;
    this.downloadmultiple = false;
    this.downloadsingle = true;
    this.Default = false;

  }

}
