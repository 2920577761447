<app-nav-bar></app-nav-bar>
<div class="container">
<div class="col-md-12 row">
    <div class="col-md-6" style="padding-left: 60px;">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <h1 class="textheading">All of blocdrive, working for you</h1>
        <p>Sign in to your blocdrive Account, and get the most out of all the blocdrive services you use. Your account
            helps you do more by personalizing your blocdrive experience and offering easy access to your most important
            information from anywhere.
        </p>
    </div>
    <div class="col-md-6 row" style="padding-top: 130px">

        <div class="col-md-2">
            <i class="fas fa-share-square" style="font-size: 40px;margin-top: 65px;"></i>
        </div>
        <div class="col-md-2">
            <i class="fas fa-cloud-upload-alt" style="font-size: 40px;margin-top: 65px;"></i>
        </div>
        <div class="col-md-4">
            <img src="assets/img/blocdrivelogo1.png" class="picture" alt="" />
        </div>
        <div class="col-md-2">
            <i class="fas fa-trash-restore-alt" style="font-size: 40px;margin-top: 65px;"></i>
        </div>
        <div class="col-md-2">
            <i class="fas fa-cloud-download-alt" style="font-size: 40px;margin-top: 65px;"></i>
        </div>
    </div>
</div>
<br/><br/><br/>
<hr/>
<div class="col-md-12 row" style="background-color: #f9f9f9;">
    <div class="col-md-4" style="padding: 50px;">
        <h3>Helps you
        </h3>
        <p>When you’re signed in, all of the blocdrive services you use work together seamlessly to offer help with everyday 
            tasks like syncing your objects and folders</p>
    </div>
    <div class="col-md-4" style="padding: 50px;">
        <h3>Built for you</h3>
        <p>No matter which device or blocdrive service you’re using, your account gives you a consistent experience you can customize and manage at any time.</p>
    </div>
    <div class="col-md-4" style="padding: 50px;">
        <h3>Protects you</h3>
        <p>Your blocdrive Account is protected by Blockchain security that automatically helps detect and block threats before they ever reach you.</p>
    </div>
</div>
<hr/>
<br/><br/>
        <div class="col-md-12 row">
            <div class="col-md-4">
                <div class="our-team">
                    <div class="pic">
                        <img  src="assets/img/sogala.png" alt="">
                    </div>
                  
                    <div class="team-content">
                        <h3 class="title">DR SATCHIDANANDA S SOGALA
                  
                            </h3>
                        <span class="post">          FOUNDER AND CEO</span>
                    </div>
                    <p class="description">
                        MS (Financial Markets) from University of Illinois, USA,  Ph.D. ( Credit Risk Quantification), M.A.,
                        B.A. Ed .(I Rank), L.L.B, C.A.I.I.B, ,C.R.B., PGCTE,  ISO Auditor , Adjunct Professor at Business School
                                            </p>
                </div>
            </div>
           
            <div class="col-md-4">
                <div class="our-team">
                    <div class="pic">
                        <img  src="assets/img/dinesh.png" alt="">
                    </div>
                   
                    <div class="team-content">
                        <h3 class="title">K V DINESHA
                           
                            </h3>
                        <span class="post">PROFESSOR FROM IIIT BENGALURU</span>
                    </div>
                    <p class="description">
                       Ph.D.(IIIT Bombay), Post doctoral fellow, Doctorate of Philosophy(Ph.D), 
                       Master of Science(M.Sc-Physics), 
                       Bachelor of Science(B.Sc-Honors)
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="our-team">
                    <div class="pic">
                        <img  src="assets/img/sid.png" alt="">
                    </div>
                   
                    <div class="team-content">
                        <h3 class="title">Sandeep Bhat
                            </h3>
                        <span class="post">C.A, C.P.A (Aus),
                            Director Strategy</span>
                    </div>
                    <p class="description">
                        35+ Years experience in financial services,
                        Ex-CFO, Aditya Birla Private Equity,
                        Ex-COO & ED, UBS India Limited,,
                        Ex-COO, CLSA India Ltd.,
                        Ex-CFO & D, ABN AMRO Equities Ltd.
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="our-team">
                    <div class="pic">
                        <img  src="assets/img/a.png" alt="">
                    </div>
                   
                    <div class="team-content">
                        <h3 class="title">Mr. Siddharth Arur
                           
                            </h3>
                        <span class="post">BEPM – (U-C) Berkeley,

                            Director Buisness Development</span>
                    </div>
                    <p class="description">
                        IIM, Calcutta, Alumnus,
                        17+ Years in Impact Investing,
                        Women’s World Banking,
                        ING, Standard Chartered & DWM,
                        Visiting Faculty – NMIMS / S.P.
                    </p>
                </div>
            </div>
        </div>
    </div>
