import { Component, OnInit } from '@angular/core';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-deleted-items',
  templateUrl: './deleted-items.component.html',
  styleUrls: ['./deleted-items.component.css']
})
export class DeletedItemsComponent implements OnInit {
  uid: string;
  username: string;
  Role: string;
  totalItems: any;
  p:number=1;
  DeleteItems:any
  roleid: string;
  mobileview: boolean;
  constructor(private workflowservice:WorkFlowService) { 
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.Role = localStorage.getItem("role");
      this.roleid = localStorage.getItem("role");

    }
    this.isMobileMenu();
  }

  ngOnInit(): void {
    this.getDeletedItems();
  }
  getDeletedItems(){
    this.workflowservice.getDeletedItem(this.uid).subscribe((response:any)=>{
      this.DeleteItems=response;
      this.totalItems=this.DeleteItems.length;
    })
  } isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

}
