import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Exibhits } from 'src/app/Workflow/work-allotment/work-allotment.component';

@Injectable({
  providedIn: 'root'
})
export class WorkAllotmentService {
  readonly baseAppUrl: string = 'https://blocdrive.in/blocdriveapi'

  constructor(private http:HttpClient) {  
  }
  getUsers(id){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetAllUsers/' + id);
  }
  getUsersWithinDepartment(id){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetAllUsersWithinDepartment/' + id);

  }
  getinwardedDocuments(){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetInwardedDocuments/');
  }
  getSections(){
    return this.http.get(this.baseAppUrl + '/api/Custom/GetWorkflowStageMaster/');
  }
  GetExhibits(data:Exibhits){
    return this.http.post(this.baseAppUrl + '/api/Examinations/CaseExhibits/',data);
  }
  GetInwardData(data){
    return this.http.post(this.baseAppUrl + '/api/WorkflowDataProvider/PostInwardByMailId/',data);
  }
  GetDocumentsByDocumentID(id){
    return this.http.get(this.baseAppUrl + '/api/GeneralTransaction/GetGeneralTransactionDocumentId/' + id);
  }
  SendWork(data){
    return this.http.post(this.baseAppUrl + '/api/WorkAllotment/PostIWorkAllotmentForm/',data);
  }
  GetAllSidebarMenus(){
    return this.http.get(this.baseAppUrl + '/api/MenuMaster/GetMenusForSidebar/');

  }
}
