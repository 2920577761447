import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { MessageService } from 'src/app/Shared/MessageService';
import { RoleService } from 'src/app/Shared/Role/role.service';
import { WorkFlowMasterService } from 'src/app/Shared/WorkFloMaster/work-flow-master.service';
import { WorkFlowStateMasterService } from 'src/app/Shared/WorkFlowState/work-flow-state-master.service';

@Component({
  selector: 'app-work-flow-state-master',
  templateUrl: './work-flow-state-master.component.html',
  styleUrls: ['./work-flow-state-master.component.css']
})
export class WorkFlowStateMasterComponent implements OnInit {
  workflowstates: any;
  staid: any;
  workstatename: string;
  roleDesc: any;
  rolid: number;
  showSave: any;
  showUpdate: any;
  p: number = 1
  public dismissalert = false;
  notify: string
  createdby: any;
  createdon: any;

  Serviceid: any;
  ServieTypeList: any;
  loansList: any;
  dataList: any;
  lid: any;
  workstatedesc: any;
  workid: number;
  RolesList: any;
  roleid: any;
  Workflows: any;
  GUID: string;
  uid: string;
  username: string;
  constructor(private messageService: MessageService,private auditortransactionservice:AuditorTransactionService
    , private workflowservice: WorkFlowMasterService, private roleservice: RoleService, private Wkstateservice: WorkFlowStateMasterService, private spinner: NgxSpinnerService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }

  }

  ngOnInit() {
    this.Getworkflowstates();
    this.Getworkflowstatedata();
    this.GetRoles();
    this.GetWorkflowdata();
    this.workstatename = "";
    this.roleDesc = "";

  }

  onSelectworkflow(data: any) {
    this.workid = data
  }
  GetService(id) {
    for (let index = 0; index < this.ServieTypeList.length; index++) {
      if (this.ServieTypeList[index].servieTypeId == id) {
        return this.ServieTypeList[index].serviceName;
      }
    }
  }
  GetRoles() {
    this.spinner.show();
    this.roleservice.getAllRoles(this.GUID).subscribe((Data: any) => {
      this.RolesList = Data;
      this.spinner.hide();
    })
  }
  GetRoleName(id) {
    for (let index = 0; index < this.RolesList.length; index++) {
      if (this.RolesList[index].id == id) {
        return this.RolesList[index].roleName;
      }
    }
  }
  GetWorkflowdata() {
    this.spinner.show();
    this.workflowservice.getworkflows(this.GUID).subscribe((response: any) => {
      this.Workflows = response;
      this.spinner.hide();
    });
  }
  Getworkflow(id) {
    for (let index = 0; index < this.Workflows.length; index++) {
      if (this.Workflows[index].workflowMasterId == id) {
        return this.Workflows[index].workflowName;
      }
    }
  }
  onSelectRole(data: any) {
    this.roleid = data
  }

  Updateworkflowstate(form: NgForm) {
    this.spinner.show();
    const inputRequest1 = {
      WorkflowId: this.workid,
      RoleId: this.roleid,
      WorkflowStateId: this.staid,
      WorkflowStateName: form.controls["workstatename"].value,
      WorkflowStateDescription: form.controls["workstatedesc"].value,
      HostId: "",
      CreatedBy: "",
      CreatedOn: "",
      GUID: this.GUID

    }
    this.Wkstateservice.UpdateworkflowstateRow(inputRequest1).subscribe(
      (data: any) => {
        if (data == 1) {
          this.notify = "Workflow State updated successfully"
          const audit = {
            TransactionName:"Master",
            TransactionType:"Update",
            TransactionDesc:"Workflow State  master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Getworkflowstates();
          this.spinner.hide();

        }
        else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Getworkflowstates();
          this.spinner.hide();
        }
      }
    );
  }
  SaveworkflowstateD(form: NgForm) {
    this.spinner.show();
    const inputRequest = {
      WorkflowStateId: 0,
      WorkflowId: this.workid,
      RoleId: this.roleid,
      WorkflowStateName: form.controls["workstatename"].value,
      WorkflowStateDescription: form.controls["workstatedesc"].value,
      HostId: null,
      CreatedBy: "",
      CreatedOn: "",
      GUID: this.GUID

    }
    this.Wkstateservice.saveworkflowstate(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Workflow State saved successfully"
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Workflow State  master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Getworkflowstates();
          this.spinner.hide();

        }
        else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Getworkflowstates();
          this.spinner.hide();
        }
      }
    );
  }
  Getworkflowstates() {
    this.spinner.show();
    this.Wkstateservice.getworkflowstates(this.GUID).subscribe((response: any) => {
      this.workflowstates = response;
      this.spinner.hide();
    });
  }

  Getworkflowstatedata() {
    this.spinner.show();
    this.Wkstateservice.getworkflowstatedata(this.roleid).subscribe((response: any) => {
      this.workflowstates = response;
      this.spinner.hide();
    });
  }
  editworkflowstate(data: any) {
    this.showSave = false;
    this.showUpdate = true;
    this.staid = data.workflowStateId;
    this.workid = data.workflowId;
    this.roleid = data.roleId;
    this.workstatename = data.workflowStateName;
    this.workstatedesc = data.workflowStateDescription;
    this.createdby = data.createdBy;
    this.createdon = data.createdOn;
  }
  addClick() {
    this.showSave = true;
    this.showUpdate = false;
    if (this.workstatename != "") {
      this.workstatename = "";
    }
  }

  deleteworkflowstate(data: any) {
    this.rolid = data.workflowStateId;
  }
  deleteworkflowstateRow() {
    this.spinner.show();
    this.Wkstateservice.deleteworkflowstatedata(this.rolid).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Workflow State deleted successfully"
          const audit = {
            TransactionName:"Master",
            TransactionType:"Delete",
            TransactionDesc:"Workflow State  master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Getworkflowstates();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Getworkflowstates();
          this.spinner.hide();
        }
      });
  }

}
