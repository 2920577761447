import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VCEvent } from '../ViewModels/VCEvent';
import { VideoService } from '../Shared/Video/video.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-host-event',
  templateUrl: './host-event.component.html',
  styleUrls: ['./host-event.component.css']
})
export class HostEventComponent implements OnInit {

  count = 1;
  userid;
  isLoggedIn: boolean;
  uid: string;
  username: string;
  roleid: string;
  mobileview: boolean;
  constructor(private service: VideoService, private router: Router) {
    this.userid = localStorage.userId;
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
    }
    this.isMobileMenu()
  }

  ngOnInit(): void {
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  add() {

    var emailtext = (<HTMLInputElement>document.getElementById("inputs").lastChild).value;
    if (emailtext == "" || !emailtext.includes('@')) {
      alert("Please type in the email of invitee to continue");
    }
    else {
      var inputs = document.getElementById("inputs");
      var node = document.createElement("input");
      node.setAttribute("name", "email" + (++this.count).toString());
      node.setAttribute("id", "email" + (this.count).toString());
      node.setAttribute("class", "form-control");
      node.setAttribute("placeholder", "Please type the invitee email");

      node.setAttribute("type", "email");
      inputs.append(document.createElement('br'));
      inputs.append(document.createElement('br'));

      inputs.append(node);
    }
  }
  submit() {
    var totalAdded = 1;
    var isgroup = false;
    if (this.count > 1) {
      isgroup = true;
    }
    for (var i = 0; i < this.count; i++) {
      var j = i + 1;
      var emailcount = "email" + j;
      var emailtext = (<HTMLInputElement>document.getElementById(emailcount)).value;
      console.log(emailtext);
      if (emailtext != "" && emailtext.includes('@')) {
        const data: VCEvent = {
          ClientID: emailtext,
          Finished: false,
          HostID: this.userid,
          Id: null,
          OnDate: null,
          isGroup: isgroup
        }
        this.service.getusermailid(data.ClientID).subscribe((response: any) => {
          if (response != null) {
            data.ClientID = response;
            this.service.saveHostId(data).subscribe((data: any) => {
              totalAdded += 1;
            });
          }
          else {
            alert(data.ClientID + " is not using Jobzone");
          }
          var timerid = setInterval(() => {
            if (totalAdded == this.count) {
              alert("Invitation sent successfully");

              clearInterval(timerid);
              this.router.navigate(['/hostjoin']);
            }
          }, 100);
        })
      }
      else {
        totalAdded += 1;

      }
    }

  }
}
