<app-nav-bar></app-nav-bar>



<div class="row">
    <div class="col-lg-12">
        <div class="box">
            <!--mail inbox start-->
            <div class="mail-box">
                <aside class="sm-side">
                    <div class="user-head">

                        <div class="user-name">
                            <h5><a href="#">NAME</a></h5>
                            <span><a href="#">EMAIL</a></span>
                        </div>

                    </div>
                    <div class="inbox-body">
                        <a class="btn btn-compose" data-toggle="modal" (click)="composemail()" data-original-title="" title="">
                            Compose
                        </a>


                    </div>

                    <ul class="inbox-nav inbox-divider">
                        <li class="active">
                            <a (click)="inbox()"><i class="fa fa-inbox"></i> Inbox </a>
                        </li>
                        <li>
                            <a (click)="sentitems()"><i class="fa fa-envelope-o"></i> Sent Mail</a>
                        </li>

                        <!-- <li>
                            <a (click)="archiveitems()"><i  class="fas fa-download"></i> Archive</a>
                        </li> -->
                        <li>
                            <a (click)="deleteitems()"><i class=" fa fa-trash-o"></i> Trash</a>
                        </li>
                    </ul>


                </aside>
                <aside class="lg-side">
                    <div class="inbox-head">
                        <h3>Compose</h3>

                    </div>
                    <div class="inbox-body">

                        <div class="col-md-10" style="
          padding: 30px 72px 50px 180px;">

                            <!-- <label>Ticket Id</label>
                            <select (change)="onSelectTicket($event.target.value)" class="form-control">
    <option value="">Select Ticket Id</option>
    <option *ngFor="let do of TicketsList" value={{do.ticketId}}>
        {{do.ticketId}}
    </option>
</select> -->
                            <div class="col-md-12" id="divstyle">
                                <label id="labeldiv">To</label>
                                <div id="aboveTagInp">
                                    <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                                        [separatorKeyCodes]="[13,188,186,32]">
                                        <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                                        <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'email'" >
                                            <ng-template let-item="item" let-index="index">
                                                {{ item.email }}
                                            </ng-template>
                                        </tag-input-dropdown>
                                    </tag-input>
                                </div>
                                <!-- <input class="form-control" type="text" id="inputbox" name="to" [(ngModel)]="to" /> -->
                            </div><br /><br />
                            <!-- <br /> <br />

                            <label> To</label>
                            <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
                <option value=""> Select Friend </option>
                <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
                    {{ I.friendName }}
                </option>
            </select>
                            <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
                                {{ mem.friendName }}<br />
                            </div> -->
                            <br />
                            <label>Subject</label>
                            <input type="text" class="form-control" name="mailsubject" [(ngModel)]="mailsubject" autofocus>
                            <br />
                            <label>Message</label>
                            <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="4" cols="50"> </textarea>
                            <br /> <br />
                            <!-- <label id="actionfunctions">
                                <p data-toggle="modal" data-target="#UploadModalPopup">
                                  <img src="./assets/img/ellipse182.png"
                style="cursor: pointer;" /> &nbsp; Add Attachment From Blocdrive
                                </p>
                              </label><br /> <br /> -->
                            <label>Upload Documents</label>
                            <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)"> <br /> <br />
                            <div style="text-align: right;">
                                <button type="submit" id="SendBtn" class="btn btn-primary" (click)="sendmail()">SEND</button></div><br /> <br />
                        </div>

                    </div>
                </aside>
            </div>
            <!--mail inbox end-->
        </div>
    </div>
</div>

<!-- Upload from computer modal -->
<div class="modal modal-angular fade" id="UploadModalPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content" id="choosepopup">
            <button type="button" class="close" id="closeinto" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" style="float: right;">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach File</p>
            </div>
            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <!-- <div id="chosefromcom">
            <br /> <br />
            <img src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
            <br />
            <label id="chosefromcom">
              <p class="temp-upload-icon" for="excel-upload">
                UPLOAD FROM COMPUTER</p>
              <input #fileInput (click)="fileInput.value = null"
type="file" id='excel-upload' style="display: none"
                (change)="onChangeDocumnets($event)" multiple="true" />
                <input type="file" name="avatars" multiple=true
(change)="onChangeDocumnets($event)"> <br /> <br />

            </label>
            <br />
          </div> -->
                </div>
                <div class="col-md-2"></div>
            </div>

            <br />
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv" id="chosefromcom">
                        <br /> <br />
                        <img data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitmaindiv" src="./assets/img/Choosefrom.png" style="cursor: pointer;" />
                        <br />
                        <p class="temp-upload-icon" data-dismiss="modal" style="margin-top: 1%;" data-toggle="modal" data-target="#choosefromhaspitmaindiv">
                            UPLOAD FROM BLOCDRIVE
                        </p>
                        <br />
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-5"></div>
                    <div style="margin-left: -6%;">
                        <button type="button" id="testt" class="close" data-dismiss="modal" aria-label="Close"></button>
                        <button type="submit" id="CloseBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button><br>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<br /> <br /> <br />