import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientapprovalService } from '../Shared/ClientCreation/clientapproval.service';
import { LoginService } from '../Shared/Login/login.service';
import { SuperadmindashboardService } from '../Shared/SuperAdmin-Dashboard/superadmindashboard.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';

@Component({
  selector: 'app-clientdashboard',
  templateUrl: './clientdashboard.component.html',
  styleUrls: ['./clientdashboard.component.css']
})
export class ClientdashboardComponent implements OnInit {
  clientdatalist: any;
  p: number = 1;
  clientdetails: boolean = false;
  frndslist: any;
  Friends: any;
  GUID: string;
  friendid: any;
  allclientsharedvalue: boolean = false;
  allFolders1: any;
  SharedFiles: any;
  selectedSelected: any[];
  allFolders: any;
  logindetails: any;
  userid: any;
  clientuserid: any;
  documentName:any
  userId: string;
  all: any =[];

  current_url: any;
  pdfUrl: any;
  imgdisplay: any;
  clientfiledetails1: any;
  blk1: any;
  zz: any  =[];
  clientfiledetails2: any =[];
  zzzz: any  =[];
  blk2: any;
  zzz1: any;
  blk3: any;
  ppt: any;
  pptview: any;
  clientfiledetails3: any =[];
  blkdoc: any;
  docview: any;
  clientfiledetailsdoc: any =[];
  doc: any;
  blkexcel: any;
  excelview: any;
  clientfiledetailsexcel: any = [];
  excel: any;

  closeResult: string;
  msg: any;
  m: any;
  clientfiledetails: any =[];
  blk: any;
  zzz: any;
  File: any;
  constructor(private clientapproval: ClientapprovalService,private userService: LoginService,
    private userservice: UserDetailsService,private router:Router,
    private superadmindashboard: SuperadmindashboardService,
    private modalService: NgbModal,private domSanitizer: DomSanitizer) { 
    this.GUID = localStorage.getItem("GUID");
  }

  ngOnInit(): void {
//this.GetAllFolders1();
    this. GetFriendDetails();
  }
  onSelectedclient(data : any){
    this.friendid = data;
      //this.GetallFilesForUplodtoCompare();
      this.selectedSelected = [];
      this.userservice.getAllSharingfolders(this.friendid).subscribe((respose: any) => {
        this.allFolders = respose;
        console.log(this.allFolders)
         this.all = this.allFolders[0].sharedFiles;
         this.File = this.all.file;
         console.log(this.all);
        //console.log(this.all);
        if (this.allFolders.length > 0 && this.allFolders[0].sharedFiles.length != 0 && this.allFolders[0].TotalMembers == null) {
          this.SharedFiles = this.allFolders[0].sharedFiles;
        }
        else if (this.allFolders == null || this.allFolders == undefined || this.allFolders == "") {
        }
        else if (this.allFolders[0].TotalMembers != null) {
          this.allFolders = respose;
          this.SharedFiles = this.allFolders[0].sharedFiles;
        }
        // this.spinner.hide();
      });
  }






 private getDismissReason(reason: any): string {
   if (reason === ModalDismissReasons.ESC) {
     return 'by pressing ESC';
   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
     return 'by clicking on a backdrop';
   } else {
     return `with: ${reason}`;
   }
 }
 
 ViewImageDoc(aa, content) {
   if (aa.pdfFile != null) {
    // this.ViewDoc(aa)
   }
   else {
     this.imgdisplay = aa;
     this.modalService.open(content).result.then((result) => {
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.m = this.msg;
     });
   }
 }
 ViewDocumenttxt(aa, pdftesting) {

   if (aa.pdfFile != null) {
     //this.ViewDoc(aa)
   }
   else {
 
     this.zzz = aa;
     this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
     console.log(this.pdfUrl);
     this.pdfUrl = this.zzz.file;
     // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";
 
     this.modalService.open(pdftesting).result.then((result) => {
 
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.m = this.msg;
       console.log(this.m)
 
     });
   }
 }
 
 
 ViewPpt(aa, pdftesting) {
   if (aa.pdfFile != null) {
     //this.ViewDoc(aa)
   }
   else {
 
     this.zzz = aa;
     this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
     console.log(this.pdfUrl);
     this.pdfUrl = this.zzz.file;
     // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";
 
     this.modalService.open(pdftesting).result.then((result) => {
 
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.m = this.msg;
       console.log(this.m)
 
     });
   }
 }
 
 
 ViewDocx(aa, pdftesting) {
   if (aa.pdfFile != null) {
     //this.ViewDoc(aa)
   }
   else {
 
  this.zzz = aa;
     this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
     console.log(this.pdfUrl);
     this.pdfUrl = this.zzz.file;
     // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";
 
     this.modalService.open(pdftesting).result.then((result) => {
 
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.m = this.msg;
       console.log(this.m)
 
     });
   }
 }
 
 
 ViewExcel(aa, pdftesting) {
  
   if (aa.pdfFile != null) {
     //this.ViewDoc(aa)
   }
   else {
 
     this.zzz = aa;
     this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
     console.log(this.pdfUrl);
     this.pdfUrl = this.zzz.file;
     // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";
 
     this.modalService.open(pdftesting).result.then((result) => {
 
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.m = this.msg;
       console.log(this.m)
 
     });
   }
 }
 
 
 ViewDocument(aa, pdftesting) {
   if (aa.pdfFile != null) {
     //this.ViewDoc(aa)
   }
   else {
 
    this.zzz = aa;
     this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
     console.log(this.pdfUrl);
     this.pdfUrl = this.zzz.file;
     // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";
 
     this.modalService.open(pdftesting).result.then((result) => {
 
       this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
       this.m = this.msg;
       console.log(this.m)
 
     });
   }
 }




  GetFriendDetails() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
    
      // console.log(this.frndslist);

    });
  }




 
  GetALLClientsDetails() {
     this.clientapproval.getclientdetails().subscribe((respose: any) => {
       this.clientdatalist = respose;
       this.clientdetails = true;
       this.allclientsharedvalue =false;
     });
   }
   GetsharedClientsDetails(){
    this.clientapproval.getclientdetails().subscribe((respose: any) => {
      this.clientdatalist = respose;
      this.clientdetails = false;
      this.allclientsharedvalue =true;
    });
   }




}
