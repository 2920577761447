import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from '../Shared/MenuMaster/menu-master.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { MessageService } from '../Shared/MessageService';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.css']
})
export class UserSidebarComponent implements OnInit {
  uid: string;
  roleid: string;
  isLoggedIn: boolean;
  sidebaropen: boolean=false;
  mobileview: boolean;
  Menus: any;
  MenuList: any;
  MasterList: any;
  subscription: Subscription;
  message: any;
  downpath: any;
  seleteddownload: boolean;
  BsafeMenus: any;

  constructor(private spinner:NgxSpinnerService,private menuservice:MenuMasterService,private router:Router,
    private messageService:MessageService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message.text == "true") {
        this.isLoggedIn = true;
      }
    });
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.roleid = localStorage.getItem("role");
      this.uid = localStorage.getItem("userId");
      this.isLoggedIn = true;
      this.GetAllMenus();
     }
   }

  ngOnInit(): void {
    this.openNav();
    this.closeNav();
    this.GetBsafeMenus();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  GetBsafeMenus() {
    this.spinner.show();
    this.menuservice.getbsafemenus().subscribe((response: any) => {
      this.BsafeMenus = response;
          this.spinner.hide();
    })
  }
  DownloadFile() {
    this.menuservice.downloadFile().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      });
  
  }
  DownloadFilePortable() {
    this.menuservice.downloadFileportable().subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        this.seleteddownload = true;
        window.open(this.downpath);
      }
      });

    }
  openNav() {
    this.isMobileMenu();
    this.sidebaropen=false;
    document.getElementById('mySidenav').style.width = '250px';
    document.getElementById('main').style.marginLeft = '250px';
  }
  closeNav() {
    this.isMobileMenu();
    this.sidebaropen=true;
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('main').style.marginLeft = '0';
  }
  Logout() {
    this.isLoggedIn = false;
    localStorage.setItem("IsLoggedIn", "false");
    localStorage.removeItem("employee")
    localStorage.removeItem('userToken');
    this.spinner.show();
    this.menuservice.logoutsession(this.uid).subscribe((data: any) => {
      this.spinner.hide();
    });
    this.router.navigate(['/home']);
  }
  ShowDetails(menu) {
    this.router.navigate(['report/' + menu]);
  }
  GetAllMenus() {
    this.spinner.show();
    this.menuservice.getusermenus(this.roleid).subscribe((response: any) => {
      this.Menus = response;
      this.MenuList = this.Menus.menusList;
      this.MasterList = this.Menus.masterList;
      this.spinner.hide();

    })
  }
}
