import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {
    readonly weburl: string = "https://blocdrive.in";
    readonly usrmurl: string = "https://blocdrive.in/blocdriveapi";
    readonly dmsurl: string = "https://blocdrive.in/blocdriveapi";
   // readonly APIURL: string = "https://capadditum.com/capadditumapi/api/";   
    readonly ANAURL: string = "https://blocdrive.in/blocdriveanalytics/api/";  
    // readonly weburl: string = "http://localhost:4200";
    // readonly usrmurl: string = "http://localhost:44319";
    // readonly dmsurl: string = "http://localhost:45320";
    
    
}