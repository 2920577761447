<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="register">
    <h3 style="text-align: center; color: black">Project Changes List</h3>
    <div>&nbsp;</div>
    <div class="row col-sm-12 prochngbtnmedia">
        <div class="col-sm-2"></div>
        <div class="col-sm-2">
            <button class="btn btn-primary" (click)="addChange(addchange)">
        Add
      </button>
        </div>
        <div class="col-sm-9"></div>
    </div>
    <br />
    <div class="col-md-12 row">
        <div class="col-md-2"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>Project</th>
                            <th>Change Request Name</th>
                            <th>Description</th>
                            <th>Effective From</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                        <tr *ngFor="
                let aa of ChangeList
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              " style="
                font-size: 16px;
                color: rgb(0, 0, 0);
                font-family: DINNextRoundedLTPro;
              ">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ GetProjectName(aa.projectId) }}</td>
                            <td>{{ aa.changeRequestName }}</td>
                            <td>{{ aa.description }}</td>
                            <td>{{ aa.effectiveFrom | date: "short" }}</td>

                            <td>
                                <button class="btn btn-success" (click)="EditChange(aa, updatechange)">
                  Edit
                </button>
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteChange(aa, deletechange)">
                  Delete
                </button>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<br /><br />

<ng-template #addchange let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Change</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
      <option value="">Select Project</option>
      <option
        *ngFor="let project of ProjectList"
        value="{{ project.uniqueProjectId }}"
      >
        {{ project.projectName }}
      </option></select
    ><br />

    <h5 class="modal-title" id="modal-basic-title">Change Request Name:</h5>
    <input
      type="text"
      id="changeRequestName"
      [(ngModel)]="changeRequestName"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Description:</h5>
    <input
      type="text"
      id="description"
      [(ngModel)]="description"
      class="form-control"
      name="dp"
    />

    <h5 class="modal-title" id="modal-basic-title">EffectiveFrom:</h5>
    <input
      type="date"
      id="effectiveFrom"
      [(ngModel)]="effectiveFrom"
      class="form-control"
      name="dp"
    />
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="SaveChange()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Save
    </button>
  </div>
  <br />
</ng-template>
<ng-template #updatechange let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Change</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <select
      class="form-control dropdownsize"
      (change)="onSelectedProject($event.target.value)"
    >
      <option value="">Select Project</option>
      <option
        *ngFor="let project of ProjectList"
        value="{{ project.uniqueProjectId }}"
        [selected]="project.uniqueProjectId == editprojectId"
      >
        {{ project.projectName }}
      </option></select
    ><br />
    <h5 class="modal-title" id="modal-basic-title">Change Request Name:</h5>
    <input
      type="text"
      id="editchangeRequestName"
      [(ngModel)]="editchangeRequestName"
      class="form-control"
      name="dp"
    />
    <h5 class="modal-title" id="modal-basic-title">Description:</h5>
    <input
      type="text"
      id="editdescription"
      [(ngModel)]="editdescription"
      class="form-control"
      name="dp"
    />

    <h5 class="modal-title" id="modal-basic-title">EffectiveFrom:</h5>
    <input
      type="date"
      id="converteddate"
      [(ngModel)]="converteddate"
      class="form-control"
      name="dp"
    />
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-info"
      (click)="UpdateChange()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Update
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deletechange let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Change</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="DeleteChangeRow()"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Ok
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="d('Cross click')"
      style="cursor: pointer"
    >
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div
      class="alert alert-info"
      style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      "
    >
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="closealert()"
      >
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>