<div id="grad1">


    <app-nav-bar></app-nav-bar>

    <!-- MultiStep Form -->
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                    <div class="col-md-12 text-center">
                        switch to
                        <button class="btn btn-secondary" [routerLink]="['/indregister']"
                            style="padding: 10px;">Individual</button>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12 mx-0">
                            <form id="msform" [formGroup]="EnterpriseForm">
                                <!-- progressbar -->
                                <ul id="progressbar">
                                    <li class="active" id="account"><strong>Account</strong></li>
                                    <li id="personal"><strong>Logo</strong></li>
                                    <li id="payment"><strong>Address</strong></li>
                                    <li id="register"><strong>Complete Registration</strong></li>
                                    <li id="confirm"><strong>Finish</strong></li>

                                </ul>
                                <!-- fieldsets -->

                                <fieldset>
                                    <div class="form-card">
                                        <h2 class="fs-title" style="text-align: center;">General Information</h2>
                                        <input formControlName="cname" [(ngModel)]="cname" type="text" name="cname"
                                            (change)="Validateone()" placeholder="Company Name"
                                            [ngClass]="{ 'is-invalid': submitted && f.cname.errors }" />
                                        <div *ngIf="submitted && f.cname.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.cname.errors.required">Company Name is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="noemp" [(ngModel)]="noemp" type="number" name="noemp"
                                            placeholder="Number of Employees" (change)="Validateone()"
                                            [ngClass]="{ 'is-invalid': submitted && f.noemp.errors }" />
                                        <div *ngIf="submitted && f.noemp.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.noemp.errors.required">Number of employees is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="website" [(ngModel)]="website" type="text"
                                            name="website" placeholder="Website of Company" (change)="Validatewebsite()"
                                            [ngClass]="{ 'is-invalid': submitted && f.website.errors }" />
                                        <div *ngIf="submitted && f.website.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.website.errors.required">Company Website is required</div>
                                        </div>
                                        <h5 *ngIf="validwebsite" style="color: red;">Website already exist or Invalid
                                            Website</h5>
                                        <br />
                                        <!-- <select type="password" name="pwd" placeholder="Country" /> -->
                                        <select (change)="onSelectCountry($event.target.value)"
                                            class="form-control dropdownsize">
                                            <option value="">Select Country</option>
                                            <option *ngFor="let do of CountryList" value={{do.countryId}}
                                                [selected]="do.countryId == countryid">
                                                {{do.countryName}}
                                            </option>
                                        </select>
                                        <br />
                                        <input formControlName="fname" [(ngModel)]="fname" type="text" name="fname"
                                            placeholder="First Name" (change)="Validateone()"
                                            [ngClass]="{ 'is-invalid': submitted && f.fname.errors }" />
                                        <div *ngIf="submitted && f.fname.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.fname.errors.required">First Name is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="sname" [(ngModel)]="sname" type="text" name="sname"
                                            placeholder="SurName" (change)="Validateone()"
                                            [ngClass]="{ 'is-invalid': submitted && f.sname.errors }" />
                                        <div *ngIf="submitted && f.sname.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.sname.errors.required">SurName is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="email" [(ngModel)]="email" type="email" name="email"
                                            placeholder="Email/Mobile Number" (change)="Validateone()"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.email.errors.pattern">Enter a valid
                                                email Or Mobile Number</div>
                                        </div>
                                        <h5 *ngIf="validmail" style="color: red;">Email Or Mobile Number already exist!!
                                        </h5>
                                        <br />
                                        <input *ngIf="enableotp" formControlName="otp" [(ngModel)]="otp" type="email"
                                            name="otp" placeholder="OTP" (change)="Validateotp()"
                                            [ngClass]="{ 'is-invalid': submitted && f.otp.errors }" />
                                        <div *ngIf="submitted && f.otp.errors && enableotp" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div style="color: red;" *ngIf="f.otp.errors.required">OTP is required
                                            </div>
                                            <div style="color: red;" *ngIf="f.otp.errors.pattern">Enter a valid
                                                OTP</div>
                                        </div>
                                        <p *ngIf="validotp" style="color: red;">{{displayotpmsg}}</p>
                                    </div>
                                    <input type="button" class="next action-button" name="next" value="Next Step" />
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <h2 class="fs-title" style="text-align: center;">Upload a logo or drop here</h2>
                                        <div class="container fileUploadWrapper">
                                            <div class="col-md-12">
                                                <div class="fileupload" appDragDropFileUpload accept="image/*"
                                                    (click)="fileField.click()" (fileDropped)="onDrop($event)">
                                                    <span class="ddinfo">Choose an object or drag here</span>
                                                </div>
                                                <input type="file" accept="image/*" name="avatars" #fileField
                                                    (change)="onChange($event)">
                                            </div>
                                            <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of
                                                type png or jpg or jpeg</h3>
                                        </div>
                                    </div>
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous" (change)="Validateone()" />
                                    <input type="button" name="next" class="next action-button" value="Next Step" />
                                </fieldset>

                                <fieldset>
                                    <div class="form-card">
                                        <h2 class="fs-title" style="text-align: center;">Personal Information</h2>
                                        <input formControlName="staddress" [(ngModel)]="staddress" type="text"
                                            name="staddress" placeholder="Street Address"
                                            [ngClass]="{ 'is-invalid': submitted && f.staddress.errors }"
                                            (change)="Validatethree()" />
                                        <div *ngIf="submitted && f.staddress.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.staddress.errors.required">Street Address is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="staddress2" [(ngModel)]="staddress2" type="text"
                                            name="staddress2" placeholder="Street Address Line 2"
                                            [ngClass]="{ 'is-invalid': submitted && f.staddress2.errors }"
                                            (change)="Validatethree()" />
                                        <div *ngIf="submitted && f.staddress2.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.staddress2.errors.required">Street Address Line two is
                                                required</div>
                                        </div>
                                        <br />
                                        <input formControlName="city" [(ngModel)]="city" type="text" name="city"
                                            placeholder="City" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                            (change)="Validatethree()" />
                                        <div *ngIf="submitted && f.city.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.city.errors.required">City is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="pincode" [(ngModel)]="pincode" type="text"
                                            name="pincode" placeholder="Pin Code"
                                            [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }"
                                            (change)="Validatethree()" />
                                        <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.pincode.errors.required">Pin Code is required</div>
                                            <div *ngIf="f.pincode.errors.pattern">Enter valid Pin Code</div>

                                        </div>
                                        <br />
                                        <input formControlName="state" [(ngModel)]="state" type="text" name="state"
                                            placeholder="State"
                                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                                            (change)="Validatethree()" />
                                        <div *ngIf="submitted && f.state.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.state.errors.required">State is required</div>
                                        </div>
                                        <br />

                                    </div>
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous" />
                                    <input type="button" name="make_payment" class="next action-button" value="Next" />
                                </fieldset>

                            
                                <fieldset>
                                    <div class="form-card">
                                        <h2 class="fs-title" style="text-align: center;">Complete Registration</h2>
                                        <input formControlName="username" [(ngModel)]="username" type="text"
                                            name="username" placeholder="UserName"
                                            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                            (change)="Validatefour()" (change)="Validateusername()" />
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.username.errors.required">UserName is required</div>
                                        </div>
                                        <h6 *ngIf="validusername" style="color: red;">UserName already taken, Try with
                                            other UserName</h6>
                                        <br />
                                        <input formControlName="password" [(ngModel)]="password" type="password"
                                            name="password" placeholder="Password" (change)="Validatefour()"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                        <br />
                                        <input formControlName="cpassword" [(ngModel)]="cpassword" type="password"
                                            name="cpassword" placeholder="Confirm Password"
                                            [ngClass]="{ 'is-invalid': submitted && f.cpassword.errors }"
                                            (change)="Validatefour()" />
                                        <div *ngIf="submitted && f.cpassword.errors" class="invalid-feedback"
                                            style="margin-top: -20px;">
                                            <div *ngIf="f.cpassword.errors.required">Password must include minimum of 8
                                                and special characters</div>
                                            <div *ngIf="f.cpassword.errors.pattern">Password must include minimum of 8
                                                and special characters</div>

                                        </div>
                                    </div>
                                    <label for="Email " style="  margin-left: 20px;">Identity Verification</label>
                                    <input class="form-control" placeholder="Identity Verification" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton " style="    height: 30px;
                                    margin-top: -30px; margin-left: 130px;">
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous" />
                                    <input type="button" name="make_payment" class="next action-button" value="Confirm"
                                        (click)="RegisterUser()" />

<div *ngIf="verification">

        <!-- Face -->
        <div class="form-card">
            <h2 class="fs-title" style="text-align: center;">FACE VERIFICATION </h2>
            <div class="col-md-12">


                <!-- upload -->
                <div class="col-md-12 row fileupload">
                    <h5 class="fs-title" style="text-align: center;">UPLOAD PANCARD PHOTO
                    </h5>
                </div>
                <div class="col-md-6 row fileupload">
                    <!-- upload -->
                    <input type="file" accept="image/*" name="avatars" #fileField
                        (change)="onChangeforImage($event)">
                    <img [src]="croppedImage" height="200">
                </div>




                <ul style="list-style:none;">
                    <br />
                    <!-- <div class="form-group">
                        <label for="file">Choose your image</label>
                        <input type="file" id="file"
                            (change)="handleFileInput1($event.target.files)">
                    </div> -->

                    <div style="text-align:center">
                        <div>
                            <p>{{seconds}}</p>
                            <webcam [trigger]="triggerObservable"
                                (imageCapture)="handleImage($event)"></webcam>
                            <br />
                            <button id="snapshotBtn" class="btn btn-primary"
                                (click)="triggerSnapshot();">Take A
                                Snapshot</button>
                        </div>
                    </div>
                    <div class="snapshot" *ngIf="webcamImage">
                        <h2>Nice one!</h2>
                        <img [src]="webcamImage.imageAsDataUrl" />
                    </div>


                </ul>
                <br />
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    style="cursor: pointer;" (click)="verifyimage()">
                    Done
                </button>
            </div>
        </div>
        <br>

        <!-- Signature -->
        <div class="form-card" id="signf">
            <h2 class="fs-title" style="text-align: center;">UPLOAD SIGNATURE</h2>
            <div class="container fileUploadWrapper">
                <div class="col-md-12 row">
                    <div class="col-md-4 row"></div>
                    <div class="col-md-6 row fileupload">

                        <img [src]="signatureurl" height="200">
                    </div>
                    <h5 class="fs-title" style="text-align: center;">UPLOAD SIGNED IMAGE
                    </h5>

                    <input type="file" accept="image/*" name="avatars" #fileField
                        (change)="onChangeforSignature($event)">
                </div>
                <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of
                    type png or jpg or jpeg</h3>
            </div>
            <!-- <div class="container fileUploadWrapper">
                <div class="col-md-12 row">
                    <div class="col-md-4 row"></div>
                    <div class="col-md-6 row fileupload">
                        <img [src]="signatureurltwo" height="200">
                    </div>
                    <h5 class="fs-title" style="text-align: center;">UPLOAD SCANNED PAN CARD IMAGE</h5>

                    <input type="file" accept="image/*" name="avatars" #fileField
                    (change)="onChangeforSignatureTwo($event)">
                </div>
                <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of
                    type png or jpg or jpeg</h3>
            </div> -->
            <!-- <div class="col-md-12 row" id="sign">
                <input type="file" accept="image/*" name="avatars" #fileField
                (change)="onChangeforSignatureTwo($event)">
                <img [src]="signatureurltwo" height="200">
                <ng-signature-pad id="signaturepadtag" [width]="500" [height]="400" doneButtonText="Show Signature"
                    format="base64" (done)="showImage($event)">
                </ng-signature-pad>
                <img [src]="signatureImage" *ngIf="signatureImage" />
            </div> -->
            <br />
            <button type="button" class="btn btn-primary" data-dismiss="modal"
                style="cursor: pointer;" (click)="verifysignature()">
                Done
            </button>
        </div>

        <input type="button" name="previous" class="previous action-button-previous"
            value="Previous" />
        <input type="button" name="next" class="next action-button" value="Next Step" />
    
</div>


                                </fieldset>
                            
                                <fieldset>
                                    <div class="form-card">
                                        <h2 class="fs-title text-center">Success !</h2> <br><br>
                                        <div class="row justify-content-center">
                                            <div class="col-3"> <img
                                                    src="https://img.icons8.com/color/96/000000/ok--v2.png"
                                                    class="fit-image"> </div>
                                        </div> <br><br>
                                        <div class="row justify-content-center">
                                            <div class="col-7 text-center">
                                                <h5>You Have Successfully Signed Up</h5>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="displayalert">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>