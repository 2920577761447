import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileNumberGenerationService {
  readonly url = "https://blocdrive.in/blocdriveapi/api/";

  constructor(private http:HttpClient) { }
  getDepartments(){
    return this.http.get(this.url + 'DepartmentMasters/');
  }
  getLocation(){
    return this.http.get(this.url + 'LocationMasters/');
  }
  getSections(){
    return this.http.get(this.url + 'FP_SectionMaster/');
  }
  getSubTypes(id){
    return this.http.post(this.url + 'FP_SectionMaster/PostGroupBySection', id);
  }
  getDivisions(){
    return this.http.get(this.url + 'DivisionMasters/');
  }
  getTypes(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetTypes');
  }
  getSubjects(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetSubjects' );
  }
  getFormCode(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetFormCode');
  }
  getStatus(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetStatus');
  }
  getYears(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetYears' );
  }
  getMinorsubjects(subject){
    return this.http.post(this.url + 'FilePlanDataProvider/PostMinorSubBySubject', subject);
  }
  getSubtype(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetSubTypes' );
  }
  getMinorSubjects(){
    return this.http.get(this.url + 'FilePlanDataProvider/GetMinorSub' );
  }
  getautoid(){
    return this.http.get(this.url + 'FileNumberGeneration/GetNewAutoNumber' );
  }
  saveFileCode(data){
    return this.http.post(this.url + 'FileNumberGeneration/PostFileNumberGeneration', data);

  }
}
