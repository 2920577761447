<app-nav-bar></app-nav-bar>
<br />
<div class="col-md-12 row">
    <div class="col-md-2">
        <app-composemail></app-composemail>
    </div>
    <div class="col-md-10">
        <aside class="lg-side">

            <div class="inbox-body" *ngFor="let mail of maildata">
                <div class="col-md-10 mailreadmedia" style="    padding: 30px 1px 50px 70px;">

                    <div class="col-lg-12 row">
                        <div class="col-lg-6">
                            <span>
                                <h5>From: {{ mail.fromName }}</h5>
                            </span>
                            <p>
                                To:
                                <!-- <span *ngFor="let mailmem of mail.memlist">
                                    {{ mailmem.useName }},
                                </span> -->
                                <span>
                                    {{ mail.toName }},
                                </span>
                            </p>
                            <p *ngIf="mail.showccusers">
                                Cc:
                                <!-- <span *ngFor="let mailmem of mail.memlist">
                                    {{ mailmem.useName }},
                                </span> -->
                                <span>
                                    {{ mail.toCCName }},
                                </span>
                            </p>
                        </div>

                        <div class="col-lg-6">
                            <span>
                                <p style="float: right; font-size: smaller">
                                    {{ mail.date }}
                                </p>
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-12 row">
                        <div class="col-lg-12">
                            <h5>Sub: {{ mail.subject }}</h5>
                        </div>
                        <div class="col-lg-12">
                            <p style="text-align: justify;line-height: 1.8rem;font-size: 15px;">{{ mail.message }}</p>
                            <div class="" *ngIf="showesign">
                                <div style =  'color: #252222;
                                width: 80%;
                                height:auto;
                                padding: 20px;
                                align-items: center;
                                margin-left: 49px;
                                border-radius: 4px;
                                border: 1px #dceaf5 solid;
                                background-color: #fffdfd;'>
                                <div style='color: #252222;
                                width: 86%;
                                padding: 50px;
                                align-items: center;
                                margin-left: 45px;
                            border-radius: 4px;
                                border: 1px #dceaf5 solid;
                                background-color: #232564;'>
                 <p style="text-align:center;">
                    <!--...... <img src="./assets/img/blocdriverz.png" style='height: 68px; width: 120px;'/> -->
                    <img src="./assets/img/blocdriverz.png" style='height: 54px; width: 118px;'/>

                </p>
                                       <br/> 
                             <p style='font-size:18px;text-align:center;margin-top:0px;font-weight: bold;color:white;'>
                            {{mail.fromName}} sent you a document to review and sign</p>
                            <br/>
                            <p style="text-align:center;">
                            <button style="background-color: orange;" (click)="ViewESignFile(mail)">SIGN FILES</button></p>      
                                  </div>
                
                         <div style='font-size:18px;color:black; padding:45px;'>
                        <p>  Please ESign the Document</p>
                
                        <p>  Thank You, </p>
            
                        <p> {{mail.fromName}} </p>    
                </div>     
                </div>
                            </div>
                            <div class="col-md-3 row" *ngFor="let ret of mail.documents">
                                <div *ngIf="
                        ret.documentType == 'image/jpeg' || ret.documentType == 'png'||  ret.documentType == 'PNG' ||ret.documentType=='jpeg'||ret.documentType=='jpg'||
                        ret.documentType == 'image/png' 
                      " (click)="ViewImageDoc(ret, content)">
                                    <img src="{{ ret.doclink }}" style="cursor: pointer; width: 60%; height: 80px;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>

                                <div *ngIf="
                        ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/pdf.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType == 'audio/mp3' ||
                        ret.documentType == 'mp3'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/video.png" style="cursor: pointer; width: 100%; height: 100%" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx' ||
                        ret.documentType == 'application/octet-stream'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/Excel.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'pptx' ||
                        ret.documentType == 'ppt'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/ppt.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType == 'video/mp4' ||
                        ret.documentType == 'mp4'
                      " (click)="ViewVideo(ret, videocontent)">
                                    <img src="assets/img/nvideo.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'application/octet-stream' ||
                        ret.documentType == 'doc' ||
                        ret.documentType == 'docx'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/word.jpg" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                        ret.documentType == 'text/plain' ||
                        ret.documentType == 'txt'
                      " (click)="ViewDocument(ret, pdftesting)">
                                    <img src="assets/img/textt.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />

                                    <div id="foldername" style="
                          white-space: nowrap;
                          width: auto;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                          font-variant: unicase;
                        " title="{{ ret.documentName }}">
                                        {{ ret.documentName }}
                                    </div>
                                </div>
                                <div *ngIf="
                                ret.documentType == 'application/x-zip-compressed' ||
                                ret.documentType == 'x-zip-compressed' || ret.documentType == 'zip'
                              " (click)="ViewDocument(ret, zipfile)">
                                            <img src="assets/img/zip.jpg" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
        
                                            <div id="foldername" style="
                                  white-space: nowrap;
                                  width: auto;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  cursor: pointer;
                                  font-variant: unicase;
                                " title="{{ ret.documentName }}">
                                                {{ ret.documentName }}
                                            </div>
                                        </div>

                                        <div *ngIf="
                                        ret.documentType == 'sql'
                                      " (click)="ViewDocument(ret, zipfile)">
                                                    <img src="assets/img/sql.png" style="cursor: pointer; width: 24%;height: 75%;padding: 6px 0px 12px 0px;" />
                
                                                    <div id="foldername" style="
                                          white-space: nowrap;
                                          width: auto;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                          cursor: pointer;
                                          font-variant: unicase;
                                        " title="{{ ret.documentName }}">
                                                        {{ ret.documentName }}
                                                    </div>
                                                </div>

                            </div>
                        </div>
                       
                        <!-- <div *ngIf="forwardimages" class="col-md-12">
                            <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                border-radius: 7px;" />
                        </div> -->

                        <br><br>
                        <!--...... <div class="col-lg-12">
                            <br>
                            <p>Thanks & Regards,<br> {{ mail.fromName }}</p>
                        </div> -->
                        <div class="col-lg-12">
                            <br>
                            <p class="line-breaks">{{ mail.signdetail }}</p>
                        </div>
                        <div class="col-md-12 row">
                            <div style="    padding-left: 2%;"></div>
                            <div>

                                <br />

                                <button id="SendBtn" (click)="replay(mail, 'single')" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fa fa-reply pr-2" aria-hidden="true"></i>Reply
                                </button>
                                <br />
                                <br />
                            </div>
                            <div style="
                            padding: 0px 14px 0px 14px;
                        ">

                                <br />
                                <button id="SendBtn" (click)="replay(mail, 'all')" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fa fa-reply-all pr-2" aria-hidden="true"></i>Reply All
                                </button>
                                <br />
                                <br />
                            </div>
                            <div>

                                <br />
                                <!--...... <button id="SendBtn" (click)="Addnewmembers(mail, newmember)" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fas fa-share pr-2" aria-hidden="true"></i>Forward
                                </button> ......-->
                                <button id="SendBtn" pageScroll href="#showforward" (click)="Addnewmembers(mail)" type="button" class="btn btn-outline-secondary btn-rounded waves-effect" style="border: solid 1px">
                                    <i class="fas fa-share pr-2" aria-hidden="true"></i>Forward
                                </button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        </aside>
    </div>

</div>

<div class="col-md-12 row replymailmedia" *ngIf="replayenable">
    <div class="col-md-3"></div>

    <div class="col-md-8" style="background-color: white; padding: 30px 36px 50px 43px;box-shadow: 0px 4px 24px 0px rgb(0 0 0 / 20%);">
        <label> To :</label>
        <label style="margin-left: 705px;text-decoration: underline;" (click)="ccmail()" title="Add Cc recipients"> Cc</label>
        <div class="col-lg-12 row" style="cursor: pointer">
            {{ replytouser }}<br />
        </div>
              <!-- for cc mail users selection -->
              <div id="aboveTagInp" *ngIf="showccmail">
                <h6>Cc</h6>
                <tag-input 
                ng-disabled="all" id="ShareTagInp" 
                [ngModel]="selectedFriend1" name="test" 
                [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved1($event)" 
                (onAdd)="onItemAdded1($event)" [addOnBlur]="true" 
                [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">
                    <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" 
                    [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email1'" 
                    [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.email1 }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input>
            </div>
        <br />
        <label>Message :</label>

        <textarea type="text" class="form-control" name="message" [(ngModel)]="message" style="font-size: 20px;line-height: 1.7rem;" rows="4" cols="50">
    </textarea>
        <br />
        <br />

        <label>Upload Documents</label>

        <input type="file" name="avatars" multiple="true" (change)="onChangeDocumnets($event)" />
        <!-- <p>{{docname1}}</p>
        <p>{{docname2}}</p><p>{{docname3}}</p><p>{{docname4}}</p><p>{{docname5}}</p>
        <p>{{docname6}}</p><p>{{docname7}}</p> <p>{{docname8}}</p><p>{{docname9}}</p>
        <p>{{docname10}}</p> -->
        <div *ngIf="progress">
            <progress id="file" value="50" max="100"> 32% </progress>
        </div>
        <div *ngIf="color" style="color: blue;" >
            <div *ngFor="let file of SelectedFiles">
                {{file.name}}&nbsp;&nbsp;&nbsp;&nbsp;
                <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveFile(file)"></i>

            </div><br>
        </div>

        <br /><br />
        <div style="text-align: center">
            <button type="submit" id="SendBtn" style="text-align: left" class="btn btn-success" (click)="sendmail()">
                SEND
            </button>
        </div>
    </div>
    <div class="col-md-12 row">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-4" *ngIf="dismissalert" >
          <div class="alert alert-info"
            style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
              <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
          </div>
        </div>
      </div>
</div>
<br><br>
<!-- forward mail -->
<div class="col-md-12 row forwardmailmedia" *ngIf="showforward" id="showforward">
    <div class="col-md-3"></div>

    <div class="col-md-7" style="background-color: white; padding: 30px 36px 50px 43px;box-shadow: 0px 4px 24px 0px rgb(0 0 0 / 20%);">
        <h5> Forward Mail</h5>
        <div>
            <label id="labeldiv" style="font-weight:bold" >To</label>
            <label id="labeldiv" style="margin-left: 582px;text-decoration: underline;" (click)="ccmail()" title="Add Cc recipients">Cc</label>
            <button class="btn btn-success " *ngIf='select' style="margin-left: 625px;padding: 9px 10px"
            (click)="Select()">Select</button>
        <div *ngIf='showtwobut' style='margin-left:564px;'>
            <button class="btn btn-success " style="padding: 4px 3px;border-radius: 12px"
                (click)="Selectallmail()">Select All</button>
            <div class="dropdown">
                <button class="btn btn-success "
                    style="padding: 4px 3px;border-radius: 12px;margin-left: 4px; "
                    (click)="Select()">Select by group</button>
                <div class="dropdown-content">
                    <div *ngFor="let link of GroupList">
                        <a (click)="selectgrpname(link)">{{link.groupName}}</a>
                    </div>
                </div>
            </div>
        </div>
       <div *ngIf="notenterprise">
        <button class="btn btn-success " style= "padding: 4px 3px;margin-left: 600px;" (click)="Selectallmail()">Select All</button> 
       </div>

            <div id="aboveTagInp">
                <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                [separatorKeyCodes]="[13,188,186,32]">
                <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                    <ng-template let-item="item" let-index="index">
                        {{ item.email }}
                    </ng-template>
                </tag-input-dropdown>
            </tag-input>
            </div>

                <!-- for cc mail users selection -->
                <div id="aboveTagInp" *ngIf="showccmail">
                    <h6>Cc</h6>
                    <tag-input 
                    ng-disabled="all" id="ShareTagInp" 
                    [ngModel]="selectedFriend1" name="test" 
                    [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved1($event)" 
                    (onAdd)="onItemAdded1($event)" [addOnBlur]="true" 
                    [errorMessages]="errorMessages" [validators]="regexp"
                        [separatorKeyCodes]="[13,188,186,32]">
                        <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                        <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" 
                        [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email1'" 
                        [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                            <ng-template let-item="item" let-index="index">
                                {{ item.email1 }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                </div>

            <textarea type="text" class="form-control" name="message" [(ngModel)]="message" style="font-size: 20px;line-height: 1.7rem;" rows="4" cols="40">
            </textarea>
        </div>
        <br />

        <label id="actionfunctions">
            <p data-toggle="modal" data-target="#choosefromhaspitaccs">
              <img src="./assets/img/ellipse182.png"
style="cursor: pointer;" /> &nbsp; Add Attachment from Blocdrive
            </p>
            <div *ngFor="let fileli of selectedattachments">  
                {{fileli.documentName}}&nbsp;&nbsp;&nbsp;&nbsp;
                <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveInternalFile(fileli)"></i>
            </div><br>
             <!-- <p>{{filename}}</p> <p>{{filenameone}}</p> <p>{{filenametwo}}</p>
            <p>{{filenamethree}}</p><p>{{filenamefour}}</p> -->
          </label><br />
        <br />
        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)">
        <!-- <p>{{docname1}}</p>
        <p>{{docname2}}</p><p>{{docname3}}</p><p>{{docname4}}</p><p>{{docname5}}</p>
        <p>{{docname6}}</p><p>{{docname7}}</p> <p>{{docname8}}</p><p>{{docname9}}</p>
        <p>{{docname10}}</p>   -->
        <div *ngIf="progress">
            <progress id="file" value="50" max="100"> 32% </progress>
        </div>
        <div *ngIf="color" style="color: blue;" >
            <div *ngFor="let file of SelectedFiles">
                {{file.name}}&nbsp;&nbsp;&nbsp;&nbsp;
                <i class="fa fa-window-close" aria-hidden="true" (click)="RemoveFile(file)"></i>
            </div><br>
        </div>
        <div *ngIf="friends">
            <a class="req" (click)="savefrienddetails()"><u style="font-size: 22px;
                color: blue;">Send Friend Request</u></a>
        </div>
        <button type="button" class="btn btn-success" (click)="forword()"  style="color: black; color: white; padding: 5px; margin-left: 10px">
            Forward
        </button>
    </div>
    <div class="col-md-12 row">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-4" *ngIf="dismissalert" >
          <div class="alert alert-info"
            style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
              <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
          </div>
        </div>
      </div>
</div>
<br>
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer">
            {{ imgdisplay.documentName }}
        </h6>
        <h6 style="float: right">Date &nbsp;: &nbsp;{{ date | date: "short" }}</h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.doclink }}" class="img-responsive" alt="" style="height: 300px; width: 400px" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <!-- <a [href]="imgdisplay.doclink" target="blank" style="cursor: pointer">
                    <button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a> -->
                <button class="btn btn-info" (click)="abc(imgdisplay)">
                    <i class="fa fa-download"></i>Download
                </button>
            </div>
        </div>
    </div>
</ng-template>
<!-- PDF VIEW -->
<ng-template #pdftesting let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white">
        <div>&nbsp;</div>
        <h6 style="text-align: center">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh"></ngx-doc-viewer>
        <br /><br />

        <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <!-- <a [href]="pdfUrl">
                    <button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a> -->
                <button class="btn btn-info" (click)="abc(zzz)">
                    <i class="fa fa-download"></i>Download
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- Zipfile -->
<ng-template #zipfile let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white">
        <div>&nbsp;</div>
        <h6 style="text-align: center">{{ zzz.documentName }} <br /></h6>
        <h6 style="float: left">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

<br>
    <div class="modal-body" style="background-color: white">
    
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <!-- <a [href]="pdfUrl">
                    <button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a> -->
                <button class="btn btn-info" (click)="abc(zzz)">
                    <i class="fa fa-download"></i>Download
                </button>
            </div>
        </div>
        <br>
    </div>

</ng-template>
<ng-template #newmember let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
            <b>Forward Message</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
        <div>
            <div id="aboveTagInp">
                <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                [separatorKeyCodes]="[13,188,186,32]">
                <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                    <ng-template let-item="item" let-index="index">
                        {{ item.email }}
                    </ng-template>
                </tag-input-dropdown>
            </tag-input>
            </div>
            <textarea type="text" class="form-control" name="message" [(ngModel)]="message" style="font-size: 20px;line-height: 1.7rem;" rows="4" cols="40">
            </textarea>
        </div>
        <br />
        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)"><p>{{docname1}}</p>
        <p>{{docname2}}</p><p>{{docname3}}</p><p>{{docname4}}</p><p>{{docname5}}</p>
        <p>{{docname6}}</p><p>{{docname7}}</p> <p>{{docname8}}</p><p>{{docname9}}</p>
        <p>{{docname10}}</p>  
    </div>
    <br />
    <div></div>

    <div class="modal-footer">
        <!-- <a class="req" (click)="MailFriend()"><u>Send Friend Request</u></a> -->
        <div *ngIf="friends">
            <a class="req" (click)="savefrienddetails()"><u style="font-size: 22px;
                color: blue;">Send Friend Request</u></a>
        </div>
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="forword()" (click)="d('Cross click')" style="color: black; color: white; padding: 5px; margin-left: 10px">
            Forward
        </button>
    </div>
    <br />
</ng-template>


<ng-template #videocontent let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="width: 883px; background-color: white">
        <div>&nbsp;</div>
        <h6 style="float: left">
            Date &nbsp;: &nbsp;{{ videodate | date: "short" }}
        </h6>

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white">
        <video controls #videoPlayer style="height: 350px; width: 400px">
            <source src="{{ videoplay }}" type="video/mp4" />
        </video>
    </div>
</ng-template>
<ng-template #audiocontent let-d="dismiss" let-c="dismiss">
    <!-- <object type="video/mp4" width="1000" height="800" data="{{pdfUrl}}"></object> -->

    <div class="modal-body" style="width: 883px; background-color: white">
        <audio controls #videoPlayer style="height: 350px; width: 400px">
            <source src="{{ audioplay }}" type="video/mp4" />
        </audio>
    </div>
</ng-template>





<!-- choosefrom main haspit modal -->
<div class="modal modal-angular fade" id="choosefromhaspitmaindivs" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
          id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Attach from Blocdrive</p>
            </div>
            <div class="modal-body" style="margin-top: 0%;">
                <div *ngIf="folderdisaply">
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;     ">
                            <!-- <p id="homediv">Home</p> -->
                            <!-- <p id="namediv">Name</p> -->
                            <table class="table table-hover" id="tabledivision">
                                <tbody class="items">
                                    <tr id="trdiv">
                                        <td style="cursor: pointer;" data-dismiss="modal" data-toggle="modal" data-target="#choosefromhaspitaccs">
                                            <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;margin-top: -1%;" />
                                            <span id="spandivv">My Personal Files</span>
                                        </td>
                                    </tr>
                                </tbody>
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromshared">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Shared with Others</span>
                    </td>
                  </tr>
                </tbody> -->
                                <!-- <tbody class="items">
                  <tr id="trdiv">
                    <td style="cursor: pointer;" data-dismiss="modal"
data-toggle="modal"
                      data-target="#choosefromdeletd">
                      <img src="./assets/img/folder_icon.png"
style="cursor: pointer;margin-top: -1%;" />
                      <span id="spandivv">Deleted Files</span>
                    </td>
                  </tr>
                </tbody> -->
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <button type="submit" id="uploadBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">UPLOAD</button>
                    <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
                </div>

            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>

<!-- choosefromhaspit My personal files modal -->

<div class="modal modal-angular fade" id="choosefromhaspitaccs" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="    display: block;">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
          id="closeinto">&times;</span></button>
            <br />
            <div>
                <p class="modal-title w-100" id="folderText">Upload from Blocdrive</p>
            </div>
            <div class="modal-body">

                <div *ngIf="folderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagenames">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindivs">Home</p>
                        <span (click)="GetAllFolders()" id="Breadcrumbone" style="cursor: pointer;"> &nbsp; <img
              width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp; My Personal Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
              &nbsp; <img width="30px" height="30px"
src="./assets/img/folder.png" />&nbsp;
              <span (click)="GetDetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <!-- <span id="Breadcrumbone" style="cursor: pointer;">
&nbsp; <img src="./assets/img/Vector122.png" />
              &nbsp;{{currentfoldername}}</span> -->
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files</span></p>               -->
                            <table class="table table-hover table-responsive" id="popuptable" style="display: inline-table;">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of allFolders">
                                    <tr id="trdiv">
                                        <td (click)="GetDetails(folderview)" style="cursor: pointer;" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                                <tbody class="items" *ngFor="let ret of allFoldersList">
                                    <tr id="hoverlink" id="{{ret.Id}}" style="height: 60px;">
                                        <td style="cursor: pointer;" *ngIf="ret.DocumentType=='image/jpeg'||ret.DocumentType=='image/png'||ret.DocumentType=='png'
||ret.DocumentType=='jpeg'||ret.DocumentType=='jpg'

||ret.DocumentType=='PNG'" id="foldernamedisplay1">

                                            <div class="col-md-12 row" (click)="selectfile(ret)">
                                                <!-- <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;height: 26px; " />
                                                </div> -->
                                                <div class="col-md-10" id="datadisplay2" style="cursor: pointer;">
                                                    {{ret.DocumentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle">{{ret.date | date: 'MM/dd/yy, hh:mm a'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div *ngIf="subfolderdisaply">
                    <div class="col-md-12 row" id="popupcurrentpagenames">
                        <p id="Breadcrumb" (click)="closecurrentpopup()" data-toggle="modal" data-target="#choosefromhaspitmaindivs">Home</p>
                        <span (click)="GetMypersonalfolders()" id="Breadcrumb" style="cursor: pointer;"> &nbsp; <img
                src="./assets/img/Vector122.png" />&nbsp; My Personal
Files</span>
                        <span *ngFor="let d of foldernamelist" id="Breadcrumb">
              &nbsp; <img src="./assets/img/Vector122.png" />&nbsp;
              <span (click)="GetSubfolderdetails(d)" style="cursor:
pointer;">{{d.folderName}}</span>
                        </span>
                        <span id="Breadcrumbone" style="cursor: pointer;"> &nbsp;
<img width="30px" height="30px" src="./assets/img/folder.png" />
              &nbsp;{{currentfoldername}}</span>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="overflow-y: auto;height: 60vh;">
                            <!-- <p id="allfoldershome">Home > <span
id="mypersonalid">My Personal Files > {{currentfoldername}}</span></p>
-->
                            <table class="table table-hover table-responsive" style="display: inline-table;" *ngIf="allSubFolders" id="popuptable">
                                <thead>
                                    <tr id="theadstyle">
                                        <th style="padding-left:6%">Name</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody class="items" *ngFor="let folderview of
allSubFolders.retData">
                                    <tr id="trdiv">
                                        <td *ngIf="folderview.folderName" style="cursor:
pointer;" (click)="GetSubfolderdetails(folderview)" id="foldernamedisplay1">

                                            <div class="col-md-12 row">
                                                <div class="col-md-1">
                                                    <img width="30px" height="30px" src="./assets/img/folder.png" style="cursor: pointer;" />
                                                </div>
                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;">
                                                    {{folderview.folderName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="!folderview.folderName" id="foldernamedisplay1">
                                            <div class="col-md-12 row">
                                                <input type="checkbox" (click)="onClick(folderview)" style="
                        height: 16px;
                        width: 16px;
                        /* margin-top: 28px; */
                        cursor: pointer;
                      " />
                                                <!-- <div class="col-md-1">
                                                    <img src="./assets/img/uploa_files.png" style="cursor: pointer;height: 26px; " />
                                                </div> -->

                                                <div class="col-md-10" id="datadisplay3" style="cursor: pointer;" (click)="onClick(folderview)">
                                                    {{folderview.documentName}}
                                                </div>
                                            </div>
                                        </td>
                                        <td id="datestyle" *ngIf="folderview.folderName">
                                            {{folderview.ModifiedOn | date: 'MM/dd/yy, hh:mm a'}}</td>
                                        <td id="datestyle" *ngIf="!folderview.folderName">{{folderview.date | date: 'MM/dd/yy, hh:mm a'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="margin-right: 10%;">
                <button type="button" id="closetwo" class="close" data-dismiss="modal" aria-label="Close"></button>
                <button type="submit" id="Uploadbtnn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal" (click)="uploadmypersonalfiles()">UPLOAD</button>
                <button type="submit" id="CancelBtn" class="btn btn-success
btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal">CANCEL</button>
            </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>


