<app-nav-bar>
</app-nav-bar>

<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <div class="login100-pic js-tilt">
                <img src="../assets/img/img-01.png" aTilt alt="IMG">
            </div>

            <form class="login100-form" [formGroup]="loginForm">


                <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                    <input formControlName="email" [(ngModel)]="email" class="input100" type="text" name="email" placeholder="Email/Mobile">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                </div>

                <div class="wrap-input100 validate-input">
                    <input formControlName="password" [(ngModel)]="password" class="input100" type="password" name="pass" placeholder="Password">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="col-md-12 text-right">
                    <a class="txt2 text-warning" href="#/forgotpassword">
                        Forgot Password ?
                    </a>
                </div>
                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" (click)="OnSubmit()">
                        Sign In
                    </button>
                </div>
                <div>&nbsp;</div>
                <div *ngIf="isLoginError">
                    <h5 style="color: red;font-size: 18px;">Incorrect Login Credentials!!</h5>
                </div>

                <br><br>
                <div class="text-center p-t-10">
                    <div class="dropdown show">
                        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          New User ?
                        </a>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item" href="#/indregister">Individual</a>
                            <a class="dropdown-item" href="#/registration">Enterprise</a>
                            <a class="dropdown-item" href="#/apiconsumerreg">API Consumer</a>
                        </div>
                    </div>


                    <br>


                </div>
                <div class="col-lg-12 row">
                    <div class="col-3">
                        &nbsp;
                    </div>
                    <div class="col-9 row">
                        <!-- <div class="col-4 ">

                            <i (click)="signInWithLI()" style="cursor: pointer;" data-toggle="tooltip" title="Sign in with LinkedIn!" class="fa fa-linkedin">  
                        
                      </i>
                        </div> -->
                        <div class="col-6 ">

                            <i (click)="signInWithFB()" style="cursor: pointer;font-size: 25px;" data-toggle="tooltip" title="Sign in with Facebook!" class="fa fa-facebook">  
                          
                        </i>
                        </div>
                        <div class="col-6 ">

                            <i (click)="signInWithGoogle()" style="cursor: pointer;font-size: 25px;" data-toggle="tooltip" title="Sign in with Google!" class="fa fa-google">  
                           
                        </i>
                        </div>
                        <!-- <div class="col-4 ">
                            <i (click)="socialSignIn('google')" style="cursor: pointer;" data-toggle="tooltip" title="Sign in with Google!" class="fa fa-google">  
                           
                        </i>
                        </div> -->
                    </div>
                    <div class="col-3">
                        &nbsp;
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>