import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from 'src/app/Shared/MenuMaster/menu-master.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuMaster } from 'src/app/ViewModels/MenuMaster';
import { RoleService } from 'src/app/Shared/Role/role.service';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-menu-master',
  templateUrl: './menu-master.component.html',
  styleUrls: ['./menu-master.component.css']
})
export class MenuMasterComponent implements OnInit {
  MenuID: string;
  MenuName: string;
  Parent_MenuID: string;
  MenuFileName: string;
  MenuURL: string;
  USE_YN: string;
  MenuList: any;
  closeResult: any;
  menuid: any;
  menuname: any;
  parentmenuid: any;
  userrole: any;
  menufilename: any;
  menuurl: any;
  createdon: any;
  createdby: any;
  menuidentity: any;
  public dismissalert = false;
  notify: string;
  edituserrole: any;
  editmenuidentity: any;
  RoleList: any;
  p: number = 1;
  editmenuid: any;
  editmenuname: any;
  editparentmenuid: any;
  editmenufilename: any;
  editmenuurl: any;
  roleid: any;
  DeleteName: any;
  editimage: any;
  uid: string;
  mobileview: boolean;
  selectrole: any;
  GUID: string;
  constructor(private spinner: NgxSpinnerService,private auditortransactionservice:AuditorTransactionService
    , private menuservice: MenuMasterService,
    private modalService: NgbModal, private roleService: RoleService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };

  ngOnInit(): void {
    this.Menus();
    this.Roles();
    this.Menusdata();
    this.MenuID = "";
    this.MenuName = "";
    this.Parent_MenuID = "";
    this.MenuFileName = "";
    this.MenuURL = "";
    this.USE_YN = "";
  }
  Menus() {
    this.spinner.show();
    this.menuservice.getAllMenus(this.GUID).subscribe((Data: any) => {
      this.MenuList = Data;
      this.spinner.hide();
    });
  }

  Menusdata() {
    this.spinner.show();
    this.menuservice.getAllMenudata(this.roleid).subscribe((Data: any) => {
      this.MenuList = Data;
      this.spinner.hide();
    });
  }
  Roles() {
    this.spinner.show();
    this.roleService.getAllRoles(this.GUID).subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  GetUserRole(id) {
    for (let index = 0; index < this.RoleList.length; index++) {
      if (id == this.RoleList[index].id) {
        return this.RoleList[index].roleName
      }
    }
  }
  addMenu(addmenu) {
    this.modalService.open(addmenu).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  OnselectUserRole(id) {
    this.userrole = id;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveMenu() {
    const inputRequest: MenuMaster = {
      MenuIdentity: 0,
      MenuID: this.menuid,
      MenuName: this.menuname,
      Parent_MenuID: this.parentmenuid,
      User_Role: this.userrole,
      MenuFileName: this.menufilename,
      MenuURL: this.menuurl,
      USE_YN: "Y",
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      Image: null,
      UserId: this.uid,
      GUID: this.GUID
    };
    this.spinner.show();
    this.menuservice.saveMenu(inputRequest).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Menu added successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Create",
          TransactionDesc:"Menu master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.userrole = null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Menus();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        this.userrole = null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Menus();
      }
    });
  }
  EditMenu(updatedata, updatemenu) {
    this.editmenuidentity = updatedata.menuIdentity;
    this.editmenuid = updatedata.menuID;
    this.editmenuname = updatedata.menuName;
    this.editparentmenuid = updatedata.parent_MenuID;
    this.editmenufilename = updatedata.menuFileName;
    this.editmenuurl = updatedata.menuURL;
    this.roleid = updatedata.user_Role;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.edituserrole = updatedata.User_Role;
    this.editimage = updatedata.image;
    this.modalService.open(updatemenu).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  UpdateMenu() {
    if (this.userrole == null || this.userrole == undefined) {
      this.userrole = this.roleid;
    }
    const inputRequest1: MenuMaster = {
      MenuIdentity: this.editmenuidentity,
      MenuID: this.editmenuid,
      MenuName: this.editmenuname,
      Parent_MenuID: this.editparentmenuid,
      User_Role: this.userrole,
      MenuFileName: this.editmenufilename,
      MenuURL: this.editmenuurl,
      USE_YN: "Y",
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      Image: this.editimage,
      UserId: this.uid,
      GUID: this.GUID

    };
    this.spinner.show();
    this.menuservice.UpdateMenu(inputRequest1).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Menu updated successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Update",
          TransactionDesc:"Menu master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.userrole = null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Menus();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        this.userrole = null;
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Menus();
        this.spinner.hide();
      }
    });
  }
  deleteMenu(deletedata, deletemenu) {
    this.DeleteName = deletedata.menuFileName;
    this.menuidentity = deletedata.menuIdentity;
    this.modalService.open(deletemenu).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  DeleteMenuRow() {
    this.spinner.show();
    this.menuservice.deleteMenu(this.menuidentity).subscribe((data: any) => {
      if (data == "success") {
        this.notify = "Menu deleted successfully";
        const audit = {
          TransactionName:"Master",
          TransactionType:"Delete",
          TransactionDesc:"Menu master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Menus();
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!";
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Menus();
        this.spinner.hide();
      }
    });
  }
  closealert() {
    this.dismissalert = false;
  }
}
