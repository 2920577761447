<app-nav-bar></app-nav-bar>
<br /><br /><br /><br /><br />

<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-8 row">
        <div class="col-md-3">
            <button class="collapsible">Getting Started</button>
            <div class="content">
                <ul>
                    <li><a (click)="signup1()">Sign up</a></li>
                    <li><a (click)="login1()">Login</a></li>
                </ul>
            </div>
            <button class="collapsible">Upload</button>
            <div class="content">
                <ul>
                    <li><a (click)="uploadfolder1()">Upload Folder</a></li>
                    <li><a (click)="uploadfile1()">Upload Object</a></li>
                </ul>
            </div>
            <button class="collapsible">Share</button>
            <div class="content">
                <ul>
                    <li><a (click)="sharesingle1()">Share Single Folder/Object</a></li>
                    <li><a (click)="sharemulti1()">Share Multiple Folder/Object</a></li>
                </ul>
            </div>
            <button class="collapsible">Move</button>
            <div class="content">
                <ul>
                    <li><a (click)="movesingle1()">Move Single Folder/Object</a></li>
                    <li><a (click)="movemulti1()">Move Multiple Folder/Object</a></li>
                </ul>
            </div>
            <button class="collapsible">Delete</button>
            <div class="content">
                <ul>
                    <li><a (click)="deletesingle1()">Delete Single Folder/Object</a></li>
                    <li><a (click)="deletemulti1()">Delete Multiple Folder/Object</a></li>
                    <li><a (click)="deleterestore1()">Restore Folder/Object</a></li>
                </ul>
            </div>
            <button class="collapsible">View</button>
            <div class="content">
                <ul>
                    <li><a (click)="viewsort1()">Sort Object/Folder</a></li>
                    <li><a (click)="viewgrid1()">List/Grid View</a></li>
                </ul>
            </div>
            <button class="collapsible">Chat</button>
            <div class="content">
                <ul>
                    <li><a (click)="addfriend1()">Add Friend</a></li>
                    <li><a (click)="acceptfriend1()">Accept Friend Request</a></li>
                    <li><a (click)="newchat1()">Start a new chat</a></li>
                </ul>
            </div>
            <button class="collapsible">Download</button>
            <div class="content">
                <ul>
                    <li>
                        <a (click)="downloadsingle1()">Download Single Object/Folder</a>
                    </li>
                    <!-- <li><a (click)="downloadmultiple1()">Download Multiple File/Folder</a></li> -->
                </ul>
            </div>
            <button class="collapsible">Offline blocdrive</button>
            <div class="content">
                <ul>
                    <li><a (click)="Bsafe1()">Use blocdrive Offline</a></li>
                </ul>
            </div>
        </div>
        <div class="col-md-7">
            <!-- Default -->
            <div class="col-md-12" *ngIf="Default">
                <h1 class="col-md-12 text-center">How to use blocdrive ?</h1>
                Get help to navigate blocdrive on the left. Please choose the feature and click.
            </div>
            <!-- signup -->
            <div class="col-md-12" *ngIf="signup">
                <h1 class="col-md-12 text-center">Sign Up</h1>
                We value our users privacy and no user shall worry providing your details. We have made it very simple to our user to signup. Just click on signup with Facebook, Google or Linkedin anything you prefer. Don't have an account in any of those just register
                with us by providing your name, email and password. Click on the below link to sign up.
                <br />
                <br />
                <a [routerLink]="['/indregister']">Sign up here</a>
            </div>
            <!-- login -->
            <div class="col-md-12" *ngIf="login">
                <h1 class="col-md-12 text-center">Login</h1>
                Login to enjoy our services Just in one click one can get into the application. You can either login with: <br />
                <ol>
                    <li>Facebook.</li>
                    <li>Google.</li>
                    <li>Linkedin.</li>
                    <li>
                        If you are a registered user of blocdrive just provide your Email and Password.
                    </li>
                </ol>
                <a [routerLink]="['/login']">Sign in here</a>
            </div>
            <!-- uploadfolder -->
            <div class="col-md-12" *ngIf="uploadfolder">
                <h1 class="col-md-12 text-center">Upload Folder</h1>
                blocdrive provides a service to store your folder in the safe with high security. <br /> To Upload a folder:
                <ol>
                    <li>
                        <a [routerLink]="['/indregister']"><b>Signup here</b></a> if you are a new user.
                    </li>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>Click on <b>Upload Folder</b>.</li>
                    <li>
                        Select your desired folder or to upload in a current directory click Upload Here.
                    </li>
                    <li>Click on <b>Upload Button</b>.</li>
                </ol>
            </div>
            <!-- uploadfile -->
            <div class="col-md-12" *ngIf="uploadfile">
                <h1 class="col-md-12 text-center">Upload Object</h1>
                blocdrive provides a service to store your Objects in the safe with high security. <br /> To Upload a object:
                <ol>
                    <li>
                        <a [routerLink]="['/indregister']"> <b>Signup here</b></a> if you are a new user
                    </li>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>Select your desired folder.</li>
                    <li>Click on <b>Upload Object</b>.</li>
                    <li>
                        You can either drag and drop the Objects or upload by clicking on it .
                    </li>
                    <li>Click on <b>Upload</b>.</li>
                </ol>
            </div>
            <!-- sharesingle -->
            <div class="col-md-12" *ngIf="sharesingle">
                <h1 class="col-md-12 text-center">Share Single Object/Folder</h1>
                blocdrive is not only about storing your folder/object. You can even share your documents with your friends. To Share with your friend you should be connected first.
                <br />To get connected with your friend.
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>Chat</b>.</li>
                    <li>Click on <b>Add Friend</b> button.</li>
                    <li>Type in your friends Email and click on <b>Add</b>.</li>
                </ol>
                Now you can share it with your friend by
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of your desired folder/object.
                    </li>
                    <li>Select <b>Share</b> option from the dropdown.</li>
                    <li>Select your <b>Friend's Name</b>.</li>
                    <li>Click on <b>Share</b>.</li>
                </ol>
            </div>
            <!-- sharemulti -->
            <div class="col-md-12" *ngIf="sharemulti">
                <h1 class="col-md-12 text-center">Share Multi Object/Folder</h1>
                blocdrive is not only about storing your folders/Objects. You can even share your documents with your friends. To Share with your friend you should be connected first. You are also given an option to share multiple documents at a single go.
                <br />To get connected with your friend.
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>Chat</b>.</li>
                    <li>Click on <b>Add Friend</b> button.</li>
                    <li>Type in your friends Email and click on <b>Add</b>.</li>
                </ol>
                Now you can share it with your friend by
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>
                        Select all the <b>Checkbox</b> that you wish to send it to your friend .
                    </li>

                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of one of the selected <b>Folder/Object</b> .
                    </li>
                    <li>Select <b>Share</b> option from the dropdown.</li>
                    <li>Select your <b>Friend's Name</b>.</li>
                    <li>Click on <b>Share</b>.</li>
                </ol>
            </div>

            <!-- movesingle -->
            <div class="col-md-12" *ngIf="movesingle">
                <h1 class="col-md-12 text-center">Move Single Object/Folder</h1>
                As a person arranges his closet, blocdrive user can also organise his Folder/Object as he wishes.

                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of your desired folder/object.
                    </li>
                    <li>Select <b>Move</b> option from the dropdown.</li>
                    <li>Navigate to the desired folder.</li>
                    <li>Click on <b>Move Here</b> button.</li>
                </ol>
            </div>
            <!-- movemulti -->
            <div class="col-md-12" *ngIf="movemulti">
                <h1 class="col-md-12 text-center">Move Multi Object/Folder</h1>
                blocdrive allows user to organise the Objects/folder by selecting multiple Objects or folders.

                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>Select all the Checkbox of the Object/folder you wish to move.</li>

                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of one of the selected folder/object.
                    </li>
                    <li>Select <b>Move</b> option from the dropdown.</li>
                    <li>Select the folder you wish to move the objects/folders to.</li>
                    <li>Click on <b>Move</b> button.</li>
                </ol>
            </div>

            <!-- deletesingle -->
            <div class="col-md-12" *ngIf="deletesingle">
                <h1 class="col-md-12 text-center">Delete Single Object/Folder</h1>
                What if you upload a object by mistake or you no longer need a object/folder? <br /> blocdrive allows you to delete it.

                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of your desired folder/object.
                    </li>
                    <li>Select <b>Delete</b> option from the dropdown.</li>
                    <li><b>Acknowledge</b> to delete it.</li>
                </ol>
            </div>
            <!-- deletemulti -->
            <div class="col-md-12" *ngIf="deletemulti">
                <h1 class="col-md-12 text-center">Delete Multi Object/Folder</h1>
                What if you upload some objects by mistake or you no longer need those Objects/folders? blocdrive allows you to delete them.

                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>
                        Select all the Checkbox of the Object/folder you wish to delete.
                    </li>

                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of one of the selected folder/object.
                    </li>
                    <li>Select <b>Delete</b> option from the dropdown.</li>
                    <li><b>Acknowledge</b> to delete them.</li>
                </ol>
            </div>

            <!-- deleterestore -->
            <div class="col-md-12" *ngIf="deleterestore">
                <h1 class="col-md-12 text-center">Restore deleted Objects/folders</h1>
                Did you by mistake delete a folder or Object? No worries you can restore it.

                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>Deleted Objects</b>.</li>
                    <li>
                        Click on <b>Restore</b> button in front of the Object or folder you wish to restore.
                    </li>
                </ol>
            </div>

            <!-- view sort -->
            <div class="col-md-12" *ngIf="viewsort">
                <h1 class="col-md-12 text-center">Sort your Objects</h1>
                Do you wish to sort your Objects? Here in blocdrive you can either sort by
                <ol>
                    <li>Date</li>
                    <li>Name</li>
                </ol>
                <ol>
                    <li>
                        Click on <b>Name</b> to sort the Objects in either <b>Ascending</b> or
                        <b>Descending</b> order based on <b>Name</b> .
                    </li>
                    <li>
                        Click on <b>Modified</b> to sort the Objects in either
                        <b>Ascending</b> or <b>Descending</b> order based on <b>Date</b> .
                    </li>
                </ol>
            </div>
            <!-- viewgrid -->
            <div class="col-md-12" *ngIf="viewgrid">
                <h1 class="col-md-12 text-center">Change your view</h1>
                If your love to view your Objects/folders in <strong>List</strong> view with all the details in front of them. You can click on
                <i class="fa fa-bars" style="font-weight: bold;"></i> button and view is changed. You can also change your view to <strong>Grid</strong> view by the same method by clicking on
                <i class="fa fa-th" style="font-weight: bold;"></i>.
                <br />
                <br />
            </div>

            <!-- addfriend -->
            <div class="col-md-12" *ngIf="addfriend">
                <h1 class="col-md-12 text-center">Add Friends to your network</h1>
                Need to grow your network? Just
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Select <b>Chat</b> option.</li>
                    <li>Click on <b>Add Friend</b>.</li>
                    <li>Enter your friend Email.</li>
                    <li>Click on <b>Send</b>.</li>
                </ol>
                Your request is sent to the provided Email only if he is a
                <b>member</b> of blocdrive!
            </div>
            <!-- acceptfriend -->
            <div class="col-md-12" *ngIf="acceptfriend">
                <h1 class="col-md-12 text-center">Accept received request</h1>
                Received a friend request? Follow the below given procedure
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Select <b>Chat</b> option.</li>
                    <li>Click on <b>Received Request</b>.</li>
                    <li>Click on <b>Accept Request</b>.</li>
                </ol>
                <br /> You and your friend are connected! Now you can share Objects/folders or chat with them!
            </div>
            <!-- newchat -->
            <div class="col-md-12" *ngIf="newchat">
                <h1 class="col-md-12 text-center">Chat with your friend</h1>
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Select <b>Chat</b> option.</li>
                    <li>
                        Select a friend list to the left or start a new chat by clicking on
                        <b>New Chat</b> .
                    </li>
                    <li>
                        View the old messages in the chat window or send a new message .
                    </li>
                </ol>
                <br /> Your messages have reached your friend with blockchain technology!
            </div>

            <!-- downloadsingle -->
            <div class="col-md-12" *ngIf="downloadsingle">
                <h1 class="col-md-12 text-center">Download Single Object/Folder</h1>
                Do you wish to have a copy of your object or folder in your local system?
                <ol>
                    <li>Click on <b>Safe</b>.</li>
                    <li>Click on <b>My Objects</b>.</li>
                    <li>
                        Click on <i class="fa fa-gear" style="font-weight: bold;"></i> in front of your desired folder/object .
                    </li>
                    <li>Select <b>Download</b> option from the dropdown.</li>
                    <li>Allow pop up for our web application if it doesn't download.</li>
                </ol>
            </div>
            <!-- downloadmultiple -->
            <!-- <div class="col-md-12" *ngIf="downloadmultiple">
                <h1 class="col-md-12 text-center">Download Multi File/Folder</h1>
                Do you wish to have a copy of your file or folder in your local system? And Download multiple files/folders:
                <ol>
                    <li>Click on safe.</li>
                    <li>Click on my files.</li>
                    <li>Select all the checkbox of the file/folder you wish to download.</li>

                    <li>Click on <img src="/assets/img/settings.png" style=" height: 26px;"> in front of one of the selected file/folder.</li>
                    <li>Select Download option from the dropdown.</li>
                    <li>Allow pop up for our web application if it doesn't download.</li>
                </ol>


                That's it your files/folders are downloaded.

            </div> -->
            <!-- offline bsafe -->
            <div class="col-md-12" *ngIf="offlinebsafe">
                <h1 class="col-md-12 text-center">Srichid Drive</h1>
                Do you wish to have a copy of your object or folder in your blocdrive Drive by auto sync from your local system?
                <ol>

                    <li>Click on <b>Srichid Drive</b></li>
                    <li><b>Allow pop up</b> for our web application if it doesn't download</li>

                    <li><b>Run</b> the downloaded object</li>
                    <li>Provide your credentials</li>
                    <li><b>Double</b> click on the Logo</li>
                    <li><b>Default folder</b> will be created in the tree view of object explorer</li>
                    <li> <b>Drag and drop</b> the object or folder that you wish to sync in the default folder</li>
                </ol>
                That's it. Your object/folder is synced to your blocdrive Drive!

            </div>
        </div>
    </div>
    <div class="col-md-2">&nbsp;</div>
</div>
<app-support-chat></app-support-chat>