<app-nav-bar></app-nav-bar>
<!-- Start Masthead -->
<!-- <section class="masthead js-masthead-height pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-7" style="margin-left:-10%;margin-top: 15%;">
                <img src="assets/img/3.png" class="img-fluid" alt="Business Looking" />
            </div>
            <div class="col-lg-5 mb-40" style="margin-left: 10%;margin-top: 15%;">
                <div class="masthead__content">
                    <div class="col-lg-12 row">
                        <div class="col-3">
                            &nbsp;
                        </div>
                        <div class="col-9 row">
                         
                            <div class="col-12 text-center">

                                <h2>Sign Up</h2>
                            </div>
                           
                        </div>
                        <div class="col-3">
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div class="masthead__form" style="border-style: none;background: transparent;box-shadow: none;margin-top: -15%;">
                    <form name="register" action="" #OtpForm="ngForm" (ngSubmit)="OnSubmit(OtpForm)">

                        <hr>
                        <br>
                        <input type="text" class="form-control" id="inputName" name="Username" placeholder="Name" ngModel/>
                            <br>
                        <input type="email" class="form-control" *ngIf="!onlyphone" id="inputEmail" name="Email" placeholder="Email" ngModel/>
                        <input type="number" min="1" step="1" *ngIf="onlyphone" class="form-control" name="phone" placeholder="Phone" ngModel/>
                     
                        <br>




                        <input type="password" class="form-control" id="inputPassword" name="Password" placeholder="Password" ngModel/>

                        <hr>

                        <div *ngIf="otp && !onlyphone">
                            <input type="number" class="form-control" id="otp" name="otp" placeholder="Verify Email" ngModel/>
                        </div>
                        <div *ngIf="otp && onlyphone">
                            <input type="number" class="form-control" id="otp" name="otpp" placeholder="Verify Phone number" ngModel/>
                        </div>
                        <div>
                            Click Sign Up to accept and agree to the <a [routerLink]="['/termsofservice']">Terms and Conditions</a> and <a [routerLink]="['/privacy']">Privacy Policy</a>

                        </div>


                        <div class="masthead__form-action" *ngIf="!otp">
                            <button type="submit" *ngIf="!expired" class="btn btn-rounded btn-orange btn-icon-right">Sign Up<i class="fa fa-long-arrow-right"></i></button>
                        </div>

                    </form>
                    <button type="submit" class="btn btn-rounded btn-orange btn-icon-right" *ngIf="expired" (click)="resendotp(OtpForm)">Resend Otp<i class="fa fa-long-arrow-right"></i></button>
                    <br>
                    <div *ngIf="otp">
                        <button data-target="#basicExample" data-toggle="modal" *ngIf="!expired" class="btn btn-rounded btn-orange btn-icon-right">Sign Up<i class="fa fa-long-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<div class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="margin-top: 20%;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Select Your Profession
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
            </div>
            <div class="modal-body">
                <div>
                    <select class='select-option' style="width:90%;border: none;border-bottom: 1px solid #1e5ee5;margin-top: 10px;text-decoration: none;" (change)='onchangerole($event)'>
            <option value="" selected disabled hidden style="color: #312f5356;">Select your profession</option>
        <option class='option'  *ngFor='let role of roles' [value]="role.id">{{role.roleName}}</option>
        </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="OnSkip(OtpForm)" data-dismiss="modal" style="cursor: pointer;">
        Skip
      </button>
                <button type="button" id="closemove" class="btn btn-warning" (click)="OnSubmitrole(OtpForm)" data-dismiss="modal" style="cursor: pointer;">
        Submit
      </button>
            </div>
        </div>
    </div>
</div> -->
<!-- End Masthead -->

<!-- Start Masthead -->
<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <div class="login100-pic js-tilt">
                <img src="../assets/img/img-01.png" aTilt alt="IMG" />
            </div>
            <div class="login100-form">
                <!-- <span class="login100-form-title"> Sign Up </span> -->
                <div class="col-md-12 row">

                    <div class="col-md-12 text-center">
                        switch to
                        <button class="btn btn-secondary" [routerLink]="['/registration']">Enterprise</button>
                    </div>
                </div>
                <form name="register" action="" #OtpForm="ngForm" (ngSubmit)="OnSubmit(OtpForm)">
                    <hr />

                    <input type="text" class="form-control" id="inputName" name="Username" placeholder="Name" ngModel />
                    <br />
                    <div (click)="changetophone()"  *ngIf="!onlyphone" style="text-align: center;color: #1e5ee5;cursor: pointer;">Sign up
                        with phone number</div>
                    <div (click)="changetophone()" *ngIf="onlyphone" style="text-align: center;color: #1e5ee5;cursor: pointer;">Sign up
                        with Email</div>
                        <br />
                    <input type="email" class="form-control" *ngIf="!onlyphone" id="inputEmail" name="Email"
                        placeholder="Email" ngModel />
                    <input type="number" min="1" step="1" *ngIf="onlyphone" class="form-control" name="phone"
                        placeholder="Phone" ngModel />
                    <br />

                    <input type="password" class="form-control" id="inputPassword" name="Password"
                        placeholder="Password" ngModel />
                        <br />
                   
                        <div>
                            <h5 class="fs-title" style="text-align: center;">Upload Your Picture</h5>
    
                            <input  style="padding: 13px;" type="file" accept="image/*" name="avatars" #fileField (change)="onChange($event)">
    
                        </div>
                        <br />
                    <div *ngIf="otp && !onlyphone">
                        <input type="number" class="form-control" id="otp" name="otp" placeholder="Verify Email"
                            ngModel />
                    </div>
                    <div *ngIf="otp && onlyphone">
                        <input type="number" class="form-control" id="otp" name="otpp" placeholder="Verify Phone number"
                            ngModel />
                    </div>
                    <br />
                    <label for="Email " style="    margin-left: 35px;">I am an Auditor</label>
                    <input class="form-control" placeholder="I am an Auditor" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton " style="    height: 30px;
                    margin-top: -30px; margin-left: 44px;">
                      <!-- <br />
                      <label for="Email " style="    margin-left: 35px;">I am an Auditor</label>
                      <input class="form-control" placeholder="I am an Auditor" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton " style="    height: 30px;
                      margin-top: -30px; margin-left: 44px;"> -->
                    <hr />
                    <div>
                        Click Sign Up to accept and agree to the
                        <a [routerLink]="['/termsofservice']">Terms and Conditions</a> and
                        <a [routerLink]="['/privacy']">Privacy Policy</a>
                    </div>

                    <div class="container-login100-form-btn" *ngIf="!otp">
                        <button type="submit" *ngIf="!expired" class="login100-form-btn">
                            Sign Up
                        </button>
                    </div>
                    <br />
                    <div class="container-login100-form-btn" *ngIf="otp">
                        <button type="submit" *ngIf="!expired" class="login100-form-btn">
                            Sign Up<i class="fa fa-long-arrow-right"></i>
                        </button>
                    </div>
                </form>
                <!-- <button type="submit" class="btn btn-rounded btn-orange btn-icon-right" *ngIf="expired" (click)="resendotp(OtpForm)">
          Resend Otp<i class="fa fa-long-arrow-right"></i>
        </button> -->
        <div class="col-lg-12 row">
            <div class="col-3">
                &nbsp;
            </div>
            <div class="col-9 row">
                <!-- <div class="col-4 ">

                    <i (click)="signInWithLI()" style="cursor: pointer;" data-toggle="tooltip" title="Sign in with LinkedIn!" class="fa fa-linkedin">  
                
              </i>
                </div> -->
                <div class="col-6 ">

                    <i (click)="signInWithFB()" style="cursor: pointer;font-size: 25px;" data-toggle="tooltip" title="Sign in with Facebook!" class="fa fa-facebook">  
                  
                </i>
                </div>
                <div class="col-6 ">

                    <i (click)="signInWithGoogle()" style="cursor: pointer;font-size: 25px;" data-toggle="tooltip" title="Sign in with Google!" class="fa fa-google">  
                   
                </i>
                </div>
                <!-- <div class="col-4 ">
                    <i (click)="socialSignIn('google')" style="cursor: pointer;" data-toggle="tooltip" title="Sign in with Google!" class="fa fa-google">  
                   
                </i>
                </div> -->
            </div>
            <div class="col-3">
                &nbsp;
            </div>
        </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="margin-top: 20%">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Select Your Profession
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div>
                    <select class="select-option" style="
              width: 90%;
              border: none;
              border-bottom: 1px solid #1e5ee5;
              margin-top: 10px;
              text-decoration: none;
            " (change)="onchangerole($event)">
            <option value="" selected disabled hidden style="color: #312f5356">
              Select your profession
            </option>
            <option class="option" *ngFor="let role of roles" [value]="role.id">
              {{ role.roleName }}
            </option>
          </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="OnSkip(OtpForm)" data-dismiss="modal" style="cursor: pointer; color: black">
          Skip
        </button>
                <button type="button" id="closemove" class="btn btn-warning" (click)="OnSubmitrole(OtpForm)" data-dismiss="modal" style="cursor: pointer">
          Submit
        </button>
            </div>
        </div>
    </div>
</div> -->
<!-- End Masthead -->