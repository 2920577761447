import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UploadData } from 'src/app/ViewModels/uploaddata';
import { InwardModel } from 'src/app/ViewModels/Inward';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { Friend } from 'src/app/ViewModels/Friend';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';
import { AddNewFolder } from 'src/app/ViewModels/NewFolder';
import { DownloadFolderVM } from 'src/app/ViewModels/DownloadFolderVM';

@Component({
  selector: 'app-inward',
  templateUrl: './inward.component.html',
  styleUrls: ['./inward.component.css']
})
export class InwardComponent implements OnInit {
  inwarddetails = new FormGroup({
    FromDate: new FormControl(),
    ToDate: new FormControl(),
    fileusername: new FormControl(),
    exhibitfilename: new FormControl(),
    inwarddate: new FormControl(),
    circulation: new FormControl(),
    page: new FormControl(),
    note: new FormControl(),
    InwardNumber: new FormControl(),
    foldername: new FormControl(),
  });
  FilePlanList: any;
  DocumentTypeList: any;
  InwardTypeList: any;
  WorkflowStageList: any;
  ExhibitsFilesList: any;
  FolderList: any;
  sensitivitylist: any;
  selectedworkflowstage: any;
  selectedfilename: any;
  selectedfolder: any;
  selecteddocumenttype: any;
  selectedInwardType: any;
  inward: any
  InwardNumber: any
  mailId: any
  foldername: any;
  folderid: any;
  fileusername: any;
  datetime: Date;
  selectedExhibits: any;
  exhibitfilename: any;
  files: any;
  DateTime: Date;
  page: any;
  note: any;
  inwarddate: any;
  circulation: any;
  selectedpriority: any;
  uid: string;
  public dismissalert = false;
  notify: string
  submitted: any = false;
  closeResult: string;
  filedescription: any;
  fileurl: any;
  DocumentType: any;
  username: string;
  CirculationGroup: any;
  circulationdata: any;
  fileuploaded: boolean=false;
  roleid: string;
  mobileview: boolean;
  InwardFolderId: string;
  InwardFileId: any;
  InwardTypeFile: string;
  AllFolders: any;
  AllFiles: any;
  subf: any;
  UserRights: any;
  EnableShare: boolean=false;
  EnableDelete: boolean=false;
  EnableEdit: boolean=false;
  EnableInward: boolean=false;
  statuschange: string;
  ShareOnlyDoc: any;
  BlockId: any;
  FolderID: any;
  ShareOnlyDocName: any;
  m: any;
  msg: any;
  shareitem: boolean;
  deleteitem: boolean;
  Friends: any;
  frndslist: any;
  accid: any;
  FileFoldID: any;
  allSubFolders: any;
  fid: any;
  CurrentFiles: any;
  checkenable: boolean;
  multi: boolean;
  sfiles: number;
  sfolder: number;
  selectedSelected: any=[];
  deletemulticontent: string;
  foladdednotify: boolean;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  Res: any;
  createdmsg: string;
  pid: any;
  foldercreated: boolean;
  n: number;
  num: any;
  selectedFriendcancel: any;
  selectedFriend: any=[];
  shareUniqueID: any;
  userid: string;
  frndrqst: boolean;
  sendFID: any;
  sendBID: any;
  shareSubFolderFolderType: any;
  shareSubFolderFolderName: any;
  shareSubFolderParentID: any;
  shareSubFolderFolderId: any;
  pathdt: any;
  EnableDownload: boolean=false;
  details: any;
  updatemsg: any;
  u: any;
  downpath: any;
  shareSubFolderId: any;
  InwardPreviewData: any;
  GUID: string;
  get c() { return this.inwarddetails.controls; }

  constructor(private spinner: NgxSpinnerService, private inwardservice: InwardServiceService, private datepipe: DatePipe,
    private formBuilder: FormBuilder, private httpService: HttpClient, private modalService: NgbModal,
    private router:Router,private userservice:UserDetailsService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
     this.InwardFolderId= localStorage.getItem("InwardFolderId")
     this.InwardFileId=localStorage.getItem("InwardFileId")
     this.InwardTypeFile =localStorage.getItem("InwardTypeFile")
     this.GUID=localStorage.getItem("GUID");

     setTimeout(() => {
      if(this.InwardTypeFile=="true"){
        this.Getfiles((+this.InwardFileId));
       }
       else{
         this.GetFoldersandFiles(this.InwardFolderId);
       }
     }, 2000);
  
    }
    this.isMobileMenu();

    this.inwarddetails = formBuilder.group({
      fileusername: ['', Validators.required],
      exhibitfilename: ['', Validators.required],
      inwarddate: ['', Validators.required],
      circulation: ['', Validators.required],
      page: ['', Validators.required],
      note: ['', Validators.required],
      InwardNumber: "",
      foldername: ""
    })
  }

  ngOnInit(): void {
    this.FilePlan();
    this.DocumentTypes();
    this.InwardType();
    this.WorkflowStage();
    this.Priority();
    this.GetInwardNumber();
    this.datetime = new Date();
    this.getuserrights();
    if(localStorage.getItem("InwardPreviewData")!=null || localStorage.getItem("InwardPreviewData")!=undefined){
      var data = JSON.parse(localStorage.getItem("InwardPreviewData"))
      this.InwardPreviewData = data
      this.AssignData();

    }
  }
  mob = false;

  AssignData() {

    this.InwardNumber = this.InwardPreviewData.Id,
      this.selectedfilename = this.InwardPreviewData.File,
      this.foldername = this.InwardPreviewData.Folderid,
      this.fileusername = this.InwardPreviewData.ReceivedFrom,
      this.exhibitfilename = this.InwardPreviewData.Subject,
      this.InwardNumber = this.InwardPreviewData.InwardId,
      this.selectedpriority = this.InwardPreviewData.Sensitivity,
      this.page = this.InwardPreviewData.Pages,
      this.selectedInwardType = this.InwardPreviewData.InwardType,
      this.circulation = this.InwardPreviewData.CirculationGroup,
      this.selecteddocumenttype = this.InwardPreviewData.DocumentId,
      this.note = this.InwardPreviewData.Note,
      this.selectedworkflowstage = this.InwardPreviewData.Workflowstage,
      this.inwarddate = this.InwardPreviewData.InwardDate,
      this.InwardNumber = this.InwardPreviewData.InwardNumber,
      this.InwardFolderId = this.InwardPreviewData.InwardFolderId,
      this.InwardFileId = +this.InwardPreviewData.InwardFileId
  }
  resize() {
    if (window.innerWidth > 500) {
      this.mob = true;
    }
    else {
      this.mob = false;
    }
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };
  Getfiles(fileid){
    //this.spinner.show();
    this.inwardservice.GetInwardFiles(fileid).subscribe((Data: any) => {
      this.AllFiles = Data;
    //  this.spinner.hide();

    })
  }
  GetFoldersandFiles(folderid){
   // this.spinner.show();
    this.inwardservice.GetInwardFolders(folderid).subscribe((Data: any) => {
      this.AllFolders = Data;
     // this.spinner.hide();

    })
  }
  
  GetSubfolder(data) {
   
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.AllFolders = respose;
      this.subf = this.AllFolders.folderName;
      var Pid = this.AllFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.AllFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.AllFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.AllFolders.parentId));

      // console.log("user", this.allSubFolders);
      //this.route.navigate(['allsubfolders/' + data.folderID]);
    });
  }
  FilePlan() {
   // this.spinner.show();
    this.inwardservice.GetFilePlanList().subscribe((Data: any) => {
      this.FilePlanList = Data;
     // this.spinner.hide();

    })
  }
  DocumentTypes() {
   // this.spinner.show();
    this.inwardservice.GetDocumentTypeList().subscribe((Data: any) => {
      this.DocumentTypeList = Data;
     // this.spinner.hide();

    })
  }
  InwardType() {
    //this.spinner.show();
    this.inwardservice.GetInwardTypeList().subscribe((Data: any) => {
      this.InwardTypeList = Data;
      //this.spinner.hide();

    })
  }
  WorkflowStage() {
    //this.spinner.show();
    this.inwardservice.GetWorkflowStage("IN").subscribe((Data: any) => {
      this.WorkflowStageList = Data;
    //  this.spinner.hide();

    })
  }

  Priority() {
    this.spinner.show();
    this.inwardservice.GetPriority().subscribe((Data: any) => {
      this.sensitivitylist = Data;
      this.spinner.hide();

    })
  }
  OnselectWorkflowStage(id) {
    this.selectedworkflowstage = id
  }

  OnselectFilename(id) {
    this.selectedfilename = id
    this.spinner.show();
    this.inwardservice.GetFolders(this.selectedfilename).subscribe((Data: any) => {
      this.FolderList = Data;
      this.foldername = this.FolderList.folderName
      this.folderid = this.FolderList.folderId
      this.fileusername = this.FolderList.registeredBy
      this.spinner.hide();
    })
    this.spinner.show();
    this.inwardservice.GetExhibitsFiles(this.selectedfilename).subscribe((Data: any) => {
      this.ExhibitsFilesList = Data;
      this.exhibitfilename = this.ExhibitsFilesList.filename
      this.fileurl = this.ExhibitsFilesList.file,
        this.DocumentType = this.ExhibitsFilesList.documentType
      this.spinner.hide();

    })
  }

  OnselectDocumentType(id) {
    this.spinner.show();
    this.selecteddocumenttype = id
    this.inwardservice.GetCirculationGroup(this.selecteddocumenttype).subscribe((Data: any) => {
      this.circulationdata = Data;
      this.circulation = this.circulationdata[0].label
      this.spinner.hide();

    })
  }
  OnselectInwardType(id) {
    this.selectedInwardType = id
  }

  GetInwardNumber() {
    this.spinner.show();
    this.inwardservice.GetInwardNumber().subscribe((Data: any) => {
      this.InwardNumber = Data;
      this.spinner.hide();
    })
  }

  OnselectPriority(id) {
    this.selectedpriority = id
  }
  AddDocuments(adddocuments) {
    this.modalService.open(adddocuments).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  gotofile(filepopup){
    this.modalService.open(filepopup).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  PreviewInward(){
    this.submitted = true;

    if (this.inwarddetails.invalid) {
      return;
    }
    else if(this.fileuploaded==false){
      alert("please upload atleast one file!!")
    }
    else if (this.selectedpriority == null || this.selectedpriority == undefined) {
      alert("Please Select Priority")
    }
    else if (this.selecteddocumenttype == null || this.selecteddocumenttype == undefined) {
      alert("Please Select Document Type")
    }
    else if (this.selectedInwardType == null || this.selectedInwardType == undefined) {
      alert("Please Select Inward Type")
    }

    else if (this.selectedfilename == null || this.selectedfilename == undefined) {
      alert("Please Select File Name")
    }
    else if (this.selectedworkflowstage == null || this.selectedworkflowstage == undefined) {
      alert("Please Select Work Flow Stage")
    }
    else {
      this.page = this.inwarddetails.controls["page"].value
      this.note = this.inwarddetails.controls["note"].value
      this.fileusername = this.inwarddetails.controls["fileusername"].value
      this.exhibitfilename = this.inwarddetails.controls["exhibitfilename"].value
      this.inwarddate = this.inwarddetails.controls["inwarddate"].value
      this.circulation = this.inwarddetails.controls["circulation"].value;
      const alldetails: InwardModel = {
        Id: this.InwardNumber,
        File: this.selectedfilename,
        Folderid: this.folderid,
        ReceivedFrom: this.fileusername,
        Subject: this.exhibitfilename,
        InwardId: this.InwardNumber,
        Sensitivity: this.selectedpriority,
        Pages: this.page,
        InwardType: this.selectedInwardType,
        CirculationGroup: this.circulation,
        DocumentId: this.selecteddocumenttype,
        Note: this.note,
        MailId: 0,
        Workflowstage: this.selectedworkflowstage,
        AttachmentDes: null,
        CircularGroup: null,
        ForWhom: null,
        FromUser: this.uid,
        Group: null,
        InwardDate: this.inwarddate,
        InwardNumber: this.InwardNumber,
        Section: null,
        Status: null,
        SubjectId: null,
        ToUsers: null,
        InwardFolderId:this.InwardFolderId,
        InwardFileId:+this.InwardFileId
      }
      localStorage.setItem("InwardPreviewData",JSON.stringify(alldetails));
      this.router.navigate(['/inwardpreview'])

    }
  }
  SaveInward() {
    this.submitted = true;
    const frmData = new FormData();

    if (this.inwarddetails.invalid) {
      return;
    }
    // else if(this.fileuploaded==false){
    //   alert("please upload atleast one file!!")
    // }
    else if (this.selectedpriority == null || this.selectedpriority == undefined) {
      alert("Please Select Priority")
    }
    else if (this.selecteddocumenttype == null || this.selecteddocumenttype == undefined) {
      alert("Please Select Document Type")
    }
    else if (this.selectedInwardType == null || this.selectedInwardType == undefined) {
      alert("Please Select Inward Type")
    }

    else if (this.selectedfilename == null || this.selectedfilename == undefined) {
      alert("Please Select File Name")
    }
    else if (this.selectedworkflowstage == null || this.selectedworkflowstage == undefined) {
      alert("Please Select Work Flow Stage")
    }
    else {
      this.page = this.inwarddetails.controls["page"].value
      this.note = this.inwarddetails.controls["note"].value
      this.fileusername = this.inwarddetails.controls["fileusername"].value
      this.exhibitfilename = this.inwarddetails.controls["exhibitfilename"].value
      this.inwarddate = this.inwarddetails.controls["inwarddate"].value
      this.circulation = this.inwarddetails.controls["circulation"].value;
      const alldetails: InwardModel = {
        Id: this.InwardNumber,
        File: this.selectedfilename,
        Folderid: this.folderid,
        ReceivedFrom: this.fileusername,
        Subject: this.exhibitfilename,
        InwardId: this.InwardNumber,
        Sensitivity: this.selectedpriority,
        Pages: this.page,
        InwardType: this.selectedInwardType,
        CirculationGroup: this.circulation,
        DocumentId: this.selecteddocumenttype,
        Note: this.note,
        MailId: 0,
        Workflowstage: this.selectedworkflowstage,
        AttachmentDes: null,
        CircularGroup: null,
        ForWhom: null,
        FromUser: this.uid,
        Group: null,
        InwardDate: this.inwarddate,
        InwardNumber: this.InwardNumber,
        Section: null,
        Status: null,
        SubjectId: null,
        ToUsers: null,
        InwardFolderId:this.InwardFolderId,
        InwardFileId:+this.InwardFileId
      }
      this.spinner.show()
      this.inwardservice.SaveInward(alldetails).subscribe(
        (data: any) => {
          this.notify = "File saved and circulated successfully"
          localStorage.removeItem("InwardPreviewData");

          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.InwardNumber=null,
          this.selectedfilename=null
          this.foldername="";
          this.fileusername=""
          this.exhibitfilename=""
          this.selectedpriority=null
          this.circulation=""
          this.page=""
          this.selectedInwardType=null
          this.selecteddocumenttype=null
          this.inwarddate=null
          this.spinner.hide();
          this.GetInwardNumber();
          localStorage.removeItem("employee");
          this.router.navigate(['/workallotment'])
        });
    }
  }
  getuserrights(){
    this.spinner.show();
    this.userservice.getuserRights(this.uid).subscribe((Data: any) => {
      this.UserRights = Data;
      for (let i = 0; i <  this.UserRights.length; i++) {
        if(this.UserRights[i].type==1){
          this.EnableShare=true;
        }     
        else if(this.UserRights[i].type==2){
          this.EnableDelete=true;
        }  
        else if(this.UserRights[i].type==3){
          this.EnableEdit=true;
        }  
        else if(this.UserRights[i].type==4){
          this.EnableInward=true;
        } 
        else if(this.UserRights[i].type==5){
          this.EnableDownload=true;
        }      
      }
      this.spinner.hide();
    })
  }
  SaveDocuments() {
    const data: UploadData = {
      InwardNumber: this.InwardNumber,
      Desrciption: this.filedescription,
      Document: this.fileurl,
      DocumentName: this.exhibitfilename,
      DocumentType: this.DocumentType,
      FilePlan: this.selectedfilename,
      FolderName: this.foldername,
      UserName: this.username
    }
    this.spinner.show();
    this.inwardservice.SaveDocuments(data).subscribe(
      (data: any) => {
        this.notify = "File uploaded successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.InwardNumber=null,
        this.selectedfilename=null
        this.foldername="";
        this.fileusername=""
        this.exhibitfilename=""
        this.spinner.hide();
        this.GetInwardNumber();
        this.OnselectFilename(this.selectedfilename);
        this.fileuploaded = true
        this.GetFoldersandFiles(this.InwardFolderId);

      });
  
  }
  closealert() {
    this.dismissalert = false;
  }
  ViewDoc(file){
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType'); 
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');


    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType); 
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSINWARD");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSINWARD");


    this.router.navigate(['fileview/'])
  }
  ShareoneFile(data, Sharecontent) {
    this.ShareOnlyFile(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  
  ShareOnlyFile(data: any) {
    this.statuschange = "";
    this.statuschange = data;
    this.ShareOnlyDoc = data.file;
    this.BlockId = data.blockId;
    this.FolderID = data.folderId;
    this.ShareOnlyDocName = data.documentName;

  }
  
  GetFriendDetails() {
    this.userservice.friendlist(this.uid).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      // console.log(this.frndslist);
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }

  Share() {
    this.shareitem = true;
  }
  CancelShare() {
    this.shareitem = false;
  }
  Deleteitems() {
    this.deleteitem = true;
  }
  CancelDelete() {
    this.deleteitem = false;
  }
  Delete(data) {
    var res;
    if (data.folderName == null) {
      res = confirm("Do You Want to Delete " + data.documentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.folderName + "!!!");
    }
    if (res == true) {
      if (data.hash) {
        var info = {
          Id: data.id,
          FolderID: data.folderId,
          FolderName: data.folderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.folderId,
          RoleId: this.roleid,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    this.userservice.deletefolder(info).subscribe((data: any) => {
      if(data!=null){
        alert("Deleted Successfully")
      }
    });
  }
  GetDetails(id) {
    this.spinner.show();
    this.getpath(id);
    this.FileFoldID = id;
    this.userservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.fileName;
      this.fid = this.allSubFolders.parentId;
      // console.log("user", this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
    this.spinner.hide();

  }
  getpath(id) {
    this.userservice.getpath(id).subscribe((respose: any) => {
      this.pathdt = respose;
    });
  }
  SharewithMoreFriends(Sharecontent) {
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  DeleteMorecoin(Deletecontent) {
    this.modalService.open(Deletecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  toggleSelect(data) {
    this.CurrentFiles = data;
    this.checkenable = true;
    this.multi = true;
    this.sfiles = 0;
    this.sfolder = 0;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedSelected.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.checkenable = false;
        this.multi = false;
      }
    }

    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].folderName != null) {
        this.sfolder = this.sfolder + 1;
      }
      else {
        this.sfiles = this.sfiles + 1;
      }
    }


    if (this.sfolder == 0) {
      this.deletemulticontent = this.sfiles + " Files"
    }
    else if (this.sfiles == 0) {
      this.deletemulticontent = this.sfolder + " Folders"
    }
    else {
      this.deletemulticontent = this.sfolder + " Folders " + this.sfiles + " Files"
    }
  }
  Deleteselected() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Deleting selected items";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Deleting selected items";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const inputRequest: DeleteMulit = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      UserId: this.uid,
      ModifiedOn: null,
      GUID: this.GUID

    }
    // console.log(inputRequest);
    this.userservice.DeleteMore(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Deleted selected items successfully!"
        alert("Deleted selected items successfully!")
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        this.GetFoldersandFiles(this.InwardFolderId);

       

        this.selectedSelected = [];
        this.multi = false;
        this.checkenable = false;
        setTimeout(() => {
        }, 500);
        //this.GetAllFolders();
        //this.spinner.hide();
      }
      else {
        this.checkenable = false;
        this.multi = false;
        this.notify = "Something went wrong"
        setTimeout(() => this.foladdednotify = true, 100);
        setTimeout(function () {
          this.foladdednotify = false;
        }.bind(this), 3000);

        this.GetFoldersandFiles(this.InwardFolderId);
      }
    });

  }
  getPercent(per) {
    return 10;
  }
  shareallallcancel() {
    if (this.selectedFriendcancel == null || this.selectedFriendcancel == undefined || this.selectedFriendcancel == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: 0,
        ToUsers: null,
        USerId: this.uid,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.selectedFriendcancel,
        ModifiedOn: null,
        GUID: this.GUID
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Withdrawing Shared Items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Withdrawing Shared Items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.withdraw(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Withdraw of shared files successful!"
            alert("Withdraw of shared files successful!")
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            this.GetFoldersandFiles(this.InwardFolderId);

            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!Please Try Again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }
  shareallall() {
    if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
        ToUsers: this.selectedFriend,
        USerId: this.uid,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: null,
        ModifiedOn: null,
        GUID: this.GUID

      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.shareall(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            alert("Shared items successfully");
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
            this.GetFoldersandFiles(this.InwardFolderId);

          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }

  ShareFileMembers1(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        if (this.frndslist[index].userId == this.uid) {
          this.selectedFriendcancel = this.frndslist[index].friendId;
          break;
        }
        this.selectedFriendcancel = this.frndslist[index].userId;
        break;
      }
    }

  }
  cancelshareall = false;
  shareitem1 = false;
  cancelshare() {
    if (this.cancelshareall == true) {
      this.cancelshareall = false;
      this.shareitem1 = false;

    }
    else {
      this.cancelshareall = true;
      this.shareitem1 = true;

    }
  }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID:this.GUID

    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
    });
  }
  
  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    }
    else {
      this.frndrqst = true;
    }
  }
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  SharOnlyFileToUsers() {
    this.sendFID = this.FolderID;
    this.sendBID = this.BlockId;
    const inputRequest: ShareOnlyFileVM = {
      FolderID: this.FolderID,
      File: this.ShareOnlyDoc,
      BlockID: this.BlockId,
      FromUserId: this.uid,
      ToUsers: this.selectedFriend,
      DocumentName: this.ShareOnlyDocName,
      UserId: this.uid,
      AccessType: null,
      UserName: this.username,
      BodyContent: null,
      UserEmail: null,
      CreatedOn: null,
      FromUserGUID:this.GUID
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
      }
    }, 3000);
    this.userservice.shareOnlyFile(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared item successfully within blockchain!";
          alert("Shared item successfully within blockchain!")
          this.pid = data;

          this.folderCreating = false;
          this.foldercreated = true;
          this.selectedFriend = [];
          clearInterval(this.num);
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.GetFoldersandFiles(this.InwardFolderId);

          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedFriend = [];
        }
      });
  }
  ShareExsistingSubFolderToUsers() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest: FolderShareToUers = {
      Id: "0",
      ToUsers: this.selectedFriend,
      BodyContent: "",
      FolderType: this.shareSubFolderFolderType,
      FolderName: this.shareSubFolderFolderName,
      Code: "SubFolder",
      ParentID: this.shareSubFolderParentID,
      FolderID: this.shareSubFolderFolderId,
      UserId: this.uid,
      AccessType: null,
      UserName: this.username,
      UserEmail: "",
      CreatedOn: "",
      GUID: this.GUID

    }
    //this.spinner.show();

    // console.log(inputRequest);
    this.userservice.shareExsistingSubFolder(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {

          this.n = 100;
          this.createdmsg = "Shared items successfully"
          alert("Shared items successfully");
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);

          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.GetFoldersandFiles(this.InwardFolderId);


          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);

          this.selectedFriend = [];
          this.accid = "";
        }
      });
  }
  ShareExsistingMultiFolderToUsers() {

    const inputRequest: MultiFolderShareVM = {
      Id: this.shareUniqueID,
      ToUsers: this.selectedFriend,
      USerId: this.uid,
      Code: "MainFolder",
      SelectedFolders: this.selectedSelected,
      AccessType: null,
      BodyContent: null,
      UserName: this.username,
      ModifiedOn: null,
      GUID: this.GUID

    }
    //this.spinner.show();
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Sharing selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    this.userservice.addMultigMainFolderAndShare(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared items successfully!"
          alert("Shared items successfully");
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);
          // this.notify = "Folder Shared Succesfully";
          // setTimeout(() => this.dismissalert = true, 100);
          // setTimeout(function () {
          //   this.dismissalert = false;
          // }.bind(this), 3000);
          // this.spinner.hide();
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedSelected = [];
          this.multi = false;
          this.GetFoldersandFiles(this.InwardFolderId);


        }
        else {
          //alert("Something went wrong!!please try again");
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        this.spinner.hide();
      });
  }
  
  UpdateSubFolder(folderview, editfolder) {
    this.details = folderview;
    this.updatemsg = folderview.folderName;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u = this.updatemsg;
    });
  }

  UpdateSubFolderName() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    const inputRequest: AddNewFolder = {
      Id: this.details.id,
      FolderID: this.details.folderID,
      RoleId: this.roleid,
      FolderName: this.updatemsg,
      UserId: this.uid,
      Code: "Folder",
      ParentID: this.details.parentID,
      Click: null,
      date: nd,
      GUID: this.GUID

    }
    this.userservice.UpdateFolder(inputRequest).subscribe((data: any) => {
      if (data == true) {
        this.n = 100;
        this.createdmsg = "Updated Successfully";
        alert("Updated Successfully");
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        clearInterval(this.num);
        this.GetFoldersandFiles(this.InwardFolderId);
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        // alert("Updated Successfully");


      }
      else {
        alert("Something went Wrong");
      }
    });
  }
  // Download Folder
  DownloadFolder(data) {
    const inputRequest: DownloadFolderVM = {
      Id: data.id,
      AUID: this.uid,
      FolderId: data.folderID,
      FolderName: data.folderName,
      GUID: this.GUID

    }
    this.userservice.downloadFolder(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
      }
    });

  }
  getSubFolderdata(data) {
    this.shareSubFolderFolderId = data.folderID;
    this.shareSubFolderFolderName = data.folderName;
    this.shareSubFolderFolderType = data.folderType;
    this.shareSubFolderParentID = data.parentID;
    this.shareSubFolderId = data.id;
  }
  ShareFolder(data, Sharecontent) {
    this.getSubFolderdata(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
}
