<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div>&nbsp;</div>
<div class="register">
  <h2 style="text-align: center">Team List</h2>
  <div>&nbsp;</div>
  <div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
      <!-- <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#adTeamlist"
      mdbWavesEffect (click)="addClick()">
      Add
  </button> -->
      <button class="btn btn-primary" (click)="addTeamlist(adTeamlist)">
        Add
      </button>
    </div>
    <div class="col-sm-9"></div>
  </div>
  <br />
  <div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      <div>
        <div style="overflow-x: visible; height: 300px">
          <table class="table table-responsive" style="border-collapse: collapse">
            <tr class="thead-dark" style="font-size: 17px">
              <th>Sl No</th>
              <th>Project</th>
              <th>Team Name</th>
              <th>User</th>
             
              <th>Responsibility</th>
              <th>Created On</th>
             
              <th>Edit</th>
              <th>Delete</th>
            </tr>
            <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
            <tr *ngFor="
                let aa of Teamlist
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              " style="
                font-size: 16px;
                color: rgb(0, 0, 0);
                font-family: DINNextRoundedLTPro;
              ">
              <td>{{ 5 * (p - 1) + (i + 1) }}</td>
              <!-- <td>{{ project(aa.roleId) }}</td> -->
              <td>{{ GetProjectName(aa.project) }}</td>        
              <td>{{ aa.team }}</td>
              <td>{{ aa.employee }}</td>
              <td>{{ aa.responsibility }}</td>
              <td>{{ aa.createdOn | date: "short" }}</td>
              <!-- <td>{{ aa.modifiedBy }}</td>
              <td>{{ aa.modifiedOn | date: "short" }}</td> -->
              <td>
                <button class="btn btn-success" (click)="EditTeamlist(aa, updateTeamlist)">
                  Edit
                </button>
              </td>
              <td>
                <button class="btn btn-danger" (click)="DeleteTeamlist(aa, deleteTeamlist)">
                  Delete
                </button>
              </td>
            </tr>
          </table>
          <br />
          <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
          <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  </div>
</div>

<br /><br />

<ng-template #adTeamlist let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Team</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="col-xs-12">
      <div class="col-xs-4"></div>
      <label class="control-label col-xs-2">Project Name:</label>
      <div class="col-xs-4">
        <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
          <option value="">Select Project</option>
          <option *ngFor="let project of ProjectList" value="{{ project.uniqueProjectId }}">
            {{ project.projectName }}
          </option>
        </select>
      </div>
    </div>
    <br>
    <h5 class="modal-title" id="modal-basic-title">Team Name:</h5>
    <input type="text" id="teamname" [(ngModel)]="teamname" class="form-control" name="dp" /><br>
    <!--...... <div class="col-xs-12">
      <div class="col-xs-4"></div>
      <label class="control-label col-xs-2">Employees:</label>
      <div class="col-xs-4">
        <select class="form-control dropdownsize" (change)="onSelectUser($event.target.value)">
          <option value="">Select User</option>
          <option *ngFor="let user of userList" value="{{ user.userId }}">
            {{ user.userName }}
          </option>
        </select>
      </div>
    </div> ......-->

    <div class="col-xs-12">
      <div class="col-xs-4"></div>
      <label class="control-label col-xs-2">Employees:</label>
      <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="usernames"
      [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
      (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
    </ng-multiselect-dropdown>
    </div>

    <br />
 
    <br />
    <h5 class="modal-title" id="modal-basic-title">Team Responsibility:</h5>
    <input type="text" id="responsibility" [(ngModel)]="responsibility" class="form-control" name="responsibility" />
  </div>
  <br />
  <div class="modal-footer">
    <button  data-dismiss="modal"  type="submit"    class="btn btn-success btn-sm waves-light" mdbWavesEffect 
    (click)="SaveTeamlist()"  (click)="d('Cross click')">Save</button>
<!-- <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
    (click)="UpdateTeamlist()">Update </button> -->
    <!-- <button type="button" class="btn btn-success" (click)="SaveTeamlist()" (click)="d('Cross click')"
      style="cursor: pointer">
      Save
    </button> -->
  </div>
  <br />
</ng-template>
<ng-template #updateTeamlist let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Team</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="col-xs-12">
      <div class="col-xs-4"></div>
      <label class="control-label col-xs-2">Project Name:</label>
      <div class="col-xs-4">
        <!--...... <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
          <option value="">Select Project</option>
          <option *ngFor="let project of ProjectList" value="{{ project.uniqueProjectId }}">
            {{ project.projectName }}
          </option>
        </select> ......-->
        <input type="text" id="proname" [(ngModel)]="proname" class="form-control" name="dp" readonly>
      </div>
    </div>
    <br>
    <h5 class="modal-title" id="modal-basic-title">Team Name:</h5>
    <input type="text" id="teamname" [(ngModel)]="teamname" class="form-control" name="dp" /><br>
    <!--...... <div class="col-xs-12">
      <div class="col-xs-4"></div>
      <label class="control-label col-xs-2">Employees:</label>
      <div class="col-xs-4">
        <select class="form-control dropdownsize" (change)="onSelectUser($event.target.value)">
          <option value="">Select User</option>
          <option *ngFor="let user of userList" value="{{ user.userId }}">
            {{ user.userName }}
          </option>
        </select>
      </div>
    </div> ......-->

    <div class="col-xs-12">
      <div class="col-xs-4"></div>
      <label class="control-label col-xs-2">Employees:</label>
      <ng-multiselect-dropdown [placeholder]="'Select Employees'" [settings]="dropdownSettings" [data]="usernames"
      [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
      (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
    </ng-multiselect-dropdown>
    </div>

    <br />
 
    <br />
    <h5 class="modal-title" id="modal-basic-title">Team Responsibility:</h5>
    <input type="text" id="responsibility" [(ngModel)]="responsibility" class="form-control" name="responsibility" />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="UpdateTeamlist()" (click)="d('Cross click')"
      style="cursor: pointer">
      Update
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deleteTeamlist let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Team</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="DeleteTeamlistRow()" (click)="d('Cross click')"
      style="cursor: pointer">
      Ok
    </button>
    <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="cursor: pointer">
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
      <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b></span>
    </div>
  </div>
</div>