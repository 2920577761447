import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminDashboardService } from 'src/app/Shared/AdminDashboard/admin-dashboard.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.css']
})
export class PDFViewerComponent implements OnInit {
  uid: string;
  file: string;
  SignFile: string;
  DocumentName: string;
  BlockId: string;
  PdfFile: string;
  roleid: string;
  gotfile: string;
  pagecount: number;
  mobileview: boolean;
  pagenum: number;

  constructor(private spinner:NgxSpinnerService,private router:Router,private admindashboardservice:AdminDashboardService) {  
  this.uid = localStorage.getItem("userId");
  this.file = localStorage.getItem("Inwardfile");
  this.SignFile = localStorage.getItem("InwardFileview");
  this.DocumentName = localStorage.getItem("InwardFileName");
  this.BlockId = localStorage.getItem("InwardBlockId");
  this.PdfFile = localStorage.getItem("InwardPdffile");
  this.roleid = localStorage.getItem("role");
  
  this.isMobileMenu();
  if(this.PdfFile=="undefined"){
    this.PdfFile="null"
  }
  if (this.file != null) {
    this.gotfile = this.file
  }
  setTimeout(() => {        
    var pg = document.getElementsByClassName("page");
    this.pagecount = pg.length;
    window.addEventListener('scroll', this.curpagenum.bind(this));
    // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
  }, 1000);
  this.spinner.show();

this.gotopdfView();
}
isMobileMenu() {
  if ($(window).width() > 991) {
     this.mobileview = false;
  }
  else {
    this.mobileview= true;
  }
};

  ngOnInit(): void {
  }
  curpagenum() {
    var pg = document.getElementsByClassName("page");
    var sc = window.pageYOffset;
    for (var i = 0; i < pg.length; i++) {
      var pgc = pg[i].getBoundingClientRect();
      if (sc > pgc.top && sc < pgc.bottom) {
        this.pagenum = i + 1;
        break;
      }
    }
  }
  gotopdfView() {
    this.gotfile = "";
    const  details = {
      File: this.SignFile,
      FileName: this.DocumentName,
      BlockId: this.BlockId,
      AliasUserId: this.uid,
      Pdffile: this.PdfFile,
      
    }
    this.spinner.show();
    this.admindashboardservice.getpdffiletoview(details).subscribe((data: any) => {
      if (data != null) {
        this.gotfile = data.pdfFileName;
        localStorage.setItem('ViewPdffile', data.pdfFileName);
        localStorage.setItem('Filename', data.file);
        localStorage.setItem('originafile', data.fileName);
        localStorage.setItem('blockid', data.blockId);
        this.spinner.hide();
        setTimeout(() => {
          var pg = document.getElementsByClassName("page");
          this.pagecount = pg.length;
          window.addEventListener('scroll', this.curpagenum.bind(this));
          // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
        }, 1000);
      }

      else {
        this.spinner.hide();
          this.router.navigate(['inward/']);
       
      }
    });
  }
  goBack() {
    this.router.navigate(['inward/']);

  }
}
