<app-nav-bar></app-nav-bar>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row" id="four">
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-4">
        <img src="./assets/img/backimage.png" style="cursor: pointer;" title="Back" height="21px" (click)=goBack() />
    </div>
</div>
<div class="col-md-12 row">
    <div class="col-md-8"></div>
    <div class="col-md-2">
        <button class="btn btn-primary" (click)="DownloadForm()">Download</button>
    </div>
    <div class="col-md-2">
        <button class="btn btn-info" (click)="sndpdftoprint()">Print</button>
    </div>
</div>
<div id="print-section">
    <div class="col-md-12">&nbsp;</div>
<h2 style="text-align: center;"> {{WorkPreviewData.formTitle}} DOCUMENT</h2>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div *ngIf="!codestatus" class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Object Number:</label>&nbsp;{{GetFileName(filenumber) }}
    </div>
    <div *ngIf="codestatus" class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Object Number:</label>&nbsp;{{filenumber }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">To Users:</label>&nbsp;<p *ngFor="let item of selecteduserlist">{{item.display }},</p>

    </div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">To Users Within Department:</label>&nbsp;<p *ngFor="let item of selecteddeptuserlist">{{item.display }},</p>

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    
</div>
<div class="col-md-12 row ">&nbsp;</div>
<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Inward Type:</label>&nbsp;{{GetInwardTypeName(wI_InwardTypeId) }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Exhibits:</label>&nbsp;{{ExhibhitFileName }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Received From:</label>&nbsp;{{GetUserName(fromuser) }}
    </div>
</div>
<div class="col-md-12 row ">&nbsp;</div>
<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Pages:</label>&nbsp;{{wI_Pages }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Sensitivity:</label>&nbsp;{{GetPriorityName(wI_Sensitivity) }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel"> Document Type:</label>&nbsp;{{GetDocumentTypeName(wI_DocumentType) }}
    </div>
</div>
<div class="col-md-12">&nbsp;</div>

<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Comment Box:</label>&nbsp;{{attachmentdetails }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Workflow Stage:</label>&nbsp;{{GetWorkflowStageName(selectedworkflowstage) }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Note(s):</label>&nbsp;{{remarks }}
    </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Inward Date:</label>&nbsp;{{wI_Date |date }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row "  *ngIf="!codestatus">
        <label for="Email" id="emaillabel ">Inward Number:</label>&nbsp;{{inwarddataid }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row "  *ngIf="!codestatus">
        <label for="Email " id="emaillabel">Original Reference Number:</label>&nbsp;{{wI_InwardNo }}

    </div>
</div>
<div class="col-md-12">&nbsp;</div>

<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
<div class="col-md-6 row ">
    <label for="Email " id="emaillabel">Inwarded Document:</label>&nbsp;{{GetInwardedDocumentName(InwardedDocumentId) }}
</div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div><div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
</div>
