import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { DepartmentService } from 'src/app/Shared/Department/department.service';
import { MinorDepartmentService } from 'src/app/Shared/MinorDepartment/minor-department.service';

@Component({
  selector: 'app-minor-depeartment',
  templateUrl: './minor-depeartment.component.html',
  styleUrls: ['./minor-depeartment.component.css']
})
export class MinorDepeartmentComponent implements OnInit {
  MajorDepartmentList: any;
  uid: string;
  roleid: string;
  GUID: string;
  MinorDepartmentList: any;
  dismissalert: boolean;
  notify: string;
  departmentname: any;
  departmentcode: any;
  closeResult: string;
  DeleteName: any;
  editdepartmentName: any;
  editdepartmentid: any;
  createdon: any;
  createdby: any;
  editdepartmentcode: any;
  MajorGroupinMinorTblList: any;
  deptsubgroupid: any;
  majordeptid: any;
  parentid: any;
  ParentIdTookFromTBL: string;
p:number=1;
  editmajordepartment: any;
  deleteid: any;
  constructor(private spinner:NgxSpinnerService,private auditortransactionservice:AuditorTransactionService
    ,private departmentService:DepartmentService,
    private modalService: NgbModal,private minordepartmentservice:MinorDepartmentService) { 
     
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
  }

  ngOnInit(): void {
    this.MajorDepartments();
    this.MinorDepartments();
  }
  MajorDepartments() {
    this.spinner.show();
    this.departmentService.getAllDepartments(this.GUID).subscribe((Data: any) => {
      this.MajorDepartmentList = Data;
      this.spinner.hide();
    });
  }
  
  GetMajorGrpinMinorGrpTbl(id){
    this.majordeptid=id;
    this.spinner.show();
    this.minordepartmentservice.getMajorGrpinMinorGrpTbl(id).subscribe((Data: any) => {
      this.MajorGroupinMinorTblList = Data;
      this.spinner.hide();
    });
  }
  MinorDepartments() {
    this.spinner.show();
    this.minordepartmentservice.getAllDepartments(this.GUID).subscribe((Data: any) => {
      this.MinorDepartmentList = Data;
      this.spinner.hide();
    });
  }
  addDepartment(adddepartment) {
    this.modalService.open(adddepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditDepartment(updatedata, updatedepartment) {
    var maindept=updatedata.parentId.split('-')
    this.editmajordepartment=maindept[0]
    this.editdepartmentName = updatedata.majorDepartmentName;
    this.editdepartmentid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.editdepartmentcode=updatedata.code
    this.MajorGroupinMinorTblList = this.MinorDepartmentList;
    this.modalService.open(updatedepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteDepartment(deletedata, adddepartment) {
    this.DeleteName = deletedata.departmentName;
    this.deleteid = deletedata.id;
    this.modalService.open(adddepartment).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  onSelectMinorDepartment(id){
    this.deptsubgroupid=id;
  }
  SaveDepartment() {
    if(this.deptsubgroupid==null || this.deptsubgroupid==undefined){
     this.parentid=this.majordeptid;
     this.ParentIdTookFromTBL="PIDMajorTbl"
    }
    else{
      this.parentid=this.deptsubgroupid
      this.ParentIdTookFromTBL="PIDMinorTbl"

    }
    const inputRequest = {
      Id: 0,
      MinorDepartment: this.departmentname,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      UserId:this.uid,
      GUID:this.GUID,
      Code:this.departmentcode,
      ParentId:this.parentid,
      ParentIdTookFromTBL:this.ParentIdTookFromTBL
    };
    this.spinner.show();
    this.minordepartmentservice
      .saveDepartment(inputRequest)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Sub Department added successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"MinorDepartment master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.MinorDepartments();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.MinorDepartments();
        }
      });
  }

  UpdateDepartment() {
  
    const inputRequest1 = {
      Id: this.editdepartmentid,
      MinorDepartment: this.editdepartmentName,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      UserId:this.uid,
      GUID:this.GUID,
      Code:this.editdepartmentcode,
      ParentIdTookFromTBL:this.ParentIdTookFromTBL

    };
    this.spinner.show();
    this.minordepartmentservice
      .UpdateDepartment(inputRequest1)
      .subscribe((data: any) => {
        if (data != null) {
          this.notify = "Sub Department updated successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Update",
            TransactionDesc:"MinorDepartment master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.MinorDepartments();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.MinorDepartments();
          this.spinner.hide();
        }
      });
  }
  DeleteDepartmentRow() {
    this.spinner.show();
    this.minordepartmentservice
      .deleteDepartment(this.deleteid)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Department deleted successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Delete",
            TransactionDesc:"MinorDepartment master",

            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.MinorDepartments();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.MinorDepartments();
          this.spinner.hide();
        }
      });
  }
 
  closealert(){
    this.dismissalert=false;
  }
}
