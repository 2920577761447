<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<app-nav-bar></app-nav-bar>
<app-user-sidebar *ngIf="(roleid=='2' || roleid=='9')&& mobileview"></app-user-sidebar>
<h2 style="text-align: center;">Reports</h2>
<div>&nbsp;</div>
<div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
        <h5>From Date:</h5>
        <input type="text" id="FromDate" [(ngModel)]="FromDate" class="form-control" name="FromDate" />
    </div>
    <div class="col-sm-1"></div>

    <div class="col-sm-2">
        <h5>To Date:</h5>
        <input type="text" id="ToDate" [(ngModel)]="ToDate" class="form-control" name="ToDate" />
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
        <br />
        <button class="btn btn-primary" style="width: 30vh;" (click)="GetDetails()">
            Enter
        </button>
    </div>
</div>
<br />
<br />
<br />

<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <div>
            <div style="overflow-x: visible; height: 300px;">
                <table *ngIf="menu=='utd'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>Transaction Name</th>
                        <th>Transaction Type</th>
                        <th>Transaction By / User Name</th>
                        <th>User Id</th>
                        <th>User Role</th>
                        <th>Date & Time</th>
                    </tr>
                    <tr *ngFor="let aa of ReportListTransactionDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.transactionName}}</td>
                        <td>{{ aa.transactionType }}</td>
                        <td>{{ aa.transactionBy }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{aa.userRole}}</td>
                        <td>{{ aa.dateandTime | date: "short" }}</td>
                    </tr>
                </table>
                <table *ngIf="menu=='usd'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>User Id</th>
                        <th>User Name</th>
                        <th>Login Time</th>
                        <th>Logout Time</th>

                    </tr>
                    <tr *ngFor="let aa of ReportListSessionDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index" style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{ aa.userName }}</td>
                        <td>{{aa.loginTime| date: "short"}}</td>
                        <td>{{ aa.logoutTime | date: "short" }}</td>
                    </tr>
                </table>
                <table *ngIf="menu=='createditems'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>User Id</th>
                        <th>Created By</th>
                        <th>Role</th>
                        <th>Created Item</th>
                        <th>Created Date</th>

                    </tr>
                    <tr *ngFor="let aa of ReportListCIDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{ aa.transactionBy }}</td>
                        <td>{{ aa.userRole}}</td>
                        <td>{{ aa.transactionName }}</td>
                        <td>{{ aa.dateandTime | date: "short" }}</td>
                    </tr>
                </table>
                <table *ngIf="menu=='editeditems'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>User Id</th>
                        <th>Edited By</th>
                        <th>Role</th>
                        <th>Edited Item</th>
                        <th>Edited Date</th>

                    </tr>
                    <tr *ngFor="let aa of ReportListEIDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{ aa.transactionBy }}</td>
                        <td>{{ aa.userRole}}</td>
                        <td>{{ aa.transactionName }}</td>
                        <td>{{ aa.dateandTime | date: "short" }}</td>
                    </tr>
                </table>
                <table *ngIf="menu=='deleteditems'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>User Id</th>
                        <th>Deleted By</th>
                        <th>Role</th>
                        <th>Deleted Item</th>
                        <th>Deletd Date</th>

                    </tr>
                    <tr *ngFor="let aa of ReportListDIDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{ aa.transactionBy }}</td>
                        <td>{{ aa.userRole}}</td>
                        <td>{{ aa.transactionName }}</td>
                        <td>{{ aa.dateandTime | date: "short" }}</td>
                    </tr>
                </table>
                <table *ngIf="menu=='download'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>User Id</th>
                        <th>downloaded By</th>
                        <th>Role</th>
                        <th>downloaded Item</th>
                        <th>downloaded Date</th>

                    </tr>
                    <tr *ngFor="let aa of ReportListDownloadedDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{ aa.transactionBy }}</td>
                        <td>{{ aa.userRole}}</td>
                        <td>{{ aa.transactionName }}</td>
                        <td>{{ aa.dateandTime | date: "short" }}</td>
                    </tr>
                </table>
                <table *ngIf="menu=='workflow'" class="table table-striped" style="border-collapse: collapse;">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>User Id</th>
                        <th>Workflow Transaction By</th>
                        <th>Role</th>
                        <th>Transaction Item</th>
                        <th>Transaction Date</th>

                    </tr>
                    <tr *ngFor="let aa of ReportListWorkflowDetails | paginate: { itemsPerPage: 10, currentPage: p };
              let i = index
            " style="font-size: 16px; font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{aa.userId}}</td>
                        <td>{{ aa.transactionBy }}</td>
                        <td>{{ aa.userRole}}</td>
                        <td>{{ aa.transactionType }}</td>
                        <td>{{ aa.dateandTime | date: "short" }}</td>
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                <br /><br />
                <br /><br />
            </div>
        </div>
    </div>
</div>
<br /><br />
<div class="col-md-12 row">
    <div class="col-md-7">&nbsp;</div>
    <div class="col-md-4">
        <h6 style="text-align: right">&nbsp;Print Date & Time: {{CurrentDate}} & {{CurrentTime}}</h6>
    </div>
</div>
<br />
<br />
<br />