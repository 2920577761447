import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { MobileViewModel } from 'src/app/ViewModels/MobileViewModel';
import { ChangePassword } from 'src/app/ViewModels/ChangePassword';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.scss']
})
export class ForgotpwdComponent implements OnInit {
beforeotp=true;
afterotp=false;
userName:any;
openotp=false;
otp:any;
p1:any;
p2:any;
  constructor(private userService:UserDetailsService,private router:Router,private spinner:NgxSpinnerService) {
    window.scrollTo(0,0);

   }

  ngOnInit(): void {

    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }

  OnSubmit(){
    if(this.openotp){
      this.otpvalid();
    }
    else{
    localStorage.setItem('loadingpage',"true");
   // this.loading = true;
   if(this.userName == null || this.userName == ""){
     
     alert("Please enter your Email");
   }
  
  else{
    this.spinner.show();

    this.userService.sendotp(this.userName).subscribe((data : any)=>
    {
    if(data=="0"){
      alert("We did not find the provided Email within blocdrive! Please register if you are a new user");

      this.userName = "";
    }
    else if(data == "1"){
      alert("Otp has been sent to your provided Email please enter it below");
      this.openotp=true;
    }
    this.spinner.hide();
 
   }, 
   (err : HttpErrorResponse)=>{
    alert("Something went wrong!!! Please try again later");
    this.userName="";
    this.spinner.hide();
   }
   
)

}
    }
}
otpvalid(){
  const a: MobileViewModel = {
    Id:null,
    OTPExpiryTime:null,
    OTPStartingTime:null,
    Email:this.userName,
    UserId:null,
    OTP:this.otp,
    Attempts:null
  }
  this.userService.otpvalid(a).subscribe((data:any)=>{
    if(data=="Expired"){
      alert("Otp Expired! Please retry");
      location.reload();
    }
    else{
       if(data!=0){
         localStorage.setItem("otpuid",data);
         alert("Otp Validated Reset your password now");
        //  this.router.navigate(['/reset']);
        this.beforeotp=false;
        this.afterotp=true;
       }
       else if(data==0){
         alert("Please enter correct OTP");
         this.otp="";
       }
    }
  })
}
changepassword(){
  if(this.p1 != this.p2){
    alert("Passwords do not match");
  }
  else if(this.p1 == "" || this.p2 == undefined || this.p2 == "" || this.p1 == undefined){
    alert("Please enter your password");
  }
  else if(this.p1 == this.p2){
    var uid=localStorage.getItem("otpuid");
    const inputRequest:ChangePassword={
      
     userId:uid,
      Password:this.p1,
      ConfirmPassword:this.p1,
    }
    this.userService.postchangePasswordAfterLogin(inputRequest).subscribe(
      (data: any) => {
        if (data == null) {
          alert("Your password was successfully changed");
          this.router.navigate(['/login']);
        }else{
          window.location.reload();
        } 
      });
  }
}
}
