import { Component, OnInit } from '@angular/core';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';
import { outward } from 'src/app/ViewModels/outward';
import { OutwardService } from 'src/app/Shared/Outward/outward.service';
import { WorkAllotmentService } from 'src/app/Shared/WorkAllotment/work-allotment.service';
import { FileNumberGenerationService } from 'src/app/Shared/FileNumberGeneration/file-number-generation.service';
import { TransactionService } from 'src/app/Shared/Transaction/transaction.service';
import { ExaminationService } from 'src/app/Shared/ExaminationService/examination.service';
import { examination } from 'src/app/ViewModels/examination';
import { Router } from '@angular/router';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import { TagInputModule } from 'ngx-chips';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadFolderVM } from 'src/app/ViewModels/DownloadFolderVM';
import { DeleteMulit } from 'src/app/ViewModels/DeleteMulit';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { Friend } from 'src/app/ViewModels/Friend';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';
import { AddNewFolder } from 'src/app/ViewModels/NewFolder';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';

@Component({
  selector: 'app-outward',
  templateUrl: './outward.component.html',
  styleUrls: ['./outward.component.css']
})
export class OutwardComponent implements OnInit {
  outwarddetails: string;
  outwarddata: any;
  DocList: any;
  totalItems: any;
  Casedoc: any;
  OutwardNumber: any;
  fileplanlist: any;
  InwardNumber: any;
  outwardRefNumber: any;
  outward: outward;
  doclist: any;
  documenttypelist: any;
  uid: string;
  username: string;
  Role: string;
  example15data: any;
  outwardtypelist: any;
  folderlist: any;
  Inwardno: any;
  sensitivitylist: any;
  dispatchmodelist: any;
  replyawaitedlist: any;
  statuslist: any;
  document: any;
  doctype: any;
  fileid: any;
  folderid: any;
  casedocument: any;
  documentname: any;
  public selecteduserlist: any = [];
  selectedusername: any;
  exhibit: { Exhibits: any; Inwarddata: any; };
  Response: any;
  p:number=1
  ExaminationsList: any = [];
  inwardNumber: any;
  wI_Address: any;
  observation: any;
  exid: any;
  qdslno: string;
  Exhibits: string;
  ExaminationWindowStatus: any;
  filenumber: any;
  selectedexaminationexhibhitid: any;
  addExaminationStatus: boolean;
  notify: string;
  dismissalert: boolean=false;
  Opinions: any;
  showOpinionTable: boolean;
  MessageShow: boolean;
  opinion: string;
  ExaminationDetails: any;
  Message:any
  workflowStages: any;
  date:any
  CurrentStatus:any
  users:any
  errorMessages: string;
  UsersList: any;
  converttostring: any;
  converteddate: any;
  roleid: string;
  mobileview: boolean;
  subf: any;
  AllFolders: any;
  AllFiles: any;
  InwardFolderId: string;
  InwardFileId: string;
  InwardTypeFile: string;
  closeResult: string;
  UserRights: any;
  EnableShare: boolean=false;
  EnableDelete: boolean=false;
  EnableEdit: boolean=false;
  EnableInward: boolean=false;
  statuschange: string;
  ShareOnlyDoc: any;
  BlockId: any;
  FolderID: any;
  ShareOnlyDocName: any;
  m: any;
  msg: any;
  shareitem: boolean;
  deleteitem: boolean;
  Friends: any;
  frndslist: any;
  accid: any;
  FileFoldID: any;
  allSubFolders: any;
  fid: any;
  CurrentFiles: any;
  checkenable: boolean;
  multi: boolean;
  sfiles: number;
  sfolder: number;
  selectedSelected: any=[];
  deletemulticontent: string;
  foladdednotify: boolean;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  Res: any;
  createdmsg: string;
  pid: any;
  foldercreated: boolean;
  n: number;
  num: any;
  selectedFriendcancel: any;
  selectedFriend: any=[];
  shareUniqueID: any;
  userid: string;
  frndrqst: boolean;
  sendFID: any;
  sendBID: any;
  shareSubFolderFolderType: any;
  shareSubFolderFolderName: any;
  shareSubFolderParentID: any;
  shareSubFolderFolderId: any;
  pathdt: any;
  EnableDownload: boolean=false;
  details: any;
  updatemsg: any;
  u: any;
  downpath: any;
  shareSubFolderId: any;
  OutwardPreviewData: any;
  GUID: string;
  constructor(private spinner: NgxSpinnerService, private inwardService: InwardServiceService, private outwardService: OutwardService,
    private workallotmentservice: WorkAllotmentService,private fileNumberGenerationService:FileNumberGenerationService,
    private transcationService:TransactionService,private examinatinservice:ExaminationService,private router:Router,
    private workflowService:WorkFlowService,private datepipe: DatePipe,private userservice:UserDetailsService,
    private inwardservice:InwardServiceService,private modalService: NgbModal,) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.Role = localStorage.getItem("role");
      this.roleid = localStorage.getItem("role");
      this.GUID=localStorage.getItem("GUID");
      this.InwardFolderId= localStorage.getItem("InwardFolderId")
      this.InwardFileId=localStorage.getItem("InwardFileId")
      this.InwardTypeFile =localStorage.getItem("InwardTypeFile")
      if(this.InwardFolderId=="null"){
        this.InwardTypeFile="true"
      }
      if(this.InwardTypeFile=="true"  || this.InwardFolderId=="null"){
       this.Getfiles((+this.InwardFileId));
      }
      else{
        this.GetFoldersandFiles(this.InwardFolderId);
      }
     }
    
    this.isMobileMenu();

    TagInputModule.withDefaults({
      tagInput: {
        placeholder: 'Add UserName ',
        secondaryPlaceholder: 'Add UserName ',
        // add here other default values for tag-input
      },
    });
    this.outwarddetails = localStorage.getItem("employee")
    this.outwarddata = JSON.parse(this.outwarddetails);
    if ((this.outwarddata.one)) {
      this.Getdocuments(this.outwarddata.one.filenumber);
    }
    else {
      this.GetOutWardTransactionWFId();
    }
    var currentdate = new Date();
    this.converttostring = this.datepipe.transform(currentdate, 'yyyy-MM-dd');
    this.date = this.converttostring.toString();
   
  }

  ngOnInit(): void {
    this.GetSensitivity();
    this.GetUsers();
    this.GetFilePlan();
    this.GetDocumentType();
    this.GetOutwardType();
    this.GetDispatchMode();
    this.GetReplyAwaited();
    this.GetWorkflowStages();
    this.getuserrights()
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };
  AssignData() {   
    this.outward=new outward;
    this.users = this.OutwardPreviewData.ToUsers
    this.selecteduserlist = this.OutwardPreviewData.ToUsers
    this.outward.address = this.OutwardPreviewData.WO_Address
    this.outward.attachmentdetails = this.OutwardPreviewData.WO_Attachment
    this.outward.dispatchMode = this.OutwardPreviewData.WO_DispatchMode
    this.outward.docType = this.OutwardPreviewData.WO_DocumentType
    this.Inwardno = this.OutwardPreviewData.WO_InwordNo
    this.OutwardNumber = this.OutwardPreviewData.WO_OutwardNo
    this.outwardRefNumber = this.OutwardPreviewData.WO_OutwardRefNo
    this.outward.outwardtype = this.OutwardPreviewData.WO_OutwardTypeId
    this.outward.pages = this.OutwardPreviewData.WO_Pages
    this.outward.replyAwaited = this.OutwardPreviewData.WO_ReplyAwaited
    this.outward.sensitivity = this.OutwardPreviewData.WO_Sensitivity
   // this.folderlist.divisionName= this.OutwardPreviewData.Subject
    this.outward.status = this.OutwardPreviewData.Statusid
    this.filenumber = this.OutwardPreviewData.FileNumber
    this.folderid = this.OutwardPreviewData.FolderNumber
    this.outward.mailid = this.OutwardPreviewData.Massid
    this.outward.remarks = this.OutwardPreviewData.Remarks
    this.InwardFolderId = this.OutwardPreviewData.InwardFolderId
    this.InwardFileId = this.OutwardPreviewData.InwardFileId
    this.outward.filenumber=this.OutwardPreviewData.FileNumber
    if( this.outward.filenumber!=null ||  this.outward.filenumber!=undefined){
      this.GetFolders();
    }
  }
  ShowMailForm(inbox) {
  }
    // multiple input filed adding and removing 
    onItemRemoved(value: any) {
      this.selecteduserlist.pop(value);
      this.errorMessages = "";

    }
    onItemAdded(value: any) {
      if (value.Email == undefined) {
        this.selectedusername = value.value;
      }
      else {
        this.selectedusername = value.Email;
      }
  
      this.selecteduserlist.push(value);
    }
  Getdocuments(docid) {
    var type =
    {
      filename: docid

    }
    this.spinner.show();
    this.inwardService.getFolder(type).subscribe((data: any) => {
      this.DocList = data;
      this.totalItems = this.DocList.folderName.length;
      this.Casedoc = this.DocList.folderName[this.totalItems - 1];
      this.DocList.folderName.pop();
      this.spinner.hide();
    })
  }
  GetOutWardTransactionWFId() {
    this.spinner.show();
    this.inwardService.GetOutWardTransactionWFId().subscribe((data: any) => {
      this.OutwardNumber = data;
      this.spinner.hide();
    })
  }
  GetFilePlan() {
    this.spinner.show();
    this.inwardService.GetFiles().subscribe((data: any) => {
      this.fileplanlist = data;
      this.totalItems = this.fileplanlist.length;
      if (this.outwarddata.one != undefined ||this.outwarddata.one != null) {
        this.OutwardNumber = this.outwarddata.one.id;
        this.InwardNumber = this.outwarddata.one.wO_InwordNo;
        this.outwardRefNumber = this.outwarddata.one.wO_InwordNo;
        if (this.outwarddata != null) {
          this.outward = {
            mailid: this.outwarddata.one.wO_MasID,
            address: this.outwarddata.one.wO_Address,
            attachmentdetails: this.outwarddata.one.wO_Attachment,
            dispatchMode: this.outwarddata.one.wO_DispatchMode,
            docType: this.outwarddata.one.wO_DocumentType,
            outwardtype: this.outwarddata.one.wO_OutwardTypeId,
            pages: this.outwarddata.one.wO_Pages,
            replyAwaited: this.outwarddata.one.wO_ReplyAwaited,
            sensitivity: this.outwarddata.one.wO_Sensitivity,
            subject: this.outwarddata.one.subject,
            status: this.outwarddata.one.statusid,
            filenumber: Number(this.outwarddata.one.filenumber),
            folder: Number(this.outwarddata.one.foldernumber),
            remarks: this.outwarddata.one.remarks
          }
        }
        this.GetUploadedDocuments(this.outwarddata.one.wO_InwordNo);
      }
      else {
        this.outward = {
          address: "",
          attachmentdetails: "",
          dispatchMode: "",
          docType: "",
          //InwardNumber: "",
          //outwardRefNumber: "",
          outwardtype: "",
          pages: "",
          replyAwaited: "",
          sensitivity: "",
          subject: "",
          status: "",
          filenumber: "",
          folder: "",
          mailid: "",
          remarks: ""
        }
      }
      if (localStorage.getItem("OutwardPreviewData") != null || localStorage.getItem("OutwardPreviewData") != undefined) {
        var data = JSON.parse(localStorage.getItem("OutwardPreviewData"))
        this.OutwardPreviewData = data
        this.AssignData();
  
      }
      this.spinner.hide();
    })
  }
  GetUploadedDocuments(val) {
    this.spinner.show();
    this.inwardService.GetDocumentsByDocumentID(val).subscribe((data: any) => {
      this.doclist = data;
      this.spinner.hide();
    })
  }
  GetDocumentType() {
    this.spinner.show();
    this.outwardService.getDocumentType().subscribe((data: any) => {
      this.documenttypelist = data;
      this.spinner.hide();
    })
  }
  GetUsers() {
    this.spinner.show();
    this.userservice.getAllUsers(this.GUID).subscribe((respose: any) => {
      this.UsersList = respose;
      this.spinner.hide();
    });
  }
  GetOutwardType() {
    this.spinner.show();
    this.outwardService.getOutwardType().subscribe((respose: any) => {
      this.outwardtypelist = respose;
      this.spinner.hide();
    });
  }
  GetFolders() {
    var type =
    {
      filename: this.outward.filenumber
    }
    this.spinner.show();
    this.inwardService.getFolder(type).subscribe((respose: any) => {
      this.folderlist = respose;
      this.GetInwardNumber(this.outward.filenumber)
      this.spinner.hide();
    });
  }
  GetInwardNumber(id) {
    var type =
    {
      filename: id
    }
    this.spinner.show();
    this.inwardService.getInwardNumber(type).subscribe((respose: any) => {
      this.Inwardno = respose;
      this.spinner.hide();
    });
  }
  GetSensitivity(){
    this.spinner.show();
    this.outwardService.getSensitivity().subscribe((respose: any) => {
      this.sensitivitylist = respose;
      this.spinner.hide();
    });
  }
  GetDispatchMode(){
    this.spinner.show();
    this.outwardService.getDispatchMode().subscribe((respose: any) => {
      this.dispatchmodelist = respose;
      this.spinner.hide();
    });
  }
  GetReplyAwaited(){
    this.spinner.show();
    this.outwardService.getReplyAwaited().subscribe((respose: any) => {
      this.replyawaitedlist = respose;
      this.spinner.hide();
    });
  }
  getStatus(){
    this.spinner.show();
    this.fileNumberGenerationService.getStatus().subscribe((respose: any) => {
      this.statuslist = respose;
      this.spinner.hide();
    });
  }
  PreviewOutward(){
    for (var i = 0; i < this.folderlist.folderName.length; i++) {
      if(this.folderlist.folderName[i]!=null){
   
      var currentfilenum=this.folderlist.folderName[i].fileId
      if (currentfilenum == this.outward.filenumber) {
          this.document = this.folderlist.folderName[i].impressionOftheSeal;
          this.doctype = this.folderlist.folderName[i].type;
          this.fileid = this.folderlist.folderName[i].fileId;
          this.folderid = this.folderlist.folderName[i].folderId;
          this.casedocument = this.folderlist.folderName[i].image;
          this.documentname = this.folderlist.folderName[i].folderName;
      }
    }
  }
  var data =
      {
          FromUser: this.uid,
          ToUsers: this.selecteduserlist,
          WO_Address: this.outward.address,
          WO_Attachment: this.outward.attachmentdetails,
          WO_CategoryId: "",
          WO_ComapnyID: "",
          WO_CrBy: "",
          WO_CrOn: "",
          WO_Date: "",
          WO_DispatchMode: this.outward.dispatchMode,
          WO_DocID: "",
          WO_DocumentType: this.outward.docType,
          WO_FinYear: "",
          WO_Group: "",
          WO_InwordNo: this.Inwardno,
          WO_OutwardNo: this.OutwardNumber,
          WO_OutwardRefNo: this.outwardRefNumber,
          WO_OutwardTypeId: this.outward.outwardtype,
          WO_Pages: this.outward.pages,
          WO_ReplyAwaited: this.outward.replyAwaited,
          WO_Sensitivity: this.outward.sensitivity,
          Subject: this.folderlist.divisionName,
          Statusid: this.outward.status,
          FileNumber: this.outward.filenumber,
          FolderNumber: this.folderid,
          Massid: this.outward.mailid == "" ? 0 : this.outward.mailid,
          Remarks: this.outward.remarks,
          InwardFolderId:this.InwardFolderId,
          InwardFileId:+this.InwardFileId
      }
      localStorage.setItem("OutwardPreviewData",JSON.stringify(data));
      this.router.navigate(['/outwardpreivew'])
  }
  SaveOutWard(){
    for (var i = 0; i < this.folderlist.folderName.length; i++) {
      if(this.folderlist.folderName[i]!=null){
   
      var currentfilenum=this.folderlist.folderName[i].fileId
      if (currentfilenum == this.outward.filenumber) {
          this.document = this.folderlist.folderName[i].impressionOftheSeal;
          this.doctype = this.folderlist.folderName[i].type;
          this.fileid = this.folderlist.folderName[i].fileId;
          this.folderid = this.folderlist.folderName[i].folderId;
          this.casedocument = this.folderlist.folderName[i].image;
          this.documentname = this.folderlist.folderName[i].folderName;
      }
    }
  }
  var data =
      {
          FromUser: this.uid,
          ToUsers: this.selecteduserlist,
          WO_Address: this.outward.address,
          WO_Attachment: this.outward.attachmentdetails,
          WO_CategoryId: "",
          WO_ComapnyID: "",
          WO_CrBy: "",
          WO_CrOn: "",
          WO_Date: "",
          WO_DispatchMode: this.outward.dispatchMode,
          WO_DocID: "",
          WO_DocumentType: this.outward.docType,
          WO_FinYear: "",
          WO_Group: "",
          WO_InwordNo: this.Inwardno,
          WO_OutwardNo: this.OutwardNumber,
          WO_OutwardRefNo: this.outwardRefNumber,
          WO_OutwardTypeId: this.outward.outwardtype,
          WO_Pages: this.outward.pages,
          WO_ReplyAwaited: this.outward.replyAwaited,
          WO_Sensitivity: this.outward.sensitivity,
          Subject: this.folderlist.divisionName,
          Statusid: this.outward.status,
          FileNumber: this.outward.filenumber,
          FolderNumber: this.folderid,
          Massid: this.outward.mailid == "" ? 0 : this.outward.mailid,
          Remarks: this.outward.remarks,
          InwardFolderId:this.InwardFolderId,
          InwardFileId:+this.InwardFileId,
          date:this.date
      };
      this.spinner.show();
      this.transcationService.saveOutWard(data).subscribe((data:any)=>{
        alert("Outwarded Successfully!!")
        this.spinner.hide();
        localStorage.removeItem("employee");
        this.router.navigate(['/dashboard'])
      })
  }
  SaveExamination(er) {
    var exam = {
      CaseID: er.caseId,
      InwardNumber: this.exhibit.Inwarddata.inwardNumber,
      DivisionName: this.exhibit.Inwarddata.wI_Address,
      ExhibitId: er.exhibitId,
      DoneBy: this.uid,
      Observations: er.observation,
      DateTime: new Date(),
      IsEnabled: false
    };
    this.examinatinservice.SaveExam(exam).subscribe((response: any) => {
      this.Response = response.data;
    })
  }
  SaveExamination1() {
    if (this.ExaminationsList.length == 0) {
      alert("Please add atleast one Examination")
    }
    else {
      this.spinner.show();
      this.examinatinservice.SaveExamss(this.ExaminationsList).subscribe((response: any) => {
        this.Response = response.data;
        this.spinner.hide();
        alert("Added succesfully!!");
      });
    }
  }
  AddExaminations(data) {
    if (this.ExaminationWindowStatus) {
      this.ExaminationWindowStatus = false;
    }
    else {
      this.ExaminationWindowStatus = true;
      var fid = {
        Caseid: this.filenumber,
        Role: this.Role,
        UserId: this.uid
      }
      this.examinatinservice.GetExhibit(fid).subscribe((response: any) => {
        this.exhibit = {
          Exhibits: response,
          Inwarddata: data
        };
      })
    }
  }
  onSelectexidExhibit(id){
    this.exid = id
  }
  onSelectExaminationExhibit(id) {
    this.selectedexaminationexhibhitid = id
  }
  AddExamination() {
    var len = this.ExaminationsList.length;
    const data: examination = {
      qdslno: len + 1,
      ExhibitId: this.exid,
      CaseID: this.exhibit.Exhibits.examinationdata[0].caseId,
      Observations: this.observation,
      DoneBy: this.uid,
      InwardNumber: this.inwardNumber,
      DivisionName: this.wI_Address,
    }
    this.ExaminationsList.push(data);
    //Clear the TextBoxes.
    this.qdslno = "";
    this.Exhibits = "";
    this.observation = "";
  }
  showExamination() {
    if (this.addExaminationStatus == true) {
      this.addExaminationStatus = false;
    }
    else {
      this.addExaminationStatus = true;
    }
  }
  showOpinion(pd) {
    if (this.showOpinionTable == true) {
      this.showOpinionTable = false;
      this.MessageShow = false;
    }
    else {
      this.ExaminationDetails = pd;
      this.spinner.show();
      this.examinatinservice.getOpinions(pd.id).subscribe((response: any) => {
        this.spinner.hide();

        if (response.length == 0) {
          this.notify = "No Opinions are Found!!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.showOpinionTable = false;
          this.MessageShow = true;

        } else {
          this.Opinions = response;
          this.showOpinionTable = true;
          this.MessageShow = false;
        }
      })
    }
  }
  AddOpinion(examination) {
    if (this.opinion === "") {
      alert('Please Specify the Opinion');
    } else {
      var data = {
        ExaminationId: examination.id,
        Opinion: this.opinion,
        GivenBy: this.uid,
        CaseId: examination.caseId
      }
      this.spinner.show();
      this.examinatinservice.saveOpinion(data).subscribe((response: any) => {
        if (response) {
          this.opinion = "";
          this.examinatinservice.getOpinions(examination.id).subscribe((response1: any) => {
            this.notify = "Opinion added successfully!"
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.Opinions = response1;
            this.showOpinionTable = true;
            this.MessageShow = false;
            this.spinner.hide();

          });
        } else {
          this.notify = "Something went wrong"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();

        }
      })
    }
  }
  RemoveExamination(index) {
    //var name = this.ExaminationsList[index].Name;
    this.ExaminationsList.splice(index, 1);
  }
  closealert(){
    this.dismissalert=false
  }
  gotopdfViewForAssignTask(file) {
    if (file.blockId == null || file.blockId == undefined) {
      file.blockId = file.caseId
    }
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.document);
    localStorage.setItem('FileNameView', file.fileName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.document);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.fileType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "false");
    localStorage.setItem('IsAssignTaskDoc', "true");
    this.router.navigate(['projectdocs/'])
  }
  GetWorkflowStages(){
    this.spinner.show();
    this.workflowService.getWorkflowStagesforot("OUT").subscribe((respose: any) => {
      this.workflowStages = respose;
      this.CurrentStatus=this.workflowStages.filter(function (stage, index) {
        return (stage.id === this.outward.status);
      })[0].workflowStage;
      this.spinner.hide();
    });
  }
  OnselectStatus(id){
    this.outward.status=id
  }
  OnselectDocumentType(id){
    this.outward.docType=id
  }
  OnselectReplyAwaited(id){
    this.outward.replyAwaited=id
  }
  OnselectSensitivity(id){
    this.outward.sensitivity=id
  }
  OnselectDispactcMode(id){
    this.outward.dispatchMode=id
  }
  OnselectOutwardType(id){
    this.outward.outwardtype=id
  }
  OnselectFilename(id){
    this.outward.filenumber=id
    this.GetFolders();
  }
  gotofile(filepopup){
    this.modalService.open(filepopup).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  mob = false;
  resize() {
    if (window.innerWidth > 500) {
      this.mob = true;
    }
    else {
      this.mob = false;
    }
  }
  Getfiles(fileid){
    this.spinner.show();
    this.inwardservice.GetInwardFiles(fileid).subscribe((Data: any) => {
      this.AllFiles = Data;
      this.spinner.hide();

    })
  }
  GetFoldersandFiles(folderid){
    this.spinner.show();
    this.inwardservice.GetInwardFolders(folderid).subscribe((Data: any) => {
      this.AllFolders = Data;
      this.spinner.hide();

    })
  }
  
  GetSubfolder(data) {
   
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.AllFolders = respose;
      this.subf = this.AllFolders.folderName;
      var Pid = this.AllFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.AllFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.AllFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.AllFolders.parentId));

      // console.log("user", this.allSubFolders);
      //this.route.navigate(['allsubfolders/' + data.folderID]);
    });
  }
  ViewDoc(file){
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType'); 
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');


    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType); 
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSOUT");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSOUT");


    this.router.navigate(['fileview/'])
  }
  ShareoneFile(data, Sharecontent) {
    this.ShareOnlyFile(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  
  ShareOnlyFile(data: any) {
    this.statuschange = "";
    this.statuschange = data;
    this.ShareOnlyDoc = data.file;
    this.BlockId = data.blockId;
    this.FolderID = data.folderId;
    this.ShareOnlyDocName = data.documentName;

  }
  
  GetFriendDetails() {
    this.userservice.friendlist(this.uid).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      // console.log(this.frndslist);
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }

  Share() {
    this.shareitem = true;
  }
  CancelShare() {
    this.shareitem = false;
  }
  Deleteitems() {
    this.deleteitem = true;
  }
  CancelDelete() {
    this.deleteitem = false;
  }
  Delete(data) {
    var res;
    if (data.folderName == null) {
      res = confirm("Do You Want to Delete " + data.documentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.folderName + "!!!");
    }
    if (res == true) {
      if (data.hash) {
        var info = {
          Id: data.id,
          FolderID: data.folderId,
          FolderName: data.folderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.folderId,
          RoleId: this.roleid,
          Click: null
        };
      }
      else {
        info = data;
      }
    }
    this.userservice.deletefolder(info).subscribe((data: any) => {
      if(data!=null){
        alert("Deleted Successfully")
      }
    });
  }
  GetDetails(id) {
    this.spinner.show();
    this.getpath(id);
    this.FileFoldID = id;
    this.userservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.fileName;
      this.fid = this.allSubFolders.parentId;
      // console.log("user", this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
    this.spinner.hide();

  }
  getpath(id) {
    this.userservice.getpath(id).subscribe((respose: any) => {
      this.pathdt = respose;
    });
  }
  SharewithMoreFriends(Sharecontent) {
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  DeleteMorecoin(Deletecontent) {
    this.modalService.open(Deletecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  toggleSelect(data) {
    this.CurrentFiles = data;
    this.checkenable = true;
    this.multi = true;
    this.sfiles = 0;
    this.sfolder = 0;
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedSelected.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.checkenable = false;
        this.multi = false;
      }
    }

    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].folderName != null) {
        this.sfolder = this.sfolder + 1;
      }
      else {
        this.sfiles = this.sfiles + 1;
      }
    }


    if (this.sfolder == 0) {
      this.deletemulticontent = this.sfiles + " Files"
    }
    else if (this.sfiles == 0) {
      this.deletemulticontent = this.sfolder + " Folders"
    }
    else {
      this.deletemulticontent = this.sfolder + " Folders " + this.sfiles + " Files"
    }
  }
  Deleteselected() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Deleting selected items";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Deleting selected items";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);
    const inputRequest: DeleteMulit = {
      SelectedFiles: this.selectedSelected,
      Message: "Delete",
      UserId: this.uid,
      ModifiedOn: null,
      GUID: this.GUID,

    }
    // console.log(inputRequest);
    this.userservice.DeleteMore(inputRequest).subscribe((data: any) => {
      this.Res = data;
      var fol = this.CurrentFiles;
      if (data != null) {
        this.n = 100;
        this.createdmsg = "Deleted selected items successfully!"
        alert("Deleted selected items successfully!")
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        this.GetFoldersandFiles(this.InwardFolderId);

       

        this.selectedSelected = [];
        this.multi = false;
        this.checkenable = false;
        setTimeout(() => {
        }, 500);
        //this.GetAllFolders();
        //this.spinner.hide();
      }
      else {
        this.checkenable = false;
        this.multi = false;
        this.notify = "Something went wrong"
        setTimeout(() => this.foladdednotify = true, 100);
        setTimeout(function () {
          this.foladdednotify = false;
        }.bind(this), 3000);

        this.GetFoldersandFiles(this.InwardFolderId);
      }
    });

  }
  getPercent(per) {
    return 10;
  }
  shareallallcancel() {
    if (this.selectedFriendcancel == null || this.selectedFriendcancel == undefined || this.selectedFriendcancel == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: 0,
        ToUsers: null,
        USerId: this.uid,
        GUID: this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.selectedFriendcancel,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Withdrawing Shared Items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Withdrawing Shared Items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.withdraw(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Withdraw of Shared files successfull!"
            alert("Withdraw of Shared files successfull!")
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            this.GetFoldersandFiles(this.InwardFolderId);

            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }
  shareallall() {
    if (this.selectedFriend == null || this.selectedFriend == undefined || this.selectedFriend == "") {
      alert("Please select a friend");
    }
    else {
      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
        ToUsers: this.selectedFriend,
        USerId: this.uid,
        GUID: this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: null,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);

      this.userservice.shareall(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared selected items successfully!"
            alert("Shared items successfully");
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
            this.GetFoldersandFiles(this.InwardFolderId);

          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.spinner.hide();
          }
        });
    }
  }

  ShareFileMembers1(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        if (this.frndslist[index].userId == this.uid) {
          this.selectedFriendcancel = this.frndslist[index].friendId;
          break;
        }
        this.selectedFriendcancel = this.frndslist[index].userId;
        break;
      }
    }

  }
  cancelshareall = false;
  shareitem1 = false;
  cancelshare() {
    if (this.cancelshareall == true) {
      this.cancelshareall = false;
      this.shareitem1 = false;

    }
    else {
      this.cancelshareall = true;
      this.shareitem1 = true;

    }
  }
  savefrienddetails() {
    this.spinner.show();
    const inputRequest: Friend = {
      Id: 0,
      FriendAccount: this.accid,
      UserId: this.userid,
      Status: 4,
      IsEnabled: true,
      GUID:this.GUID
    }
    this.userservice.SaveFrienddetails(inputRequest).subscribe((data: any) => {
      if (data == "SameUser") {
        alert("Please Enter your Friend MailId!!");

      }
      else if (data == "alreadyfriends") {
        alert("You are already friends!!");

      }
      else if (data == "NoUserFound") {
        alert("We are sorry " + this.accid + " is not using blocdrive!!");

      }
      else if (data == "SentRequest") {
        alert("You have already sent or recived request from this user!!");

      }
      else if (data == "1") {
        alert("Friend Request Sent Successfully!");
      }
      else {
        alert("Something went Wrong");
        // this.route.navigate(['/allfolders']);
      }
      this.spinner.hide();
      this.accid = "";
    });
  }
  
  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    }
    else {
      this.frndrqst = true;
    }
  }
  ShareFileMembers(data) {
    for (let index = 0; index < this.frndslist.length; index++) {
      if (this.frndslist[index].uid == data) {
        this.selectedFriend.push(this.frndslist[index])
        break;
      }
    }
  }
  SharOnlyFileToUsers() {
    this.sendFID = this.FolderID;
    this.sendBID = this.BlockId;
    const inputRequest: ShareOnlyFileVM = {
      FolderID: this.FolderID,
      File: this.ShareOnlyDoc,
      BlockID: this.BlockId,
      FromUserId: this.uid,
      ToUsers: this.selectedFriend,
      DocumentName: this.ShareOnlyDocName,
      UserId: this.uid,
      AccessType: null,
      UserName: this.username,
      BodyContent: null,
      UserEmail: null,
      CreatedOn: null,
      FromUserGUID:this.GUID
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
      }
    }, 3000);
    this.userservice.shareOnlyFile(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared item successfully within blockchain!";
          alert("Shared item successfully within blockchain!")
          this.pid = data;

          this.folderCreating = false;
          this.foldercreated = true;
          this.selectedFriend = [];
          clearInterval(this.num);
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.GetFoldersandFiles(this.InwardFolderId);

          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedFriend = [];
        }
      });
  }
  ShareExsistingSubFolderToUsers() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest: FolderShareToUers = {
      Id: "0",
      ToUsers: this.selectedFriend,
      BodyContent: "",
      FolderType: this.shareSubFolderFolderType,
      FolderName: this.shareSubFolderFolderName,
      Code: "SubFolder",
      ParentID: this.shareSubFolderParentID,
      FolderID: this.shareSubFolderFolderId,
      UserId: this.uid,
      AccessType: null,
      UserName: this.username,
      UserEmail: "",
      CreatedOn: "",
      GUID:this.GUID
    }
    //this.spinner.show();

    // console.log(inputRequest);
    this.userservice.shareExsistingSubFolder(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {

          this.n = 100;
          this.createdmsg = "Shared items successfully"
          alert("Shared items successfully");
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);

          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.GetFoldersandFiles(this.InwardFolderId);


          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);

          this.selectedFriend = [];
          this.accid = "";
        }
      });
  }
  ShareExsistingMultiFolderToUsers() {

    const inputRequest: MultiFolderShareVM = {
      Id: this.shareUniqueID,
      ToUsers: this.selectedFriend,
      USerId: this.uid,
      GUID: this.GUID,
      Code: "MainFolder",
      SelectedFolders: this.selectedSelected,
      AccessType: null,
      BodyContent: null,
      UserName: this.username,
      ModifiedOn: null
    }
    //this.spinner.show();
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.getPercent(this.n);
    this.Creatingmsg = "Sharing selected items...";
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing selected items...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    this.userservice.addMultigMainFolderAndShare(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared items successfully!"
          alert("Shared items successfully");
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);
          // this.notify = "Folder Shared Succesfully";
          // setTimeout(() => this.dismissalert = true, 100);
          // setTimeout(function () {
          //   this.dismissalert = false;
          // }.bind(this), 3000);
          // this.spinner.hide();
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedSelected = [];
          this.multi = false;
          this.GetFoldersandFiles(this.InwardFolderId);


        }
        else {
          //alert("Something went wrong!!please try again");
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
        }
        this.spinner.hide();
      });
  }
  
  UpdateSubFolder(folderview, editfolder) {
    this.details = folderview;
    this.updatemsg = folderview.folderName;
    this.modalService.open(editfolder).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.u = this.updatemsg;
    });
  }

  UpdateSubFolderName() {
    var offset = new Date().getTimezoneOffset();
    var nd = 0.0;
    nd = -(parseFloat(offset.toString()));
    const inputRequest: AddNewFolder = {
      Id: this.details.id,
      FolderID: this.details.folderID,
      RoleId: this.roleid,
      FolderName: this.updatemsg,
      UserId: this.uid,
      Code: "Folder",
      ParentID: this.details.parentID,
      Click: null,
      date: nd,
      GUID:this.GUID

    }
    this.userservice.UpdateFolder(inputRequest).subscribe((data: any) => {
      if (data == true) {
        this.n = 100;
        this.createdmsg = "Updated Successfully";
        alert("Updated Successfully");
        this.pid = data;
        this.folderCreating = false;
        this.foldercreated = true;
        clearInterval(this.num);
        this.GetFoldersandFiles(this.InwardFolderId);
        setTimeout(function () {
          this.folderCreating = false;
          this.foldercreated = false;
          this.generalprogressbar = false;
          this.errormsg = false;
          this.showper = false;
        }.bind(this), 3000);
        // alert("Updated Successfully");


      }
      else {
        alert("Something went Wrong");
      }
    });
  }
  // Download Folder
  DownloadFolder(data) {
    const inputRequest: DownloadFolderVM = {
      Id: data.id,
      AUID: this.uid,
      FolderId: data.folderID,
      FolderName: data.folderName,
      GUID:this.GUID

    }
    this.userservice.downloadFolder(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
      }
    });

  }
  getSubFolderdata(data) {
    this.shareSubFolderFolderId = data.folderID;
    this.shareSubFolderFolderName = data.folderName;
    this.shareSubFolderFolderType = data.folderType;
    this.shareSubFolderParentID = data.parentID;
    this.shareSubFolderId = data.id;
  }
  ShareFolder(data, Sharecontent) {
    this.getSubFolderdata(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  getuserrights(){
    this.spinner.show();
    this.userservice.getuserRights(this.uid).subscribe((Data: any) => {
      this.UserRights = Data;
      for (let i = 0; i <  this.UserRights.length; i++) {
        if(this.UserRights[i].type==1){
          this.EnableShare=true;
        }     
        else if(this.UserRights[i].type==2){
          this.EnableDelete=true;
        }  
        else if(this.UserRights[i].type==3){
          this.EnableEdit=true;
        }  
        else if(this.UserRights[i].type==4){
          this.EnableInward=true;
        } 
        else if(this.UserRights[i].type==5){
          this.EnableDownload=true;
        }      
      }
      this.spinner.hide();
    })
  }
}
