import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssignRightService } from 'src/app/Shared/AssignRightMaster/assign-right.service';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-assign-right-master',
  templateUrl: './assign-right-master.component.html',
  styleUrls: ['./assign-right-master.component.css']
})
export class AssignRightMasterComponent implements OnInit {
  notify: string;
  dismissalert: boolean;
  RightName: string;
  rightid: any;
  createdby: any;
  createdon: any;
  Rights: any;
  showSave: boolean;
  showUpdate: boolean;
  rightName: any;
  deleterightid: any;
  p:number=1
  uid: string;
  roleid: string;
  GUID: string;
  mobileview: boolean;
  constructor(private spinner:NgxSpinnerService,private auditortransactionservice:AuditorTransactionService,private assignrightservice:AssignRightService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();
   }
   isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  ngOnInit() {
    this.GetRights();
    this.GetRightdata();
    this.RightName = "";
  }
  UpdateUserRight(form: NgForm) {
    this.spinner.show();
    const inputRequest1 = {
      Id: this.rightid ,
      AssignRigntType: form.controls["rightName"].value,
      CreatedBy:  this.createdby,
      CreatedOn:  this.createdon,
      ModifiedBy:"",
      ModifiedOn:"",
      GUID: this.GUID
    }
    this.assignrightservice.UpdateUserRightRow(inputRequest1).subscribe(
      (data: any) => {
          if (data == 1) {
            this.notify = "Right updated successfully"
            const audit = {
              TransactionName:"Master",
              TransactionType:"Update",
              TransactionDesc:"Assignright master",
  
              }
              this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetRights();
            this.spinner.hide();
        
        }
        else{
          this.notify = "Something went wrong.Please Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetRights();
          this.spinner.hide();
        }
      }
    );
  }
  SaveUserRight(form: NgForm) {
    this.spinner.show();
    const inputRequest = {
      Id: 0,
      AssignRigntType: form.controls["rightName"].value,
      CreatedBy:"",
      CreatedOn:"",
      ModifiedBy:"",
      ModifiedOn:"",
      GUID: this.GUID

    }
    this.assignrightservice.saveUserRight(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.notify = "Right saved successfully"
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Assignright master",
           
            }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetRights();
          this.spinner.hide();
         
        }
        else{
          this.notify = "Something went wrong.Please Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetRights();
          this.spinner.hide();
        }
      }
    );
  }
  GetRights() {
    this.spinner.show();
    this.assignrightservice.getUserRights(this.GUID).subscribe((response: any) => {
      this.Rights = response;
      this.spinner.hide();
    });
  }
  GetRightdata() {
    this.spinner.show();
    this.assignrightservice.getUserRightdata(this.roleid).subscribe((response: any) => {
      this.Rights = response;
      this.spinner.hide();
    });
  }

  editRight(data:any){
    this.showSave = false;
    this.showUpdate = true;
    this.rightid = data.id;
    this.rightName = data.assignRigntType;
    this.createdby=data.createdBy;
    this.createdon=data.createdOn;
  }
  addClick(){
    this.showSave = true;
    this.showUpdate = false;
    if (this.rightName != "") {
      this.rightName = "";
    }
  }
  deleteRight(data:any){
    this.deleterightid = data.id;
  }
  deleteRightRow(){  
    this.spinner.show();
      this.assignrightservice.deleteRightdata(this.deleterightid).subscribe(
        (data: any) => {
          if (data != null) {
            this.notify = "Right deleted successfully"
            const audit = {
              TransactionName:"Master",
              TransactionType:"Delete",
              TransactionDesc:"Assignright master",
              }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
            this.GetRights();
            this.spinner.hide();
           
          }else{
            this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetRights();
          this.spinner.hide();
          } 
        });
    }
}
