<app-nav-bar></app-nav-bar>

<div class="row">
    <div class="col-lg-12">
        <div class="box">
            <!--mail inbox start-->
            <div class="mail-box">
                <aside class="sm-side">
                    <div class="user-head">

                        <div class="user-name">
                            <h5><a href="#">NAME</a></h5>
                            <span><a href="#">EMAIL</a></span>
                        </div>

                    </div>
                    <div class="inbox-body">
                        <a class="btn btn-compose" data-toggle="modal" (click)="composemail()" data-original-title="" title="">
                            Compose
                        </a>


                    </div>

                    <ul class="inbox-nav inbox-divider">
                        <li class="active">
                            <a (click)="inbox()"><i class="fa fa-inbox"></i> Inbox </a>
                        </li>
                        <li>
                            <a (click)="sentitems()"><i class="fa fa-envelope-o"></i> Sent Mail</a>
                        </li>

                        <!-- <li>
                            <a (click)="archiveitems()"><i  class="fas fa-download"></i> Archive</a>
                        </li> -->
                        <li>
                            <a (click)="deleteitems()"><i class=" fa fa-trash-o"></i> Trash</a>
                        </li>
                    </ul>


                </aside>
                <aside class="lg-side">
                    <div class="inbox-head">
                        <h3>Sent Mails</h3>

                    </div>
                    <div class="inbox-body">

                        <table class="table table-inbox table-hover">
                            <tbody>
                                <tr class="unread" id="{{ret.Id}}" (click)="ReadMessage(ret)" style="cursor: pointer;" *ngFor="let ret of SentItemsList">

                                    <!-- <td class="inbox-small-cells">
                                        <input type="checkbox" class="mail-checkbox">
                                    </td> -->

                                    <td class="view-message  dont-show">
                                        {{ret.toName}}
                                    </td>

                                    <td class="view-message " >
                                        {{ret.subject}}
                                    </td>
                                    <td class="view-message " >
                                        {{ret.date}}
                                    </td>
                                    

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </aside>
            </div>
            <!--mail inbox end-->
        </div>
    </div>
</div>