<div class="video-container">
    <!-- <video src="assets/video/blocks.mp4" controls autoplay loop muted></video> -->
    <video src="assets/img/blocks12.mp4" [muted]="'muted'" loop autoplay style=" left:0 !important;
    right:0 !important;"></video>
    <!-- <div class="text-overlay">
        <h2 class="animated-text">Blocdrive</h2>
        <h2 class="animated-text">Internet of privacy has arrived!</h2>
        <h2 class="animated-text" >Secure Data</h2>
      </div> -->
      <div class="image-overlay">
        <!-- <p style="color: white;font-size: 10px;letter-spacing: 1px;padding-left: 19px;margin-top: 73px;">Securing Data</p> -->

      </div>
    
    <nav class="navigation navmedia">
      <ul>
                 
        <li class="nav-item active">
            <a class="nav-link" href="" [routerLink]="['/howto']">Contact Us &nbsp; </a>
        </li>

        <li class="nav-item active" *ngIf="!isLoggedIn">
            <a class="nav-link" [routerLink]="['/faq']">FAQ</a>
        </li>
        <li class="nav-item active">
            <a class="nav-link" [routerLink]="['/help']">Help </a>
        </li>
        <li class="nav-item active" *ngIf="!isLoggedIn" >
            <a class="nav-link" [routerLink]="['/login']">Sign In </a>
        </li>
        <li class="active" *ngIf="isLoggedIn">
        <a href="#/dashboard" class="text-white">MySpace</a>
        </li>
       
        <li class="active" *ngIf="isLoggedIn">
          <div class="dropdown">
            <p  *ngIf="roleid != 1"  style="text-transform: capitalize;font-size: 15px;
              font-weight: 900;" class="text-white" >{{username}}<i class="fa fa-caret-down" aria-hidden="true"></i></p>
              <p *ngIf="roleid == 1" style="text-transform: capitalize;font-size: 15px;
              font-weight: 900;" class="text-white" > {{firstname}}<i class="fa fa-caret-down" aria-hidden="true"></i></p>
            <div class="dropdown-content" >
               
              <p style="font-weight: 900;" class="text-white" [routerLink]="['/profile']" style="cursor: pointer;">My Account</p>
              <p  style="font-weight: 900;" class="text-white" (click)="logout()"  >Logout</p>
                
            </div>
        </div>
      </li>

    </ul>
      </nav>
  </div>
  

<!-- 2nd nav -->
<!-- <div style="margin:-96px;">  dont give like this its wrong-->
    <p class="textyy text-center" style="font-size: 46px;font-weight: bold;">Blockchain</p>
    <p class="textyy text-center" >The new era of storage solution with blockchain 
        which is the technology of security, trust and immutability!</p>  
<!-- 3nd nav -->
<div class="row knowmoremedia" style="background-color: #f2f2f2;">
  <div class="fadeInDelay">
  
    <div class="row align-items-center g-5">
      <div class="col-lg-6 knowmoremedia1">
        <img src="assets/img/blocks41arz.webp" loading="lazy" class="fadeInDelay" alt="Card Image" style="width:567px;height: 373px;margin-left: 172px;">
      </div>
      <div class="col-lg-5 knowmoremedia2" style="margin-left: 61px;">
        <div class="" style="margin-top:226px;margin-left: 260px;">
        <p class="para" style="font-size: 57px;text-align: center;font-weight: bold;margin-left: -170px;margin-top: -190px;">Why Blocdrive ?</p>
       
      <p class="para" style="font-size: 30px;    margin-left: -155px;">Secured by Smart Contracts</p>
       <p class="para" style="margin-left:-154px;">The blocdrive system protects both personal and business data.<br>
           Every piece of data is saved in a block.<br>
            As a result, there is no need for data protection or assurance.<br>
             Overall, the system will be beneficial in all sectors.</p>
             <br><br><br><br><br><br><br><br>
             <button class = "btn btn-default btn-sm" (click)="knowabtblocdrive()" pageScroll href="#showhidevalue" style=" position: absolute;
             top:62%;
             background-image: linear-gradient(to right,rgb(202 44 205),rgb(102 20 101));
             color: #fff;
             border:none; 
             border-radius:10px; 
             padding:15px;
             min-height:30px; 
             min-width: 91px;
             font-size: 20px;
             margin-left: -27px;">Know more</button>
            </div>
    </div>
      
    </div>
    <br> 
  </div>
  
</div>
<br><br><br><br><br>

 
<!-- 4rd nav -->
<div class="col-md-12 row">
<div class="col-md-1"></div>
<div class="col-md-9">
<!-- <div class="card"> -->
<br>
<br>
<p class="text-center" style="font-size: 35px;font-weight: bold;margin-top: -141px;">Features</p>
<br>
<div class="row featuresmedia" style="margin-right: 11px;margin-left: 117px;">
<div class="col-md-3">
  <img src="assets/img/store1.webp" loading="lazy" style="width: 50px;height:50px;" alt="store">
  <br> <br>
          <div class="heading" >STORE</div>
  <p class="text-muted mb-0">Blocdrive provides a service to store your folder with high security.</p>
</div>
<div class="col-md-3">
  <img src="assets/img/restore1.png" loading="lazy" style="width: 50px;height:50px;" alt="restore">
  <br> <br>
  <div class="heading" >RESTORE</div>
<p class="text-muted mb-0" >If you delete a folder or object by mistake, No worries you can restore it.</p>
</div>
<div class="col-md-3">
  <img src="assets/img/organizer1.png" loading="lazy" style="width: 50px;height:50px;" alt="organize">
  <br> <br>
  <div class="heading" >ORGANISE</div>
<p class="text-muted mb-0" >Blocdrive user can also organise his Folder/Object as he wishes.</p>
              
</div>
<div class="col-md-3">
  <img src="assets/img/chat11.png" loading="lazy" style="width: 50px;height:50px;" alt="chat">
  <br> <br>
  <div class="heading" >CHAT</div>
<p class="text-muted mb-0" >Your friend and you are connected! Now you can share objects/folders or chat with them!</p>
</div>

</div>
<br><br>
<div class="row featuresmedia" style="margin-right: 11px;margin-left: 117px;" alt="share">
<div class="col-md-3">
  <img src="assets/img/share1.png" loading="lazy" style="width: 50px;height:50px;" alt="">
  <br> <br>
  <div class="heading" >SHARE</div>
  <p class="text-muted mb-0">Just click and share your objects and folders.</p>
</div>
<div class="col-md-3">
  <img src="assets/img/download1.webp" loading="lazy" style="width: 50px;height:50px;" alt="download">
  <br> <br>
  <div class="heading" >DOWNLOAD</div>
<p class="text-muted mb-0" >Download all your files and chat backup</p>
<br>
</div>
<div class="col-md-3">
    <img src="assets/img/edit1.png" loading="lazy" style="width: 50px;height:50px;" alt="edit">
    <br> <br>
    <div class="heading" >EDIT</div>
<p class="text-muted mb-0">Edit your object name and also edit your document by editor</p>
</div>
<div class="col-md-3">
  <img src="assets/img/objreq1.png" loading="lazy" style="width: 50px;height:50px;" alt="objectrequest">
  <br> <br>
  <div class="heading" >OBJECT REQUEST</div>
<p class="text-muted mb-0" >Invite anyone to upload files to your blocdrive,
    even if they don't have an account.</p>
</div>
</div>
<!-- </div> -->
</div>
</div>

<br><br><br><br>

<!-- 5th nav -->

<div class="row showmore" id="showhidevalue" style="background-color: #f2f2f2;">
<!-- d2d5fa -->
<!-- e6e7f5 -->
<div class="col-lg-6 showmore1" style="margin-left: 170px;margin-top: 100px;">
<p style="font-weight: bold;font-size: 70px;color:#155290;">Enterprise Benefits</p>
<p style="font-weight: bold;font-size: 30px;color:#155290;">
Create a team to assist you in assigning tasks<br>
and dividing responsibilities.</p>  
<p style="color:#155290;">It is a framework which allows initiating, planning,
implementing, prioritizing duties,<br> monitoring tasks,
influencing, and completing a team's work to achieve<br>
specific goals and specific requirements.</p>
</div>
<div class="col-lg-4 showmore2" >
<br><br>  
<img src="assets/img/blocks511rz.webp" loading="lazy" class="fadeInDelay" alt="Card Image" style="height: 500px;width: 500px;margin-left: 30px;">
<br>  <br><br>
</div>




</div>


<!-- some nav  -->
<br><br><br>
<div class="col-md-12 row cardmedia">
&nbsp;&nbsp;&nbsp;&nbsp;
<div class="col ">
<img src="assets/img/emailinternal1.webp" loading="lazy" alt="" width="338px" height="188px" >
<div class="row ">
<div class="col ">
<br>
<h2 >Mail System</h2>
<p style=" font-family:SegoeUI;font-size: 1rem;">
  A mailing system is a piece of software that<br>
   automates the planning, computation, outcome,<br>
   and distribution of your company's outgoing<br>
   mail.</p>
</div>
</div>
</div>
<div class="col">
<img   src="assets/img/electronic-signature1rz.webp" loading="lazy" alt="" width="338px" height="189px">
<div class="row ">
<div class="col ">
<br>

<h2 >E-Signature</h2>
<p style=" font-family:SegoeUI;font-size: 1rem;">
  It offers the best PDF tools for quickly preparing<br>
  documents and collecting electronic signatures<br>
  digitally.</p>
    </div>
</div>
</div>
<div class="col ">
<!-- <div class="card"> -->
<img  src="assets/img/srichiddrive1rz.webp" loading="lazy" alt="" style="
background-color:rgb(226 214 237)" width="340px" height="188px">
<!-- </div> -->
<div class="row ">
<div class="col ">
<br>
<h2 >Srichid Drive</h2>
<p style=" font-family:SegoeUI;font-size: 1rem;">
  Windows Based Drive can be used offline by<br>
  downloading to your local system. So any changes <br>
  made in a local system it will reflect as it is<br>
  in the Blocdrive.</p>
    </div>
</div>
</div>
<div class="col">
<img  src="assets/img/gdtobd1.webp" loading="lazy" alt="" width="338px" height="188px">
<div class="row ">
<div class="col ">
<br>
<h2 >Google Drive</h2>
<p style=" font-family:SegoeUI;font-size: 1rem;">Migrating data from Googledrive
  to Blocdrive</p>
    </div>
</div>
</div>
</div>




<!-- 6th nav -->
<br>
<!-- ------plans---------- -->
<div class="planmedia">


<div class="text-center p-5">
  <h1 style="font-weight: bold;">Plans</h1>
</div>
<div class="text-center">
  <div class="block d-inline-flex">
      <div class="p-4 p-sm-5 b-r" style="margin-top: -80px;"> 
         <sup class="text-sm" style="top: -0.5em;">₹</sup>
         <span class="h1">1</span>
          <div class="text-muted" >Individuals Plan</div>
          <div class="py-4">
          <a [routerLink]="['/payment']" class="btn" data-abc="true"
          style="background-image: linear-gradient(to right,rgb(202 44 205),rgb(102 20 101));
           height: 28px;color: #fff;" >Purchase</a>
        </div> 
        <small class="text-muted">Start <strong>for</strong>  free</small>
      </div>
      <div class="p-4 p-sm-5" style="margin-top: -80px;">
         <sup class="text-sm" style="top: -0.5em;">₹</sup>
         <span class="h1" >4500</span>
          <div class="text-muted" >Enterprise Plan</div>
          <div class="py-4">
          <a [routerLink]="['/payment']" class="btn "
           style="background-image: linear-gradient(to right,rgb(202 44 205),rgb(102 20 101));
           height: 28px;color: #fff;" 
           data-abc="true" >Purchase</a>
          </div>
           <small class="text-muted">Buy the Plan</small>
      </div>
  </div>
</div>
</div>
<br>
<!-- <app-support-chat></app-support-chat> -->
