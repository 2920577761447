
<app-nav-bar></app-nav-bar>
<br><br><br><br><br>



<!-- Button trigger modal -->

  
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Upload File</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <label for="myfile">Select a file:</label>
            <input style="padding: 13px;" type="file" accept="image/*" name="avatars"
            #fileField (change)="onChange($event)">
            <!-- <button class="btn btn-primary" >Add</button> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="Uploadexcel()">Add</button>
        </div>
      </div>
    </div>
  </div>
<div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
          Add Users
          </button>    </div>
    <div class="col-sm-9"></div>
  </div>


  <div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-10">
    <div>
      <div class="table-responsive" style="overflow-x: visible; height: 300px">
        <table class="table table-responsive" style="border-collapse: collapse">
          <tr class="thead-dark" style="font-size: 17px">
            <th style="width: 14%;">Id</th>
            <th style="width: 14%;">Projects</th>
            <th style="width: 14%;" colspan="3">Action</th>
        </tr>
        <tr *ngFor="
        let aa of Files
          | paginate: { itemsPerPage: 5, currentPage: p };
        let i = index
      " style="
        font-size: 16px;
        color: rgb(0, 0, 0);
        font-family: DINNextRoundedLTPro;
      ">
      <td>{{ 5 * (p - 1) + (i + 1) }}</td>
  
        <!-- <tr *ngFor="let aa of Files;index as i"> -->
           
            <td>{{aa.fileName}}</td>

            <!-- <td>
                <button class="btn btn-info" data-toggle="modal" mdbWavesEffect (click)="viewExcel(aa)"> View
                </button>
            </td> -->
            <td>
                <button class="btn btn-info" data-toggle="modal" mdbWavesEffec (click)="uploadexceldata(aa.fileName)">
                    UploadData</button>
            </td>


        </tr>
    </table>
    <br />
    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
    <br /><br />
    <br /><br />
  </div>
</div>
</div>
