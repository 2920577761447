<h3>Individual and Enterprise Registration</h3>

<table class="table table-primary">
    <thead class="thead-dark">
        <tr> 
            <th>UserId</th>
            <th>Email </th> 
            <th>FromuserId</th>
            <th>FromuserGUID</th>
            <th>Touser</th>
            <th>TouserGUID</th>
      
           
        </tr>
    </thead>
    <tbody>
        <tr  *ngFor="let aa of dataa">
            <!-- <td>{{5*(p-1)+(i+1)}}</td>  -->
            <td>{{aa.userId}}</td>
            <td>{{aa.email}}</td>
          <th>{{aa.fromuser}}</th>
          <th>{{aa.fromuserGUID}}</th>
          <th>{{aa.touser}}</th>
          <th>{{aa.touserGUID}}</th>
           
            

        </tr>
    </tbody>
</table>
