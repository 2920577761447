import { Component } from '@angular/core';
import { MessageService } from './Shared/MessageService';
import { Subscription } from 'rxjs';
import { Location } from "@angular/common";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'blocdrive';
  subscription: Subscription;
  message: any;
  isLoggedIn: boolean;
constructor(private messageService: MessageService,public location: Location,) {
  if (localStorage.getItem("IsLoggedIn") == "true") {
    this.message = true;
    this.isLoggedIn = true;

}
this.subscription = this.messageService.getMessage().subscribe(message => {
    this.message = message;
    if (this.message.text == "true") {
        this.isLoggedIn = true;
    }
});
 }
 ngOnInit() {
  if (environment.production) {
    if (location.protocol === 'http:') {
      if(window.location.href.includes('www.'))
      window.location.href = location.href.replace('http://www.', 'https://');
      else
      window.location.href = location.href.replace('http', 'https');
    }
  }
}
}
