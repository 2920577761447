import { Component, OnInit } from '@angular/core';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-sent-items',
  templateUrl: './sent-items.component.html',
  styleUrls: ['./sent-items.component.css']
})
export class SentItemsComponent implements OnInit {
  uid: string;
  username: string;
  Role: string;
  p:number=1;
  SentItems: any;
  totalItems: any;
  roleid: string;
  mobileview: boolean;
  constructor(private workflowservice:WorkFlowService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.Role = localStorage.getItem("role");
      this.roleid = localStorage.getItem("role");

    }
    this.isMobileMenu();
   }

  ngOnInit(): void {
  this.getSentItems();
  }
  getSentItems(){
    this.workflowservice.getSentItem(this.uid).subscribe((response:any)=>{
      this.SentItems=response;
      this.totalItems=this.SentItems.length;
    })
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

}
