import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { FolderShareToUers } from 'src/app/ViewModels/FolderShareToUers';
import { MultiFolderShareVM } from 'src/app/ViewModels/MultiFolderShareVM';
import { ShareOnlyFileVM } from 'src/app/ViewModels/ShareOnlyFileVM';

@Component({
  selector: 'app-sharefile',
  templateUrl: './sharefile.component.html',
  styleUrls: ['./sharefile.component.scss']
})
export class SharefileComponent implements OnInit {
  selectedSelected: any[];
  isLoggedIn: boolean;
  uid: string;
  username: string;
  roleid: string;
  gridvalue: string;
  allFolders: any;
  SharedFiles: any;
  subf: any;
  allSubFolders: any;
  videoplay: any;
  zzz: any;
  current_url: any;
  domSanitizer: any;
  pdfUrl: any;
  closeResult: string;
  txtUrl: any;
  m: any;
  msg: any;
  current_url1: any;
  videodate: any;
  doc: string;
  allFolders1: any;
  FileFoldID: any;
  allSubFolders1: any;
  fid: any;
  ppopup: any;
  multi: any
  mobile = false;
  shareitem: any
  deleteitem: any
  folderview: any
  Docname: any
  audioplay: any;
  audiodate: any;
  imgdisplay: any;
  GUID: string;
  CurrentFiles: any;
  checkenable: boolean;
  sfiles: number;
  sfolder: number;
  deletemulticontent: string;
  notify: string;
  foladdednotify: boolean;
  usermail: any;
  fullname: any;
  IPAddress: any;
  dnsIP: any;
  showper: boolean;
  generalprogressbar: boolean;
  folderCreating: boolean;
  Creatingmsg: string;
  num: any;
  Res: any;
  createdmsg: string;
  foldercreated: boolean;
  public n: any = 20;
  selectedFriend: any=[];
  pid: any;
  pathdt: any;
  statuschange: string;
  ShareOnlyDoc: any;
  BlockId: any;
  FolderID: any;
  ShareOnlyDocName: any;
  Friends: any;
  frndslist: any;
  accid: any;
  sendFID: any;
  sendBID: any;
  userName: string;
  shareSubFolderFolderType: any;
  shareSubFolderFolderName: any;
  shareSubFolderParentID: any;
  shareSubFolderFolderId: any;
  shareSubFolderId: any;
  frndrqst: boolean;
  dismissalert: boolean;
  shareUniqueID: any;
  enablemultishare:boolean=false;
  constructor(private userservice: UserDetailsService,private auditortransactionservice:AuditorTransactionService,
     private router: Router, private modalService: NgbModal, private spinner: NgxSpinnerService) {
    this.ppopup = true;
    this.spinner.show();
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserName");
      this.gridvalue = localStorage.getItem("gridvalue");
      this.GUID = localStorage.getItem("GUID");
      this.userName = localStorage.getItem("UserName");

    }
    window.scrollTo(0, 0);

  }
  ngOnInit() {
    this.GetAllSharingFolders();
    this.GetAllFolders1();
    this.GetFriendDetails();
    setTimeout(() => {
      var footer = document.getElementById("footer");
      if (footer.style.display == "none") {
        footer.style.display = "block";
      }
    }, 1000);
    if (window.innerWidth < 500) {
      setTimeout(() => {
        var tree = document.getElementById("treeview");

        if (tree) {
          tree.style.display = "none";
        }
        var upload = document.getElementById("view");

        if (upload) {
          upload.style.display = "none";
        }
      }, 100);

      this.mobile = true;
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }
  toggleDelete(a, b) {

  }

  ViewDoc(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType');
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.signFile);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType);
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "ALLDOCSFOLDER");
    localStorage.setItem('IsAssignTaskDoc', "ALLDOCSFOLDER");


    this.router.navigate(['fileview/'])
  }

  treeviewba() {
    var tree = document.getElementById("treeview");
    if (tree) {
      if (tree.style.display == "none") {
        tree.style.display = "block";
      }
      else {
        tree.style.display = "none";
      }
    }
  }
  GetAllFolders1() {
    this.userservice.getAllSharingfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders1 = respose;
      if (this.allFolders1.length > 0 && this.allFolders1[0].sharedFiles.length != 0 && this.allFolders1[0].TotalMembers == null) {
        this.SharedFiles = this.allFolders1[0].sharedFiles;
      }
      else if (this.allFolders1[0].TotalMembers != null) {
        this.allFolders1 = respose;
        this.SharedFiles = this.allFolders1[0].sharedFiles;
      }
      this.spinner.hide();
    });
  }

  toggle(a, b) {

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  GetAllSharingFolders() {
    //this.GetallFilesForUplodtoCompare();
    this.selectedSelected = [];
    this.userservice.getAllSharingfolders(this.GUID).subscribe((respose: any) => {
      this.allFolders = respose;
      if (this.allFolders.length > 0 && this.allFolders[0].sharedFiles.length != 0 && this.allFolders[0].TotalMembers == null) {
        this.SharedFiles = this.allFolders[0].sharedFiles;
      }
      else if (this.allFolders == null || this.allFolders == undefined || this.allFolders == "") {
        this.spinner.hide();
      }
      else if (this.allFolders[0].TotalMembers != null) {
        this.allFolders = respose;
        this.SharedFiles = this.allFolders[0].sharedFiles;

      }
      // this.spinner.hide();
    });
  }
  GetSubfolder(data) {
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));
      console.log("user", this.allSubFolders);
      this.router.navigate(['sharedsubfolders/' + data.folderID]);
    });
  }

  GetSubfolder1(data) {
    this.subf = data.folderName;
    this.userservice.getsubfolders(data.folderID).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.folderName;
      var Pid = this.allSubFolders.parentId;
      //localStorage.setItem(this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      localStorage.setItem('parentid', JSON.stringify(this.allSubFolders.parentId));
      console.log("user", this.allSubFolders);
      this.router.navigate(['sharedsubfolders/' + data.folderID]);
    });
  }

  getfolders1(id1) {
    let id = id1.folderID;

    let elem = document.getElementById(id);
    if (elem.childNodes.length < 2) {

      var icn = document.getElementById(id1.id);
      if (icn != null) {
        icn.setAttribute("class", "fa fa-caret-down");

      }

      this.FileFoldID = id;
      this.userservice.getsubfolders(this.FileFoldID).subscribe((respose: any) => {
        this.allSubFolders1 = respose;
        this.subf = this.allSubFolders1.fileName;
        this.fid = this.allSubFolders1.parentId;
        console.log("user", this.allSubFolders1);
        // localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
        //localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
        //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );

        var mdv1 = document.createElement("div");
        for (let i = 0; i < this.allSubFolders1.subFolders.length; i++) {
          var mdv = document.createElement("div");
          mdv.setAttribute("id", this.allSubFolders1.subFolders[i].folderID);
          mdv.setAttribute("style", "padding-left:20px");
          var elm = document.createElement("span");
          var img = document.createElement("img");
          img.setAttribute("src", "./assets/img/folder.svg");
          img.setAttribute("width", "30px");
          img.setAttribute("height", "30px");
          var elm1 = document.createElement("span");
          elm1.innerHTML = this.allSubFolders1.subFolders[i].folderName;

          var elm12 = document.createElement("i");

          elm12.setAttribute("class", "fa fa-caret-right");
          elm12.setAttribute("id", this.allSubFolders1.subFolders[i].id);

          elm12.addEventListener('click', this.getfolders1.bind(this, this.allSubFolders1.subFolders[i]));



          elm.appendChild(elm12);
          elm.appendChild(img);
          elm.appendChild(elm1);

          mdv.appendChild(elm);

          img.addEventListener('click', this.GetSubfolder.bind(this, this.allSubFolders1.subFolders[i]));
          elm1.addEventListener('click', this.GetSubfolder.bind(this, this.allSubFolders1.subFolders[i]));


          mdv1.appendChild(mdv);
        }



        // for(let i=0;i<this.allSubFolders.retData.length;i++)
        // {
        //     var mdv = document.createElement("div");
        // mdv.setAttribute("id",this.allSubFolders.retData[i].blockId);
        //     mdv.setAttribute("style","padding-left:20px");
        //     var elm =document.createElement("span");
        //     var img = document.createElement("img");

        // if(this.allSubFolders.retData[i].documentType=="image/gif")
        // {
        //     img.setAttribute("src",this.allSubFolders.retData[i].file);
        // }
        // else if(this.allSubFolders.retData[i].documentType=='image/jpeg'||this.allSubFolders.retData[i].documentType=='image/png')
        // {
        //     img.setAttribute("src",this.allSubFolders.retData[i].file);
        // }
        // else if(this.allSubFolders.retData[i].documentType=='application/pdf'||this.allSubFolders.retData[i].documentType=='pdf')
        // {
        //     img.setAttribute("src","./assets/img/pdficon.png");
        // }
        // else if(this.allSubFolders.retData[i].documentType=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'||this.allSubFolders.retData[i].documentType=='xlsx')
        // {
        //     img.setAttribute("src","./assets/img/Excel.png");
        // }
        // else if(this.allSubFolders.retData[i].documentType=='application/vnd.openxmlformats-officedocument.presentationml.presentation'||this.allSubFolders.retData[i].documentType=='pptx'||this.allSubFolders.retData[i].documentType=='ppt')
        // {
        //     img.setAttribute("src","./assets/img/ppt.png");
        // }
        // else if(this.allSubFolders.retData[i].documentType=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'||this.allSubFolders.retData[i].documentType=='application/octet-stream' || this.allSubFolders.retData[i].documentType=='doc' ||this.allSubFolders.retData[i].documentType=='docx')
        // {
        //     img.setAttribute("src","./assets/img/word.jpg");
        // }
        // else if((this.allSubFolders.retData[i].documentType=='text/plain') || (this.allSubFolders.retData[i].documentType=='txt'))
        // {
        //     img.setAttribute("src","./assets/img/textt.png");
        // }
        // else if(this.allSubFolders.retData[i].documentType=='video/mp4'||this.allSubFolders.retData[i].documentType=='mp4')
        // {
        //     img.setAttribute("src","./assets/img/nvideo.png");
        // }
        // else
        // {
        //     img.setAttribute("src","./assets/img/document.png");
        // }

        //     img.setAttribute("width","30px");
        //     img.setAttribute("height","30px");
        //     var elm1 =document.createElement("span");
        //     elm1.innerHTML = this.allSubFolders.retData[i].documentName;
        //     elm.appendChild(img);
        //     elm.appendChild(elm1);

        //     mdv.appendChild(elm);

        //    // mdv.addEventListener('click', this.getfolders.bind(this,this.allSubFolders.retData[i].BLK2020022800001));


        //     mdv1.appendChild(mdv);
        // }


        //mdv1.appendChild(mdv);

        elem.appendChild(mdv1);


      });


    }
    else {

      document.getElementById(id1.id).setAttribute("class", "a fa-caret-right");
      let elem = document.getElementById(id);
      while (elem.childNodes.length > 1) {
        elem.removeChild(elem.lastChild);
      }
    }
  }

  Delete(data) {
    var res;
    if (data.folderName == null || data.folderName == undefined) {
      res = confirm("Do You Want to Delete " + data.documentName + "!!!");
    }
    else {
      res = confirm("Do You Want to Delete " + data.folderName + "!!!");
    }
    if (res == true) {
      let info: any;
      if (data.hash) {
        info = {
          Id: data.id,
          FolderID: data.folderId,
          FolderName: data.folderName,
          UserId: this.uid,
          Code: "Document",
          ParentID: data.folderId,
          RoleId: this.roleid,
          Click: null,
          GUID:this.GUID
        };
      }
      else {
        info = data;
        info.GUID=this.GUID

      }
      this.userservice.deletefolder(info).subscribe((data: any) => {
        this.GetAllSharingFolders();
        this.GetAllFolders1();
      });
    }

  }
  ViewImageDoc(aa, content) {
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {
      this.imgdisplay = aa;
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
      });
    }
  }

  ViewDocument(aa, pdftesting) {
    let guid = aa.fguid;
    localStorage.setItem("guid",guid);
    //...... if(guid != undefined){
    // this.router.navigate(["msview"]);
    //....... }
    if(aa.documentType == "xls" || aa.documentType == "xlsx" || 
    aa.documentType == "doc" || aa.documentType == "docx" || aa.documentType == "ppt" ||
    aa.documentType == "pptx"){
      this.router.navigate(["msview"]);
    }

    else{
    if (aa.pdfFile != null) {
      this.ViewDoc(aa)
    }
    else {

      this.zzz = aa;
      this.current_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.zzz.file);
      console.log(this.pdfUrl);
      this.pdfUrl = this.zzz.file;
      // this.doc="http://docs.google.com/gview?"+this.zzz.file+"&embedded=true";

      this.modalService.open(pdftesting).result.then((result) => {

        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.m = this.msg;
        console.log(this.m)

      });
    }
    }
  }
  ViewVideo(cc, videocontent) {
    this.videoplay = cc.file
    this.videodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }

  Viewaudio(cc, videocontent) {
    this.audioplay = cc.file
    this.audiodate = cc.date
    // this.folderviewservice.viewDocument(cc).subscribe((data: any) => {
    //   this.pdfUrl = data;
    this.modalService.open(videocontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
    //});
  }
  // Multi Move
  toggleSelect(data) {
    this.CurrentFiles = data;
    this.checkenable = true;
    this.multi = true;
    this.sfiles = 0;
    this.sfolder = 0;
 
    if (data != null) {
      if (this.selectedSelected.length == 0) {
        // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
        // document.getElementById(data.Id.toString()).style.color = "white";
        this.selectedSelected.push(data);
      }
      else {
        const index = this.selectedSelected.indexOf(data, 0);
        if (index > -1) {
          this.selectedSelected.splice(index, 1);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "";
          // document.getElementById(data.Id.toString()).style.color = "";
        }
        else {
          this.selectedSelected.push(data);
          // document.getElementById(data.Id.toString()).style.backgroundColor = "#4f44c5";
          // document.getElementById(data.Id.toString()).style.color = "white";
        }
      }
      if (this.selectedSelected.length == 0) {
        this.checkenable = false;
        this.multi = false;
      }
    }

    for (var i = 0; i < this.selectedSelected.length; i++) {
      if (this.selectedSelected[i].folderName != null) {
        this.sfolder = this.sfolder + 1;
      }
      else {
        this.sfiles = this.sfiles + 1;
      }
    }


    if (this.sfolder == 0) {
      this.deletemulticontent = this.sfiles + " Files"
    }
    else if (this.sfiles == 0) {
      this.deletemulticontent = this.sfolder + " Folders"
    }
    else {
      this.deletemulticontent = this.sfolder + " Folders " + this.sfiles + " Files"
    }
    for (let index = 0; index < this.selectedSelected.length; index++) {
      if(this.selectedSelected[index].isOwner!=true){
        this.enablemultishare=false;
        break;
      }
      else{
        this.enablemultishare=true;

      }
      
    }
  }
    // Delete Selected Shared Files and Folders
    Deleteselected() {
      var DateTime = new Date();
      const inputRequest= {
        SelectedFiles: this.selectedSelected,
        Message: "Delete",
        UserId: this.uid,
        Email: this.usermail,
        ModifiedOn: DateTime,
        FullName: this.fullname,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null,
        GUID:this.GUID,
      
      }
      //console.log(this.selectedSelected);
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Deleting items with blockchain conversion in progress...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Deleting items with blockchain conversion in progress...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
      this.spinner.show();
      this.userservice.DeleteMultiSharedFiles(inputRequest).subscribe((data: any) => {
        this.Res = data;
        this.spinner.hide();
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Deleting items secured with blockchain completed sucessfully!"
          this.folderCreating = false;
          this.foldercreated = true;
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
            this.morefiles = false;
            this.n = 0;
  
          }.bind(this), 3000);
          //this.notify = "Deleting items successfully within blockchain! "
          this.GetAllSharingFolders();
          this.multi = false;
          // setTimeout(() => this.foladdednotify = true, 100);
          // setTimeout(function () {
          //   this.foladdednotify = false;
          // }.bind(this), 3000);
          setTimeout(() => {
  
          }, 500);
          //this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.notify = "Something went Wrong"
          this.GetAllSharingFolders()
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.foladdednotify = false;
          }.bind(this), 3000);
  
        }
      });
    }
  
    ShareFileMembers(data) {
      for (let index = 0; index < this.frndslist.length; index++) {
        if (this.frndslist[index].friendGUID == data) {
          this.selectedFriend.push(this.frndslist[index])
          break;
        }
      }
    }
    ShareExsistingMultiFolderToUsers() {

      const inputRequest: MultiFolderShareVM = {
        Id: this.shareUniqueID,
        ToUsers: this.selectedFriend,
        USerId: this.uid,
        GUID: this.GUID,
        Code: "MainFolder",
        SelectedFolders: this.selectedSelected,
        AccessType: null,
        BodyContent: null,
        UserName: this.userName,
        ModifiedOn: null
      }
      //this.spinner.show();
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Sharing selected items...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Sharing selected items...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
  
      this.userservice.addMultigMainFolderAndShare(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            this.n = 100;
            this.createdmsg = "Shared items successfully!"
            alert("Shared items successfully!")
            const audit = {
              TransactionName:"File Management",
              TransactionType:"Share",
              TransactionDesc:"File Shared",
              }
             this.auditortransactionservice.AuditorTransactionDetails(audit);
            this.folderCreating = false;
            this.foldercreated = true;
            clearInterval(this.num);
            // this.notify = "Folder Shared Succesfully";
            // setTimeout(() => this.dismissalert = true, 100);
            // setTimeout(function () {
            //   this.dismissalert = false;
            // }.bind(this), 3000);
            // this.spinner.hide();
            setTimeout(function () {
              this.folderCreating = false;
              this.foldercreated = false;
              this.generalprogressbar = false;
              this.errormsg = false;
              this.showper = false;
            }.bind(this), 3000);
            this.selectedSelected = [];
            this.multi = false;
  
          }
          else {
            //alert("Something went wrong!!please try again");
            this.notify = "Something went wrong!!please try again";
            setTimeout(() => this.dismissalert = true, 100);
            setTimeout(function () {
              this.dismissalert = false;
            }.bind(this), 3000);
          }
          this.spinner.hide();
        });
    }
    getBrowserName() {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "Edge";
        case agent.indexOf("opr") > -1 && !!(<any>window).opr:
          return "Opera";
        case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
          return "Chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "Firefox";
        case agent.indexOf("safari") > -1:
          return "Safari";
        default:
          return "Other";
      }
    }
    
  getPercent(per) {
    return 10;
  }
 
  ShareExsistingSubFolderToUsers() {
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
        if (this.n == 90) {
          this.n = 90;
          clearInterval(this.num);
        }
      }
    }, 3000);

    const inputRequest: FolderShareToUers = {
      Id: "0",
      ToUsers: this.selectedFriend,
      BodyContent: "",
      FolderType: this.shareSubFolderFolderType,
      FolderName: this.shareSubFolderFolderName,
      Code: "SubFolder",
      ParentID: this.shareSubFolderParentID,
      FolderID: this.shareSubFolderFolderId,
      UserId: this.uid,
      AccessType: null,
      UserName: this.userName,
      UserEmail: "",
      CreatedOn: "",
      GUID: this.GUID,

    }
    //this.spinner.show();

    // console.log(inputRequest);
    this.userservice.shareExsistingSubFolder(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {

          this.n = 100;
          this.createdmsg = "Shared items successfully"
          alert("Shared items successfully")
          const audit = {
            TransactionName:"File Management",
            TransactionType:"Share",
            TransactionDesc:"File Shared",
            }
           this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.pid = data;
          this.folderCreating = false;
          this.foldercreated = true;
          clearInterval(this.num);

          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          this.GetDetails(this.shareSubFolderParentID);
          this.GetAllFolders1();

          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetDetails(this.shareSubFolderParentID);
          this.GetAllFolders1();

          this.selectedFriend = [];
          this.accid = "";
        }
      });
  }

  ShareOnlyFile(data: any) {
    this.statuschange = "";
    this.statuschange = data;
    this.ShareOnlyDoc = data.file;
    this.BlockId = data.blockId;
    this.FolderID = data.folderId;
    this.ShareOnlyDocName = data.documentName;

  }

  ShareoneFile(data, Sharecontent) {
    this.ShareOnlyFile(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  SharOnlyFileToUsers() {
    this.sendFID = this.FolderID;
    this.sendBID = this.BlockId;
    const inputRequest: ShareOnlyFileVM = {
      FolderID: this.FolderID,
      File: this.ShareOnlyDoc,
      BlockID: this.BlockId,
      FromUserId: this.uid,
      ToUsers: this.selectedFriend,
      DocumentName: this.ShareOnlyDocName,
      UserId: this.uid,
      AccessType: null,
      UserName: this.userName,
      BodyContent: null,
      UserEmail: null,
      CreatedOn: null,
      FromUserGUID: this.GUID
    }
    this.showper = true;
    this.generalprogressbar = true;
    this.folderCreating = true;
    this.Creatingmsg = "Sharing items secured with blockchain...";
    this.getPercent(this.n);
    this.num = setInterval(() => {
      if (this.n <= 90) {
        this.Creatingmsg = "Sharing items secured with blockchain...";
        this.n = this.n + this.getPercent(this.n);
      }
    }, 3000);
    this.userservice.shareOnlyFileinShare(inputRequest).subscribe(
      (data: any) => {
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Shared item successfully within blockchain!";
          alert("Shared item successfully within blockchain!")
          const audit = {
            TransactionName:"File Management",
            TransactionType:"Share",
            TransactionDesc:"File Shared",
            }
           this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.pid = data;
          this.GetDetails(this.sendFID);
          this.GetAllFolders1();

          this.folderCreating = false;
          this.foldercreated = true;
          this.selectedFriend = [];
          clearInterval(this.num);
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
          }.bind(this), 3000);

          this.selectedFriend = [];
        }
        else {
          this.notify = "Something went wrong!!please try again";
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
            this.showper = false;
          }.bind(this), 3000);
          this.selectedFriend = [];
        }
      });
  }
  GetDetails(id) {
    this.spinner.show();
    this.getpath(id);
    this.FileFoldID = id;
    this.userservice.getsubfolders(id).subscribe((respose: any) => {
      this.allSubFolders = respose;
      this.subf = this.allSubFolders.fileName;
      this.fid = this.allSubFolders.parentId;
      // console.log("user", this.allSubFolders);
      localStorage.setItem('retdata', JSON.stringify(this.allSubFolders.retData));
      localStorage.setItem('subfolders', JSON.stringify(this.allSubFolders.subFolders));
      //  localStorage.setItem('parentid',JSON.stringify(this.allSubFolders.ParentId) );
    });
    this.spinner.hide();

  }
  getpath(id) {
    this.userservice.getpath(id).subscribe((respose: any) => {
      this.pathdt = respose;
    });
  }
  GetFriendDetails() {
    this.userservice.friendlist(this.GUID).subscribe((respose: any) => {
      this.Friends = respose;
      this.frndslist = this.Friends.friendsList;
      // console.log(this.frndslist);
      var idd = this.accid;

      for (let i = 0; i < this.frndslist.length; i++) {
        if (idd != undefined) {
          if (idd.includes('@')) {
            if (this.frndslist[i]['email'] == this.accid) {
              for (i = 0; i < 100; i++) {
                window.clearInterval(i);
              }
              alert(this.accid + " has accepted your friend request");
              this.accid = "";
            }
          }
        }
        if (this.frndslist[i]['friendId'] == this.uid) {
          this.frndslist[i]['friendId'] = this.frndslist[i]['userId']
        }
      }
      // console.log(this.frndslist);

    });
  }
  ShareFolder(data, Sharecontent) {
    this.getSubFolderdata(data);
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  addfrnd() {
    if (this.frndrqst == true) {
      this.frndrqst = false;
    }
    else {
      this.frndrqst = true;
    }
  }
  SharewithMoreFriends(Sharecontent) {
    this.GetFriendDetails();
    this.modalService.open(Sharecontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.m = this.msg;
    });
  }
  getSubFolderdata(data) {
    this.shareSubFolderFolderId = data.folderID;
    this.shareSubFolderFolderName = data.folderName;
    this.shareSubFolderFolderType = data.folderType;
    this.shareSubFolderParentID = data.parentID;
    this.shareSubFolderId = data.id;
  }
  Share() {
    this.shareitem = true;
  }
  CancelShare() {
    this.shareitem = false;
  }

  filestosaveinMPF(data){
    this.selectedSelected.push(data);
    this.saveinmyfiles();
  }

  saveinmyfiles(){

  var DateTime = new Date();
      const inputRequest= {
        SelectedFiles: this.selectedSelected,
        Message: "MPF",
        UserId: this.uid,
        Email: this.usermail,
        ModifiedOn: DateTime,
        FullName: this.fullname,
        ipaddress: this.IPAddress,
        dnsip: this.dnsIP,
        browser: this.getBrowserName(),
        transaction: null,
        GUID:this.GUID,
      
      }
      //console.log(this.selectedSelected);
      this.showper = true;
      this.generalprogressbar = true;
      this.folderCreating = true;
      this.getPercent(this.n);
      this.Creatingmsg = "Deleting items with blockchain conversion in progress...";
      this.num = setInterval(() => {
        if (this.n <= 90) {
          this.Creatingmsg = "Deleting items with blockchain conversion in progress...";
          this.n = this.n + this.getPercent(this.n);
          if (this.n == 90) {
            this.n = 90;
            clearInterval(this.num);
          }
        }
      }, 3000);
      this.spinner.show();
      this.userservice.SaveinMPF(inputRequest).subscribe((data: any) => {
        this.Res = data;
        if(this.Res == "1"){
          alert("Saved successfully in MySpace");
          this.GetAllSharingFolders();
        }
        if(this.Res != "1"){
          alert("Something went wrong!!!Please try again.");
        }
        this.spinner.hide();
        if (data != null) {
          this.n = 100;
          this.createdmsg = "Deleting items secured with blockchain completed sucessfully!"
          this.folderCreating = false;
          this.foldercreated = true;
          setTimeout(function () {
            this.folderCreating = false;
            this.foldercreated = false;
            this.generalprogressbar = false;
            this.errormsg = false;
            this.showper = false;
            this.morefiles = false;
            this.n = 0;
  
          }.bind(this), 3000);
          //this.notify = "Deleting items successfully within blockchain! "
          this.GetAllSharingFolders();
          this.multi = false;
          // setTimeout(() => this.foladdednotify = true, 100);
          // setTimeout(function () {
          //   this.foladdednotify = false;
          // }.bind(this), 3000);
          setTimeout(() => {
  
          }, 500);
          //this.spinner.hide();
        }
        else {
          this.spinner.hide();
          this.notify = "Something went Wrong"
          this.GetAllSharingFolders()
          setTimeout(() => this.foladdednotify = true, 100);
          setTimeout(function () {
            this.foladdednotify = false;
          }.bind(this), 3000);
  
        }
      });
    }


}
