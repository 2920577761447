<button class="btn btn-primary" (click)="login()">login</button>

<div >
<form
  #officeForm
  id="officeForm"
  name="officeForm"
  target="officeFrame"
  method="post"
>
  <input name="access_token" [value]="accessToken" type="hidden" />
  <input name="access_token_ttl" [value]="accessTokenTTL" type="hidden" />
</form>

<span #officeFrameholder id="officeFrameholder">
  <!-- <iframe src='https://word-edit.officeapps.live.com/op/embed.aspx?src=https://accountdatastorage.blob.core.windows.net/pictures/Signature and Face Recognition Documentation.docx' name="officeFrame" sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox" width='1366px' height='623px' frameborder='0'>
  </iframe> -->

</span>

</div>