
<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="register">
<h1 style="text-align: center;">Clients List</h1>
<div>&nbsp;</div>
<div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
        <button class="btn btn-primary"  (click)="addClient(addclient)">
            Add
        </button>
    </div>
    <div class="col-sm-9"></div>
</div>
<br />
<div class="col-md-12 row" >
    <div class="col-md-12">
        <div>
            <div  style="overflow-x: visible; height: 300px;">
                <table class="table table-responsive ">
                    <tr class="thead-dark" style="font-size: 17px;">
                        <th>Sl No</th>
                        <th>Client Name</th>
                        <th>Address</th>
                        <th>Contact PersonName</th>
                        <th>Contact Number</th>
                        <th>Office Number</th>
                        <th>Email</th>
                        <th>Created By</th>
                        <th>Created On</th>
                        <th>Modified By</th>
                        <th>Modified On</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                    <tr *ngFor="
              let aa of ClientList
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            " style="font-size: 16px;color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{ aa.clientName }}</td>
                        <td>{{ aa.address }}</td>
                        <td>{{ aa.contactPersonName }}</td>
                        <td>{{ aa.contactNumber }}</td>
                        <td>{{ aa.officeNumber }}</td>
                        <td>{{ aa.email }}</td>
                        <td>{{ aa.createdBy }}</td>
                        <td>{{ aa.createdOn | date: "short" }}</td>
                        <td>{{ aa.modifiedBy }}</td>
                        <td>{{ aa.modifiedOn | date: "short" }}</td>
                        <td>
                            <button class="btn btn-success" (click)="EditClient(aa, updateclient)">
                                Edit
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-danger" (click)="deleteClient(aa, deleteclient)">
                                Delete
                            </button>
                        </td>
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" style="float: right;"></pagination-controls>
                <br /><br />
                <br /><br />
            </div>
        </div>
    </div>
</div>
</div>
<br /><br />



<ng-template #addclient let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Client Name:</h5>
        <input type="text" id="clientname" [(ngModel)]="clientname" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Address:</h5>
        <input type="text" id="address" [(ngModel)]="address" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Contact Person Name:</h5>
        <input type="text" id="contactpersonname" [(ngModel)]="contactpersonname" class="form-control" name="dp" />

        <h5 class="modal-title" id="modal-basic-title">Contact Number:</h5>
        <input type="text" id="contactnumber" [(ngModel)]="contactnumber" class="form-control" name="dp" />

        <h5 class="modal-title" id="modal-basic-title">Office Number:</h5>
        <input type="text" id="officenumber" [(ngModel)]="officenumber" class="form-control" name="dp" />

        <h5 class="modal-title" id="modal-basic-title">Email:</h5>
        <input type="text" id="email" [(ngModel)]="email" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="SaveClient()" (click)="d('Cross click')"
            style="cursor: pointer;">
            Save
        </button>
    </div>
    <br />
</ng-template>
<ng-template #updateclient let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Update Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">Client Name:</h5>
        <input type="text" id="editclientName" [(ngModel)]="editclientName" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Address:</h5>
        <input type="text" id="editaddress" [(ngModel)]="editaddress" class="form-control" name="dp" />
        <h5 class="modal-title" id="modal-basic-title">Contact Person Name:</h5>
        <input type="text" id="editcontactpersonname" [(ngModel)]="editcontactpersonname" class="form-control"
            name="dp" />

        <h5 class="modal-title" id="modal-basic-title">Contact Number:</h5>
        <input type="text" id="editcontactnumber" [(ngModel)]="editcontactnumber" class="form-control" name="dp" />

        <h5 class="modal-title" id="modal-basic-title">Office Number:</h5>
        <input type="text" id="editofficenumber" [(ngModel)]="editofficenumber" class="form-control" name="dp" />

        <h5 class="modal-title" id="modal-basic-title">Email:</h5>
        <input type="text" id="editemail" [(ngModel)]="editemail" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="UpdateClient()" (click)="d('Cross click')"
            style=" cursor: pointer;">
            Update
        </button>
    </div>
    <br />
</ng-template>
<ng-template #deleteclient let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="DeleteClientRow()" (click)="d('Cross click')"
            style="color: black; cursor: pointer;">
            Ok
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')"
            style="color: black; cursor: pointer;">
            Cancel
        </button>
    </div>
    <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close"  (click)="closealert()">
                <i class="material-icons" style="color: white;">close</i>
            </button>
            <span style="color: white; margin-top: 6px;">
                <b style="text-transform: capitalize; font-size: 14px;">
                    {{ notify }}</b></span>
        </div>
    </div>
</div>