import { NgModule, ModuleWithProviders } from '@angular/core';

import { FroalaEditorDirective } from './froala.directive';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaViewModule } from './comp/froalaview';
import { HttpClientModule } from '@angular/common/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [FroalaEditorDirective],

  exports: [FroalaEditorDirective]
})

export class FroalaEditorModule {
  public static forRoot(): ModuleWithProviders<FroalaEditorModule> {
    return {ngModule: FroalaEditorModule, providers: []};
  }
}
