<app-nav-bar></app-nav-bar>
<app-user-sidebar
  *ngIf="(roleid == '2' || roleid == '9') && mobileview"
></app-user-sidebar>

<div>&nbsp;</div>
<div class="register">
  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-info" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showprojects">
              <p class="boxdisplay" (click)="GetUserDashboardTasks(1)">
                No of Projects: <b>{{ userTaskSummary.totalProjects }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
    <br />
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-primary" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay" (click)="GetUserDashboardTasks(2)">
                No of Tasks Assigned: <b>{{ userTaskSummary.tasksAssigned }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br />
  <br />

  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-secondary" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay" (click)="GetUserDashboardTasks(3)">
                Tasks Completed: <b>{{ userTaskSummary.tasksCompleted }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
    <br />
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-success" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay">
                Hours Saved: <b>{{ userTaskSummary.hoursSaved }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br /><br />
  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-warning" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay" (click)="GetUserDashboardTasks(4)">
                Tasks In-Progress: <b>{{ userTaskSummary.tasksInProgress }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
    <br />
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-dark" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay" (click)="GetUserDashboardTasks(5)">
                Tasks In Review: <b>{{ userTaskSummary.tasksInReview }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <br /><br />
  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-primary" style="max-width: 24rem">
          <div class="card-body" style="background: blueviolet">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay" (click)="GetUserDashboardTasks(6)">
                Tasks To-Do: <b>{{ userTaskSummary.tasksToDo }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
    <br />
    <div class="col-md-4">
      <div class="container-fluid">
        <div class="card text-white bg-danger" style="max-width: 24rem">
          <div class="card-body">
            <a pageScroll href="#showhidevalue">
              <p class="boxdisplay">
                Extra Hours Taken: <b>{{ userTaskSummary.extraHoursTaken }}</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
<br /><br />

<div class="panel panel-success" *ngIf="showhidevalue" id="showhidevalue">
  <!-- <div class="col-md-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-4" style="margin-top: 6px;">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                    placeholder="Search Here ">
            </div>
            <div class="col-md-3" style="margin-top: 6px;">
                <select class="form-control dropdownsize" (change)="onSelecteduserName($event.target.value)">
                    <option value=" "> Select User </option>

                    <option *ngFor="let user of userList " value={{user.userName}}>
                        {{user.userName}}
                    </option>
                </select> </div>
            <div class="col-md-3 " style="margin-top: 6px; ">
                <select class="form-control dropdownsize " (change)="onSelectstatus($event.target.value) ">
                    <option value=" "> ALL </option>

                    <option *ngFor="let status of projectstatus" value={{status.id}}>
                        {{status.status}}
                    </option>
                </select> </div>
        </div> -->
  <br />
  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr class="thead-dark">
              <th></th>
              <th>SI No</th>
              <th>User</th>
              <th>Task</th>
              <th>Project</th>
              <th>Assigned Time</th>
              <th>Estimated Time(Hrs)</th>
              <th>Present Status</th>
              <th></th>
            </tr>
            <tr
              *ngFor="
                let task of taskdata
                  | filter: searchText
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              "
            >
              <td>
                <p>&#10004;</p>
              </td>
              <td>{{ 5 * (q - 1) + (i + 1) }}</td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ task.user }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ task.task }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ task.project }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ task.assignedTime }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ task.estimatedTime }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                <p
                  [style.color]="
                    task.presentStatus == 'TO DO'
                      ? 'red'
                      : task.presentStatus == 'DONE'
                      ? 'green'
                      : task.presentStatus == 'IN PROGRESS'
                      ? 'orange'
                      : 'blue'
                  "
                >
                  {{ task.presentStatus }}
                </p>
              </td>
              <td>
                <button
                  type="button"
                  style="background-color: transparent; border: none"
                  class="btn btn-default btn-xs"
                  (click)="TaskPassdata(task, task.id)"
                >
                  <span
                    class="glyphicon glyphicon glyphicon-eye-open text-success"
                    ><i class="fa fa-eye"></i
                  ></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls
        (pageChange)="q = $event"
        style="float: right"
      ></pagination-controls>
    </div>
  </div>
  <br />
</div>

<br />
<div *ngIf="showProjects" id="showprojects">
  <div class="col-lg-12 row">
    <div class="col-md-1"></div>
    <!-- <div class="col-md-4">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                placeholder="Search Here ">
            <br />
        </div> -->
  </div>
  <div class="col-lg-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr class="thead-dark">
              <th>SI No</th>
              <th style="font-family: 'Times New Roman', Times, serif">Name</th>
              <th style="font-family: 'Times New Roman', Times, serif">
                Description
              </th>
              <th style="font-family: 'Times New Roman', Times, serif">Code</th>
              <th style="font-family: 'Times New Roman', Times, serif">Lead</th>
              <th style="font-family: 'Times New Roman', Times, serif">
                Start Date
              </th>
              <th style="font-family: 'Times New Roman', Times, serif">
                Priority
              </th>
              <th style="font-family: 'Times New Roman', Times, serif">
                Estimated Time(Hrs.)
              </th>
              <th></th>
            </tr>
            <tr
              *ngFor="
                let project of UserProjects
                  | filter: searchText
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              "
            >
              <td>{{ 5 * (p - 1) + (i + 1) }}</td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.projectName }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.projectDescription }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.projectCode }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.projectLead }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.startDate | date: "short" }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.priority }}
              </td>
              <td style="font-family: 'Times New Roman', Times, serif">
                {{ project.estimstedTime }}
              </td>

              <td>
                <button
                  type="button"
                  style="background-color: transparent; border: none"
                  class="btn btn-default btn-xs"
                  (click)="ProjPassdata(project, project.uniqueProjectId)"
                >
                  <span
                    class="glyphicon glyphicon glyphicon-eye-open text-success"
                    ><i class="fa fa-eye"></i
                  ></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls
        (pageChange)="p = $event"
        style="float: right"
      ></pagination-controls>
    </div>
  </div>
</div>
<br />
<br />
<br />
<br />
