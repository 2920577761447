import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppGlobals } from 'src/app/app.global';
import { AnalyticsService } from 'src/app/Shared/Analytics/analytics.service';
import { LoginService } from 'src/app/Shared/Login/login.service';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';

@Component({
  selector: 'app-addnewuser',
  templateUrl: './addnewuser.component.html',
  styleUrls: ['./addnewuser.component.css']
})
export class AddnewuserComponent implements OnInit {
  files1: any;
  SelectedFiles: any;
  UserId: any;
  EmailId: any;
  Role: any;
  Username: any;
  fileselect: boolean = true;
  excel: boolean = false;
  csv: boolean = false;
  mssql: boolean = false;
  mysql: boolean = false;
  dbname:any;
  Files: any;
  selectedfiletype:any;
  p: number = 1;
  masterid: any;
  Filesuploaded: any;
  fieldArray: any=[];
  savedusers: any;
  GUID: string;
  Website: any;
  multiusers: any;
  users: any;
  usersemail: any = [];
  usersemail1: any = [];
  email: string;
  emaillist: any;

  

  constructor(private loinservice: LoginService,private httpService: HttpClient, private router: Router, 
    private analyticsService: AnalyticsService,private userservice: UserDetailsService,private _urls: AppGlobals,private spinner: NgxSpinnerService,) {
      if (localStorage.getItem("IsLoggedIn") == 'true') {
        this.UserId = localStorage.getItem("userId");
        this.EmailId = localStorage.getItem("Email");
        this.Role = localStorage.getItem("role");
        this.Username = localStorage.getItem("UserName");
        this.dbname= localStorage.getItem("projname");
        //this.Role = localStorage.getItem("role");
        this.GUID = localStorage.getItem("GUID");

      }
     }

    ngOnInit(): void {
      this.getFile();
      this.GetUserWebsite();
  }
  onChange(event: any) {
    var fileslist = "";
    this.files1 = [].slice.call(event.target.files);
    console.log(this.files1);
    fileslist = this.files1[0];
    this.SelectedFiles = fileslist;
    console.log(this.SelectedFiles)
  }
   Uploadexcel(){
    
    this.spinner.show(); 
    const frmData = new FormData();
    this.masterid = this.dbname + "," + "1";
    // frmData.append("RegData", JSON.stringify(inputRequest));
    frmData.append("picture", this.SelectedFiles);
    frmData.append("masterid",this.masterid);
    frmData.append("userid",this.UserId);

    // frmData.append("logo", inputRequest);   
    this.httpService.post(this._urls.usrmurl+'/api/Multipleuserregistration/UploadFiles', frmData).subscribe(

      data => {
        console.log(data)
        var dt=data+".xlsx"
       if (this.SelectedFiles.name==dt){
        alert("Successful");
        this.getFile();
        window.location.reload()
       }else{
alert("File already exist")
       }
       
        // this.spinner.hide();
        // this.router.navigate(['/login']);
      });
  }
  uploadexceldata(data:any){
    this.spinner.show();
    var ddtt=data+","+"Blocdrive";
    this.httpService.get(this._urls.usrmurl+'/api/Multipleuserregistration/PostInsertExcelRecords/'+ddtt).subscribe(
      (res: any) => {
        this.Files = res;
        alert("Uploaded successfully");
        //this.spinner.hide();
        this.getgeneratedtable(ddtt);
        this.getFile();
        console.log(this.Files);
      }
    )
  }
  getgeneratedtable(data:any){
    //this.spinner.show();
    var datta=data+","+this.GUID
    this.httpService.get(this._urls.usrmurl+'/api/Multipleuserregistration/gettablegenerated/'+datta).subscribe(
      (res: any) => {
        this.Filesuploaded = res;
        this.fieldArray.push(this.Filesuploaded);
     //   this.spinner.hide();
        console.log(this.Filesuploaded);
        this.saveuser(datta)
      }
    )

  }

  saveuser(data:any){
   // this.spinner.show();
    const inputRequest = {
      Fieldss:this.Filesuploaded
    ,  CurrentUserId:this.GUID,
    Website:this.Website,
    Datass:data
    }

    this.httpService.post(this._urls.usrmurl+'/api/Multipleuserregistration/PostSaveUser/',inputRequest).subscribe(
      (res: any) => {
        this.multiusers = res;

        for (var i = 0; i <= this.multiusers.length; i++) {
          if (this.multiusers[i] == null) {
            this.usersemail1.push(this.multiusers[i])
          }
          else if (this.multiusers[i] != null) {
            this.usersemail.push(this.multiusers[i].email)
          }
        }
        if (this.usersemail1.length - 1 == this.multiusers.length) {
          alert("Users creation successful")
        }
        console.log(this.usersemail);

        this.emaillist = "";
        for(var j=0; (j <= Number(this.usersemail.length)-1); j++){
          this.emaillist = this.emaillist+this.usersemail[j] +",";
        }
        console.log(this.emaillist);
        if (this.usersemail != null && this.usersemail != 0) {
          alert("User creation failed for users" + this.emaillist);
        }

        window.location.reload();
      }
    )
  }

  getFile(){
    this.httpService.get(this._urls.usrmurl+'/api/Multipleuserregistration/Get/'+ this.UserId).subscribe(
      (res: any) => {
        this.Files = res;
        console.log(this.Files);
      }
    )
  }
  GetUserWebsite() {
    this.spinner.show();
    this.userservice.getUserWebsite(this.GUID).subscribe((Data: any) => {
      var splitwebsite=Data.companyWebsite.split('//');
      this.Website = splitwebsite[1];

      this.spinner.hide();
    });
  }
}
