<app-nav-bar></app-nav-bar>

<br/><br/><br/><br/><br/>
<div class="col-md-12 row" id="currentpagename" *ngIf="showBreadcrumb">
    <div class="col-md-3">&nbsp;</div>
    <!-- <span id="Breadcrumbone" style="cursor: pointer;">File Requests</span> -->
    <span *ngFor="let d of foldernamelist" id="Breadcrumb">
      &nbsp; <img src="./../../assets/img/Vector122.png" />&nbsp;
      <!-- <p id="angularimg">></p> -->{{d.FolderName}}
      <!-- <span (click)="GetSubfolderdetails(d)" style="cursor: pointer;">{{d.FolderName}}</span> -->
    </span>  
  </div>
<nav class="navbar navbar-expand-lg navbar fixed-top" style="background-color: transparent;">
    <div class="container-fluid">
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
    </div>
  </nav>

    <!-- No File Requests Yet 0th-->
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row" *ngIf="NoFileRequests" id="homePageDiv">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            &nbsp;
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <h2 style="line-height: 84px;color: #7100aa;">
                Introducing File Requests</h2>
            <p id="homePageText">
                Invite anyone to upload files to your blocdrive, <br /> even if they don't have an account.
            </p>
            <br/>
            <button  id="downloadbtn" type="button" (click)="reqFiles()">
                <span data-toggle="modal" data-target="#SendRequest" id="homePageCreateBtn">CREATE FILE REQUEST</span>
            </button>
        </div>
        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
            <img src="./../../assets/img/request.svg" alt="" id="homePageImg">
        </div>
    </div>

    <!-- File Requests Is not null / OR-CR Lists -->
    <div class="col-md-12 row" *ngIf="!NoFileRequests" style="margin-top: 1%;">
       
        <div class="col-md-1" id="multiOperationDotsDiv">
        <img *ngIf="!multi" src="../../../assets/img/Group 360.png" id="ThreeDots">
        <a *ngIf="multi" class="nav-link dropdown" href="javascript:void(0)"
        id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <img src="./../../assets/img/Multiselect.png" id="multiSelectThreeDots" />
        </a> 
        <div class="dropdown-menu dropdown-menu-right"
    aria-labelledby="navbarDropdownMenuLink" id="ShowMultiOperation">
    <a *ngIf="!showdelete" class="dropdown-item" style="cursor: pointer;"
       (click)="CloseMultiRequest()"
        data-toggle="modal">Close</a>
        <a *ngIf="showdelete" class="dropdown-item" style="cursor: pointer;"
       (click)="RemoveClosedMultiRequest()"
        data-toggle="modal">Delete</a>
        </div>
    </div>
    <div class="col-md-9" id="OCDiv">
        <mat-tab-group id="matGroup" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Open Requests">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <tbody *ngFor="let data of OpenReturnDetails" style="color: black">
                            <tr class="show-image" id="{{data.fileRequestId}}">
                                <td>
                                    <input type="checkbox" id="multicheckbox" (click)="toggleSelect(data)">                                
                                </td>
                                <td id="foldernamedisplay">
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-1">
                                            <img src="./assets/img/FRICON.png" style="cursor: pointer;" />
                                        </div>
                                        <div class="col-md-10"  style="cursor: pointer;">
                                            {{data.RequestingFor}}
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 row" style="cursor: pointer;">
                                        <div class="col-md-1">
                                            <img src="./../../assets/img/FRICON.png">
                                        </div>
                                        <div class="col-md-10" id="datadisplay1">
                                            {{data.requestingFor}}
                                        </div>
                                    </div>
                                
                                     <!-- &nbsp;<span id="datadisplay1">{{data.RequestingFor}}</span> -->
                                   
                                </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>
                                        <a class="nav-link dropdown" href="javascript:void(0)" id="navbarDropdownMenuLink"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="cursor: pointer;">
                                            <span *ngIf="!multi" style="color:white;font-size: 15px;cursor: pointer;"><i
                                                    class="fas fa-ellipsis-h" style="color:#4F44C5;"></i></span>
                                            <span *ngIf="multi" style="color:white;font-size: 15px;cursor: pointer;"><i
                                               class="fas fa-ellipsis-h" style="color:#BECBD5;"></i></span>
                                        </a>
                                            <div class="dropdown-menu dropdown-menu-right"
                                                aria-labelledby="navbarDropdownMenuLink" id="ECOptions">
                                                <!-- <a class="dropdown-item" style="cursor: pointer;" data-toggle="modal"
                                                    data-target="#EditRequest" (click)="EditRequest(data)" id="ECFsize">Edit</a> -->
                                                <a class="dropdown-item" style="cursor: pointer;"
                                                    data-toggle="modal"
                                                    (click)="CloseRequest(data)" id="ECFsize">Close
                                                </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br/> <br/> <br/> <br/> <br/> 
                   </div>
                   <br/> <br/> 
            </mat-tab>
            <mat-tab label="Closed Requests">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <tbody *ngFor="let data of ClosedFiles" style="color: black">
                            <tr class="show-image" id="{{data.fileRequestId}}">
                                <td>
                                    <input type="checkbox" id="multicheckbox" (click)="toggleSelect(data)">                                
                                </td>
                                <td id="foldernamedisplay">
                                    <div class="col-md-12 row">
                                        <div class="col-md-1">
                                            <img src="./../../assets/img/iconfinder_531_1926279 2.png" style="cursor: pointer;" />
                                        </div>
                                        <div class="col-md-10" id="datadisplay1" style="cursor: pointer;">
                                            {{data.optionalMessage}}
                                        </div>
                                    </div>
                                
                                    <!-- <img src="./assets/img/iconfinder_531_1926279 2.png">
                                    &nbsp;{{data.RequestingFor}}</td> -->
                                </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                <td>
                                    <a class="nav-link dropdown" href="javascript:void(0)" id="navbarDropdownMenuLink"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        style="margin-top: 12px;">
                                        <!-- <span style="color:white;font-size: 15px;cursor: pointer;"><i
                                                class="fas fa-ellipsis-h" style="color: #4F44C5;"></i></span> -->

                                                <span *ngIf="!multi" style="color:white;font-size: 15px;cursor: pointer;"><i
                                                    class="fas fa-ellipsis-h" style="color:#4F44C5;"></i></span>
                                            <span *ngIf="multi" style="color:white;font-size: 15px;cursor: pointer;"><i
                                               class="fas fa-ellipsis-h" style="color:#BECBD5;"></i></span>

                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="navbarDropdownMenuLink" style="position: absolute;
                                        will-change: top, left;top: 0; left: 0">
                                        <a class="dropdown-item" style="cursor: pointer;"
                                           (click)="RemoveClosedRequest(data)"
                                            data-toggle="modal">Delete</a>
                                            </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br/> <br/> <br/> <br/> <br/> 
                   </div>
                   <br/> <br/> 
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="col-md-2" id="ActionDiv">
        <p id="Actions">Actions</p>
        <hr id="ActionHR">
        <a data-toggle="modal" data-target="#SendRequest" style="cursor: pointer;" (click)="reqFiles()">
            <img src="./../../assets/img/FRICON.png">           
             &nbsp;Request Files</a>
    </div>
</div>

  <!-- notification alert -->
  <div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info"
            style="background-color: #545353;box-shadow: none;left: 245px; position: absolute;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>




<!-- send request modal 1st rajeshwari-->
<div class="modal modal-angular fade" id="SendRequest" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document" style="max-width:562px;overflow-y: 50vh;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" id="filereqText">File Request</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="CancelBtn">
                    <img src="./../../assets/img/close x Purple.png"  (click)="cancelrequest()" >
                    <!-- <span aria-hidden="true"
                        (click)="cancelrequest()">&times;</span> -->
                    </button>
            </div>
            <div class="modal-body" style="margin-top: 5%;">
                <form #SendRequestForm="ngForm" (ngSubmit)="SendRequestTo(SendRequestForm)">
                    <div class="col-md-12 row">
                        <div class="form-horizontal container">
                                <div class="col-md-12 row form-group">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-10" style="margin-top: -6%;" >
                                        <label id="ReqText">What files are you requesting?</label>
                                        <input type="text"  name="requestingfor"
                                            [(ngModel)]="requestingfor" id="reqCSS" />
                                        <br/>
                                        <label id="ReqText">Where should these files go in your blocdrive?</label>
                                        <select (change)="OnSelectFolder($event.target.value)" class="form-control"
                                            id="SelFol"  >
                                            <option value="" id="SelFolder" >Select Folder</option>
                                            <option *ngFor="let fol of Folders" value={{fol.folderID}} [selected]="fol.folderID == requestedfoldername">
                                                {{fol.folderName}}
                                            </option>
                                        </select>
                                        <br/>
                                        <label id="ReqText">Who should receive this request?</label>
                                        <tag-input id="TagInp" 
                                            [ngModel]="users" name="test" [onlyFromAutocomplete]="false"
                                            (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                                            [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                                            [separatorKeyCodes]="[13,188,186,32]">
                                            <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                                            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false"
                                                [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'email'"
                                                [identifyBy]="'aliasUserId'" [autocompleteItems]="UsersList" >
                                                <ng-template let-item="item" let-index="index">
                                                    {{ item.email }}
                                                </ng-template>
                                            </tag-input-dropdown>
                                        </tag-input>
                                        <br/><br/><br/><br/>
                                        <label id="ReqText">Optional message</label>
                                           <textarea type="text" name="optionalmsg" id="OptMsg" 
                                           cols="30" rows="3" [(ngModel)]="optionalmsg"></textarea>

                                        <input type="checkbox" [(ngModel)]="theCheckbox" style="cursor: pointer;"
                                            (change)="OnClickCheckbox($event)" checked />
                                            &nbsp;
                                            <span>                                            
                                                <b id="checkStyle">&nbsp;&nbsp;Create separate private folders for each user </b>
                                            </span>

                                   
                                    </div>
                                    <div class="col-md-12">&nbsp;</div>
                                </div>
                        </div>
                    </div>
                    </form>
            </div>
            <div class="modal-footer" id="footer">
                <div>
                <button type="submit" id="SendBtn" class="btn btn-sm waves-light" mdbWavesEffect
                    data-dismiss="modal" data-toggle="modal" 
                    (click)="SendRequestTo(SendRequestForm)">SEND</button>
                <button type="button" class="btn btn-sm waves-light"
                    id="CancelBtn" data-dismiss="modal"
                    mdbWavesEffect (click)="cancelrequest()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

 <!-- Edit request modal 1st rajeshwari-->
 <div class="modal modal-angular fade" id="EditRequest" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document" style="max-width:562px;overflow-y: 50vh;">
        <div class="modal-content" >
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" id="editfilereqText">Edit Request</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelrequest()" id="CancelBtn">
                    <img src="./../../assets/img/close x Purple.png" >
                    <!-- <span aria-hidden="true" close x Purple.png
                       >&times;</span> -->
                    </button>
            </div>
            <div class="modal-body">
                <form #SendRequestFormToExisitingFile="ngForm">
                    <div class="col-md-12 row">
                        <div class="form-horizontal container">
                                <div class="col-md-12 row form-group">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-10" style="margin-top: -10%;" >
                                        <label id="EditReqText">What files are you requesting?</label>
                                        <input type="text"  name="requestingfor"
                                            [(ngModel)]="requestingfor" id="EditreqCSS" readonly/>
                                       <br/><br/>
                                        <label id="EditReqText">Where should these files go in your blocdrive?</label>
                                        <label id="FolderNameReqText">{{FolderNameInHaspit}}</label>
                                        <br/><br/>
                                        <label id="ReqTextForEditLong">Who should be added or removed from receiving this request?</label>
                                       
                                        <tag-input id="EditTagInp" 
                                            [ngModel]="users" name="test" [onlyFromAutocomplete]="false"
                                            (onRemove)="onItemRemovedFromEdit($event)" (onAdd)="onItemAddedFromEdit($event)"
                                            [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                                            [separatorKeyCodes]="[13,188,186,32]" >
                                            <!-- 13 - enter, 188 - comma ,186 - semicolon -->
                                            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false"
                                                [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'Email'"
                                                [identifyBy]="'AliasUserId'" [autocompleteItems]="UsersList" >
                                                <ng-template let-item="item" let-index="index">
                                                    {{ item.Email }}
                                                </ng-template>
                                            </tag-input-dropdown>
                                        </tag-input>
                                        <br/>
                                                <div id="ShowFRUsers">
                                                    <div class="col-md-12 row" *ngFor="let data of particularusers" style="margin-bottom: -7%; margin-top: 4%;width: 200%;">
                                                        <img src="data:image/png;base64,{{data.UserImage}}" *ngIf="data.UserImage!=null" style="width: 7%;
                                                        border-radius: 100%;
                                                        height: 111%;" (click)=EditUserImage(data)>
                                                        <img src="./../../assets/img/iconfinder_25_2870299 1.png" style="height: 5%;" *ngIf="data.UserImage==null" (click)=EditUserImage(data)>
                                                   
                                                        <p style="color: black;text-transform: none;line-height: 144%;"> &nbsp; {{data.name}}
                                                            &nbsp; 
                                                        </p>
                                                        <p style="color: black;text-transform: lowercase;line-height: 144%;" *ngIf="data.name== '' ||data.name==null">
                                                            {{data.email}}
                                                            &nbsp; 
                                                        </p>
                                                        <img src="./../../assets/img/close x.png" (click)=RemovePersonFromEdit(data) style="
                                                        height: 50%;
                                                        margin-top: 1%;
                                                    cursor: pointer;">
                                                    </div>
                                                </div>
                                                <br/>  <br/><br/><br/> <br/>
                                        <label id="ReqText">Optional message</label>
                                           <textarea type="text" name="optionalmsg" id="EditOptMsg" 
                                           cols="30" rows="3" [(ngModel)]="optionalmsg"></textarea>

                                           <input type="checkbox" name="Checkboxmarked" [(ngModel)]="Checkboxmarked" 
                                           [checked]="Checkboxmarked == true" style="color: #66737E;"  readonly />
                                            &nbsp;
                                            <span>                                            
                                                <b id="checkStyle">&nbsp;&nbsp;Create separate private folders for each user </b>
                                            </span>

                                   
                                    </div>
                                    <div class="col-md-12">&nbsp;</div>
                                </div>
                        </div>
                    </div>
                    </form>
            </div>
            <div class="modal-footer" id="footer">
                <div>
                <button type="submit" id="SendBtn" class="btn btn-sm waves-light" mdbWavesEffect
                    data-dismiss="modal" data-toggle="modal" 
                    (click)="SendRequestToExisitingFile(SendRequestFormToExisitingFile)">SEND</button>
                <button type="button"
                    id="CancelBtn" class="btn btn-danger" data-dismiss="modal"
                    mdbWavesEffect (click)="cancelrequest()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

   
  <!-- OTHER PROGRESS BAR Malleswari-->
<!-- <div class="col-lg-12 row" *ngIf="showper">
    <div class="col-lg-3"></div>
    <div class="col-lg-7" style="top:20%;width: 60%; bottom: 140px;">
        <p *ngIf="errormsg"><b style="color: black;">{{notify}} </b></p>
        <p class="alert alert-info" style="box-shadow: white;background-color: white;" *ngIf="generalprogressbar">
            <b *ngIf="folderCreating && !foldercreated" style="color: black;">{{Creatingmsg}} :{{n}}%</b>

            <img src="./assets/img/tick.png" height="20px;" width="20px;"
                *ngIf="foldercreated && !folderCreating">&nbsp;&nbsp;
            <b style="color: black;" *ngIf="foldercreated &&  !folderCreating">&nbsp;&nbsp; {{createdmsg}} </b>
            <br />
            <progress value={{n}} max="100" style="width: 100%;height: 7px;"> </progress>&nbsp;&nbsp;{{n}}%
        </p>

    </div>
</div> -->



     


    <!-- NEW PROGRESS BAR BY MALLESWARI *ngIf="showper"  *ngIf="generalprogressbar"-->
    <div class="col-lg-12 row" *ngIf="showper">
        <div class="col-lg-3"></div>
        <div class="col-lg-7" id="mainprogressbardiv">
            <p *ngIf="errormsg"><b style="color: #4F44C5;">{{notify}} </b></p>
            <p id="mainprogress" *ngIf="generalprogressbar">
                <b id="msgstyle" *ngIf="folderCreating && !foldercreated">{{Creatingmsg}} </b>
                <b id="progressbarcancel" style="cursor: pointer;" (click)="CloseProgressbar()">Cancel</b>
                <b id="msgstyle" *ngIf="foldercreated &&  !folderCreating">{{createdmsg}} </b>
                <br />
                <progress value={{n}} max="100" id = "progressbarstyle"> </progress>
            </p>
            <p style="text-align: center;margin-top: -2%;" id="percentcolor">{{n}}%</p>
        </div>
    </div>


    
    <!-- NEW UPLOAD FILES PROGRESS BAR MALLESHWARI-->
    <!-- <div class="col-lg-12 row" *ngIf="uploadshowper">
        <div class="col-lg-3"></div>
        <div class="col-lg-7" id="mainprogressbardiv">
            <img id="expandprogressbar" src="./assets/img/expandbtn.png" (click)="minimizeprogressbar()" />
    
            <p style="box-shadow: white;background-color: white;margin-top: 5%;">
                <b id="msgstyle1" *ngIf="!showuploaded &&  showuploading">{{uploadMsg}} </b>
                <b id="msgstyle1" *ngIf="showuploaded &&  !showuploading">{{UploaddoneMsg}} </b>
                <br />
                <progress value={{percent}} max="100" id="progressbarstyle"> </progress>
            </p>
            <p style="text-align: center;margin-top: -2%;" id="percentcolor">{{percent}}%</p>
        </div>
    </div>
     -->
    
    <!-- MINIMIZED BAR FILE BY MALLESWARI -->
    <!-- <div class="col-lg-12 row" *ngIf="minimizedprogressbar">
        <div class="col-lg-10"></div>
        <div class="col-lg-2" style="position: fixed;top: 89%;left: 84%;">
            <p id="footerprogressbar">
                <a id="uploadingtxt"> Uploading Files...</a>
                <img src="./assets/img/minimize.png" style="margin-top:2%;margin-right: 5%;cursor: pointer;
                        float: right;" (click)="viewmainprogressbar()" />
            </p>
        </div>
    </div> -->



    <style>
        progress {
            border: none;
            background: crimson;
            border-radius: 5px;
        }
    
        progress::-webkit-progress-value {
             background: #4F44C5;
            border-radius: 5px;
        }
    
        progress::-webkit-progress-bar {
            background: #D7E1EB;
            border-radius: 5px;
        }
    </style>