import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../Shared/Login/login.service';
import { MessageService } from '../Shared/MessageService';
import * as $ from 'jquery';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { OtpViewModel } from '../ViewModels/OtpViewModel';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });
  email: any;
  password: any;
  isLoginError: boolean;
  result: any;
  mobileview: boolean;
  userdetails: any;
  docdata: any;
  constructor(private spinner: NgxSpinnerService, private messageService: MessageService, private router: Router, private loginService: LoginService,
    private authService: AuthService,private route: ActivatedRoute,
    ) {
      this.route.params.subscribe(params => {
        if (params["id"]) {
          this.userdetails = params["id"];
          localStorage.setItem("UserDetails", this.userdetails);
        }
      });
    this.isMobileMenu();
    
  }

  ngOnInit(): void {
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  OnSubmit() {
    this.email = this.loginForm.controls["email"].value;
    this.password = this.loginForm.controls["password"].value;
    this.spinner.show();
    if (this.email == null || this.email == "") {
      alert("Please enter Your Email");
    }
    else if (this.password == null || this.password == "") {
      alert("Please enter Your Password");
    }
    else {
      this.loginService.userAuthentication(this.email, this.password).subscribe((data: any) => {
        this.result = data;
        localStorage.setItem('IsLoggedIn', "true");
        localStorage.setItem('userToken', data.access_token);
        localStorage.setItem('authorizationData', data);
        localStorage.setItem('Token_type', data.token_type);
        localStorage.setItem("UserFullName", data.fullName);
        localStorage.setItem("role", data.roleName);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("MailId", data.mailId);
        localStorage.setItem("GUID", data.GUID);
        localStorage.setItem("ID",data.id);
        localStorage.setItem("phoneNumber", data.phoneNumber);
        localStorage.setItem("EnterPriseId", data.EnterPriseId);
        localStorage.setItem("FirstName",data.firstName);
        //   localStorage.setItem('authdata', JSON.stringify(movies));
        this.spinner.hide();

        if(data.checkapiconsumer == "Yes")
        {
          localStorage.setItem('IsApiConsumer', "true");
        }
        // send message to subscribers via observable subject
        this.messageService.sendMessage('true');
        this.messageService.sendName(data.userName);
        if(this.userdetails !=null)
        {
          this.getLinks();
        }
        else
        {
          this.router.navigate(['/home']);
        }
        if(localStorage.role!="11" && localStorage.role!="9" && localStorage.role!="0" && localStorage.role!="1"){
          console.log(localStorage.role)
          this.loginService.wheretogo(localStorage.userId).subscribe((response)=>{
            if(response==="paid"){
              this.router.navigate(['/bonlinedashboard']);
            }
            //...... else{
            //   this.router.navigate(['/payment']);
            // }
            else{
              this.router.navigate(['/bonlinedashboard']);
            }
            return;
          })
        }
        this.router.navigate(['/bonlinedashboard']);

      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.isLoginError = true;
        }

      )
    }
  }
  getLinks() {
    var useremail = localStorage.getItem("MailId");
    // localStorage.setItem("ViewFrom", "Email");
    const input = {
      Data: this.userdetails,
      Email: useremail
    }
    this.loginService.getextratSeqlink(input).subscribe((response: any) => {
      this.docdata = response;

      var role = localStorage.getItem("role");
      var loggedin = localStorage.getItem("IsLoggedIn");
      var user = localStorage.getItem("userId");

      if (this.docdata.linkType == "ES" && loggedin == "true" && ((user == this.docdata.toUser) || (useremail == this.docdata.toUser))) {
        let pdfblockid = this.docdata.mailid;    
        localStorage.setItem("esignblockid", this.docdata.esignid);
            localStorage.setItem("PdfblockidRecipients", pdfblockid);
    localStorage.setItem("fromuser", this.docdata.mmail);
    this.router.navigate(['/viewer']);

      }

    },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
        this.isLoginError = true;

      });
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialusers => {  
      console.log(socialusers);  
      let name=socialusers.name.split(" ");
      let fname=name[0];
      let lname=name[1];
      let email=socialusers.email;
      let actk=socialusers.authToken;
      this.savefromsocio(fname,lname,email,actk);
      
      //this.router.navigate(['/home']);  

      //this.Savesresponse(socialusers);  
    });  
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialusers => {  
      console.log(socialusers);  
      let name=socialusers.name.split(" ");
      let fname=name[0];
      let lname=name[1];
      let email=socialusers.email;
      let actk=socialusers.authToken;
      this.savefromsocio(fname,lname,email,actk);
      
      //this.router.navigate(['/home']);  

      //this.Savesresponse(socialusers);  
    });  ;
  } 
  signInWithLI(): void {
    window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81fsghpn98dxwx&redirect_uri=https://blockchainsafe.in/newlogin&state=gfvtfvgfv&scope=r_liteprofile%20r_emailaddress%20w_member_social","_self");
    }
  
    savefromsocio(fname,lname,email,actk)
    {
      const inputRequest: OtpViewModel = {
        Id: 0,
        Email: email,
        Otp: "1",
        FirstName: fname,
        LastName: lname,
        UserName: null,
        PhoneNumber: null,
        Auditor:null,
        Password: null,
        ConfirmPassword: null,
        ProvinceId :null ,
        PlanId: null,
        AccessToken:actk
      }
      this.loginService.LoginVerification(inputRequest).subscribe(
        (data: any) => {
          if (data != null) {
            localStorage.setItem('IsLoggedIn',"true");
                    localStorage.setItem('userToken',data.AccessToken);
                    localStorage.setItem("UserName",data.userName);
                    localStorage.storage = data.storage;
    
                    localStorage.setItem("role",data.role);
                    localStorage.setItem("userId",data.userId);
                    localStorage.setItem("area",data.area);
                    localStorage.setItem("isMember",data.IsMember);
                    localStorage.setItem("GUID",data.guid);
                    this.messageService.sendMessage('true');
                    this.messageService.sendName(data.userName);

            alert("Login Succesfull");
            localStorage.loadingpage = "true";
            
            this.router.navigate(['/allfolders']);
          }
        
          else {
            this.router.navigate(['/indregister']);
          }
        });
    }
}
