<app-nav-bar></app-nav-bar>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row"  >
    <div class="col-md-1 row">&nbsp;</div>
    <div class="col-md-11 row">
        <table class="table table-responsive" style="border-collapse: collapse;">
            <tr class="thead-dark" style="font-size: 17px">
                <th>Id</th>
                <th>Customer Id</th>
                <th>Enterprise Id</th>
                <th>GuId</th>
                <th>User Name</th>
                <th>MailId</th>
                <th>Browser Name</th>
                <th>IP Address</th>
                <th>Transaction Name</th>
                <th>Transaction Type</th>
                <th>Transaction Description</th>
                <th>Transaction Date</th>
                <th>View</th>

            </tr>

            <tr *ngFor="   let aa of auditordetails    | paginate: { itemsPerPage: 5, currentPage: p }; let i = index  "
                style=" font-size: 16px;
                color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                <td>{{ 5 * (p - 1) + (i + 1) }}</td>

                <td>{{ aa.customerId }}</td>
                <td>{{ aa.enterpriseId }}</td>
                <td>{{ aa.guId }}</td>
                <td>{{ aa.userName }}</td>
                <td>{{ aa.mailId }}</td>
                <td>{{ aa.browserName }}</td>
                <td>{{ aa.ipAddress }}</td>
                <td>{{ aa.transactionName }}</td>
                <td>{{ aa.transactionType }}</td>
                <td>{{ aa.transactionDesc }}</td>
                <td>{{ aa.transactionDate }}</td>
                <td>

                    <div *ngIf="aa.transactionName == 'File Management' ">
                        <!------------------filesview---------------->
                        <button class="btn btn-primary" *ngIf="aa.documentType == 'pdf' && aa.documentName == null "
                            (click)="ViewDocument(aa, pdftesting)"
                            style="background-color: purple; color: white;">View</button>
                        <button class="btn btn-primary" *ngIf="aa.documentType == 'txt' && aa.documentName == null"
                            (click)="ViewDocumenttxt(aa, txttesting)"
                            style="background-color: purple; color: white;">View</button>
                        <button class="btn btn-primary"
                            *ngIf="aa.documentType == 'jpeg' || aa.documentType == 'png' || aa.documentType == 'jpg' && aa.documentName == null " 
                            (click)="ViewImageDoc(aa, content)" style="
                                   background-color: purple;
                                   color: white;">View</button>
                        <button class="btn btn-primary" *ngIf="aa.documentType == 'ppt' || aa.documentType == 'pptx' && aa.documentName == null"
                            (click)="ViewPpt(aa, ppttesting)"
                            style="background-color: purple; color: white;">View</button>
                        <button class="btn btn-primary" *ngIf="aa.documentType == 'doc' || aa.documentType == 'docx' && aa.documentName == null"
                            (click)="ViewDocx(aa, doctest)"
                            style="background-color: purple; color: white;">View</button>
                        <button class="btn btn-primary" *ngIf="aa.documentType == 'xlsx' || aa.documentType == 'xls' && aa.documentName == null"
                            style="background-color: purple; color: white;"
                            (click)="ViewExcel(aa, excelview)">View</button>


                    </div>
 <div *ngIf = "aa.documentName != null">
    <button class="btn btn-primary" 
    style="background-color: purple; color: white;"
    (click)="ViewDeletefilename(aa, deleteview)">delete</button>
 </div>
                   
                    <button class="btn btn-primary" *ngIf="aa.transactionDesc == 'Compose Mail' || aa.transactionDesc == 'Reply Mail' || aa.transactionDesc == 'Forwarded Mail'  "
                        style="background-color: purple; color: white;" (click)="ViewMail(aa,mailview)">View</button>

                    <button class="btn btn-primary" *ngIf="aa.transactionDesc == 'Message' "
                     style="background-color: purple; color: white;" (click)="ViewChat(aa,chatview)">View</button>


                     <div  *ngIf="aa.transactionName == 'Project Management' ">

                        <button class="btn btn-primary"
                        style="background-color: purple; color: white;" (click)="ViewClient(aa,clientview)">View</button>

                     </div>
                     <div  *ngIf="aa.transactionDesc == 'User Master' || aa.transactionType == 'send' && aa.transactionType != 'Update'  ">

                        <button class="btn btn-primary"
                        style="background-color: purple; color: white;" (click)="ViewUser(aa,userview)">View</button>

                     </div>
                     <div  *ngIf="aa.transactionDesc == 'Folder' || aa.transactionDesc == 'Sub Folder Creation' ">

                        <button class="btn btn-primary"
                        style="background-color: purple; color: white;" (click)="ViewFolder(aa,folderview)">View</button>

                     </div>


                </td>

            </tr>
        </table>
        <br />
        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
        <br /><br />
        <br /><br />



        <!-- -----------------------pdfview--------------------------- -->
        <ng-template #pdftesting let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h6 style="text-align: center;">{{ zz.documentName }} <br /></h6>
                <!-- <h6 style="float: left;">
                            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zz.date | date: "short" }}
                        </h6> -->

                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                <br /><br />

                <div class="col-lg-12 row">
                    <div class="col-lg-5"></div>
                    <!-- <div class="col-lg-6">
                                <a [href]="pdfUrl"><button class="btn btn-info">
                                        <i class="fa fa-download"></i>Download
                                    </button>
                                </a>
                            </div> -->
                </div>
            </div>
        </ng-template>


        <!-- -----------------------pptview--------------------------- -->

        <ng-template #ppttesting let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h6 style="text-align: center;">{{ ppt }} <br /></h6>
                <!-- <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ ppt.date | date: "short" }}
        </h6> -->

                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                <br /><br />

                <div class="col-lg-12 row">
                    <div class="col-lg-5"></div>
                    <!-- <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div> -->
                </div>
            </div>
        </ng-template>

        <!-- -------------------------textview--------------- -->
        <ng-template #txttesting let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h6 style="text-align: center;">{{ zzzz.documentName }} <br /></h6>
                <!-- <h6 style="float: left;">
                          &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzzz.date | date: "short" }}
                      </h6> -->

                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                <br /><br />

                <div class="col-lg-12 row">
                    <div class="col-lg-5"></div>
                    <!-- <div class="col-lg-6">
                              <a [href]="pdfUrl"><button class="btn btn-info">
                                      <i class="fa fa-download"></i>Download
                                  </button>
                              </a>
                          </div> -->
                </div>
            </div>
        </ng-template>

        <!-- -----------------imageview------------------ -->

        <ng-template #content let-d="dismiss" let-c="dismiss">
            <div class="modal-header">
                <div>&nbsp;</div>
                <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
                <!-- <h6 style="float: left;">
                          Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
                      </h6> -->
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

                <br /><br />
                <!-- <div class="col-lg-12 row">
                          <div class="col-lg-5"></div>
                          <div class="col-lg-6">
                              <a [href]="imgdisplay.file" style="cursor: pointer;"><button class="btn btn-info">
                                      <i class="fa fa-download"></i>Download
                                  </button>
                              </a>
                          </div>
                      </div> -->
            </div>
        </ng-template>


        <!-- ---------------------docview--------------------- -->
        <ng-template #doctest let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h6 style="text-align: center;">{{ doc.documentName }} <br /></h6>
                <!-- <h6 style="float: left;">
              &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ doc.date | date: "short" }}
          </h6> -->

                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                <br /><br />

                <div class="col-lg-12 row">
                    <div class="col-lg-5"></div>
                    <!-- <div class="col-lg-6">
                  <a [href]="pdfUrl"><button class="btn btn-info">
                          <i class="fa fa-download"></i>Download
                      </button>
                  </a>
              </div> -->
                </div>
            </div>
        </ng-template>


        <!-- ---------------------excelview--------------------- -->
        <ng-template #excelview let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h6 style="text-align: center;">{{ excel.documentName }} <br /></h6>
                <!-- <h6 style="float: left;">
              &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ excel.date | date: "short" }}
          </h6> -->

                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                <br /><br />

                <div class="col-lg-12 row">
                    <div class="col-lg-5"></div>
                    <!-- <div class="col-lg-6">
                  <a [href]="pdfUrl"><button class="btn btn-info">
                          <i class="fa fa-download"></i>Download
                      </button>
                  </a>
              </div> -->
                </div>
            </div>
        </ng-template>

        <!-- -----------------------mailview--------------------------- -->
        <ng-template #mailview let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h3 style="text-align: left;" >MAIL<br /></h3>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <div class="col-lg-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: black;font-size:12px;">
                   <p style="font-size: 20px;">
                <strong class="primary-font">Subject : </strong>{{ mailsub }}
                   <br><br>
               <strong class="primary-font">Message : </strong>{{ mailmsg }}
            </p>

                <div  *ngIf="showdoc">
                    <div *ngFor="let do of maildoc" style="font-size: 20px;"><strong class="primary-font">Document Name:</strong> {{  do.documentName }} <br /></div>
                </div>
                <br>
                <div class="">
                <strong class="primary-font" style="font-size: 20px;">To User:</strong>
                <div *ngFor="let do of tousernamelist" style="font-size: 18px; line-height: 1rem;"><br />{{  do.useName }}<br> </div>
            </div>
            <br>
            </div>

            </div>

        </ng-template>
  <!-- -----------------------Deletefile--------------------------- -->
        <ng-template #deleteview let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <!-- <h6 style="text-align: center;">{{ mail }}<br /></h6> -->
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">

                <h6>Deleted File: <br /><br />{{ DocName }} <br /></h6>
                <br>

            </div>
        </ng-template>

  <!-- -----------------------chatview--------------------------- -->
        <ng-template #chatview let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <h6 style="text-align: center;font-size: 30px;">Chat<br /></h6>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white; overflow-y: auto;max-height: 500px;" >

                <div class="col-md-12" *ngFor="let c of chatdatalist">

                    <div class="col-md-12" *ngIf="c.chatFrom != chatfromguid">

                      <div class="chat">
                        <br /><br />
                        <div>

                          <div class="left clearfix">

                            <div class="chat-body clearfix">

                                <!-- <div  *ngIf="c.chatFrom != chatfromguid ">
                                  <p>{{c.toName}}</p>
                                </div>
                                <div  *ngIf="c.chatFrom = chatfromguid ">
                                   <p> {{c.chatFromName}}</p>
                                </div> -->
                              <span style="

                                      color: black;
                                      padding: 8px 8px;
                                      border-radius: 20px;
                                      font-size: 21px;
                                    ">
                                {{ c.chat }}
                              </span>
                              <p>
                                {{ message }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12" style="float: right" *ngIf="c.chatFrom == chatfromguid">

                      <div>

                        <br /><br />
                        <div style="float: right">
                          <div style="float: right">

                            <div>
                                <div>{{c.chatFromName}}</div>
                              <span style="

                                      color: black;
                                      padding: 8px 8px;
                                      border-radius: 20px;
                                      font-size: 21px;
                                    ">
                                {{ c.chat }}
                              </span>
                              <p>
                                {{ message }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <br>

            </div>
        </ng-template>

  <!-- -----------------------clientview--------------------------- -->

        <ng-template #clientview let-d="dismiss" let-c="dismiss">
            <div class="modal-header" style="background-color: white;">
                <div>&nbsp;</div>
                <!-- <h3 style="text-align: center;">Client<br /></h3> -->
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: white;">
                <div class="col-lg-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: black;font-size:12px;">

                    <div  *ngIf="showclient">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Client Name : </strong>{{ cliname }}
                       <br><br>
                   <strong class="primary-font">Client Email : </strong>{{ cliemail }}
                   <br><br>
                   <strong class="primary-font">Contact Number : </strong>{{ cliaddress }}
                   <br><br>
                   <strong class="primary-font">Address : </strong>{{ cliconNo }}
                   <br>
                </p>
            </div>
            <div  *ngIf="showproject">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Project Name : </strong> {{ proname }}
                       <br><br>
                   <strong class="primary-font">Project Description : </strong>{{ prodesc }}
                   <br><br>
                   <strong class="primary-font">Purpose Of Project : </strong>{{ propurpose }}
                   <br><br>
                   <strong class="primary-font">Objectives : </strong>{{ proobj }}
                   <br>
                </p>
            </div> 
           
            <div  *ngIf="showaddtask">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Task : </strong> {{ addtaskname }}
                       <br><br>
                   <strong class="primary-font">Project : </strong>{{ projname }}
                   <br><br>
                   <strong class="primary-font">Priority : </strong>{{ priority }}
                   <br><br>
                   <strong class="primary-font">Issue type : </strong>{{ proissue }}
                   <br><br>
                   <strong class="primary-font">Present Status : </strong>{{ prestatus }}
                   <br>
                </p>
            </div>

            <div  *ngIf="showassigntask">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Project Name : </strong> {{ assignproname }}
                       <br><br>
                   <strong class="primary-font">Assigned To : </strong>{{ Touser }}
                   <br><br>
                   <strong class="primary-font">Comments : </strong>{{ Comments }}
                   <br><br>
                   <strong class="primary-font">Assigned Time: </strong>{{ AssignedTime }}
                   <br>
                   
                </p>
            </div>

            <div  *ngIf="showcliname">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Client Name : </strong> {{ cliname }}
                       <br>
                   
                </p>
            </div>
            <div  *ngIf="showaddtaskname">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Task Name : </strong> {{ addtaskname }}
                       <br>
                   
                </p>
            </div>
            <div  *ngIf="showproname">
                <p style="font-size: 20px;">
                    <strong class="primary-font">Project Name : </strong> {{ proname }}
                       <br>
                   
                </p>
            </div>
                <!-- <h6>Deleted File: <br /><br />{{ DocName }} <br /></h6> -->
                <br>
            </div>
            </div>
        </ng-template>

<!-- -----------------------userview--------------------------- -->
        
<ng-template #userview let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <!-- <h5 style="text-align: center;">User Master<br /></h5> -->
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <div class="col-lg-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: black;font-size:12px;">

       <div  *ngIf="showuser">
        <p style="font-size: 20px;">
            <strong class="primary-font">User Name : </strong>{{ uname }}
               <br><br>
           <strong class="primary-font">User Email : </strong>{{ email }}
           <br><br>
           <strong class="primary-font">Role : </strong>{{ Role }}
           <br><br>
           <strong class="primary-font">Location : </strong>{{ Location }}
           <br>
        </p>
   </div>
   <div  *ngIf="showdeluser">
    <p style="font-size: 20px;">
        <strong class="primary-font">User Name : </strong>{{ uname }}
           <br>
    </p>
</div>
<div  *ngIf="showfilereq">
    <p style="font-size: 20px;">
        <strong class="primary-font">Requesting For : </strong>{{ reqdata }}
           <br><br>
       <strong class="primary-font">Requested MailId : </strong>{{ reqmailid }}
       <br><br>
       <strong class="primary-font">Optional Message : </strong>{{ reqmsg }}
       <br>
    </p>
</div>

        </div>
    </div>
</ng-template>


  <!-- -----------------------folderview--------------------------- -->
  <ng-template #folderview let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <!-- <h6 style="text-align: center;">{{ mail }}<br /></h6> -->
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <div class="col-lg-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: black;font-size:12px;">

        <div  *ngIf="showfol">
            <p style="font-size: 20px;">
                <strong class="primary-font">Folder Name : </strong>{{ FolderName }}
                   <br><br>
            </p>
       </div>
       <div  *ngIf="showsubfol">
        <p style="font-size: 20px;">
            <strong class="primary-font">Sub Folder Name : </strong>{{ SubFolname }}
               <br><br>
        </p>
   </div>
    </div>
    </div>
</ng-template>
    </div>

</div>