
<!-- <app-nav-bar></app-nav-bar>


<div>
    <div class="col-md-12 row" *ngIf="foladdednotify">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-4">
            <div class="alert alert-info" style="
          background-color: #545353;
          box-shadow: none;
          left: 245px;
          position: fixed;
          top: 150px;
          width: 315px;
          height: 15px;
          border-radius: 10px;
          text-align: center;
          z-index: 10000;
        ">
                <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <i class="material-icons" style="color: rgb(0, 0, 0);">close</i>
        </button>
                <span style="color: rgb(0, 0, 0); margin-top: 7px;">
          <b style="
              text-transform: capitalize;
              font-size: 14px;
              line-height: 15px;
            ">
            {{ notify }}</b></span>
            </div>
        </div>
    </div>
    

    <div class="col-md-12 row" *ngIf="SearchEnabled" style="padding: 0; margin-left: 0; margin-right: 0;">
        <div class="col-md-12 row" style="padding: 0; margin-left: 0; margin-right: 0;">


            <div class="col-md-3" style="justify-content: center;">
                <br /><br />

                


                <div style=" text-align: center;">

                    <div style="width: 100%;">
                        <div class="btn btn-warning">
                            <span data-toggle="modal" data-target="#exampleModal" (click)="openmodal()">
                
                  Upload Object
                </span>
                        </div><br><br>

                        <label class="btn btn-primary">
                  <b class="temp-upload-icon" for="excel-upload">
                    Upload Folder</b>
    
                  <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                    (change)="filesPicked(folderInput.files)" webkitDirectory />
                 
                </label>
                    </div>
                    <div><br>
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                    Move Here
                  </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                      title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info">
                            <div style="border: none; background: transparent; display: block;" (click)="addSubFolder('allSubFolders', subfoldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; " (click)="addSubFolder(allSubFolders, subfoldercontent)">Create Sub Folder</b>
                            </div>
                        </div>
                        <div *ngIf="role == 11" style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                            <br>
                            <div class="btn btn-success"> <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; " (click)="addnewFolder('folderview', addclient)">&nbsp;Add New Client</b>
                            </div>
                        </div>

                    </div>

                </div>


                <div id="treeview" *ngIf="!mobile" style="
                background-color: #ffffff;
                height: 15vh;
               
                margin-top: 30px;
                z-index: 1;
                overflow-y: scroll;
                overflow-x: hidden;
              ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 20px;">
                        <div *ngFor="let a of SearchResult.folders" id="{{ a.folderID }}" style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgb(0, 0, 0);
              ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                    style="cursor: pointer;"></i>&nbsp;
                  <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                    style="cursor: pointer;" />
                  <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                    title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" autofocus class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">


                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 col-lg-2" (click)="getpdfdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/pdf.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">PDF<span> <br> <small></small></span>
                                                    </h5>


                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getpngdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/png.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Images <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="gettxtdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/txt.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Text Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getzipdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/zip.png" alt="icon" style="height: 96px;">
                                                    <h5 class="mt-3 mb-0">Zipped Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getsqldocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/sql.png" alt="icon">
                                                <h5 class="mt-3 mb-0">SQL Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getmpdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/mp4.png" alt="icon">
                                                <h5 class="mt-3 mb-0"> Videos <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex align-items-center">
                            <div>

                                <h5 class="mb-0" [routerLink]="['/allfolders']"> <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> My Objects</h5>
                            </div>

                            <div class="ms-auto">
                                <div class="col-md-12 text-right" style="height: 20px;">
                                    <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);;" title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                                    <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);;" (click)="gridview(1)" aria-hidden="true" title="Grid View"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <div style="
            padding: 0;
          
            height: 70vh;
           
            vertical-align: top;
          ">

                    <div class="table-responsive mt-3" style="  overflow-y: scroll;height: 66vh;">
                        <table class="table table-striped table-hover table-sm mb-0">

                            <thead style="color: #33383e;">

                                <th *ngIf="mob"></th>
                                <th *ngIf="!mob" style="width: 20px;"></th>
                                <th *ngIf="mob" style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
<i class="fa fa-caret-down" aria-hidden="true"></i>
<i class="fa fa-caret-up" aria-hidden="true"></i>
</span>
                                </th>
                                <th *ngIf="!mob" style="font-weight: bold; cursor: pointer; width: 30%;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
<i class="fa fa-caret-down" aria-hidden="true"></i>
<i class="fa fa-caret-up" aria-hidden="true"></i>
</span>
                                </th>
                                <th style="font-weight: bold; cursor: pointer;" *ngIf="mob" (click)="sortbydate()">
                                    Modified
                                    <span class="fa fa-stack">
<i class="fa fa-caret-down" aria-hidden="true"></i>
<i class="fa fa-caret-up" aria-hidden="true"></i>
</span>
                                </th>
                                <th style="font-weight: bold;" *ngIf="mob">Type</th>
                                
                            </thead>
                            <tbody *ngFor="let folderview of SearchResult.folders">
                                <tr>
                                    <td *ngIf="mob" style="text-align: center;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="!mob" style="text-align: center; width: 20px;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="mob" style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="!mob" style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="mob">{{ folderview.createdOn | date: "short" }}</td>
                                    <td *ngIf="mob">Object Folder</td>


                                    <td *ngIf="!multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer;"><i
    class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="ShareFolder(folderview, shareSingleFolder)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="DownloadFolder(folderview)" style="cursor: pointer;">Download</a>
                                                <a class="dropdown-item" (click)="UpdateSubFolder(folderview, editsubfolder)" style="cursor: pointer;">Edit</a>
                                                <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="Delete(folderview)" style="cursor: pointer;">Delete</a>

                                            </div>
                                        </div>

                                    </td>

                                    <td *ngIf="multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                                                <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)" style="cursor: pointer;">Delete</a>
                                                <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="CheckDownloadMultiple()" style="cursor: pointer;">Download</a>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngFor="let ret of SearchResult.files">
                                <tr>
                                    <td *ngIf="mob">
                                        <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="!mob" style="width: 18px;">
                                        <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'image/jpeg' ||
ret.documentType == 'image/png') &&
mob
">
                                        <img src="{{ ret.file }}" style="width: 15px; height: 40px; cursor: pointer;" (click)="ViewDocument(ret,pdftesting)" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'application/pdf' ||
ret.documentType == 'pdf') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/pdficon.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.documentType == 'xlsx') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.documentType == 'ppt' ||
ret.documentType == 'pptx') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'video/mp4' ||
ret.documentType == 'mp4') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.documentType == 'doc' ||
ret.documentType == 'docx') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'application/x-zip-compressed' ||
ret.documentType == 'zip') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'text/plain' ||
ret.documentType == 'txt') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.documentType == 'audio/mp3' ||
ret.documentType == 'mp3') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'image/jpeg' ||
ret.documentType == 'image/png') &&
!mob
">
                                        <img src="{{ ret.file }}" style="width: 15px; height: 40px; cursor: pointer;" (click)="ViewDocument(ret,pdftesting)" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'application/pdf' ||
ret.documentType == 'pdf') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/pdficon.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.documentType == 'xlsx') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.documentType == 'ppt' ||
ret.documentType == 'pptx') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'video/mp4' ||
ret.documentType == 'mp4') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.documentType == 'doc' ||
ret.documentType == 'docx') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'application/x-zip-compressed' ||
ret.documentType == 'zip') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'text/plain' ||
ret.documentType == 'txt') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.documentType == 'audio/mp3' ||
ret.documentType == 'mp3') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.documentName }}">
&nbsp;&nbsp;{{ ret.documentName }}
</span>
                                    </td>
                                    <td [hidden]="
ret.documentType == 'text/plain' ||
ret.documentType == 'txt' ||
ret.documentType == 'application/x-zip-compressed' ||
ret.documentType == 'zip' ||
ret.documentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.documentType == 'doc' ||
ret.documentType == 'docx' ||
ret.documentType == 'video/mp4' ||
ret.documentType == 'mp4' ||
ret.documentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.documentType == 'ppt' ||
ret.documentType == 'pptx' ||
ret.documentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.documentType == 'xlsx' ||
ret.documentType == 'application/pdf' ||
ret.documentType == 'image/jpeg' ||
ret.documentType == 'image/png' ||
ret.documentType == 'pdf' ||
ret.documentType == 'audio/mp3' ||
ret.documentType == 'mp3'
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/anyf1.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
                                    </td>

                                    <td *ngIf="mob">{{ ret.date | date: "short" }}</td>
                                    <td *ngIf="mob">{{ ret.documentType }}</td>
                                    
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>




        </div>
    </div>

    <div class="col-md-12" *ngIf="!SearchEnabled && gridvalue">

        <div class="col-md-12 row" style="padding: 0;">


            <div class="col-md-3" style="justify-content: center;">
                <br /><br />

                

                <div style=" text-align: center;">
                    <div class="btn btn-primary">

                        <label>
        <b class="temp-upload-icon" for="excel-upload">Upload
          Folder</b>
    
          <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
            (change)="filesPicked(folderInput.files)" webkitDirectory />
        
      </label>
                    </div>
                    <br><br>
                    <div style=" text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
          Move Here
        </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
            title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Create
          Folder</b>
                            </div>
                        </div> <br><br>

                        <div  style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                            <div class="btn btn-success" style="width:40%;"> <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                            </div>
                        </div>
                        <br><br>
                       

                    </div>
                </div>
                <div id="treeview" *ngIf="!mobile" style="
                background-color: #ffffff;
    
                height: 70vh;
                margin-top: -5px;
                z-index: 1;
                overflow-y: scroll;
                overflow-x: hidden;
              ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 30px;">
                        <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
                    white-space: nowrap;
                    width: auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: rgb(0, 0, 0);
                  ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                      style="cursor: pointer;"></i>&nbsp;
                    <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                      style="cursor: pointer;" />
                    <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                      title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">

                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <div class="d-flex align-items-center">
                            <div>
                                <h5 class="mb-0"> <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> My Objects</h5>
                            </div>

                        </div>
                        <div class="col-md-12 text-right" style="height: 20px;">
                            <button (click)="open(shareall)" type="submit" class="btn btn-primary">
    Share All
    </button> &nbsp;
                            <button (click)="sortby()" style="background: transparent; border: none; cursor: pointer;">
    Name
    <span class="fa fa-stack">
    <i class="fa fa-caret-down" aria-hidden="true"></i>
    <i class="fa fa-caret-up" aria-hidden="true"></i>
    </span>
    </button> &nbsp;
                            <button (click)="sortbydate()" style="background: transparent; border: none; cursor: pointer;">
    Date
    <span class="fa fa-stack">
    <i class="fa fa-caret-down" aria-hidden="true"></i>
    <i class="fa fa-caret-up" aria-hidden="true"></i>
    </span>
    </button> &nbsp;
                            <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);;" title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                            <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);;" (click)="gridview(1)" aria-hidden="true" title="Grid View"></i>
                        </div>

                        <br><br>
                        <div style=" padding: 0;">


                            <div style="padding: 0; height: 94vh;">

                                <div class="col-md-12 row" style=" margin-top: 60px;padding: 0;
                                overflow-y: scroll;
                                height: 94vh;
                              
                                vertical-align: top;">
                                    <div class="column col-md-3" *ngFor="let folderview of allFolders">
                                        <div class="show-image" style="    text-align: center;">



                                            <div id="menuContainer">
                                                <div class="menuItem first">
                                                    <i class="fa-4x fa fa-folder-open" aria-hidden="true" (click)="GetSubfolder(folderview)" style="left: 0; cursor: pointer; color: rgb(84 118 254);;"></i>

                                                    <div style="
                                            white-space: nowrap;
                                            width: auto;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            cursor: pointer;
                                          " id="foldername" (click)="GetSubfolder(folderview)" title="{{ folderview.folderName }}">
                                                        {{ folderview.folderName }}
                                                    </div>
                                                </div>
                                                <div id="settingsMenu">
                                                    <div ngbDropdown class="dropdown" placement="bottom-right">
                                                        <a class="nav-link" id="dropdownBasic1" style="cursor: pointer;    text-align: right;" ngbDropdownToggle><i
    class="fa fa-gear" aria-hidden="true" ></i></a>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">

                                                            <a class="dropdown-item" *ngIf="shareitem" (click)="UpdateFolder(folderview, editfolder)" data-target="#basicExample" data-toggle="modal" style="cursor: pointer;">Edit</a>
                                                            <a class="dropdown-item" *ngIf="shareitem" (click)="MoveTo(folderview, 'Doc')" style="cursor: pointer;">Move</a>

                                                            <a class="dropdown-item" *ngIf="shareitem" (click)="Delete(folderview)" style="cursor: pointer;" data-toggle="modal" data-target="#deleteExample">Delete</a>
                                                            <a class="dropdown-item" (click)="showfolderdetails(folderview, folderdetails)" data-target="#Details" data-toggle="modal" style="cursor: pointer;">Details</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>







        </div>
    </div>


    <div class="col-md-12 row" *ngIf="!SearchEnabled && listvalue" style="padding: 0;">

        <div class="col-md-12 row" style="padding: 0;" *ngIf="displaydesign">


            <div class="col-md-3" style="justify-content: center;">
                <br /><br />

               


                <div style=" text-align: center;">
                    <div class="btn btn-primary" style="width: 40%;">

                        <label>
                <b class="temp-upload-icon" for="excel-upload">Upload
                  Folder</b>
  
                <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                  (change)="filesPicked(folderInput.files)" webkitDirectory />
                
              </label>
                    </div>
                    <br><br>
                    <div style=" text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                  Move Here
                </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                    title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info"  style="width: 40%;" >
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Create
                  Folder</b>
                            </div>
                        </div> <br><br>
                            <div class="btn btn-primary" *ngIf="role != 1">
                            <div style="border: none; background: transparent; display: block;" (click)="addFolderMigrate('folderviewmig', addmigrate)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Migrate
                  Folder</b>
                            </div>
                        </div><br><br>
                        

                        <div class="btn btn-info" style="width: 40%;" *ngIf="clientmenushow && role == 1 " >
                            <div  style="border: none; background: transparent; display: block;" (click)="showclientmenu()">
                                 <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                               
                            </div>
                            </div>
                            <br><br>
                        <div class="btn btn-info"  style="width: 40%;    margin-top: -11%;" *ngIf="clientmenu" >
                        <div  style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                             <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                           
                        </div>
                        </div>
                        <br><br>
                        <div class="btn btn-info"  style="width: 41%;" *ngIf="clientmenu" >
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addcousultant)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Consultant</b>
                            </div>
                        </div> <br><br>
                        <div class="btn btn-info"  style="width: 40%;" *ngIf="clientmenu" >
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addcousultant)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Regelatee</b>
                            </div>
                        </div> <br><br>
                       
                    </div>
                </div>


                <div id="treeview" *ngIf="!mobile" style="
                    background-color: #ffffff;
 
                    height: 80vh;
                    margin-top: -5px;
                    z-index: 1;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 30px;">
                        <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
                        white-space: nowrap;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgb(0, 0, 0);
                      ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                          style="cursor: pointer;"></i>&nbsp;
                        <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                          style="cursor: pointer;" />
                        <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                          title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row mt-3">
                                <div class="col-12 col-lg-4" (click)="getpdfdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="align-items-center">

                                                <div class="file-man-box">
                                                    <div class="file-img-box" (click)="getpdfdocument()"><img src="assets/img/pdf.png" (click)="getpdfdocument()" alt="icon">
                                                        <h5 class="mt-3 mb-0">PDF<span> <br> <small></small></span>
                                                        </h5>


                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getpngdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/png.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">Images <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="gettxtdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/txt.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">Text Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row mt-3">

                                <div class="col-12 col-lg-4" (click)="getzipdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="align-items-center">

                                                <div class="file-man-box">
                                                    <div class="file-img-box"><img src="assets/img/zip.png" alt="icon" style="height: 96px;">
                                                        <h5 class="mt-3 mb-0">Zipped Files <span> <br> <small></small></span></h5>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getsqldocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/sql.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">SQL Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getmpdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">

                                                <div class="file-img-box"><img src="assets/img/mp4.png" alt="icon">
                                                    <h5 class="mt-3 mb-0"> Videos <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mt-3">

                                <div class="col-12 col-lg-4" (click)="getpptdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="align-items-center">

                                                <div class="file-man-box">
                                                    <div class="file-img-box"><img src="assets/img/ppt.png" alt="icon">
                                                        <h5 class="mt-3 mb-0">PPT <span> <br> <small></small></span></h5>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getdocdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/doc.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">Word <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getfolderdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">

                                                <div class="file-img-box"><img src="assets/img/allfile.png" alt="icon">
                                                    <h5 class="mt-3 mb-0"> All Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>





            </div>









        </div>
    </div>

    <div class="col-md-12 row" *ngIf="!SearchEnabled && listvalue" style="padding: 0;">

        <div class="col-md-12 row" style="padding: 0;" *ngIf="allfoldersview">


            <div class="col-md-3" style="justify-content: center;">

                <br /><br />
                


                <div style=" text-align: center;">
                    <div class="btn btn-primary">

                        <label>
                <b class="temp-upload-icon" for="excel-upload">Upload
                  Folder</b>
  
                <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                  (change)="filesPicked(folderInput.files)" webkitDirectory />
                
              </label>
                    </div>
                    <br><br>
                    <div style=" text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                  Move Here
                </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                    title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Create
                  Folder</b>
                            </div>
                        </div> <br><br>

                        <div  style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                            <div class="btn btn-success" > <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                            </div>
                        </div>
                        <br><br>
                        
                    </div>
                </div>


                <div id="treeview" *ngIf="!mobile" style="
                    background-color: #ffffff;
 
                    height: 80vh;
                    margin-top: -5px;
                    z-index: 1;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 30px;">
                        <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
                        white-space: nowrap;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgb(0, 0, 0);
                      ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                          style="cursor: pointer;"></i>&nbsp;
                        <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                          style="cursor: pointer;" />
                        <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                          title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 col-lg-2" (click)="getpdfdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box" (click)="getpdfdocument()"><img src="assets/img/pdf.png" (click)="getpdfdocument()" alt="icon">
                                                    <h5 class="mt-3 mb-0">PDF<span> <br> <small></small></span>
                                                    </h5>


                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getpngdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/png.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Images <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="gettxtdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/txt.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Text Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getzipdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/zip.png" alt="icon" style="height: 96px;">
                                                    <h5 class="mt-3 mb-0">Zipped Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getsqldocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/sql.png" alt="icon">
                                                <h5 class="mt-3 mb-0">SQL Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getmpdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">

                                            <div class="file-img-box"><img src="assets/img/mp4.png" alt="icon">
                                                <h5 class="mt-3 mb-0"> Videos <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex align-items-center">
                            <div>
                                <h5 class="mb-0"> <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> My Objects</h5>
                            </div>
                            <div class="ms-auto">
                                <div class="col-md-12 text-right" style="height: 20px;">
                                    <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);;" title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                                    <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);;" (click)="gridview(1)" aria-hidden="true" title="Grid View"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <div style="
                padding: 0;
                /* overflow-y: scroll; */
                height: 88vh;
               
                vertical-align: top;
              ">

                    <div class="table-responsive mt-3" style="height:88vh">
                        <table class="table table-striped table-hover table-sm mb-0">
                            <thead>


                                <th *ngIf="mob" style="text-align: center;"><button (click)="open(shareall)" type="submit" class="btn btn-primary">Share All</button></th>
                                <th *ngIf="!mob" style="width: 20px;text-align: center;"><button (click)="open(shareall)" type="submit" class="btn btn-primary text-center">Share All</button></th>
                                <th *ngIf="mob" style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                                </th>
                                <th *ngIf="!mob" style="font-weight: bold; cursor: pointer; width: 60%;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                                </th>
                                <th style="font-weight: bold; cursor: pointer;" *ngIf="mob" (click)="sortbydate()">
                                    Modified
                                    <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                                </th>
                                <th style="font-weight: bold;" *ngIf="mob">Type</th>
                                <th style="font-weight: bold;">Operations</th>

                            </thead>
                            <tbody *ngFor="let folderview of allFolders">
                                <tr>
                                    <td *ngIf="mob" style="text-align: center;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="!mob" style="text-align: center; width: 20px;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="mob" style="
                        white-space: nowrap;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      ">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="!mob" style="
                        white-space: nowrap;
                        width: 60%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      ">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}" *ngIf="!mob">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="mob">
                                        {{ folderview.createdOn | date: "short" }}
                                    </td>
                                    <td *ngIf="mob">Object Folder</td>

                                    <td *ngIf="!multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" style="cursor: pointer;" ngbDropdownToggle><i
                            class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DownloadFolder(folderview)" style="cursor: pointer;">Download</a>
                                                <a class="dropdown-item" (click)="ShareFolder(folderview, shareSingleFolder)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="UpdateFolder(folderview, editfolder)" style="cursor: pointer;">Edit</a>
                                                <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="Delete(folderview)" style="cursor: pointer;">Delete</a>

                                            </div>
                                        </div>

                                    </td>
                                    <td *ngIf="multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" style="cursor: pointer;" ngbDropdownToggle><i
                            class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)" style="cursor: pointer;">Delete</a>
                                                <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="CheckDownloadMultiple()" style="cursor: pointer;">Download</a>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>








        </div>
    </div>
    <ng-template #pdftesting let-d="dismiss" let-c="dismiss">
        <div class="modal-header" style="background-color: white;">
            <div>&nbsp;</div>
            <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
            <h6 style="float: left;">
                &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
            </h6>
    
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    
        <div class="modal-body" style="background-color: white;">
            <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
            <br /><br />
    
    
            <div class="col-lg-12 row">
                <div class="col-lg-5"></div>
                <div class="col-lg-6">
                    <a [href]="pdfUrl"><button class="btn btn-info">
                            <i class="fa fa-download"></i>Download
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
        <div>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                    <b>Select Members to Share</b>
                </h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            

            <div class="modal-body" style="font-size: 16px">
                <div>
                    <div id="aboveTagInp">
                        <tag-input ng-disabled="all" id="ShareTagInp" 
                        [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" 
                        (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                         [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                        [separatorKeyCodes]="[13,188,186,32]">
                      
                        <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false"
                         [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'friendName'" 
                         [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                            <ng-template let-item="item" let-index="index">
                                {{ item.friendName }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                    </div>
                </div>
                <br />
            </div>
            <br />

            <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list?
          &nbsp;</span>
                <button type="button" class="btn btn-primary" (click)="addfrnd()">
          Add Friend
        </button>
            </div>
            <div *ngIf="frndrqst">
                <div>

                    <br>
                    <input type="text" placeholder="Registered Email/Mobile Number" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
                </div>
                <br />
                <div>
                    <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()" style="color: black;padding: 5px;margin-left: 10px;">
            Send Request
          </button>
                </div>
            </div>
            <br />
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="ShareExsistingFolderToUsersForm()" (click)="d('Cross click')">
          Share
        </button>
            </div>
        </div>
        <br />
    </ng-template>

    <ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Select Members to Share</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        
        <div class="modal-body" style="font-size: 16px">
            <div>
                <div id="aboveTagInp">
                    <tag-input ng-disabled="all" id="ShareTagInp" 
                    [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" 
                    (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                     [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                    [separatorKeyCodes]="[13,188,186,32]">
                  
                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false"
                     [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'friendName'" 
                     [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                        <ng-template let-item="item" let-index="index">
                            {{ item.friendName }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input>
                </div>
            </div>
            <br />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()" (click)="d('Cross click')" style="cursor: pointer;">
        Share
      </button>
        </div>
        <br />
    </ng-template>
    <ng-template #shareall let-d="dismiss" let-c="dismiss">
        <div>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                    <b>Select Members to Share</b>
                </h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            
            <div class="modal-body" style="font-size: 16px">
                <div>
                    <div id="aboveTagInp">
                        <tag-input ng-disabled="all" id="ShareTagInp" 
                        [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" 
                        (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)"
                         [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                        [separatorKeyCodes]="[13,188,186,32]">
                      
                        <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false"
                         [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'friendName'" 
                         [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                            <ng-template let-item="item" let-index="index">
                                {{ item.friendName }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                    </div>
                </div>
                <br />
            </div>
            <br />

            <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list?
          &nbsp;</span>
                <button type="button" class="btn btn-primary" (click)="addfrnd()">
          Add Friend
        </button>
            </div>
            <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Do you wish to cancel shared documents?
          &nbsp;</span>

                <button type="button" class="btn btn-primary" (click)="cancelshare()">
          Cancel Shared Documents
        </button>
            </div>
            <div *ngIf="frndrqst">
                <div>
                  
                    <br>
                    <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
                </div>
                <br />
                <div>
                    <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()" style="color: black;padding: 5px;margin-left: 10px;">
            Send Request
          </button>
                </div>
            </div>
            <br />
            <div *ngIf="cancelshareall">

                <div class="col-lg-12 row">
                    <select *ngIf="shareitem1" class="form-control dropdownsize" (change)="ShareFileMembers1($event.target.value)">
            <option value="" hidden> Select Friend </option>
            <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer;">
              {{ I.friendName }}
            </option>
          </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="shareitem1" class="btn btn-primary" (click)="shareallallcancel()" (click)="d('Cross click')">
          Cancel Shared Documents
        </button> &nbsp; &nbsp;
                <button type="button" *ngIf="!shareitem1" class="btn btn-primary" (click)="shareallall()" (click)="d('Cross click')">
          Share
        </button>
            </div>
        </div>
        <br />
    </ng-template>


    <div class="modal fade" id="Movetofold" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 4%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Select Folder to Move
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12">
                            <div>
                                <div *ngFor="let a of allFolders1" id="{{ a.folderID }}">
                                    <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                      style="cursor: pointer;"></i>
                    <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="gotoFolder(a, Movetofold)" />
                    <span (click)="gotoFolder(a, Movetofold)" style="cursor: pointer;">{{ a.folderName }}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="MoveMultiFolder(destinationFold)" style="cursor: pointer;">
            Move
          </button>
                    <button type="button" id="closemove" class="btn btn-warning" data-dismiss="modal" style="cursor: pointer;">
            Close
          </button>
                </div>
            </div>
        </div>
    </div>

    <ng-template #deleteMultiConformation let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Delete Data </b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body" style="font-size: 16px;">
            <div class="col-lg-12 row">
                <h4 class="modal-title w-100" id="myModalLabel">
                    <b>Do You want to Delete {{ deletemulticontent }} &nbsp; ?</b>
                </h4>
            </div>
            <br />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="Deleteselected()" (click)="d('Cross click')" style="cursor: pointer;">
        Delete
      </button>
            <button type="button" class="btn btn-warning" (click)="d('Cross click')" style="cursor: pointer;">
        Close
      </button>
        </div>
        <br />
    </ng-template>

    <div class="col-lg-12 row" *ngIf="showper">
        <div class="col-lg-3"></div>
        <div class="col-lg-7" style="position: fixed; top: 86%; left: 20%;">
            <p class="alert alert-info" style="box-shadow: white; background-color: white;" *ngIf="generalprogressbar">
                <b *ngIf="folderCreating && !foldercreated" style="color: black;">{{ Creatingmsg }} :{{ n }}%</b>
                <img src="./assets/img/tick.png" height="20px;" width="20px;" *ngIf="foldercreated && !folderCreating" />&nbsp;&nbsp;
                <b style="color: black;" *ngIf="foldercreated && !folderCreating">&nbsp;&nbsp; {{ createdmsg }}
        </b>
                <br />
                <progress value="{{ n }}" max="100" style="width: 100%; height: 7px;">
        </progress>&nbsp;&nbsp;{{ n }}%
            </p>
        </div>
    </div>

    <ng-template #folderdetails let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Folder Details</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body" style="font-size: 16px;">
            <div class="col-lg-12 row">
                <div class="col-md-6">Folder Name :</div>
                <div class="col-md-6" style="color: blue;" style="cursor: pointer;">
                    {{ FolderName }}
                </div>
            </div>
            <div class="col-lg-12 row">
                <div class="col-lg-6">Created On :</div>
                <div class="col-lg-6" style="text-align: left; color: blue;">
                    {{ CreatedOn | date: "short" }}
                </div>
            </div>
            
            <br />
        </div>
        <br />

        <div class="modal-footer">
            <button type="button" class="btn btn-warning" (click)="d('Cross click')" style="cursor: pointer;">
        Close
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #foldercontent let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="msg" [(ngModel)]="msg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="savenewFolder()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>



    <ng-template #adddeligence let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Due Diligence</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" *ngIf="isLoggedIn">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
    
            <h5 class="modal-title" id="modal-basic-title"> Enter the Name:</h5>
            <input type="text" id="ddname" [(ngModel)]="dname" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="saveduedeligence()" (click)="d('Cross click')"
                style="cursor: pointer;">
                Add Due Diligence
            </button>
        </div>
        <br />
    </ng-template>

    <ng-template #addmigrate let-d="dismiss" let-c="dismiss">
          <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Migrate Folder/Files</h4>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" *ngIf="isLoggedIn && roleid == '1'">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
      
              <h5 class="modal-title" id="modal-basic-title"> Enter the Email:</h5>
              <input type="text" id="mail" [(ngModel)]="mail" class="form-control" name="dp" />
          </div>
          <br />
          <div class="modal-footer">
              <button type="button" class="btn btn-success" (click)="savemigrate()" (click)="d('Cross click')"
                  style="cursor: pointer;">
                  Migrate
              </button>
          </div>
          <br />
      </ng-template>
  
    

    <ng-template #addcousultant let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Consultant/Regelatee</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Consultant/Regelatee Name:</h5>
            <input type="text" id="clientname" [(ngModel)]="clientname" class="form-control" name="dp"/><br />
         
             
    

            <h5 class="modal-title" id="modal-basic-title">Email:</h5>
            <input type="text" id="clientemail" [(ngModel)]="clientemail" class="form-control" name="dp"  
              />
           
        </div>
        <br />

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="consaltantreg()"  (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #editfolder let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="UpdateFolderName()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black;">
        Update
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #subfoldercontent let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="msg" [(ngModel)]="msg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="savenewSubFolder()" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #addclient let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Client</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Client Name:</h5>
            <input type="text" id="clientname" [(ngModel)]="clientname" class="form-control" name="dp"/><br />
         
             
    

            <h5 class="modal-title" id="modal-basic-title">Email:</h5>
            <input type="text" id="clientemail" [(ngModel)]="clientemail" class="form-control" name="dp"  
              />
           
        </div>
        <br />

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="regnewclient()"  (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>



    <ng-template #editsubfolder let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="UpdateSubFolderName()" style="cursor: pointer;" style="color: black;">
        Update
      </button>
        </div>
        <br />
    </ng-template>










    <button type="button" id="ppup" data-toggle="modal" data-target="#exampleModal213" style="display: none;">
    <span style="
        width: 30px;
        height: 30px;
        border-radius: 15%;
        background-color: #edf3ff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 25px;
      "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
  </button>

    <button type="button" id="ppup12" data-toggle="modal" data-target="#Movetofold" style="display: none;">
    <span style="
        width: 30px;
        height: 30px;
        border-radius: 15%;
        background-color: #edf3ff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 25px;
      "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
  </button>

    <div class="modal fade" id="exampleModal213" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="height: 100vh; overflow-y: auto;">
        <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 10%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 5px;">
                        Upload Folder
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="canceltree()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12">
                            <div>
                                <div *ngFor="let a of allFolders1" id="{{ a.folderID }}">
                                    <span><i class="fa fa-caret-right" (click)="getfolders1(a)" style="cursor: pointer; margin-left: 5px;"
                      id="{{ a.id }}"></i>
                    <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="gotoFolder12(a)" />
                    <span (click)="gotoFolder12(a)" style="cursor: pointer;">{{
                      a.folderName
                    }}</span></span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12" style="text-align: center;">Or</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12" style="text-align: center;" (click)="uploadhere()" style="cursor: pointer;">
                            Upload Here
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="cancelUpload1" class="btn btn-secondary" (click)="cancelupload()" data-dismiss="modal">
            Cancel
          </button>
                    <button type="button" class="btn btn-primary" (click)="Uploaddocs1()" style="cursor: pointer;">
            Upload
          </button>
                </div>
            </div>
        </div>
    </div>

</div> -->

<!-- +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
<!-- Notify -->

<app-nav-bar></app-nav-bar>
<!-- <div class="col-md-12 row">
    <div class="col-md-4"></div>
    <div class="col-md-4" style="text-align: center;    padding: 30px 0px 30px 0px;"><img src="../../assets/img/blocdrive-01.jpg" class="img-fluid" style="    height: 100px;
width: 180px;" /></div>
</div> -->

<div>
    <div class="col-md-12 row" *ngIf="foladdednotify">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-4">
            <div class="alert alert-info" style="
          background-color: #545353;
          box-shadow: none;
          left: 245px;
          position: fixed;
          top: 150px;
          width: 315px;
          height: 15px;
          border-radius: 10px;
          text-align: center;
          z-index: 10000;
        ">
                <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <i class="material-icons" style="color: rgb(0, 0, 0);">close</i>
        </button>
                <span style="color: rgb(0, 0, 0); margin-top: 7px;">
          <b style="
              text-transform: capitalize;
              font-size: 14px;
              line-height: 15px;
            ">
            {{ notify }}</b></span>
            </div>
        </div>
    </div>
    <!-- Notify -->

    <!-- Search -->

    <div class="col-md-12 row" *ngIf="SearchEnabled" style="padding: 0; margin-left: 0; margin-right: 0;">
        <div class="col-md-12 row" style="padding: 0; margin-left: 0; margin-right: 0;">


            <div class="col-md-3" style="justify-content: center;">
                <br /><br />

                <!-- <div class="card" style="    padding: 17px 0px 32px 38px;">
                    <div class="card-body">
                        <h5 class="mb-0 text-primary font-weight-bold">10 GB <span class="float-end text-secondary">15 GB</span></h5>
                        <p class="mb-0 mt-2"><span class="text-secondary">Used</span><span class="float-end text-primary">Upgrade</span>
                        </p>
                        <div class="progress mt-3" style="height:7px;">
                            <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                           
                        </div>

                    </div>
                </div> -->


                <div style=" text-align: center;">

                    <div style="width: 100%;">
                        <div class="btn btn-warning">
                            <span data-toggle="modal" data-target="#exampleModal" (click)="openmodal()">
                
                  Upload Object
                </span>
                        </div><br><br>

                        <label class="btn btn-primary">
                  <b class="temp-upload-icon" for="excel-upload">
                    Upload Folder</b>
    
                  <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                    (change)="filesPicked(folderInput.files)" webkitDirectory />
                 
                </label>
                    </div>
                    <div><br>
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                    Move Here
                  </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                      title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info">
                            <div style="border: none; background: transparent; display: block;" (click)="addSubFolder('allSubFolders', subfoldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; " (click)="addSubFolder(allSubFolders, subfoldercontent)">Create Sub Folder</b>
                            </div>
                        </div>
                        <div *ngIf="role == 11" style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                            <br>
                            <div class="btn btn-success"> <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; " (click)="addnewFolder('folderview', addclient)">&nbsp;Add New Client</b>
                            </div>
                        </div>

                    </div>

                </div>


                <div id="treeview" *ngIf="!mobile" style="
                background-color: #ffffff;
                height: 15vh;
               
                margin-top: 30px;
                z-index: 1;
                overflow-y: scroll;
                overflow-x: hidden;
              ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 20px;">
                        <div *ngFor="let a of SearchResult.folders" id="{{ a.folderID }}" style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgb(0, 0, 0);
              ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                    style="cursor: pointer;"></i>&nbsp;
                  <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                    style="cursor: pointer;" />
                  <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                    title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" autofocus class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">


                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 col-lg-2" (click)="getpdfdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/pdf.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">PDF<span> <br> <small></small></span>
                                                    </h5>


                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getpngdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/png.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Images <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="gettxtdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/txt.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Text Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getzipdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/zip.png" alt="icon" style="height: 96px;">
                                                    <h5 class="mt-3 mb-0">Zipped Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getsqldocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/sql.png" alt="icon">
                                                <h5 class="mt-3 mb-0">SQL Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getmpdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/mp4.png" alt="icon">
                                                <h5 class="mt-3 mb-0"> Videos <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <!--end row-->
                        <div class="" *ngIf='showload'>
                            <div class="spinner-border text-dark">
                                <span class="sr-only">Loading...</span>
                              </div>
                           </div>
                        <div class="d-flex align-items-center">
                            <div>

                                <h5 class="mb-0" [routerLink]="['/allfolders']"> <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> My Objects</h5>
                            </div>

                            <div class="ms-auto">
                                <div class="col-md-12 text-right" style="height: 20px;">
                                    <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);;" title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                                    <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);;" (click)="gridview(1)" aria-hidden="true" title="Grid View"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <div style="
            padding: 0;
          
            height: 70vh;
           
            vertical-align: top;
          ">

                    <div class="table-responsive mt-3" style="  overflow-y: scroll;height: 66vh;">
                        <table class="table table-striped table-hover table-sm mb-0">

                            <thead style="color: #33383e;">

                                <th *ngIf="mob"></th>
                                <th *ngIf="!mob" style="width: 20px;"></th>
                                <th *ngIf="mob" style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
<i class="fa fa-caret-down" aria-hidden="true"></i>
<i class="fa fa-caret-up" aria-hidden="true"></i>
</span>
                                </th>
                                <th *ngIf="!mob" style="font-weight: bold; cursor: pointer; width: 30%;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
<i class="fa fa-caret-down" aria-hidden="true"></i>
<i class="fa fa-caret-up" aria-hidden="true"></i>
</span>
                                </th>
                                <th style="font-weight: bold; cursor: pointer;" *ngIf="mob" (click)="sortbydate()">
                                    Modified
                                    <span class="fa fa-stack">
<i class="fa fa-caret-down" aria-hidden="true"></i>
<i class="fa fa-caret-up" aria-hidden="true"></i>
</span>
                                </th>
                                <th style="font-weight: bold;" *ngIf="mob">Type</th>
                                <!-- <th style="font-weight: bold;">Operations</th> -->

                                <!-- <th>Delete</th> -->
                            </thead>
                            <!--...... <tbody *ngFor="let folderview of SearchResult.folders">
                                <tr>
                                    <td *ngIf="mob" style="text-align: center;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="!mob" style="text-align: center; width: 20px;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="mob" style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="!mob" style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="mob">{{ folderview.createdOn | date: "short" }}</td>
                                    <td *ngIf="mob">Object Folder</td>


                                    <td *ngIf="!multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer;"><i
    class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="ShareFolder(folderview, shareSingleFolder)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="DownloadFolder(folderview)" style="cursor: pointer;">Download</a>
                                                <a class="dropdown-item" (click)="UpdateSubFolder(folderview, editsubfolder)" style="cursor: pointer;">Edit</a>
                                                <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="Delete(folderview)" style="cursor: pointer;">Delete</a>

                                            </div>
                                        </div>

                                    </td>

                                    <td *ngIf="multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                                                <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)" style="cursor: pointer;">Delete</a>
                                                <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="CheckDownloadMultiple()" style="cursor: pointer;">Download</a>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody> ......-->
                            <tbody *ngFor="let ret of SearchResult.files">
                                <tr>
                                    <td *ngIf="mob">
                                        <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="!mob" style="width: 18px;">
                                        <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="(ret.DocumentType == 'image' ) && mob
">
                                        <img src="{{ ret.file }}" style="width: 15px; height: 40px; cursor: pointer;" (click)="ViewImageDoc(ret,content)" />
                                        <span (click)="ViewImageDoc(ret,content)" style="cursor: pointer;" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'application/pdf' ||
ret.DocumentType == 'pdf') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/pdficon.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.DocumentType == 'xlsx') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.DocumentType == 'ppt' ||
ret.DocumentType == 'pptx') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'video/mp4' ||
ret.DocumentType == 'mp4') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.DocumentType == 'doc' ||
ret.DocumentType == 'docx') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'application/x-zip-compressed' ||
ret.DocumentType == 'zip') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'text/plain' ||
ret.DocumentType == 'txt') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
" *ngIf="
(ret.DocumentType == 'audio/mp3' ||
ret.DocumentType == 'mp3') &&
mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'image') &&
!mob
">
                                        <img src="{{ ret.file }}" style="width: 15px; height: 40px; cursor: pointer;" (click)="ViewImageDoc(ret,content)" />
                                        <span (click)="ViewImageDoc(ret,content)" style="cursor: pointer;" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'application/pdf' ||
ret.DocumentType == 'pdf') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/pdficon.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.DocumentType == 'xlsx') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" style="cursor: pointer;" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.DocumentType == 'ppt' ||
ret.DocumentType == 'pptx') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'video/mp4' ||
ret.DocumentType == 'mp4') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.DocumentType == 'doc' ||
ret.DocumentType == 'docx') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'application/x-zip-compressed' ||
ret.DocumentType == 'zip') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/zip.jpg" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'text/plain' ||
ret.DocumentType == 'txt') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>

                                    <td style="
white-space: nowrap;
width: auto;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
width: 70%;
" *ngIf="
(ret.DocumentType == 'audio/mp3' ||
ret.DocumentType == 'mp3') &&
!mob
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/video.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span (click)="ViewDocument(ret,pdftesting)" title="{{ ret.DocumentName }}">
&nbsp;&nbsp;{{ ret.DocumentName }}
</span>
                                    </td>
                                    <!--...... <td [hidden]="
ret.DocumentType == 'text/plain' ||
ret.DocumentType == 'txt' ||
ret.DocumentType == 'application/x-zip-compressed' ||
ret.DocumentType == 'zip' ||
ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
ret.DocumentType == 'doc' ||
ret.DocumentType == 'docx' ||
ret.DocumentType == 'video/mp4' ||
ret.DocumentType == 'mp4' ||
ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
ret.DocumentType == 'ppt' ||
ret.DocumentType == 'pptx' ||
ret.DocumentType ==
'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
ret.DocumentType == 'xlsx' ||
ret.DocumentType == 'application/pdf' ||
ret.DocumentType == 'image/jpeg' ||
ret.DocumentType == 'image/png' ||
ret.DocumentType == 'pdf' ||
ret.DocumentType == 'audio/mp3' ||
ret.DocumentType == 'mp3'
" (click)="ViewDocument(ret,pdftesting)">
                                        <img src="assets/img/anyf1.png" style="width: 35px; height: 34px; cursor: pointer;" />
                                        <span> &nbsp;&nbsp;{{ ret.DocumentName }} </span>
                                    </td> ......-->

                                    <td *ngIf="mob">{{ ret.date | date: "short" }}</td>
                                    <td *ngIf="mob">{{ ret.DocumentType }}</td>
                                    <!-- <td *ngIf="!multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer;"><i
    class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">

                                                <a class="dropdown-item" (click)="MoveDoc(ret, 'Img')" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="Delete(ret)" style="cursor: pointer;">Delete</a>
                                                <a class="dropdown-item" (click)="ShareoneFile(ret, shareSingleFile)" style="cursor: pointer;">Share</a>


                                            </div>
                                        </div>

                                    </td> -->

                                    <!-- <td *ngIf="multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
    style="cursor: pointer;" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)" style="cursor: pointer;">Delete</a>
                                                <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)" style="cursor: pointer;">Move</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>




        </div>
    </div>

    <!-- GRID VIEW -->
    <div class="col-md-12" *ngIf="!SearchEnabled && gridvalue">

        <div class="col-md-12 row" style="padding: 0;">


            <div class="col-md-3" style="justify-content: center;">
                <br /><br />

                <!-- <div class="card" style="    padding: 17px 0px 32px 38px;">
                    <div class="card-body">
                        <h5 class="mb-0 text-primary font-weight-bold">10 GB <span class="float-end text-secondary">15 GB</span></h5>
                        <p class="mb-0 mt-2"><span class="text-secondary">Used</span><span class="float-end text-primary">Upgrade</span>
                        </p>
                        <div class="progress mt-3" style="height:7px;">
                            <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                         
                        </div>

                    </div>
                </div> -->


                <div style=" text-align: center;">
                    <div class="btn btn-primary">

                        <label>
        <b class="temp-upload-icon" for="excel-upload">Upload
          Folder</b>
    
          <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
            (change)="filesPicked(folderInput.files)" webkitDirectory />
        
      </label>
                    </div>
                    <br><br>
                    <div style=" text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
          Move Here
        </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
            title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Create
          Folder</b>
                            </div>
                        </div> <br><br>

                        <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                            <div class="btn btn-success" style="width:40%;"> <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                            </div>
                        </div>
                        <br><br>
                        <!-- <div  style="border: none; background: transparent; display: block;" (click)="addDueDeligence('folderviewdeligence', adddeligence)">
                            <div class="btn btn-success" > <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Due Diligence</b>
                            </div>
                        </div> -->

                    </div>
                </div>
                <div id="treeview" *ngIf="!mobile" style="
                background-color: #ffffff;
    
                height: 70vh;
                margin-top: -5px;
                z-index: 1;
                overflow-y: scroll;
                overflow-x: hidden;
              ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 30px;">
                        <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
                    white-space: nowrap;
                    width: auto;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: rgb(0, 0, 0);
                  ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                      style="cursor: pointer;"></i>&nbsp;
                    <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                      style="cursor: pointer;" />
                    <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                      title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">

                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                                </div> <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong">
                                    <img src="./assets/img/folder.svg" alt="HTML tutorial" style="width:42px;height:42px;">    AI Search
                                </a>
                            </div>
                        </div>
                        <br><br>
                        <div class="d-flex align-items-center">
                            <div>
                                <h5 class="mb-0"> <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> My Objects</h5>
                            </div>

                        </div>
                        <div class="col-md-12 text-right" style="height: 20px;">
                            <button (click)="open(shareall)" type="submit" class="btn btn-primary">
    Share All
    </button> &nbsp;
                            <button (click)="sortby()" style="background: transparent; border: none; cursor: pointer;">
    Name
    <span class="fa fa-stack">
    <i class="fa fa-caret-down" aria-hidden="true"></i>
    <i class="fa fa-caret-up" aria-hidden="true"></i>
    </span>
    </button> &nbsp;
                            <button (click)="sortbydate()" style="background: transparent; border: none; cursor: pointer;">
    Date
    <span class="fa fa-stack">
    <i class="fa fa-caret-down" aria-hidden="true"></i>
    <i class="fa fa-caret-up" aria-hidden="true"></i>
    </span>
    </button> &nbsp;
                            <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);;" title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                            <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);;" (click)="gridview(1)" aria-hidden="true" title="Grid View"></i>
                        </div>

                        <br><br>
                        <div style=" padding: 0;">


                            <div style="padding: 0; height: 94vh;">

                                <div class="col-md-12 row" style=" margin-top: 60px;padding: 0;
                                overflow-y: scroll;
                                height: 94vh;
                              
                                vertical-align: top;">
                                    <div class="column col-md-3" *ngFor="let folderview of allFolders">
                                        <div class="show-image" style="    text-align: center;">



                                            <div id="menuContainer">
                                                <div class="menuItem first">
                                                    <i class="fa-4x fa fa-folder-open" aria-hidden="true" (click)="GetSubfolder(folderview)" style="left: 0; cursor: pointer; color: rgb(84 118 254);;"></i>

                                                    <div style="
                                            white-space: nowrap;
                                            width: auto;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            cursor: pointer;
                                          " id="foldername" (click)="GetSubfolder(folderview)" title="{{ folderview.folderName }}">
                                                        {{ folderview.folderName }}
                                                    </div>
                                                </div>
                                                <div id="settingsMenu">
                                                    <div ngbDropdown class="dropdown" placement="bottom-right">
                                                        <a class="nav-link" id="dropdownBasic1" style="cursor: pointer;    text-align: right;" ngbDropdownToggle><i
    class="fa fa-gear" aria-hidden="true" ></i></a>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">

                                                            <a class="dropdown-item" *ngIf="shareitem" (click)="UpdateFolder(folderview, editfolder)" data-target="#basicExample" data-toggle="modal" style="cursor: pointer;">Edit</a>
                                                            <a class="dropdown-item" *ngIf="shareitem" (click)="MoveTo(folderview, 'Doc')" style="cursor: pointer;">Move</a>

                                                            <a class="dropdown-item" *ngIf="shareitem" (click)="Delete(folderview)" style="cursor: pointer;" data-toggle="modal" data-target="#deleteExample">Delete</a>
                                                            <a class="dropdown-item" (click)="showfolderdetails(folderview, folderdetails)" data-target="#Details" data-toggle="modal" style="cursor: pointer;">Details</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>







        </div>
    </div>

    <!-- LIST VIEW -->

    <div class="col-md-12 row" *ngIf="!SearchEnabled && listvalue" style="padding: 0;">

        <div class="col-md-12 row" style="padding: 0;" *ngIf="displaydesign">


            <div class="col-md-3" style="justify-content: center;">
                <br /><br />

                <!-- <div class="card" style="    padding: 17px 0px 32px 38px;">
                    <div class="card-body">
                        <h5 class="mb-0 text-primary font-weight-bold">12 GB of <span class="float-end text-secondary">15 GB</span></h5>
                        <p class="mb-0 mt-2"><span class="text-secondary">Used / </span><span class="float-end text-primary">Upgrade</span>
                        </p>
                        <div class="progress mt-3" style="height:7px;">
                            <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>

                    </div>
                </div> -->


                <div style=" text-align: center;">
                    <div class="btn btn-primary" style="width: 40%;">

                        <label>
                <b class="temp-upload-icon" for="excel-upload">Upload
                  Folder</b>
  
                <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                  (change)="filesPicked(folderInput.files)" webkitDirectory />
                
              </label>
                    </div>
                    <br><br>
                    <div style=" text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                  Move Here
                </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                    title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info" style="width: 40%;">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Create
                  Folder</b>
                            </div>
                        </div> <br><br>
                        <!-- <div class="btn btn-primary" *ngIf="role == 0 || role == 11"> -->
                        <div class="btn btn-primary" *ngIf="role != 1">
                            <div style="border: none; background: transparent; display: block;" (click)="addFolderMigrate('folderviewmig', addmigrate)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Migrate
                  Folder</b>
                            </div>
                        </div><br><br>
                        <!-- <div  style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                            <div class="btn btn-success" > <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                            </div>
                        </div>
                        <br><br> -->

                        <div class="btn btn-info" style="width: 40%;" *ngIf="clientmenushow && role == 1 ">
                            <!-- <div class="btn btn-info" style="width: 40%;" *ngIf="clientmenushow && role == 1 || role == 11" > -->
                            <div style="border: none; background: transparent; display: block;" (click)="showclientmenu()">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>

                            </div>
                        </div>
                        <br><br>
                        <div class="btn btn-info" style="width: 40%;    margin-top: -11%;" *ngIf="clientmenu">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>

                            </div>
                        </div>
                        <br><br>
                        <div class="btn btn-info" style="width: 41%;" *ngIf="clientmenu">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addcousultant)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Consultant</b>
                            </div>
                        </div> <br><br>
                        <div class="btn btn-info" style="width: 40%;" *ngIf="clientmenu">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addcousultant)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Regelatee</b>
                            </div>
                        </div> <br><br>
                        <!-- <div  style="border: none; background: transparent; display: block;" (click)="addDueDeligence('folderviewdeligence', adddeligence)">
                            <div class="btn btn-success" > <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Due Diligence</b>
                            </div>
                        </div> -->
                    </div>
                </div>


                <div id="treeview" *ngIf="!mobile" style="
                    background-color: #ffffff;
 
                    height: 80vh;
                    margin-top: -5px;
                    z-index: 1;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 30px;">
                        <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
                        white-space: nowrap;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgb(0, 0, 0);
                      ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                          style="cursor: pointer;"></i>&nbsp;
                        <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                          style="cursor: pointer;" />
                        <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                          title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                                </div> <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong">
                                    <img src="./assets/img/folder.svg" alt="HTML tutorial" style="width:42px;height:42px;">    AI Search
                                </a>
                            </div>
                        </div>
                        <div>
                            <div class="row mt-3">
                                <div class="col-12 col-lg-4" (click)="getpdfdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="align-items-center">

                                                <div class="file-man-box">
                                                    <div class="file-img-box" (click)="getpdfdocument()"><img src="assets/img/pdf.png" (click)="getpdfdocument()" alt="icon">
                                                        <h5 class="mt-3 mb-0">PDF<span> <br> <small></small></span>
                                                        </h5>


                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getpngdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/png.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">Images <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="gettxtdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/txt.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">Text Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row mt-3">

                                <div class="col-12 col-lg-4" (click)="getzipdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="align-items-center">

                                                <div class="file-man-box">
                                                    <div class="file-img-box"><img src="assets/img/zip.png" alt="icon" style="height: 96px;">
                                                        <h5 class="mt-3 mb-0">Zipped Files <span> <br> <small></small></span></h5>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getsqldocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/sql.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">SQL Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getmpdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">

                                                <div class="file-img-box"><img src="assets/img/mp4.png" alt="icon">
                                                    <h5 class="mt-3 mb-0"> Videos <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mt-3">

                                <div class="col-12 col-lg-4" (click)="getpptdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="align-items-center">

                                                <div class="file-man-box">
                                                    <div class="file-img-box"><img src="assets/img/ppt.png" alt="icon">
                                                        <h5 class="mt-3 mb-0">PPT <span> <br> <small></small></span></h5>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getdocdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/doc.png" alt="icon">
                                                    <h5 class="mt-3 mb-0">Word <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" (click)="getfolderdocument()">
                                    <div class="card shadow-none border radius-15">
                                        <div class="card-body">
                                            <div class="file-man-box">

                                                <div class="file-img-box"><img src="assets/img/allfile.png" alt="icon">
                                                    <h5 class="mt-3 mb-0"> All Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>





            </div>









        </div>
    </div>

    <!-- ------- -->
    <div class="col-md-12 row" *ngIf="!SearchEnabled && listvalue" style="padding: 0;">

        <div class="col-md-12 row" style="padding: 0;" *ngIf="allfoldersview">


            <div class="col-md-3" style="justify-content: center;">

                <br /><br />
                <!-- <div class="card" style="    padding: 17px 0px 32px 38px;">
                    <div class="card-body">
                        <h5 class="mb-0 text-primary font-weight-bold">10 GB <span class="float-end text-secondary">15 GB</span></h5>
                        <p class="mb-0 mt-2"><span class="text-secondary">Used</span><span class="float-end text-primary">Upgrade</span>
                        </p>
                        <div class="progress mt-3" style="height:7px;">
                            <div class="progress-bar" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                            
                        </div>
                    </div>
                </div> -->


                <div style=" text-align: center;">
                    <div class="btn btn-primary">

                        <label>
                <b class="temp-upload-icon" for="excel-upload">Upload
                  Folder</b>
  
                <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                  (change)="filesPicked(folderInput.files)" webkitDirectory />
                
              </label>
                    </div>
                    <br><br>
                    <div style=" text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                  Move Here
                </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                    title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>
                        <div class="btn btn-info">
                            <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                                <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Create
                  Folder</b>
                            </div>
                        </div> <br><br>

                        <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                            <div class="btn btn-success"> <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Client</b>
                            </div>
                        </div>
                        <br><br>
                        <!-- <div  style="border: none; background: transparent; display: block;" (click)="addDueDeligence('folderviewdeligence', adddeligence)">
                            <div class="btn btn-success" > <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">Add Due Diligence</b>
                            </div>
                        </div> -->
                    </div>
                </div>


                <div id="treeview" *ngIf="!mobile" style="
                    background-color: #ffffff;
 
                    height: 80vh;
                    margin-top: -5px;
                    z-index: 1;
                    overflow-y: scroll;
                    overflow-x: hidden;
                  ">
                    <br />

                    <div *ngIf="ppopup" style="margin-left: 30px;">
                        <div *ngFor="let a of allFolders1" id="{{ a.folderID }}" style="
                        white-space: nowrap;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgb(0, 0, 0);
                      ">
                            <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                          style="cursor: pointer;"></i>&nbsp;
                        <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="GetSubfolder(a)"
                          style="cursor: pointer;" />
                        <span (click)="GetSubfolder(a)" style="cursor: pointer; color: rgb(0, 0, 0); letter-spacing: 1.5px;"
                          title="{{ a.folderName }}">&nbsp;{{ a.folderName }}</span></span>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="fm-search">
                            <div class="mb-0">
                                <div class="input-group input-group-lg" style="    box-shadow: 0px 4px 4px rgb(6 74 226 / 25%);"> <span class="input-group-text bg-transparent"><i class="fa fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Search..." (input)="onSearchChange($event.target.value)" [(ngModel)]="val">
                                </div>
                                 <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalLong">
                                    <img src="./assets/img/folder.svg" alt="HTML tutorial" style="width:42px;height:42px;">    AI Search
                                </a>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 col-lg-2" (click)="getpdfdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box" (click)="getpdfdocument()"><img src="assets/img/pdf.png" (click)="getpdfdocument()" alt="icon">
                                                    <h5 class="mt-3 mb-0">PDF<span> <br> <small></small></span>
                                                    </h5>


                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getpngdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/png.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Images <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="gettxtdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/txt.png" alt="icon">
                                                <h5 class="mt-3 mb-0">Text Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getzipdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="align-items-center">

                                            <div class="file-man-box">
                                                <div class="file-img-box"><img src="assets/img/zip.png" alt="icon" style="height: 96px;">
                                                    <h5 class="mt-3 mb-0">Zipped Files <span> <br> <small></small></span></h5>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getsqldocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">
                                            <div class="file-img-box"><img src="assets/img/sql.png" alt="icon">
                                                <h5 class="mt-3 mb-0">SQL Files <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-2" (click)="getmpdocument()">
                                <div class="card shadow-none border radius-15">
                                    <div class="card-body">
                                        <div class="file-man-box">

                                            <div class="file-img-box"><img src="assets/img/mp4.png" alt="icon">
                                                <h5 class="mt-3 mb-0"> Videos <span> <br> <small></small></span></h5>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <!--end row-->
                        <div class="d-flex align-items-center">
                            <div>
                                <h5 class="mb-0"> <i class="fa fa-folder" style="color: rgb(84 118 254);"></i> My Objects</h5>
                            </div>
                            <div class="ms-auto">
                                <div class="col-md-12 text-right" style="height: 20px;">
                                    <i class="fa fa-bars" aria-hidden="true" style="cursor: pointer; color: rgb(84 118 254);;" title="List View" (click)="gridview(0)"></i> &nbsp;&nbsp;
                                    <i class="fa fa-th" style="cursor: pointer; color: rgb(84 118 254);;" (click)="gridview(1)" aria-hidden="true" title="Grid View"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <div style="
                padding: 0;
                /* overflow-y: scroll; */
                height: 88vh;
               
                vertical-align: top;
              ">

                    <div class="table-responsive mt-3" style="height:88vh">
                        <table class="table table-striped table-hover table-sm mb-0">
                            <thead>


                                <th *ngIf="mob" style="text-align: center;"><button (click)="open(shareall)" type="submit" class="btn btn-primary">Share All</button></th>
                                <th *ngIf="!mob" style="width: 20px;text-align: center;"><button (click)="open(shareall)" type="submit" class="btn btn-primary text-center">Share All</button></th>
                                <th *ngIf="mob" style="font-weight: bold; cursor: pointer;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                                </th>
                                <th *ngIf="!mob" style="font-weight: bold; cursor: pointer; width: 60%;" (click)="sortby()">
                                    Name
                                    <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                                </th>
                                <th style="font-weight: bold; cursor: pointer;" *ngIf="mob" (click)="sortbydate()">
                                    Modified
                                    <span class="fa fa-stack">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                      <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                                </th>
                                <th style="font-weight: bold;" *ngIf="mob">Type</th>
                                <th style="font-weight: bold;">Operations</th>

                            </thead>
                            <tbody *ngFor="let folderview of allFolders">
                                <tr>
                                    <td *ngIf="mob" style="text-align: center;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="!mob" style="text-align: center; width: 20px;">
                                        <input type="checkbox" (click)="toggleSelect(folderview)" style="height: 16px; width: 16px; cursor: pointer;" />
                                    </td>
                                    <td *ngIf="mob" style="
                        white-space: nowrap;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      ">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="!mob" style="
                        white-space: nowrap;
                        width: 60%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      ">
                                        <i class="fa fa-folder" style="color: rgb(84 118 254);;"></i> &nbsp;&nbsp;
                                        <span (click)="GetSubfolder(folderview)" style="cursor: pointer;" title="{{ folderview.folderName }}" *ngIf="!mob">{{ folderview.folderName }}</span>
                                    </td>
                                    <td *ngIf="mob">
                                        {{ folderview.createdOn | date: "short" }}
                                    </td>
                                    <td *ngIf="mob">Object Folder</td>

                                    <td *ngIf="!multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" style="cursor: pointer;" ngbDropdownToggle><i
                            class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DownloadFolder(folderview)" style="cursor: pointer;">Download</a>
                                                <a class="dropdown-item" (click)="ShareFolder(folderview, shareSingleFolder)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="UpdateFolder(folderview, editfolder)" style="cursor: pointer;">Edit</a>
                                                <a class="dropdown-item" (click)="MoveTo(folderview, 'Doc')" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="Delete(folderview)" style="cursor: pointer;">Delete</a>

                                            </div>
                                        </div>

                                    </td>
                                    <td *ngIf="multi">
                                        <div ngbDropdown class="dropdown" placement="bottom-left">
                                            <a class="nav-link" id="dropdownBasic1" style="cursor: pointer;" ngbDropdownToggle><i
                            class="fa fa-gear" aria-hidden="true"></i></a>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                                                <a class="dropdown-item" (click)="DeleteMorecoin(deleteMultiConformation)" style="cursor: pointer;">Delete</a>
                                                <a class="dropdown-item" (click)="SharewithMoreFriends(sharewithmore)" style="cursor: pointer;">Share</a>
                                                <a class="dropdown-item" (click)="MotoselectedFolde(Movetofold)" style="cursor: pointer;">Move</a>
                                                <a class="dropdown-item" (click)="CheckDownloadMultiple()" style="cursor: pointer;">Download</a>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>








            <!-- <div class="col-md-2" *ngIf="mobile">
                <div style="width: 100%;">
                    <div style="width: 15%; float: left; text-align: center;">

                        <label style="
                z-index: 100;
                font-size: 16px;
                border: none;
                background: transparent;
                display: block;
              ">
              <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">&nbsp;Upload
                Folder</b>

              <input type="file" #folderInput id="excel-upload" multiple="true" style="display: none;"
                (change)="filesPicked(folderInput.files)" webkitDirectory />
            
            </label>
                    </div>
                    <div style="width: 15%; float: right; text-align: center;">
                        <div id="moveid">
                            <button type="button" id="moveid" class="btn" style="padding: 7px; cursor: pointer;" (click)="MoveDash1()">
                Move Here
              </button>
                            <span (click)="cancel()" style="margin-top: 10px; cursor: pointer;"><i class="fa fa-window-close"
                  title="Cancel" style="cursor: pointer; padding: 7px; font-size: 1.5em;"></i></span>
                            <br />
                        </div>

                        <div style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', foldercontent)">
                            <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">&nbsp;Create
                Folder</b>
                        </div>
                        <div *ngIf="role == 11" style="border: none; background: transparent; display: block;" (click)="addnewFolder('folderview', addclient)">
                            <b class="temp-upload-icon" for="excel-upload" style="cursor: pointer; padding: 0px;">&nbsp;Add Client</b>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
      <!-- IMAGE VIEW -->
<ng-template #content let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <div>&nbsp;</div>
        <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.DocumentName }}</h6>
        <h6 style="float: left;">
            Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
        </h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />

        <br /><br />
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <div class="col-lg-6">
                <!-- <a [href]="imgdisplay.file" style="cursor: pointer;">
                    <button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a> -->
                <button class="btn btn-info" (click)="abc(imgdisplay)">
                    <i class="fa fa-download"></i>Download
                </button>
            </div>
        </div>
    </div>
</ng-template>
    <!-- FILES VIEW -->
    <ng-template #pdftesting let-d="dismiss" let-c="dismiss">
        <div class="modal-header" style="background-color: white;">
            <div>&nbsp;</div>
            <h6 style="text-align: center;">{{ zzz.documentName }} <br /></h6>
            <h6 style="float: left;">
                &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzz.date | date: "short" }}
            </h6>

            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body" style="background-color: white;">
            <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
            <br /><br />

            <!-- <a [href]="pdfUrl"><button class="btn btn-info"><i class="fa fa-download"></i>Download</button> </a> -->

            <div class="col-lg-12 row">
                <div class="col-lg-5"></div>
                <div class="col-lg-6">
                    <!-- <a [href]="pdfUrl">
                        <button class="btn btn-info">
                            <i class="fa fa-download"></i>Download
                        </button>
                    </a> -->
                    <button class="btn btn-info" (click)="abc(zzz)">
                        <i class="fa fa-download"></i>Download
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
        <div>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                    <b>Select Members to Share</b>
                </h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <!-- <div class="modal-body" style="font-size: 16px;">

                <br />
            </div> -->

            <!-- <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
                {{ mem.friendName }}<br />
            </div>
            <div class="col-lg-12 row">
                <select *ngIf="shareitem" class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
          <option value="" hidden> Select Friend </option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
            {{ I.friendName }}
          </option>
        </select>
            </div> -->

            <div class="modal-body" style="font-size: 16px">
                <div>
                    <div id="aboveTagInp">
                        <!--...... <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                            [separatorKeyCodes]="[13,188,186,32]">

                            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                                <ng-template let-item="item" let-index="index">
                                    {{ item.friendName }}
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input> ......-->
                        <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="frndslistnames"
                        [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                      </ng-multiselect-dropdown>
                    </div>
                </div>
                <br />
            </div>
            <br />

            <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list?
          &nbsp;</span>
                <button type="button" class="btn btn-primary" (click)="addfrnd()">
          Add Friend
        </button>
            </div>
            <div *ngIf="frndrqst">
                <div>

                    <br>
                    <input type="text" placeholder="Registered Email/Mobile Number" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
                </div>
                <br />
                <div>
                    <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()" style="color: black;padding: 5px;margin-left: 10px;">
            Send Request
          </button>
                </div>
            </div>
            <br />
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="ShareExsistingFolderToUsersForm()" (click)="d('Cross click')">
          Share
        </button>
            </div>
        </div>
        <br />
    </ng-template>

    <ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Select Members to Share</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <!-- <div class="modal-body" style="font-size: 16px;">
            <div class="col-lg-12 row" *ngFor="let mem of selectedFriend">
                {{ mem.friendName }}<br />
            </div>
            <div class="col-lg-12 row">
                <select *ngIf="shareitem" class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
          <option value=""> Select Friend </option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
            {{ I.friendName }}
          </option>
        </select>
            </div>
            <br />
        </div> -->
        <div class="modal-body" style="font-size: 16px">
            <div>
                <div id="aboveTagInp">
                    <!--...... <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                        [separatorKeyCodes]="[13,188,186,32]">

                        <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                            <ng-template let-item="item" let-index="index">
                                {{ item.friendName }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input> ......-->
                    <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="frndslistnames"
                    [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>
            </div>
            <br />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()" (click)="d('Cross click')" style="cursor: pointer;">
        Share
      </button>
        </div>
        <br />
    </ng-template>
    <ng-template #shareall let-d="dismiss" let-c="dismiss">
        <div>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                    <b>Select Members to Share</b>
                </h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <!-- <div class="modal-body" style="font-size: 16px;">

                <br />
            </div>

            <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;">
                {{ mem.friendName }}<br />
            </div>
            <div class="col-lg-12 row">
                <select *ngIf="shareitem" class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)">
          <option value="" hidden> Select Friend </option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.friendGUID }}" style="cursor: pointer;">
            {{ I.friendName }}
          </option>
        </select>
            </div> -->
            <div class="modal-body" style="font-size: 16px">
                <div>
                    <div id="aboveTagInp">
                        <!--...... <tag-input ng-disabled="all" id="ShareTagInp" [ngModel]="selectedFriend" name="test" [onlyFromAutocomplete]="false" (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true" [errorMessages]="errorMessages" [validators]="regexp"
                            [separatorKeyCodes]="[13,188,186,32]">

                            <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000" [focusFirstElement]="true" [displayBy]="'friendName'" [identifyBy]="'uid'" [autocompleteItems]="frndslist">
                                <ng-template let-item="item" let-index="index">
                                    {{ item.friendName }}
                                </ng-template>
                            </tag-input-dropdown>
                        </tag-input> ......-->
                        <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="dropdownSettings" [data]="frndslistnames"
                        [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                      </ng-multiselect-dropdown>

                    </div>
                </div>
                <br />
            </div>
            <br />

            <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list?
          &nbsp;</span>
                <button type="button" class="btn btn-primary" (click)="addfrnd()">
          Add Friend
        </button>
            </div>
            <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Do you wish to cancel shared documents?
          &nbsp;</span>

                <button type="button" class="btn btn-primary" (click)="cancelshare()">
          Cancel Shared Documents
        </button>
            </div>
            <div *ngIf="frndrqst">
                <div>
                    <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;">
                                                      Email:
                                                  </h5> -->
                    <br>
                    <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
                </div>
                <br />
                <div>
                    <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()" style="color: black;padding: 5px;margin-left: 10px;">
            Send Request
          </button>
                </div>
            </div>
            <br />
            <div *ngIf="cancelshareall">

                <div class="col-lg-12 row">
                    <select *ngIf="shareitem1" class="form-control dropdownsize" (change)="ShareFileMembers1($event.target.value)">
            <option value="" hidden> Select Friend </option>
            <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer;">
              {{ I.friendName }}
            </option>
          </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="shareitem1" class="btn btn-primary" (click)="shareallallcancel()" (click)="d('Cross click')">
          Cancel Shared Documents
        </button> &nbsp; &nbsp;
                <button type="button" *ngIf="!shareitem1" class="btn btn-primary" (click)="shareallall()" (click)="d('Cross click')">
          Share
        </button>
            </div>
        </div>
        <br />
    </ng-template>


    <div class="modal fade" id="Movetofold" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 4%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Select Folder to Move
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12">
                            <div>
                                <div *ngFor="let a of allFolders1" id="{{ a.folderID }}">
                                    <span><i class="fa fa-caret-right" (click)="getfolders1(a)" id="{{ a.id }}"
                      style="cursor: pointer;"></i>
                    <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="gotoFolder(a, Movetofold)" />
                    <span (click)="gotoFolder(a, Movetofold)" style="cursor: pointer;">{{ a.folderName }}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="MoveMultiFolder(destinationFold)" style="cursor: pointer;">
            Move
          </button>
                    <button type="button" id="closemove" class="btn btn-warning" data-dismiss="modal" style="cursor: pointer;">
            Close
          </button>
                </div>
            </div>
        </div>
    </div>

    <ng-template #deleteMultiConformation let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Delete Data </b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body" style="font-size: 16px;">
            <div class="col-lg-12 row">
                <h4 class="modal-title w-100" id="myModalLabel">
                    <b>Do You want to Delete {{ deletemulticontent }} &nbsp; ?</b>
                </h4>
            </div>
            <br />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="Deleteselected()" (click)="d('Cross click')" style="cursor: pointer;">
        Delete
      </button>
            <button type="button" class="btn btn-warning" (click)="d('Cross click')" style="cursor: pointer;">
        Close
      </button>
        </div>
        <br />
    </ng-template>

    <!-- PROGRESS BAR Anusha-->
    <div class="col-lg-12 row" *ngIf="showper">
        <div class="col-lg-3"></div>
        <div class="col-lg-7" style="position: fixed; top: 86%; left: 20%;">
            <p class="alert alert-info" style="box-shadow: white; background-color: white;" *ngIf="generalprogressbar">
                <b *ngIf="folderCreating && !foldercreated" style="color: black;">{{ Creatingmsg }} :{{ n }}%</b>
                <img src="./assets/img/tick.png" height="20px;" width="20px;" *ngIf="foldercreated && !folderCreating" />&nbsp;&nbsp;
                <b style="color: black;" *ngIf="foldercreated && !folderCreating">&nbsp;&nbsp; {{ createdmsg }}
        </b>
                <br />
                <progress value="{{ n }}" max="100" style="width: 100%; height: 7px;">
        </progress>&nbsp;&nbsp;{{ n }}%
            </p>
        </div>
    </div>

    <ng-template #folderdetails let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
                <b>Folder Details</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body" style="font-size: 16px;">
            <div class="col-lg-12 row">
                <div class="col-md-6">Folder Name :</div>
                <div class="col-md-6" style="color: blue;" style="cursor: pointer;">
                    {{ FolderName }}
                </div>
            </div>
            <div class="col-lg-12 row">
                <div class="col-lg-6">Created On :</div>
                <div class="col-lg-6" style="text-align: left; color: blue;">
                    {{ CreatedOn | date: "short" }}
                </div>
            </div>
            <!-- <div class="col-md-12 row">
      <div class="col-md-6">CustomerId : </div>
      <div class="col-md-6" style="color: blue;"> {{CusId}}</div>
    </div> -->
            <br />
        </div>
        <br />

        <div class="modal-footer">
            <button type="button" class="btn btn-warning" (click)="d('Cross click')" style="cursor: pointer;">
        Close
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #foldercontent let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="msg" [(ngModel)]="msg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="savenewFolder()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>



    <ng-template #adddeligence let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Due Diligence</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" *ngIf="isLoggedIn">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <h5 class="modal-title" id="modal-basic-title"> Enter the Name:</h5>
            <input type="text" id="ddname" [(ngModel)]="dname" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="saveduedeligence()" (click)="d('Cross click')" style="cursor: pointer;">
                Add Due Diligence
            </button>
        </div>
        <br />
    </ng-template>
    <!-- Enterprise migratiom -->

    <ng-template #addmigrate let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Migrate Folder/Files</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" *ngIf="isLoggedIn && roleid == '1'">
                  <span aria-hidden="true">&times;</span>
              </button>
        </div>
        <div class="modal-body">

            <h5 class="modal-title" id="modal-basic-title"> Enter the Email:</h5>
            <input type="text" id="mail" [(ngModel)]="mail" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="savemigrate()" (click)="d('Cross click')" style="cursor: pointer;">
                  Migrate
              </button>
        </div>
        <br />
    </ng-template>

    <!-- <ng-template #addclient let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Client</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Client Name:</h5>
            <input type="text" id="client" [(ngModel)]="client" class="form-control" name="dp"(change)="Validateusername()" /><br />
            <h6 *ngIf="validusername" style="color: red">
              UserName already taken, Try with other UserName
            </h6>

            <h5 class="modal-title" id="modal-basic-title">Email:</h5>
            <input type="text" id="email" [(ngModel)]="email" class="form-control" name="dp"   (change)="Validatemail()" 
              readonly />
            <h5 *ngIf="validmail" style="color: red">Email already exist!!</h5>
        </div>
        <br />
        
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="savenewClient()"  (click)="SaveUser()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
                Add
              </button>
        </div>
        <br />
    </ng-template> -->

    <ng-template #addcousultant let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Consultant/Regelatee</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Consultant/Regelatee Name:</h5>
            <input type="text" id="clientname" [(ngModel)]="clientname" class="form-control" name="dp" /><br />




            <h5 class="modal-title" id="modal-basic-title">Email:</h5>
            <input type="text" id="clientemail" [(ngModel)]="clientemail" class="form-control" name="dp" />

        </div>
        <br />

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="consaltantreg()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #editfolder let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="UpdateFolderName()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black;">
        Update
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #subfoldercontent let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="msg" [(ngModel)]="msg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="savenewSubFolder()" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>

    <ng-template #addclient let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Client</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Client Name:</h5>
            <input type="text" id="clientname" [(ngModel)]="clientname" class="form-control" name="dp" /><br />




            <h5 class="modal-title" id="modal-basic-title">Email:</h5>
            <input type="text" id="clientemail" [(ngModel)]="clientemail" class="form-control" name="dp" />

        </div>
        <br />

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="regnewclient()" (click)="d('Cross click')" style="cursor: pointer;" style="color: black; width: 16%;">
        Add
      </button>
        </div>
        <br />
    </ng-template>



    <ng-template #editsubfolder let-d="dismiss" let-c="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
            <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
        </div>
        <br />
        <div class="modal-footer">
            <button type="button" class="btn btn-success" (click)="UpdateSubFolderName()" style="cursor: pointer;" style="color: black;">
        Update
      </button>
        </div>
        <br />
    </ng-template>










    <button type="button" id="ppup" data-toggle="modal" data-target="#exampleModal213" style="display: none;">
    <span style="
        width: 30px;
        height: 30px;
        border-radius: 15%;
        background-color: #edf3ff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 25px;
      "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
  </button>

    <button type="button" id="ppup12" data-toggle="modal" data-target="#Movetofold" style="display: none;">
    <span style="
        width: 30px;
        height: 30px;
        border-radius: 15%;
        background-color: #edf3ff;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 25px;
      "><i class="fa fa-plus" title="Upload Object"></i></span>
    Upload Object
  </button>

    <div class="modal fade" id="exampleModal213" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="height: 100vh; overflow-y: auto;">
        <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 10%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 5px;">
                        Upload Folder
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="canceltree()">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="col-md-12">
                            <div>
                                <div *ngFor="let a of allFolders1" id="{{ a.folderID }}">
                                    <span>
                                        <!-- <i class="fa fa-caret-right" (click)="getfolders1(a)" style="cursor: pointer; margin-left: 5px;"
                      id="{{ a.id }}"></i> -->
                      <i (click)="getfolders1(a)" style="cursor: pointer; margin-left: 5px;"
                      id="{{ a.id }}"></i>
                    <img width="30px" height="30px" src="./assets/img/folder.svg" (click)="gotoFolder12(a)" />
                    <span (click)="gotoFolder40(a)" style="cursor: pointer;">{{
                      a.folderName
                    }}</span></span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf='showbutton'>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12" style="text-align: center;">Or</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12" style="text-align: center;"  id="Uploadhere" (click)="uploadhere()" style="cursor: pointer;">
                            Upload Here
                        </div>
                    </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="cancelUpload1" class="btn btn-secondary" (click)="cancelupload()" data-dismiss="modal">
            Cancel
          </button>
                    <button type="button" class="btn btn-primary" (click)="Uploaddocs1()" style="cursor: pointer;">
            Upload
          </button>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">AI Document Search</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input  [(ngModel)]="searchTerm" class="form-control"  type="text" name="searchtext" placeholder="Type text here">
  
          <button  class="btn btn-primary" (click)="searchPdf()"> Search PDF</button>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Document Name</th>
                <th scope="col">Page Numbers</th>
                <th scope="col">Document</th>
              </tr>
            </thead>          <tbody>
              <tr *ngFor="
              let aa of searchresult | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              ">
                <th scope="row">{{ 5 * (p - 1) + (i + 1) }}</th>
                <td>{{aa.documentname}}</td>
                <td>{{aa.documentpagenumber}}</td>
                <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 70%;"
              (click)="ViewDocument1(aa,pdftesting)">
                                  <img src="assets/img/pdficon.png"
                                      style="width: 35px; height: 34px; cursor: pointer;" />
                                  <span (click)="ViewDocument1(aa,pdftesting)" style="cursor: pointer;"
                                      title="{{ aa.DocumentName }}">
                                      &nbsp;&nbsp;{{ aa.DocumentName }}
                                  </span>
                              </td>
              </tr>
          
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>