<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>

<div class="container">
    <div class="text-center">
        <div class="nav price-tabs" role="tablist">
            <a class="nav-link active" href="#yearly" role="tab" data-toggle="tab">Choose your plan!</a 
    >
</div>
</div>

  
    <form class="register-form" #loginForm="ngForm">
        <div class="col-md-12 row tab-content wow fadeIn text-center" style="visibility: visible; animation-name: fadeIn;">
            <div class="col-md-4" *ngFor="let plans of PayuPlans; let i = index">
                <div class="price-item text-center">
                    <div class="price-top">
                        <h4>{{plans.type}}</h4>
                        <h2 class="mb-0"><sup>Rs</sup>{{plans.price}}</h2>
                        <span class="text-capitalize">per year</span>
                    </div>
                    <div class="price-content">
                        <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                            <li>
                                <i class="zmdi zmdi-check mr-2"></i>
                                <span class="c-black">{{plans.period}} GB Storage</span>
                            </li>
                            <!-- <li>
                                <i class="zmdi zmdi-check mr-2"></i>
                                <span class="c-black">Chat</span>
                            </li>

                            <li>
                                <i class="zmdi zmdi-close mr-2"></i>
                                <span>Post Preliminary Rounds</span>
                            </li>
                            <li>
                                <i class="zmdi zmdi-close mr-2"></i>
                                <span>Video Screening</span>
                            </li> -->
                        </ul>
                        <button href="#" class="btn btn-custom" (click)="payforplan(plans)">
                  Buy now
                </button>
                    </div>
                </div>
            </div>

         

        </div>
    </form>
    <br /><br /><br />
</div>