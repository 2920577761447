<app-nav-bar></app-nav-bar>
<div>&nbsp;</div>

<h1 style="text-align: center;color: darkblue;">SETUP</h1>

<div>&nbsp;</div>
<div class="col-md-12 row">
    <div class="column">
        <div class="card" style="background-color: gray">
            <br />
            <div class="col-md-12 row">
                <div class="col-md-7">
                    <p class="complaintext">Company</p>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-building" style="font-size: 50px;
                    color: white;"></i>
                </div>
            </div>
            
            <P style="padding-left: 10px;color: white;">Setup Company by Managing Users, Roles, Departments etc.</P>
            <br />
            <div class="col-md-12 row" style="border-top: solid #dfdfdf 1px;margin-left: 1px;">
                <div class="col-md-1"></div>
                <div class="col-md-8" style="padding-bottom: 11px;">
                    <br />
                    <a style="color: #bee1ff;font-weight: 700;text-align: center;" href="#/setupdata/company">Manage Company</a>
                </div>
              
            </div>
        </div>

    </div>
    <div class="column">

        <div class="card" style="background-color: gray">
            <br />
            <div class="col-md-12 row">
                <div class="col-md-7">
                    <p class="complaintext">Services</p>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-tools" style="font-size: 50px;
                    color: white;"></i>
                </div>
            </div>
            <P style="padding-left: 10px;color: white;">Get New Services for Your Company like Accounts, Billing etc.</P>
            <br />
            <div class="col-md-12 row" style="border-top: solid #dfdfdf 1px;margin-left: 1px;">
                <div class="col-md-1"></div>
                <div class="col-md-8" style="padding-bottom: 11px;">
                    <br />
                    <a style="color: #bee1ff;font-weight: 700;text-align: center;" href="#/setupdata/service">Manage Services</a>
                </div>
             
               
            </div>
           
        </div>
    </div>

</div>
<div>&nbsp;</div>
<div>&nbsp;</div><div>&nbsp;</div>
<div>&nbsp;</div>