
<app-nav-bar></app-nav-bar>
<br>
<br>


    <br>
    <br>
    <div class="col-md-12 row viewrawdatasetmedia">
    <table class="table table-bordered">

        <!-- <tr>
            <th *ngFor="let aa of Columns">{{aa}}</th>
        </tr> -->

        <tr>
            <th >Sheet1</th>
        </tr>

        <tr *ngFor="let dt of Data;index as i">
            <td *ngFor="let rr of dt | keyvalue">{{rr.value}}</td>

        </tr>
    </table>

    <table class="table table-bordered">

        
        <tr>
            <th >Sheet2</th>
        </tr>
<!-- 
        <tr>
            <th *ngFor="let aa1 of Columns1">{{aa1}}</th>
        </tr> -->


        <tr *ngFor="let dt1 of Data1;index as i">
            <td *ngFor="let rr1 of dt1 | keyvalue">{{rr1.value}}</td>

        </tr>
    </table>
    <table class="table table-bordered">

        <!-- <tr>
            <th *ngFor="let aa2 of Columns2">{{aa2}}</th>
        </tr> -->

        <tr>
            <th >Sheet3</th>
        </tr>

        <tr *ngFor="let dt2 of Data2;index as i">
            <td *ngFor="let rr2 of dt2 | keyvalue">{{rr2.value}}</td>

        </tr>
    </table>
    <table class="table table-bordered">

        <!-- <tr>
            <th *ngFor="let aa3 of Columns3">{{aa3}}</th>
        </tr> -->

        <tr>
            <th >Sheet4</th>
        </tr>

        <tr *ngFor="let dt1 of Data3;index as i">
            <td *ngFor="let rr1 of dt1 | keyvalue">{{rr1.value}}</td>

        </tr>
    </table>
    </div>

<br>
<br>