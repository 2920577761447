<p><b><h1 style="text-align: center;">Privacy Policy for Blocdrive Extension</h1></b>
<br>
    Last updated: 16-10-2023
    <br>
    <b>Introduction</b>
    <br>
    Welcome to Blocdrive Extension! We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy is intended to inform you about the types of information we collect and how we use, share, and protect that information.
    <br><br>
    <b>Information We Collect</b> 
    <br>
    User-Provided Information: When you use Blocdrive Extension, we may collect the following information:
    <br><br>
    Your name, email address,Mail subject, Mail body and other details you provide.
    Your Gmail account information, including email messages and contacts as necessary for the functioning of the extension.
    <br>
    <b>How We Use Your Information</b>
    <br>
    <b>We use the information we collect for the following purposes:</b>
    <br><br>
    To provide, maintain, and improve our Gmail extension.
    To communicate with you, including responding to your requests and inquiries.
    To customize your experience and enhance the functionality of our extension.
    To send you updates and important information about our services.
    Sharing Your Information
    <br>
    <b>We may share your information under the following circumstances:</b>
    <br>
    With trusted third-party service providers who help us operate and maintain our extension.
    In response to a legal request or to comply with applicable laws and regulations.
    To protect our rights, privacy, safety, or property and that of our users.
    In the event of a business transfer, such as a merger, acquisition, or sale of all or part of our assets.
    Security
    <br><br>
    We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of data transmission over the internet is 100% secure, and we cannot guarantee the absolute security of your data.
    <br>
    <b>Your Choices</b>
    <br>
    <b>You have the following choices regarding your personal information:</b>
    <br><br>
    You may review, update, or delete the personal information you have provided to us.
    You can opt-out of receiving promotional communications from us by following the instructions in those messages.
    Children's Privacy
    <br>
    Our extension is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us so we can delete it.
    <br><br>
    <b>Changes to this Privacy Policy</b>
    <br>
    We may update this Privacy Policy from time to time. The most recent version will be posted on our website. Your continued use of our extension after any changes indicate your acceptance of those changes.
    <br><br>
    <b>Contact Us</b>
    <br>
    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at info@srichidtechnologies.com.</p>
    <br><br>