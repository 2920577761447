<div class="container" style="background-color:white">
    <div class="col-md-12 row">
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-10">
            <br />
            <div class="text-center">
                <h2><b>CLIENT REGISTRATION</b></h2>
            </div><br />
            <div>
                <mat-tab-group>
                    <mat-tab label="Individual Client">
                        <div>&nbsp;</div>
                        <div class="form-horizontal">
                            <form [formGroup]="individual">
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="firstname"
                                            formControlName="firstname" [(ngModel)]="firstname"
                                            placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && i.firstname.errors }" />
                                            <div *ngIf="submitted && i.firstname.errors" class="invalid-feedback">
                                                <div *ngIf="i.firstname.errors.required">First Name  is required</div>
                                              </div>
                                        </div>
                                        <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="lastname"
                                            formControlName="lastname" [(ngModel)]="lastname" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && i.lastname.errors }"/>
                                            <div *ngIf="submitted && i.lastname.errors" class="invalid-feedback">
                                                <div *ngIf="i.lastname.errors.required">Last Name  is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <select (change)="OnselectGender($event.target.value)" class="form-control dropdownsize">
                                            <option value="">Select Gender</option>
                                            <option *ngFor="let do of Genders" value={{do.id}}>
                                                {{do.gender}}
                                            </option>
                                        </select>

                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="address" formControlName="address"
                                            [(ngModel)]="address" placeholder="Address"  [ngClass]="{ 'is-invalid': submitted && i.address.errors }"/>
                                            <div *ngIf="submitted && i.address.errors" class="invalid-feedback">
                                                <div *ngIf="i.address.errors.required">Address  is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="pincode"
                                            [(ngModel)]="pincode" placeholder="Pin code"  [ngClass]="{ 'is-invalid': submitted && i.pincode.errors }"/>
                                            <div *ngIf="submitted && i.pincode.errors" class="invalid-feedback">
                                                <div *ngIf="i.pincode.errors.required">Pin code  is required</div>
                                                <div *ngIf="i.pincode.errors.pattern">Enter Valid Pin code </div>

                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="email" formControlName="email"
                                            [(ngModel)]="email" placeholder="Email"  [ngClass]="{ 'is-invalid': submitted && i.email.errors }"/>
                                            <div *ngIf="submitted && i.email.errors" class="invalid-feedback">
                                                <div *ngIf="i.email.errors.required">Email  is required</div>
                                                <div *ngIf="i.email.errors.email">Enter valid Email</div>

                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="number" class="form-control" name="mobile" formControlName="mobile"
                                            [(ngModel)]="mobile" placeholder="Mobile Number"  [ngClass]="{ 'is-invalid': submitted && i.mobile.errors }"/>
                                            <div *ngIf="submitted && i.mobile.errors" class="invalid-feedback">
                                                <div *ngIf="i.mobile.errors.required">Mobile Number  is required</div>
                                                <div *ngIf="i.mobile.errors.pattern">Enter valid Phone number</div>

                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="landline"
                                            [(ngModel)]="landline" placeholder="Landline Number"  [ngClass]="{ 'is-invalid': submitted && i.landline.errors }"/>
                                            <div *ngIf="submitted && i.landline.errors" class="invalid-feedback">
                                                <div *ngIf="i.landline.errors.required">Landline Number  is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="occupation"
                                            [(ngModel)]="occupation" placeholder="Occupation"  [ngClass]="{ 'is-invalid': submitted && i.occupation.errors }"/>

                                            <div *ngIf="submitted && i.occupation.errors" class="invalid-feedback">
                                                <div *ngIf="i.occupation.errors.required">Occupation  is required</div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="qualification"
                                            [(ngModel)]="qualification" placeholder="Qualification"  [ngClass]="{ 'is-invalid': submitted && i.qualification.errors }"/>

                                            <div *ngIf="submitted && i.qualification.errors" class="invalid-feedback">
                                                <div *ngIf="i.qualification.errors.required">Qualification  is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="voterId"
                                            [(ngModel)]="voterId" placeholder="Voter Id" [ngClass]="{ 'is-invalid': submitted && i.voterId.errors }" />
                                            <div *ngIf="submitted && i.voterId.errors" class="invalid-feedback">
                                                <div *ngIf="i.voterId.errors.required">Voter Id  is required</div>
                                              </div>
                                        </div> <br/>
                                    <!--<div class="col-xs-6">
                                        <input type="text" class="form-control" maxlength="12" ng-model="customer.aadhaarNumber" placeholder="Aadhaar Number" />
                                    </div>-->
                                </div>

                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="authpersonname"
                                            [(ngModel)]="authpersonname" placeholder="Authorised Person's Name"  [ngClass]="{ 'is-invalid': submitted && i.authpersonname.errors }"/>
                                            <div *ngIf="submitted && i.authpersonname.errors" class="invalid-feedback">
                                                <div *ngIf="i.authpersonname.errors.required">Authorised Person's Name  is required</div>
                                              </div>
                                        </div> <br/>
                                    <!--<div class="col-xs-6">
                                        <input type="number" class="form-control" maxlength="12" ng-model="customer.authpersonmobile" placeholder="Authorised Person's Mobile" />
                                    </div>-->
                                    <div class="col-xs-6">

                                        <input type="number" class="form-control" formControlName="authpersonmobile"
                                            [(ngModel)]="authpersonmobile"
                                            placeholder="Authorised Person’s Contact Number"  [ngClass]="{ 'is-invalid': submitted && i.authpersonmobile.errors }"/>
                                            <div *ngIf="submitted && i.authpersonmobile.errors" class="invalid-feedback">
                                                <div *ngIf="i.authpersonmobile.errors.required">Authorised Person’s Contact Number  is required</div>
                                                <div *ngIf="i.authpersonmobile.errors.pattern">Enter valid Contact Number </div>
  
                                            </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="authpersonemail"
                                            [(ngModel)]="authpersonemail" placeholder="Authorised Person's Email-id"  [ngClass]="{ 'is-invalid': submitted && i.authpersonemail.errors }"/>
                                            <div *ngIf="submitted && i.authpersonemail.errors" class="invalid-feedback">
                                                <div *ngIf="i.authpersonemail.errors.required">Authorised Person's Email-id  is required</div>
                                                <div *ngIf="i.authpersonemail.errors.email">Enter valid Email </div>

                                            </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="authpersonpostaladdress"
                                            [(ngModel)]="authpersonpostaladdress"
                                            placeholder="Authorised Person's Postal Address"  [ngClass]="{ 'is-invalid': submitted && i.authpersonpostaladdress.errors }"/>
                                            <div *ngIf="submitted && i.authpersonpostaladdress.errors" class="invalid-feedback">
                                                <div *ngIf="i.authpersonpostaladdress.errors.required">Authorised Person's Postal Address  is required</div>
                                              </div>
                                        </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <!--<label>Photo</label>
                                        <input type="file" />-->
                                    </div>
                                    <div class="col-xs-6">
                                        <!--<input type="text" class="form-control" maxlength="12" ng-model="customer.source" placeholder="How did you hear about us?" />-->
                                        <!--<input ng-disabled="individual.$invalid" type="submit" ng-click="PostIndividualCustomer()" class="form-control btn-info" value="Submit" />-->
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-4 "></div>
                                    <div class="col-xs-4 ">
                                        <button type="submit" (click)="PostIndividualCustomer()"
                                            class="form-control btn-info">Submit</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </mat-tab>
                    <mat-tab label="Corporate Client">
                        <div>&nbsp;</div>
                        <div class="form-horizontal">
                            <form [formGroup]="Corporate">
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="companyName"
                                            formControlName="companyName" [(ngModel)]="companyName"
                                            placeholder="Company Name" [ngClass]="{ 'is-invalid': submitted && c.companyName.errors }"/>
                                            <div *ngIf="submitted && c.companyName.errors" class="invalid-feedback">
                                                <div *ngIf="c.companyName.errors.required">Company Name  is required</div>
                                              </div>
                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="date" class="form-control" formControlName="eshtablishedDate" name="eshtablishedDate"
                                            [(ngModel)]="eshtablishedDate" placeholder="Year Of Established Date" [ngClass]="{ 'is-invalid': submitted && c.eshtablishedDate.errors }"/>
                                            <div *ngIf="submitted && c.eshtablishedDate.errors" class="invalid-feedback">
                                                <div *ngIf="c.eshtablishedDate.errors.required">Year Of Established Date is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <select (change)="OnselectCorporate($event.target.value)" class="form-control dropdownsize">
                                            <option value="">Select Client Type</option>
                                            <option *ngFor="let do of CorporateSubcategories"
                                                value={{do.corporateSubCategoryId}}>
                                                {{do.corporateSubCategoryName}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="companyCategory"
                                            [(ngModel)]="companyCategory"
                                            placeholder="Company Category(Private or Public Ltd.)" [ngClass]="{ 'is-invalid': submitted && c.companyCategory.errors }"/>
                                            <div *ngIf="submitted && c.companyCategory.errors" class="invalid-feedback">
                                                <div *ngIf="c.companyCategory.errors.required">Company Category  is required</div>
                                              </div>
                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="website" name="website"
                                            [(ngModel)]="website" placeholder="Website" [ngClass]="{ 'is-invalid': submitted && c.website.errors }"/>
                                            <div *ngIf="submitted && c.website.errors" class="invalid-feedback">
                                                <div *ngIf="c.website.errors.required">Website  is required</div>
                                              </div>
                                        </div>
                                </div>

                                <div class="form-group">

                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="officialEmailId"
                                            [(ngModel)]="officialEmailId" placeholder="Official Email" [ngClass]="{ 'is-invalid': submitted && c.officialEmailId.errors }"/>
                                            <div *ngIf="submitted && c.officialEmailId.errors" class="invalid-feedback">
                                                <div *ngIf="c.officialEmailId.errors.required">Official Email is required</div>
                                                <div *ngIf="c.officialEmailId.errors.email">Enter valid Email </div>
  
                                            </div>
                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="number" class="form-control" name="CorporateofficialConntactNumber"
                                            formControlName="CorporateofficialConntactNumber"
                                            [(ngModel)]="CorporateofficialConntactNumber"
                                            placeholder="Official Contact Number" [ngClass]="{ 'is-invalid': submitted && c.CorporateofficialConntactNumber.errors }"/>
                                            <div *ngIf="submitted && c.CorporateofficialConntactNumber.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporateofficialConntactNumber.errors.required">Official Contact Number  is required</div>
                                                <div *ngIf="c.CorporateofficialConntactNumber.errors.pattern">Enter Valid Contact Number </div>
 
                                            </div>
                                        </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="corporateDetailsaddress"
                                            formControlName="corporateDetailsaddress"
                                            [(ngModel)]="corporateDetailsaddress" placeholder="Address" required [ngClass]="{ 'is-invalid': submitted && c.corporateDetailsaddress.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailsaddress.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailsaddress.errors.required">Address  is required</div>
                                              </div>
                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="corporateDetailsbranch"
                                            formControlName="corporateDetailsbranch"
                                            [(ngModel)]="corporateDetailsbranch" placeholder="Branch Name" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailsbranch.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailsbranch.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailsbranch.errors.required">Branch Name  is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="corporateDetailsemployeeStrength"
                                            [(ngModel)]="corporateDetailsemployeeStrength"
                                            placeholder="Employee strength" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailsemployeeStrength.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailsemployeeStrength.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailsemployeeStrength.errors.required">Employee strength is required</div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6" *ngIf="selectedCorporateId==1">
                                        <input type="text" class="form-control" name="CorporatecontactPersonName"
                                            formControlName="CorporatecontactPersonName"
                                            [(ngModel)]="CorporatecontactPersonName"
                                            placeholder="Authorized Officer Name" [ngClass]="{ 'is-invalid': submitted && c.CorporatecontactPersonName.errors }"/>
                                            <div *ngIf="submitted && c.CorporatecontactPersonName.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporatecontactPersonName.errors.required">Authorized Officer Name  is required</div>
                                              </div>
                                    </div> <br/>
                                    <div class="col-xs-6" *ngIf="selectedCorporateId!=1">
                                        <input type="text" class="form-control" name="CorporatecontactPersonName"
                                            formControlName="CorporatecontactPersonName"
                                            [(ngModel)]="CorporatecontactPersonName"
                                            placeholder="Contact Person Name" [ngClass]="{ 'is-invalid': submitted && c.CorporatecontactPersonName.errors }"/>
                                            <div *ngIf="submitted && c.CorporatecontactPersonName.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporatecontactPersonName.errors.required">Contact Person Name is required</div>
                                              </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="selectedCorporateId==1">

                                    <div class="col-xs-6">
                                        <input type="number" class="form-control"
                                            name="CorporatecontactPersonMobileNumber"
                                            formControlName="CorporatecontactPersonMobileNumber"
                                            [(ngModel)]="CorporatecontactPersonMobileNumber"
                                            placeholder="Authorized Officer Phone Number" [ngClass]="{ 'is-invalid': submitted && c.CorporatecontactPersonMobileNumber.errors }"/>
                                            <div *ngIf="submitted && c.CorporatecontactPersonMobileNumber.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporatecontactPersonMobileNumber.errors.required">Authorized Officer Phone Number  is required</div>
                                                <div *ngIf="c.CorporatecontactPersonMobileNumber.errors.pattern">Enter valid Phone Number  </div>
 
                                            </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="CorporatecontactPersonEmail"
                                            formControlName="CorporatecontactPersonEmail"
                                            [(ngModel)]="CorporatecontactPersonEmail"
                                            placeholder="Authorized Officer Email Address" [ngClass]="{ 'is-invalid': submitted && c.CorporatecontactPersonEmail.errors }" />
                                            <div *ngIf="submitted && c.CorporatecontactPersonEmail.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporatecontactPersonEmail.errors.required">Authorized Officer Email Address  is required</div>
                                                <div *ngIf="c.CorporatecontactPersonEmail.errors.email">Enter valid Email  </div>

                                            </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="selectedCorporateId!=1">

                                    <div class="col-xs-6">
                                        <input type="number" class="form-control"
                                            name="CorporatecontactPersonMobileNumber"
                                            formControlName="CorporatecontactPersonMobileNumber"
                                            [(ngModel)]="CorporatecontactPersonMobileNumber"
                                            placeholder="Contact Person Phone Number" [ngClass]="{ 'is-invalid': submitted && c.CorporatecontactPersonMobileNumber.errors }"/>
                                            <div *ngIf="submitted && c.CorporatecontactPersonMobileNumber.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporatecontactPersonMobileNumber.errors.required">Contact Person Phone Number  is required</div>
                                                <div *ngIf="c.CorporatecontactPersonMobileNumber.errors.pattern">Enter valid Phone Number  </div>

                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="CorporatecontactPersonEmail"
                                            formControlName="CorporatecontactPersonEmail"
                                            [(ngModel)]="CorporatecontactPersonEmail"
                                            placeholder="Contact Person Email Address" [ngClass]="{ 'is-invalid': submitted && c.CorporatecontactPersonEmail.errors }"/>
                                            <div *ngIf="submitted && c.CorporatecontactPersonEmail.errors" class="invalid-feedback">
                                                <div *ngIf="c.CorporatecontactPersonEmail.errors.required">Contact Person Email Address is required</div>
                                                <div *ngIf="c.CorporatecontactPersonEmail.errors.pattern">Enter valid Email  </div>
  
                                            </div>
                                    </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6" *ngIf="selectedCorporateId==1">
                                        <input type="text" class="form-control"
                                            formControlName="corporateDetailscontactPersonDesignation"
                                            [(ngModel)]="corporateDetailscontactPersonDesignation"
                                            placeholder="Authorized Officer Designation" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailscontactPersonDesignation.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailscontactPersonDesignation.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailscontactPersonDesignation.errors.required">Authorized Officer Designation  is required</div>
                                              </div>
                                        <!--<select ng-model="corporateDetails.contactPersonDesignation" ng-options="i.id  as i.occupationName for i in Occupation" class="form-control" required>
                                            <option selected="selected" value="">Select Contact Person's Occupation</option>
                                        </select>-->
                                    </div> <br/>

                                    <div class="col-xs-6" *ngIf="selectedCorporateId!=1">
                                        <input type="text" class="form-control"
                                            formControlName="corporateDetailscontactPersonDesignation"
                                            [(ngModel)]="corporateDetailscontactPersonDesignation"
                                            placeholder="Contact Person Designation" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailscontactPersonDesignation.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailscontactPersonDesignation.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailscontactPersonDesignation.errors.required">Contact Person Designation is required</div>
                                              </div>
                                        <!--<select ng-model="corporateDetails.contactPersonDesignation" ng-options="i.id  as i.occupationName for i in Occupation" class="form-control" required>
                                            <option selected="selected" value="">Select Contact Person's Occupation</option>
                                        </select>-->
                                    </div><br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="corporateDetailsEmpoweredName"
                                            [(ngModel)]="corporateDetailsEmpoweredName"
                                            placeholder="Empowered Authority Name" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailsEmpoweredName.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailsEmpoweredName.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailsEmpoweredName.errors.required">Empowered Authority Name  is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="corporateDetailsEmpoweredDesignation"
                                            [(ngModel)]="corporateDetailsEmpoweredDesignation"
                                            placeholder="Empowered Authority Designation" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailsEmpoweredDesignation.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailsEmpoweredDesignation.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailsEmpoweredDesignation.errors.required">Empowered Authority Designation is required</div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="corporateDetailssource"
                                            [(ngModel)]="corporateDetailssource"
                                            placeholder="How did you hear about us?" [ngClass]="{ 'is-invalid': submitted && c.corporateDetailssource.errors }"/>
                                            <div *ngIf="submitted && c.corporateDetailssource.errors" class="invalid-feedback">
                                                <div *ngIf="c.corporateDetailssource.errors.required">Please let us know How did you hear about us?</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="col-xs-4 "></div>
                                        <div class="col-xs-4 ">
                                            <input type="submit" (click)="PostCustomerCorporateDetails()"
                                                class="form-control btn-info" value="Submit" />
                                        </div>
                                    </div>
                                    <!--&nbsp;
                                    <div class="col-xs-6">
                                        <label>-</label>
                                        <input type="submit" ng-click="PostCustomerCorporateDetails()" class="form-control btn-info" value="Submit" />
                                    </div>-->
                                </div>
                            </form>
                        </div>
                    </mat-tab>

                    <mat-tab label="Government Client">
                        <div>&nbsp;</div>
                        <div class="form-horizontal">
                            <form [formGroup]="Government">
                                <div class="form-group">
                                    <div class="col-xs-6">

                                        <select class="form-control dropdownsize"
                                            (change)="OnselectGovernmentType($event.target.value)">
                                            <option value="">Select Options</option>
                                            <option value="1">State Government</option>
                                            <option value="2">Union Territory</option>
                                            <option value="3">Government Of India</option>
                                        </select>

                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="date" class="form-control"
                                            formControlName="governmentCustomereshtablishedDate"
                                            [(ngModel)]="governmentCustomereshtablishedDate"
                                            placeholder="Established year" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomereshtablishedDate.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomereshtablishedDate.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomereshtablishedDate.errors.required">Established year is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">

                                        <select (change)="changewebsitelink($event.target.value)" class="form-control dropdownsize">
                                            <option value="">Select Client Type</option>
                                            <option *ngFor="let do of Subcategories" value={{do.subCategoryId}}>
                                                {{do.subCategoryName}}
                                            </option>
                                        </select> 
                                    </div> <br/>

                                    <div class="col-xs-6">

                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomerwebsite"
                                            [(ngModel)]="governmentCustomerwebsite" placeholder="Website" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomerwebsite.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomerwebsite.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomerwebsite.errors.required">Website is required</div>
                                              </div>
                                        </div>
                                </div>
                              
                                <div class="form-group">
                                    <div class="col-xs-6">

                                        <input type="text" class="form-control" name="GovernmentofficialEmail"
                                            formControlName="GovernmentofficialEmail"
                                            [(ngModel)]="GovernmentofficialEmail" placeholder="Official Email" [ngClass]="{ 'is-invalid': submitted && g.GovernmentofficialEmail.errors }"/>
                                            <div *ngIf="submitted && g.GovernmentofficialEmail.errors" class="invalid-feedback">
                                                <div *ngIf="g.GovernmentofficialEmail.errors.required">Official Email is required</div>
                                                <div *ngIf="g.GovernmentofficialEmail.errors.email">Enter valid Email</div>

                                              </div>
                                    </div> <br/>
                                    <div class="col-xs-6">

                                        <input type="number" class="form-control" name="GovernmentofficialContactNumber"
                                            formControlName="GovernmentofficialContactNumber"
                                            [(ngModel)]="GovernmentofficialContactNumber"
                                            placeholder="Official Contact Number" [ngClass]="{ 'is-invalid': submitted && g.GovernmentofficialContactNumber.errors }"/>
                                            <div *ngIf="submitted && g.GovernmentofficialContactNumber.errors" class="invalid-feedback">
                                                <div *ngIf="g.GovernmentofficialContactNumber.errors.required">Official Contact Number is required</div>
                                                <div *ngIf="g.GovernmentofficialContactNumber.errors.pattern">Enter valid Contact Number</div>

                                            </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-xs-6" *ngIf="SelectedSubcategoryId=='2'">

                                        <input type="text" class="form-control" name="governmentCustomercourtname"
                                            formControlName="governmentCustomercourtname"
                                            [(ngModel)]="governmentCustomercourtname" placeholder="Court Name" />
                                         
                                        </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6" *ngIf="SelectedSubcategoryId!='2'">

                                        <input type="text" class="form-control" name="governmentCustomeraddress"
                                            formControlName="governmentCustomeraddress"
                                            [(ngModel)]="governmentCustomeraddress" placeholder="Address" />
                                          
                                    </div> <br/>

                                    <div class="col-xs-6" *ngIf="SelectedSubcategoryId=='2'">
                                        <input type="text" class="form-control" name="address"
                                            formControlName="governmentCustomeraddress"
                                            [(ngModel)]="governmentCustomeraddress" placeholder="Court Address" />
                                          
                                        </div><br/>

                                    <div class="col-xs-6" *ngIf="SelectedSubcategoryId=='3'">

                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomerbranch"
                                            [(ngModel)]="governmentCustomerbranch" placeholder="Name Of the Branch" />
                                           
                                        </div> <br/>
                                    <div class="col-xs-6" *ngIf=!displaybranch >

                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomerbranch"
                                            [(ngModel)]="governmentCustomerbranch"
                                            placeholder="Name Of the Police Station" />
                                        
                                        </div> <br/>

                                    <div class="col-xs-6" *ngIf="SelectedSubcategoryId=='2'">

                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomerparties"
                                            [(ngModel)]="governmentCustomerparties" placeholder="Parties Name" />
                                        
                                        </div>
                                </div>
                                <div class="form-group">

                                    <div class="col-xs-6">

                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomeremployeeStrength"
                                            [(ngModel)]="governmentCustomeremployeeStrength"
                                            placeholder="Employee strength" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomeremployeeStrength.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomeremployeeStrength.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomeremployeeStrength.errors.required">Employee strength is required</div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">

                                        <input type="text" class="form-control" name="GovernmentcontactPersonName"
                                            formControlName="GovernmentcontactPersonName"
                                            [(ngModel)]="GovernmentcontactPersonName"
                                            placeholder="Authorised Officer’s Name" [ngClass]="{ 'is-invalid': submitted && g.GovernmentcontactPersonName.errors }"/>
                                            <div *ngIf="submitted && g.GovernmentcontactPersonName.errors" class="invalid-feedback">
                                                <div *ngIf="g.GovernmentcontactPersonName.errors.required">Authorised Officer’s Name is required</div>
                                              </div>
                                    </div>
                                </div>
                                <div class="form-group">


                                    <div class="col-xs-6">

                                        <input type="number" name="contactPersonMobileNumber" class="form-control"
                                            formControlName="GovernmentcontactPersonMobileNumber"
                                            [(ngModel)]="GovernmentcontactPersonMobileNumber"
                                            placeholder="Authorised Officer’s Contact Number" [ngClass]="{ 'is-invalid': submitted && g.GovernmentcontactPersonMobileNumber.errors }"/>
                                            <div *ngIf="submitted && g.GovernmentcontactPersonMobileNumber.errors" class="invalid-feedback">
                                                <div *ngIf="g.GovernmentcontactPersonMobileNumber.errors.required">Authorised Officer’s Contact Number is required</div>
                                                <div *ngIf="g.GovernmentcontactPersonMobileNumber.errors.pattern">Enter Valid Authorised Contact Number </div>
  
                                            </div>
                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="contactPersonEmail"
                                            formControlName="governmentCustomercontactPersonEmail"
                                            [(ngModel)]="governmentCustomercontactPersonEmail"
                                            placeholder="Authorised Officer’s E-mail" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomercontactPersonEmail.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomercontactPersonEmail.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomercontactPersonEmail.errors.required">Authorised Officer’s E-mail is required</div>
                                                <div *ngIf="g.governmentCustomercontactPersonEmail.errors.email">Enter valid E-mail </div>

                                              </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <!--<select ng-model="governmentCustomer.contactPersonDesignation" ng-options="i.id  as i.occupationName for i in Occupation" class="form-control" required>
                                            <option selected="selected" value="">Select Contact Person's Occupation</option>
                                        </select>-->
                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomercontactPersonDesignation"
                                            [(ngModel)]="governmentCustomercontactPersonDesignation"
                                            placeholder="Authorised Officer’s Designation" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomercontactPersonDesignation.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomercontactPersonDesignation.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomercontactPersonDesignation.errors.required">Authorised Officer’s Designation is required</div>
 
                                            </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomerEmpoweredName"
                                            [(ngModel)]="governmentCustomerEmpoweredName"
                                            placeholder="Empowered Authority Name" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomerEmpoweredName.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomerEmpoweredName.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomerEmpoweredName.errors.required">Empowered Authority Name is required</div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomerEmpoweredDesignation"
                                            [(ngModel)]="governmentCustomerEmpoweredDesignation"
                                            placeholder="Empowered Authority Designation" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomerEmpoweredDesignation.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomerEmpoweredDesignation.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomerEmpoweredDesignation.errors.required">Empowered Authority Designation is required</div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control"
                                            formControlName="governmentCustomersource"
                                            [(ngModel)]="governmentCustomersource"
                                            placeholder="How did you hear about us?" [ngClass]="{ 'is-invalid': submitted && g.governmentCustomersource.errors }"/>
                                            <div *ngIf="submitted && g.governmentCustomersource.errors" class="invalid-feedback">
                                                <div *ngIf="g.governmentCustomersource.errors.required">Please let us know How did you hear about us? </div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="col-xs-4 "></div>
                                        <div class="col-xs-4 ">
                                            <input type="submit" (click)="PostCustomerGovernmentDetails()"
                                                class="form-control btn-info" value="Submit" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-tab>
                    <mat-tab label="International Client">
                        <div>&nbsp;</div>
                        <div class="form-horizontal">
                            <!--<div class="form-group">
                                <div class="col-xs-6">&nbsp;</div>
                                <div class="col-xs-6">
                                    <select ng-model="type" class="form-control" required ng-change="showType(type)">
                                        <option selected="selected" value="">Select Type</option>
                                        <option selected="selected" value="1">Corporate Customer</option>
                                        <option selected="selected" value="2">Government Customer</option>
                                    </select>
                                </div>
                            </div>-->
                            <form [formGroup]="International">
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="firstName"
                                            formControlName="InternationalfirstName"
                                            [(ngModel)]="InternationalfirstName" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.InternationalfirstName.errors }"/>
                                            <div *ngIf="submitted && f.InternationalfirstName.errors" class="invalid-feedback">
                                                <div *ngIf="f.InternationalfirstName.errors.required">First Name is required </div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" formControlName="InternationallastName"
                                            [(ngModel)]="InternationallastName" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.InternationallastName.errors }"/>
                                            <div *ngIf="submitted && f.InternationallastName.errors" class="invalid-feedback">
                                                <div *ngIf="f.InternationallastName.errors.required">Last Name is required </div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <select (change)="OnselectGender($event.target.value)" class="form-control dropdownsize">
                                            <option value="">Select Gender</option>
                                            <option *ngFor="let do of Genders" value={{do.id}}>
                                                {{do.gender}}
                                            </option>
                                        </select>
                                    </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="address"
                                            formControlName="Internationaladdress" [(ngModel)]="Internationaladdress"
                                            placeholder="Address" [ngClass]="{ 'is-invalid': submitted && f.Internationaladdress.errors }"/>
                                            <div *ngIf="submitted && f.Internationaladdress.errors" class="invalid-feedback">
                                                <div *ngIf="f.Internationaladdress.errors.required">Address is required </div>
                                              </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="pincode"
                                            formControlName="Internationalpincode" [(ngModel)]="Internationalpincode"
                                            placeholder="Pin code" [ngClass]="{ 'is-invalid': submitted && f.Internationalpincode.errors }"/>
                                            <div *ngIf="submitted && f.Internationalpincode.errors" class="invalid-feedback">
                                                <div *ngIf="f.Internationalpincode.errors.required">Pin code is required </div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="email" placeholder="Email"
                                            formControlName="Internationalemail" [(ngModel)]="Internationalemail" [ngClass]="{ 'is-invalid': submitted && f.Internationalemail.errors }"/>
                                            <div *ngIf="submitted && f.Internationalemail.errors" class="invalid-feedback">
                                                <div *ngIf="f.Internationalemail.errors.required">Email is required </div>
                                                <div *ngIf="f.Internationalemail.errors.email">Enter valid Email </div>

                                            </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="mobile"
                                            formControlName="Internationalmobile" [(ngModel)]="Internationalmobile"
                                            placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && f.Internationalmobile.errors }"/>
                                            <div *ngIf="submitted && f.Internationalmobile.errors" class="invalid-feedback">
                                                <div *ngIf="f.Internationalmobile.errors.required">mobile number is required </div>
                                                <div *ngIf="f.Internationalmobile.errors.pattern">Enter valid mobile number </div>
 
                                            </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <select (change)="OnSelectCountry($event.target.value)"
                                            class="form-control dropdownsize">
                                            <option value="">Select Country</option>
                                            <option *ngFor="let do of CountryList" value={{do.countryId}} >
                                              {{do.countryName}}
                                            </option>                                       
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="mobile"
                                            formControlName="internationalcustomeroccupation"
                                            [(ngModel)]="internationalcustomeroccupation" placeholder="Occupation" [ngClass]="{ 'is-invalid': submitted && f.internationalcustomeroccupation.errors }"/>
                                            <div *ngIf="submitted && f.internationalcustomeroccupation.errors" class="invalid-feedback">
                                                <div *ngIf="f.internationalcustomeroccupation.errors.required">Occupation is required </div>
                                              </div>
                                        </div> <br/>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="mobile"
                                            formControlName="internationalcustomerqualification"
                                            [(ngModel)]="internationalcustomerqualification"
                                            placeholder="Qualification" [ngClass]="{ 'is-invalid': submitted && f.internationalcustomerqualification.errors }"/>
                                            <div *ngIf="submitted && f.internationalcustomerqualification.errors" class="invalid-feedback">
                                                <div *ngIf="f.internationalcustomerqualification.errors.required">Qualification is required </div>
                                              </div>
                                        </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" name="internationalcustomersource"
                                            formControlName="internationalcustomersource"
                                            [(ngModel)]="internationalcustomersource"
                                            placeholder="How did you hear about us?" [ngClass]="{ 'is-invalid': submitted && f.internationalcustomersource.errors }"/>
                                            <div *ngIf="submitted && f.internationalcustomersource.errors" class="invalid-feedback">
                                                <div *ngIf="f.internationalcustomersource.errors.required">Please let us know How did you hear about us? </div>
                                              </div>
                                        </div> <br/>
                                    <!--<div class="col-xs-6">
                                        <input type="text" class="form-control" maxlength="12" ng-model="customer.aadhaarNumber" placeholder="Aadhaar Number" />
                                    </div>-->
                                </div>
                                <!--<div class="form-group">
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" maxlength="10" ng-model="customer.voterId" placeholder="Voter Id" />
                                    </div>
                                    <div class="col-xs-6">
                                        <input type="text" class="form-control" maxlength="10" ng-model="customer.panNumber" placeholder="Pan Number" />
                                    </div>
                                </div>-->
                                <div class="form-group">
                                    <!-- <div class="col-xs-6">
                                        <label>Photo</label>
                                        <input type="file" />
                                    </div> -->
                                    <div class="col-xs-6">
                                        <label>-</label>
                                        <input type="submit" (click)="PostInternationalCustomer()"
                                            class="form-control btn-info" value="Submit" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </mat-tab>
                    </mat-tab-group>

            </div>
        </div>
        <div class="col-md-1">&nbsp;</div>
    </div>
</div>



<!-- notification alert -->

<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
        z-index: 1
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close"
                (click)="closealert()">
                <i class="material-icons" style="color: white;">close</i>
            </button>
            <span style="color: white; margin-top: 6px;">
                <b style="text-transform: capitalize; font-size: 14px;">
                    {{ notify }}</b></span>
        </div>
    </div>
</div>