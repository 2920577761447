<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div class="container register">
  <div>
    <div class="row col-sm-12">
      <div class="col-sm-4">&nbsp;</div>
      <div class="col-sm-4">
        <h2 style="text-align: center; color: rgb(0, 0, 0)">Sent Items</h2>
      </div>
      <br />
      <br />
      <br />
    </div>
    <br />
    <div class="row col-sm-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th>From</th>
              <th>TO</th>
              <th>Subject</th>
              <th>Date</th>
            </tr>
          </thead>
          <tr
            *ngFor="
              let sendItem of SentItems
                | paginate: { itemsPerPage: 5, currentPage: p };
              let i = index
            "
            style="color: rgb(0, 0, 0)"
          >
            <td>
              <i class="fas fa-user"></i>
            </td>
            <td>{{ sendItem.from }}</td>
            <td>{{ sendItem.to }}</td>
            <td>{{ sendItem.subject }}</td>
            <td>{{ sendItem.date }}</td>
          </tr>
        </table>
        <br />
        <pagination-controls
          (pageChange)="p = $event"
          style="float: right"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>
<br /><br />
