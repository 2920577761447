import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';

@Component({
  selector: 'app-inward-preview',
  templateUrl: './inward-preview.component.html',
  styleUrls: ['./inward-preview.component.css']
})
export class InwardPreviewComponent implements OnInit {
  InwardPreviewData: any;
  Id: any;
  File: any;
  Folderid: any;
  ReceivedFrom: any;
  Subject: any;
  InwardId: any;
  Sensitivity: any;
  Pages: any;
  CirculationGroup: any;
  DocumentId: any;
  Note: any;
  Workflowstage: any;
  FromUser: any;
  InwardDate: any;
  InwardNumber: any;
  InwardFolderId: any;
  InwardFileId: any;
  FilePlanList: any;
  DocumentTypeList: any;
  InwardTypeList: any;
  WorkflowStageList: any;
  sensitivitylist: any;
  InwardType: any;
  printdoc: any;
  gotfile: any;

  constructor(private spinner:NgxSpinnerService,private inwardservice:InwardServiceService,private router:Router) {
    if(localStorage.getItem("InwardPreviewData")!=null || localStorage.getItem("InwardPreviewData")!=undefined){
      var data = JSON.parse(localStorage.getItem("InwardPreviewData"))
      this.InwardPreviewData = data
      this.AssignData();

    }
 
  }

  ngOnInit(): void {
    this.Priority()
    this.DocumentTypes();
    this.FilePlan();
    this.InwardTypes();
    this.WorkflowStage();
  }
  AssignData() {

    this.Id = this.InwardPreviewData.Id,
      this.File = this.InwardPreviewData.File,
      this.Folderid = this.InwardPreviewData.Folderid,
      this.ReceivedFrom = this.InwardPreviewData.ReceivedFrom,
      this.Subject = this.InwardPreviewData.Subject,
      this.InwardId = this.InwardPreviewData.InwardId,
      this.Sensitivity = this.InwardPreviewData.Sensitivity,
      this.Pages = this.InwardPreviewData.Pages,
      this.InwardType = this.InwardPreviewData.InwardType,
      this.CirculationGroup = this.InwardPreviewData.CirculationGroup,
      this.DocumentId = this.InwardPreviewData.DocumentId,
      this.Note = this.InwardPreviewData.Note,
      this.Workflowstage = this.InwardPreviewData.Workflowstage,
      this.FromUser = this.InwardPreviewData.FromUser,
      this.InwardDate = this.InwardPreviewData.InwardDate,
      this.InwardNumber = this.InwardPreviewData.InwardNumber,
      this.InwardFolderId = this.InwardPreviewData.InwardFolderId,
      this.InwardFileId = +this.InwardPreviewData.InwardFileId
  }
  DownloadForm() {
   
    var data = document.getElementById('print-section');
    this.spinner.show();
    html2canvas(data).then(canvas => {
      var imgWidth = 100;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpg')
      var data45 = {
        htmlString: contentDataURL,
        FormName:"Inward"

      }
      this.inwardservice.DownloadInwardForm(data45).subscribe((data1: any) => {
        var aa = data1
        this.printdoc=data1
     
        window.open(data1);

     
        this.spinner.hide();

      })
    });
  }
  FilePlan() {
    this.spinner.show();
    this.inwardservice.GetFilePlanList().subscribe((Data: any) => {
      this.FilePlanList = Data;
      this.spinner.hide();

    })
  }
  DocumentTypes() {
    this.spinner.show();
    this.inwardservice.GetDocumentTypeList().subscribe((Data: any) => {
      this.DocumentTypeList = Data;
      this.spinner.hide();

    })
  }
  InwardTypes() {
    this.spinner.show();
    this.inwardservice.GetInwardTypeList().subscribe((Data: any) => {
      this.InwardTypeList = Data;
      this.spinner.hide();

    })
  }
  WorkflowStage() {
    this.spinner.show();
    this.inwardservice.GetWorkflowStage("IN").subscribe((Data: any) => {
      this.WorkflowStageList = Data;
      this.spinner.hide();

    })
  }

  Priority() {
    this.spinner.show();
    this.inwardservice.GetPriority().subscribe((Data: any) => {
      this.sensitivitylist = Data;
      this.spinner.hide();

    })
  }
  GetPriorityName(id){
    for (let index = 0; index < this.sensitivitylist.length; index++) {
      if(id==this.sensitivitylist[index].id){
        return this.sensitivitylist[index].sensitivityType
      }
    }
  }
  GetWorkflowStageName(id){
    for (let index = 0; index < this.WorkflowStageList.length; index++) {
      if(id==this.WorkflowStageList[index].id){
        return this.WorkflowStageList[index].workflowStage
      }
    }
  }
  GetInwardTypeName(id){
    for (let index = 0; index < this.InwardTypeList.length; index++) {
      if(id==this.InwardTypeList[index].id){
        return this.InwardTypeList[index].inwardType1
      }
    }
  }
  GetFilePlan(id){
    for (let index = 0; index < this.FilePlanList.length; index++) {
      if(id==this.FilePlanList[index].fileUniqueId){
        return this.FilePlanList[index].filename
      }
    }
  }
  GetDocumentTypeName(id){
    for (let index = 0; index < this.DocumentTypeList.length; index++) {
      if(id==this.DocumentTypeList[index].documentTypeID){
        return this.DocumentTypeList[index].documentTypeName
      }
    }
  }
  sndpdftoprint(){
    var data = document.getElementById('print-section');
    this.spinner.show();
    html2canvas(data).then(canvas => {
      var imgWidth = 100;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpg')
      var data45 = {
        htmlString: contentDataURL,
        FormName:"Inward"

      }
      this.inwardservice.DownloadInwardForm(data45).subscribe((data1: any) => {
        var aa = data1
        this.printdoc=data1
        var data45 = {
          Pdffile:  this.printdoc,
          File: this.printdoc,
        }
        this.spinner.show();
    
        this.inwardservice.PrintInwardForm(data45).subscribe((data1: any) => {
          var aa = data1
          this.gotfile=data1
          this.spinner.hide();
    
          var bs64data = this.gotfile.split(',')[1];
          var blnk = this.base64ToBlob(bs64data);
          const blobUrl = URL.createObjectURL(blnk);
      
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
    
        })
         
        this.spinner.hide();

      })
    });

    
 }
 base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};
goBack(){
  this.router.navigate(['inward/']);
}
}
