import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newfaq',
  templateUrl: './newfaq.component.html',
  styleUrls: ['./newfaq.component.scss']
})
export class NewfaqComponent implements OnInit {
  oneclick:any;
  twoclick:any;
  threeclick:any;
  fourclick:any;
  questionlist:boolean=true;
 
  constructor() { 
    window.scrollTo(0,0);

  }

  ngOnInit() {
  
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                content.style.maxHeight = content.scrollHeight + "px";
            }
        });
    }
    if(window.innerWidth>=500)
    {
      var div = document.getElementById("div");
      if(div){
        div.style.width = "50%";
        div.style.marginLeft="25%";
      }
    }
  }
  one(){
    this.oneclick=true;
    this.twoclick=false;
    this.threeclick=false;
    this.questionlist=false;
  }
  two(){
    this.oneclick=false;
    this.twoclick=true;
    this.threeclick=false;
    this.questionlist=false;
  }
  three(){
    this.oneclick=false;
    this.twoclick=false;
    this.threeclick=true;
    this.questionlist=false;
  }
  four(){
    this.oneclick=false;
    this.twoclick=false;
    this.threeclick=false;
    this.questionlist=true;
  }
}