<app-nav-bar></app-nav-bar>
<div class="wrapper">
    <div class="page-header" style="background-color:#f1f1f1;">
        <div class="filter"></div>
        <div class="container">

            <!-- <div *ngIf="loading">
                  <img src="assets/img/loading.jpg" style="width:500px;height:500px"  />
              </div> -->
            <div class="row">
                <div class="row col-md-12">
                    <div class="col-md-6">
                        <div class="col-lg-12 col-sm-12 mr-auto ml-auto">
                            <div class="card card-register" style="background-color:#153157;min-height: 700px;margin: 70px 0 70px;">
                                <h3 class="title" style="color:white;text-align: center;">Support</h3>

                                <form class="support-form" #supportform="ngForm" class="col s6 white">
                                    <label>Issue</label>
                                    <!-- <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus===true}">
                                        <select (change)="onSelectType($event.target.value)" class="form-control">
                                <option value="">Select Type</option>
                                <option value="0">Issue</option>
                                <option value="1">Help</option>
                            </select>
                                    </div> -->

                                    <label>Name</label>
                                    <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">

                                        <input class="form-control" type="text" #firstname ngModel name="firstname" placeholder="Name" required (focus)="focus1=true" (blur)="focus1=false">
                                    </div>
                                    <label>Email</label>
                                    <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">

                                        <input class="form-control" type="text" #email ngModel name="email" placeholder="Email" required (focus)="focus1=true" (blur)="focus1=false">
                                    </div>

                                    <label>Issue / Help</label>
                                    <div class="input-group form-group-no-border" [ngClass]="{'input-group-focus':focus1===true}">

                                        <textarea class="form-control" rows="4" type="text" #msg ngModel name="msg" placeholder="Type here..." required (focus)="focus1=true" (blur)="focus1=false">
                            </textarea>
                                    </div>
                                    <button class="btn btn-danger btn-block btn-round" (click)="SaveSupportQuery(supportform)">Submit</button>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <br /><br /><br /><br />
                        <div class="card card-lift--hover shadow border-0" style="background-color: #f1f1f1;">
                            <div class="card-body py-5">
                                <div class="icon icon-shape icon-shape-success rounded-circle mb-4">
                                    <i class="ni ni-check-bold"></i>
                                </div>
                                <h3 class="sc-center"><i class="fa fa-envelope"></i></h3>
                                <h3 class="text-uppercase">Email</h3>
                                <a href="mailto:support@blocdrive.com">
                                    <p class="sc-opacity sc-center" style="color: black;"><b>support@blocdrive.com</b></p>
                                </a><br />
                            </div>
                        </div>

                        <div class="card card-lift--hover shadow border-0" style="background-color: #f1f1f1;">
                            <div class="card-body py-5">
                                <div class="icon icon-shape icon-shape-success rounded-circle mb-4">
                                    <i class="ni ni-check-bold"></i>
                                </div>
                                <h3 class="sc-center"><i class="fa fa-phone"></i></h3>
                                <h3 class="text-uppercase">Phone</h3>
                                <p class="sc-opacity sc-center" style="color: black;"> <b>+91 80 2685 2070</b></p><br />
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>





<!-- <div class="col-lg-12 row">
  <div class="col-lg-12 row">
    <div class="col-lg-3"></div>
    <div class="col-lg-8">
      <h2 style="color:black;font-family: Montserrat ">Support</h2>
    </div>
  </div>
  <div class="col-lg-12 row">&nbsp;</div>
  <div class="col-lg-12 row">&nbsp;</div>
  
  <div class="col-lg-12 row">
      <form #SupportForm="ngForm" (ngSubmit)="onSubmit(SupportForm)">
    <div class="col-lg-12 row">&nbsp;</div>

    <div class="col-lg-2"></div>
    <div class="col-lg-8" style="border:solid 3px;color: burlywood;">
      <br />
      <div class="col-lg-12 row">
          <div class="col-lg-3">
            <label style="font-size:20px;color:black;font-family: Montserrat">Issue:</label>
          </div>
          <div class="col-lg-9">
              <div class="form-group">
                  <select (change)="onSelectType($event.target.value)" class="form-control">
                      <option value="">Select Type</option>
                      <option value="0">Issue</option>
                      <option value="1">Help</option>
                  </select>
              </div>
          </div>
        </div>
        <div class="col-lg-12 row">&nbsp;</div>
      <div class="col-lg-12 row">
        <div class="col-lg-3">
          <label style="font-size:20px;color:black;font-family: Montserrat">Name:</label>
        </div>
        <div class="col-lg-9">
            <input type="text" class="form-control" #name ngModel name="name"
            placeholder="Name" (focus)="focus1=true" (blur)="focus1=false">
        </div>
      </div>
      <div class="col-lg-12 row">&nbsp;</div>
      <div class="col-lg-12 row">
          <div class="col-lg-3">
            <label style="font-size:20px;color:black;font-family: Montserrat">Email:</label>
          </div>
          <div class="col-lg-9">
              <input type="text" class="form-control" #email ngModel name="email"
              placeholder="Email" (focus)="focus1=true" (blur)="focus1=false">
          </div>
        </div>
        <div class="col-lg-12 row">&nbsp;</div>
        
      <div class="col-lg-12 row">
          <div class="col-lg-3">
            <label style="font-size:20px;color:black;font-family: Montserrat">Issues / Help:</label>
          </div>
          <div class="col-lg-9">
            <textarea class="form-control" rows="4" name="msg" #msg  placeholder="Type here....."></textarea>
          </div>
        </div>
     
      <div class="col-lg-12 row">&nbsp;</div>
      
      <div class="col-lg-12 row">&nbsp;</div>
      <div class="col-lg-12 row">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-4" style="text-align: center;">
          <button type="button" class="btn btn-success" >Success</button>
        </div>
        <div class="col-lg-4">
        </div>
      </div>

      <br /><br />
    </div>
    <div class="col-lg-2"></div>
  </form>
  </div>
 
</div> -->
<app-support-chat></app-support-chat>