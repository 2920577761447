import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Client } from 'src/app/ViewModels/Client';
import { ClientService } from 'src/app/Shared/Client/client.service';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  closeResult: any;
  ClientName: any;
  clientname: any;
  Address: any;
  address: any;
  ContactPersonName: any;
  contactpersonname: any;
  ContactNumber: any;
  contactnumber: any;
  OfficeNumber: any;
  officenumber: any;
  Email: any;
  email: any;
  public dismissalert = false;
  notify: string;
  ClientList: any;
  p: number = 1;
  editclientName: any;
  editid: any;
  uclientname: any;
  uclientdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  editaddress: any;
  editcontactnumber: any;
  editcontactpersonname: any;
  editofficenumber: any;
  editemail: any;
  uid: string;
  roleid: string;
  mobileview: boolean;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  phonev = new RegExp(/^[6-9]\d{9}$/);
  //telephoneval= new RegExp(/^[0-9]\d{2,4}-\d{6,8}$/
  telephoneval= new RegExp(/^[6-9]\d{9}$/);
  
  checkphone: boolean;
  checkemail: boolean;
  checktelephone: boolean;
  GUID: string;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService, private spinner: NgxSpinnerService, private clientService: ClientService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };

  ngOnInit(): void {
    this.Clients();
    this.ClientName = '';
    this.Address = '';
  }
  Clients() {
    this.spinner.show();
    this.clientService.getAllClients(this.GUID).subscribe((Data: any) => {
      this.ClientList = Data;
      this.spinner.hide();
    });
  }
  addClient(addclient) {
    this.modalService.open(addclient).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditClient(updatedata, updateclient) {
    this.editclientName = updatedata.clientName;
    this.editaddress = updatedata.address;
    this.editcontactpersonname = updatedata.contactPersonName;
    this.editcontactnumber = updatedata.contactNumber;
    this.editofficenumber = updatedata.officeNumber;
    this.editemail = updatedata.email;
    this.editid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.modalService.open(updateclient).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteClient(deletedata, addclient) {
    this.DeleteName = deletedata.clientName;
    this.deleteid = deletedata.id;
    this.modalService.open(addclient).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  SaveClient() {
    this.checkphone = this.phonev.test(this.contactnumber);
    this.checkemail = this.regexp.test(this.email);
    this.checktelephone=this.telephoneval.test(this.officenumber)
    if(!this.checkphone){
      alert("Please enter valid contact number")
    }
   else if(!this.checkemail){
      alert("Please enter valid Email")
    }
    // else if(!this.checktelephone){
    //   alert("Please Enter Valid Office Number")

    // }
    else{

 
    const inputRequest: Client = {
      ID: 0,
      ClientName: this.clientname,
      Address: this.address,
      ContactPersonName: this.contactpersonname,
      ContactNumber: this.contactnumber,
      Email: this.email,
      OfficeNumber: this.officenumber,
      IsEnabled: 0,
      UserId: this.uid,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      GUID:this.GUID 
    };
    this.spinner.show();
    this.clientService.saveClient(inputRequest).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Client added Successfully';
        const audit = {
          TransactionName:"Project Management",
          TransactionType:"Create",
          TransactionDesc:"Client",
         
          }
        this.auditortransactionservice.AuditorDetailsProject(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Clients();
      } else {
        this.notify = 'Something went wrong!!!Please Try Again.!!';
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Clients();
      }
    });
  }
  }

  UpdateClient() {
    var checkphone = this.phonev.test(this.editcontactnumber);
    var checkemail = this.regexp.test(this.editemail);
    var checktelephone=this.telephoneval.test(this.editofficenumber)
    if(!checkphone){
      alert("Please enter valid Contact Number")
    }
   else if(!checkemail){
      alert("Please enter valid Email")
    }
    else if(!checktelephone){
      alert("Please enter valid Office Number")

    }
    else{

 
    const inputRequest1: Client = {
      ID: this.editid,
      ClientName: this.editclientName,
      Address: this.editaddress,
      ContactNumber: this.editcontactnumber,
      ContactPersonName: this.editcontactpersonname,
      OfficeNumber: this.editofficenumber,
      Email: this.editemail,
      IsEnabled: 1,
      UserId: this.uid,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      GUID:this.GUID 

    };
    this.spinner.show();
    this.clientService.UpdateClient(inputRequest1).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Client Updated Successfully';
        const audit = {
          TransactionName:"Project Management",
          TransactionType:"Update",
          TransactionDesc:"Client",
         
          }
        this.auditortransactionservice.AuditorDetailsProject(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Clients();
        this.spinner.hide();
      } else {
        this.notify = 'Something went wrong!!!Please Try Again...';
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Clients();
        this.spinner.hide();
      }
    });
  }
  }
  DeleteClientRow() {
    this.spinner.show();
    this.clientService.deleteClient(this.deleteid).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Folder deleted successfully';
        const audit = {
          TransactionName:"Project Management",
          TransactionType:"Delete",
          TransactionDesc:"Client",
         
          }
        this.auditortransactionservice.AuditorDetailsProject(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Clients();
        this.spinner.hide();
      } else {
        this.notify = 'Something went wrong.Please Try Again.!!';
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Clients();
        this.spinner.hide();
      }
    });
  }
  closealert() {
    this.dismissalert = false
  }
}
