import { Component, OnInit } from '@angular/core';
import { OutwardService } from 'src/app/Shared/Outward/outward.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-work-flow-tracking',
  templateUrl: './work-flow-tracking.component.html',
  styleUrls: ['./work-flow-tracking.component.css']
})
export class WorkFlowTrackingComponent implements OnInit {
  trackid: string;
  Trackdetails: any;
  uid: string;
  roleid: string;
  mobileview: boolean;

  constructor(private outeardsevice: OutwardService, private spinner: NgxSpinnerService, private router: Router) {
    this.trackid = localStorage.getItem("TrackId")
  
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
    }
    this.isMobileMenu()
  }

  ngOnInit(): void {
    this.GetTrackingDetails();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };


  GetTrackingDetails() {
    var uidtrackid = this.trackid + "," + this.uid
    this.spinner.show();
    this.outeardsevice.GetTrackingDetails(uidtrackid).subscribe((data: any) => {
      this.Trackdetails = data;
      this.spinner.hide();
      if (this.Trackdetails.count == 0 && this.Trackdetails.isOutwarded == false && this.Trackdetails.isInwarded == false) {
        document.getElementById('step1').className = 'step completed'
        document.getElementById('step2').className = 'step'
        document.getElementById('step3').className = 'step'
        document.getElementById('step4').className = 'step'
        document.getElementById('step5').className = 'step'
      }
      else if (this.Trackdetails.isInwarded == true && this.Trackdetails.count == 0 && this.Trackdetails.isOutwarded == false) {
        document.getElementById('step1').className = 'step completed'
        document.getElementById('step2').className = 'step completed'
        document.getElementById('step3').className = 'step '
        document.getElementById('step4').className = 'step '
        document.getElementById('step5').className = 'step '
      }
      else if (this.Trackdetails.isInwarded == true && this.Trackdetails.count <= 3 && this.Trackdetails.count >= 1 && this.Trackdetails.isOutwarded == false) {
        document.getElementById('step1').className = 'step completed'
        document.getElementById('step2').className = 'step completed'
        document.getElementById('step3').className = 'step completed'
        document.getElementById('step4').className = 'step '
        document.getElementById('step5').className = 'step '
      }
      else if (this.Trackdetails.isInwarded == true && this.Trackdetails.count <= 5 && this.Trackdetails.count > 3 && this.Trackdetails.isOutwarded == false) {
        document.getElementById('step1').className = 'step completed'
        document.getElementById('step2').className = 'step completed'
        document.getElementById('step3').className = 'step completed'
        document.getElementById('step4').className = 'step completed'
        document.getElementById('step5').className = 'step '
      }
      else if (this.Trackdetails.isOutwarded == true) {
        document.getElementById('step1').className = 'step completed'
        document.getElementById('step2').className = 'step completed'
        document.getElementById('step3').className = 'step completed'
        document.getElementById('step4').className = 'step completed'
        document.getElementById('step5').className = 'step completed'
      }
       
    })
  }
  backtoprojects() {
    this.router.navigate(['projectregistration/']);
  }
}
