<app-nav-bar></app-nav-bar>
<div style="background-color: #dedede;">&nbsp;</div>


<div style="background-color: #dedede;">&nbsp;</div>
<div class="col-md-12 row" style="background-color: #dedede;">
    <div class="column" *ngFor="let item of SetupList">
        <div class="card" style="border-radius: 20px;background-color: aliceblue;">
            <br />
            <div class="col-md-12 row">
                <p class="complaintext">{{item.menuName}}</p>
            </div>
            <P style="padding-left: 10px">{{item.menuFileName}}</P>
            <br />
            <div class="col-md-12 row" style="border-top: solid #dfdfdf 1px;margin-left: 1px;">
                <div class="col-md-1"></div>
                <div class="col-md-8" style="padding-bottom: 11px;">
                    <br />
                    <a *ngIf="item.menuID=='Setup'" style="color: #36a1ff;font-weight: 700;text-align: center;" href="#{{item.menuURL}}">Manage</a>
                    <a *ngIf="item.menuID=='Service'" style="color: #36a1ff;font-weight: 700;text-align: center;" href="#{{item.menuURL}}">Get Service</a>

                </div>
            </div>
        </div>
        <div>&nbsp;</div>

    </div>

</div>
<div style="background-color: #dedede;">&nbsp;</div>
<div style="background-color: #dedede;">&nbsp;</div>
<div style="background-color: #dedede;">&nbsp;</div>
<div style="background-color: #dedede;">&nbsp;</div>