<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->

<div style="background: linear-gradient(270deg, #7100aa, #000)">
  <div class="col-md-12 row" style="color: white">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div>&nbsp;</div>
      <div>&nbsp;</div>

      <h2 style="text-align: center">{{ formTitle }}</h2>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  </div>
  <div class="col-md-12 row" style="color: white">
    <div class="col-md-3"></div>
    <div class="col-md-3">
      <div class="hidden">{{ inwardMailId }}</div>
      <div class="hidden">{{ allotmentMailId }}</div>
    </div>
  </div>

  <div class="container" style="
      background: ghostwhite;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    ">
    <div class="col-md-12 row">
      <div class="col-md-1">&nbsp;</div>
      <div class="col-md-2">
        <div>&nbsp;</div>
        <button class="btn btn-primary" (click)="gotofile(filepopup)">
          View Object
        </button>
        <div>&nbsp;</div>
      </div>
    </div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4" *ngIf="!codestatus">
        <label for="section">Object Number:</label>
        <select (change)="OnselectFilename($event.target.value)" class="form-control dropdownsize">
          <option value="">Select ObjectName</option>
          <option *ngFor="let do of FilePlanList" value="{{ do.fileUniqueId }}"
            [selected]=" do.fileUniqueId==filenumber">
            {{ do.filename }}
          </option>
        </select>
      </div>
      <div class="col-md-4" *ngIf="codestatus">
        <label for="section">Object Number:</label>
        <input type="text" class="form-control" name="filenumber" [(ngModel)]="filenumber" readonly />
      </div>
      <div class="col-md-4">
        <label for="to">To (Within Department)</label>
        <tag-input id="TagInp" [ngModel]="userswithindept" name="test" [onlyFromAutocomplete]="false"
          (onRemove)="onItemRemovedWithihDept($event)" (onAdd)="onItemAddedWithinDept($event)" [addOnBlur]="true"
          [errorMessages]="errorMessages" [separatorKeyCodes]="[13, 188, 186, 32]">
          <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
            [focusFirstElement]="true" [displayBy]="'userName'" [identifyBy]="'userId'" [autocompleteItems]="UsersListWithinSameDepartment">
            <ng-template let-item="item" let-index="index">
              {{ item.userName }}
            </ng-template>
          </tag-input-dropdown>
        </tag-input>
      </div>
     
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>

      <div class="col-md-4">
        <label for="to">To (All Users)</label>
        <tag-input id="TagInp1" [ngModel]="users" name="test" [onlyFromAutocomplete]="false"
          (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true"
          [errorMessages]="errorMessages" [separatorKeyCodes]="[13, 188, 186, 32]">
          <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
            [focusFirstElement]="true" [displayBy]="'userName'" [identifyBy]="'userId'" [autocompleteItems]="UsersList">
            <ng-template let-item="item" let-index="index">
              {{ item.userName }}
            </ng-template>
          </tag-input-dropdown>
        </tag-input>
      </div>
    </div>

    <div>&nbsp;</div>

    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label for="section">Inwarded Document</label>
        <select (change)="GetInwardDataforAllotment($event.target.value, filenumber)" class="form-control dropdownsize">
          <option value="">Select Inwarded Document</option>
          <option *ngFor="let do of inwards" value="{{ do.id }}" [selected]="do.id==InwardedDocumentId">
            {{ do.inwardNumber }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="inward">Inward Type</label>
        <select (change)="OnselectInwardType($event.target.value)" class="form-control dropdownsize">
          <option value="">Select Inward Type</option>
          <option *ngFor="let do of InwardTypeList" value="{{ do.id }}" [selected]="do.id == wI_InwardTypeId">
            {{ do.inwardType1 }}
          </option>
        </select>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>

      <div class="col-md-4">
        <label for="subject">Exhibits</label>
        <input type="text" class="form-control" name="ExhibhitFileName" [(ngModel)]="ExhibhitFileName" autofocus />
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label for="recievedfrom">Received From</label>
        <input type="text" class="form-control" name="wI_InwardFrom" [(ngModel)]="wI_InwardFrom" autofocus />
      </div>
      <div class="col-md-4">
        <label for="inwarddate">Date(DD-MM-YYYY)</label>
        <input type="text" class="form-control" name="wI_Date" [(ngModel)]="wI_Date" autofocus />
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label for="inward">Inward Number</label>
        <input type="text" class="form-control" name="wI_InwardNo" [(ngModel)]="wI_InwardNo" autofocus readonly />
      </div>
      <div class="col-md-4">
        <label for="refno">Original Reference Number</label>
        <input type="text" class="form-control" name="wI_InwardNo" [(ngModel)]="wI_InwardNo" autofocus />
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label for="page">Pages</label>
        <input type="text" class="form-control" name="wI_Pages" [(ngModel)]="wI_Pages" autofocus />
      </div>
      <div class="col-md-4">
        <label for="sensitivity">Sensitivity</label>
        <select (change)="OnselectPriority($event.target.value)" class="form-control dropdownsize">
          <option value="">Select Sensitivity</option>
          <option *ngFor="let do of sensitivitylist" value="{{ do.id }}" [selected]="do.id == wI_Sensitivity">
            {{ do.sensitivityType }}
          </option>
        </select>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label for="doc">Document Type</label>
        <select (change)="OnselectDocumentType($event.target.value)" class="form-control dropdownsize">
          <option value="">Select Document Type</option>
          <option *ngFor="let do of DocumentTypeList" value="{{ do.documentTypeID }}"
            [selected]="do.documentTypeID == wI_DocID">
            {{ do.documentTypeName }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="page">Comment Box</label>
        <textarea type="text" class="form-control" name="attachmentdetails" [(ngModel)]="attachmentdetails"
          autofocus></textarea>
      </div>
    </div>
    <div>&nbsp;</div>

    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <label for="section">Workflow Stage</label>
        <select (change)="OnselectWorkflowStage($event.target.value)" class="form-control dropdownsize">
          <option value="">Select Workflow Stage</option>
          <option *ngFor="let do of WorkflowStageList" value="{{ do.id }}" [selected]="do.id==selectedworkflowstage">
            {{ do.workflowStage }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="sensitivity">Note(s)</label>
        <textarea type="text" class="form-control" name="remarks" [(ngModel)]="remarks" autofocus></textarea>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row" *ngIf="Role == '1' || Role == '7'">
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <label for="to">Exhibit's</label>
        <input type="text" class="form-control" name="ExhibhitFileName" [(ngModel)]="ExhibhitFileName" autofocus />
      </div>

      <div class="col-md-4" *ngIf="inwardData == '7'">
        <label for="section">Process Type</label>
        <select (change)="OnselectProcessType($event.target.value)" class="form-control dropdownsize">
          <option value="">Select Workflow Stage</option>
          <option *ngFor="let do of processtypelist" value="{{ do.id }}">
            {{ do.processType }}
          </option>
        </select>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-3">&nbsp;</div>
      <div class="col-md-4">
        <button type="submit" class="btn btn-primary form-control" style="
            
            color: white;
            padding: 9px 30px;
          " (click)="PreviewWorkAllotment()">
          PREVIEW & DOWNLOAD
        </button>
      </div>
      <div class="col-md-3">
        <button type="submit" class="btn btn-primary form-control" (click)="SendWork()" style="padding: 9px 30px">
          SEND
        </button>
      </div>
      <div class="col-md-4">&nbsp;</div>
    </div>
    <div>&nbsp;</div>
  </div>
  <div>&nbsp;</div>

  <div>&nbsp;</div>
  <div class="container" style="
      background: ghostwhite;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    " *ngIf="inwarsdoclist">
    <div class="col-md-12 row">
      <div>&nbsp;</div>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th>Document Name</th>
              <th>Document</th>
            </tr>
            <tr *ngFor="let doc of inwarsdoclist">
              <td>{{ doc.fileName }}</td>
              <td>
                <input type="submit" class="form-control btn btn-warning" (click)="gotopdfViewForAssignTask(doc)"
                  value="view" />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <div>&nbsp;</div>
  <div class="container" style="
      background: ghostwhite;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    ">
    <div>&nbsp;</div>

    <div class="col-md-12 row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <button type="submit" pageScroll href="#showaddexamination" class="fa fa-arrow-down form-control btn btn-info"
          (click)="AddExaminations(inwardData)">
          Examination
        </button>
      </div>
    </div>
    <div>&nbsp;</div>
    <div id="showaddexamination">
      <div class="col-md-12 row" *ngIf="ExaminationWindowStatus">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div>&nbsp;</div>
          <div>
            <h4 style="padding-left: 20%">EXAMINATIONS</h4>
            <br />
            <input type="submit" pageScroll href="#showalldetailsofexam" (click)="showExamination()"
              class="col-lg-12 btn btn-warning form-control" value="ADD EXAMINATION" />
            <div>&nbsp;</div>
          </div>
        </div>
      </div>

      <div class="col-md-12 row" *ngIf="ExaminationWindowStatus">
        <div class="col-md-2"></div>
        <div class="col-md-8 table-responsive">
          <table class="table table-bordered" *ngIf="exhibit">
            <thead class="thead-light">
              <tr>
                <th>SL No.</th>
                <th>Nature Name</th>
                <th>Examine By</th>
                <th>Observation</th>
                <th>Date Time</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="
                  let pd of exhibit.Exhibits.existingExam
                    | paginate: { itemsPerPage: 5, currentPage: p };
                  let i = index
                ">
                <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                <td>{{ pd.natureName }}</td>
                <td>{{ pd.userName }}</td>
                <td>{{ pd.observations }}</td>
                <td>{{ pd.dateTime | date: "short" }}</td>
                <td>
                  <button type="submit" pageScroll href="#showopiniondetails" (click)="showOpinion(pd)"
                    class="form-control">
                    OPINIONS
                  </button>
                  <br />
                  <input type="submit" id="file1" class="form-control" (click)="gotopdfViewForAssignTask(pd)"
                    value="VIEW DOCUMENTS" />
                </td>
              </tr>
              <tr>
                <!-- <button class="col-lg-12 btn btn-warning form-control" type="submit">
                            <a href="#/finalreport" target="_blank">Full Report</a>
                        </button> -->
              </tr>
            </thead>
          </table>
          <br />
          <pagination-controls *ngIf="exhibit" (pageChange)="p = $event" style="float: right">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
  <div>&nbsp;</div>
  <div class="container" style="
      background: ghostwhite;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    " *ngIf="showOpinionTable || MessageShow">
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div>
          <h5>
            Opinions for {{ ExaminationDetails.natureName }}, which is Examined
            by
            {{ ExaminationDetails.userName }}
          </h5>
          <h6 style="color: red" *ngIf="MessageShow">{{ Message }}</h6>
        </div>
        <div>
          <label>Add Opinion</label>
          <textarea placeholder="Please specify the Opinion here..." class="form-control"
            [(ngModel)]="opinion"></textarea>
        </div>
        <div>
          <label>-</label>
          <input type="submit" class="form-control" (click)="AddOpinion(ExaminationDetails)" value=" + Add Opinion" />
        </div>
        <br />
        <div id="showopiniondetails">
          <div style="overflow-x: visible; height: 300px">
            <table class="table table-striped" *ngIf="showOpinionTable">
              <thead class="thead-light">
                <tr>
                  <th>Opinion</th>
                  <th>By</th>
                  <th>DateTime</th>
                </tr>
                <tr *ngFor="let op of Opinions">
                  <td>{{ op.opinion }}</td>
                  <td>{{ op.givenByName }}</td>
                  <td>{{ op.dateTime | date: "short" }}</td>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>&nbsp;</div>
  <div id="showalldetailsofexam">
    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      " *ngIf="addExaminationStatus">
      <div>&nbsp;</div>
      <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-8">
          <h4>ADD EXAMINATION</h4>
          <!--/////////////////************************************************CA,DRM,CF,AV,FP,DNA,PE*******************************/////-->
          <div class="col-md-12">
            <!--///////////*****************************************************add************************************/////////-->
            <div class="col-lg-12" *ngFor="
                let E of ExaminationsList
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              ">
              <br />
              <br />

              <div>
                <h3>Observation:{{ E.qdslno }}</h3>
              </div>
              <div class="col-md-12 row">
                <div class="col-lg-8">
                  <select (change)="onSelectExaminationExhibit($event.target.value)" class="form-control dropdownsize">
                    <option value="">Select Exhibits</option>
                    <option *ngFor="let do of exhibit.Exhibits.examinationdata" value="{{ do.exhibitId }}"
                      [selected]="do.exhibitId == E.ExhibitId">
                      {{ do.name }}
                    </option>
                  </select>
                </div>
              </div>
              <br />
              <br />
              <div class="row">
                <div class="col-lg-8">
                  <textarea [(ngModel)]="E.Observations" rows="5" cols="45"></textarea>
                </div>
              </div>
              <br />
              <input style="width: 40%" class="btn btn-danger" type="button" (click)="RemoveExamination(E)"
                value="Remove" />

              <hr />
            </div>

            <!--///*****************************************************data********************************************///-->
            <div>&#160;</div>
            <div class="row">
              <div class="col-md-12 row">
                <div class="col-lg-5">
                  <select (change)="onSelectexidExhibit($event.target.value)" class="form-control dropdownsize">
                    <option value="">Select Exhibits</option>
                    <option *ngFor="let do of exhibit.Exhibits.examinationdata" value="{{ do.exhibitId }}">
                      {{ do.name }}
                    </option>
                  </select>
                </div>
              </div>
              <br />
              <br />
              <div class="row">
                <div class="col-lg-8">
                  <textarea [(ngModel)]="observation" rows="5" cols="45"></textarea>
                </div>
              </div>
            </div>

            <br />
            <button class="btn fa-plus-square" (click)="AddExamination()">
              Add
            </button>
            <br />
            <br />
          </div>
          <button class="btn btn-info" (click)="SaveExamination1()" style="align-items: center">
            Save
          </button>
          <br />
          <br />
          <div class="col-md-12 row">
            <h5>ADD DOCUMENTS</h5>

            <!--<div class="col-md-2"></div>-->
            <div class="col-md-12">
              <table cellpadding="0.08px;" cellspacing="0" width="100%"
                class="table-responsive border-collapse: collapse;" border="1">
                <tr>
                  <th style="padding: 5px">Sl no.</th>
                  <th style="text-align: center">Description</th>
                  <th style="text-align: center">ExhibitName</th>
                  <th style="text-align: center">Document</th>
                  <th></th>
                </tr>

                <tbody *ngFor="
                    let m of UploadDocuments
                      | paginate: { itemsPerPage: 5, currentPage: p };
                    let i = index
                  ">
                  <tr>
                    <td>{{ m.docqdslno }}</td>
                    <td>{{ m.DocumentDescription }}</td>
                    <td>
                      <select (change)="onSelectmExhibitId($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Exhibits</option>
                        <option *ngFor="let do of exhibit.Exhibits.examinationdata" value="{{ do.exhibitId }}"
                          [selected]="do.exhibitId == m.ExhibitId">
                          {{ do.name }}
                        </option>
                      </select>
                    </td>
                    <td>{{ m.docdetails }}</td>
                    <td>
                      <input style="width: 100%" type="button" (click)="RemoveDocuments(m)" value="Remove" />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <input style="width: 100%" type="text" [(ngModel)]="docqdslno" />
                    </td>
                    <td>
                      <input style="width: 100%" type="text" [(ngModel)]="docdescription" />
                    </td>
                    <td>
                      <select (change)="onSelectexid($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Exhibits</option>
                        <option *ngFor="let do of exhibit.Exhibits.examinationdata" value="{{ do.exhibitId }}">
                          {{ do.name }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <label class="dropdown-item">
                        <b for="excel-upload" style="
                            color: #0000ff;
                            cursor: pointer;
                            font-size: 20px;
                          "><i class="fas fa-file"></i>&nbsp;&nbsp;Upload
                          Files</b>
                        <input #fileInput (click)="fileInput.value = null" type="file" id="excel-upload"
                          style="display: none" (change)="getTheotherFiles3($event)" />
                      </label>
                    </td>
                    <td>
                      <input style="width: 100%" type="button" (click)="AddDocument()" value="Add" />
                    </td>
                  </tr>
                </tfoot>
              </table>
              <br />
              <br />
              <button class="btn btn-info" (click)="savedocuments()" style="align-items: center">
                Save Documents
              </button>
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>

          <!-- <div *ngFor="let er of exhibit.Exhibits.examinationdata|  paginate: { itemsPerPage: 5, currentPage: p };
                let i = index">

                    <div class="col-md-12">
                        <div class="col-lg-12">
                            <div class="col-lg-4">
                                <h5><b>Exhibit: </b> {{i + 1 }}</h5>
                                <h5><b>Nature Of Building/GoDown:</b> {{er.address }}</h5>
                                <h5><b>Adresss:</b>{{er.natureOfBuildingOrGodown }}</h5>
                            </div>
                            <div class="col-lg-8">
                                &nbsp;
                            </div>
                        </div>

                        <br />
                        <div class="row">
                            <div class="col-lg-8">
                                <textarea [(ngModel)]="er.observation" rows="5" cols="120"></textarea>
                            </div>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>

                    <button class="btn btn-info" (click)="SaveExamination(er)" style="align-items: center;">
                        Save
                    </button>
                </div> -->
          <br />
          <br />
          <br />

          <style>
            hr {
              display: block;
              margin-top: 0.5em;
              margin-bottom: 0.5em;
              margin-left: auto;
              margin-right: auto;
              border-style: inset;
              border-width: 1px;
            }
          </style>
        </div>
      </div>
    </div>
  </div>
  <div>&nbsp;</div>
  <div class="container" style="
      background: ghostwhite;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    " *ngIf="transactionHistory">
    <div>&nbsp;</div>
    <h3 style="text-align: center">HISTORY</h3>
    <div class="row col-sm-12">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th></th>
              <th>From</th>
              <th>TO</th>
              <th>Remarks</th>
            </tr>
            <tr *ngFor="let transaction of transactionHistory">
              <td>
                <i class="fas fa-user"></i>
              </td>
              <td>
                <h4 (click)="ShowMailForm(transaction)">
                  {{ transaction.from }}
                </h4>
              </td>
              <td>
                <h4 (click)="ShowMailForm(transaction)">
                  {{ transaction.wtD_To }}
                </h4>
              </td>
              <td>
                <h4 (click)="ShowMailForm(transaction)">
                  {{ transaction.wtD_Remarks }}
                </h4>
              </td>
            </tr>
          </thead>
        </table>
        <br /><br />
      </div>
    </div>
  </div>
  <ng-template class="modalsize" #filepopup let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Select File or Folder to View
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table" *ngIf="InwardTypeFile == 'false'">
        <thead style="color: #33383e">
          <th></th>
          <th>Object/Folder</th>
          <th>Setting</th>
        </thead>
        <tbody *ngFor="let folderview of AllFolders.subFolders">
          <tr>
            <td *ngIf="mob">
              <input type="checkbox" (click)="toggleSelect(folderview)"
                style="height: 16px; width: 16px; cursor: pointer" />
            </td>
            <td *ngIf="!mob" style="width: 18px">
              <input type="checkbox" (click)="toggleSelect(folderview)"
                style="height: 16px; width: 16px; cursor: pointer" />
            </td>
            <td *ngIf="mob" style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              ">
              <i class="fa fa-folder" style="color: orange"></i> &nbsp;&nbsp;
              <span (click)="GetSubfolder(folderview)" style="cursor: pointer" title="{{ folderview.folderName }}">{{
                folderview.folderName }}</span>
            </td>
            <td *ngIf="!mob" style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              ">
              <i class="fa fa-folder" style="color: orange"></i> &nbsp;&nbsp;
              <span (click)="GetSubfolder(folderview)" style="cursor: pointer" title="{{ folderview.folderName }}">{{
                folderview.folderName }}</span>
            </td>
            <td *ngIf="!multi">
              <div ngbDropdown class="dropdown" placement="bottom-left">
                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer"><i class="fa fa-gear"
                    aria-hidden="true"></i></a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                  <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareFolder(folderview, shareSingleFolder)"
                    (click)="c('Close click')" style="cursor: pointer">Share</a>
                  <a class="dropdown-item" *ngIf="EnableDownload" (click)="DownloadFolder(folderview)"
                    style="cursor: pointer">Download</a>
                  <a class="dropdown-item" *ngIf="EnableEdit" (click)="UpdateSubFolder(folderview, editsubfolder)"
                    (click)="c('Close click')" style="cursor: pointer">Edit</a>
                  <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(folderview)" (click)="c('Close click')"
                    style="cursor: pointer">Delete</a>
                </div>
              </div>
            </td>

            <td *ngIf="multi">
              <div ngbDropdown class="dropdown" placement="bottom-left">
                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer"></i></a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                  <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                    (click)="c('Close click')" style="cursor: pointer">Delete</a>
                  <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                    (click)="c('Close click')" style="cursor: pointer">Share</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngFor="let ret of AllFolders.retData">
          <tr>
            <td *ngIf="mob">
              <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
            </td>
            <td *ngIf="!mob" style="width: 18px">
              <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'image/jpeg' ||
                  ret.documentType == 'image/png') &&
                mob
              ">
              <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
                (click)="c('Close click')" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'application/pdf' ||
                  ret.documentType == 'pdf') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  ret.documentType == 'xlsx') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  ret.documentType == 'ppt' ||
                  ret.documentType == 'pptx') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'video/mp4' ||
                  ret.documentType == 'mp4') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  ret.documentType == 'doc' ||
                  ret.documentType == 'docx') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'application/x-zip-compressed' ||
                  ret.documentType == 'zip') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'text/plain' ||
                  ret.documentType == 'txt') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'audio/mp3' ||
                  ret.documentType == 'mp3') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'image/jpeg' ||
                  ret.documentType == 'image/png') &&
                !mob
              ">
              <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
                (click)="c('Close click')" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'application/pdf' ||
                  ret.documentType == 'pdf') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  ret.documentType == 'xlsx') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  ret.documentType == 'ppt' ||
                  ret.documentType == 'pptx') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'video/mp4' ||
                  ret.documentType == 'mp4') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  ret.documentType == 'doc' ||
                  ret.documentType == 'docx') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'application/x-zip-compressed' ||
                  ret.documentType == 'zip') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'text/plain' ||
                  ret.documentType == 'txt') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'audio/mp3' ||
                  ret.documentType == 'mp3') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td [hidden]="
                ret.documentType == 'text/plain' ||
                ret.documentType == 'txt' ||
                ret.documentType == 'application/x-zip-compressed' ||
                ret.documentType == 'zip' ||
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'doc' ||
                ret.documentType == 'docx' ||
                ret.documentType == 'video/mp4' ||
                ret.documentType == 'mp4' ||
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx' ||
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx' ||
                ret.documentType == 'application/pdf' ||
                ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png' ||
                ret.documentType == 'pdf' ||
                ret.documentType == 'audio/mp3' ||
                ret.documentType == 'mp3'
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/anyf1.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
            </td>
            <td *ngIf="!multi">
              <div ngbDropdown class="dropdown" placement="bottom-left">
                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer"><i class="fa fa-gear"
                    aria-hidden="true"></i></a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                  <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(ret)" (click)="c('Close click')"
                    style="cursor: pointer">Delete</a>
                  <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareoneFile(ret, shareSingleFile)"
                    (click)="c('Close click')" style="cursor: pointer">Share</a>
                </div>
              </div>
              <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
            </td>

            <td *ngIf="multi">
              <div ngbDropdown class="dropdown" placement="bottom-left">
                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear" style="cursor: pointer"
                    aria-hidden="true"></i></a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                  <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                    (click)="c('Close click')" style="cursor: pointer">Delete</a>
                  <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                    (click)="c('Close click')" style="cursor: pointer">Share</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table" *ngIf="InwardTypeFile == 'true' && AllFiles">
        <thead style="color: #33383e">
          <th>Object</th>
          <th></th>
          <th style="font-weight: bold">Setting</th>
        </thead>

        <tbody *ngFor="let ret of AllFiles.retData">
          <tr>
            <td *ngIf="mob">
              <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
            </td>
            <td *ngIf="!mob" style="width: 18px">
              <input type="checkbox" (click)="toggleSelect(ret)" style="height: 16px; width: 16px; cursor: pointer" />
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'image/jpeg' ||
                  ret.documentType == 'image/png') &&
                mob
              ">
              <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
                (click)="c('Close click')" class="close" aria-label="Close" (click)="d('Cross click')" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" class="close" aria-label="Close"
                (click)="d('Cross click')" style="cursor: pointer" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'application/pdf' ||
                  ret.documentType == 'pdf') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  ret.documentType == 'xlsx') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  ret.documentType == 'ppt' ||
                  ret.documentType == 'pptx') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'video/mp4' ||
                  ret.documentType == 'mp4') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  ret.documentType == 'doc' ||
                  ret.documentType == 'docx') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'application/x-zip-compressed' ||
                  ret.documentType == 'zip') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'text/plain' ||
                  ret.documentType == 'txt') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              " *ngIf="
                (ret.documentType == 'audio/mp3' ||
                  ret.documentType == 'mp3') &&
                mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'image/jpeg' ||
                  ret.documentType == 'image/png') &&
                !mob
              ">
              <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer" (click)="ViewDoc(ret)"
                (click)="c('Close click')" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'application/pdf' ||
                  ret.documentType == 'pdf') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  ret.documentType == 'xlsx') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" style="cursor: pointer"
                title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  ret.documentType == 'ppt' ||
                  ret.documentType == 'pptx') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'video/mp4' ||
                  ret.documentType == 'mp4') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  ret.documentType == 'doc' ||
                  ret.documentType == 'docx') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'application/x-zip-compressed' ||
                  ret.documentType == 'zip') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'text/plain' ||
                  ret.documentType == 'txt') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>

            <td style="
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                width: 40%;
              " *ngIf="
                (ret.documentType == 'audio/mp3' ||
                  ret.documentType == 'mp3') &&
                !mob
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span (click)="ViewDoc(ret)" (click)="c('Close click')" title="{{ ret.documentName }}">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
            </td>
            <td [hidden]="
                ret.documentType == 'text/plain' ||
                ret.documentType == 'txt' ||
                ret.documentType == 'application/x-zip-compressed' ||
                ret.documentType == 'zip' ||
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'doc' ||
                ret.documentType == 'docx' ||
                ret.documentType == 'video/mp4' ||
                ret.documentType == 'mp4' ||
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx' ||
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx' ||
                ret.documentType == 'application/pdf' ||
                ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png' ||
                ret.documentType == 'pdf' ||
                ret.documentType == 'audio/mp3' ||
                ret.documentType == 'mp3'
              " (click)="ViewDoc(ret)" (click)="c('Close click')">
              <img src="assets/img/anyf1.png" style="width: 65px; height: 60px; cursor: pointer" />
              <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
            </td>
            <td *ngIf="!multi">
              <div ngbDropdown class="dropdown" placement="bottom-left">
                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer"><i class="fa fa-gear"
                    aria-hidden="true"></i></a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                  <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(ret)" (click)="c('Close click')"
                    style="cursor: pointer">Delete</a>
                  <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareoneFile(ret, shareSingleFile)"
                    (click)="c('Close click')" style="cursor: pointer">Share</a>

                  <!-- <div class="dropdown-divider"></div>
                                            <a class="dropdown-item">Separated link</a> -->
                </div>
              </div>
              <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
            </td>

            <td *ngIf="multi">
              <div ngbDropdown class="dropdown" placement="bottom-left">
                <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear" style="cursor: pointer"
                    aria-hidden="true"></i></a>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                  <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                    (click)="c('Close click')" style="cursor: pointer">Delete</a>
                  <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                    (click)="c('Close click')" style="cursor: pointer">Share</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')"
        style="color: black; cursor: pointer">
        Close
      </button>
    </div>
    <br />
  </ng-template>
  <!-- notification alert -->
  <div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
      <div class="alert alert-info" style="
          background-color: #545353;
          box-shadow: none;
          left: 145px;
          position: fixed;
          top: 480px;
          width: 350px;
        ">
        <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
          <i class="material-icons" style="color: white">close</i>
        </button>
        <span style="color: white; margin-top: 6px">
          <b style="text-transform: capitalize; font-size: 14px">
            {{ notify }}</b></span>
      </div>
    </div>
  </div>

  <ng-template #shareSingleFile let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
      <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="margin-left: 10px">
        {{ mem.friendName }}<br />
      </div>
      <div class="col-lg-12 row">
        <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)"
          style="margin-left: 10px">
          <option value="">Select Friend</option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
            {{ I.friendName }}
          </option>
        </select>
      </div>
      <br />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="SharOnlyFileToUsers()" (click)="d('Cross click')">
        Share
      </button>
    </div>
    <br />
  </ng-template>

  <ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
      <br />
    </div>

    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="margin-left: 10px">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)"
        style="margin-left: 10px">
        <option value="" hidden>Select Friend</option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />
    <div class="col-lg-12 text-center">
      <span style="font-size: 12px">Don't find your friend in the list? &nbsp;</span>
      <button type="button" class="btn btn-primary" (click)="addfrnd()">
        Add Friend
      </button>
    </div>
    <div *ngIf="frndrqst">
      <br />
      <div>
        <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                                                              Email:
                                                          </h5> -->
        <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email" name="dp" />
      </div>
      <br />
      <div>
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
          style="color: black; color: white; padding: 5px; margin-left: 10px">
          Send Request
        </button>
      </div>
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ShareExsistingSubFolderToUsers()"
        (click)="d('Cross click')">
        Share
      </button>
    </div>
    <br />
  </ng-template>

  <ng-template #shareall let-d="dismiss" let-c="dismiss">
    <div>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
          <b>Select Members to Share</b>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="font-size: 16px">
        <br />
      </div>

      <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer; margin-left: 10px">
        {{ mem.friendName }}<br />
      </div>
      <div class="col-lg-12 row">
        <select *ngIf="shareitem" class="form-control dropdownsize" style="margin-left: 10px"
          (change)="ShareFileMembers($event.target.value)">
          <option value="" hidden>Select Friend</option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer">
            {{ I.friendName }}
          </option>
        </select>
      </div>
      <br />

      <div class="col-lg-12 text-center">
        <span style="font-size: 12px">Don't find your friend in the list? &nbsp;</span>
        <button type="button" class="btn btn-primary" (click)="addfrnd()">
          Add Friend
        </button>
      </div>
      <div class="col-lg-12 text-center">
        <span style="font-size: 12px">Do you wish to cancel shared documents? &nbsp;</span>

        <button type="button" class="btn btn-primary" (click)="cancelshare()">
          Cancel Shared Documents
        </button>
      </div>

      <div *ngIf="frndrqst">
        <div>
          <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;">
                                                                                  Email:
                                                                              </h5> -->
          <br />
          <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
        </div>
        <br />
        <div>
          <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
            style="color: black; color: white; padding: 5px; margin-left: 10px">
            Send Request
          </button>
        </div>
      </div>
      <br />
      <div *ngIf="cancelshareall">
        <div class="col-lg-12 row">
          <select *ngIf="shareitem1" class="form-control dropdownsize"
            (change)="ShareFileMembers1($event.target.value)">
            <option value="" hidden>Select Friend</option>
            <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer">
              {{ I.friendName }}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" *ngIf="shareitem1" class="btn btn-primary" (click)="shareallallcancel()"
          (click)="d('Cross click')">
          Cancel Shared Documents
        </button>
        &nbsp; &nbsp;
        <button type="button" *ngIf="!shareitem1" class="btn btn-primary" (click)="shareallall()"
          (click)="d('Cross click')">
          Share
        </button>
      </div>
    </div>
    <br />
  </ng-template>

  <ng-template #deleteMultiConformation let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
        <b>Delete Data </b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
      <div class="col-lg-12 row">
        <h4 class="modal-title w-100" id="myModalLabel">
          <b>Do You want to Delete {{ deletemulticontent }} &nbsp; ?</b>
        </h4>
      </div>
      <br />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="Deleteselected()" (click)="d('Cross click')">
        Delete
      </button>
      <button type="button" class="btn btn-warning" (click)="d('Cross click')">
        Close
      </button>
    </div>
    <br />
  </ng-template>

  <ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px">
      <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer; margin-left: 10px">
        {{ mem.friendName }}<br />
      </div>
      <div class="col-lg-12 row">
        <select class="form-control dropdownsize" style="margin-left: 10px"
          (change)="ShareFileMembers($event.target.value)">
          <option value="">Select Friend</option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer">
            {{ I.friendName }}
          </option>
        </select>
      </div>
      <br />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()" style="cursor: pointer"
        (click)="d('Cross click')">
        Share
      </button>
    </div>
    <br />
  </ng-template>
  <!-- EDIT FOLDER -->
  <ng-template #editsubfolder let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
      <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
    </div>
    <br />
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="UpdateSubFolderName()" (click)="d('Cross click')"
        style="color: black; cursor: pointer; width: 15%">
        Update
      </button>
    </div>
    <br />
  </ng-template>

  <style>
    .modalsize {
      width: 950px;
      max-width: 950px;
      overflow-y: 50vh;
    }

    @media (max-width: 960px) {
      .modalsize {
        width: 450px;
        max-width: 650px;
        overflow-y: 50vh;
      }
    }

    @media (max-width: 575px) {
      .modalsize {
        width: 480px;
        max-width: 550px;
        overflow-y: 50vh;
      }
    }

    @media (max-width: 460px) {
      .modalsize {
        width: 380px;
        max-width: 450px;
        overflow-y: 50vh;
      }
    }

    @media (max-width: 390px) {
      .modalsize {
        width: 350px;
        max-width: 450px;
        overflow-y: 50vh;
      }
    }

    @media (max-width: 320px) {
      .modalsize {
        width: 310px;
        max-width: 450px;
        overflow-y: 50vh;
      }
    }
  </style>
</div>