import { Component, OnInit } from '@angular/core';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileNumberGenerationService } from 'src/app/Shared/FileNumberGeneration/file-number-generation.service';
import { InwardServiceService } from 'src/app/Shared/Inward/inward-service.service';
import { FolderService } from 'src/app/Shared/Folder/folder.service';
import { TransactionService } from 'src/app/Shared/Transaction/transaction.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-file-pagefor-mail-box',
  templateUrl: './file-pagefor-mail-box.component.html',
  styleUrls: ['./file-pagefor-mail-box.component.css']
})
export class FilePageforMailBoxComponent implements OnInit {
  workflowStages: any;
  departmentlist: any;
  yearlist: any;
  divisionlist: any;
  subjectlist: any;
  typelist: any;
  sectionlist: any;
  formcodelist: any;
  statuslist: any;
  fileplanlist: any;
  example15data: any;
  example15model: any;
  folder: any;
  uid: any;
  FileNumber: any;
  locationlist: any;
  AutoIncrId: any;
  CurrentStatus: any;
  selectedyear: any;
  selectedlocation: any;
  selecteddepartment: any;
  selecteddivision: any;
  selectedsection: any;
  selectedgroup: any;
  subtypelist: any;
  selectedsubject: any;
  selectedminorsubject: any;
  minorsublist: any;
  selectedtype: any;
  selectedformcode: any;
  selectedpreservation: any;
  selectedperiod: any;
  fileplanName:any
  description:any
  selectedstatus: any;
  roleid: string;
  constructor(private workflowservice: WorkFlowService, private spinner: NgxSpinnerService,
    private fileNumberGenerationService: FileNumberGenerationService, private inwardService: InwardServiceService,
    private folderService: FolderService, private transactionservice: TransactionService) { 
      this.roleid = localStorage.getItem("role");

    }

  ngOnInit(): void {
    this.GetWorkflowStages();
  }
  GetWorkflowStages() {
    this.spinner.show();
    this.workflowservice.getWorkflowStagesforfile("FILE").subscribe((response: any) => {
      this.workflowStages = response;
      this.spinner.hide();
      this.CurrentStatus = this.workflowStages.filter(function (stage) {
        return (stage.id === this.folder.status);
      })[0].workflowStage;
    })
  }
  GetMinorSub() {
    var subject =
    {
      SubjectId: this.FileNumber.subject
    }
    this.spinner.show();
    this.fileNumberGenerationService.getMinorsubjects(subject).subscribe((response: any) => {
      this.departmentlist = response;
      this.spinner.hide();
    })
  }
  GetSubTypes(){
    var type =
    {
        TypeID: this.FileNumber.sectionId

    }
    this.spinner.show();
    this.fileNumberGenerationService.getSubTypes(type).subscribe((response: any) => {
      this.subtypelist = response;
      this.spinner.hide();
    })
  }
  getLocations(){
    this.spinner.show();
    this.fileNumberGenerationService.getLocation().subscribe((response: any) => {
      this.locationlist = response;
      this.spinner.hide();
    })
  }
  getDepartments() {
    this.spinner.show();
    this.fileNumberGenerationService.getDepartments().subscribe((response: any) => {
      this.departmentlist = response;
      this.spinner.hide();
    })
  }
  getsubtypes(){
    this.spinner.show();
    this.fileNumberGenerationService.getSubtype().subscribe((response: any) => {
      this.departmentlist = response;
      this.spinner.hide();
    })
  }
  getminorsubs(){
    this.spinner.show();
    this.fileNumberGenerationService.getMinorSubjects().subscribe((response: any) => {
      this.minorsublist = response;
      this.spinner.hide();
    })
  }
  getYears() {
    this.spinner.show();
    this.fileNumberGenerationService.getYears().subscribe((response: any) => {
      this.yearlist = response;
      this.spinner.hide();
    })
  }
  getDivisions() {
    this.spinner.show();
    this.fileNumberGenerationService.getDivisions().subscribe((response: any) => {
      this.divisionlist = response;
      this.spinner.hide();
    })
  }
  getSubjects() {
    this.spinner.show();
    this.fileNumberGenerationService.getSubjects().subscribe((response: any) => {
      this.subjectlist = response;
      this.spinner.hide();
    })
  }
  getTypes() {
    this.spinner.show();
    this.fileNumberGenerationService.getTypes().subscribe((response: any) => {
      this.typelist = response;
      this.spinner.hide();
    })
  }
  getSections() {
    this.spinner.show();
    this.fileNumberGenerationService.getSections().subscribe((response: any) => {
      this.sectionlist = response;
      this.spinner.hide();
    })
  }
  getFormCode() {
    this.spinner.show();
    this.fileNumberGenerationService.getFormCode().subscribe((response: any) => {
      this.formcodelist = response;
      this.spinner.hide();
    })
  }
  getStatus() {
    this.spinner.show();
    this.fileNumberGenerationService.getStatus().subscribe((response: any) => {
      this.statuslist = response;
      this.spinner.hide();
    })
  }

  getUsers() {
    this.spinner.show();
    this.transactionservice.getUsers().subscribe((response: any) => {
      this.example15data = response;
      this.spinner.hide();
    })
  }
  GenerateautoincrId(){
    this.spinner.show();
    this.fileNumberGenerationService.getautoid().subscribe((response: any) => {
      this.AutoIncrId = response;
      this.spinner.hide();
    })
  }
  onSelectYear(id){
    this.selectedyear=id
  }
  onSelectLocation(id){
    this.selectedlocation=id
  } 
  onSelectDepartment(id){
    this.selecteddepartment=id
  } 
  onSelectDivision(id){
    this.selecteddivision=id
  } 
  onSelectSection(id){
    this.selectedsection=id
  } 
  onSelectGroups(id){
    this.selectedgroup=id
  }
  onSelectSubject(id){
    this.selectedsubject=id
  }
  onSelectMinorSubject(id){
    this.selectedminorsubject=id
  }
  onSelectType(id){
    this.selectedtype=id
  }
  onSelectFormCode(id){
    this.selectedformcode=id
  }
  onSelectPreservation(id){
    this.selectedpreservation=id
  }
  onSelectPeriod(id){
    this.selectedperiod=id;
  }
  onSelectStatus(id){
    this.selectedstatus=id;
  }
  saveFileCode() {
    var tempFileNumber = this.FileNumber.locationId + "/" + this.FileNumber.departmentId + "/" + this.FileNumber.divisionId + "/" + this.FileNumber.sectionId + "/" + this.FileNumber.group + "/" + this.FileNumber.subject + "/" + this.FileNumber.minorsubject + "/" + this.FileNumber.year + "/" + this.AutoIncrId;

    var data = {
      ToUsers: this.example15model,
      FromUser: this.uid,
      Location: this.FileNumber.locationId,
      Department: this.FileNumber.departmentId,
      MailId: this.FileNumber.mailId == "" ? 0 : this.FileNumber.mailId,
      FinancialYear: this.FileNumber.year,
      Division: this.FileNumber.divisionId,
      Section: this.FileNumber.sectionId,
      Subject: this.FileNumber.subject,
      MinorSubject: this.FileNumber.minorsubject,
      Type: this.FileNumber.type,
      Group: this.FileNumber.group,
      FormCode: this.FileNumber.formcodeId,
      Status: this.FileNumber.status,
      FileNumber: this.FileNumber.mailId == "" ? tempFileNumber : this.FileNumber.fileNumber,
      Description: this.FileNumber.description,
      FileplanName: this.FileNumber.fileplanName,
      Preservation: this.FileNumber.preservation,
      Period: this.FileNumber.period,
      Id: this.AutoIncrId
    }
    this.spinner.show();
    this.fileNumberGenerationService.saveFileCode(data).subscribe((response: any) => {
      this.example15data = response;
      this.spinner.hide();
      if (this.folder.status == "1") {
        alert("Created successfully!!!");
      }
      else {
        alert("Mail sent successfully!!!");
      }
    })
  }
}
