<div>&nbsp;</div>

<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div style="  background: linear-gradient(270deg, #7100aa, #000);
">
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="col-md-12 row" style="color: white;">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <h2 style="text-align: center;">OUTWARD LETTER</h2>
        </div>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row" style="color: white;">
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <!-- <h4>Current Stage: {{ CurrentStatus }}</h4> -->
            <h4>{{ outward.mailid }}</h4>
        </div>
    </div>
    <div>&nbsp;</div>

    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      ">
       <div class="col-md-12 row">
        <div class="col-md-1">&nbsp;</div>
        <div class="col-md-2">
          <br/>
            <button class="btn btn-primary" (click)="gotofile(filepopup)">View Object</button>
        </div>
    </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label for="section">Object Number</label>

                <select *ngIf="!outwarddata.one" (change)="OnselectFilename($event.target.value)" class="form-control dropdownsize">
                    <option value="">Select Number</option>
                    <option *ngFor="let do of fileplanlist" value={{do.fileId}} [selected]="do.fileId==filenumber">
                        {{do.filename}}
                    </option>
                </select>

                <input *ngIf="outwarddata.one" type="text" class="form-control" name="subject"
                   [(ngModel)]="outwarddata.one.filenumber" autofocus readonly />
            </div>
            <div class="col-md-4">
                <label for="to">To</label>
                <tag-input id="TagInp" [ngModel]="users" name="test" [onlyFromAutocomplete]="false"
                    (onRemove)="onItemRemoved($event)" (onAdd)="onItemAdded($event)" [addOnBlur]="true"
                    [errorMessages]="errorMessages" [separatorKeyCodes]="[13,188,186,32]">
                    <tag-input-dropdown [showDropdownIfEmpty]="false" [dynamicUpdate]="false" [zIndex]="2000"
                        [focusFirstElement]="true" [displayBy]="'userName'" [identifyBy]="'userId'"
                        [autocompleteItems]="UsersList">
                        <ng-template let-item="item" let-index="index">
                            {{ item.userName }}
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input>
            </div>
        </div>
        <div>&nbsp;</div>

        <div class="col-md-12 row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <label for="outward">Outward Number</label>
                <input type="text" class="form-control" name="outward" [(ngModel)]="OutwardNumber" autofocus readonly />
            </div>
            <div class="col-md-4">
                <label for="date">Date (MM-DD-YYYY)</label>
                <input type="date" class="form-control" name="date" [(ngModel)]="date" autofocus />
            </div>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-2"></div>
           
            <div class="col-md-4">
                &nbsp;
            </div>
            <div>&nbsp;</div>

            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                    <h4>Document Details</h4>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="col-md-12 row">
                <div class="col-md-2"></div>

                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="inward">Outward Type</label>
                    <select  (change)="OnselectOutwardType($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Outward Type</option>
                        <option *ngFor="let do of outwardtypelist" value={{do.id}} [selected]="do.id==outward.outwardtype">
                            {{do.outwardType1}}
                        </option>
                    </select>

                </div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="page">Pages</label>
                    <input type="text" class="form-control" name="page" [(ngModel)]="outward.pages" autofocus />
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="inward">Inward Number</label>
                    <input *ngIf="!outwarddata.one" type="text" class="form-control" name="inward"
                    [(ngModel)]="Inwardno" autofocus />
                    <input *ngIf="outwarddata.one" type="text" class="form-control" name="subject"
                    [(ngModel)]="outwarddata.one.wO_InwordNo" autofocus readonly />
                </div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="page">Address</label>
                    <textarea type="text" class="form-control" name="address" [(ngModel)]="outward.address"
                        autofocus></textarea>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="sensitivity">Sensitivity</label>
                    <select  (change)="OnselectSensitivity($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Sensitivity</option>
                        <option *ngFor="let do of sensitivitylist" value={{do.id}} [selected]="do.id==outward.sensitivity">
                            {{do.sensitivityType}}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    &nbsp;
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <h4>Dispatch Details</h4>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="refno">Outward Reference Number</label>
                    <input type="number" class="form-control" name="refno" [(ngModel)]="outwardRefNumber" autofocus />
                </div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="dispatchmode">Dispatch Mode</label>
                    <select  (change)="OnselectSensitivity($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Dispatch Mode</option>
                        <option *ngFor="let do of dispatchmodelist" value={{do.id}} [selected]="do.id==outward.dispatchMode">
                            {{do.dispatchMode}}
                        </option>
                    </select>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="reply">Reply Awaited</label>
                    <select  (change)="OnselectReplyAwaited($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Reply Awaited</option>
                        <option *ngFor="let do of replyawaitedlist" value={{do.id}} [selected]="do.id==outward.replyAwaited">
                            {{do.replyAwaited1}}
                        </option>
                    </select>

                </div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="doctype">Document Type</label>
                    <select  (change)="OnselectDocumentType($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Document Type</option>
                        <option *ngFor="let do of documenttypelist" value={{do.id}} [selected]="do.id==outward.docType">
                            {{do.documentType}}
                        </option>
                    </select>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label>Status</label>
                    <select  (change)="OnselectStatus($event.target.value)" class="form-control dropdownsize">
                        <option value="">Select Status</option>
                        <option *ngFor="let do of workflowStages" value={{do.id}} [selected]="do.id==outward.status">
                            {{do.workflowStage}}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <div>&nbsp;</div>
                    <label for="recievedfrom">Office Notes/Remarks/Comments</label>
                    <textarea type="text" class="form-control" name="subject" [(ngModel)]="outward.remarks"
                        autofocus></textarea>
                </div>
            </div>
            <div class="col-md-12 row">&nbsp;</div>
            <div class="col-md-12 row">&nbsp;</div>
        
            <div class="col-md-12 row">
              <div class="col-md-3">&nbsp;</div>
              <div class="col-md-4">
                <button type="submit" class="btn btn-primary form-control" style="
                    
                    color: white;
                    padding: 9px 30px;
                  " (click)="PreviewOutward()">
                  PREVIEW & DOWNLOAD
                </button>
              </div>
              <div class="col-md-3">
                <button
                  type="submit"
                  class="btn btn-primary form-control"
                  (click)="SaveOutWard()"
                  style="padding: 9px 30px"
                >
                  SEND
                </button>
              </div>
              <div class="col-md-4">&nbsp;</div>
            </div>
    
        </div>
    </div>
    <div>&nbsp;</div>
    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      ">
        <div class="col-md-12 row">
            <div>&nbsp;</div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-light">
                        <tr>
                            <th style="display: none;"></th>
                            <th></th>
                            <th>From</th>
                            <th>TO</th>
                            <th>Remarks</th>
                        </tr>
                        <tr *ngFor="let inbox of outwarddata.two">
                            <td>
                                <i class="fas fa-user"></i>
                            </td>
                            <td>
                                <h5 (click)="ShowMailForm(inbox);">{{ inbox.from }}</h5>
                            </td>
                            <td>
                                <h5 (click)="ShowMailForm(inbox);">{{ inbox.wtD_To }}</h5>
                            </td>
                            <td>
                                <h5 (click)="ShowMailForm(inbox);">{{ inbox.wtD_Remarks }}</h5>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </div>
    <div>&nbsp;</div>
    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      "  *ngIf="DocList">
        <div>&nbsp;</div>

        <div class="col-md-12 row" *ngIf="DocList">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div>&nbsp;</div>

                <h3 style="padding-left: 30%;">Exhibits</h3>
                <br />

                <div>&nbsp;</div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead class="thead-light">
                        <tr>
                            <th>Document Name</th>
                            <th>Document</th>
                        </tr>
                        <tr *ngFor="let doc of DocList.folderName">
                            <td>{{ doc.exhibitName }}</td>
                            <td>
                                <input type="submit" class="form-control btn btn-warning" (click)="gotopdfViewForAssignTask(doc)"
                                    value="view" />
                            </td>
                        </tr>
                    </thead>
                </table> 
            </div>  
    </div>
    </div>
    <div>&nbsp;</div>
    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      " *ngIf="outwarddata.one || ExaminationWindowStatus">
        <div>&nbsp;</div>

        <div class="col-md-12 row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <button *ngIf="outwarddata.one" class="fa fa-arrow-down form-control btn btn-info"
                    (click)="AddExaminations(outwarddata.one.filenumber)">
                    For Examination Report
                </button>
            </div>
        </div>

        <div class="col-md-12 row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div>&nbsp;</div>
                <div *ngIf="ExaminationWindowStatus">
                    <h4 style="padding-left: 20%;">EXAMINATIONS</h4>
                    <div>&nbsp;</div>
                </div>
            </div>
            <table class="table table-striped"  *ngIf="exhibit">
                <thead class="thead-light">
                    <tr>
                        <th>SL No.</th>
                        <th>Nature Name</th>
                        <th>Examine By</th>
                        <th>Observation</th>
                        <th>Date Time</th>
                        <th>Action</th>
                    </tr>
                    <tr *ngFor="let pd of exhibit.Exhibits.existingExam| paginate: { itemsPerPage: 5, currentPage: p };
                    let i = index">
                        <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                        <td>{{ pd.natureName }}</td>
                        <td>{{ pd.userName }}</td>
                        <td>{{ pd.observations }}</td>
                        <td>{{ pd.dateTime }}</td>
                        <td>
                            <input type="submit" (click)="showOpinion(pd)" class="form-control" value="OPINIONS" />
                        
                        </td>
                    </tr>
                </thead>
            </table><br />
            <pagination-controls *ngIf="exhibit" (pageChange)="p = $event" style="float: right;">
            </pagination-controls>
        </div>
    </div>
    <div>&nbsp;</div>
    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      " *ngIf="showOpinionTable">
        <div>&nbsp;</div>
        <div class="col-md-12 row" >
            <div class="col-md-2"></div>
            <div class="col-md-8" *ngIf="showOpinionTable || MessageShow">
                <h5>
                    Opinions for {{ ExaminationDetails.natureName }}, which is Examined by
                    {{ ExaminationDetails.userName }}
                </h5>
                <h6 style="color: red;" *ngIf="MessageShow">{{ Message }}</h6>
            </div>
            <table class="table table-striped" >
                <thead class="thead-light">
                    <tr>
                        <th>Opinion</th>
                        <th>By</th>
                        <th>DateTime</th>
                    </tr>
                    <tr *ngFor="let op of Opinions">
                        <td>{{ op.opinion }}</td>
                        <td>{{ op.givenByName }}</td>
                        <td>{{ op.dateTime }}</td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <div>&nbsp;</div>
    <div class="container" style="
        background: ghostwhite;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
      " *ngIf="addExaminationStatus">
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-8" >
                <h4>ADD EXAMINATION</h4>
                <!--/////////////////************************************************CA,DRM,CF,AV,FP,DNA,PE*******************************/////-->
                <div class="col-md-12">
                    <!--///////////*****************************************************add************************************/////////-->
                    <div class="col-lg-12" *ngFor="let E of ExaminationsList">
                        <br />
                        <br />

                        <div class="row">
                            <h3>
                                Observation:{{ E.qdslno}}
                            </h3>
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <label>Exhibits</label>
                                <select  (change)="onSelectExaminationExhibit($event.target.value)" class="form-control dropdownsize">
                                    <option value="">Select Exhibits</option>
                                    <option *ngFor="let do of exhibit.Exhibits.examinationdata" value={{do.exhibitId}}>
                                        {{do.name}}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-lg-8">
                                <textarea [(ngModel)]="E.Observations" rows="5" cols="120"></textarea>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <!--///*****************************************************data********************************************///-->
                    <div>
                        &#160;
                    </div>
                    <div class="row">
                        <div class="row">
                            <div class="col-lg-3">
                                <select  (change)="onSelectexidExhibit($event.target.value)" class="form-control dropdownsize">
                                    <option value="">Select Exhibits</option>
                                    <option *ngFor="let do of exhibit.Exhibits.examinationdata" value={{do.exhibitId}}>
                                        {{do.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-lg-8">
                                <textarea [(ngModel)]="observation" rows="5" cols="120"></textarea>
                            </div>
                        </div>
                    </div>

                    <br />
                    <button class="btn fa-plus-square" (click)="AddExamination()">
                        Add
                    </button>
                    <br />
                    <br />
                </div>

                <button class="btn btn-info" (click)="SaveExamination1()"
                  >
                    Save
                </button>

                <div *ngFor="let er of exhibit.Exhibits.examinationdata | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index">
                    <!--/////////////////************************************************ICExhibitDetailsOnes*******************************/////-->

                    <div class="col-md-12" >
                        <div class="col-lg-12">
                            <div class="col-lg-4">
                                <h5><b>Exhibit: </b>{{ i + 1 }}</h5>
                                <h5><b>Nature Of Building/GoDown:</b> {{ er.address }}</h5>
                                <h5><b>Adresss:</b> {{ er.natureOfBuildingOrGodown }}</h5>
                            </div>
                            <div class="col-lg-8">
                                &nbsp;
                            </div>
                        </div>

                        <br />
                        <div class="row">
                            <div class="col-lg-8">
                                <textarea [(ngModel)]="er.observation" rows="5" cols="120"></textarea>
                            </div>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>
             
                    <button  class="btn btn-info" (click)="SaveExamination(er)">
                        Save
                    </button>
                </div>
                <br />
                <br />
                <br />
                <div>
                    &nbsp;
                </div>

                <style>
                    hr {
                        display: block;
                        margin-top: 0.5em;
                        margin-bottom: 0.5em;
                        margin-left: auto;
                        margin-right: auto;
                        border-style: inset;
                        border-width: 1px;
                    }
                </style>
            </div>
        </div>
    </div>
</div>
    <!-- notification alert -->
    <div class="col-md-12 row">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-4" *ngIf="dismissalert">
            <div class="alert alert-info" style="
            background-color: #545353;
            box-shadow: none;
            left: 145px;
            position: fixed;
            top: 480px;
            width: 350px;
          ">
                <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close"
                    (click)="closealert()">
                    <i class="material-icons" style="color: white;">close</i>
                </button>
                <span style="color: white; margin-top: 6px;">
                    <b style="text-transform: capitalize; font-size: 14px;">
                        {{ notify }}</b></span>
            </div>
        </div>
    </div>
    <ng-template class="modalsize" #filepopup let-d="dismiss" let-c="dismiss">
      <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Select Object or Folder to View</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <table class="table" *ngIf="InwardTypeFile=='false'">
              <thead style="color: #33383e;">
                <th></th>
                <th>File/Folder</th>
                <th>Setting</th>
              </thead>
              <tbody *ngFor="let folderview of AllFolders.subFolders">
                  <tr>
                    <td *ngIf="mob">
                      <input type="checkbox" (click)="toggleSelect(folderview)"
                        style="height: 16px; width: 16px; cursor: pointer;" />
                    </td>
                    <td *ngIf="!mob" style="width: 18px;">
                      <input type="checkbox" (click)="toggleSelect(folderview)"
                        style="height: 16px; width: 16px; cursor: pointer;" />
                    </td>
                      <td *ngIf="mob" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    ">
                          <i class="fa fa-folder" style="color: orange;"></i> &nbsp;&nbsp;
                          <span (click)="GetSubfolder(folderview)" style="cursor: pointer;"
                              title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                      </td>
                      <td *ngIf="!mob" style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    ">
                          <i class="fa fa-folder" style="color: orange;"></i> &nbsp;&nbsp;
                          <span (click)="GetSubfolder(folderview)" style="cursor: pointer;"
                              title="{{ folderview.folderName }}">{{ folderview.folderName }}</span>
                      </td>
                      <td *ngIf="!multi">
                        <div ngbDropdown class="dropdown" placement="bottom-left">
                          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer;"><i
                              class="fa fa-gear" aria-hidden="true"></i></a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                            <a class="dropdown-item" *ngIf="EnableShare" (click)="ShareFolder(folderview, shareSingleFolder)"
                            (click)="c('Close click')"  style="cursor: pointer;">Share</a>
                            <a class="dropdown-item"  *ngIf="EnableDownload" (click)="DownloadFolder(folderview)" style="cursor: pointer;">Download</a>
                            <a class="dropdown-item" *ngIf="EnableEdit" (click)="UpdateSubFolder(folderview, editsubfolder)"
                            (click)="c('Close click')"  style="cursor: pointer;">Edit</a>
                            <a class="dropdown-item" *ngIf="EnableDelete" (click)="Delete(folderview)"  (click)="c('Close click')" style="cursor: pointer;">Delete</a>
                         
                          </div>
                        </div>
                       
                      </td>
        
                      <td *ngIf="multi">
                        <div ngbDropdown class="dropdown" placement="bottom-left">
                          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                            <i class="fa fa-gear" aria-hidden="true" style="cursor: pointer;"></i></a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                            <a class="dropdown-item" *ngIf="EnableDelete"  (click)="DeleteMorecoin(deleteMultiConformation)"
                            (click)="c('Close click')"  style="cursor: pointer;">Delete</a>
                            <a class="dropdown-item"  *ngIf="EnableShare"  (click)="SharewithMoreFriends(sharewithmore)"
                            (click)="c('Close click')" style="cursor: pointer;">Share</a>
                          </div>
                        </div>
                      </td>
                  </tr>
              </tbody>
              <tbody *ngFor="let ret of AllFolders.retData">
                <tr>
                <td *ngIf="mob">
                  <input type="checkbox" (click)="toggleSelect(ret)"
                    style="height: 16px; width: 16px; cursor: pointer;" />
                </td>
                <td *ngIf="!mob" style="width: 18px;">
                  <input type="checkbox" (click)="toggleSelect(ret)"
                    style="height: 16px; width: 16px; cursor: pointer;" />
                </td> 
            
                                         <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'image/jpeg' ||
                        ret.documentType == 'image/png') &&
                      mob
                    ">
                          <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                              (click)="ViewDoc(ret)" (click)="c('Close click')"  />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'ppt' ||
                        ret.documentType == 'pptx') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'video/mp4' ||
                        ret.documentType == 'mp4') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'doc' ||
                        ret.documentType == 'docx') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'application/x-zip-compressed' ||
                        ret.documentType == 'zip') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'text/plain' ||
                        ret.documentType == 'txt') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>

                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'audio/mp3' ||
                        ret.documentType == 'mp3') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>

                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'image/jpeg' ||
                        ret.documentType == 'image/png') &&
                      !mob
                    ">
                          <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                              (click)="ViewDoc(ret)" (click)="c('Close click')"  />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'ppt' ||
                        ret.documentType == 'pptx') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'video/mp4' ||
                        ret.documentType == 'mp4') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'doc' ||
                        ret.documentType == 'docx') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'application/x-zip-compressed' ||
                        ret.documentType == 'zip') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'text/plain' ||
                        ret.documentType == 'txt') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>

                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'audio/mp3' ||
                        ret.documentType == 'mp3') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td [hidden]="
                      ret.documentType == 'text/plain' ||
                      ret.documentType == 'txt' ||
                      ret.documentType == 'application/x-zip-compressed' ||
                      ret.documentType == 'zip' ||
                      ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                      ret.documentType == 'doc' ||
                      ret.documentType == 'docx' ||
                      ret.documentType == 'video/mp4' ||
                      ret.documentType == 'mp4' ||
                      ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                      ret.documentType == 'ppt' ||
                      ret.documentType == 'pptx' ||
                      ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                      ret.documentType == 'xlsx' ||
                      ret.documentType == 'application/pdf' ||
                      ret.documentType == 'image/jpeg' ||
                      ret.documentType == 'image/png' ||
                      ret.documentType == 'pdf' ||
                      ret.documentType == 'audio/mp3' ||
                      ret.documentType == 'mp3'
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/anyf1.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
                      </td>  
                      <td *ngIf="!multi">
                        <div ngbDropdown class="dropdown" placement="bottom-left">
                          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer;"><i
                              class="fa fa-gear" aria-hidden="true"></i></a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                            <a class="dropdown-item" *ngIf="EnableDelete"  (click)="Delete(ret)" (click)="c('Close click')" style="cursor: pointer;">Delete</a>
                            <a class="dropdown-item" *ngIf="EnableShare"  (click)="ShareoneFile(ret, shareSingleFile)" (click)="c('Close click')"
                              style="cursor: pointer;">Share</a>
        
                         
                          </div>
                        </div>
                        <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
                      </td>
        
                      <td *ngIf="multi">
                        <div ngbDropdown class="dropdown" placement="bottom-left">
                          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                              style="cursor: pointer;" aria-hidden="true"></i></a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                            <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                            (click)="c('Close click')"  style="cursor: pointer;">Delete</a>
                            <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                            (click)="c('Close click')"  style="cursor: pointer;">Share</a>
                          </div>
                        </div>
                      </td>                  
                  </tr>
              </tbody>
          </table>
          <table class="table" *ngIf="InwardTypeFile=='true' && AllFiles">
              <thead style="color: #33383e;">
                <th>Object</th>
                <th></th>
                <th style="font-weight: bold;">Setting</th>

              </thead>
         
              <tbody *ngFor="let ret of AllFiles.retData">
                  <tr>
                    <td *ngIf="mob">
                      <input type="checkbox" (click)="toggleSelect(ret)"
                        style="height: 16px; width: 16px; cursor: pointer;" />
                    </td>
                    <td *ngIf="!mob" style="width: 18px;">
                      <input type="checkbox" (click)="toggleSelect(ret)"
                        style="height: 16px; width: 16px; cursor: pointer;" />
                    </td>
                                         <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'image/jpeg' ||
                        ret.documentType == 'image/png') &&
                      mob
                    ">
                          <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                              (click)="ViewDoc(ret)" (click)="c('Close click')"  class="close" aria-label="Close" (click)="d('Cross click')"/>
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  class="close" aria-label="Close" (click)="d('Cross click')" style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf') &&
                      mob"  (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"   style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"   style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'ppt' ||
                        ret.documentType == 'pptx') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'video/mp4' ||
                        ret.documentType == 'mp4') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'doc' ||
                        ret.documentType == 'docx') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'application/x-zip-compressed' ||
                        ret.documentType == 'zip') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'text/plain' ||
                        ret.documentType == 'txt') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>

                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                    " *ngIf="
                      (ret.documentType == 'audio/mp3' ||
                        ret.documentType == 'mp3') &&
                      mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>

                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'image/jpeg' ||
                        ret.documentType == 'image/png') &&
                      !mob
                    ">
                          <img src="{{ ret.file }}" style="width: 50px; height: 40px; cursor: pointer;"
                              (click)="ViewDoc(ret)" (click)="c('Close click')"  />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"   >
                          <img src="assets/img/pdficon.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/xls.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  style="cursor: pointer;"
                              title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'ppt' ||
                        ret.documentType == 'pptx') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"   >
                          <img src="assets/img/ppticon2.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"   title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'video/mp4' ||
                        ret.documentType == 'mp4') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"    title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'doc' ||
                        ret.documentType == 'docx') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"    >
                          <img src="assets/img/docx.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"   title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'application/x-zip-compressed' ||
                        ret.documentType == 'zip') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"    >
                          <img src="assets/img/zip.jpg" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'text/plain' ||
                        ret.documentType == 'txt') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/txt.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>

                      <td style="
                      white-space: nowrap;
                      width: auto;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      width: 40%;
                    " *ngIf="
                      (ret.documentType == 'audio/mp3' ||
                        ret.documentType == 'mp3') &&
                      !mob
                    " (click)="ViewDoc(ret)" (click)="c('Close click')" >
                          <img src="assets/img/video.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span (click)="ViewDoc(ret)" (click)="c('Close click')"  title="{{ ret.documentName }}">
                              &nbsp;&nbsp;{{ ret.documentName }}
                          </span>
                      </td>
                      <td [hidden]="
                      ret.documentType == 'text/plain' ||
                      ret.documentType == 'txt' ||
                      ret.documentType == 'application/x-zip-compressed' ||
                      ret.documentType == 'zip' ||
                      ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                      ret.documentType == 'doc' ||
                      ret.documentType == 'docx' ||
                      ret.documentType == 'video/mp4' ||
                      ret.documentType == 'mp4' ||
                      ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                      ret.documentType == 'ppt' ||
                      ret.documentType == 'pptx' ||
                      ret.documentType ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                      ret.documentType == 'xlsx' ||
                      ret.documentType == 'application/pdf' ||
                      ret.documentType == 'image/jpeg' ||
                      ret.documentType == 'image/png' ||
                      ret.documentType == 'pdf' ||
                      ret.documentType == 'audio/mp3' ||
                      ret.documentType == 'mp3'
                    " (click)="ViewDoc(ret)" (click)="c('Close click')"  >
                          <img src="assets/img/anyf1.png" style="width: 65px; height: 60px; cursor: pointer;" />
                          <span> &nbsp;&nbsp;{{ ret.documentName }} </span>
                      </td>  
                      <td *ngIf="!multi">
                        <div ngbDropdown class="dropdown" placement="bottom-left">
                          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer;"><i
                              class="fa fa-gear" aria-hidden="true"></i></a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                            <a class="dropdown-item" *ngIf="EnableDelete"  (click)="Delete(ret)" (click)="c('Close click')" style="cursor: pointer;">Delete</a>
                            <a class="dropdown-item" *ngIf="EnableShare"  (click)="ShareoneFile(ret, shareSingleFile)" (click)="c('Close click')"
                              style="cursor: pointer;">Share</a>
        
                            <!-- <div class="dropdown-divider"></div>
                                          <a class="dropdown-item">Separated link</a> -->
                          </div>
                        </div>
                        <!-- <input class="delete btn btn-danger" type="button" value="Delete" (click)="Delete(ret)" /></td> -->
                      </td>
        
                      <td *ngIf="multi">
                        <div ngbDropdown class="dropdown" placement="bottom-left">
                          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-gear"
                              style="cursor: pointer;" aria-hidden="true"></i></a>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-danger">
                            <a class="dropdown-item" *ngIf="EnableDelete" (click)="DeleteMorecoin(deleteMultiConformation)"
                            (click)="c('Close click')" style="cursor: pointer;">Delete</a>
                            <a class="dropdown-item" *ngIf="EnableShare" (click)="SharewithMoreFriends(sharewithmore)"
                            (click)="c('Close click')" style="cursor: pointer;">Share</a>
                          </div>
                        </div>
                      </td>               
                  </tr>
              </tbody>
          </table>
      </div>
      <br />
      <div class="modal-footer">
         
          <button type="button" class="btn btn-outline-dark" (click)="d('Cross click')"
              style="color: black; cursor: pointer;">
              Close
          </button>
      </div>
      <br />
  </ng-template>
      
<ng-template #shareSingleFile let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
      <b>Select Members to Share</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px;">
    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="margin-left: 10px;">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)" style="margin-left: 10px;">
        <option value=""> Select Friend </option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="SharOnlyFileToUsers()" (click)="d('Cross click')">
      Share
    </button>
  </div>
  <br />
</ng-template>

<ng-template #shareSingleFolder let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
      <b>Select Members to Share</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px;">


    <br />
  </div>

  <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="margin-left: 10px;">
    {{ mem.friendName }}<br />
  </div>
  <div class="col-lg-12 row">
    <select class="form-control dropdownsize" (change)="ShareFileMembers($event.target.value)" style="margin-left: 10px;">
      <option value="" hidden> Select Friend </option>
      <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}">
        {{ I.friendName }}
      </option>
    </select>
  </div>
  <br />
  <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list? &nbsp;</span>
    <button type="button" class="btn btn-primary" (click)="addfrnd()">
      Add Friend
    </button>
  </div>
  <div *ngIf="frndrqst">
    <br>
    <div>
      <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;padding-left:10px ;">
                                                            Email:
                                                        </h5> -->
      <input type="text" id="accid" [(ngModel)]="accid" class="form-control" placeholder="Email" name="dp" />
    </div>
    <br />
    <div>

      <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
        style="color: black; color: white;padding: 5px;margin-left: 10px;">
        Send Request
      </button>
    </div>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ShareExsistingSubFolderToUsers()" (click)="d('Cross click')">
      Share
    </button>
  </div>
  <br />
</ng-template>

<ng-template #shareall let-d="dismiss" let-c="dismiss">
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
        <b>Select Members to Share</b>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="font-size: 16px;">

      <br />
    </div>

    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;margin-left: 10px;">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select *ngIf="shareitem" class="form-control dropdownsize" style="margin-left: 10px;" (change)="ShareFileMembers($event.target.value)">
        <option value="" hidden> Select Friend </option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer;">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />

    <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Don't find your friend in the list? &nbsp;</span>
      <button type="button" class="btn btn-primary" (click)="addfrnd()">
        Add Friend
      </button>
    </div>
    <div class="col-lg-12 text-center"> <span style="font-size: 12px;">Do you wish to cancel shared documents?
        &nbsp;</span>

      <button type="button" class="btn btn-primary" (click)="cancelshare()">
        Cancel Shared Documents
      </button>
    </div>

    <div *ngIf="frndrqst">
      <div>
        <!-- <h5 class="modal-title" id="modal-basic-title" style="color: mediumslateblue;">
                                                                                Email:
                                                                            </h5> -->
        <br>
        <input type="text" placeholder="Email" id="accid" [(ngModel)]="accid" class="form-control" name="dp" />
      </div>
      <br />
      <div>
        <button type="button" class="btn btn-outline-dark btn-primary" (click)="savefrienddetails()"
          style="color: black; color: white;padding: 5px;margin-left: 10px;">
          Send Request
        </button>
      </div>
    </div>
    <br />
    <div *ngIf="cancelshareall">

      <div class="col-lg-12 row">
        <select *ngIf="shareitem1" class="form-control dropdownsize" (change)="ShareFileMembers1($event.target.value)">
          <option value="" hidden> Select Friend </option>
          <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer;">
            {{ I.friendName }}
          </option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="shareitem1" class="btn btn-primary" (click)="shareallallcancel()"
        (click)="d('Cross click')">
        Cancel Shared Documents
      </button> &nbsp; &nbsp;
      <button type="button" *ngIf="!shareitem1" class="btn btn-primary" (click)="shareallall()"
        (click)="d('Cross click')">
        Share
      </button>
    </div>
  </div>
  <br />
</ng-template>


<ng-template #deleteMultiConformation let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
      <b>Delete Data </b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px;">
    <div class="col-lg-12 row">
      <h4 class="modal-title w-100" id="myModalLabel">
        <b>Do You want to Delete {{ deletemulticontent }} &nbsp; ?</b>
      </h4>
    </div>
    <br />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="Deleteselected()" (click)="d('Cross click')">
      Delete
    </button>
    <button type="button" class="btn btn-warning" (click)="d('Cross click')">
      Close
    </button>
  </div>
  <br />
</ng-template>

<ng-template #sharewithmore let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="text-align: center; font-weight: 600;">
      <b>Select Members to Share</b>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="font-size: 16px;">
    <div class="col-lg-12 row" *ngFor="let mem of selectedFriend" style="cursor: pointer;margin-left: 10px;">
      {{ mem.friendName }}<br />
    </div>
    <div class="col-lg-12 row">
      <select class="form-control dropdownsize" style="margin-left: 10px;" (change)="ShareFileMembers($event.target.value)">
        <option value=""> Select Friend </option>
        <option *ngFor="let I of Friends.friendsList" value="{{ I.uid }}" style="cursor: pointer;">
          {{ I.friendName }}
        </option>
      </select>
    </div>
    <br />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ShareExsistingMultiFolderToUsers()" style="cursor: pointer;"
      (click)="d('Cross click')">
      Share
    </button>
  </div>
  <br />
</ng-template>
<!-- EDIT FOLDER -->
<ng-template #editsubfolder let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Folder</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title" id="modal-basic-title">Folder Name:</h5>
    <input type="text" id="updatemsg" [(ngModel)]="updatemsg" class="form-control" name="dp" />
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="UpdateSubFolderName()" (click)="d('Cross click')"
      style="color: black; cursor: pointer; width: 15%;">
      Update
    </button>
  </div>
  <br />
</ng-template>

    <style>
      .modalsize {
          width: 950px;
          max-width: 950px;
          overflow-y: 50vh;
      }

      @media (max-width: 960px) {
          .modalsize {
              width: 450px;
              max-width: 650px;
              overflow-y: 50vh;
          }
      }

      @media (max-width: 575px) {
          .modalsize {
              width: 480px;
              max-width: 550px;
              overflow-y: 50vh;
          }
      }

      @media (max-width: 460px) {
          .modalsize {
              width: 380px;
              max-width: 450px;
              overflow-y: 50vh;
          }
      }

      @media (max-width: 390px) {
          .modalsize {
              width: 350px;
              max-width: 450px;
              overflow-y: 50vh;
          }
      }

      @media (max-width: 320px) {
          .modalsize {
              width: 310px;
              max-width: 450px;
              overflow-y: 50vh;
          }
      }
  </style>