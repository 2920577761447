import { Component, OnInit } from '@angular/core';
import { ReportService } from '../Shared/Report/report.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.css']
})
export class AuditReportComponent implements OnInit {
  FromDate: any;
  ToDate: any;
  uid: any;
  username: any;
  roleid: any;
  ReportList: any;
  menu: any;
  p: number = 1;
  CurrentDate: any;
  converttostringdate: any;
  CurrentTime: any;
  converttostringtime: string;
  converttostring: string;
  ReportListCIDetails: any;
  ReportListDIDetails: any;
  ReportListEIDetails: any;
  ReportListSessionDetails: any;
  ReportListTransactionDetails: any;
  ReportListDownloadedDetails: any;
  ReportListWorkflowDetails: any;
  mobileview: boolean;
  constructor(private reportservice: ReportService, private router: ActivatedRoute,private datepipe:DatePipe,
    private spinner:NgxSpinnerService) {
      this.ReportList=[]
    this.router.params.subscribe(params => {
      if (params["id"]) {
        this.menu = (params["id"])
      }
    });
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserFullName");
    }
    this.isMobileMenu()
    var date = new Date();
    this.converttostringdate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.CurrentDate = this.converttostringdate.toString();
    this.converttostringtime = this.datepipe.transform(date, 'h:mm a');
    this.CurrentTime = this.converttostringtime.toString();
    this.converttostring = this.datepipe.transform(date, 'dd-MM-yyyy');
    this.FromDate=this.converttostring
    this.ToDate=this.converttostring
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  ngOnInit(): void {

  }
  GetDetails() {
    if (this.menu == "usd") {
      this.GetUserSessionDetails();
    }
    else if (this.menu == "utd") {
      this.GetUserTransactionDetails();
    }
    else if (this.menu == "createditems") {
      this.GetCIDetails();
    }
    else if (this.menu == "editeditems") {
      this.GetEIDetails();
    }
    else if (this.menu == "deleteditems") {
      this.GetDIDetails();
    }
    else if (this.menu == "download") {
      this.GetDownloadedDetails();
    }
    else if (this.menu == "workflow") {
      this.GetWorkflowDetails();
    }
  }
  GetCIDetails() {
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      function: 'Create',
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid
    }
    this.spinner.show();
    this.reportservice.getCIDetails(data).subscribe((response: any) => {
      this.ReportListCIDetails = response
      this.ReportListDIDetails=[]
      this.ReportListEIDetails =[]
      this.ReportListSessionDetails=[]
      this.ReportListTransactionDetails=[]
      this.ReportListWorkflowDetails=[]
      this.ReportListDownloadedDetails=[]
      this.spinner.hide();

    })
  }
  GetDIDetails() {
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      function: 'Delete',
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid
    }
    this.spinner.show();
    this.reportservice.getDIDetails(data).subscribe((response: any) => {
      this.ReportListDIDetails = response
      this.ReportListCIDetails = []
      this.ReportListEIDetails =[]
      this.ReportListSessionDetails=[]
      this.ReportListTransactionDetails=[]
      this.ReportListWorkflowDetails=[]
      this.ReportListDownloadedDetails=[]
      this.spinner.hide();

    })
  }
  GetEIDetails() {
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      function: 'Edit',
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid
    }
    this.spinner.show();
    this.reportservice.getEIDetails(data).subscribe((response: any) => {
      this.ReportListEIDetails = response
      this.ReportListCIDetails = []
      this.ReportListDIDetails=[]
      this.ReportListSessionDetails=[]
      this.ReportListTransactionDetails=[]
      this.ReportListWorkflowDetails=[]
      this.ReportListDownloadedDetails=[]
      this.spinner.hide();

    })
  }
  GetUserSessionDetails() {
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid
    }
    this.spinner.show();
    this.reportservice.getUserSessionDetails(data).subscribe((response: any) => {
      this.ReportListSessionDetails = response
      this.ReportListCIDetails = []
      this.ReportListDIDetails=[]
      this.ReportListEIDetails =[]
      this.ReportListTransactionDetails=[]
      this.ReportListWorkflowDetails=[]
      this.ReportListDownloadedDetails=[]
      this.spinner.hide();
    })
  }
  GetUserTransactionDetails() {
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid
    }
    this.spinner.show();
    this.reportservice.getUserTransactionDetails(data).subscribe((response: any) => {
      this.ReportListTransactionDetails = response
      this.ReportListCIDetails = []
      this.ReportListDIDetails=[]
      this.ReportListEIDetails =[]
      this.ReportListSessionDetails=[]
      this.ReportListWorkflowDetails=[]
      this.ReportListDownloadedDetails=[]
      this.spinner.hide();

    })
  }
  GetDownloadedDetails(){
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid,
      function: 'Download',
    }
    this.spinner.show();
    this.reportservice.getDownloadedTransactionDetails(data).subscribe((response: any) => {
      this.ReportListDownloadedDetails = response
      this.ReportListCIDetails = []
      this.ReportListDIDetails=[]
      this.ReportListEIDetails =[]
      this.ReportListSessionDetails=[]
      this.ReportListTransactionDetails=[]
      this.ReportListWorkflowDetails=[]
      this.spinner.hide();

    })
  }
  GetWorkflowDetails(){
    var data = {
      fromdate: this.FromDate,
      todate: this.ToDate,
      viewedby: this.uid,
      viewername: this.username,
      viewerrole: this.roleid,
      function: 'Workflow Data',

    }
    this.spinner.show();
    this.reportservice.getWorkflowTransactionDetails(data).subscribe((response: any) => {
      this.ReportListWorkflowDetails = response
      this.ReportListCIDetails = []
      this.ReportListDIDetails=[]
      this.ReportListEIDetails =[]
      this.ReportListSessionDetails=[]
      this.ReportListTransactionDetails=[]
      this.ReportListDownloadedDetails=[]
      this.spinner.hide();

    })
  }
}
