import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from '../Shared/MenuMaster/menu-master.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  // styles: [require('./home.component.css')]
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  lap = false;
  mob = false;
  mobile = false;
  isLoggedIn;
  name;
  apiconsumer: string;
  apiconsumer1: boolean;
  constructor(private spinner: NgxSpinnerService, private menuservice: MenuMasterService, private router:Router) {
    this.spinner.show();
    window.scrollTo(0, 0);
    this.name =  localStorage.getItem("UserFullName");
    if (localStorage.loadingpage == 'true') {
      localStorage.loadingpage = 'false';
      location.reload();
    }
    if(localStorage.userId){
      this.isLoggedIn=true;
    }
    else{
      this.isLoggedIn=false;

    }
    
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.apiconsumer = localStorage.getItem("IsApiConsumer");
      if(this.apiconsumer == "true"){
        this.apiconsumer1 = true;
      }
    }
  }

  ngOnInit() {
    setTimeout(() => {
      var footer = document.getElementById('footer');
      if (footer.style.display == 'none') {
        footer.style.display = 'block';
      }
    }, 1000);
    window.onresize = changeImage;

    function changeImage() {
      if (window.innerWidth > 600) {
        console.log(this.lap);
        this.lap = true;
        this.mob = false;
      } else {
        this.lap = false;
        this.mob = true;
      }
    }

    if (window.innerWidth > 600) {
      console.log(this.lap);

      this.lap = true;
      this.mob = false;
    } else {
      this.lap = false;
      this.mob = true;
    }

    this.spinner.hide();

    if (window.innerWidth <= 500) {
      this.mobile = true;
    }
    var anima = document.getElementById('anima');
    if (anima) {
      anima.style.display = 'block';
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 6000);
  }

  downpath: any;
  seleteddownload: any;
  DownloadFile() {
   
   // this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/blocdrive.exe";
   this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/SrichidDrive%20Installer.zip";
    this.seleteddownload = true;
    window.open(this.downpath);


}

DownloadMigrateFile()
{
  this.downpath = "https://accountdatastorage.blob.core.windows.net/pictures/GoogleDriveMigration.zip";
  this.seleteddownload = true;
  window.open(this.downpath);
}

DownloadFilePortable() {
  this.menuservice.downloadFileportable().subscribe((data: any) => {
    if (data != null) {
      this.downpath = data;
      this.seleteddownload = true;
      window.open(this.downpath);
    }
    });

  }
  logout(){
    localStorage.clear();
    this.router.navigate(['/']);
    location.reload();
  }
}
