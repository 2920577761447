<div id="grad1">


    <app-nav-bar></app-nav-bar>

    <!-- MultiStep Form -->
    <div class="container-fluid" id="grad1">
        <div class="row justify-content-center mt-0">
            <div class="col-11 col-sm-9 col-md-9 col-lg-9 text-center p-0 mt-3 mb-2">
                <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                    <p></p>
                    <div class="row ckyckmedia">

                        <div class="col-md-12 mx-0">
                            <form id="msform" [formGroup]="RegistrationForm">
                                <!-- progressbar -->
                                <ul id="progressbar">
                                    <li class="active" id="account"><strong>PERSONAL DETAILS</strong></li>
                                    <li id="personal"><strong>PROOF OF IDENTITY</strong></li>
                                    <li id="payment"><strong>PROOF ADDRESS</strong></li>
                                    <li id="register"><strong>ATTESTATION</strong></li>

                                    <li id="payment"><strong>UPLOAD IMAGE</strong></li>
                                    <li id="confirm"><strong>Finish</strong></li>

                                </ul>
                                <!-- fieldsets -->
                                <fieldset>
                                    <div class="form-card">
                                        <div class="col-md-12 mx-0">

                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">First Name</label>

                                                    <input formControlName="firstname" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="firstname"
                                                        [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                                                    <div *ngIf="submitted && f.firstname.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.firstname.errors.required">
                                                            First Name is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Middle Name</label>
                                                    <input formControlName="middlename" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="middlename" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Last Name</label>
                                                    <input formControlName="lastname" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="lastname" />

                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Maiden First Name</label>

                                                    <input formControlName="maidenfirstname" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="maidenfirstname"
                                                        [ngClass]="{ 'is-invalid': submitted && f.maidenfirstname.errors }">
                                                    <div *ngIf="submitted && f.maidenfirstname.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;"
                                                            *ngIf="f.maidenfirstname.errors.required">
                                                            Maiden Name is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Middle Name</label>
                                                    <input formControlName="maidenmiddlename" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="maidenmiddlename" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Last Name</label>
                                                    <input formControlName="maidenlastname" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="maidenlastname" />

                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Father/Spouse First Name</label>

                                                    <input formControlName="fathername" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="fathername"
                                                        [ngClass]="{ 'is-invalid': submitted && f.fathername.errors }" />
                                                    <div *ngIf="submitted && f.fathername.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.fathername.errors.required">
                                                            Father Name is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Middle Name</label>
                                                    <input formControlName="fathermiddlename" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="fathermiddlename" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Last Name</label>

                                                    <input formControlName="fatherlastname" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="fatherlastname" />
                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Mother First Name </label>

                                                    <input formControlName="mothername" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="mothername"
                                                        [ngClass]="{ 'is-invalid': submitted && f.mothername.errors }" />
                                                    <div *ngIf="submitted && f.mothername.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.mothername.errors.required">
                                                            Mother Name is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Middle Name</label>
                                                    <input formControlName="mothermiddlename" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="mothermiddlename" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Last Name</label>
                                                    <input formControlName="motherlastname" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="motherlastname" />
                                                </div>
                                            </div>


                                            <h3
                                                style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;    margin-left: 8%;">
                                                Other Details</h3>
                                            <div class="col-md-12 row">&nbsp;</div>
                                            <div class="col-md-12 row">&nbsp;</div>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel"> Date Of Birth</label>
                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }"
                                                        formControlName="dateofbirth" class="form-control" type="date"
                                                        id="inputbutton" [(ngModel)]="dateofbirth" />
                                                    <div *ngIf="submitted && f.dateofbirth.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.dateofbirth.errors.required">
                                                            Date Of Birth
                                                             is required
                                                        </div>
                                                    </div>

                                                </div>



                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel"> Gender</label>
                                                    <select (change)="OnselectGender($event.target.value)"
                                                        class="form-control dropdownsize">
                                                        <option value="">Select Gender</option>
                                                        <option *ngFor="let do of Genders" value={{do.id}}>
                                                            {{do.gender}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Marital Status</label>
                                                    <select (change)="onSelectmaritalstatus($event.target.value)"
                                                        class="form-control">
                                                        <option value="">Select Marital Status</option>
                                                        <option *ngFor="let do of status" value={{do.id}}>
                                                            {{do.text}}
                                                        </option>
                                                    </select>
                                               </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Citizenship</label>

                                                    <select (change)="Onselectcitizen($event.target.value)"
                                                        class="form-control dropdownsize">
                                                        <option value="">Select Citizenship</option>
                                                        <option *ngFor="let do of Citizenship" value={{do.countryId}}>
                                                            {{do.country}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Occupation</label>
                                                    <select (change)="Onselectoccupation($event.target.value)"
                                                        class="form-control dropdownsize">
                                                        <option value="">Select Occupation</option>
                                                        <option *ngFor="let do of occupations" value={{do.id}}>
                                                            {{do.description}}
                                                        </option>
                                                    </select>

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Residential Status</label>
                                                    <select (change)="Onselectresidential($event.target.value)"
                                                        class="form-control dropdownsize">
                                                        <option value="">Select Residential Status</option>
                                                        <option *ngFor="let do of ResidentialStatus" value={{do.id}}>
                                                            {{do.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-12 row ">&nbsp;</div>
                                            <div class="col-md-12 row ">
                                                <div class="col-md-2 row ">&nbsp;</div>
                                                <div class="col-md-7 row ">
                                                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Please
                                                        tick if applicable Residence for tax purpose in jurisdiction(s)
                                                        outside india</h4>
                                                </div>
                                                <div class="col-md-1 row ">
                                                    <input class="form-control" formControlName="confirm"
                                                        [checked]="confirm" [(ngModel)]="confirm"
                                                        (change)="onSelectIsConfirm($event)" type="checkbox"
                                                        id="inputbutton" />
                                                </div>
                                                <div class="col-md-2 row ">&nbsp;</div>
                                            </div>
                                            <div class="col-md-12 row ">&nbsp;</div>
                                            <div *ngIf="details">
                                                <div class="col-md-12 row">
                                                    <div class="col-md-1 row">&nbsp;</div>
                                                    <div class="col-md-3 row">

                                                        <label id="emaillabel">Country jurisdiction(s) outside
                                                            India</label>

                                                        <input formControlName="countryjurisdiction"
                                                            class="form-control" type="text" id="inputbutton"
                                                            [(ngModel)]="countryjurisdiction"
                                                            [ngClass]="{ 'is-invalid': submitted && f.countryjurisdiction.errors }">
                                                        <div *ngIf="submitted && f.countryjurisdiction.errors"
                                                            class="invalid-feedback">
                                                            <div style="color: red;"
                                                                *ngIf="f.countryjurisdiction.errors.required">Country Name
                                                                is
                                                                required
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 row">&nbsp;</div>
                                                    <div class="col-md-3 row">
                                                        <label for="Email" id="emaillabel">Place/City of Birth</label>
                                                        <input formControlName="place" class="form-control" type="text"
                                                            id="inputbutton" [(ngModel)]="place" />

                                                    </div>
                                                    <div class="col-md-1 row">&nbsp;</div>
                                                    <div class="col-md-2 row">
                                                        <label for="Email" id="emaillabel">TIN Issued By
                                                            Jusisdiction</label>
                                                        <input formControlName="tinissued" class="form-control"
                                                            type="text" id="inputbutton" [(ngModel)]="tinissued" />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 row">
                                                    <div class="col-md-1 row">&nbsp;</div>
                                                    <div class="col-md-3 row">

                                                        <label id="emaillabel">ISO 3166 country code of birth</label>

                                                        <input formControlName="isocode" class="form-control"
                                                            type="text" id="inputbutton" [(ngModel)]="isocode" />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <input type="button" class="next action-button" (click)="Validateone()" name="next" value="Next" />
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <div class="col-md-12 mx-0">

                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Id Proof Type</label>

                                                    <input formControlName="idprooftype" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="idprooftype"
                                                        [ngClass]="{ 'is-invalid': submitted && f.idprooftype.errors }">
                                                    <div *ngIf="submitted && f.idprooftype.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.idprooftype.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Id Proof Number</label>
                                                    <input formControlName="proofnumber" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="proofnumber" [ngClass]="{ 'is-invalid': submitted && f.proofnumber.errors }">
                                                        <div *ngIf="submitted && f.proofnumber.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.proofnumber.errors.required">
                                                            Id Proof Number is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Id Proof Submitted</label>
                                                    <select  (change)="Onselectproofsumbit($event.target.value)"
                                                    class="form-control dropdownsize">
                                                        <option value="">Select Id Proof Submitted</option>
                                                        <option value="1">YES</option>
                                                        <option value="2">NO</option>
                                                      </select>
                                              
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous"  />
                                    <input type="button" name="next" class="next action-button" (click)="Validatetwo()" value="Next Step" />
                                </fieldset>

                                <fieldset>
                                    <div class="form-card">
                                        <div class="col-md-12 mx-0">
                                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;    float: left;
                                            margin-left: 8%; ">Proof Of Address(one certified copy of any one of the
                                                proof of address needs to be submitted)
                                            </h4><br>
                                            <div>&nbsp;</div>
                                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;     float: left;
                                            margin-left: 8%;">Current/Permanent/Overseas address :
                                            </h4>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Address type</label>

                                                    <input formControlName="address" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="address"
                                                        [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                                                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.address.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Line one</label>
                                                    <input formControlName="addressline" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="addressline" [ngClass]="{ 'is-invalid': submitted && f.addressline.errors }" >
                                                    <div *ngIf="submitted && f.addressline.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.addressline.errors.required">
                                                          
                                                            Field is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Line two</label>
                                                    <input [ngClass]="{ 'is-invalid': submitted && f.linetwo.errors }"
                                                        formControlName="linetwo" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="linetwo">
                                                    <div *ngIf="submitted && f.linetwo.errors" class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.linetwo.errors.required">
                                                            Field is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Line three</label>


                                                    <input formControlName="firstname" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="linethree"
                                                        [ngClass]="{ 'is-invalid': submitted && f.linethree.errors }">
                                                    <div *ngIf="submitted && f.linethree.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.linethree.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label id="emaillabel">City/Town/Village</label>

                                                    <input formControlName="citytown" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="citytown" [ngClass]="{ 'is-invalid': submitted && f.citytown.errors }">
                                                    <div *ngIf="submitted && f.citytown.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.citytown.errors.required">
                                                            Field is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">District</label>

                                                    <input [ngClass]="{ 'is-invalid': submitted && f.district.errors }"
                                                        formControlName="district" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="district">
                                                    <div *ngIf="submitted && f.district.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.district.errors.required">
                                                            Field is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label for="Email" id="emaillabel">State/U.T code </label>

                                                    <input formControlName="state" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="state"
                                                        [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.state.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel"><small>iso-3166 Country Code
                                                            of</small> Residence</label>
                                                    <input formControlName="isocountry" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="isocountry">

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Pin Code</label>
                                                    <input [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }"
                                                        formControlName="pincode" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="pincode">
                                                    <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.pincode.errors.required">
                                                            Field is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Proof Of Address(POA)</label>

                                                    <input formControlName="proofoadress" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="proofoadress"
                                                        [ngClass]="{ 'is-invalid': submitted && f.proofoadress.errors }">
                                                    <div *ngIf="submitted && f.proofoadress.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.proofoadress.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">(POA)Others</label>
                                                    <input formControlName="poa" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="poa">

                                                </div>

                                            </div>
                                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;    float: left;
                                            margin-left: 8%; ">Please tick if applicable is Not Same as
                                                Current/Permanent/Overseas Address details
                                            </h4>
                                            <br>
                                            <div class="col-md-12 row">

                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Line one</label>
                                                    <input formControlName="lineonedata" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="lineonedata">

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Line two</label>
                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.linetwodata.errors }"
                                                        formControlName="linetwodata" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="linetwodata">

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">

                                                    <label id="emaillabel">Line three</label>


                                                    <input formControlName="linethreedata" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="linethreedata"
                                                        [ngClass]="{ 'is-invalid': submitted && f.linethreedata.errors }">

                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">

                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label id="emaillabel">City/Town/Village</label>

                                                    <input formControlName="village" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="village">

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">District</label>

                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.districtone.errors }"
                                                        formControlName="districtone" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="districtone">

                                                </div>

                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">

                                                    <label for="Email" id="emaillabel">State/U.T code </label>

                                                    <input formControlName="stateutcode" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="stateutcode"
                                                        [ngClass]="{ 'is-invalid': submitted && f.stateutcode.errors }">

                                                </div>
                                            </div>


                                            <br>
                                            <div class="col-md-12 row">

                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel"><small>iso-3166 Country Code
                                                            of</small> Residence</label>
                                                    <input formControlName="isocountrycode" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="isocountrycode">

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Pin Code</label>
                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.pincodeone.errors }"
                                                        formControlName="pincodeone" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="pincodeone">

                                                </div>
                                            </div>

                                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;    float: left;
                                            margin-left: 8%; ">Contact Details(Communication Can be done by the
                                                specified mobile number Or Email Id)
                                            </h4>
                                            <div>&nbsp;</div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Office Telephone Number</label>


                                                    <input formControlName="Telephone" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="Telephone"
                                                        [ngClass]="{ 'is-invalid': submitted && f.Telephone.errors }">
                                                    <div *ngIf="submitted && f.Telephone.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.Telephone.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label id="emaillabel">Residence Telephone Number</label>

                                                    <input formControlName="Residencenum" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="Residencenum">

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Mobile Number</label>

                                                    <input [ngClass]="{ 'is-invalid': submitted && f.mobilenum.errors }"
                                                        formControlName="mobilenum" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="mobilenum">
                                                    <div *ngIf="submitted && f.mobilenum.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.mobilenum.errors.required">
                                                            Last
                                                            Name is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label for="Email" id="emaillabel">Fax Number</label>

                                                    <input formControlName="faxnumber" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="faxnumber"
                                                        [ngClass]="{ 'is-invalid': submitted && f.faxnumber.errors }">
                                                  
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Email-Id</label>
                                                    <input formControlName="email" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                        <div *ngIf="submitted && f.email.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.email.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous" />
                                    <input type="button" name="make_payment" class="next action-button" (click)="Validatethree()" value="Next" />
                                </fieldset>

                                <fieldset>
                                    <div class="form-card">
                                        <div class="col-md-12 mx-0">


                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-4 row">

                                                    <label id="emaillabel">Related person Type</label>
                                                    <select (change)="Onselectrelateds($event.target.value)"
                                                        class="form-control dropdownsize">
                                                        <option value="">Select Related person Type</option>
                                                        <option *ngFor="let do of relateds" value={{do.id}}>
                                                            {{do.typeofRelationship}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-4 row">
                                                    <label for="Email" id="emaillabel">Related Person KYC Number</label>
                                                    <input formControlName="kycnumber" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="kycnumber"
                                                        [ngClass]="{ 'is-invalid': submitted && f.kycnumber.errors }" />
                                                        <div *ngIf="submitted && f.kycnumber.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.kycnumber.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">First Name</label>
                                                    <input formControlName="personname" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="personname"
                                                        [ngClass]="{ 'is-invalid': submitted && f.personname.errors }">
                                                    <div *ngIf="submitted && f.personname.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.personname.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label id="emaillabel">Middle Name</label>

                                                    <input formControlName="personmiddlename" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="personmiddlename" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Last Name</label>

                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.personlastname.errors }"
                                                        formControlName="personlastname" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="personlastname" />

                                                </div>
                                            </div>


                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label for="Email" id="emaillabel">Pancard</label>

                                                    <input formControlName="Pancard" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="Pancard"
                                                        [ngClass]="{ 'is-invalid': submitted && f.Pancard.errors }">
                                                    <div *ngIf="submitted && f.Pancard.errors" class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.Pancard.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel"><small>Passport Number</small>
                                                        Residence</label>
                                                    <input formControlName="passportnum" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="passportnum" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Passport Exipre Date</label>
                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.Passportexipre.errors }"
                                                        formControlName="Passportexipre" class="form-control"
                                                        type="date" id="inputbutton" [(ngModel)]="Passportexipre" />

                                                </div>
                                            </div>

                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Voter Id</label>

                                                    <input formControlName="voterid" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="voterid"
                                                        [ngClass]="{ 'is-invalid': submitted && f.voterid.errors }">
                                                    <div *ngIf="submitted && f.voterid.errors" class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.voterid.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Driveing Licence</label>
                                                    <input formControlName="driveing" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="driveing" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Driveing Licence Exipre
                                                        Date</label>
                                                    <input formControlName="driveingdate" class="form-control"
                                                        type="date" id="inputbutton" [(ngModel)]="driveingdate" />

                                                </div>
                                            </div>

                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">UID(Aadhaar) </label>

                                                    <input formControlName="uidaadhaar" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="uidaadhaar"
                                                        [ngClass]="{ 'is-invalid': submitted && f.uidaadhaar.errors }">
                                                    <div *ngIf="submitted && f.uidaadhaar.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.uidaadhaar.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">NREGA job card</label>
                                                    <input formControlName="nregajob" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="nregajob" />

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Others(any document notified the
                                                        central Govt.) </label>
                                                    <input [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                                                        formControlName="Others" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="Others" />

                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Identification Number</label>
                                                    <input formControlName="Identification" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="Identification"
                                                        [ngClass]="{ 'is-invalid': submitted && f.Identification.errors }">

                                                </div>

                                            </div>
                                            <br>
                                            <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;    float: left;
                                            margin-left: 8%; ">Attestation Details
                                            </h3>
                                            <br> <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label for="Email" id="emaillabel">Document Received</label>

                                                    <input formControlName="document" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="document"
                                                        [ngClass]="{ 'is-invalid': submitted && f.document.errors }">
                                                    <div *ngIf="submitted && f.document.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.document.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Kyc Verification Date</label>
                                                    <input formControlName="kycverify" class="form-control" type="date"
                                                        id="inputbutton" [(ngModel)]="kycverify" [ngClass]="{ 'is-invalid': submitted && f.kycverify.errors }" />
                                                        <div *ngIf="submitted && f.kycverify.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.kycverify.errors.required">
                                                           Field is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Kyc Verification Name</label>
                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.Verification.errors }"
                                                        formControlName="Verification" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="Verification">
                                                    <div *ngIf="submitted && f.Verification.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.Verification.errors.required">
                                                            Field is is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">

                                                    <label for="Email" id="emaillabel">Kyc Verification Emp Code</label>

                                                    <input formControlName="Verificationcode" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="Verificationcode"
                                                        [ngClass]="{ 'is-invalid': submitted && f.Verificationcode.errors }">
                                                    <div *ngIf="submitted && f.Verificationcode.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;"
                                                            *ngIf="f.Verificationcode.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label id="emaillabel">Kyc Verification Designation</label>


                                                    <input formControlName="Verifycodedesignation" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="Verifycodedesignation"
                                                        [ngClass]="{ 'is-invalid': submitted && f.Verifycodedesignation.errors }">
                                                    <div *ngIf="submitted && f.Verifycodedesignation.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;"
                                                            *ngIf="f.Verifycodedesignation.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label id="emaillabel">Kyc Verification Branch</label>

                                                    <input formControlName="branch" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="branch" [ngClass]="{ 'is-invalid': submitted && f.branch.errors }" />
                                                        <div *ngIf="submitted && f.branch.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;"
                                                            *ngIf="f.branch.errors.required">
                                                            Field is
                                                            required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Organisation Name</label>

                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && f.organisation.errors }"
                                                        formControlName="organisation" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="organisation">
                                                    <div *ngIf="submitted && f.organisation.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.organisation.errors.required">
                                                            Last
                                                            Name is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <br>
                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">

                                                    <label for="Email" id="emaillabel">Organisation Code</label>

                                                    <input formControlName="organisationcode" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="organisationcode"
                                                        [ngClass]="{ 'is-invalid': submitted && f.organisationcode.errors }" />
                                                        <div *ngIf="submitted && f.organisationcode.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.organisationcode.errors.required">
                                                            Last
                                                            Name is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-3 row">
                                                    <label for="Email" id="emaillabel">Place Of Declaration</label>
                                                    <input formControlName="declaration" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.declaration.errors }"  type="text" id="inputbutton" [(ngModel)]="declaration" />
                                                        <div *ngIf="submitted && f.declaration.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.declaration.errors.required">
                                                            Last
                                                            Name is required
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-2 row">
                                                    <label for="Email" id="emaillabel">Date Of Declaration</label>
                                                    <input formControlName="declarationdate" class="form-control"
                                                        type="text" id="inputbutton" [(ngModel)]="declarationdate" [ngClass]="{ 'is-invalid': submitted && f.declarationdate.errors }" />
                                                        <div *ngIf="submitted && f.declarationdate.errors"
                                                        class="invalid-feedback">
                                                        <div style="color: red;" *ngIf="f.declarationdate.errors.required">
                                                            Last
                                                            Name is required
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous" />
                                    <input type="button" name="make_payment" class="next action-button" (click)="Validationfour()" value="Confirm"
                                        (click)="RegisterUser()" />
                                </fieldset>
                                <fieldset>
                                    <div class="form-card">
                                        <div class="col-md-12 mx-0">


                                            <div class="col-md-12 row">
                                                <div class="col-md-1 row">&nbsp;</div>
                                                <div class="col-md-8 row">

                                                    <label id="emaillabel">Remarks</label>

                                                    <input formControlName="remarks" class="form-control" type="text"
                                                        id="inputbutton" [(ngModel)]="remarks"
                                                        [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />

                                                </div>
                                            </div>
                                            <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-9 row">
                                                    <div>
                                                        <h5 class="fs-title" style="text-align: center;">Add Attachment
                                                        </h5>

                                                        <input style="padding: 13px;" type="file" accept="image/*"
                                                            name="avatars" #fileField (change)="onChange($event)">

                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                    <input type="button" name="previous" class="previous action-button-previous"
                                        value="Previous" (change)="Validateone()" />
                                    <input type="button" name="next" class="next action-button" value="Next Step" (click)="CKYCRegisterUser()" />
                                </fieldset>
                          
                                <fieldset>
                                    <div class="form-card">
                                        <h2 class="fs-title text-center">Success !</h2> <br><br>
                                        <div class="row justify-content-center">
                                            <div class="col-3"> <img
                                                    src="https://img.icons8.com/color/96/000000/ok--v2.png"
                                                    class="fit-image"> </div>
                                        </div> <br><br>
                                        <div class="row justify-content-center">
                                            <div class="col-7 text-center">
                                                <h5>You Have Successfully Registered</h5>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </fieldset>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>