import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/Shared/Analytics/analytics.service';
import { LoginService } from 'src/app/Shared/Login/login.service';

@Component({
  selector: 'app-project-creation',
  templateUrl: './project-creation.component.html',
  styleUrls: ['./project-creation.component.css']
})
export class ProjectCreationComponent implements OnInit {
  UserId: any;
  EmailId: any;
  Id: any;
  Role: any;
  Username: any;
  projectName: any;
  url: any;
  mobile: any;
  email: any;
  contactPerson: any;
  projectIncharge: any;
  objectiveOfAnalysis: any;
  projectObjective: any;
  companyName: any;
  prolist: any;
project: any;
  constructor(private loinservice: LoginService, private router: Router, private analyticsService: AnalyticsService) {
    if (localStorage.getItem("IsLoggedIn") == 'true') {
      this.UserId = localStorage.getItem("userId");
      this.Id = localStorage.getItem("ID");
      this.EmailId = localStorage.getItem("Email");
      this.Role = localStorage.getItem("role");
      this.Username = localStorage.getItem("UserName");
    }
  }


  ngOnInit(): void {
    this.GetProjectlist();
  }
  GetProjectlist() {
    this.analyticsService.getprojetlist(this.Id).subscribe(
      (res: any) => {
        this.prolist = res;
        console.log(this.prolist);
      }
    )
  }

  SaveProject() {
    const project = {
      ProjectName: this.projectName,
      CompanyName: this.companyName,
      ProjectObjective: this.projectObjective,
      ObjectiveOfAnalysis: this.objectiveOfAnalysis,
      ProjectIncharge: this.projectIncharge,
      ContactPerson: this.contactPerson,
      Email: this.email,
      Mobile: this.mobile,
      Url: this.url,
      UserId: this.Id
    };
    this.analyticsService.savenewproject(project).subscribe(
      (res: any) => {
        var result = res;
        alert("Project created successully!!!")
        this.GetProjectlist();
        // if (result == "Succ") {
        
        // }
        // else if (result == "Fail") {
        //   alert("Something went worng")
        // }
      }
    )

     

  }
  // DeleteEMP(data:any){

  // }

  deleteProject(data:any){
    this.project = data;
  }
  
  DeleteRowProject(){
  
    this.analyticsService.deleteProject(this.project).subscribe(
      (data: any) => {
        this.prolist = data;
        this.GetProjectlist();
        console.log(this.prolist);
      })
  
  }
  editProject(data:any){

  }
  addInfo(data:any){
    localStorage.setItem("projname", data)
    this.router.navigate(['/addInformation']);
  }

}
  