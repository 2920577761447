
<app-nav-bar></app-nav-bar>
<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
  <br>
    <div id="midVerlineDiv">
        <h2 id="EsignStyleText">E-Signature Style</h2>
    </div>
    <br><br>
    <div id="sign-div-content">
    <form #esignForm="ngForm">
        <div class="col-md-12 row " id="ThreeOptionsOfEsign">
            <div class="col-md-4" style="cursor: pointer;color: #9BAAB6;text-align: center;">
                <a id="choose" (click)="ActiveMenuEsign('1')">SELECT</a>
                <hr>
            </div>
            <div class="col-md-4" style="cursor: pointer;color: #9BAAB6;text-align: center;">
                <a id="draw" (click)="ActiveMenuEsign('2')">DRAW</a>
                <hr>
            </div>
            <div class="col-md-4" style="cursor: pointer;color: #9BAAB6;text-align: center;">
                <a id="upload" (click)="ActiveMenuEsign('3')">UPLOAD</a>
                <hr>
            </div>
        </div>
       
        <br><br><br><br>
        <!-- CHOOSE -->
        <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" *ngIf="!isDraw && !isUpload" id="SELECTtab" >
            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
                <div class="col-md-12 row" style="padding-bottom: 1.5%;">
                    <label id="EsignfieldTextName">Full Name</label>
                    <input class="form-control" placeholder="Full Name" id="inpBox" name="esfullname" type="text"  [(ngModel)]="esfullname">
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
                <div class="col-md-12">
                    <input type="radio" (change)="onSignFontChange($event)" name="sign" style="background-color: #EAF1F7;" [(ngModel)]="esign" value="1">
                    <h3 id="fontone">
                        &nbsp;&nbsp;{{esfullname}}&nbsp;{{esinitials}}
                    </h3>
                </div>
                <br />
                <div class="col-md-12">
                    <input type="radio" (change)="onSignFontChange($event)" name="sign" style="background-color: #EAF1F7;" [(ngModel)]="esign" value="2">
                    <h4 id="fonttwo">
                        &nbsp;&nbsp;{{esfullname}}&nbsp;{{esinitials}}
                    </h4>
                </div>
                <br />
                <div class="col-md-12">
                    <input type="radio" (change)="onSignFontChange($event)" name="sign" style="background-color: #EAF1F7;" [(ngModel)]="esign" value="3">
                    <h5 id="fontthree">
                        &nbsp;&nbsp;{{esfullname}}&nbsp;{{esinitials}}
                    </h5>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12" style="text-align: center;margin-left: 5%;margin-top: 1%;">
                <button type="submit" id="EsignsaveBtn" class="btn btn-primary" (click)="SaveChoosenESigns(esignForm)"><span
                        id="EsignCREATEBtnText">CREATE</span></button>&nbsp;&nbsp;
                <a [routerLink]="['/home']"><button type="button" class="btn btn-warning" id="CancelBtn" >Cancel</button></a>
            </div>
        </div>
          <!-- DRAW -->
          <div *ngIf="isDraw">
          <div class="col-md-12 row"  style="left: -3%;">
              <div class="col-md-2"></div>
              <div  class="col-md-8" >
                  <div>
                      <button type="button" class="btn btn-sm waves-light" id="drawsign" data-dismiss="modal" mdbWavesEffect>Draw your signature</button>
                  </div>
                  <br>
                  <div id="canv">
                      <ng-signature-pad id="signpad" doneButtonText="Save" format="base64" (done)="showImage($event)">
                      </ng-signature-pad>
                  </div>
                  <img [src]="signatureImage" *ngIf="signatureImage" style="    width: 480px;height: 85px;margin-top: -10%;" />
  
              </div>
  
          </div>
          <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12" style="text-align: center;margin-left: 11%; padding-top: 1%;">
                          <button type="submit" id="EsignsaveBtn" class="btn btn-primary" (click)="SaveDrawESigns(esignForm)"><span
                                  id="EsignCREATEBtnText">CREATE</span></button>
                          <button type="button" class="btn btn-warning" id="CancelBtn" >Cancel</button>
                      </div>
                  </div>
        <!-- UPLOAD -->
        <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" *ngIf="isUpload">
            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 row">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                    <div id="upldsignini">
                        <img src="./assets/img/Choosefrom.png" style="cursor: pointer;margin-top: 15px;" />
                        <br />
                        <label id="UploadSignatures">
                            <p class="temp-upload-icon" for="excel-upload" style="cursor: pointer;">
                                UPLOAD SIGNATURE</p>
                            <input #fileInput (click)="fileInput.value = null" type="file"
                                id='excel-upload' style="display: none"
                                (change)="UploadESignature($event)" />
                        </label>
                        <br />
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 row">
                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12" style="display:flex;justify-content: center;">
                    <img src="{{uploadedsign}}" alt="" height="50px" id="UploadedSign">
                </div>
            </div>
            <br><br>
            <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12" style="text-align: center; margin-left: 8%;padding-top: 1%;">
                <button type="submit" id="EsignsaveBtn" class="btn btn-primary" (click)="saveUploadedEsign()"><span
                        id="EsignCREATEBtnText">CREATE</span></button>
                <button type="button" class="btn btn-warning" id="CancelBtn" >Cancel</button>
            </div>
        </div>
    </form>
  </div>
  </div>
    </div>
  </div>
  

