import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from '../Shared/MenuMaster/menu-master.service';

@Component({
  selector: 'app-setup-page-data',
  templateUrl: './setup-page-data.component.html',
  styleUrls: ['./setup-page-data.component.css']
})
export class SetupPageDataComponent implements OnInit {
  type: any;
  SetupList: any;

  constructor(private router:ActivatedRoute,private spinner:NgxSpinnerService,private menuservice:MenuMasterService) {
    this.router.params.subscribe(params=>{
      if(params["id"]){
        this.type= params["id"];
      }
    });
    this.GetSetupData(this.type);
   }

  ngOnInit(): void {
  }
  GetSetupData(type){
    this.spinner.show();
  this.menuservice.getAllSetupList(type).subscribe((Data: any) => {
    this.SetupList = Data;
    this.spinner.hide();
  });
  }
}
