import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FileviewVM } from '../ViewModels/FileviewVM';
import { AdminDashboardService } from '../Shared/AdminDashboard/admin-dashboard.service';
import * as $ from 'jquery';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.css']
})
export class FileViewComponent implements OnInit {
  SignFile: string;
  DocumentName: string;
  BlockId: string;
  PdfFile: string="null";
  IsProject: any;
  file: string;
  uid: string;
  gotfile: string;
  pagecount: number;
  pagenum: any;
  isproject: any;
istaskdoc:any
isassigntaskdoc:any
  roleid: string;
  mobileview: boolean;
  downloadfile: any;
  constructor(private spinner:NgxSpinnerService,private router:Router,private admindashboardservice:AdminDashboardService) {
    this.uid = localStorage.getItem("userId");
    this.file = localStorage.getItem("ViewPdffile");
    this.SignFile = localStorage.getItem("Fileview");
    this.DocumentName = localStorage.getItem("FileNameView");
    this.BlockId = localStorage.getItem("BlockId");
    this.roleid = localStorage.getItem("role");
    this.downloadfile= this.SignFile

    this.PdfFile = localStorage.getItem("Pdffile");
    if(this.PdfFile=="undefined"){
      this.PdfFile="null"
    }
    this.IsProject = localStorage.getItem('FileType');
    this.isassigntaskdoc= localStorage.getItem('IsAssignTaskDoc')
    if(this.IsProject=="true"){
      this.isproject=true;
    }
    else if(this.IsProject=="false"){
      this.isproject=false;
    }
    if(this.isassigntaskdoc=='true'){
      this.istaskdoc=true;
    }
    else if(this.isassigntaskdoc=='false'){
      this.istaskdoc=false
    }
    if (this.file != null) {
      this.gotfile = this.file
    }
    setTimeout(() => {        
      var pg = document.getElementsByClassName("page");
      this.pagecount = pg.length;
      window.addEventListener('scroll', this.curpagenum.bind(this));
      // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
    }, 1000);
    this.spinner.show();
  
  this.gotopdfView();
  this.isMobileMenu();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  ngOnInit(): void {
  }
  curpagenum() {
    var pg = document.getElementsByClassName("page");
    var sc = window.pageYOffset;
    for (var i = 0; i < pg.length; i++) {
      var pgc = pg[i].getBoundingClientRect();
      if (sc > pgc.top && sc < pgc.bottom) {
        this.pagenum = i + 1;
        break;
      }
    }
  }
  gotopdfView() {   
    this.gotfile = "";
    const inputRequest: FileviewVM = {
      File: this.SignFile,
      FileName: this.DocumentName,
      BlockId: this.BlockId,
      AliasUserId: this.uid,
      Pdffile: this.PdfFile,
      IsProjectFile:this.isproject,
      IsAssignTaskDocument:this.istaskdoc,
      OnlyProjectDocs:"no"
    }
    console.log(inputRequest);
    this.spinner.show();
    this.admindashboardservice.getdocforpdfview(inputRequest).subscribe((data: any) => {
      console.log("Now",data);
      if (data != null) {
        var dt= data.pdfFileName.split(',');
        this.gotfile = dt[1];
        localStorage.setItem('ViewPdffile', data.pdfFileName);
        localStorage.setItem('Filename', data.file);
        localStorage.setItem('originafile', data.fileName);
        localStorage.setItem('blockid', data.blockId);
        this.spinner.hide();    
        setTimeout(() => {
          var pg = document.getElementsByClassName("page");
          this.pagecount = pg.length;
          window.addEventListener('scroll', this.curpagenum.bind(this));
          // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
        }, 1000);

      }

      else{
        this.spinner.hide(); 
        if(this.IsProject=="true"){
          this.router.navigate(['projectview/']);
        }  
        else if(this.IsProject=="false"){
          this.router.navigate(['taskview/']);
    
        }
        else if(this.IsProject=="ALLDOCSINWARD") {
          this.router.navigate(['inward/']);

        } 
        else if(this.IsProject=="ALLDOCSALLOT"){
          this.router.navigate(['workallotment/']);
    
        } 
        else if(this.IsProject=="ALLDOCSOUT"){
          this.router.navigate(['outward/']);
    
        } 
        else if(this.IsProject=="ALLDOCSFOLDER"){
          this.router.navigate(['allfolders/']);
    
        } 
        else if(this.IsProject=="ALLDOCSFILE"){
          this.router.navigate(['allfolders/']);
    
        } 
      }
    });     
  }
  goBack(){
    if(this.IsProject=="true"){
      this.router.navigate(['projectview/']);
    }  
    else if(this.IsProject=="false"){
      this.router.navigate(['taskview/']);

    }
    else if(this.IsProject=="ALLDOCSINWARD") {
      this.router.navigate(['inward/']);

    } 
    else if(this.IsProject=="ALLDOCSALLOT"){
      this.router.navigate(['workallotment/']);

    } 
    else if(this.IsProject=="ALLDOCSOUT"){
      this.router.navigate(['outward/']);

    } 
    else if(this.IsProject=="ALLDOCSFOLDER"){
      this.router.navigate(['allfolders/']);

    } 
    else if(this.IsProject=="ALLDOCSFILE"){
      this.router.navigate(['allfolders/']);

    } 
  }
  sndpdftoprint() {
    console.log(localStorage);
    var bs64data = this.gotfile;
    var blnk = this.base64ToBlob(bs64data);
    const blobUrl = URL.createObjectURL(blnk);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

base64ToBlob(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: 'application/pdf' });
};

}
