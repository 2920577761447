import { Component, OnInit } from '@angular/core';
import { UserDashboardService } from '../Shared/UserDashboard/user-dashboard.service';
import { request } from '../ViewModels/request';
import { Router } from '@angular/router';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { TaskDetails } from 'src/app/ViewModels/TaskDetails';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  uid: string;
  roleid: string;
  username: string;
  userTaskSummary: any;
  UserProjects: any;
  showProjects: boolean;
  showhidevalue: boolean;
  taskdata: any;
  p: number = 1;
  q: number = 1;
  searchText: any
  currentUsertaskPage: any
  ProjectCompletionPercentage: any;
  userList: any;
  statuid: any;
  userid: any;
  projectstatus: any;
  mobileview: boolean;
  constructor(private userdashboardservice: UserDashboardService, private router: Router,
    private userservice: UserDetailsService,private spinner:NgxSpinnerService) {
      this.isMobileMenu();
      if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserFullName");
    }
  }

  ngOnInit(): void {
    this.GetUserTasksSummary();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };
  Getstatus() {
    this.spinner.show();
    this.userdashboardservice.getstatus().subscribe((respose: any) => {
      this.projectstatus = respose;
      this.spinner.hide();
    });
  }
  GetUserTasksSummary() {
    this.spinner.show();
    this.userdashboardservice.getuserTasksSummary(this.uid).subscribe((respose: any) => {
      this.userTaskSummary = respose;
      this.spinner.hide();

    });
  }
  GetUserDashboardTasks(id) {
    if (id == 1) {
      this.GetUserProjects();
      this.showProjects = true;
      this.showhidevalue = false;

    }
    else if (id == 2) {
      this.GetUDashboardTotalTasks("");
      this.showhidevalue = true;
      this.showProjects = false;
    }
    else if (id == 3) {
      this.GetUDashboardTasks(4);
      this.showhidevalue = true;
      this.showProjects = false;

    }
    else if (id == 4) {
      this.GetUDashboardTasks(2);
      this.showhidevalue = true;
      this.showProjects = false;

    }
    else if (id == 5) {
      this.GetUDashboardTasks(3);
      this.showhidevalue = true;
      this.showProjects = false;

    }
    else if (id == 6) {
      this.GetUDashboardTasks(1);
      this.showhidevalue = true;
      this.showProjects = false;

    }
  }
  onSelectstatus(data) {
    this.statuid = data;
    const inputrequest: request = {
      Status: this.statuid,
      User: this.userid,
      Type: "IND",
    }
    this.spinner.show();
    this.userdashboardservice.getadminDashboard(inputrequest).subscribe((respose: any) => {
      this.taskdata = respose;
      this.spinner.hide();
    });
  }
  onSelecteduserName(data: any) {
    this.userid = data;

    const inputrequest: request = {
      Status: "0",
      User: this.userid,
      Type: "IND",
    }
    this.spinner.show();
    this.userdashboardservice.getadminDashboard(inputrequest).subscribe((respose: any) => {
      this.taskdata = respose;
      this.spinner.hide();

    });
  }
  GetUserProjects() {
    this.spinner.show();
    this.userdashboardservice.getUserProjects(this.uid).subscribe((respose: any) => {
      this.UserProjects = respose;
      this.spinner.hide();

    });
  }
  GetUDashboardTotalTasks(id) {
    const inputrequest: request = {
      Status: null,
      User: this.uid,
      // Type:(this.username== 'ceo' || this.username== 'reddy)? "All" : "IND"
      Type: "IND"
    }
    this.spinner.show();
    this.userdashboardservice.getUserTotalTasks(inputrequest).subscribe((respose: any) => {
      this.taskdata = respose;
      this.spinner.hide();

    });
  }
  GetUDashboardTasks(id) {
    const inputrequest: request = {
      Status: id,
      User: this.uid,
      Type: "IND",
    }
    this.spinner.show();
    this.userdashboardservice.getUserTasks(inputrequest).subscribe((respose: any) => {
      this.taskdata = respose;
      this.spinner.hide();

    });
  }
  ProjPassdata(list,projectid) {
    //this.ProjectCompletionPercentage = percentage;
    this.userdashboardservice.getProjData(projectid)
      .subscribe((respose: any) => {
        localStorage.setItem("ProjectData", respose);
        localStorage.setItem("ProjectList", list);
        localStorage.setItem("ProjectData", JSON.stringify(respose));
        this.router.navigate(['/projectview']);
      });
  }
  TeamMember() {
    this.spinner.show();
    this.userservice.getAllUsers(this.uid).subscribe((Data: any) => {
      this.userList = Data;
      this.spinner.hide();

    })
  }
  TaskPassdata(tasklist,taskid){
     const details : TaskDetails={
          TaskId:taskid,
          UserId:this.uid
    }
    this.spinner.show();
    this.userdashboardservice.getTaskData(details)
    .subscribe((respose: any) => {
      this.spinner.hide();
      localStorage.setItem("TaskData", JSON.stringify(respose));
      localStorage.setItem("TaskList", tasklist);
      this.router.navigate(['/taskview']);
    });
  }
}
