import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoService } from '../Shared/Video/video.service';
import * as $ from 'jquery';

declare var Peer: any;

@Component({
  selector: 'app-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.css']
})
export class JoinMeetingComponent implements OnInit {

  hostid;
  isLoggedIn: boolean;
  uid: string;
  username: string;
  roleid: string;
  mobileview: boolean;
  constructor(private route: ActivatedRoute, private service: VideoService, private router: Router) {

    this.route.params.subscribe((data: any) => {
      this.hostid = data['id'];
    });
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
    }
    this.isMobileMenu()
    window.scrollTo(0, 0);
    if (localStorage.userId != null) {
      this.myid = localStorage.userId
    }
    else {
      this.router.navigate(['/newsignin']);
      return;
    }
  }
  mypeerid;
  peer;
  myid;
  ngOnInit(): void {
    let video;
    var remove = document.getElementById("footerremove");
    if (remove) {
      remove.style.display = "none";
    }
    setTimeout(() => {
      video = this.myvideo.nativeElement;
      console.log(video)
    }, 5000);
    this.peer = new Peer();
    this.peer.on('open', function (id) {
      this.peer.id = id
      console.log('My peer ID is: ' + id);
    });
    setTimeout(() => {
      this.mypeerid = this.peer.id;
      if (this.mypeerid == null || this.mypeerid == undefined) {
        location.reload();
      }
      else {
        this.service.storePeer(this.hostid, this.myid, this.mypeerid).subscribe((data: any) => {
          if (data == -1) {
            alert("This meeting is over");
          }
          else if (data == 0) {
            alert("Either the meeting is over or you are not part of this meeting");
          }
          else {
            this.anotherid = data;
            this.videoconnect();
          }
          console.log(data);
        });
      }
    }, 3000);


    var n = <any>navigator;

    n.getUserMedia = (n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia);

    this.peer.on('call', function (call) {
      n.webkitGetUserMedia({ video: true, audio: true }, function (stream) {
        call.answer(stream);
        call.on('stream', function (remotestream) {
          video.srcObject = remotestream;
          video.play();
        })
      }, function (err) {
        console.log('Failed to get stream other', err);
      })
    })
  }
  @ViewChild('myvideo') myvideo: any;
  @ViewChild('onlymyvideo') onlymyvideo: any;
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  anotherid;
  videoconnect() {
    let video = this.myvideo.nativeElement;
    var localvar = this.peer;
    var fname = this.anotherid;
    let myonlyvideo = this.onlymyvideo.nativeElement;
    // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

    var n = <any>navigator;

    n.getUserMedia = (n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia);

    n.getUserMedia({ video: true, audio: true }, function (stream) {
      myonlyvideo.srcObject = stream;
      myonlyvideo.play();
      myonlyvideo.muted = true;
      var call = localvar.call(fname, stream);
      call.on('stream', function (remotestream) {
        video.srcObject = remotestream;
        video.play();
      })
    }, function (err) {
      console.log('Failed to get stream', err);
    })


  }


}
