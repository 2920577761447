import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OutwardService } from 'src/app/Shared/Outward/outward.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-work-flow-history',
  templateUrl: './work-flow-history.component.html',
  styleUrls: ['./work-flow-history.component.css']
})
export class WorkFlowHistoryComponent implements OnInit {
  HistoryofProject: any;
  historyid: string;
  uid: string;
  roleid: string;
  mobileview: boolean;

  constructor(private spinner:NgxSpinnerService,private outwardservice:OutwardService,
    private router:Router) { 
  this.historyid=  localStorage.getItem("ProjectHistoryId");
  if (localStorage.getItem("IsLoggedIn") == "true") {
    this.uid = localStorage.getItem("userId");
    this.roleid = localStorage.getItem("role");

     }
     this.isMobileMenu()

  this.History(this.historyid);
}

  ngOnInit(): void {
  }
  History(history){
    var piduid = history + "," + this.uid
    this.spinner.show();
    this.outwardservice.GetHistoryofProject(piduid).subscribe((Data: any) => {
      this.HistoryofProject = Data;
      this.spinner.hide();
    });
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  backtoprojects(){
    this.router.navigate(['myprojects/']);
  }
}
