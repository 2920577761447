


<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<app-user-sidebar *ngIf="(roleid=='2' || roleid=='9')&& mobileview"></app-user-sidebar>
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-3">
        <img src="/assets/img/back.png" height="40" width="40" style="cursor: pointer;" (click)="backtoprojects()"/>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div id="content" class="content content-full-width">
                <div class="profile">
                    <div class="profile-header">
                        <div class="profile-header-cover"></div>
                    </div>
                </div>

                <div class="profile-content">
                    <div class="tab-content p-0">
                        <div class="tab-pane fade active show" id="profile-post">
                            <ul class="timeline" *ngFor="let history of HistoryofProject">
                                <li>
                                    <div class="timeline-time">
                                        <span class="date">{{history.wtD_TransDate|date:'shortDate'}}</span>
                                        <span class="time">{{history.wtD_TransDate|date:'shortTime'}}</span>
                                    </div>

                                    <div class="timeline-icon">
                                        <a href="javascript:;">&nbsp;</a>
                                    </div>

                                    <div class="timeline-body">
                                        <div class="timeline-header">
                                            <span class="userimage"><img src="/assets/img/icon.png" /></span>

                                            <span class="username">{{history.from}}</span><br/>
                                            <b style="color: purple;margin-left: 45px">{{history.role}}</b>
                                        </div>

                                        <!-- <div class="input-group">
                                            <button class="btn btn-primary f-s-12 rounded-corner" type="button"
                                                (click)="myFunction()">
                                                View Details
                                            </button>
                                        </div> -->
                                    </div>
                                </li>
                         
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br /><br />
