<app-nav-bar></app-nav-bar>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">
  <div class="col-md-4"></div>
  <div class="col-md-4"><h1><b>{{username+"/"+aa}}</b></h1>
  </div>
  </div>
    <div class="col-md-12 row">&nbsp;</div>



  
  <form [formGroup]="userForm" (ngSubmit)="onFormSubmit(userForm)">
    <div class="col-md-12 row">
        <div class="col-md-4 row">&nbsp;</div>
        <div class="col-md-8 row" style="font-size: 24px;">
            <div class="col-md-3 row">
    
       <input type="radio" id="active" name="drone" value="true"
                checked (change)="onItemChange($event.target.value)">&nbsp;&nbsp;Active
    </div>
    <div class="col-md-3 row">
        <!-- <input class="form-check-input" type="radio" value="2" [(ngModel)]="deactive" name="deactive"   [checked]="true" formControlName="deactive" (change)="onItemChange($event.target.value)" />&nbsp;&nbsp;Deactivate -->
        <input type="radio" id="deactive" name="drone" value="false" (change)="onItemChange($event.target.value)" >&nbsp;&nbsp;Deactivate
    </div>
    <div class="col-md-1 row">&nbsp;</div>
    <div class="col-md-2 row"  *ngIf= "submit" >
        <button type="button" class="btn btn-success" (click)="onFormSubmit(userForm)" style="   
        background-color: purple;
        color: white;" >Submit</button>
    </div>
 
</div>
</div>
  </form> 

<div class="row clientdetmedia">
    <div class="col-md-2"></div>
<div class="main">
   
    <ul class="cards">
      <li class="cards_item">
        <div class="card">
            <div class="card_image">
                <img src="assets/img/session.jpg" style="height: 206px;">
            </div>
          <div class="card_content">
            <h2 class="card_title">Session Details</h2>
            <button class="btn card_btn" href="#allclientvalue" (click)="GetClientData()">Continue</button>
          </div>
        </div>
      </li>
     
      <li class="cards_item">
        <div class="card">
          <div class="card_image"><img src="assets/img/clienttrans.jpeg"></div>
          <div class="card_content">
            <h2 class="card_title">All Transaction Details</h2>
            <button class="btn card_btn" href="#alluservalue" (click)="GetAllAuditorDetails()">Continue</button>
          </div>
        </div>
      </li>
    </ul>
  </div>

</div>
<br>
<br>
<br>
<div class="col-md-12 row" >
    <div class="col-md-12 row"  id="allclientvalue" *ngIf="allclientvalue">
        <div class="col-md-2"></div>
        <div class="col-md-10">
            <div>
            
            
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse;">
                        <!--...... <table class="table table-responsive" style="border-collapse: collapse;display: table;">        ......      -->
                        <tr class="thead-dark" style="font-size: 17px">
                            <th>Sl No</th>
                            <th>UserId</th>
                            <th>User Name</th>
                            <th>Login Time</th>
                            <th>Logout Time</th>
                            <!-- <th>Modified On</th>                    -->
                        </tr>
               
                        <tr *ngFor="   let aa of logindetails    | paginate: { itemsPerPage: 5, currentPage: p }; let i = index  " style=" font-size: 16px;
                color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.userId }}</td>
                            <td>{{ aa.userName }}</td>
                            <!-- <td>{{ aa.userRole }}</td> -->                   
                            <td>{{ aa.loginTime | date: "short" }}</td>                
                            <td>{{ aa.logoutTime | date: "short" }}</td>
                       
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
    </div>

    <div class="col-md-12 row" id="alluservalue" *ngIf="alluservalue">
       
      <!-- <div class="col-md-2"></div> -->
      <div class="col-md-1"></div>
          <div class="col-md-11 row clienttransmedia">
       
          
              <table class="table table-responsive" style="border-collapse: collapse;">
                  <tr class="thead-dark" style="font-size: 17px">
                      <th>Id</th>
                      <th>CustomerId</th>
                      <th>EnterPriseId</th>
                      <th>GuId</th>
                      <th>UserName</th>
                      <th>MailId</th>
                      <th>BrowserName</th>
                      <th>IPAddress</th>
                      <th>TransactionName</th>
                      <th>TransactionType</th>
                      <th>TransactionDesc</th>
                      <th>TransactionDate</th>
                      <th>View</th>
                  </tr>

                  <tr *ngFor="   let aa of auditordetails    | paginate: { itemsPerPage: 5, currentPage: p }; let i = index  "
                      style=" font-size: 16px;
          color: rgb(0, 0, 0); font-family: DINNextRoundedLTPro;">
                      <td>{{ 5 * (p - 1) + (i + 1) }}</td>

                      <td>{{ aa.customerId }}</td>
                      <td>{{ aa.enterpriseId }}</td>
                      <td>{{ aa.guId }}</td>
                      <td>{{ aa.userName }}</td>
                      <td>{{ aa.mailId }}</td>
                      <td>{{ aa.browserName }}</td>
                      <td>{{ aa.ipAddress }}</td>
                      <td>{{ aa.transactionName }}</td>
                      <td>{{ aa.transactionType }}</td>
                      <td>{{ aa.transactionDesc }}</td>
                      <td>{{ aa.transactionDate }}</td>
                      <td>
                          
<div  *ngIf="aa.transactionDesc == 'File' && aa.transactionType == 'Upload'" >
  <!-- ----------------filesview-------------- -->
  <button class="btn btn-primary"  *ngIf="aa.documentType == 'pdf'"
  (click)="ViewDocument(aa, pdftesting)"
  style="background-color: purple; color: white;" >View</button>
  <button class="btn btn-primary"  *ngIf="aa.documentType == 'txt'"
  (click)="ViewDocumenttxt(aa, txttesting)"
  style="background-color: purple; color: white;" >View</button>
  <button class="btn btn-primary"  
  *ngIf="aa.documentType == 'jpeg' || aa.documentType == 'png' || aa.documentType == 'jpg'" 
  (click)="ViewImageDoc(aa, content)" style="   
  background-color: purple;
  color: white;" >View</button>
  <button class="btn btn-primary"  *ngIf="aa.documentType == 'ppt' || aa.documentType == 'pptx'"
  (click)="ViewPpt(aa, ppttesting)"
  style="background-color: purple; color: white;" >View</button>
  <button class="btn btn-primary"  *ngIf="aa.documentType == 'doc' || aa.documentType == 'docx' "
  (click)="ViewDocx(aa, doctest)" 
  style="background-color: purple; color: white;">View</button>
  <button class="btn btn-primary"  *ngIf="aa.documentType == 'xlsx' || aa.documentType == 'xls'"
  style="background-color: purple; color: white;"
  (click)="ViewExcel(aa, excelview)">View</button>
</div>
  <button class="btn btn-primary"  *ngIf="aa.transactionDesc == 'Compose Mail'"
  style="background-color: purple; color: white;" (click)="ViewMail(aa,mailview)">View</button>
                      </td>

                  </tr>
              </table>
              <br />
              <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
              <br /><br />
              <br /><br />
     


<!-- -----------------------pdfview--------------------------- -->
              <ng-template #pdftesting let-d="dismiss" let-c="dismiss">
                  <div class="modal-header" style="background-color: white;">
                      <div>&nbsp;</div>
                      <h6 style="text-align: center;">{{ zz.documentName }} <br /></h6>
                      <!-- <h6 style="float: left;">
                          &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zz.date | date: "short" }}
                      </h6> -->
              
                      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
              
                  <div class="modal-body" style="background-color: white;">
                      <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                      <br /><br />
                            
                      <div class="col-lg-12 row">
                          <div class="col-lg-5"></div>
                          <!-- <div class="col-lg-6">
                              <a [href]="pdfUrl"><button class="btn btn-info">
                                      <i class="fa fa-download"></i>Download
                                  </button>
                              </a>
                          </div> -->
                      </div>
                  </div>
              </ng-template>


<!-- -----------------------pptview--------------------------- -->

<ng-template #ppttesting let-d="dismiss" let-c="dismiss">
  <div class="modal-header" style="background-color: white;">
      <div>&nbsp;</div>
      <h6 style="text-align: center;">{{ ppt }} <br /></h6>
      <!-- <h6 style="float: left;">
          &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ ppt.date | date: "short" }}
      </h6> -->

      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body" style="background-color: white;">
      <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
      <br /><br />
            
      <div class="col-lg-12 row">
          <div class="col-lg-5"></div>
          <!-- <div class="col-lg-6">
              <a [href]="pdfUrl"><button class="btn btn-info">
                      <i class="fa fa-download"></i>Download
                  </button>
              </a>
          </div> -->
      </div>
  </div>
</ng-template>

<!-- -------------------------textview--------------- -->
              <ng-template #txttesting let-d="dismiss" let-c="dismiss">
                <div class="modal-header" style="background-color: white;">
                    <div>&nbsp;</div>
                    <h6 style="text-align: center;">{{ zzzz.documentName }} <br /></h6>
                    <!-- <h6 style="float: left;">
                        &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ zzzz.date | date: "short" }}
                    </h6> -->
            
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            
                <div class="modal-body" style="background-color: white;">
                    <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
                    <br /><br />
                          
                    <div class="col-lg-12 row">
                        <div class="col-lg-5"></div>
                        <!-- <div class="col-lg-6">
                            <a [href]="pdfUrl"><button class="btn btn-info">
                                    <i class="fa fa-download"></i>Download
                                </button>
                            </a>
                        </div> -->
                    </div>
                </div>
            </ng-template>
       
<!-- -----------------imageview------------------ -->

              <ng-template #content let-d="dismiss" let-c="dismiss">
                <div class="modal-header">
                    <div>&nbsp;</div>
                    <h6 style="text-align: center; cursor: pointer;">{{ imgdisplay.documentName }}</h6>
                    <!-- <h6 style="float: left;">
                        Date &nbsp;: &nbsp;{{ imgdisplay.date | date: "short" }}
                    </h6> -->
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img src="{{ imgdisplay.file }}" class="img-responsive" alt="" style="height: 300px; width: 400px;" />
            
                    <br /><br />
                    <div class="col-lg-12 row">
                        <div class="col-lg-5"></div>
                        <div class="col-lg-6">
                            <a [href]="imgdisplay.file" style="cursor: pointer;"><button class="btn btn-info">
                                    <i class="fa fa-download"></i>Download
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>


<!-- ---------------------docview--------------------- -->
<ng-template #doctest let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ doc.documentName }} <br /></h6>
        <!-- <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ doc.date | date: "short" }}
        </h6> -->

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />
              
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <!-- <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div> -->
        </div>
    </div>
</ng-template>


<!-- ---------------------excelview--------------------- -->
<ng-template #excelview let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ excel.documentName }} <br /></h6>
        <!-- <h6 style="float: left;">
            &nbsp; &nbsp; Date &nbsp;: &nbsp;{{ excel.date | date: "short" }}
        </h6> -->

        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">
        <ngx-doc-viewer [url]="pdfUrl" viewer="google" style="width: 100%; height: 70vh;"></ngx-doc-viewer>
        <br /><br />
              
        <div class="col-lg-12 row">
            <div class="col-lg-5"></div>
            <!-- <div class="col-lg-6">
                <a [href]="pdfUrl"><button class="btn btn-info">
                        <i class="fa fa-download"></i>Download
                    </button>
                </a>
            </div> -->
        </div>
    </div>
</ng-template>

<!-- -----------------------mailview--------------------------- -->

<ng-template #mailview let-d="dismiss" let-c="dismiss">
    <div class="modal-header" style="background-color: white;">
        <div>&nbsp;</div>
        <h6 style="text-align: center;">{{ mail }}<br /></h6>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="background-color: white;">

        <h6 >Subject: <br />{{ mailsub }} <br /></h6>
        <br>
        <h6 >Message:  <br />{{ mailmsg }} <br /></h6>
        <br>
        <div class="row" *ngIf = "showdoc">
         <h6 >Document Name:  <br />{{ maildocname }} <br /></h6> 
      </div>
      <br>
      <h6 >To User:  <br />{{   touser }} <br /></h6> 
    </div>
</ng-template>

          </div>
      </div>







