<app-nav-bar></app-nav-bar>
<br /><br /><br /><br /><br /><br /><br />
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-8">
        <h1>Privacy Policy for Srichid Blockchain Services Private Limited</h1>

        <p style="text-align: justify;">
            At blocdrive, accessible from
            <a [routerLink]="['']">https://blocdrive.in</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by blocdrive and how we use it.
        </p>

        <p style="text-align: justify;">
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
        </p>

        <p style="text-align: justify;">
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in blocdrive. This policy is not applicable to any information collected offline or via
            channels other than this website.
        </p>

        <h2>Consent</h2>

        <p style="text-align: justify;">
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>

        <h2>Information we collect</h2>

        <p style="text-align: justify;">
            When you register for an Account, we may ask for your contact information, including items such as name, and email address.
        </p>

        <h2>How we use your information</h2>

        <p style="text-align: justify;">
            We use the information we collect in various ways, including to:
        </p>

        <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
                Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
        </ul>

        <h2>Cookies and Web Beacons</h2>

        <p style="text-align: justify;">
            blocdrive uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web
            page content based on visitors' browser type and/or other information.
        </p>

        <h2>GDPR Data Protection Rights</h2>

        <p style="text-align: justify;">
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        </p>
        <p style="text-align: justify;">
            The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
        </p>
        <p style="text-align: justify;">
            The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
        </p>
        <p style="text-align: justify;">
            The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
        </p>
        <p style="text-align: justify;">
            The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
        </p>
        <p style="text-align: justify;">
            The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
        </p>
        <p style="text-align: justify;">
            The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
        </p>
        <p style="text-align: justify;">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
        </p>

        <h2>Children's Information</h2>

        <p style="text-align: justify;">
            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
        </p>

        <p style="text-align: justify;">
            blocdrive does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will
            do our best efforts to promptly remove such information from our records.
        </p>
    </div>
</div>
<br /><br /><br />