<app-nav-bar></app-nav-bar>
<br>

<div class="col-md-12 row">
    <div class="col-md-1">
        <!-- <app-composemail></app-composemail> -->
    </div>
    <div class="col-md-10">

        <aside class="lg-side">
            <div class="inbox-head">
                <div class="col-md-8">
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search Here " />
                    <br />
                </div>

            </div>
            <div class="inbox-body">
                <table class="table table-hover">
                    <tbody style="background: #ecf0f161">
                            <tr  style="font-size: 17px;background-color: #6495ED;">
                              
                                <th> Name</th>
                                <th>Subject</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        <tr class="unread" id="{{ ret.Id }}" style="cursor: pointer" *ngFor="
                    let ret of InboxList
                      | filter: searchText
                      | paginate: { itemsPerPage: 10, currentPage: p };
                    let i = index
                  ">
                            <td class="view-message dont-show" (click)="ReadMessage(ret)">
                                {{ ret.fromName }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.subject }}
                            </td>

                            <td class="view-message" (click)="ReadMessage(ret)">
                                {{ ret.date }}
                            </td>
                            <td class="view-message" >
                                {{ ret.mailstatus }}
                            </td>
                            <!-- <td *ngIf = "activestate" >
                                <p style="color: #21b621; font-size: 18px;">Active</p>
                                                    </td>
                                                    <td *ngIf = "deactivestate">
                                                        <p style="color:red; font-size: 18px;">Deactivate</p>
                                                                            </td> -->
                            <td (click)="Deactiveusers(ret)">
                                <button type="button" class="btn btn-success"  style="   
                                background-color: purple;
                                color: white; height:52px; width:110px;" > {{ ret.color }}</button>
                            </td>
                        

                        </tr>
                    </tbody>
                </table>

                <br /><br />
                <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                <br /><br />
                <br /><br />
            </div>
        </aside>
    </div>
</div>
