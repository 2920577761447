import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { ClientService } from '../Shared/Client/client.service';
import { ProjectService } from '../Shared/Project/project.service';
import { ProjectVM } from '../ViewModels/ProjectVM';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Priority } from '../ViewModels/Priority';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
  editField: string;
  UserList: any = [];
  ProjectList: any;
  ClientList: any = [];
  closeResult: string;
  editprojectname: any;
  editprojectdesc: any;
  editpurpose: any;
  editstartdate: any;
  editobjectives: any;
  editestimatedtime: any;
  editestimatedcost: any;
  createdon: any;
  DeleteName: any;
  deleteid: any;
  editpriority: any;
  edituserid: any;
  editclentid: any;
  editprojectcode: any;
  dismissalert: boolean;
  projectname: any;
  projectdesc: any;
  purpose: any;
  selecteduser: any;
  selectedclient: any;
  priority: any;
  projectcode: any;
  startdate: any;
  objectives: any;
  estimatedtime: any;
  estimatedcost: any;
  notify: string
  editid: any;
  p: number = 1;
  DateTime: Date;
  converttostring: string;
  converteddate: string;
  selectedpriority: any;
  prioritylist: any=[];
  selectedpriorityname: string;
  uid: string;
  username: string;
  roleid: string;
  GUID: string;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    , private spinner: NgxSpinnerService, private userservice: UserDetailsService,
    private clientService: ClientService, private projectservice: ProjectService, private datepipe: DatePipe,
    private datePipe: DatePipe) {
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.uid = localStorage.getItem("userId");
        this.username = localStorage.getItem("UserFullName");
        this.roleid = localStorage.getItem("role");
        this.GUID = localStorage.getItem("GUID");

      }
     }

  ngOnInit(): void {
    this.Projects();
    this.Users();
    this.Clients();
    var prioritymajor: Priority = {
      PriorityId: 1,
      PriorityName: "Major"
    }
    this.prioritylist.push(prioritymajor);
    var prioritycritical: Priority = {
      PriorityId: 2,
      PriorityName: "Critical"
    }
    this.prioritylist.push(prioritycritical);
     var priorityminor: Priority = {
      PriorityId: 3,
      PriorityName: "Minor"
    }
    this.prioritylist.push(priorityminor);
  }
  Users() {
    this.spinner.show();
    this.userservice.getAllUsers(this.uid).subscribe((Data: any) => {
      this.UserList = Data;
      this.spinner.hide();
    });
  }
  Projects() {
    this.spinner.show();
    this.projectservice.getAllProjects(this.username).subscribe((Data: any) => {
      this.ProjectList = Data;
      this.spinner.hide();

    })
  }
  Clients() {
    // this.spinner.show();
    this.clientService.getAllClients(this.uid).subscribe((Data: any) => {
      this.ClientList = Data;
      this.spinner.hide();
    });
  }
  addProject(addproject) {
    this.modalService.open(addproject).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  EditProject(updatedata, updateproject) {
    this.editid = updatedata.id
    this.editprojectname = updatedata.projectName
    this.editprojectdesc = updatedata.projectDescription
    this.editpurpose = updatedata.purposeOfProject
    this.editpriority = updatedata.priority
    this.edituserid = updatedata.projectLead
    this.editprojectcode = updatedata.projectCode
    this.editstartdate = updatedata.startDate
    this.editobjectives = updatedata.objectives
    this.editestimatedtime = updatedata.estimstedTime
    this.converttostring = this.datePipe.transform(this.editstartdate, 'yyyy-MM-dd');
    this.converteddate = this.converttostring.toString();
    this.editestimatedcost = updatedata.estimatedCost
    this.editclentid = updatedata.client
    this.createdon = updatedata.createdOn;
    this.modalService.open(updateproject).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }
  deleteProject(deletedata, deleteproject) {
    this.DeleteName = deletedata.projectName;
    this.deleteid = deletedata.id;
    this.modalService.open(deleteproject).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSelectUser(id) {
    this.selecteduser = id;
  }
  onSelectClient(id) {
    this.selectedclient = id;
  }
  onSelectPriority(id) {
    this.selectedpriority = id;
  }
  SaveProject() {
       this.DateTime = new Date();
    let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
    const inputRequest: ProjectVM = {
      ID: 0,
      ProjectName: this.projectname,
      ProjectDescription: this.projectdesc,
      PurposeOfProject: this.purpose,
      Priority: this.selectedpriority,
      ProjectCode: this.projectcode,
      ProjectLead: this.selecteduser,
      StartDate: this.startdate,
      Objectives: this.objectives,
      EstimstedTime: this.estimatedtime,
      EstimatedCost: this.estimatedcost,
      Client: this.selectedclient,
      CreatedOn: latest_date,
      GUID:this.GUID

    }
    this.spinner.show();
    this.projectservice.SaveProject(inputRequest).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Project added successfully"
          const audit = {
            TransactionName:"Project Management",
            TransactionType:"Create",
            TransactionDesc:"Project",
  
            }
            this.auditortransactionservice.AuditorTransactionDetails(audit);
          this.selectedclient=null
          this.selecteduser=null
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.Projects();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          this.selectedclient=null
          this.selecteduser=null
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.spinner.hide();
          this.Projects();
        }
      });
  }

  UpdateProject() {
    if (this.selectedclient == null || this.selectedclient == undefined) {
      this.selectedclient = this.editclentid;
    }
    if (this.selecteduser == null || this.selecteduser == undefined) {
      this.selecteduser = this.edituserid;
    }
    const inputRequest1: ProjectVM = {
      ID: this.editid,
      ProjectName: this.editprojectname,
      ProjectDescription: this.editprojectdesc,
      PurposeOfProject: this.editpurpose,
      Priority: this.editpriority,
      ProjectCode: this.editprojectcode,
      ProjectLead: this.selecteduser,
      StartDate: this.editstartdate,
      Objectives: this.editobjectives,
      EstimstedTime: this.editestimatedtime,
      EstimatedCost: this.editestimatedcost,
      Client: this.selectedclient,
      CreatedOn: this.createdon,
      GUID:this.GUID
    }
    this.spinner.show();
    this.projectservice.UpdateProject(inputRequest1).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Project updated successfully"
          this.selectedclient=null
          this.selecteduser=null
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          this.selectedclient=null
          this.selecteduser=null
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
        }
      });
  }
  DeleteProjectRow() {
    this.spinner.show();
    this.projectservice.deleteProject(this.deleteid).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Project deleted successfully"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.Projects();
          this.spinner.hide();
        }
      });
  }
  closealert() {
    this.dismissalert = false;
  }
  GetUser(userid) {
    for (let i = 0; i < this.UserList.length; i++) {
      if (userid == this.UserList[i].userId) {
        return this.UserList[i].userName;
      }
    }
  }
  GetClient(cid) {
    for (let i = 0; i < this.ClientList.length; i++) {
      if (cid == this.ClientList[i].id) {
        return this.ClientList[i].clientName;
      }
    }
  }

}
