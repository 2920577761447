import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectVM } from 'src/app/ViewModels/ProjectVM';
import { DownloadFileVM } from 'src/app/ViewModels/DownloadFileVM';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  readonly url="https://blocdrive.in/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  SaveProject(data:ProjectVM){
    return this.http.post(this.url +"AddProject/PostSaveProject",data);
  }

  getAllProjects(id){
    return this.http.get(this.url +"AddTask/GettblProjects/" + id);
  }

  deleteProject(id: any){
    return this.http.get(this.url +"AddProject/GetDeleteProjectData/"+ id);
  }

  UpdateProject(data:ProjectVM){
    return this.http.post(this.url +"AddProject/PutProject",data);
  }
  getParticularAllProjects(id){
    return this.http.get(this.url +"AddProject/GetAllParticularProjects/"+ id);
  }
  getProjectFiles(id){
    return this.http.get(this.url +"AddProject/GetProjectFiles/"+ id);
  }
  downloadFile(data:DownloadFileVM){
    return this.http.post(this.url +"Documents/PostDownloadNewProjectFiles",data);

  }
}
