import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.global';
import { AnalyticsService } from 'src/app/Shared/Analytics/analytics.service';
import { LoginService } from 'src/app/Shared/Login/login.service';

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.css']
})
export class DataViewComponent implements OnInit {
  UserId: any;
  EmailId: any;
  Role: any;
  Username: any;
  dbname: any;
  fimename: any;
  Data: any;
  Columns: any;
  tablevalues:any;
Columns1: any;
Data1: any;
tablevalues1: any;
columnlist: any= [];
tblvaluelist: any =[];
rowname:any;
Columns3: any;
Data3: any;
Data2: any;
Columns2: any;
  constructor(private loinservice: LoginService,private httpService: HttpClient, private router: Router, 
    private analyticsService: AnalyticsService,private _urls: AppGlobals) {
    if (localStorage.getItem("IsLoggedIn") == 'true') {
      this.UserId = localStorage.getItem("userId");
      this.EmailId = localStorage.getItem("Email");
      this.Role = localStorage.getItem("role");
      this.Username = localStorage.getItem("UserName");
      this.dbname= localStorage.getItem("projname");
      this.fimename= localStorage.getItem("filename");
    }

  }

  ngOnInit(): void {
    this.viewdata();
  }
  viewdata(){
    this.analyticsService.getColumnName(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Columns = res;
      console.log(this.Columns);
      }
    )
    this.analyticsService.getValue(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Data = res;
        this.tablevalues = Object.keys(this.Data).map(key => ({ type: key, value: this.Data[key] }));
    
        // this.columnlist = this.Columns; 
        //  for(var i=0; i <= this.Data.length; i++){
          
        //    for(var j=0; j <= this.columnlist.length; j++ ){
            
        //     if(this.Data[i].type.includes(this.columnlist[j])){
        //       this.rowname = true;  
        //     }
        //    }
           
        //  }

        
        console.log(this.Data);
       console.log(this.tablevalues);
      }
    )
    this.analyticsService.getColumnName1(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Columns1 = res;
       // console.log(this.Columns1);
      }
    )
    this.analyticsService.getValue1(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Data1 = res;
        this.tablevalues1 = Object.keys(this.Data1).map(key => ({ type: key, value: this.Data1[key] }));
       // console.log(this.Data1);
       // console.log(this.tablevalues1);
      }
    )

    this.analyticsService.getColumnName2(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Columns2 = res;
       // console.log(this.Columns1);
      }
    )
    this.analyticsService.getValue2(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Data2 = res;
        this.tablevalues1 = Object.keys(this.Data1).map(key => ({ type: key, value: this.Data1[key] }));
       // console.log(this.Data1);
       // console.log(this.tablevalues1);
      }
    )

    this.analyticsService.getColumnName3(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Columns3 = res;
       // console.log(this.Columns1);
      }
    )
    this.analyticsService.getValue3(this.fimename+","+this.dbname).subscribe(
      (res: any) => {
        this.Data3 = res;
        this.tablevalues1 = Object.keys(this.Data1).map(key => ({ type: key, value: this.Data1[key] }));
       // console.log(this.Data1);
       // console.log(this.tablevalues1);
      }
    )


  }
}