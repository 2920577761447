import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-aplication',
  templateUrl: './leave-aplication.component.html',
  styleUrls: ['./leave-aplication.component.css']
})
export class LeaveAplicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
