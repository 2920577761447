import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileviewVM } from '../ViewModels/FileviewVM';
import { AdminDashboardService } from '../Shared/AdminDashboard/admin-dashboard.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-project-document-view',
  templateUrl: './project-document-view.component.html',
  styleUrls: ['./project-document-view.component.css']
})
export class ProjectDocumentViewComponent implements OnInit {
  uid: string;
  file: string;
  SignFile: string;
  DocumentName: string;
  BlockId: string;
  PdfFile: string;
  gotfile: string;
  pagecount: number;
  pagenum: any;
  isproject: any;
  istaskdoc: any;
  roleid: string;
  mobileview: boolean;

  constructor(private router:Router,private spinner:NgxSpinnerService,private admindashboardservice:AdminDashboardService) { 
    this.uid = localStorage.getItem("userId");
    this.file = localStorage.getItem("ViewPdffile");
    this.SignFile = localStorage.getItem("Fileview");
    this.DocumentName = localStorage.getItem("FileNameView");
    this.BlockId = localStorage.getItem("BlockId");
    this.PdfFile = localStorage.getItem("Pdffile");
    this.roleid = localStorage.getItem("role");
    this.isMobileMenu();
    if(this.PdfFile=="undefined"){
      this.PdfFile="null"
    }
    if (this.file != null) {
      this.gotfile = this.file
    }
    setTimeout(() => {        
      var pg = document.getElementsByClassName("page");
      this.pagecount = pg.length;
      window.addEventListener('scroll', this.curpagenum.bind(this));
      // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
    }, 1000);
    this.spinner.show();
  
  this.gotopdfView();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  ngOnInit(): void {
  }
  curpagenum() {
    var pg = document.getElementsByClassName("page");
    var sc = window.pageYOffset;
    for (var i = 0; i < pg.length; i++) {
      var pgc = pg[i].getBoundingClientRect();
      if (sc > pgc.top && sc < pgc.bottom) {
        this.pagenum = i + 1;
        break;
      }
    }
  }
  gotopdfView() {
    this.gotfile = "";
    const inputRequest: FileviewVM = {
      File: this.SignFile,
      FileName: this.DocumentName,
      BlockId: this.BlockId,
      AliasUserId: this.uid,
      Pdffile: this.PdfFile,
      IsProjectFile: this.isproject,
      IsAssignTaskDocument: this.istaskdoc,
      OnlyProjectDocs:"yes"
    }
    this.spinner.show();
    this.admindashboardservice.getdocforpdfview(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.gotfile = data.pdfFileName;
        localStorage.setItem('ViewPdffile', data.pdfFileName);
        localStorage.setItem('Filename', data.file);
        localStorage.setItem('originafile', data.fileName);
        localStorage.setItem('blockid', data.blockId);
        this.spinner.hide();
        setTimeout(() => {
          var pg = document.getElementsByClassName("page");
          this.pagecount = pg.length;
          window.addEventListener('scroll', this.curpagenum.bind(this));
          // document.getElementById('showScroll').innerHTML = window.pageYOffset + 'px';
        }, 1000);
      }

      else {
        this.spinner.hide();
          this.router.navigate(['projectregistration/']);
       
      }
    });
  }
  goBack() {
    this.router.navigate(['projectregistration/']);

  }
}
