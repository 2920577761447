import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { AddprojectComponent } from './addproject/addproject.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { CountryComponent } from './Masters/country/country.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DesignationComponent } from './Masters/designation/designation.component';
import { DepartmentComponent } from './Masters/department/department.component';
import { RoleComponent } from './Masters/role/role.component';
import { MenuMasterComponent } from './Masters/menu-master/menu-master.component';
import { DatePipe, CommonModule } from '@angular/common';
import { ERegistrationComponent } from './eregistration/eregistration.component';
import { AlertsModule } from 'angular-alert-module';
import { ClientComponent } from './Masters/client/client.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { UserComponent } from './Masters/user/user.component';
import { LoginComponent } from './login/login.component';
import { ProjectComponent } from './project/project.component';
import { AngularTiltModule } from 'angular-tilt';
import { HomeComponent } from './home/home.component';
import { ProjectModuleComponent } from './Masters/project-module/project-module.component';
import { ProjectChangeComponent } from './Masters/project-change/project-change.component';
import { AssignTaskComponent } from './assign-task/assign-task.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { TaskViewComponent } from './task-view/task-view.component';
import { FileViewComponent } from './file-view/file-view.component';
import { ClientRegistrationComponent } from './Workflow/client-registration/client-registration.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProjectRegistrationComponent } from './Workflow/project-registration/project-registration.component';
import { ProjectDocumentViewComponent } from './project-document-view/project-document-view.component';
import { InwardComponent } from './Workflow/inward/inward.component';
import { WorkFlowTrackingComponent } from './Workflow/work-flow-tracking/work-flow-tracking.component';
import { WorkAllotmentComponent } from './Workflow/work-allotment/work-allotment.component';
import { TagInputModule } from 'ngx-chips';
import { InboxComponent } from './Workflow/Mail/inbox/inbox.component';
import { SentItemsComponent } from './Workflow/Mail/sent-items/sent-items.component';
import { DeletedItemsComponent } from './Workflow/Mail/deleted-items/deleted-items.component';
import { OutwardComponent } from './Workflow/outward/outward.component';
import { GeneralTransactionFormComponent } from './Workflow/general-transaction-form/general-transaction-form.component';
import { FilePageforMailBoxComponent } from './Workflow/file-pagefor-mail-box/file-pagefor-mail-box.component';
import { FolderCreationComponent } from './Workflow/folder-creation/folder-creation.component';
import { LeaveAplicationComponent } from './Workflow/leave-aplication/leave-aplication.component';
import { TaskComponent } from './Workflow/task/task.component';
import { SidebarComponent } from './Workflow/sidebar/sidebar.component';
import { OutwardedProjectsComponent } from './Workflow/outwarded-projects/outwarded-projects.component';
import { WorkFlowHistoryComponent } from './Workflow/work-flow-history/work-flow-history.component';
import { RoleBasedDashboardComponent } from './role-based-dashboard/role-based-dashboard.component';
import { AuditReportComponent } from './audit-report/audit-report.component';
import { UserSidebarComponent } from './user-sidebar/user-sidebar.component';
import { HomeSidebarComponent } from './home-sidebar/home-sidebar.component';
import { BonlineDashboardComponent } from './bonline-dashboard/bonline-dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { ChatComponent } from './BSafe/chat/chat.component';
import { FileRequestsComponent } from './BSafe/newComponents/file-requests/file-requests.component';
import { GoogleLoginProvider, FacebookLoginProvider, AuthServiceConfig, AuthService } from "angularx-social-login";
import { AllfoldersComponent } from './BSafe/USRM/AllFolders/allfolders/allfolders.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AllfilesComponent } from './BSafe/USRM/AllFiles/allfiles.component';
import { ModalServiceService } from './Shared/Modalpopup/modal-service.service';
import { MessageService } from './Shared/MessageService';
import { AcceptfriendComponent } from './BSafe/newComponents/acceptfriend/acceptfriend.component';
import { SharefileComponent } from './BSafe/newComponents/sharefile/sharefile.component';
import { UploadfileforrequestComponent } from './BSafe/newComponents/uploadfileforrequest/uploadfileforrequest.component';
import { LogintouploadfilesComponent } from './BSafe/newComponents/logintouploadfiles/logintouploadfiles.component';
import { DeletedfilesComponent } from './BSafe/deletedfiles/deletedfiles.component';
import { CompanyComponent } from './Masters/company/company.component';
import { HostEventComponent } from './host-event/host-event.component';
import { HostJoinComponent } from './host-join/host-join.component';
import { JoinMeetingComponent } from './join-meeting/join-meeting.component';
import { PDFViewerComponent } from './Workflow/pdfviewer/pdfviewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IndregisterComponent } from './indregister/indregister.component';
import { PlansComponent } from './payu/plans/plans.component';
import { FroalaComponent } from './editorraju/froala.component';
import { CompComponent } from './editorraju/comp/comp.component';
import { PayuComponent } from './payu/payu/payu.component';
import { FroalaEditorModule } from './editorraju/froala.module';
import { FroalaViewModule } from './editorraju/comp/froalaview/index';
import { GotoroutingComponent } from './gotorouting/gotorouting.component';
import { SupportComponent } from './BSafe/support/support.component';
import { PrivacyComponent } from './BSafe/newComponents/privacy/privacy.component';
import { TermsofserviceComponent } from './BSafe/newComponents/termsofservice/termsofservice.component';
import { NewfaqComponent } from './BSafe/newComponents/newfaq/newfaq.component';
import { HelppageComponent } from './BSafe/newComponents/helppage/helppage.component';
import { UserProfileComponent } from './BSafe/USRM/user-profile/user-profile.component';
import { ForgotpwdComponent } from './BSafe/newComponents/forgotpwd/forgotpwd.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InwardPreviewComponent } from './Workflow/inward-preview/inward-preview.component';
import { WorkAllotmentPreviewComponent } from './Workflow/work-allotment-preview/work-allotment-preview.component';
import { OutwardPreviewComponent } from './Workflow/outward-preview/outward-preview.component';
import { FriendRequestsComponent } from './friend-requests/friend-requests.component';
import { WorkflowMasterComponent } from './Masters/workflow-master/workflow-master.component';
import { WorkFlowStateMasterComponent } from './Masters/work-flow-state-master/work-flow-state-master.component';
import { AssignRightMasterComponent } from './Masters/assign-right-master/assign-right-master.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SharingSubfolderComponent } from './BSafe/newComponents/sharing-subfolder/sharing-subfolder.component';
import { SetupPageComponent } from './setup-page/setup-page.component';
import { SetupPageDataComponent } from './setup-page-data/setup-page-data.component';
import { MinorDepeartmentComponent } from './Masters/minor-depeartment/minor-depeartment.component';
import { WorkFlowStageComponent } from './Masters/work-flow-stage/work-flow-stage.component';
import { WorkflowTypeComponent } from './Masters/workflow-type/workflow-type.component';
import { SuperadmindashboardComponent } from './superadmindashboard/superadmindashboard.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ArchiveMailsComponent } from './Mail/archive-mails/archive-mails.component';
import { ComposemailComponent } from './Mail/composemail/composemail.component';
import { DeleteMailsComponent } from './Mail/delete-mails/delete-mails.component';
import { InboxMailsComponent } from './Mail/inbox-mails/inbox-mails.component';
import { SentMailsComponent } from './Mail/sent-mails/sent-mails.component';
import { ReadMailComponent } from './Mail/read-mail/read-mail.component';
import { CkycregistrationComponent } from './ckycregistration/ckycregistration.component';
import { MsloginComponent } from './mslogin/mslogin.component';
import { DocumentViewerComponent } from './E_Signature/document-viewer/document-viewer.component';
import { EsignRegistrationComponent } from './E_Signature/esign-registration/esign-registration.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppGlobals } from './app.global';
import { ExtInboxComponent } from './Extension/ext-inbox/ext-inbox.component';
import { ExtReadComponent } from './Extension/ext-read/ext-read.component';
import { ExtsentmailsComponent } from './Extension/extsentmails/extsentmails.component';
import { ExtcomposemailComponent } from './Extension/extcomposemail/extcomposemail.component';
import { ExtDeleteComponent } from './Extension/ext-delete/ext-delete.component';
import { MsviewComponent } from './MS_View/msview/msview.component';
import { DovViewerforMobileComponent } from './dov-viewerfor-mobile/dov-viewerfor-mobile.component';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { DocVersionComponent } from './doc-version/doc-version.component';
import { ClientapprovalComponent } from './clientapproval/clientapproval.component';
import { ClientdashboardComponent } from './clientdashboard/clientdashboard.component';
import { ClientdetailsComponent } from './clientdetails/clientdetails.component';
import { AuditordashboardComponent } from './auditordashboard/auditordashboard.component';
import { MailGateWayComponent } from './mail-gate-way/mail-gate-way.component';
import { ProjectTeamsComponent } from './project-teams/project-teams.component';
import { DataViewComponent } from './Analytics/data-view/data-view.component';
import { FileUploadOptionComponent } from './Analytics/file-upload-option/file-upload-option.component';
import { ProjectCreationComponent } from './Analytics/project-creation/project-creation.component';
import { BranchComponent } from './Masters/branch/branch.component';
import { AuditorviewComponent } from './auditorview/auditorview.component';
import { AddnewuserComponent } from './Masters/addnewuser/addnewuser.component';
import { UserlocationMasterComponent } from './Masters/userlocation-master/userlocation-master.component';
import { SmartcontractlistComponent } from './smartcontractlist/smartcontractlist.component';
import { ApiconsumerregistrationComponent } from './apiconsumerregistration/apiconsumerregistration.component';
import { GroupComponent } from './Masters/group/group.component';
import { HomedesignComponent } from './homedesign/homedesign.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { GmailMailsComponent } from './Mail/gmail-mails/gmail-mails.component';
import { ReadGmailComponent } from './Mail/read-gmail/read-gmail.component';
import { ExtensionPrivacyPolicyComponent } from './extension-privacy-policy/extension-privacy-policy.component';
//import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("341594227731-bvbchpq842e0k69a4ruhhfmrmnjr9sec.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1082185945546834")
  }
]);
export function provideConfig() {
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    // AddprojectComponent,
    DashboardComponent,
    CountryComponent,
    DesignationComponent,
    DepartmentComponent,
    RoleComponent,
    MenuMasterComponent,
    ERegistrationComponent,
    ClientComponent,
    AddTaskComponent,
    UserComponent,
    LoginComponent,
    ProjectComponent,
    HomeComponent,
    ProjectModuleComponent,
    ProjectChangeComponent,
    AssignTaskComponent,
    NavBarComponent,
    AdminDashboardComponent,
    ProjectViewComponent,
    UserDashboardComponent,
    TaskViewComponent,
    FileViewComponent,
    ClientRegistrationComponent,
    ProjectRegistrationComponent,
    ProjectDocumentViewComponent,
    InwardComponent,
    WorkFlowTrackingComponent,
    WorkAllotmentComponent,
    InboxComponent,
    SentItemsComponent,
    DeletedItemsComponent,
    OutwardComponent,
    GeneralTransactionFormComponent,
    FilePageforMailBoxComponent,
    FolderCreationComponent,
    LeaveAplicationComponent,
    TaskComponent,
    SidebarComponent,
    OutwardedProjectsComponent,
    WorkFlowHistoryComponent,
    RoleBasedDashboardComponent,
    AuditReportComponent,
    UserSidebarComponent,
    HomeSidebarComponent,
    BonlineDashboardComponent,
    FooterComponent,
    ChatComponent,
    FileRequestsComponent,
    AllfoldersComponent,
    AllfilesComponent,
    AcceptfriendComponent,
    SharefileComponent,
    UploadfileforrequestComponent,
    LogintouploadfilesComponent,
    DeletedfilesComponent,
    CompanyComponent,
    HostEventComponent,
    HostJoinComponent,
    JoinMeetingComponent,
    PDFViewerComponent,
    IndregisterComponent,
    PayuComponent,
    PlansComponent,
    FroalaComponent,
    CompComponent,
    GotoroutingComponent,
    SupportComponent,
    PrivacyComponent,
    TermsofserviceComponent,
    NewfaqComponent,
    HelppageComponent,
    UserProfileComponent,
    ForgotpwdComponent,
    InwardPreviewComponent,
    WorkAllotmentPreviewComponent,
    OutwardPreviewComponent,
    FriendRequestsComponent,
    WorkflowMasterComponent,
    WorkFlowStateMasterComponent,
    AssignRightMasterComponent,
    AboutUsComponent,
    SharingSubfolderComponent,
    SetupPageComponent,
    SetupPageDataComponent,
    MinorDepeartmentComponent,
    WorkFlowStageComponent,
    WorkflowTypeComponent,
    SuperadmindashboardComponent,
    AboutusComponent,
    ArchiveMailsComponent,
    ComposemailComponent,
    DeleteMailsComponent,
    InboxMailsComponent,
    SentMailsComponent,
    ReadMailComponent,
    CkycregistrationComponent,
    MsloginComponent,
    DocumentViewerComponent,
    EsignRegistrationComponent,
    ExtInboxComponent,
    ExtReadComponent,
    ExtsentmailsComponent,
    ExtcomposemailComponent,
    ExtDeleteComponent,
    MsviewComponent,
    DovViewerforMobileComponent,
    SupportChatComponent,
    DocVersionComponent,
    ClientapprovalComponent,
    ClientdashboardComponent,
    ClientdetailsComponent,
    AuditordashboardComponent,
    MailGateWayComponent,
    ProjectTeamsComponent,
    DataViewComponent,
    FileUploadOptionComponent,
    ProjectCreationComponent,
    BranchComponent,
    AuditorviewComponent,
    AddnewuserComponent,
    UserlocationMasterComponent,
    SmartcontractlistComponent,
    ApiconsumerregistrationComponent,
    GroupComponent,
    HomedesignComponent,
    PdfviewerComponent,
    GmailMailsComponent,
    ReadGmailComponent,
    ExtensionPrivacyPolicyComponent

  ],
  imports: [
    NgxDocViewerModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    AlertsModule.forRoot(),
    AngularTiltModule,
    Ng2SearchPipeModule,
    Ng2PageScrollModule,
    MatTabsModule,
    TagInputModule,
    NgMultiSelectDropDownModule,
    PdfViewerModule,
    FroalaEditorModule,
    DragDropModule,
    FroalaViewModule,
    SignaturePadModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [DatePipe, ModalServiceService, MessageService, AuthService,AppGlobals, 
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },], bootstrap: [AppComponent],
})
export class AppModule { }
