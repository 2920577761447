import { Injectable } from '@angular/core';
import { ProjectChange } from 'src/app/ViewModels/ProjectChange';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectChangeService {

  readonly url="https://blocdrive.in/blocdriveapi/api/";
  constructor(private http: HttpClient) {}
  saveChange(data: ProjectChange) {
    return this.http.post(this.url + 'ProjectChange/PostSaveProjectChanges', data);
  }
  getAllChangesdata(id) {
    return this.http.get(this.url + 'ProjectChange/GetAllProjectsdata/' + id);
  }
  getAllChanges(id) {
    return this.http.get(this.url + 'ProjectChange/GetAllProjectChanges/' + id);
  }

  deleteChange(id: number) {
    return this.http.get(this.url + 'ProjectChange/GetDeleteProjectChangesData/' + id);
  }

  UpdateChange(data: ProjectChange) {
    return this.http.post(this.url + 'ProjectChange/PutProjectChanges', data);
  }
}
