import { Component, OnInit } from '@angular/core';
import { addresslist } from 'src/app/ViewModels/addresslist';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/Shared/Login/login.service';
import { PayU } from 'src/app/ViewModels/Payment';
import { SavepaymentDetails } from 'src/app/ViewModels/SavePaymentDet';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payu',
  templateUrl: './payu.component.html',
  styleUrls: ['./payu.component.css']
})
export class PayuComponent implements OnInit {
  isLoggedIn: boolean;
  uid: string;
  roleid: string;
  username: string;
  gridvalue: string;
  PayuPlans: any;
  plandetails: any;
  planid: any;
  plantype: any;
  price: any;
  closeResult: string;
  selectedplan: any;
  orderid: any;
  CardDetails: any;

  constructor(private registrationService: LoginService,private route : Router,private router:ActivatedRoute,private modalService: NgbModal,) {
    this.router.params.subscribe(params=>{
      if(params["id"]){
        this.orderid= params["id"].split(',')[0];
        this.size= params["id"].split(',')[1];
      }
    });
    window.scrollTo(0,0);

    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserName");
      this.gridvalue = localStorage.getItem("gridvalue");
    }
   }

  ngOnInit() {
    this.GetPlanDetails();
    setTimeout(() => {
      var nav = document.getElementById("nav");
      if(nav){
        nav.style.display = "none";
      }
    }, 1000);
  }
  size:any;
  plans:any;
  GetPlanDetails(){
    this.registrationService.getPayuPlans().subscribe((respose:any) =>{
      this.PayuPlans = respose;     
      console.log(this.PayuPlans)
  });
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

SelectedPlans(plans,PlansContent) {
  this.plandetails=plans;
  this.planid = plans.id;
  this.plantype = plans.type;
  this.price = plans.price;
  this.modalService.open(PlansContent).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
    
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
         this.plandetails=this.selectedplan;
  });
}

payforplan(plans){
  // if(this.isLoggedIn == true){
    this.price = plans.price;
    this.orderid = this.orderid;
    this.planid = plans.id;
    const aa:SavepaymentDetails = {
      CustomerId:localStorage.userId,
      Amount:this.price,
      PlanType:this.planid,
      GST:''
    }
    this.registrationService.SaveOrderDetails(aa).subscribe((data:any)=>{
        this.CardDetails = data;
        this.orderid = data;
        if(data!=null){
          this.route.navigate(['/payuplans',this.orderid]);
          this.getDismissReason("Cross click")
        }
        else{
          alert("Something went wrong")
        }     
    });
 // }
  // else{
  //   this.route.navigate(['/login']);
  // }
}

}
