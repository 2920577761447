<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-2">
        <button class="btn btn-warning" [routerLink]="['/editfileview']">Edit</button>

    </div>
    <div class="col-md-2">
        <button (click)="sndpdftoprint()" class="btn btn-primary" >Print</button>

    </div>
</div>

<div class="col-md-12 row" id="four">
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-4">
        <img src="./assets/img/backimage.png" style="cursor: pointer;" title="Back" height="21px" (click)=goBack() />
    </div>
</div>
<div class="col-md-12">
    <h5 style="text-align:center ;" id="docname">{{DocumentName}}</h5>
</div>

<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-11">
        <p id="dispfile" style="zoom: 90%;">
            <pdf-viewer src="data:application/pdf;base64,{{gotfile}}" [render-text]="true">
            </pdf-viewer>
        </p>
    </div>


</div>
<div class="col-md-12 row">
<div class="col-lg-5"></div>
<div class="col-lg-4">
    <a [href]="downloadfile" style="cursor: pointer;"><button class="btn btn-info">
<i class="fa fa-download"></i>Download
</button>
</a>
</div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>