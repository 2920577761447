import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-bonline-dashboard',
  templateUrl: './bonline-dashboard.component.html',
  styleUrls: ['./bonline-dashboard.component.css']
})
export class BonlineDashboardComponent implements OnInit {
  isLoggedIn: boolean;
  uid: string;
  username: string;
  roleid: string;
  lap = false;
  mob = false;
  mobile = false;

  mobileview: boolean;

  constructor(
  
  ) {
    if (localStorage.getItem('IsLoggedIn') == 'true') {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem('userId');
      this.username = localStorage.getItem('UserFullName');
      this.roleid = localStorage.getItem('role');
    }

    this.isMobileMenu();
  }

  ngOnInit() {
    setTimeout(() => {
      var footer = document.getElementById('footer');
      if (footer.style.display == 'none') {
        footer.style.display = 'block';
      }
    }, 1000);
    window.onresize = changeImage;

    function changeImage() {
      if (window.innerWidth > 600) {
        console.log(this.lap);
        this.lap = true;
        this.mob = false;
      } else {
        this.lap = false;
        this.mob = true;
      }
    }

    if (window.innerWidth > 600) {
      console.log(this.lap);

      this.lap = true;
      this.mob = false;
    } else {
      this.lap = false;
      this.mob = true;
    }


    if (window.innerWidth <= 500) {
      this.mobile = true;
    }
    var anima = document.getElementById('anima');
    if (anima) {
      anima.style.display = 'block';
    }
   
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    } else {
      this.mobileview = true;
    }
  }
  downpath: any;
  seleteddownload: any;
  
}