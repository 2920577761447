<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<app-user-sidebar
  *ngIf="(roleid == '2' || roleid == '9') && mobileview"
></app-user-sidebar>
<style type="text/css"></style>
<div class="register">
  <div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div>
        <br />
        <h2 class="modal-title text-capitalize" align="center">
          <strong>Task:</strong> {{ getMoveData.task.issue }}
        </h2>
      </div>
    </div>
  </div>
  <hr />
  <div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div>
        <div class="panel panel-primary">
          <div class="panel-body">
            <form class="form-horizontal" role="form">
              <div class="form-group">
                <div class="col-md-12 row">
                  <div class="col-md-1"></div>
                  <div class="col-md-2">
                    <label class="control-label col-xs-2"
                      ><b>Project:</b></label
                    >
                  </div>
                  <div class="col-md-3">
                    <label class="control-label col-xs-2"
                      >{{
                        projectDisplayName
                      }}</label
                    >
                  </div>
                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="ProjPassdata()" style="padding: 4px;;"
                    >
                      CLICK HERE TO VIEW PROJECT DETAILS
                    </button>
                  </div>
                </div>
              </div>
                 <!-- ...... -->
                 <div *ngIf='showteam'>
                  <div class="form-group" *ngIf="userrole != '1001'">
                    <div class="col-md-12 row">
                      <div class="col-md-1"></div>
    
                      <div class="col-md-2">
                      <label class="control-label col-xs-2"><b>Team Name:</b></label>
                  
                    </div>
                    <div class="col-md-3">
                      <label class="control-label col-xs-2"></label>
                      {{ projectteamname }}
                    </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="userrole != '1001'">
                    <div class="col-md-12 row">
                      <div class="col-md-1"></div>
    
                      <div class="col-md-2">
                      <label class="control-label col-xs-2"><b>Team Members:</b></label>
                  
                    </div>
                    <div class="col-md-3">
                      <label class="control-label col-xs-2"></label>
                      {{ projectteammembers }}
                    </div>
                    </div>
                  </div>
                </div>
                  <!-- ...... -->
              <div class="form-group" *ngIf="userrole != '1001'">
                <div class="col-md-12 row">
                  <div class="col-md-1"></div>

                  <div class="col-md-2">
                  <label class="control-label col-xs-2"><b>Priority:</b></label>
              
                </div>
                <div class="col-md-3">
                  <label class="control-label col-xs-2"></label>
                  {{ getMoveData.priority }}
                </div>
                </div>
              </div>
              <div class="form-group" *ngIf="userrole != '1001'">
                <div class="col-md-12 row">
                  <div class="col-md-1"></div>

                  <div class="col-md-2">
                  <label class="control-label col-xs-2"><b>Type:</b></label>
                
                </div>
                <div class="col-md-3">
                  <label class="control-label col-xs-2"></label>
                  {{ getMoveData.task.issueType }}
                </div>
                </div>
              </div>
              <div class="form-group" *ngIf="userrole != '1001'">
                <div class="col-md-12 row">
                  <div class="col-md-1"></div>

                  <div class="col-md-2">

                  <label class="control-label col-xs-2"
                    ><b>Assigned To:</b></label
                  >
                </div>
                <div class="col-md-2">

                  <label class="control-label col-xs-2"
                    ></label
                  >
                  {{ getMoveData.name }}
                </div>
              </div>
              </div>
              <div class="form-group">
                <div class="col-md-12 row">
                  <div class="col-md-1"></div>

                  <div class="col-md-2">
                    <label
                      class="control-label col-xs-2"
                      style="margin-left: -12px"
                      ><b
                        >&nbsp;&nbsp;Status:
                       </b
                      >
                    </label>
                  
                  </div>
                  <div class="col-md-3">
                    <label
                      class="control-label col-xs-2"
                     
                      >
                        <p
                          [style.color]="
                            TaskCurrentStatus == 'TO DO'
                              ? 'red'
                              : TaskCurrentStatus == 'DONE'
                              ? 'green'
                              : TaskCurrentStatus == 'IN PROGRESS'
                              ? 'orange'
                              : 'blue'
                          "
                        >
                          {{ TaskCurrentStatus }}
                        </p>
                    </label>
                
                  </div>
                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="addTaskStatus(statuscontent)" style="padding: 4px;width: 295px;;"
                    >
                      CLICK HERE TO UPDATE STATUS
                    </button>
                  </div>
              
                </div>
              </div>
              <div
                class="form-group"
                style="margin-left: 30px"
                *ngIf="userrole != '1001'"
              >
                <h4>Documents For Reference:</h4>
                <table class="table table-striped" *ngIf="getMoveData">
                  <tr class="thead-dark">
                    <th style="display: none">ID</th>
                    <th>Documents</th>
                    <th>Download</th>
                  </tr>

                  <tr
                    *ngFor="let ret of getMoveData.fileDetails; let i = index"
                    style="color: rgb(0, 0, 0)"
                  >
                    <td>
                      <div
                        *ngIf="
                          ret.documentType == 'image/jpeg' ||
                          ret.documentType == 'image/png' ||
                          ret.documentType == 'image/jpg'
                        "
                      >
                        <img
                          [src]="ret.file"
                          style="
                            width: 50px;
                            height: 50px;
                            margin-left: 15px;
                            cursor: pointer;
                          "
                          (click)="gotopdfViewForAssignTask(ret)"
                        />
                        <span style="cursor: pointer">
                          &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          ret.documentType == 'application/pdf' ||
                          ret.documentType == 'pdf'
                        "
                      >
                        <img
                          src="./assets/img/pdficon.png"
                          style="
                            width: 35px;
                            height: 34px;
                            cursor: pointer;
                            margin-left: 15px;
                          "
                          (click)="gotopdfViewForAssignTask(ret)"
                        />
                        <span style="cursor: pointer">
                          &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          ret.documentType ==
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                          ret.documentType == 'xlsx' ||
                          ret.documentType == 'xls' ||
                          ret.documentType == 'application/vnd.ms-excel'
                        "
                        (click)="gotopdfViewForAssignTask(ret)"
                      >
                        <img
                          src="./assets/img/xls.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                        />
                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          ret.documentType ==
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                          ret.documentType == 'ppt' ||
                          ret.documentType == 'pptx'
                        "
                        (click)="gotopdfViewForAssignTask(ret)"
                      >
                        <img
                          src="./assets/img/ppticon2.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                        />
                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>

                      <div
                        *ngIf="
                          ret.documentType ==
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          ret.documentType == 'docx'
                        "
                        (click)="gotopdfViewForAssignTask(ret)"
                      >
                        <img
                          src="./assets/img/docx.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                        />
                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>
                      <div *ngIf="ret.documentType == 'text/plain'">
                        <img
                          src="./assets/img/txt.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                          (click)="gotopdfViewForAssignTask(ret)"
                        />

                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <a (click)="DownloadFileforAssignTask(ret)" download
                        ><button class="btn btn-primary">
                          <i class="fa fa-download"></i>Download
                        </button></a
                      >
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <hr />
              <div class="form-group" style="margin-left: 30px">
                <div class="panel panel-success">
                  <div class="panel-heading">
                    <div class="panel panel-primary" align="center">
                      <h2><b> Comments</b></h2>
                    </div>
                  </div>
                  <div>&nbsp;</div>
                  <div *ngIf="userrole != '1001'">
                    <button
                      class="btn"
                      style="border-color: rgb(0, 0, 0); color: rgb(0, 0, 0)"
                      (click)="addTaskcomment(commentscontent)"
                    >
                      Add Comments
                    </button>
                  </div>
                  <div>&nbsp;</div>
                  <div>&nbsp;</div>
                  <div class="panel-body">
                    <table class="table table-striped">
                      <tr class="thead-dark"></tr>
                      <tr class="thead-dark">
                        <th></th>
                        <th>SI NO</th>
                        <th>Comment</th>
                        <th>Commented By</th>
                        <th>Commented On</th>
                      </tr>
                      <tr
                        *ngFor="
                          let taskComment of taskcommentslist;
                          let i = index
                        "
                        style="color: rgb(0, 0, 0)"
                      >
                        <td>
                          <i class="glyphicon glyphicon-ok"></i>
                        </td>
                        <td>{{ i + 1 }}</td>

                        <td>
                          <a ng-href="/#/taskview">{{ taskComment.comment }}</a>
                        </td>
                        <td>{{ taskComment.commentedBy }}</td>
                        <td>{{ taskComment.commentedOn | date }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <hr />

              <div class="col-md-12 row" *ngIf="userrole != '1001'">
                <div class="col-md-5">
                  <label class="dropdown-item">
                    <b
                      for="excel-upload"
                      style="color: #0000ff; cursor: pointer; font-size: 20px"
                      ><i class="fas fa-file"></i>&nbsp;&nbsp;Upload Files</b
                    >
                    <input
                      #fileInput
                      (click)="fileInput.value = null"
                      type="file"
                      id="excel-upload"
                      multiple="true"
                      style="display: none"
                      (change)="onChangeUploadDoc($event)"
                    />
                  </label>
                </div>
              </div>
              <div>&nbsp;</div>
              <div
                class="form-group"
                style="margin-left: 30px"
                *ngIf="userrole != '1001'"
              >
                <h4>Attachments:</h4>
                <table class="table table-striped" *ngIf="alldocuments">
                  <tr class="thead-dark">
                    <th style="display: none">ID</th>
                    <th>Documents</th>
                    <th>Download</th>
                  </tr>

                  <tr
                    *ngFor="let ret of alldocuments.retData; let i = index"
                    style="color: rgb(0, 0, 0)"
                  >
                    <td>
                      <div
                        *ngIf="
                          ret.documentType == 'image/jpeg' ||
                          ret.documentType == 'image/png' ||
                          ret.documentType == 'image/jpg'
                        "
                      >
                        <img
                          [src]="ret.file"
                          style="
                            width: 50px;
                            height: 50px;
                            margin-left: 15px;
                            cursor: pointer;
                          "
                          (click)="gotopdfView(ret)"
                        />
                        <span style="cursor: pointer">
                          &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          ret.documentType == 'application/pdf' ||
                          ret.documentType == 'pdf'
                        "
                      >
                        <img
                          src="./assets/img/pdficon.png"
                          style="
                            width: 35px;
                            height: 34px;
                            cursor: pointer;
                            margin-left: 15px;
                          "
                          (click)="gotopdfView(ret)"
                        />
                        <span style="cursor: pointer">
                          &nbsp;&nbsp;{{ ret.documentName }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          ret.documentType ==
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                          ret.documentType == 'xlsx' ||
                          ret.documentType == 'xls' ||
                          ret.documentType == 'application/vnd.ms-excel'
                        "
                        (click)="gotopdfView(ret)"
                      >
                        <img
                          src="./assets/img/xls.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                        />
                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>
                      <div
                        *ngIf="
                          ret.documentType ==
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                          ret.documentType == 'ppt' ||
                          ret.documentType == 'pptx'
                        "
                        (click)="gotopdfView(ret)"
                      >
                        <img
                          src="./assets/img/ppticon2.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                        />
                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>

                      <div
                        *ngIf="
                          ret.documentType ==
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                          ret.documentType == 'docx'
                        "
                        (click)="gotopdfView(ret)"
                      >
                        <img
                          src="./assets/img/docx.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                        />
                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>
                      <div *ngIf="ret.documentType == 'text/plain'">
                        <img
                          src="./assets/img/txt.png"
                          style="width: 35px; height: 34px; cursor: pointer"
                          (click)="gotopdfView(ret)"
                        />

                        <span style="cursor: pointer">
                          {{ ret.documentName }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <a (click)="DownloadFile(ret)" download
                        ><button class="btn btn-primary">
                          <i class="fa fa-download"></i>Download
                        </button></a
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </form>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="border: medium 1px">
    <div></div>
  </div>
  <div class="modal-footer"></div>
</div>
<ng-template #statuscontent let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title ">Status</h4>
      <button
        type="button "
        class="close"
        aria-label="Close "
        (click)="d('Cross click')"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-xs-12">
        <div class="col-xs-4"></div>
        <label class="control-label col-xs-2">Status:</label>
        <div class="col-xs-4">
          <select
            class="form-control dropdownsize"
            (change)="onSelectstatus($event.target.value)"
          >
            <option value=" ">Select Status</option>
            <option
              *ngFor="let status of projectstatus"
              value="{{ status.id }}"
            >
              {{ status.status }}
            </option>
          </select>
        </div>
      </div>

      <div>&nbsp;</div>
      <div class="col-md-12 row">
        <div class="col-md-5"></div>
        <div class="modal-footer" style="float: left">
          <button class="btn btn-primary" type="submit ">
            <span
              class="glyphicon glyphicon-cloud-upload"
              style="font-size: 19px"
              (click)="addstatusdata()"
              (click)="d('Cross click')"
            >
              Save</span
            >
          </button>
          <button class="btn btn-primary" type="submit ">
            <span
              class="glyphicon glyphicon-cloud-upload"
              style="font-size: 19px"
              (click)="d('Cross click')"
            >
              Cancel</span
            >
          </button>
        </div>
      </div>
    </div>
    <br />
  </div>
</ng-template>
<ng-template #commentscontent let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title ">Status</h4>
      <button
        type="button "
        class="close"
        aria-label="Close "
        (click)="d('Cross click')"
      >
        <span aria-hidden="true ">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-xs-12">
        <div class="col-xs-4"></div>
        <label class="control-label col-xs-2">Comment:</label>
        <div class="col-xs-4">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="comment"
            id="comment"
            name="comment"
            placeholder="Add comment"
          />
        </div>
      </div>
    </div>

    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-5"></div>
      <div class="modal-footer" style="float: left">
        <button class="btn btn-primary" type="submit ">
          <span
            class="glyphicon glyphicon-cloud-upload"
            style="font-size: 19px"
            (click)="Savecomment()"
            (click)="d('Cross click')"
          >
            Save</span
          >
        </button>
        <button class="btn btn-primary" type="submit ">
          <span
            class="glyphicon glyphicon-cloud-upload"
            style="font-size: 19px"
            (click)="d('Cross click')"
          >
            Cancel</span
          >
        </button>
      </div>
    </div>
  </div>
  <br />
</ng-template>
<!-- View Documents Modals -->
<ng-template #viewimage let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center">
        <b>{{ imgdata.documentName }}</b>
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <img
        [src]="imgdata.file"
        class="img-responsive"
        alt=""
        style="height: 300px; width: 317px"
      />
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-5"></div>
      <div class="modal-footer">
        <div class="col-lg-12 row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8"></div>
        </div>
      </div>
    </div>
  </div>
  <br />
</ng-template>
<ng-template #viewpdf let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        <b style="text-align: center">{{ pdfdata.documentName }}</b>
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngx-doc-viewer
        [url]="pdffile"
        viewer="google"
        style="width: 100%; height: 70vh"
      >
      </ngx-doc-viewer>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-5"></div>
      <div class="modal-footer"></div>
    </div>
  </div>
  <br />
</ng-template>
<ng-template #viewppt let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center">
        <b>{{ pptdata.documentName }}</b>
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngx-doc-viewer
        [url]="pptfile"
        viewer="google"
        style="width: 100%; height: 70vh"
      ></ngx-doc-viewer>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-5"></div>
      <div class="modal-footer">
        <div class="col-lg-12 row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8"></div>
        </div>
      </div>
    </div>
  </div>
  <br />
</ng-template>
<ng-template #viewxls let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="text-align: center">
        <b>{{ xlsdata.documentName }}</b>
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <iframe
        [src]="xlssantizedisplay"
        width="100%"
        height="565px"
        frameborder="0"
      >
      </iframe>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-5"></div>
      <div class="modal-footer">
        <div class="col-lg-12 row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8"></div>
        </div>
      </div>
    </div>
  </div>
  <br />
</ng-template>
<ng-template #viewword let-d="dismiss " let-c="dismiss ">
  <div
    class="container body-content col-sm-12"
    style="background-color: #f3f3f3"
  >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        <b style="text-align: center">{{ worddata.documentName }}</b>
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ngx-doc-viewer
        [url]="wordfile"
        viewer="google"
        style="width: 100%; height: 70vh"
      ></ngx-doc-viewer>
    </div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
      <div class="col-md-5"></div>
      <div class="modal-footer">
        <div class="col-lg-12 row">
          <div class="col-lg-2"></div>
          <div class="col-lg-8"></div>
        </div>
      </div>
    </div>
  </div>
  <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div
      class="alert alert-info"
      style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      "
    >
      <button
        mat-button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="closealert()"
      >
        <i class="material-icons" style="color: white">Close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>
<br />
<br />
