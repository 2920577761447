export class outward{
    address:any
    attachmentdetails:any
    dispatchMode: any
    docType: any
    //InwardNumber: "",
    remarks:any
    outwardtype: any
    pages: any
    replyAwaited: any
    sensitivity: any
    subject: any
    status:any
    filenumber: any
    folder:any
    mailid: any
}