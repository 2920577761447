import { Component, OnInit } from '@angular/core';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {
  uid: string;
  username: string;
  Role: string;
  Inboxs: any;
  totalItems: any;
  p: number = 1;
  employee: any;
  exi: any;
  closeResult: string;
  comment: any
  Exhibit: any;
  roleid: string;
  mobileview: boolean;
  constructor(private workflowservice: WorkFlowService, private router: Router, private modalService: NgbModal,) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.Role = localStorage.getItem("role");
      this.roleid = localStorage.getItem("role");

    }
    this.isMobileMenu();

  }

  ngOnInit(): void {
    this.getInBox();
  }
  getInBox() {
    this.workflowservice.getInbox(this.uid).subscribe((response: any) => {
      this.Inboxs = response;
      this.totalItems = this.Inboxs.length;
    })
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };

  openUnauthmodal(modal) {
    this.modalService.open(modal).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  ShowMailForm(mail) {
    localStorage.setItem("InwardFileId", mail.fileId);
    localStorage.setItem("InwardFolderId", mail.folderId);

    if (mail.formCode == "OUT") {
      var Wdata = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid
      }
      this.workflowservice.GetOutWardData(Wdata).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/outward']);
      })
    }
    else if (mail.formCode == "WK" &&  this.roleid=='1') {
      var Wdata1 = {
        mailid: mail.masId,
        WorkflowType: "1",
        transid: mail.transId,
        FromUser: this.uid

      }
      this.workflowservice.GetWorkAllocateData(Wdata1).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/outward']);      })
    }
    else if (mail.formCode == "WK") {
      var Wdata1 = {
        mailid: mail.masId,
        WorkflowType: "1",
        transid: mail.transId,
        FromUser: this.uid

      }
      this.workflowservice.GetWorkAllocateData(Wdata1).subscribe((response: any) => {
        this.employee = response;
        this.GetExhibits(this.employee.caseid)
      })
    }

    else if (mail.formCode == "GEN") {
      var Wdata2 = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid

      }
      this.workflowservice.GetGenaralTransactionData(Wdata2).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/generaltrascationform']);
      })
    }
    else if (mail.formCode == "IN") {
      var Wdata3 = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid

      }

      this.workflowservice.GetInwardData(Wdata3).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/inward']);
      })
    }
    else if (mail.formCode == "FILE") {
      var Wdata4 = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid

      }
      this.workflowservice.GetFileNumberGenerationData(Wdata4).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/inboxfile']);
      })
    }
    else if (mail.formCode == "FOLDER") {
      var Wdata5 = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid

      }
      this.workflowservice.GetFolderCreationData(Wdata5).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/folder']);
      })
    }
    else if (mail.formCode == "LEAVE") {
      var Wdata6 = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid

      }
      this.workflowservice.GetLeaveData(Wdata6).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/leave']);
      })
    }
    else if (mail.formCode == "TASK") {
      var Wdata7 = {
        mailid: mail.masId,
        WorkflowType: "1",
        FromUser: this.uid

      }
      this.workflowservice.GetTaskData(Wdata7).subscribe((response: any) => {
        this.employee = response;
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/task']);
      })
    }
  }
  GetExhibits(id) {
    var caseinfo = {
      Caseid: id,
      Role: this.Role,
      UserId: this.uid,
    }
    this.Exhibit = caseinfo;
    this.workflowservice.GetExhibit(caseinfo).subscribe((response: any) => {
      this.exi = response;
      if (this.employee.ackStatus == 0) {
        document.getElementById("openModalButton").click();
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/workallotment']);
      }
      else if (this.employee.ackStatus == 1) {
        localStorage.setItem("employee", JSON.stringify(this.employee));
        this.router.navigate(['/workallotment']);
      }
      else if (this.employee.ackStatus == 2) {
        document.getElementById("openUnauthModalButton").click();

      }
    })
  }
  Accept(caseid, ackid) {

  }
  Rejectone(caseid, ackid) {

  }
  Rejecttwo(caseid, ackid, exi) {

  }

  openmodal(modal) {
    this.modalService.open(modal).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
