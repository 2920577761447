<!-- Start Masthead -->
<app-nav-bar></app-nav-bar>

<section class="masthead js-masthead-height pb-0">
    <br><br><br><br><br><br>
    <div class="container">
        <div class="row">
            <div class="col-lg-5 mb-40" *ngIf="beforeotp">
                <div class="masthead__content">
                    <h3 class="xx" style="text-align: center;">Reset Password</h3>
                </div>
                <div class="masthead__form" style="background-color: transparent;border-style: none;box-shadow: none;">
                    <form name="login" action="" (ngSubmit)="OnSubmit()">

                        <input type="email" class="form-control" id="inputEmail" placeholder="Email" [(ngModel)]="userName" [ngModelOptions]="{standalone: true}" />

                        <hr>


                        <div *ngIf="openotp">
                            <input type="number" class="form-control" id="otp" name="otp" placeholder="Enter OTP" [(ngModel)]="otp" />
                        </div>

                        <div>
                            <p> Don't Have an Account? <a [routerLink]="['/indregister']" style="color: #1e5ee5;"> Sign Up</a></p>
                        </div>
                        <hr>

                        <div class="masthead__form-action">
                            <button type="submit" class="btn btn-rounded btn-orange btn-icon-right">Submit <i class="fa fa-long-arrow-right"></i></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 mb-40" *ngIf="afterotp">
                <div class="masthead__content">
                    <h3 class="xx" style="text-align: center;">Reset Password</h3>
                </div>
                <div class="masthead__form" style="background-color: transparent;border-style: none;box-shadow: none;">
                    <form name="login" action="" (ngSubmit)="changepassword()">

                        <input type="password" class="form-control" id="p1" name="p1" placeholder="Password" [(ngModel)]="p1" />

                        <input type="password" class="form-control" id="p2" name="p2" placeholder="Confirm Password" [(ngModel)]="p2" />

                        <hr>


                        <div class="masthead__form-action">
                            <button type="submit" class="btn btn-rounded btn-orange btn-icon-right">Reset Password<i class="fa fa-long-arrow-right"></i></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-7">
                <img src="assets/img/register.svg" class="img-fluid" alt="Business Looking" />
            </div>
        </div>
        <div class="col-lg-12 ">&nbsp;</div>
        <div class="col-lg-12 ">&nbsp;</div>

    </div>
</section>
<!-- End Masthead -->