import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DesignationService } from 'src/app/Shared/Designation/designation.service';
import { Designation } from 'src/app/ViewModels/Designation';
import { RoleService } from 'src/app/Shared/Role/role.service';
import { DepartmentService } from 'src/app/Shared/Department/department.service';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {
  closeResult: any;
  DesignationName: any;
  designationname: any;
  DesignationDesc: any;
  designationdesc: any;
  public dismissalert = false;
  notify: string;
  DesignationList: any;
  p: number = 1;
  editdesignationName: any;
  editdesc: any;
  editdesignationid: any;
  udesignationname: any;
  udesignationdesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  RoleList: any = [];
  DepartmentList: any = [];
  selectedrole: any;
  selecteddepartment: any;
  editrole: any;
  editdepid: any;
  AllDepartmentList: any = [];
  uid: string;
  roleid: string;
  mobileview: boolean;
  GUID: string;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    , private spinner: NgxSpinnerService, private designationService: DesignationService,
    private roleService: RoleService, private departmentService: DepartmentService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();
  }

  ngOnInit(): void {
    this.Designations();
this.Designationdata();
    this.Roles();
    this.Departments();
    this.DesignationName = "";
    this.DesignationDesc = "";
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };

  Roles() {
    this.spinner.show();
    this.roleService.getAllRoles(this.GUID).subscribe((Data: any) => {
      this.RoleList = Data;
      this.spinner.hide();
    });
  }
  Departments() {
    this.spinner.show();
    this.departmentService.getAllDepartments(this.GUID).subscribe((Data: any) => {
      this.AllDepartmentList = Data;
      this.spinner.hide();
    });
  }
  Designationdata() {
    this.spinner.show();
    this.designationService.getDesignationsdata(this.roleid).subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
  }

  onSelectedRole(id) {
    this.selectedrole = id;
    this.spinner.show();
    this.departmentService.getParticularDepart(this.selectedrole).subscribe((Data: any) => {
      this.DepartmentList = Data;
      this.spinner.hide();
    });
  }
  onSelectedDepartment(id) {
    this.selecteddepartment = id
  }
  Designations() {
    this.spinner.show();
    this.designationService.getAllDesignations(this.GUID).subscribe((Data: any) => {
      this.DesignationList = Data;
      this.spinner.hide();
    });
  }
  addDesignation(addesignation) {
    this.modalService.open(addesignation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditDesignation(updatedata, updatedesignation) {
    this.editdesignationName = updatedata.designationName;
    this.editdesignationid = updatedata.id;
    this.createdon = updatedata.createdOn;
    this.createdby = updatedata.createdBy;
    this.editrole = updatedata.roleId;
    this.editdepid = updatedata.departmentId
    this.modalService.open(updatedesignation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteDesignation(deletedata, adddesignation) {
    this.DeleteName = deletedata.designationName;
    this.deleteid = deletedata.id;
    this.modalService.open(adddesignation).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  SaveDesignation() {
    const inputRequest: Designation = {
      Id: 0,
      DesignationName: this.designationname,
      DesignationDesc: this.designationdesc,
      CreatedBy: "",
      CreatedOn: "",
      ModifiedBy: "",
      ModifiedOn: "",
      RoleId: this.selectedrole,
      DepartmentId: this.selecteddepartment,
      UserId: this.uid,
      GUID: this.GUID
    };
    this.spinner.show();
    this.designationService.saveDesignation(inputRequest)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Designation added successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Create",
            TransactionDesc:"Designation master",
           
            }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Designations();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.spinner.hide();
          this.Designations();
        }
      });
  }

  UpdateDesignation() {
    if (this.selectedrole == null || this.selectedrole == "") {
      this.selectedrole = this.editrole
    }
    if (this.selecteddepartment == null || this.selecteddepartment == "") {
      this.selecteddepartment = this.editdepid
    }
    const inputRequest1: Designation = {
      Id: this.editdesignationid,
      DesignationName: this.editdesignationName,
      DesignationDesc: this.editdesc,
      CreatedBy: this.createdby,
      CreatedOn: this.createdon,
      ModifiedBy: "",
      ModifiedOn: "",
      DepartmentId: this.selecteddepartment,
      RoleId: this.selectedrole,
      UserId: this.uid,
      GUID: this.GUID

    };
    this.spinner.show();
    this.designationService
      .UpdateDesignation(inputRequest1)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Designation updated successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Update",
            TransactionDesc:"Designation master",
           
            }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        }
      });
  }
  DeleteDesignationRow() {
    this.spinner.show();
    this.designationService
      .deleteDesignation(this.deleteid)
      .subscribe((data: any) => {
        if (data == "success") {
          this.notify = "Designation deleted successfully";
          const audit = {
            TransactionName:"Master",
            TransactionType:"Delete",
            TransactionDesc:"Designation master",
           
            }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        } else {
          this.notify = "Something Went Wrong. Try again.!!";
          setTimeout(() => (this.dismissalert = true), 100);
          setTimeout(
            function () {
              this.dismissalert = false;
            }.bind(this),
            3000
          );
          this.Designations();
          this.spinner.hide();
        }
      });
  }
  closealert() {
    this.dismissalert = false;
  }
  GetDepartment(deptid) {
    for (let i = 0; i < this.AllDepartmentList.length; i++) {
      if (deptid == this.AllDepartmentList[i].id) {
        return this.AllDepartmentList[i].majorDepartmentName;
      }
    }
  }
  GetRole(rid) {
    for (let i = 0; i < this.RoleList.length; i++) {
      if (rid == this.RoleList[i].id) {
        return this.RoleList[i].roleName;
      }
    }
  }
}
