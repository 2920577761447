
<html>
    <style>
        progress {
            border: none;
            background: crimson;
            border-radius: 5px;
        }
        
        progress::-webkit-progress-value {
            background: #4F44C5;
            border-radius: 5px;
        }
        
        progress::-webkit-progress-bar {
            background: #D7E1EB;
            border-radius: 5px;
        }
    </style>
    
    <body>
    
      
        <div id="viewerBG" class="container" style="padding-top: 0px;">
            <div>
                <div fxLayout="row"  id="tpnvbr" *ngIf="!showInvitetoSign && !recpsignaturepage" style="width: 100%">
                    <div fxFlex="80">
                        {{docName}}
                     </div>
                    
                     <div fxFlex="20" style="display: flex;justify-content: space-evenly;">
                         <button class="btn btn-primary" (click)="openModal(signature)">Esign</button>&nbsp;
                         <button class="btn btn-primary" (click)="selfsign()">Selfsign</button>
                     </div>
        
                </div>
        
                <!-- Send Message to Esign Recipients -->
                <div id="EsignSendMsgdrawDownRect" *ngIf="ShowEsignSendMsgLayout">
                    <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 col" id="DrawDownFirstHalf">
        
                        <p id="EsignMsgHeading">Send Message to Recipients for E-Signatures</p>
                        <p>
                            <input type="checkbox" [(ngModel)]="thereminderCheckbox" style="cursor: pointer;" (change)="OnClickReminderCheckbox($event)" />
                            <span id="automaticremiandersText">&nbsp; Send automatic reminders:
                            </span>
                            <select (change)="onSelectReminder($event)" class="form-control" id="RemindersDropDown">
                                <option *ngFor="let fol of Reminders" value={{fol.RemainderDays}}>
                                    {{fol.Time}}
                                </option>
                            </select>
                        </p>
        
                        <img src="./assets/img/close x Purple.png" id="EsignMsgClose" (click)="CloseMsgofEsign()"> &nbsp;
                        <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" id="subjectMsgDiv">
                            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6" id="messageVerLine">
                                <div id="first">
                                    <label id="fieldTextName">Email Subject</label>
                                    <input #subjectlength class="form-control" type="text" id="inpBox" placeholder="Email Subject" name="EsignSubject" [(ngModel)]="EsignSubject">
                                    <p id="esignsubjectCharremaining" [(ngModel)]="subjectcharacters" name="subjectcharacters">
                                        characters remaining : {{100 - subjectlength.value.length}} </p>
                                    <br />
                                </div>
                                <div id="esignmsg">
                                    <label id="fieldTextName">Email Message</label>
                                    <textarea #msglength type="text" name="EsignEmailMsgtext" id="EsignEmailMsgtext" placeholder="Enter message" cols="45" rows="3" [(ngModel)]="esignEmailMsgtext"></textarea>
                                    <p id="esignmsgCharremaining" [(ngModel)]="messagecharacters" name="messagecharacters">
                                        characters remaining : {{1000 - msglength.value.length}} </p>
                                    <br />
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6">
                                <p id="fieldTextName" style="margin-top: -2%;">Recipients</p>
                                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" style="left: -6%;top: -10%;">
                                    <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                                        <div class="">
                                            <table class="table table-hover">
                                                <tbody *ngFor="let data of EsignReceipientsList" style="color: black">
                                                    <tr class="show-image" style="font-family: DIN Next Rounded LT Pro;">
                                                        <td id="EsignFN" class="row">
                                                            <img src="data:image/png;base64,{{data.UserImage}}" *ngIf="data.UserImage!=null" id="ToProPic">
                                                            <p *ngIf="data.UserImage==null" id="EsignNoProPic">
                                                                <span id="toInitialLetter">{{data.UserName}}</span>
                                                            </p>
                                                            <p *ngIf="data.FirstName" id="EsignRecFN">
                                                                &nbsp;{{data.FirstName}}</p>
                                                            <p *ngIf="data.FirstName == null" id="EsignRecEID" title="{{data.Email}}">
                                                                &nbsp;{{data.Email}}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12" style="text-align: center;padding-bottom: 2%; margin-top: -3%;
                border-top: 1px solid #D7E1EB;margin-left: 2%;">
                                <button type="submit" id="EsignsaveBtn" class="btn btn-sm waves-light" mdbWavesEffect data-dismiss="modal" data-toggle="modal" (click)="SaveEsignDetails()"><span
                                        id="EsignsaveBtnText">SEND</span></button>
                                <button type="button" class="btn btn-sm waves-light" id="CancelBtn" data-dismiss="modal" mdbWavesEffect (click)="CloseEsign()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <!-- END of Send Message to Esign Recipients -->
        
                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" *ngIf="showInvitetoSign && !recpsignaturepage" id="EsignTools">
                    <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7 row">
        
                        <div id="ToolsDiv" *ngIf="!SelfSignChecked">
                            <div cdkDropList id="signi" class="esign" [cdkDropListData]="esign1" (cdkDropListDropped)="onTaskeesignDrop3($event,1)"> <img cdkDrag src="./assets/img/edit.png" width="20px" height="20px" id="ESfullsign" /> </div>
                            <div cdkDropList id="initi" class="esign" [cdkDropListData]="esign2" (cdkDropListDropped)="onTaskeesignDrop3($event,2)"> <img cdkDrag src="./assets/img/date.png" width="20px" height="20px" id="ESinitials" /> </div>
    <div>
        <select (change)="someMethod($event)">
            <option >select user</option>
            <option *ngFor="let user of RecipientsList">{{user}}</option>
          </select>
    </div>
                        </div>
        
                        <div id="ToolsDiv" *ngIf="SelfSignChecked">
                            <div cdkDropList id="signi" class="esign" [cdkDropListData]="esign1" (cdkDropListDropped)="onTaskesignDrop2($event,1)">
                                <img cdkDrag src="./assets/img/edit.png" id="ESfullsign" width="20px" height="20px" /> </div>
                            <div cdkDropList id="datei" class="esign" [cdkDropListData]="esign3" (cdkDropListDropped)="onTaskesignDrop2($event,2)">
                                <img cdkDrag src="./assets/img/date.png"  width="20px" height="20px" id="ESdate" /> </div>
                        </div>
        
        
        
                    </div>
                    <div class="col-md-5 col-sm-5 col-lg-5 col-xs-5">
        
                        <button type="submit" id="InvitetoSignBtn" class="btn btn-success btn-sm waves-light" data-dismiss="modal" (click)="SaveEsignDetails()" mdbWavesEffect *ngIf="!SelfSignChecked">
                            <span id="InvitetoSignText">INVITE TO SIGN</span></button>
        
                        <button type="submit" id="ESignFINISHBtn" class="btn btn-success btn-sm waves-light" data-dismiss="modal" (click)="selfSignsave()" mdbWavesEffect *ngIf="SelfSignChecked">
                            <span id="InvitetoSignText">FINISH</span></button>
        
                        <label id="exitEsign">EXIT E-SIGN <img src="./assets/img/IARclose x.svg" (click)="exitEsign()"
                                id="IARexitEsign" /> </label>
                    </div>
                </div>
        
                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" *ngIf="recpsignaturepage" id="recpEsignTools" style="margin-left: 0.3%;width: 78%">
                    <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7 row">
        
        
        
                        <div id="ToolsDiv">
        
                            <div class="esign" id="ESbackDiv" (click)="back()"> <img src="./assets/img/IARback.svg" id="ESback" /> </div>
                            <div class="esign" id="ESfrontDiv" (click)="next()"> <img src="./assets/img/IARfront.svg" id="ESfront" /> </div>
                        </div>
        
        
                    </div>
                    <div class="col-md-5 col-sm-5 col-lg-5 col-xs-5">
        
        
                        <button type="submit" id="ESignFINISHBtn" class="btn btn-success btn-sm waves-light" data-dismiss="modal" (click)="recpsignedsignature()" mdbWavesEffect *ngIf="!showInvSignBtn">
                            <span id="InvitetoSignText">FINISH</span></button>
        
                        <label id="exitEsign">EXIT E-SIGN <img src="./assets/img/IARclose x.svg" (click)="exitEsign()"
                                id="IARexitEsign" /> </label>
                    </div>
                </div>
            </div>
            <div class="example-boundary" id="apndchldcdk" >
      
                <div *ngFor="let i of esignlist" class="esign1" (cdkDragEnded)="dragEnd($event,i.id)" [ngStyle]="applyStyles(i)" id="{{i.id}}" style="position:absolute;" cdkDragBoundary=".example-boundary" cdkDrag>
    
                    <div id="signxmark" *ngIf="i.img == 1">
                        <img src="./assets/img/edit.png" width="20px" height="20px">
                    </div>
                    <div id="signxmark" *ngIf="i.img == 2">
                        <img src="./assets/img/date.png" width="20px" height="20px">
                    </div>
                </div>
                <!-- E Sign start -->
                <div *ngFor="let i of Esignmemlist" (cdkDragEnded)="dragEnd($event,i.id)" [ngStyle]="applyStylesforesign(i)" id="{{i.id}}" style="position:absolute;" cdkDragBoundary=".example-boundary" cdkDrag>
                   
                    <div id="signxmark" class="esign1" *ngIf="i.img == 1" >
                        <img src="./assets/img/edit.png" width="20px" height="20px">
                    </div>
                    <div id="signxmark" class="esign1" *ngIf="i.img == 2">
                        <img src="./assets/img/date.png" width="20px" height="20px">
                    </div>
                </div>
    
                <div *ngFor="let i of recipientIconList"  [ngStyle]="applyStylesforRecp(i)" id="{{i.id}}" style="position:absolute;" >
                   
                    <div id="signxmark" class="esign1" (click)="recipientSign(i,EsignTemplate)" *ngIf="i.img == 1" >
                        <img src="./assets/img/edit.png" width="20px" height="20px">
                    </div>
                    <div id="signxmark" class="esign1" (click)="recipientSign(i,EsignTemplate)" *ngIf="i.img == 2">
                        <img src="./assets/img/date.png" width="20px" height="20px">
                    </div>
                </div>
                <!-- E Sign End -->
    
                <!-- self Sign -->
                <div *ngFor="let i of selfesignlist" (cdkDragEnded)="dragEnd($event,i.id)" [ngStyle]="applyStyles(i)" id="{{i.id}}" style="position:absolute;" cdkDragBoundary=".example-boundary" cdkDrag>
    
                    <div *ngIf="i.img == 1">
                        <img  height="60px" src="{{i.val}}">
                    </div>
                    <div *ngIf="i.img == 2">
                        <p>{{i.val}}</p>
                    </div>
                </div>
                <pdf-viewer  src="{{gotfile}}" (after-load-complete)="pageLoaded()" [render-text]="true" [show-borders]="true" [fit-to-page]="true" style=" position: absolute;
                height: 100%;
                background: white;
                width: 98%;">
                </pdf-viewer>
            </div>
       
        </div>
    
        <ng-template #signature let-modal>
            <div class="modal-header">
              <h4 class="modal-title">Add Recipients</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 row">
                <div class="col-md-6">
                    <input [(ngModel)]="RecipientEmail" class="input100" type="text" name="email" placeholder="Recipients Email">
                    <br>
                    <h5 id="addRecText"  (click)="addRecipients()"><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Recipients</h5>
                </div>
                <div class="col-md-6" style="height: 200px;overflow: auto;">
                    <div *ngFor="let user of RecipientsList">
                        {{user}} &nbsp; <i class="fa fa-close" (click)="RemoveRecipient(user)" aria-hidden="true"></i>
                        <hr>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" (click)="EsignView()" (click)="modal.dismiss('Cross click')">Done</button>
            </div>
          </ng-template>
    
    
          <ng-template #EsignTemplate let-modal>
            <div class="modal-header">
              <h4 class="modal-title">E-Signature</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div id="sign-div-content">
                    <form #esignForm="ngForm">
                        <div class="col-md-12 row " id="ThreeOptionsOfEsign">
                            <div class="col-md-4" style="cursor: pointer;color: #9BAAB6;text-align: center;">
                                <a id="draw" (click)="ActiveMenuEsign('1')">DRAW</a>
                                <hr>
                            </div>
                            <div class="col-md-4" style="cursor: pointer;color: #9BAAB6;text-align: center;">
                                <a id="upload" (click)="ActiveMenuEsign('2')">UPLOAD</a>
                                <hr>
                            </div>
                        </div>
                       
                        <br><br><br><br>
                          <!-- DRAW -->
                          <div *ngIf="isDraw">
                          <div class="col-md-12 row"  style="left: -3%;">
                              <div class="col-md-2"></div>
                              <div  class="col-md-8" >
                                  <div>
                                      <button type="button" class="btn btn-sm waves-light" id="drawsign" data-dismiss="modal" mdbWavesEffect>Draw your signature</button>
                                  </div>
                                  <br>
                                  <div id="canv">
                                      <ng-signature-pad id="signpad" doneButtonText="Save" format="base64" (done)="showImage($event)">
                                      </ng-signature-pad>
                                  </div>
                                  <img [src]="signatureImage" *ngIf="signatureImage" style="    width: 480px;
                  height: 85px;
                  margin-top: -10%;" />
                  
                              </div>
                  
                          </div>
                                  </div>
                        <!-- UPLOAD -->
                        <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12 row" *ngIf="!isDraw">
                            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 row">
                                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                                    <div id="upldsignini">
                                        <img src="./assets/img/Choosefrom.png" style="cursor: pointer;margin-top: 15px;" />
                                        <br />
                                        <label id="UploadSignatures">
                                            <p class="temp-upload-icon" for="excel-upload" style="cursor: pointer;">
                                                UPLOAD SIGNATURE</p>
                                            <input #fileInput (click)="fileInput.value = null" type="file"
                                                id='excel-upload' style="display: none"
                                                (change)="UploadESignature($event)" />
                                        </label>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 row">
                                <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12" style="display:flex;justify-content: center;">
                                    <img src="{{uploadedsign}}" alt="" height="50px" id="UploadedSign">
                                </div>
                            </div>
                        </div>
                    </form>
                  </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="FinishSign()" (click)="modal.dismiss('Cross click')">
                    Done
                  </button>
                  <button type="button" class="btn btn-warning" (click)="modal.dismiss('Cross click')">
                    Close
                  </button> 
            </div>
          </ng-template>
    
          
      
    
    </body>
    
    </html>

