
<app-nav-bar></app-nav-bar>

<br>
<br>
<br>

<div class="container">

    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="row col-sm-12">
        <div class="col-sm-9"></div>
        <div class="col-sm-2">
            <button style="background-color: green" class="btn btn-success" data-toggle="modal"
                data-target="#basicExample" mdbWavesEffect>
                Add
            </button>
            <br /><br />
        </div>
    </div>
    <table class="table table-bordered">
        <thead>
            <tr style="text-align: center;">
                <th>Id</th>
                <th>Projects</th>
                <th colspan="3">Action</th>                
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let aa of prolist;index as i">
                <td>{{i+1}}</td>
                <td>{{aa}}</td>
                
                <td>
                    <button  class="btn btn-info" data-toggle="modal" mdbWavesEffect (click)="addInfo(aa)">  UploadData </button>
                </td>
                <!-- <td>
                    <button class="btn btn-info" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                        (click)="editProject(aa)">  Details</button>
                </td> -->
                <td>
                    <button class="btn btn-info" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                        (click)="deleteProject(aa)">Delete</button>
                </td>

            </tr>
        </tbody>
    </table>

    <br>
    <br>


    <div class="modal modal-angular fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center;color: brown;">Create Project</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body text-center">
                    <form #userRoleForm="ngForm">
                        <div class="col-md-12 row">
                            <div class="form-horizontal container">
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Company Name:</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control" name="companyName"   placeholder="Company Name" [(ngModel)]="companyName" />
                                    </div>
                                </div>
                                <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Project Name :</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control mb-4" name="projectName"  placeholder="Project Name" [(ngModel)]="projectName" />
                                    </div>
                                </div>
                                <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Project Objective :</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control " name="projectObjective" placeholder="Project Objective"  [(ngModel)]="projectObjective" />

                                    </div>
                                </div> <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Objective of Analysis :</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control " name="objectiveOfAnalysis"  placeholder="Objective of Analysis" [(ngModel)]="objectiveOfAnalysis" />
                                    </div>
                                </div> <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Project Incharge Name:</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control " name="projectIncharge"  placeholder="Project Incharge Name" [(ngModel)]="projectIncharge" />

                                    </div>
                                </div> <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Conatct Person Name:</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control " name="contactPerson"  placeholder="Conatct Person Name" [(ngModel)]="contactPerson" />
                                    </div>
                                </div> <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Email</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control " name="email"  placeholder="Email" [(ngModel)]="email" />
                                    </div>
                                </div> <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Mobile Number :</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control " name="mobile" placeholder="Mobile Number" [(ngModel)]="mobile" />
                                    </div>
                                </div> <br />
                                <div class="col-md-12 row">
                                    <label class="col-md-4" style="color:black;padding-top: 13px">Company Website :</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control" name="url" placeholder="Company Website"  [(ngModel)]="url" />

                                    </div>
                                </div> <br />
                                 
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
                        mdbWavesEffect>Close</button>
                    <button  (click)="SaveProject()" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect>Save</button>

                    <!-- <button (click)="UpdateEmp()" data-dismiss="modal" type="submit" *ngIf="showupdate"
                        class="btn btn-success btn-sm waves-light" mdbWavesEffect>Update</button> -->

                    <!-- <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
                            (click)="UpdateUserRole(userRoleForm)">Update </button> -->
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-angular fade" id="deleteExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"> -->
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete Role</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					  >
						<span aria-hidden="true">×</span>
					</button>
				</div>
	
				<div class="modal-body text-center">
					<h3 style="text-align: center">Are you sure to delete..??</h3>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-warning" data-dismiss="modal" 
						mdbWavesEffect>Close</button>
					<button  data-dismiss="modal" type="submit" style="background-color: red" class="btn btn-danger" (click)="DeleteRowProject()"
						mdbWavesEffect>Delete</button>
				</div>
			</div>
		</div>
	</div> 
</div>
<br>
<br>
<br>