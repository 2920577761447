import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SignatureService } from '../Shared/E_Signature/signature.service';
import { DocumentViewerService } from '../Shared/E_SignDocumentViewer/document-viewer.service';
import { DrawnESign, FileviewVM } from 'src/app/ViewModels/E_Sign';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dov-viewerfor-mobile',
  templateUrl: './dov-viewerfor-mobile.component.html',
  styleUrls: ['./dov-viewerfor-mobile.component.css']
})
export class DovViewerforMobileComponent implements OnInit,OnDestroy { 
    EsignReceipientsList:any;
    showInvitetoSign:any;
    messagecharacters:any;
    esignEmailMsgtext:any;
    subjectcharacters:any;
    EsignSubject:any;
    thereminderCheckbox:any;
    ShowEsignSendMsgLayout:any;
    Reminders:any;
    selfesignlist:any[]=[];
    Esignmemlist:any[]=[];
    esignlist:any;
    showInvSignBtn:any;
    recpsignaturepage;any;
    esign1: any[];
    esign2: any[];
    esign3: any[];
    SelfSignChecked:any;
    gotfile: string;
    BlockId: any;
    EmailID: any;
    uid: any;
    PdfFile: any;
    docName: any;
    pageOfSign: any;
    font: any;
    avalue: any;
    signs: any;
    type: string;
    cntses: number;
    sign: any;
    pageCount: number;
    pagerect: any;
    pageleft: number;
    pagewidth: number;
    pageheight: number;
    pagetop: number;
    RecipientEmail:any;
    RecipientsList: any[]=[];
    currentesinmemberUID: any;
    i: number;
    ecountint: any;
    dyidfd: any;
    colors: any;
    totalcoord: any;
    selectedUserCodeeee: any;
    selectedCode: any;
    esignBlockid: string;
    esigncoordlist: any;
    filename: any;
    esignids: any[]=[];
    usersigncount: number;
    fileupload: any;
    uploadedsign: string | ArrayBuffer;
    isDraw: boolean;
    isUpload: boolean;
    signatureImage: any;
    recipientIconList: any[]=[];
    currentSigingIcon: any;
    signId: any;
  GUID: string;
  mesigndetails: any;
  
    constructor(private documentViewerService:DocumentViewerService, private router:Router, private route: ActivatedRoute,
      private modalService: NgbModal, private signatureService:SignatureService, private spinner:NgxSpinnerService) {
      this.BlockId = localStorage.getItem("blockId");
      this.EmailID = localStorage.getItem("MailId");
      this.uid = localStorage.getItem("userId");
      this.GUID = localStorage.getItem("GUID");
      this.PdfFile = localStorage.getItem("PdfFile");
      this.cntses = 0;
      this.dyidfd = 0;
      let recipients = localStorage.getItem("PdfblockidRecipients");
      if( recipients !=null && recipients != undefined)
      {
        this.BlockId = recipients;
        this.esignBlockid = localStorage.getItem("esignblockid");
        this.recpsignaturepage = false;
      }
      this.route.params.subscribe(params => {
        if (params["id"]) {
          this.mesigndetails = params["id"];
        }
      })
     }
  
    ngOnInit(): void {
      this.gotopdfView();
    }
  
    ngOnDestroy()
    {
      localStorage.removeItem("retdata");
      localStorage.removeItem("sign");
      localStorage.removeItem("PdfFile");
      localStorage.removeItem("ViewPdffile");
      localStorage.removeItem("retdata");
      localStorage.removeItem("UserDetails");
      localStorage.removeItem("PdfblockidRecipients");
      localStorage.removeItem("esignblockid");
    }
   
    applyStyles(da) {
      const styles = { 'top': da.top + "%", 'left': da.left + "%", 'z-index': 2, 'font-family':da.fntfmly };
      return styles;
    }
  
    applyStylesforesign(da)
    {
      const styles = { 'top': da.top + "%", 'left': da.left + "%", 'z-index': 2, 'font-family':da.fntfmly };
      return styles;
    }
  
    applyStylesforRecp(da)
    {
      const styles = { 'top': da.y + "%", 'left': da.x + "%", 'z-index': 2 };
      return styles;
    }
  
    OnClickReminderCheckbox(a)
    {
  
    }
  
    onSelectReminder(a:any)
    {
  
    }
  
    CloseMsgofEsign()
    {
  
    }
  
    CloseEsign()
    {
  
    }
  
  
  
  dragEnd(a,id)
  {
  
  }
  
  exitEsign()
  {
  
  }
  
  recpsignedsignature() {
    this.filename = localStorage.getItem("originafile");
    var sgncnt = Number(localStorage.getItem("sgndcnt"));
      for (let j = 0; j < this.esigncoordlist.length; j++) {
          let elem = document.getElementById(this.esigncoordlist[j].id).getBoundingClientRect();
        if (this.esigncoordlist[j].toUser == this.uid) {
          this.esigncoordlist[j].width = elem.width;
          this.esigncoordlist[j].height = elem.height;
  
          if (this.esigncoordlist[j].type == 2) {
            this.esigncoordlist[j].esign = true;
            this.esigncoordlist[j].signedon = formatDate(new Date(), 'MM/dd/yyyy', 'en');
  
          }
          else {
            this.esigncoordlist[j].esign = true;
          }
  
        }
      }
  
  
      var esigndata = JSON.parse(JSON.stringify(this.esigncoordlist));
      var deta = {
        DocumentBlockId: this.BlockId,
        OwnerUserId: this.uid,
        ModifiedUserId: this.uid,
        Subject: this.EsignSubject,
        Message: this.esignEmailMsgtext,
        ToUsers: this.EsignReceipientsList,
        ReminderDays: "",
        Coordinates: esigndata,
        Filename: this.filename,
        esignblockid: localStorage.getItem("esignblockid"),
        pdflink: ""
      }
      this.spinner.show();
         this.documentViewerService.savesigneddata(deta).subscribe((respose: any) => {
          this.spinner.hide();
        if (respose == "0") {
          this.router.navigate(['allfolders'])
        }
        else {
          alert("Something Wrong Try Again");
         // this.router.navigate(['docs'])
        }
      });
  
    
  
  }
  
  
  recipientSign(data,content)
  {
    if(data.type == "1")
    {
      this.signId = data.id;
      this.currentSigingIcon = data;
      this.modalService.open(content, { size: 'lg' });
      this.ActiveMenuEsign('1');
    }
    else
    {
      var anothertext = document.createElement('p');
      // let dt = date.getMonth() + "-" + date.getDate() + "-" + date.getFullYear();
      let dt = formatDate(new Date(), 'MM/dd/yyyy', 'en');
      anothertext.innerHTML = dt;
      anothertext.style.fontSize = "25px";
      anothertext.setAttribute("id", data.id);
      anothertext.style.top = data.y + "%";
      anothertext.style.left = data.x + "%";
      anothertext.style.position = "absolute";
      document.getElementById(data.pageID).appendChild(anothertext);
      var anothernode = document.getElementById(data.id);
      anothernode.remove();
    }
  }
  
  next()
  {
  
  }
  
  back()
  {
  
  }
  
  savepdf()
  {
  
  }
  
  SaveEsignDetails() {
    var esigndata = JSON.parse(JSON.stringify(this.Esignmemlist));
    var deta = {
      DocumentBlockId: this.BlockId,
      OwnerUserId: this.uid,
      ModifiedUserId: this.uid,
      Subject: this.EsignSubject,
      Message: this.esignEmailMsgtext,
      ToUsers: this.RecipientsList,
      ReminderDays: "",
      Coordinates: esigndata,
      Filename: this.docName
    }
    this.spinner.show();
      this.documentViewerService.SendEsignMail(deta).subscribe((respose: any) => {
      if (respose == "0") {
        this.spinner.hide();
        this.router.navigate(['/allfolders']);
      }
      else {
       alert("Something Wrong Try Again");
      }
    });
  }
  
  
  
  gotopdfView() {
    this.gotfile = "";
    var aa = this.mesigndetails.split(',');
    localStorage.setItem("userId",aa[2]);
    const inputRequest: FileviewVM = {
      File: "",
      FileName: "",
      BlockId: aa[0],
      Email:  aa[1],
      AliasUserId:  aa[2],
      Fid: "",
      Pdffile: this.PdfFile
    }
      this.documentViewerService.getdocformobileesign(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.gotfile = data.pdfFileName;
        this.docName = data.fileName;
        localStorage.setItem('ViewPdffile', data.pdfFileName);
        localStorage.setItem('Filename', data.file);
        localStorage.setItem('originafile', data.fileName);
        localStorage.setItem('blockid', data.blockId);
        localStorage.setItem("blockId",data.blockId);
        localStorage.setItem("PdfFile",data.blobLink)
        localStorage.setItem("GUID",data.guid); 
      }
    });
  }
  
  selfsign()
  {
    this.showInvitetoSign = true;
    this.SelfSignChecked = true;
    this.getsignature();
  }
  
  getsignature() {
    var b = "";
    const id = {
      data: null,
      uid: this.uid
    }
    var a = "";
     this.documentViewerService.getsignature(id).subscribe((response) => {
      this.sign = response;
      localStorage.setItem("sign", this.sign);
      if (this.sign == null) {
        this.router.navigate(["/esignreg"])
      }
      if (this.sign.substr(0, 10) == "thisistext") {
        //this.sign = this.sign.substr(10, this.sign.length);
        // const signtype = 1;
        this.signs = this.sign.split(",");
        this.avalue = this.signs[1];
        this.font = this.signs[2];
        this.type = "text";
      }
      else if (this.sign.substr(0, 10) == "data:image") {
  
        this.avalue = this.sign;
        this.type = "img";
      }
      else {
        this.router.navigate(["/allfolders"])
      }
      if(this.esignBlockid != null && this.esignBlockid != "undefined")
      {
        this.displayesignbuttons();
      }
  
    });
  }
  
  onTaskesignDrop2(event: CdkDragDrop<Array<any>>, type) {
    var pdwd =  this.pagewidth;
    var pdht = this.pageheight;
    var pdvr = document.getElementById("apndchldcdk").getBoundingClientRect();
    var sc = pdvr.top;
    // console.log(sc);
    var y = sc;
    var ax = 0
    var ay = 0;
    var type;
    var id = event.container.id;
    var rect = document.getElementById(id).getBoundingClientRect();
    var x = Math.abs(event.distance.x);
    var tpnv = document.getElementById("EsignTools").getBoundingClientRect();
    var y = event.distance.y;
  
   // var rctleftic = document.getElementById("ReceipientDropDown").getBoundingClientRect();
  //var rctlft = rctleftic.left + rctleftic.width;
  
    var page = document.getElementsByClassName("page");
    var a = 0;
    var pageid = "";
    this.pageOfSign = -1;
    // var pagetp =  page[0].getBoundingClientRect().top;
    for (let i = 0; i < page.length; i++) {
      var pg = page[i].getBoundingClientRect();
      // console.log(i,pg.top);
  
        var pglft =pg.left ;
       
        var pgrt = document.getElementById(i.toString()).getBoundingClientRect();
  
        var tx = 0
  
  
  
        if (event.distance.x > 0) {
          tx = (x + rect.left + 90);
        }
        else {
          tx = Math.abs(rect.left - x);
        }
  
        console.log(x, rect.left, pgrt.left, pgrt.right);
  
        if (tx > pg.left && tx < pg.right && (y + rect.top) > pg.top && y < pg.bottom) {
          this.pageOfSign = i;
          y = y - pgrt.top;
          //  x=x-rect.left+rctlft;
  
  
  
  
          if (event.distance.x > 0) {
            x = (x + rect.left) - pglft;
          }
          else {
            x = Math.abs(x - (rect.left - pgrt.left));
          }
  
  
          //console.log(i);
          break;
        }
      }
    if (this.pageOfSign != -1) {
  
      ax = ((((x) / (pdwd)) * 100)) + 1;
      ay = (((y) / (pdht) * 100)) + 1;
      var setty;
      this.cntses += 1;
      if (type == 1) {
        if (this.type == "img") {
          this.avalue = this.sign;
          setty = 1;
        }
        else {
          this.avalue = this.signs[1];
          this.font = this.signs[2];
          setty = 2;
        }
      }
      else {
        setty = 2;
        this.avalue = formatDate(new Date(), 'MM/dd/yyyy', 'en');
        if (this.signs != null || this.signs != undefined) {
          this.font = this.signs[2];
        }
        else {
          this.font = "Arial Verdana";
        }
      }
  
  
  
      var newPos2 = {
        id: "SE" + this.cntses,
        top: ay,
        left: ax,
        img: setty,
        fntfmly: this.font,
        iid: this.pageOfSign,
        val: this.avalue
      };
      this.selfesignlist.push(newPos2);
  
      if (this.selfesignlist.length != 0) {
        document.getElementById("ESignFINISHBtn").style.backgroundColor = "#4F44C5";
        document.getElementById("ESignFINISHBtn").style.color = "#ffffff";
        document.getElementById("ESignFINISHBtn").style.pointerEvents = 'auto';
        document.getElementById("ESignFINISHBtn").style.cursor = 'pointer';
      }
  
      setTimeout(() => {
        var elm = document.getElementById(newPos2.id);
        elm.style.display = "block";
        //var elem = document.getElementById(id);
        //   elem.parentElement.removeChild(elem);
        document.getElementById(this.pageOfSign).appendChild(elm);
  
      }, 100);
    }
  }
  
  pageLoaded()
  {
    setTimeout(() => {
    var pg = document.getElementsByClassName("page");
    this.pageCount = pg.length;
    for (var i = 0; i < pg.length; i++) {
      pg[i].id = i.toString();
      var pgrct = pg[i].getBoundingClientRect();
    }
    if(pg.length != 0){
    var pgrtcv = pg[0].getBoundingClientRect();
    this.pageleft = pgrtcv.left;
    this.pagewidth = pgrtcv.width;
    this.pageheight = pgrtcv.height;
    this.pagetop = pgrtcv.top;
    }
  
    if(this.esignBlockid!=null)
    {
      this.getsignaturebuttons(this.BlockId);
    }
  
  }, 2000);
  }
  
  
  selfSignsave()
  {
    if (this.selfesignlist.length > 0) {
      var data = JSON.stringify(this.selfesignlist);
      var fnm = localStorage.Filename;
      this.spinner.show();
       this.documentViewerService.storecord1(data, fnm).subscribe((response: any) => {
        this.spinner.hide();
        if(response == "1")
        {
          alert("Signed Successfully");
          this.router.navigate(['allfolders']);
        }
        else
        {
          alert("Something went wrong!!!");
          this.router.navigate(['allfolders']);
        }
       });
      }
  }
  
  openModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }
  
  addRecipients()
  {
    this.RecipientsList.push(this.RecipientEmail);
  }
  
  RemoveRecipient(id)
  {
    const index = this.RecipientsList.indexOf(id, 0);
  if (index > -1) {
     this.RecipientsList.splice(index, 1);
  }
  }
  
  
  onTaskeesignDrop3(event: CdkDragDrop<Array<any>>, signtype) {
    var page = document.getElementsByClassName("page");
    var pdwd = this.pagewidth;
    var pdht = this.pageheight;
    // console.log(pdwd,pdht);
  
    for (let i = 0; i < this.RecipientsList.length; i++) {
            if (this.RecipientsList[i]== this.currentesinmemberUID) {        
        this.i = i;
        break;
      }
    }
    
    
      var pdvr = document.getElementById("apndchldcdk").getBoundingClientRect();
    var sc = pdvr.top;
    var y = sc;
    var ax = 0
    var ay = 0;
    var type;
    var id = event.container.id;
    var rect = document.getElementById(id).getBoundingClientRect();
    var x = Math.abs(event.distance.x);
    var tpnv = document.getElementById("EsignTools").getBoundingClientRect();
    var y = event.distance.y;
  
   var page = document.getElementsByClassName("page");
    var a = 0;
    var pageid = "";
    this.pageOfSign = -1;
  
      for (let i = 0; i < page.length; i++) {
      var pg = page[i].getBoundingClientRect();
      // console.log(i,pg.top);
  
        var pglft =pg.left ;
       
        var pgrt = document.getElementById(i.toString()).getBoundingClientRect();
  
        var tx = 0
  
  
  
        if (event.distance.x > 0) {
          tx = (x + rect.left + 90);
        }
        else {
          tx = Math.abs(rect.left - x);
        }
  
        console.log(x, rect.left, pgrt.left, pgrt.right);
  
        if (tx > pg.left && tx < pg.right && (y + rect.top) > pg.top && y < pg.bottom) {
          this.pageOfSign = i;
          y = y - pgrt.top;
  
          if (event.distance.x > 0) {
            x = (x + rect.left) - pglft;
          }
          else {
            x = Math.abs(x - (rect.left - pgrt.left));
          }
  
  
          //console.log(i);
          break;
        }
      }
  
    if (this.pageOfSign != -1) {
  
      ax = ((((x) / (pdwd)) * 100)) + 1;
      ay = (((y) / (pdht) * 100)) + 1;
      var setty;
      var imgp = "";
      if (signtype == 1) 
      {
        type = 1;
        imgp = "1";
      }
      else
      {
        type = 2;
        imgp = "2";
      }
  
      var recp = this.currentesinmemberUID;
      this.dyidfd = (this.dyidfd + 1);
      var newesi = {
        numid: this.ecountint,
        img: imgp,
        id: "e" + this.dyidfd,
        top: ay,
        left: ax,
        backgroundColor: "black",
        type: type,
        selecteduid: recp,
        iid: this.pageOfSign,
        signedon: null,
        esign: false
      };
  
      this.Esignmemlist.push(newesi);
  
  
      this.totalcoord = this.Esignmemlist;
      console.log(this.Esignmemlist);
      this.ecountint = this.ecountint + 1;
  
      setTimeout(() => {
        var elm = document.getElementById(newesi.id);
        elm.style.display = "block";
        document.getElementById(this.pageOfSign).appendChild(elm);
  
      }, 100);
     }
  
  
  }
  EsignView()
  {
    this.SelfSignChecked = false;
    this.showInvitetoSign = true;
  }
  
  someMethod(event) {
    let data = (<HTMLInputElement>event.target).value;
    this.selectedCode=data;
    
  this.currentesinmemberUID = data
  
    var emaill = data;
  
  
      this.selectedUserCodeeee = data;
  }
  
  getsignaturebuttons(blockid: any) {
    let data =
    {
      pdfblockid: blockid,
      userid: this.uid,
      esignblockid: localStorage.getItem("esignblockid")
    };
      this.documentViewerService.getusersignaturebuttons(data).subscribe((data: any) => {
      if (data != null) {
        this.esigncoordlist = data;
        console.log(data);
       // this.getsignature();
       this.displayesignbuttons();
      }
    });
  }
  
  
  
  displayesignbuttons() {
  
  
  var esigncoordlist = this.esigncoordlist;
  
  for (let j = 0; j < esigncoordlist.length; j++) {
  
    if(esigncoordlist[j].toUser == this.uid)
  {
    esigncoordlist[j].id = "recp"+j;
  
    this.recipientIconList.push(esigncoordlist[j]);
  
    setTimeout(() => {
      var elm = document.getElementById(esigncoordlist[j].id);
      elm.style.display = "block";
      document.getElementById(esigncoordlist[j].pageID).appendChild(elm);
  
    }, 100);
  }
  }
  
  
  }
  
  getUnique(array){
    var uniqueArray = [];
    
    // Loop through array values
    for(let i=0; i < array.length; i++){
        if(uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
        }
    }
    return uniqueArray;
  }
  
  
  
  ActiveMenuEsign(data: any) {
   if (data == 1) {
  
    this.isDraw = true;
      document.getElementById("draw").style.color = "#4F44C5";
      document.getElementById("upload").style.color = "#9BAAB6";
  
      document.getElementById("draw").style.borderBottom = "3px solid rgb(79, 68, 197)";
      document.getElementById("upload").style.borderBottom = "none";
  
      
      document.getElementById("draw").style.fontSize = "13px";
      document.getElementById("upload").style.fontSize = "13px";
  
      document.getElementById("draw").style.lineHeight = "12px";
      document.getElementById("upload").style.lineHeight = "12px";
  
      document.getElementById("draw").style.lineHeight = "0.1em";
      document.getElementById("upload").style.lineHeight = "0.1em";
  
        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-primary ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-16%';
            (<HTMLElement>b[i]).style.marginLeft = '104%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
        }, 1);
  
  
        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-default ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-57%';
            (<HTMLElement>b[i]).style.marginLeft = '104%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
          var element = document.getElementById("canv");
          var sign = element.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var sign1 = element.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var sign2 = element.getElementsByTagName("canvas")[0].style.paddingRight = "10%";
          var sign3 = element.getElementsByTagName("canvas")[0].style.height = "85px";
          var sign4 = element.getElementsByTagName("canvas")[0].style.width = "439px";//469
          var sign5 = element.getElementsByTagName("canvas")[0].style.marginTop = "-2%";
  
          var ele = document.getElementById("canvone");
          var initial = ele.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var initial1 = ele.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var initial2 = ele.getElementsByTagName("canvas")[0].style.paddingLeft = "2%";
          var initial3 = ele.getElementsByTagName("canvas")[0].style.marginLeft = "9%";
          var initial4 = ele.getElementsByTagName("canvas")[0].style.height = "85px";
          var initial5 = ele.getElementsByTagName("canvas")[0].style.width = "413px";//438
          var initial6 = ele.getElementsByTagName("canvas")[0].style.marginTop = "-2%";
        }, 1);
  
      
   
  
    }
    else{
  
      this.isDraw = false;
      document.getElementById("draw").style.color = "#9BAAB6";
      document.getElementById("upload").style.color = "#4F44C5";
  
      document.getElementById("draw").style.borderBottom = "none";
      document.getElementById("upload").style.borderBottom = "3px solid rgb(79, 68, 197)";
  
      document.getElementById("draw").style.fontSize = "13px";
      document.getElementById("upload").style.fontSize = "13px";
  
      document.getElementById("draw").style.lineHeight = "12px";
      document.getElementById("upload").style.lineHeight = "12px";
  
      document.getElementById("draw").style.lineHeight = "0.1em";
      document.getElementById("upload").style.lineHeight = "0.1em";
  
  
    }
  }
  
  
  UploadESignature(event: any) {
  
    this.fileupload = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.fileupload);
    reader.onload = () => {
      this.uploadedsign = reader.result; 
    };
  }
  
  FinishSign()
  {
  
    
    if(this.isDraw) {
  
      var im = document.createElement("img");
      im.setAttribute("src", this.signatureImage);
      im.style.height = "60px";
      im.style.position = "absolute";
      im.setAttribute("id",this.currentSigingIcon.id);
      im.style.top = this.currentSigingIcon.y + "%";
      im.style.left = this.currentSigingIcon.x + "%";
  
      document.getElementById(this.currentSigingIcon.pageID).appendChild(im);
      var removenode = document.getElementById(this.currentSigingIcon.id);
      removenode.remove();
  
      const inputRequest: DrawnESign = {
        CustomerId: this.uid,
        CustomerFullName: "",
        CustomerInitials: "",
        DrawnESign: this.signatureImage,
        DrawnEInitial: "",
        ipaddress: "",
        dnsip: "",
        browser: this.getBrowserName(),
        transaction: "Edited E-Signature using Draw Option"
      }
      this.spinner.show();
        this.signatureService.PostCustomerDrawSign(inputRequest).subscribe(
        data => {
          this.spinner.hide();
          if (data != "0") {
            for(let i=0;i<this.esigncoordlist.length;i++)
            {
              if(this.esigncoordlist[i].id == this.signId)
              {
                this.esigncoordlist[i].regId = data;
              }
            }
          }
        });
    }
  
    else
    {
     //let data = this.getBase64(this.fileupload);
    // console.log(data);
      var im = document.createElement("img");
      //im.setAttribute("src", data);
      im.style.height = "60px";
      im.style.position = "absolute";
      im.setAttribute("id",this.currentSigingIcon.id);
      im.style.top = this.currentSigingIcon.y + "%";
      im.style.left = this.currentSigingIcon.x + "%";
      const reader = new FileReader();
      reader.onload = (e: any) => {
        im.setAttribute("src", e.target.result);
      }
      reader.readAsDataURL(this.fileupload);
  
      document.getElementById(this.currentSigingIcon.pageID).appendChild(im);
      var removenode = document.getElementById(this.currentSigingIcon.id);
      removenode.remove();
      this.spinner.show();
      this.signatureService.postESignature(this.uid, this.fileupload).subscribe(
        data => {
          this.spinner.hide();
          if (data != "0") {
            for(let i=0;i<this.esigncoordlist.length;i++)
            {
              if(this.esigncoordlist[i].id == this.signId)
              {
                this.esigncoordlist[i].regId = data;
              }
            }
            }
  
        });
    }
  }
  
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  
  showImage(data) {
    this.signatureImage = data;
  }
  
  
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "Edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "Opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "Chrome";
      case agent.indexOf("trident") > -1:
        return "IE";
      case agent.indexOf("firefox") > -1:
        return "Firefox";
      case agent.indexOf("safari") > -1:
        return "Safari";
      default:
        return "Other";
    }
  }
  
  
  }