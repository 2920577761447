
        
    
      <div class="fabs">
        <div class="chat">
          <div class="chat_header">
            <div class="chat_option">
           
              <span id="chat_head" text-align="center">Support(Online)</span> <br>
             <span id="chat_fullscreen_loader" class="chat_fullscreen_loader"><i class="fullscreen zmdi zmdi-window-maximize"></i></span>
      
            </div>
      
          </div>
    
    
          <div id="chat_form" class="chat_body chat_login chat_converse chat_form">
         
            <span class="chat_msg_item chat_msg_item_admin">Welcome to Blocdrive! We are excited to offer you messaging,
               allowing you to respond to us if you have any queries.</span>
            
            <div *ngFor="let c of chatdetail">
            <span class="chat_msg_item chat_msg_item_admin"  *ngIf="c.guid != GUID" >
              {{c.chat}}</span>
            
            <span class="chat_msg_item chat_msg_item_user" *ngIf="c.guid == GUID"  >
              {{c.chat}}</span>
            </div>
           
          </div>
     
          <div class="fab_field">
            
             <a id="fab_send" class="fab">
              <i class="fa fa-paper-plane" (click)="SendIndividualMessage()" aria-hidden="true"></i>
             </a> 
             <!-- <button type="button" id="fab_send" class="fab" aria-label="Send btnn"  style="border: none;">
              <i class="fa fa-paper-plane" (click)="SendIndividualMessage()" aria-hidden="true"></i>
             </button> -->
            <textarea id="chatSend" name="chat_message" placeholder="Send a message" class="chat_field chat_message" [(ngModel)]="chatSend" ></textarea>
          </div>
        </div>
           <a class="fab" id="prime" ><i class="fa fa-commenting" aria-hidden="true"></i></a>
          <!-- <button type="button" class="fab" aria-label="Chat Icon"  style="border: none;" ><i class="fa fa-commenting" aria-hidden="true" ></i></button> -->
      </div>
        <script src='http://code.jquery.com/jquery-1.11.3.min.js'></script>
      
          <script src="js/index.js"></script>
      
  
      