
<div>&nbsp;</div>
<div>&nbsp;</div>

<div id="mySidenav" class="sidenav" style="z-index: 2">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>

    <a  href="#/home" style="color: white;">Home</a>
   <a href="#/login" style="color: white;" >Login</a>
   <a  href="#/registration" style="color: white;">Signup</a>
  
  </div>
  
  <div id="main">
    <span style="font-size: 30px; cursor: pointer;" (click)="openNav()"
      ><button type="button" class="btn btn-outline-secondary waves-effect" *ngIf="sidebaropen" style="color: black;">
        <i class="fas fa-bars" style="font-size: 25px;"></i>
      </button></span>
  </div>
  