import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { TaskService } from '../Shared/Task/task.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { addTask } from '../ViewModels/addTask';
import { Priority } from '../ViewModels/Priority';
import { TaskType } from '../ViewModels/TaskType';
import { ProjectChangeService } from '../Shared/projectchangemaster/project-change.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { AuditorTransactionService } from '../Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css']
})
export class AddTaskComponent implements OnInit {
  isLoggedIn: boolean;
  uid: string;
  username: string;
  projectlist: any;
  changeList: any;
  moduleList: any;
  Teamtask: any;
  projectid: any;
  closeResult: string;
  prioritylist: any = [];
  issuetypelist: any = [];
  taskName: any;
  selectedtype: any;
  selectedpriority: any;
  summary: any;
  components: string;
  estimatedtime: string;
  detaileddescription: any;
  editid: any;
  DeleteName: any;
  deleteid: any;
  editprojectid: string;
  editmoduleid: string;
  edittaskName: string;
  edittasktype: string;
  editpriority: string;
  editsummary: string;
  editcomponents: string;
  editchange: string;
  editestimatedtime: string;
  editdetaileddescription: string;
  editissuetype: any;
  Module: any;
  Change: any;
  public dismissalert = false;
  notify: string
  p: number = 1;
  roleid: string;
  mobileview: boolean;
  GUID: string;
  constructor(private router: Router, private taskservice: TaskService, private modalService: NgbModal,
    private auditortransactionservice:AuditorTransactionService, private projectchangeservice: ProjectChangeService, private spinner: NgxSpinnerService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu()
  }

  ngOnInit(): void {
    var prioritymajor: Priority = {
      PriorityId: 1,
      PriorityName: "Major"
    }
    this.prioritylist.push(prioritymajor);
    var prioritycritical: Priority = {
      PriorityId: 2,
      PriorityName: "Critical"
    }
    this.prioritylist.push(prioritycritical);
    var priorityminor: Priority = {
      PriorityId: 3,
      PriorityName: "Minor"
    }
    this.prioritylist.push(priorityminor);

    // Task Type
    var typeissue: TaskType = {
      TaskTypeId: 1,
      TaskTypeName: "Issue"
    }
    this.issuetypelist.push(typeissue);
    var typetask: TaskType = {
      TaskTypeId: 2,
      TaskTypeName: "Task"
    }
    this.issuetypelist.push(typetask);
    var typebug: TaskType = {
      TaskTypeId: 3,
      TaskTypeName: "Bug"
    }
    this.issuetypelist.push(typebug);
    var typenewfeature: TaskType = {
      TaskTypeId: 4,
      TaskTypeName: "New Feature"
    }
    this.issuetypelist.push(typenewfeature);
    this.ProjectName();
    this.GetTask();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };
  ProjectName() {
    this.spinner.show();
    this.taskservice.getTeamProject(this.GUID).subscribe((response: any) => {
      this.projectlist = response;
    })
  }

  GetTask() {
    this.spinner.show();
    this.taskservice.gettask(this.GUID).subscribe((response: any) => {
      this.Teamtask = response;
      this.spinner.hide();

    })
  }
  onSelectedProject(Id: number) {
    this.projectid = Id;
    this.spinner.show();
    this.taskservice.getChangeDetails(this.projectid).subscribe((response: any) => {
      this.changeList = response.changelist;
      this.moduleList = response.modulelist;
      this.spinner.hide();

    })
  }
  onSelectedmoduleName(Id) {
    this.Module = Id;
  }
  onSelectchange(Id) {
    this.Change = Id;
  }
  onSelecttype(id) {
    this.selectedtype = id;
  }
  onSelectaPriority(id) {
    this.selectedpriority = id;
  }
  addTask(subfoldercontent) {
    this.modalService.open(subfoldercontent).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  Savetaskdata() {
    const inputRequest: addTask = {
      ID: 0,
      ProjectName: this.projectid,
      Module: this.Module,
      Issue: this.taskName,
      IssueType: this.selectedtype,
      Priority: this.selectedpriority,
      Summary: this.summary,
      Components: this.components,
      ChangeId: this.Change,
      EstimatedTime: this.estimatedtime,
      DetailedDescription: this.detaileddescription,
      IsEnabled: true,
      UserId: this.uid,
      GUID:this.GUID

    }
    this.spinner.show();
    this.taskservice.saveTask(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.notify = "Task Added Succesfully"
        const audit = {
          TransactionName:"Project Management",
          TransactionType:"Create",
          TransactionDesc:"Task",

          }
          this.auditortransactionservice.AuditorDetailsProject(audit);
        this.projectid = null;
        this.Module = null;
        this.Change = null
        this.selectedtype = null
        this.selectedpriority = null;
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.GetTask();
        this.spinner.hide();
      }
      else {
        alert("Something went wrong");
        this.projectid = null;
        this.Module = null;
        this.Change = null
        this.selectedtype = null
        this.selectedpriority = null;
      }
    });
  }

  EditTask(updatedata, updatetask) {
    this.editid = updatedata.id
    this.editprojectid = updatedata.projectId,
      this.editmoduleid = updatedata.moduleId,
      this.edittaskName = updatedata.issue,
      this.editissuetype = updatedata.issueTypeId,
      this.editpriority = updatedata.priorityId,
      this.editsummary = updatedata.summary,
      this.editcomponents = updatedata.components,
      this.editchange = updatedata.projectChangeId,
      this.editestimatedtime = updatedata.estimatedTime,
      this.editdetaileddescription = updatedata.detailedDescription,
      this.onSelectedProject(updatedata.projectId);
    this.modalService.open(updatetask).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }
  deleteTask(deletedata, deletetask) {
    this.DeleteName = deletedata.issue;
    this.deleteid = deletedata.id;
    this.modalService.open(deletetask).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  UpdateTask() {
    if (this.projectid == null || this.projectid == undefined) {
      this.projectid = this.editprojectid
    }
    if (this.Module == null || this.Module == undefined) {
      this.Module = this.editmoduleid
    }
    if (this.Change == null || this.Change == undefined) {
      this.Change = this.editchange
    }
    if (this.selectedtype == null || this.selectedtype == undefined) {
      this.selectedtype = this.editissuetype
    }
    if (this.selectedpriority == null || this.selectedpriority == undefined) {
      this.selectedpriority = this.editpriority
    }
    const inputRequest: addTask = {
      ID: this.editid,
      ProjectName: this.projectid,
      Module: this.Module,
      Issue: this.edittaskName,
      IssueType: this.selectedtype,
      Priority: this.selectedpriority,
      Summary: this.editsummary,
      Components: this.editcomponents,
      ChangeId: this.Change,
      EstimatedTime: this.editestimatedtime,
      DetailedDescription: this.editdetaileddescription,
      IsEnabled: true,
      UserId: this.uid,
      GUID:this.GUID

    }
    this.spinner.show();
    this.taskservice.UpdateTask(inputRequest).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Task Updated Succesfully"
          const audit = {
            TransactionName:"Project Management",
            TransactionType:"Update",
            TransactionDesc:"Task",

            }
            this.auditortransactionservice.AuditorDetailsProject(audit);
          this.projectid = null;
          this.Module = null;
          this.Change = null
          this.selectedtype = null
          this.selectedpriority = null;
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetTask();
          this.spinner.hide();
        } else {
          this.notify = "Something went wrong.Please Try again.!!"
          this.projectid = null;
          this.Module = null;
          this.Change = null
          this.selectedtype = null
          this.selectedpriority = null;
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetTask();
          this.spinner.hide();
        }
      });
  }
  DeleteTaskRow() {
    this.spinner.show();
    var deleteiduid = this.deleteid + "," + this.uid
    this.taskservice.deleteTask(deleteiduid).subscribe(
      (data: any) => {
        if (data == "success") {
          this.notify = "Task deleted succesfully"
          const audit = {
            TransactionName:"Project Management",
            TransactionType:"Delete",
            TransactionDesc:"Task",

            }
            this.auditortransactionservice.AuditorDetailsProject(audit);
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetTask();
          this.spinner.hide();

        } else {
          this.notify = "Something Went Wrong. Try again.!!"
          setTimeout(() => this.dismissalert = true, 100);
          setTimeout(function () {
            this.dismissalert = false;
          }.bind(this), 3000);
          this.GetTask();
          this.spinner.hide();
        }
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  closealert() {
    this.dismissalert = false;
  }
  sortuserdate = "1";
  
  sortuserbydate() {
    if (this.sortuserdate == "1") {
      this.Teamtask.sort((a, b) => a.taskStartDate < b.taskStartDate ? -1 : a.taskStartDate > b.taskStartDate ? 1 : 0)
      this.sortuserdate = "2";
    }
    else if (this.sortuserdate == "2") {
      this.Teamtask.sort((a, b) => a.taskStartDate > b.taskStartDate ? -1 : a.taskStartDate < b.taskStartDate ? 1 : 0)
      this.sortuserdate = "1";
  
    }
   
  }
}
