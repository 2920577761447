import { Component, OnInit } from '@angular/core';
import { OutwardService } from 'src/app/Shared/Outward/outward.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/Shared/Project/project.service';
import { DownloadFileVM } from 'src/app/ViewModels/DownloadFileVM';
import { UserDetailsService } from 'src/app/Shared/UserDetails/user-details.service';
import { ClientService } from 'src/app/Shared/Client/client.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-outwarded-projects',
  templateUrl: './outwarded-projects.component.html',
  styleUrls: ['./outwarded-projects.component.css']
})
export class OutwardedProjectsComponent implements OnInit {
  show: any = false;
  ProjectList: any;
  isLoggedIn: boolean;
  uid: string;
  username: string;
  p: number = 1;
  downpath: any;
  notify: string;
  dismissalert: boolean=false;
  showhidevalue: boolean;
  ProjectFileList: any;
  ViewDoc: boolean;
  UserList: any;
  ClientList: any;
  HistoryofProject: any;
  roleid: string;
  mobileview: boolean;
  GUID: any;
  constructor(private outwardservice: OutwardService, private spinner: NgxSpinnerService,private router:Router,private projectservice:ProjectService,
    private userservice:UserDetailsService,private clientService:ClientService) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("UserFullName");
      this.roleid = localStorage.getItem("role");
    }
    this.isMobileMenu();
  }

  ngOnInit(): void {
    this.GetMyProjects();
    this.Users();
    this.Clients();
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
       this.mobileview = false;
    }
    else {
      this.mobileview= true;
    }
  };

  GetMyProjects() {
    this.spinner.show();
    this.outwardservice.Getmyprojects(this.uid).subscribe((data: any) => {
      this.ProjectList = data;
      this.spinner.hide();
    })
  }
  Users() {
    this.spinner.show();
    this.userservice.getAllComapnyUsers().subscribe((Data: any) => {
      this.UserList = Data;
      this.spinner.hide();
    });
  }
  gotopdfViewForAssignTask(file) {
    localStorage.removeItem('BlockId');
    localStorage.removeItem('EmailView');
    localStorage.removeItem('Pdffile');
    localStorage.removeItem('Fid');
    localStorage.removeItem('DocType'); 
    localStorage.removeItem('UploadedDate');
    localStorage.removeItem('FileType');
    localStorage.removeItem('IsAssignTaskDoc');

    console.log(file);
    this.spinner.show();
    localStorage.setItem('Fileview', file.file);
    localStorage.setItem('FileNameView', file.documentName);
    localStorage.setItem('BlockId', file.blockId);
    localStorage.setItem('Pdffile', file.pdfFile);
    localStorage.setItem('Fid', file.folderId);
    localStorage.setItem('DocType', file.documentType); 
    localStorage.setItem('UploadedDate', file.date);
    localStorage.setItem('FileType', "false");
   localStorage.setItem('IsAssignTaskDoc', "true");
    this.router.navigate(['projectdocs/'])
  }
  DownloadFile(ret) {
    const inputRequest: DownloadFileVM = {
      Id: null,
      AUID: this.uid,
      FolderId: null,
      FolderName:null,
      BlockID: ret.blockId,
      GUID:this.GUID
    }
    this.spinner.show();
    this.projectservice.downloadFile(inputRequest).subscribe((data: any) => {
      if (data != null) {
        this.downpath = data;
        window.open(this.downpath);
        this.spinner.hide();
      }
      else {
        this.notify = "Something went wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
    });
  
  }
  viewallprojects(){
    this.showhidevalue=true
  }
  ViewProjectFiles(id){
    this.spinner.show();
    var piduserid =id.uniqueProjectId + "," + this.uid;
    this.projectservice.getProjectFiles(piduserid).subscribe((Data: any) => {
      this.ProjectFileList = Data;
      this.spinner.hide();
    })
    this.ViewDoc=true;
  }
  GetUser(userid) {
    for (let i = 0; i < this.UserList.length; i++) {
      if (userid == this.UserList[i].userId) {
        return this.UserList[i].userName;
      }
    }
  }
  GetClient(cid) {
    for (let i = 0; i < this.ClientList.length; i++) {
      if (cid == this.ClientList[i].id) {
        return this.ClientList[i].clientName;
      }
    }
  }
  closealert() {
    this.dismissalert = false;
  }
  Clients() {
     this.spinner.show();
    this.clientService.getAllClients(this.uid).subscribe((Data: any) => {
      this.ClientList = Data;
      this.spinner.hide();
    });
  }
  History(history){
  localStorage.setItem("ProjectHistoryId",history.blockId);
  this.router.navigate(['workflowhistory/']);
  }

}
