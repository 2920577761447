
<app-nav-bar></app-nav-bar>

<br>

<div class="container exceluploadmedia">

    <div class="col-lg-12 col-md-12" style="padding-top:4cm; width:100%" *ngIf="fileselect">
        <div style="background-color:aliceblue;border:solid;">

            <div class="container col-lg-12" style="text-align:center;padding-bottom:3cm;padding-top:2em;">
                <div class="row">
                    <h2>
                        <span style="color:black;font-family:'Baskerville Old Face';font-size:36px;text-align:center">
                            Select The Type Of Data You Want To Restore To The Project For Analysis</span>
                    </h2>
                </div>
            </div>

            <div class="row" style="padding-bottom:2cm;text-align:center;">
                <div class="col-lg-3 col-md-3">
                    <button style="background-color:#ff8000 " class="btn btn-info" data-toggle="modal" mdbWavesEffect
                        (click)="selectExcelfile()"> Excel Upload </button>

                    <!-- <button class="btn-lg btn-info" ng-click="selectExcelfile()">
                Excel Upload
            </button> -->
                </div>
                <div class="col-lg-3 col-md-3">
                    <button style="background-color:#ff8000 " class="btn btn-info" data-toggle="modal" mdbWavesEffect
                        (click)="selectCsvfile()"> CSV Upload </button>
                    <!-- <button class="btn-lg btn-info" ng-click="selectCsvfile()">
                Csv Upload
            </button> -->
                </div>
                <div class="col-lg-3 col-md-3">
                    <button style="background-color:#ff8000 " class="btn btn-info" data-toggle="modal" mdbWavesEffect
                        (click)="selectSqlfile()"> Sql Restore </button>
                    <!-- <button class="btn-lg btn-info" ng-click="selectSqlfile()">Sql Restore</button> -->
                </div>
                <div class="col-lg-3 col-md-3">
                    <button style="background-color:#ff8000 " class="btn btn-info" data-toggle="modal" mdbWavesEffect
                        (click)="selectMysqlfile()"> Mysql Resotre</button>
                    <!-- <button class="btn-lg btn-info" ng-click="selectMysqlfile()"> Mysql Resotre</button> -->
                </div>
            </div>

        </div>

    </div>
    <div class="col-sm-12" *ngIf="excel">

        <div class="row col-sm-12">
            <div class="col-sm-9"></div>
            <div class="col-sm-2">
                <button style="background-color: green" class="btn btn-success" data-toggle="modal"
                    data-target="#excelpopup" mdbWavesEffect>
                    Add
                </button>
                <br /><br />
            </div>
        </div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Projects</th>
                    <th colspan="3">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let aa of Files;index as i">
                    <td>{{i+1}}</td>
                    <td>{{aa}}</td>

                    <td>
                        <button class="btn btn-info" data-toggle="modal" mdbWavesEffect (click)="viewExcel(aa)"> View
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-info" data-toggle="modal" mdbWavesEffec (click)="uploadexceldata(aa)">
                            UploadData</button>
                    </td>


                </tr>
            </tbody>
        </table>

        <br>
        <br>


        <div class="modal modal-angular fade" id="excelpopup" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center;color: brown;">Add
                            Attachment</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body text-center">
                        <form #userRoleForm="ngForm">
                            <div class="col-md-12 row">
                                <div class="form-horizontal container">
                                    <div>
                                        <label>Upload Type : Excel</label>


                                        <input style="padding: 13px;" type="file" accept="image/*" name="avatars"
                                            #fileField (change)="onChange($event)">

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
                            mdbWavesEffect>Close</button>
                        <button (click)="Uploadexcel()" data-dismiss="modal" type="submit"
                            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Save</button>

                        <!-- <button (click)="UpdateEmp()" data-dismiss="modal" type="submit" *ngIf="showupdate"
                            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Update</button> -->

                        <!-- <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
                                (click)="UpdateUserRole(userRoleForm)">Update </button> -->
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div class="col-sm-12" *ngIf="csv">

        <div class="row col-sm-12">
            <div class="col-sm-9"></div>
            <div class="col-sm-2">
                <button style="background-color: green" class="btn btn-success" data-toggle="modal"
                    data-target="#csvpopup" mdbWavesEffect>
                    Add
                </button>
                <br /><br />
            </div>
        </div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Projects</th>
                    <th colspan="3">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let aa of Files;index as i">
                    <td>{{i+1}}</td>
                    <td>{{aa}}</td>
                    <td>
                        <button class="btn btn-info" data-toggle="modal" mdbWavesEffect (click)="viewExcel(aa)"> View
                        </button>
                    </td>
                    <!-- <td>
                        <button style="background-color:#ff8000 " class="glyphicon glyphicon-plus text-info btn btn-success" data-toggle="modal"
                            data-target="#basicExample" mdbWavesEffect (click)="addInfo(aa)">  <span class="glyphicon glyphicon-plus text-info">UploadData</span> UploadData </button>
                    </td>
                    <td>
                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                            (click)="editProject(aa)">   <span class="glyphicon glyphicon-edit text-success">Details</span></button>
                    </td>
                    <td>
                        <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                            (click)="DeleteEMP(aa)"> <span class="glyphicon glyphicon-trash text-danger">Delete</span></button>
                    </td>
     -->
                </tr>
            </tbody>
        </table>

        <br>
        <br>


        <div class="modal modal-angular fade" id="csvpopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center;color: brown;">ADD CSV
                        </h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body text-center">
                        <form #userRoleForm="ngForm">
                            <div class="col-md-12 row">
                                <div class="form-horizontal container">

                                    <div>
                                        <label>Upload Type : CSV</label>


                                        <input style="padding: 13px;" type="file" accept="image/*" name="avatars"
                                            #fileField (change)="onChange($event)">

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
                            mdbWavesEffect>Close</button>
                        <button (click)="Uploadcsv()" data-dismiss="modal" type="submit"
                            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Save</button>

                        <!-- <button (click)="UpdateEmp()" data-dismiss="modal" type="submit" *ngIf="showupdate"
                            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Update</button> -->

                        <!-- <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
                                (click)="UpdateUserRole(userRoleForm)">Update </button> -->
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="col-sm-12" *ngIf="mssql">

        <div class="row col-sm-12">
            <div>
                <label>Upload Type : SQL</label>


                <input style="padding: 13px;" type="file" accept="image/*" name="avatars"
                    #fileField (change)="onChange($event)">

            </div>
            <button (click)="Uploadmssql()" data-dismiss="modal" type="submit"
            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Restore</button>
            <!-- <div class="col-sm-9"></div>
            <div class="col-sm-2">
                <button style="background-color: green" class="btn btn-success" data-toggle="modal"
                    data-target="#mssqlpopup" mdbWavesEffect>
                    Add
                </button>
                <br /><br />
            </div> -->
        </div>
     
        <br>
        <br>


        <div class="modal modal-angular fade" id="mssqlpopup" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center;color: brown;">Create
                            Project</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body text-center">
                        <form #userRoleForm="ngForm">
                            <div class="col-md-12 row">
                                <div class="form-horizontal container">


                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
                            mdbWavesEffect>Close</button>
                        <!-- <button  (click)="SaveProject()" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect>Save</button> -->

                        <!-- <button (click)="UpdateEmp()" data-dismiss="modal" type="submit" *ngIf="showupdate"
                            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Update</button> -->

                        <!-- <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
                                (click)="UpdateUserRole(userRoleForm)">Update </button> -->
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="col-sm-12" *ngIf="mysql">

        <div class="row col-sm-12">
            <div class="row col-sm-12">
                <div>
                    <label>Upload Type : SQL</label>
    
    
                    <input style="padding: 13px;" type="file" accept="image/*" name="avatars"
                        #fileField (change)="onChange($event)">
    
                </div>
                <button (click)="Uploadmysql()" data-dismiss="modal" type="submit"
                class="btn btn-success btn-sm waves-light" mdbWavesEffect>Restore</button>
                <!-- <div class="col-sm-9"></div>
                <div class="col-sm-2">
                    <button style="background-color: green" class="btn btn-success" data-toggle="modal"
                        data-target="#mssqlpopup" mdbWavesEffect>
                        Add
                    </button>
                    <br /><br />
                </div> -->
            </div>

        <br>
        <br>


        <div class="modal modal-angular fade" id="mysqlpopup" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center;color: brown;">Create
                            Project</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body text-center">
                        <form #userRoleForm="ngForm">
                            <div class="col-md-12 row">
                                <div class="form-horizontal container">


                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
                            mdbWavesEffect>Close</button>
                        <!-- <button  (click)="SaveProject()" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect>Save</button> -->

                        <!-- <button (click)="UpdateEmp()" data-dismiss="modal" type="submit" *ngIf="showupdate"
                            class="btn btn-success btn-sm waves-light" mdbWavesEffect>Update</button> -->

                        <!-- <button  data-dismiss="modal" *ngIf="showUpdate" type="submit" class="btn btn-success btn-sm waves-light"
                                (click)="UpdateUserRole(userRoleForm)">Update </button> -->
                    </div>
                </div>
            </div>
        </div>


    </div>

</div>
<br>
<br>
<br>
