import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuMasterService } from '../Shared/MenuMaster/menu-master.service';

@Component({
  selector: 'app-homedesign',
  templateUrl: './homedesign.component.html',
  styleUrls: ['./homedesign.component.css']
})
export class HomedesignComponent implements OnInit {
  isLoggedIn;
  name;
  apiconsumer: string;
  apiconsumer1: boolean;
  showhidevalue: boolean=true;
  showtw: boolean;
  showon: boolean=true;
  imgurl: string;
  EnterPriseId: string;
  Enterprise: any;
  username: string;
  firstname: string;
  roleid: any;
  uid: string;

  constructor(private spinner: NgxSpinnerService,private router:Router,private menuservice: MenuMasterService) {
    // this.spinner.show();
    window.scrollTo(0, 0);
    this.name =  localStorage.getItem("UserFullName");
    if (localStorage.loadingpage == 'true') {
      localStorage.loadingpage = 'false';
      location.reload();
    }
    if(localStorage.userId){
      this.isLoggedIn=true;
    }
    else{
      this.isLoggedIn=false;

    }
    
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.apiconsumer = localStorage.getItem("IsApiConsumer");
      this.EnterPriseId = localStorage.getItem("EnterPriseId");
      this.username = localStorage.getItem("UserFullName");
      this.firstname = localStorage.getItem("FirstName");
      this.roleid = localStorage.getItem("role");
      this.uid = localStorage.getItem("userId");
      if(this.apiconsumer == "true"){
        this.apiconsumer1 = true;
      }
      if(this.EnterPriseId != "null"){
        this.GetEnterprise();

      }
    }
   }

  ngOnInit(): void {

    
    // $(function () {
  
    //   $(window).scroll(function () {
    //     var windowHeight = $(window).height();
    //     var scroll = $(window).scrollTop();
    
    //     $('.fadeInDelay').each(function (i) {
    //       var targetPosition = $(this).offset().top;
    //       if (scroll >= targetPosition - windowHeight + 50) {
    //         $(this).delay( 10 * i ).animate({
    //           opacity : 5 ,
    //           transform : 'translateY(0)',
    //         }, 500).addClass('is-visible');
    //       }
    //     });
    //   });
    // });
    
  }

  knowabtblocdrive(){
    this.showhidevalue=true;
  }
  showtwo(){
    this.showtw=true;
    this.showon=false
  }
  logout(){
    localStorage.clear();
    this.router.navigate(['/']);
    location.reload();
  }
  GetEnterprise() {
   // this.spinner.show();
    this.menuservice.getAllUsers(this.EnterPriseId).subscribe((Data: any) => {
      this.Enterprise = Data;
    //  this.spinner.hide();
    });
  }
}
