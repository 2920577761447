import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { userlocation } from 'src/app/ViewModels/Role';

@Injectable({
  providedIn: 'root'
})
export class UserlocationService {
  readonly url="https://blocdrive.in/blocdriveapi/api/";
  constructor(private http:HttpClient) { }
  saveUserLocation(data:userlocation){
    return this.http.post(this.url +"UserLocationMaster/PostSaveUserLocation",data);
  }

  getAllUserLocation(id){
    return this.http.get(this.url +"UserLocationMaster/GetAllUserLocation/" + id);
  }
  getAllBranchdata(id){
    return this.http.get(this.url +"UserLocationMaster/GetAllUserLocationData/" + id);
  }
  deleteuserlocation(id: number){
    return this.http.get(this.url +"UserLocationMaster/GetDeleteUserLocationData/"+ id);
  }

  Updateuserlocation(data:userlocation){
    return this.http.post(this.url +"UserLocationMaster/PutUserLocation",data);
  }
}
