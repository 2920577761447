<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8' || roleid=='2'"></app-sidebar> -->
<div class="register">
    <h2 class="" style="
      font-family: 'Times New Roman', Times, serif;
      text-align: center;
      color: white;
    ">
        Tasks
    </h2>
    <div class="panel panel-primary">
        <div class="col-md-12 row">
            <div class="col-md-2"></div>
            <div class="col-md-2">
                <button class="btn btn-primary" (click)="addTask(addtask)">
          Add Task
        </button>
            </div>
        </div>
        <div class="col-md-12 row">&nbsp;</div>
        <div class="panel-body">
            <div class="col-md-12 row">
                <div class="col-md-2">&nbsp;</div>
                <div class="col-md-10">
                    <div class="table-responsive">
                        <table class="table table-responsive" style="border-collapse: collapse">
                            <tr class="thead-dark" style="font-size: 17px">
                                <!-- <th></th> -->
                                <th>SI NO</th>
                                <th>Task</th>
                                <th>Project</th>
                                <th>Priority</th>
                                <th>Estimated Time(Hrs.)</th>
                                <th>Issue type</th>
                                <th>Present Status</th>
                                <th  style="cursor: pointer;"  (click)="sortuserbydate()">Date</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                            <tr *ngFor="
                  let task of Teamtask
                    | paginate: { itemsPerPage: 5, currentPage: p };
                  let i = index
                " style="color: rgb(0, 0, 0)">
                                <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                                <td>{{ task.issue }}</td>
                                <td>{{ task.projectName }}</td>
                                <td>{{ task.priority }}</td>
                                <td>{{ task.estimatedTime }}</td>
                                <td>{{ task.issueType }}</td>
                                <td>
                                    <p ng-class="{
                      red: task.components == 'TO DO',
                      green: task.components == 'DONE',
                      orange: task.components == 'IN PROGRESS',
                      blue: task.components == 'IN REVIEW'
                    }">
                                        {{ task.components }}
                                    </p>
                                </td>
                                <td>{{ task.taskStartDate |date }}</td>

                                <td>
                                    <button class="btn btn-success" type="button" title="Edit Task" (click)="EditTask(task, updatetask)">
                    <span>Edit</span>
                  </button>
                                </td>
                                <td>
                                    <button class="btn btn-danger" type="button" title="Delete Task" (click)="deleteTask(task, deletetask)">
                    <span>Delete</span>
                  </button>
                                </td>
                                <!-- <button type="button" title="View Task" class="btn btn-default btn-xs"
                                style="background-color:transparent;border:none;" (click)="Taskpasdata(task);">
                                <span class="glyphicon glyphicon glyphicon-eye-open text-success">View</span>
                            </button> -->
                                <!-- <button type="button" title="Assign Task" class="btn btn-default btn-xs"
                                style="background-color:transparent;border:none;" (click)="assignTask(task);">
                                <span class="glyphicon glyphicon-send text-success"></span>
                            </button> -->
                            </tr>
                        </table>
                        <br />
                        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #addtask let-d="dismiss" let-c="dismiss">
    <div class="container body-content col-sm-12">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Task</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Project Name:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
            <option value="">Select Project</option>
            <option
              *ngFor="let project of projectlist"
              value="{{ project.uniqueProjectId }}"
            >
              {{ project.projectName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Module:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedmoduleName($event.target.value)">
            <option value="">Select Module</option>
            <option *ngFor="let module of moduleList" value="{{ module.id }}">
              {{ module.moduleName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Task:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="taskName" id="taskName" name="taskName" placeholder="Enter taskName" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Select Type:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelecttype($event.target.value)">
            <option value="">Select Type</option>
            <option
              *ngFor="let type of issuetypelist"
              value="{{ type.TaskTypeId }}"
            >
              {{ type.TaskTypeName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Priority:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectaPriority($event.target.value)">
            <option value="">Select Priority</option>
            <option
              *ngFor="let priority of prioritylist"
              value="{{ priority.PriorityId }}"
            >
              {{ priority.PriorityName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Summary:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="summary" id="summary" name="summary" placeholder="enter summary" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Components:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="components" id="components" name="components" placeholder="Enter components" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Change:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectchange($event.target.value)">
            <option value="">Select Change</option>
            <option *ngFor="let change of changeList" value="{{ change.id }}">
              {{ change.changeRequestName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Estimated Time(in Hrs):</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="estimatedtime" id="estimatedtime" name="estimatedtime" placeholder="Enter estimated time(in Hrs)" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Detailed Description:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="detaileddescription" id="taskName" name="detaileddescription" placeholder="Enter Detailed description" />
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="modal-footer" style="float: left">
                    <button class="btn btn-success" type="submit">
            <span
              class="glyphicon glyphicon-cloud-upload"
              style="font-size: 19px"
              (click)="Savetaskdata()"
              (click)="d('Cross click')"
            >
              Save</span
            >
          </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #updatetask let-d="dismiss" let-c="dismiss">
    <div class="container body-content col-sm-12">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Task</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Project Name:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
            <option value="">Select Project</option>
            <option
              *ngFor="let project of projectlist"
              value="{{ project.uniqueProjectId }}"
              [selected]="project.uniqueProjectId == editprojectid"
            >
              {{ project.projectName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Module:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedmoduleName($event.target.value)">
            <option value="">Select Module</option>
            <option
              *ngFor="let module of moduleList"
              value="{{ module.id }}"
              [selected]="module.id == editmoduleid"
            >
              {{ module.moduleName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Task:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="edittaskName" id="edittaskName" name="edittaskName" placeholder="Enter taskName" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Select Type:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelecttype($event.target.value)">
            <option value="">Select Type</option>
            <option
              *ngFor="let type of issuetypelist"
              value="{{ type.TaskTypeId }}"
              [selected]="type.TaskTypeId == editissuetype"
            >
              {{ type.TaskTypeName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Priority:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectaPriority($event.target.value)">
            <option value="">Select Priority</option>
            <option
              *ngFor="let priority of prioritylist"
              value="{{ priority.PriorityId }}"
              [selected]="priority.PriorityId == editpriority"
            >
              {{ priority.PriorityName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Summary:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="editsummary" id="editsummary" name="editsummary" placeholder="enter summary" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Components:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="editcomponents" id="editcomponents" name="editcomponents" placeholder="Enter components" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Change:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectchange($event.target.value)">
            <option value="">Select Change</option>
            <option
              *ngFor="let change of changeList"
              value="{{ change.id }}"
              [selected]="change.id == editchange"
            >
              {{ change.changeRequestName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Estimated Time:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="editestimatedtime" id="editestimatedtime" name="editestimatedtime" placeholder="Enter estimatedtime" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Detailed Description:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="editdetaileddescription" id="editdetaileddescription" name="editdetaileddescription" placeholder="Enter detaileddescription" />
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="modal-footer" style="float: left">
                    <button class="btn btn-info" type="submit">
            <span (click)="UpdateTask()" (click)="d('Cross click')">
              Update</span
            >
          </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #deletetask let-d="dismiss" let-c="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Task</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
    </div>
    <br />
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="DeleteTaskRow()" (click)="d('Cross click')" style="cursor: pointer">
      Ok
    </button>
        <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="cursor: pointer">
      Cancel
    </button>
    </div>
    <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b
        ></span
      >
    </div>
  </div>
</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>