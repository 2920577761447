import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectModule } from 'src/app/ViewModels/ProjectModule ';
import { ProjectModuleService } from 'src/app/Shared/ProjectModule/project-module.service';
import { ProjectService } from 'src/app/Shared/Project/project.service';
import { ProjectChangeService } from 'src/app/Shared/projectchangemaster/project-change.service';
import * as $ from 'jquery';
import { AuditorTransactionService } from 'src/app/Shared/auditortransaction/auditor-transaction.service';

@Component({
  selector: 'app-project-module',
  templateUrl: './project-module.component.html',
  styleUrls: ['./project-module.component.css']
})
export class ProjectModuleComponent implements OnInit {
  closeResult: any;
  ModuleName: any;
  modulename: any;
  ProjectId: any;
  projectId: any;
  public dismissalert = false;
  notify: string;
  ModuleList: any;
  p: number = 1;
  editmoduleName: any;
  editid: any;
  umodulename: any;
  umoduledesc: any;
  createdon: any;
  createdby: any;
  DeleteName: any;
  deleteid: any;
  editprojectId: any;
  ProjectList: any;
  moduleName: any;
  roleid: string;
  username: string;
  uid: string;
  editcreatedon: any;
  editcreatedby: any;
  mobileview: boolean;
  GUID: string;
  constructor(private modalService: NgbModal,private auditortransactionservice:AuditorTransactionService
    , private projectservice: ProjectService,
    private spinner: NgxSpinnerService, private moduleService: ProjectModuleService, ) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.roleid = localStorage.getItem("role");
      this.username = localStorage.getItem("UserFullName");
      this.uid = localStorage.getItem("userId");
      this.GUID = localStorage.getItem("GUID");

    }
    this.isMobileMenu();

  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      this.mobileview = false;
    }
    else {
      this.mobileview = true;
    }
  };

  ngOnInit(): void {
    this.Modules();
    this.Projects();
    this.ModuleName = '';
    this.ProjectId = '';
  }
  Modules() {
    this.spinner.show();
    this.moduleService.getAllModules(this.GUID).subscribe((Data: any) => {
      this.ModuleList = Data;
      this.spinner.hide();
    });
  }
  Projects() {
    this.spinner.show();
    this.projectservice.getAllProjects(this.GUID).subscribe((Data: any) => {
      this.ProjectList = Data;
      this.spinner.hide();

    })
  }
  GetProjectName(id){
    for (let i = 0; i <  this.ProjectList.length; i++) {
    if(id==this.ProjectList[i].uniqueProjectId){
      return this.ProjectList[i].projectName;
    }
      
    }
  }
  addModule(addmodule) {
    this.modalService.open(addmodule).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  EditModule(updatedata, updatemodule) {
    this.editmoduleName = updatedata.moduleName;
    this.editprojectId = updatedata.projectId;
    this.editid = updatedata.id;
    this.editcreatedon = updatedata.createdOn;
    this.editcreatedby = updatedata.createdBy;

    this.modalService.open(updatemodule).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteModule(deletedata, addmodule) {
    this.DeleteName = deletedata.moduleName;
    this.deleteid = deletedata.id;
    this.modalService.open(addmodule).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSelectedProject(id) {
    this.projectId = id;
  }
  SaveModule() {
    const inputRequest: ProjectModule = {
      ID: 0,
      ModuleName: this.moduleName,
      ProjectId: this.projectId,
      IsEnabled: true,
      UserId: this.uid,
      CreatedBy: null,
      CreatedOn: null,
      GUID: this.GUID
    };
    this.spinner.show();
    this.moduleService.saveModule(inputRequest).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Module added successfully';
        const audit = {
          TransactionName:"Master",
          TransactionType:"Create",
          TransactionDesc:"Project Module master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.projectId = null
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Modules();
      } else {
        this.notify = 'Something Went Wrong. Try again.!!';
        this.projectId = null
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.spinner.hide();
        this.Modules();
      }
    });
  }

  UpdateModule() {
    if (this.projectId == null || this.projectId == undefined) {
      this.projectId = this.editprojectId
    }
    const inputRequest1: ProjectModule = {
      ID: this.editid,
      ModuleName: this.editmoduleName,
      ProjectId: this.projectId,
      IsEnabled: true,
      UserId: this.uid,
      CreatedBy: this.editcreatedby,
      CreatedOn: this.editcreatedon,
      GUID: this.GUID

    };
    this.spinner.show();
    this.moduleService.UpdateModule(inputRequest1).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Module updated successfully';
        const audit = {
          TransactionName:"Master",
          TransactionType:"Update",
          TransactionDesc:"Project Module master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        this.projectId = null
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Modules();
        this.spinner.hide();
      } else {
        this.notify = 'Something Went Wrong. Try again.!!';
        this.projectId = null
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Modules();
        this.spinner.hide();
      }
    });
  }
  DeleteModuleRow() {
    this.spinner.show();
    this.moduleService.deleteModule(this.deleteid).subscribe((data: any) => {
      if (data == 'success') {
        this.notify = 'Folder deleted successfully';
        const audit = {
          TransactionName:"Master",
          TransactionType:"Delete",
          TransactionDesc:"Project Module master",

          }
          this.auditortransactionservice.AuditorTransactionDetails(audit);
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Modules();
        this.spinner.hide();
      } else {
        this.notify = 'Something Went Wrong. Try again.!!';
        setTimeout(() => (this.dismissalert = true), 100);
        setTimeout(
          function () {
            this.dismissalert = false;
          }.bind(this),
          3000
        );
        this.Modules();
        this.spinner.hide();
      }
    });
  }
  closealert() {
    this.dismissalert = false;
  }
}
