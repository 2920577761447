<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<app-user-sidebar *ngIf="(roleid == '2' || roleid == '9') && mobileview"></app-user-sidebar>
<!-- <div class="register">
    <div class="col-md-12 row">
        <div class="col-md-4">&nbsp;</div>
        <div class="col-md-4">
            <h2 style="text-align: center">
                <strong>Project:</strong>{{ getMoveData.project.projectName }}
            </h2>
        </div>
    </div>
    <div class="col-md-12 row">
        <div class="col-md-2"></div>
        <div class="col-md-10">
            <div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <br />
                    <table class="table table-striped" style="border-collapse: collapse" style="margin-left: 50px">
                        <tbody style="color: rgb(0, 0, 0)">
                            <tr>
                                <td>
                                    <div>
                                        <label class="control-label col-xs-2"><b>Project Lead:</b></label> {{ projectLeadDisplayName }}
                                        <div>&nbsp;</div>
                                        <label class="control-label col-xs-2"><b>Start Date:</b></label> {{ getMoveData.project.startDate | date: "short" }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <label class="control-label col-xs-2"><b>Type: Project</b></label>
                                        <div class="col-xs-3"></div>
                                        <label class="control-label col-xs-2"><b>Priority:</b></label> {{ getMoveData.project.priority }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label class="control-label"><b>Current Stage:
                            <p style="color: cadetblue">
                              {{ ProjectCurrentStage }}
                            </p>
                          </b></label>
                                            </div>
                                            <div class="col-md-3"></div>
                                            <a (click)="addProjectStages(foldercontent)" style="color: #000000; font-size: 20px; cursor: pointer"><i
                          class="fas fa-pen-square"></i>&nbsp;Update</a>
                                        </div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-5">
                                                <label class="control-label col-xs-2" style="margin-left: -12px"><b>Status:
                            <p [style.color]="
                                ProjectCurrentStatus == 'TO DO'
                                  ? 'red'
                                  : ProjectCurrentStatus == 'DONE'
                                  ? 'green'
                                  : ProjectCurrentStatus == 'IN PROGRESS'
                                  ? 'orange'
                                  : 'blue'
                              ">
                              {{ ProjectCurrentStatus }}
                            </p>
                          </b>
                        </label>
                                            </div>
                                            <div class="col-md-3"></div>
                                            <a (click)="addProjectStatus(statuscontent)" style="color: #000000; font-size: 20px; cursor: pointer"><i
                          class="fas fa-pen-square"></i>&nbsp;Update</a>
                                        </div>
                                    </div>
                                    <br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <div>
                <div class="panel panel-primary">
                    <div class="panel-body">
                        <form class="form-horizontal" role="form">
                            <div class="form-group">
                                <div class="panel panel-warning">
                                    <div class="panel-heading">&nbsp;</div>
                                    <div class="panel-heading">
                                        <div class="panel panel-primary" align="center">
                                            <h2><b>Modules</b></h2>
                                        </div>
                                    </div>
                                    <div>&nbsp;</div>
                                    <div *ngIf="userrole != '1001'">
                                        <button class="btn btn-primary" (click)="Addmodule(modulecontent)">
                      Add Module
                    </button>
                                    </div>
                                    <div>&nbsp;</div>
                                    <div>&nbsp;</div>
                                    <br />
                                    <div class="panel-body">
                                        <table class="table table-striped">
                                            <tr class="thead-dark"></tr>
                                            <tr class="thead-dark">
                                                <th></th>
                                                <th>SI NO</th>
                                                <th>Module</th>

                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr *ngFor="let module of projectModules; let i = index" style="color: rgb(0, 0, 0)">
                                                <td>
                                                    <i class="glyphicon glyphicon-file"></i>
                                                </td>
                                                <td>{{ i + 1 }}</td>
                                                <td>{{ module.moduleName }}</td>

                                                <td>
                                                    <button type="button" class="btn btn-default btn-xs">
                            <span class="glyphicon glyphicon-eye-open text-success"></span>
                          </button>
                                                </td>
                                                <td>
                                                    <button type="button" style="background-color: transparent; border: none" data-*ngIf="userrole=='1001'" class="btn btn-default btn-xs">
                            <span class="glyphicon glyphicon-edit text-success"></span>
                          </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <hr />

                                <div class="panel panel-warning">
                                    <div class="panel-heading">
                                        <div class="panel panel-primary" align="center">
                                            <h2><b>Tasks</b></h2>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div *ngIf="userrole != '1001'">
                                            <button class="btn btn-primary" (click)="AddTask(addtask)">
                        Add Task
                      </button>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>&nbsp;</div>
                                    </div>
                                    <div class="panel-body">
                                        <table class="table table-striped">
                                            <tr class="thead-dark">
                                                <th></th>
                                                <th>SI NO</th>
                                                <th>Task</th>
                                                <th>Start Date & Time</th>
                                                <th>End Date & Time</th>

                                                <th>Current Status</th>
                                            </tr>
                                            <tr *ngFor="let task of CurrentProjectTasks; let i = index" style="color: rgb(0, 0, 0)">
                                                <td>
                                                    <i class="glyphicon glyphicon-user"></i>
                                                </td>
                                                <td>{{ i + 1 }}</td>
                                                <td>{{ task.issue }}</td>
                                                <td>
                                                    {{ task.taskStartDate | date: "short" }}

                                                </td>
                                                <td>{{ task.endDateTime }}</td>
                                                <td>
                                                    <p ng-class="{
                              red: task.currentStatus == 'TO DO',
                              green: task.currentStatus == 'DONE',
                              orange: task.currentStatus == 'IN PROGRESS',
                              blue: task.currentStatus == 'IN REVIEW'
                            }">
                                                        {{ task.currentStatus }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <br />
                    <div>&nbsp;</div>
                    <hr />

                    <div class="form-group" style="margin-left: 30px">
                        <div class="panel panel-success">
                            <div class="panel-heading">
                                <div class="panel panel-primary" align="center">
                                    <h2><b> Comments</b></h2>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <div *ngIf="userrole != '1001'">
                                <button class="btn btn-primary" (click)="AddComment(commentscontent)">
                  Add Comments
                </button>
                            </div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div class="panel-body">
                                <table class="table table-striped">
                                    <tr class="thead-dark"></tr>
                                    <tr class="thead-dark">
                                        <th></th>
                                        <th>SI NO</th>
                                        <th>Comment</th>
                                        <th>Commented By</th>
                                        <th>Commented On</th>
                                    </tr>
                                    <tr *ngFor="let taskComment of taskcommentslist; let i = index" style="color: rgb(0, 0, 0)">
                                        <td>
                                            <i class="glyphicon glyphicon-ok"></i>
                                        </td>
                                        <td>{{ i + 1 }}</td>

                                        <td>
                                            <a ng-href="/#/taskview">{{ taskComment.comment }}</a>
                                        </td>
                                        <td>{{ taskComment.commentedBy }}</td>
                                        <td>{{ taskComment.commentedOn | date }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <br />

                        <div class="panel panel-success" *ngIf="userrole == '1'">
                            <div class="panel-heading">
                                <div class="panel panel-primary" align="center">
                                    <h2><b>Project Users</b></h2>
                                </div>
                            </div>
                            <div>&nbsp;</div>

                            <div>
                                <button class="btn btn-primary" (click)="addprojectuser(projectuser)">
                  Add User
                </button>
                            </div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div class="panel-body">
                                <table class="table table-striped">
                                    <tr class="thead-dark">
                                        <th></th>
                                        <th>SI NO</th>
                                        <th>User</th>
                                        <th>Alias</th>
                                        <th>Role</th>
                                    </tr>
                                    <tr *ngFor="let projectUser of projectUsers; let i = index" style="color: rgb(0, 0, 0)">
                                        <td>
                                            <i class="glyphicon glyphicon-ok"></i>
                                        </td>
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ projectUser.userName }}</td>
                                        <td>{{ projectUser.aliasName }}</td>
                                        <td>{{ projectUser.roleInProject }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="panel panel-success" *ngIf="userrole == '1001'">
                            <div class="panel-heading">Project Users:</div>
                            <div class="panel-body">
                                <table class="table table-striped">
                                    <tr class="thead-dark">
                                        <th colspan="2">
                                            <h4>Users:</h4>
                                        </th>
                                        <th colspan="1">
                                            <a *ngIf="userrole != '1001'"><i class="glyphicon glyphicon glyphicon-comment">Add User</i></a>
                                        </th>
                                    </tr>
                                    <tr class="thead-dark">
                                        <th></th>
                                        <th style="display: none">ID</th>

                                        <th>Name</th>
                                        <th>Role</th>
                                    </tr>
                                    <tr *ngFor="let projectUser of projectUsers; let i = index" style="color: rgb(0, 0, 0)">
                                        <td>
                                            <i class="glyphicon glyphicon-ok"></i>
                                        </td>
                                        <td style="display: none">{{ projectUser.Id }}</td>

                                        <td>{{ projectUser.aliasName }}</td>
                                        <td>{{ projectUser.roleInProject }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <br />
                        <hr />

                        <div class="panel-heading">
                            <div class="panel panel-primary" align="center">
                                <h2><b>Attachments</b></h2>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>

                        <div class="col-md-12 row">
                            <div class="col-md-5">
                                <label class="dropdown-item">
                  <b for="excel-upload" style="color: #0000ff; cursor: pointer; font-size: 20px"><i
                      class="fas fa-file"></i>&nbsp;&nbsp;Upload Objects</b>
                  <input #fileInput (click)="fileInput.value = null" type="file" id="excel-upload" multiple="true"
                    style="display: none" (change)="onChangeUploadDoc($event)" />
                </label>
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div class="form-group">
                            <div>&nbsp;</div>
                            <table class="table table-striped" *ngIf="alldocuments">
                                <tr class="thead-dark">
                                    <th style="display: none">ID</th>
                                    <th>Documents</th>
                                    <th>Download</th>
                                </tr>

                                <tr *ngFor="let ret of alldocuments.retData; let i = index" style="color: rgb(0, 0, 0)">
                                    <td>
                                        <div *ngIf="
                        ret.documentType == 'image/jpeg' ||
                        ret.documentType == 'image/png' ||
                        ret.documentType == 'image/jpg'
                      ">
                                            <img [src]="ret.file" style="
                          width: 50px;
                          height: 50px;
                          margin-left: 15px;
                          cursor: pointer;
                        " (click)="gotopdfView(ret)" />
                                            <span style="cursor: pointer">
                        &nbsp;&nbsp;{{ ret.documentName }}
                      </span>
                                        </div>
                                        <div *ngIf="
                        ret.documentType == 'application/pdf' ||
                        ret.documentType == 'pdf'
                      ">
                                            <img src="./assets/img/pdficon.png" style="
                          width: 35px;
                          height: 34px;
                          cursor: pointer;
                          margin-left: 15px;
                        " (click)="gotopdfView(ret)" />
                                            <span style="cursor: pointer">
                        &nbsp;&nbsp;{{ ret.documentName }}
                      </span>
                                        </div>
                                        <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        ret.documentType == 'xlsx' ||
                        ret.documentType == 'xls' ||
                        ret.documentType == 'application/vnd.ms-excel'
                      " (click)="gotopdfView(ret)">
                                            <img src="./assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer" />
                                            <span style="cursor: pointer">
                        {{ ret.documentName }}
                      </span>
                                        </div>
                                        <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                        ret.documentType == 'ppt' ||
                        ret.documentType == 'pptx'
                      " (click)="gotopdfView(ret)">
                                            <img src="./assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer" />
                                            <span style="cursor: pointer">
                        {{ ret.documentName }}
                      </span>
                                        </div>

                                        <div *ngIf="
                        ret.documentType ==
                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        ret.documentType == 'docx'
                      " (click)="gotopdfView(ret)">
                                            <img src="./assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer" />
                                            <span style="cursor: pointer">
                        {{ ret.documentName }}
                      </span>
                                        </div>
                                        <div *ngIf="ret.documentType == 'text/plain'">
                                            <img src="./assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer" (click)="gotopdfView(ret)" />

                                            <span style="cursor: pointer">
                        {{ ret.documentName }}
                      </span>
                                        </div>
                                    </td>
                                    <td>
                                        <a (click)="DownloadFile(ret)" download><button class="btn btn-success">
                        <i class="fa fa-download"></i>Download
                      </button></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- -------------------------------------------------- -->
<div>
    <div class="container">
        <!--small cards-->
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header-blue">
                            <h1 class="card-heading">
                                Project: {{ getMoveData.project.projectName }}
                            </h1>
                        </div>
                        <div class="card-body">
                            <p class="card-p">
                                Project Lead: {{ projectLeadDisplayName }} <br /> 
                                <a *ngIf='showteam'>
                                    Team Name: {{ projectteamname }} <br />
                                    Team Members: {{projectteammembers}} <br />
                                </a>
                                Start Date: {{ getMoveData.project.startDate | date: "short" }}<br />
                                 Type: Project <br />
                                  Priority: {{ getMoveData.project.priority }}<br />                                
                            </p>
                            <p>
                                Current Stage:
                                <span style="color: cadetblue">
                {{ ProjectCurrentStage }}
              </span> &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                <a (click)="addProjectStages(foldercontent)"><i class="fas fa-pen-square"></i>&nbsp;Update</a>
                            </p>
                            <p>
                                Status:
                                <span [style.color]="
                  ProjectCurrentStatus == 'TO DO'
                    ? 'red'
                    : ProjectCurrentStatus == 'DONE'
                    ? 'green'
                    : ProjectCurrentStatus == 'IN PROGRESS'
                    ? 'orange'
                    : 'blue'
                ">
                {{ ProjectCurrentStatus }} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a (click)="addProjectStatus(statuscontent)"><i class="fas fa-pen-square"></i>&nbsp;Update</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <!--small cards-->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header-orange">
                            <h1 class="card-heading">
                                Modules
                                <a *ngIf="userrole != '1001'" style="float: right; ">
                                    <button class="btn btn-primary" (click)="Addmodule(modulecontent)">
                  Add Module
                </button>
                                </a>
                            </h1>
                        </div>
                        <div class="card-body">
                            <form class="form-horizontal card-p" role="form">
                                <div class="form-group">
                                    <div class="panel panel-warning">
                                        <div class="panel-body">
                                            <table class="table table-striped">
                                                <tr class="thead-dark"></tr>
                                                <tr class="thead-dark">
                                                    <th></th>
                                                    <th>SI NO</th>
                                                    <th>Module</th>

                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr *ngFor="let module of projectModules; let i = index" style="color: rgb(0, 0, 0)">
                                                    <td>
                                                        <i class="glyphicon glyphicon-file"></i>
                                                    </td>
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ module.moduleName }}</td>

                                                    <td>
                                                        <button type="button" class="btn btn-default btn-xs">
                            <span class="glyphicon glyphicon-eye-open text-success"></span>
                          </button>
                                                    </td>
                                                    <td>
                                                        <button type="button" style="background-color: transparent; border: none" data-*ngIf="userrole=='1001'" class="btn btn-default btn-xs">
                            <span class="glyphicon glyphicon-edit text-success"></span>
                          </button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        

        </div>
    </div>


    <div class="container">
        <!--small cards-->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header-grey">
                            <h1 class="card-heading">
                                Tasks

                                <span *ngIf="userrole != '1001'" style="float: right; ">
                <button class="btn btn-primary" (click)="AddTask(addtask)">
                  Add Task
                </button>
              </span>
                            </h1>
                        </div>
                        <div class="card-body">
                            <form class="form-horizontal card-p" role="form">
                                <div class="form-group">
                                    <div class="panel panel-warning">
                                        <div class="panel-body">
                                            <table class="table table-striped">
                                                <tr class="thead-dark">
                                                    <th></th>
                                                    <th>SI NO</th>
                                                    <th>Task</th>
                                                    <th>Start Date & Time</th>
                                                    <th>End Date & Time</th>

                                                    <th>Current Status</th>
                                                </tr>
                                                <tr *ngFor="let task of CurrentProjectTasks; let i = index" style="color: rgb(0, 0, 0)">
                                                    <td>
                                                        <i class="glyphicon glyphicon-user"></i>
                                                    </td>
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ task.issue }}</td>
                                                    <td>
                                                        {{ task.taskStartDate | date: "short" }}
                                                        <!-- {{ task.startTime }} -->
                                                    </td>
                                                    <td>{{ task.endDateTime }}</td>
                                                    <td>
                                                        <p ng-class="{
                              red: task.currentStatus == 'TO DO',
                              green: task.currentStatus == 'DONE',
                              orange: task.currentStatus == 'IN PROGRESS',
                              blue: task.currentStatus == 'IN REVIEW'
                            }">
                                                            {{ task.currentStatus }}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <!--small cards-->
        <div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-content">
                <div class="card-header-red">
                    <h1 class="card-heading">
                        Comments
                        <span *ngIf="userrole != '1001'" style="float: right; ">
        <button class="btn btn-primary" (click)="AddComment(commentscontent)">
          Add Comments
        </button>
      </span>
                    </h1>
                </div>
                <div class="card-body">
                    <form class="form-horizontal card-p" role="form">
                        <div class="form-group">
                            <div class="panel panel-success">
                                <div class="panel-body">
                                    <table class="table table-striped">
                                        <tr class="thead-dark"></tr>
                                        <tr class="thead-dark">
                                            <th></th>
                                            <th>SI NO</th>
                                            <th>Comment</th>
                                            <th>Commented By</th>
                                            <th>Commented On</th>
                                        </tr>
                                        <tr *ngFor="let taskComment of taskcommentslist; let i = index" style="color: rgb(0, 0, 0)">
                                            <td>
                                                <i class="glyphicon glyphicon-ok"></i>
                                            </td>
                                            <td>{{ i + 1 }}</td>

                                            <td>
                                                <a ng-href="/#/taskview">{{ taskComment.comment }}</a>
                                            </td>
                                            <td>{{ taskComment.commentedBy }}</td>
                                            <td>{{ taskComment.commentedOn | date }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    <div class="container">
        <!--small cards-->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content">
                        <div class="card-header-pink">
                            <h1 class="card-heading">
                                Project Users
                                <span *ngIf="userrole == '1'" style="float: right; ">
                <button class="btn btn-primary" (click)="addprojectuser(projectuser)">
                  Add User
                </button>
              </span>
                            </h1>
                        </div>
                        <div class="card-body">
                            <form class="form-horizontal card-p" role="form">
                                <div class="form-group">
                                    <div class="panel panel-success" *ngIf="userrole == '1'">
                                        <div class="panel-body">
                                            <table class="table table-striped">
                                                <tr class="thead-dark">
                                                    <th></th>
                                                    <th>SI NO</th>
                                                    <th>User</th>
                                                    <th>Alias</th>
                                                    <th>Role</th>
                                                </tr>
                                                <tr *ngFor="let projectUser of projectUsers; let i = index" style="color: rgb(0, 0, 0)">
                                                    <td>
                                                        <i class="glyphicon glyphicon-ok"></i>
                                                    </td>
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ projectUser.userName }}</td>
                                                    <td>{{ projectUser.aliasName }}</td>
                                                    <td>{{ projectUser.roleInProject }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="panel panel-success" *ngIf="userrole == '1001'">

                                        <div class="panel-body">
                                            <table class="table table-striped">
                                                <tr class="thead-dark">
                                                    <th colspan="2">
                                                        <h4>Users:</h4>
                                                    </th>
                                                    <th colspan="1">
                                                        <a *ngIf="userrole != '1001'"><i class="glyphicon glyphicon glyphicon-comment">Add
                              User</i></a>
                                                    </th>
                                                </tr>
                                                <tr class="thead-dark">
                                                    <th></th>
                                                    <th style="display: none">ID</th>

                                                    <th>Name</th>
                                                    <th>Role</th>
                                                </tr>
                                                <tr *ngFor="let projectUser of projectUsers; let i = index" style="color: rgb(0, 0, 0)">
                                                    <td>
                                                        <i class="glyphicon glyphicon-ok"></i>
                                                    </td>
                                                    <td style="display: none">{{ projectUser.Id }}</td>

                                                    <td>{{ projectUser.aliasName }}</td>
                                                    <td>{{ projectUser.roleInProject }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      

        </div>
    </div>

<div class="container">
    <!--small cards-->
    <div class="row">
<div class="col-md-12">
    <div class="card">
        <div class="card-content">
            <div class="card-header-purple">
                <h1 class="card-heading">
                    Attachments
                    <span *ngIf="userrole != '1001'" style="float: right; ">
                    <label class="dropdown-item btn btn-primary">
                        
                    <b for="excel-upload"><i
                        class="fas fa-file"></i>&nbsp;&nbsp;Upload Objects</b>
                    <input #fileInput (click)="fileInput.value = null" type="file" id="excel-upload" multiple="true"
                      style="display: none" (change)="onChangeUploadDoc($event)" />
                  </label>
                  </span>

                </h1>
            </div>
            <div class="card-body">
                <form class="form-horizontal card-p" role="form">

                    <div class="form-group">
                        <div class="panel panel-success">
                            <div class="panel-body">
                                <table class="table table-striped" *ngIf="alldocuments">
                                    <tr class="thead-dark">
                                        <th style="display: none">ID</th>
                                        <th>Documents</th>
                                        <th>Download</th>
                                    </tr>

                                    <tr *ngFor="let ret of alldocuments.retData; let i = index" style="color: rgb(0, 0, 0)">
                                        <td>
                                            <div *ngIf="
                  ret.documentType == 'image/jpeg' ||
                  ret.documentType == 'image/png' ||
                  ret.documentType == 'image/jpg'
                ">
                                                <img [src]="ret.file" style="
                    width: 50px;
                    height: 50px;
                    margin-left: 15px;
                    cursor: pointer;
                  " (click)="gotopdfView(ret)" />
                                                <span style="cursor: pointer">
                  &nbsp;&nbsp;{{ ret.documentName }}
                </span>
                                            </div>
                                            <div *ngIf="
                  ret.documentType == 'application/pdf' ||
                  ret.documentType == 'pdf'
                ">
                                                <img src="./assets/img/pdficon.png" style="
                    width: 35px;
                    height: 34px;
                    cursor: pointer;
                    margin-left: 15px;
                  " (click)="gotopdfView(ret)" />
                                                <span style="cursor: pointer">
                  &nbsp;&nbsp;{{ ret.documentName }}
                </span>
                                            </div>
                                            <div *ngIf="
                  ret.documentType ==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  ret.documentType == 'xlsx' ||
                  ret.documentType == 'xls' ||
                  ret.documentType == 'application/vnd.ms-excel'
                " (click)="gotopdfView(ret)">
                                                <img src="./assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer" />
                                                <span style="cursor: pointer">
                  {{ ret.documentName }}
                </span>
                                            </div>
                                            <div *ngIf="
                  ret.documentType ==
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  ret.documentType == 'ppt' ||
                  ret.documentType == 'pptx'
                " (click)="gotopdfView(ret)">
                                                <img src="./assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer" />
                                                <span style="cursor: pointer">
                  {{ ret.documentName }}
                </span>
                                            </div>

                                            <div *ngIf="
                  ret.documentType ==
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  ret.documentType == 'docx'
                " (click)="gotopdfView(ret)">
                                                <img src="./assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer" />
                                                <span style="cursor: pointer">
                  {{ ret.documentName }}
                </span>
                                            </div>
                                            <div *ngIf="ret.documentType == 'text/plain'">
                                                <img src="./assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer" (click)="gotopdfView(ret)" />

                                                <span style="cursor: pointer">
                  {{ ret.documentName }}
                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <a (click)="DownloadFile(ret)" download><button class="btn btn-success">
                  <i class="fa fa-download"></i>Download
                </button></a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
<!-- ---------------------------------------------------------- -->

<ng-template #statuscontent let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title ">Status</h4>
            <button type="button " class="close" aria-label="Close " (click)="d('Cross click')">
        <span aria-hidden="true ">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Status:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectstatus($event.target.value)">
            <option value=" ">Select Status</option>
            <option *ngFor="let status of projectstatus" value="{{ status.id }}">
              {{ status.status }}
            </option>
          </select>
                </div>
            </div>

            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="modal-footer" style="float: left">
                    <button class="btn btn-success" type="submit ">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="Savestatus()"
              (click)="d('Cross click')">
              Save</span>
          </button>
                    <button class="btn btn-danger" type="submit ">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="d('Cross click')">
              Cancel</span>
          </button>
                </div>
            </div>
        </div>
        <br />
    </div>
</ng-template>
<ng-template #foldercontent let-d="dismiss" let-c="dismiss">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Stage</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Stage:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectstage($event.target.value)">
            <option value="">Select Stage</option>
            <option *ngFor="let users of projectstages" value="{{ users.id }}">
              {{ users.stage }}
            </option>
          </select>
                </div>
            </div>

            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="modal-footer" style="float: left">
                    <button class="btn btn-success" type="submit">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="Savestages()"
              (click)="d('Cross click')">
              Save</span>
          </button>
                    <button class="btn btn-danger" type="submit">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="d('Cross click')">
              Cancel</span>
          </button>
                </div>
            </div>
        </div>
        <br />
    </div>
</ng-template>
<ng-template #commentscontent let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title ">Status</h4>
            <button type="button " class="close" aria-label="Close " (click)="d('Cross click')">
        <span aria-hidden="true ">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Comment:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="comment" id="comment" name="comment" placeholder="Add comment" />
                </div>
            </div>
        </div>

        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer" style="float: left">
                <button class="btn btn-success" type="submit ">
          <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="Savecomment()"
            (click)="d('Cross click')">
            Save</span>
        </button>
                <button class="btn btn-danger" type="submit ">
          <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="d('Cross click')">
            Cancel</span>
        </button>
            </div>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #modulecontent let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title ">Module</h4>
            <button type="button " class="close" aria-label="Close " (click)="d('Cross click')">
        <span aria-hidden="true ">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Module:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="module" id="module" name="module" placeholder="Add Module" />
                </div>
            </div>
        </div>

        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer" style="float: left">
                <button class="btn btn-success" type="submit ">
          <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="Savemodule()"
            (click)="d('Cross click')">
            Save</span>
        </button>
                <button class="btn btn-danger" type="submit ">
          <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="d('Cross click')">
            Cancel</span>
        </button>
            </div>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #addtask let-d="dismiss" let-c="dismiss">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Task</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Project Name:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedProject($event.target.value)">
            <option value="">Select Project</option>
            <option *ngFor="let project of projectlist" value="{{ project.uniqueProjectId }}">
              {{ project.projectName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Module:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedmoduleName($event.target.value)">
            <option value="">Select Module</option>
            <option *ngFor="let module of moduleList" value="{{ module.id }}">
              {{ module.moduleName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Task:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="taskName" id="taskName" name="taskName" placeholder="Enter taskName" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Select Type:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelecttype($event.target.value)">
            <option value="">Select Type</option>
            <option *ngFor="let type of issuetypelist" value="{{ type.TaskTypeId }}">
              {{ type.TaskTypeName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Priority:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectaPriority($event.target.value)">
            <option value="">Select Priority</option>
            <option *ngFor="let priority of prioritylist" value="{{ priority.PriorityId }}">
              {{ priority.PriorityName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Summary:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="summary" id="summary" name="summary" placeholder="enter summary" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Components:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="components" id="components" name="components" placeholder="Enter components" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Change:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectchange($event.target.value)">
            <option value="">Select Change</option>
            <option *ngFor="let change of changeList" value="{{ change.id }}">
              {{ change.changeRequestName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Estimated Time(in Hrs):</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="estimatedtime" id="estimatedtime" name="estimatedtime" placeholder="Enter estimated time(in Hrs)" />
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Detailed Description:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="detaileddescription" id="taskName" name="detaileddescription" placeholder="Enter Detailed description" />
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="modal-footer" style="float: left">
                    <button class="btn btn-success" type="submit">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="Savetaskdata()"
              (click)="d('Cross click')">
              Save</span>
          </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #projectuser let-d="dismiss" let-c="dismiss">
    <div class="container body-content col-sm-12">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add User</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Team Member:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelectedProjectuser($event.target.value)">
            <option value="">Select Team Member:</option>
            <option *ngFor="let project of userList" value="{{ project.guid }}">
              {{ project.userName }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Role:</label>
                <div class="col-xs-4">
                    <select class="form-control dropdownsize" (change)="onSelecteroleName($event.target.value)">
            <option value="">Select Role</option>
            <option *ngFor="let module of projectRoles" value="{{ module.id }}">
              {{ module.role }}
            </option>
          </select>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Alias Name:</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="AliasName" id="AliasName" name="AliasName" placeholder="Enter Alias Name" />
                </div>
            </div>

            <div class="col-xs-12">
                <div class="col-xs-4"></div>
                <label class="control-label col-xs-2">Compensation(per Hour):</label>
                <div class="col-xs-4">
                    <input type="text" class="form-control" [(ngModel)]="Compensation" id="Compensation" name="Compensation" placeholder="Enter Compensation" />
                </div>
            </div>

            <div>&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-5"></div>
                <div class="modal-footer" style="float: left">
                    <button class="btn btn-success" type="submit">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" (click)="Saveprojectrole()"
              (click)="d('Cross click')">
              Save</span>
          </button>
                    <button class="btn btn-danger" type="submit">
            <span class="glyphicon glyphicon-cloud-upload" style="font-size: 19px" class="close" aria-label="Close"
              (click)="d('Cross click')">
              Cancel</span>
          </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- View Documents Modals -->
<ng-template #viewimage let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center">
                <b>{{ imgdata.documentName }}</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <img [src]="imgdata.file" class="img-responsive" alt="" style="height: 300px; width: 317px" />
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer">
                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8"></div>
                </div>
            </div>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #viewpdf let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                <b style="text-align: center">{{ pdfdata.documentName }}</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <ngx-doc-viewer [url]="pdffile" viewer="google" style="width: 100%; height: 70vh">
            </ngx-doc-viewer>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer"></div>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #viewppt let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center">
                <b>{{ pptdata.documentName }}</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <ngx-doc-viewer [url]="pptfile" viewer="google" style="width: 100%; height: 70vh"></ngx-doc-viewer>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer">
                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8"></div>
                </div>
            </div>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #viewxls let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title" style="text-align: center">
                <b>{{ xlsdata.documentName }}</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <iframe [src]="xlssantizedisplay" width="100%" height="565px" frameborder="0">
      </iframe>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer">
                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8"></div>
                </div>
            </div>
        </div>
    </div>
    <br />
</ng-template>
<ng-template #viewword let-d="dismiss " let-c="dismiss ">
    <div class="container body-content col-sm-12" style="background-color: #f3f3f3">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
                <b style="text-align: center">{{ worddata.documentName }}</b>
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <ngx-doc-viewer [url]="wordfile" viewer="google" style="width: 100%; height: 70vh"></ngx-doc-viewer>
        </div>
        <div>&nbsp;</div>
        <div class="col-md-12 row">
            <div class="col-md-5"></div>
            <div class="modal-footer">
                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8"></div>
                </div>
            </div>
        </div>
    </div>
    <br />
</ng-template>
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">Close</i>
      </button>
            <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b></span>
        </div>
    </div>
</div>
<br />
<br />
<br />
<br />
<br />