<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div>&nbsp;</div>
<div>&nbsp;</div>
<app-user-sidebar *ngIf="(roleid == '2' || roleid == '9') && mobileview"></app-user-sidebar>
<div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-3">
        <img src="/assets/img/back.png" height="40" width="40" style="cursor: pointer" (click)="backtoprojects()" />
    </div>
</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<div class="container padding-bottom-3x mb-1">
    <div class="card mb-3">
        <div class="p-4 text-center text-white text-lg bg-dark rounded-top" style="background: linear-gradient(270deg, #7100aa, #000)">
            <span class="text-uppercase">WORK </span
      ><span class="text-medium">PROGRESS</span>
        </div>
        <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
            <!-- <div class="w-100 text-center py-1 px-2">
        <span class="text-medium">Shipped Via:</span> UPS Ground
      </div>
      <div class="w-100 text-center py-1 px-2">
        <span class="text-medium">Status:</span> Checking Quality
      </div>
      <div class="w-100 text-center py-1 px-2">
        <span class="text-medium">Expected Date:</span> SEP 09, 2017
      </div> -->
        </div>
        <div class="card-body">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <div class="step completed" id="step1">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Start</h4>
                </div>
                <div class="step completed" id="step2">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Inward</h4>
                </div>
                <div class="step completed" id="step3">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Work Allotment</h4>
                </div>
                <div class="step completed" id="step4">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Work Progress</h4>
                </div>
                <div class="step completed" id="step5">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Outward</h4>
                </div>
            </div>
        </div>
    </div>
    <br /><br />
    <br /><br />

    <!-- <div class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
        <div class="custom-control custom-checkbox mr-3">
            <input class="custom-control-input" type="checkbox" id="notify_me" checked="" />
            <label class="custom-control-label" for="notify_me">Notify me when work is done</label>
        </div>
        <div class="text-left text-sm-right">
            <a class="btn btn-outline-primary btn-rounded btn-sm" href="orderDetails" data-toggle="modal"
                data-target="#orderDetails">View work Details</a>
        </div>
    </div> -->
</div>