import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GmailmigrationService } from 'src/app/Shared/GmailMigration/gmailmigration.service';

@Component({
  selector: 'app-gmail-mails',
  templateUrl: './gmail-mails.component.html',
  styleUrls: ['./gmail-mails.component.css']
})
export class GmailMailsComponent implements OnInit {
  public isLoggedIn: boolean = false;
  uid: string;
  GmailList: any;
  searchText: any;

  p: number = 1;
  threadId: string;
  constructor( private spinner: NgxSpinnerService,private gmailservice : GmailmigrationService,private route: Router) {
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.isLoggedIn = true;
      this.uid = localStorage.getItem("userId");

    }
   }

  ngOnInit(): void {
    this.GetGmailList();
  }


  GetGmailList() {
    this.spinner.show();
    var info ={
      UID  :this.uid
    }
    this.gmailservice.getGmaillist(this.uid).subscribe((respose: any) => {
      this.GmailList = respose;
      console.log(this.GmailList);
      
      this.spinner.hide(); 
    });
  }

  ReadMessage(data){
   this.route.navigate(['readgmail/'+ data.threadId ]);

 //window.open("http://localhost:4200/readgmail/"+data.threadId);
  }


}
