<app-nav-bar>
</app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div class="register">
  <h2 style="text-align: center">User List</h2>

  <div class="row col-sm-12">
    <div class="col-sm-1"></div>
    <div class="col-sm-2">
      <button class="btn btn-primary" (click)="addUser(adduser)">Add</button>
    </div>
    <div class="col-sm-2">
      <button class="btn btn-primary" (click)="nextpage()">Add Multiple users</button>
    </div>
    <div class="col-sm-9"></div>
  </div>
  <br />
  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div>
        <div class="table-responsive" style="overflow-x: visible; height: 300px">
          <table class="table table-responsive" style="border-collapse: collapse">
            <tr class="thead-dark" style="font-size: 17px">
              <th>Sl No</th>
              <th>UserId</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Branch</th>
              <th>Role</th>
              <th>Designation</th>
              <th>Department</th>
              <th>Sub Department</th>
              <th>Group</th>
              <th>Rights</th>
              <th>Location</th>
              <th>Created On</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Status</th>
            </tr>
            <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
            <tr *ngFor="
                let aa of UserList
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              " style="
                font-size: 16px;
                color: rgb(0, 0, 0);
                font-family: DINNextRoundedLTPro;
              ">
              <td>{{ 5 * (p - 1) + (i + 1) }}</td>
              <td>{{ aa.userId }}</td>
              <td>{{ aa.userName }}</td>
              <td>{{ aa.email }}</td>
              <td>{{ Getbranch(aa.branch) }}</td>
              <td>{{ GetRole(aa.role) }}</td>
              <td>{{ GetDesignation(aa.designation) }}</td>
              <td>{{ GetDepartment(aa.department) }}</td>
              <td data-toggle="modal" data-target="#Levels" (click)="GetLevels(aa)">{{ GetSubDepartment(aa.subDepartmentId) }}</td>
              <td>{{ GetGroup(aa.groupId) }}</td>
              <td>{{ aa.displayRights }}</td>
              <td>{{ GetUserLocation(aa.location)  }}</td>
              <td>{{ aa.createdOn | date: "short" }}</td>

              <td>
                <button class="btn btn-success" (click)="EditUser(aa, updateuser)">
                  Edit
                </button>
              </td>
              <td>
                <button class="btn btn-danger" (click)="deleteUser(aa, deleteuser)">
                  Delete
                </button>
              </td>
              <td>
                <button class="btn btn-success" (click)="deactiveUser(aa,deactiveuser)">
                  {{aa.isactivateuser}}
                </button>
                
              </td>
            </tr>
          </table>
          <br />
          <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
          <br /><br />
          <br /><br />
        </div>
      </div>
    </div>
  </div>
</div>

<br /><br />

<ng-template #adduser let-d="dismiss" let-c="dismiss">
  <div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">Add User</h3>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <div class="row">
      <div class="col-md-6">
    <h4 class="modal-title" id="modal-basic-title">User Name:</h4>
    <!-- <input type="text" id="fullname" [(ngModel)]="fullname" class="form-control" name="dp"
     /><br /> -->
      <input type="text" id="fullname" [(ngModel)]="fullname" class="form-control" name="dp"
      (change)="Validateusername()" /><br /> 
    <h6 *ngIf="validusername" style="color: red">
      UserName already taken, Try with other UserName
    </h6> 


    <!-- <input type="text" id="fullname" [(ngModel)]="fullname" class="form-control" name="dp" (change)="Validateusername()"
     /><br /> -->
  <!-- <h6 *ngIf="validusername" style="color: red">
    UserName already taken, Try with other UserName
  </h6> -->
  </div>
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Email:</h5>
    <input type="text" id="email" [(ngModel)]="email" class="form-control" name="dp" (change)="Validatemail()"
      readonly />
     
    <!-- <h5 *ngIf="validmail" style="color: red">Email already exist!!</h5> -->
    <br />
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Branch:</h5>
    <select (change)="OnselectUserbranch($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Branch</option>
      <option *ngFor="let do of BranchList" value="{{ do.id }}" [selected]="do.id == selectedrole">
        {{ do.branchName }}
      </option>
    </select><br />
  </div>
    <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Role:</h5>
    <select (change)="OnselectUserRole($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Role</option>
      <option *ngFor="let do of RoleList" value="{{ do.id }}" [selected]="do.id == selectedrole">
        {{ do.roleName }}
      </option>
    </select><br />
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Department:</h5>
    <select (change)="OnselectUserDepartment($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Department</option>
      <option *ngFor="let do of DepartmentList" value="{{ do.id }}">
        {{ do.majorDepartmentName }}- {{ do.code }}
      </option>
    </select><br />
  </div>
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Sub Department:</h5>
    <select (change)="OnselectUserMinorDepartment($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Sub Department</option>
      <option *ngFor="let do of MajorGroupinMinorTblList" value="{{ do.id }}">
        {{ do.minorDepartment }}
      </option>
    </select><br />
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Designation:</h5>
    <select (change)="OnselectUserDesignation($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Designation</option>
      <option *ngFor="let do of DesignationList" value="{{ do.id }}">
        {{ do.designationName }}
      </option>
    </select><br />
  </div>
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Group:</h5>
    <select (change)="Onselectgroup($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Group</option>
      <option *ngFor="let do of GroupList" value="{{ do.id }}">
        {{ do.groupName }}
      </option>
    </select><br />
  </div>


</div>
<div class="row">
  <div class="col-md-6">

    <h5 class="modal-title" id="modal-basic-title">Rights:</h5>
    <!-- <div style="width:50%;"> -->
     <ng-multiselect-dropdown class="success" placeholder="Select Rights" [settings]="dropdownSettings" [data]="AllRights"
       [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
       (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
 
     </ng-multiselect-dropdown><br />
   <!-- </div> -->
   </div>
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Location:</h5>
    <select (change)="OnselectUserLocation($event.target.value)" class="form-control dropdownsize">
      <option value="" >Select Location</option>
      <option *ngFor="let do of UserLocationList" value="{{ do.id }}" [selected]="do.id == selectedrole">
        {{ do.locationName }}
      </option>
    </select>
  </div>
</div>
  </div>
  
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="SaveUser()" (click)="d('Cross click')"
      style="color: black; cursor: pointer">
      Save
    </button>
  </div>
</div>
</ng-template>
<ng-template #updateuser let-d="dismiss" let-c="dismiss">
  <div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">Update User</h3>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Full Name:</h5>
    <input type="text" id="editfullname" [(ngModel)]="editfullname" class="form-control" name="dp" readonly><br />
  </div>
    <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Branch:</h5>
    <select (change)="OnselectUserbranch($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Branch</option>
      <option *ngFor="let do of BranchList" value="{{ do.id }}" [selected]="do.id == selectedrole">
        {{ do.branchName }}
      </option>
    </select><br />
  </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h5 class="modal-title" id="modal-basic-title">Role:</h5>
    <select (change)="OnselectUserRole($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Role</option>
      <option *ngFor="let do of RoleList" value="{{ do.id }}" [selected]="do.id == editrole">
        {{ do.roleName }}
      </option>
    </select><br />
  </div>
    <div class="col-md-6">
      <h5 class="modal-title" id="modal-basic-title">Department:</h5>
    <select (change)="OnselectUserDepartment($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Department</option>
      <option *ngFor="let do of DepartmentList" value="{{ do.id }}" [selected]="do.id == editdepartment">
        {{ do.majorDepartmentName }}- {{ do.code }}
      </option>
    </select><br />
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Sub Department:</h5>
    <select (change)="OnselectUserMinorDepartment($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Sub Department</option>
      <option *ngFor="let do of MajorGroupinMinorTblList" value="{{ do.id }}" [selected]="do.id == SelectedMinorDept">
        {{ do.minorDepartment }}
      </option>
    </select><br />
  </div>
    <div class="col-md-6">
      <h5 class="modal-title" id="modal-basic-title">Designation:</h5>
    <select (change)="OnselectUserDesignation($event.target.value)" class="form-control dropdownsize">
      <option value="">Select Designation</option>
      <option *ngFor="let do of DesignationList" value="{{ do.id }}" [selected]="do.id == editdesignation">
        {{ do.designationName }}
      </option>
    </select><br />
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Group:</h5>
  <select (change)="Onselectgroup($event.target.value)" class="form-control dropdownsize">
    <option value="">Select Group</option>
    <option *ngFor="let do of GroupList" value="{{ do.id }}" [selected]="do.id == editgroup">
      {{ do.groupName }}
    </option>
  </select><br />
</div>
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Rights:</h5>
    <ng-multiselect-dropdown [placeholder]="'Select Rights'" [settings]="dropdownSettings" [data]="AllRights"
      [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
      (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
    </ng-multiselect-dropdown><br />
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <h5 class="modal-title" id="modal-basic-title">Location:</h5>
  <select (change)="OnselectUserLocation($event.target.value)" class="form-control dropdownsize">
    <option value="">Select Location</option>
    <option *ngFor="let do of UserLocationList" value="{{ do.id }}" [selected]="do.id == editlocation">
      {{ do.locationName }}
    </option>
  </select><br />
</div>
</div>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="UpdateUser()" (click)="d('Cross click')"
      style="cursor: pointer">
      Update
    </button>
  </div>


</div>
</ng-template>


<ng-template #deactiveuser let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{userdet}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf = '!enterpriseacc'>
    <h2>Are your sure? Do you want to {{userstaus}} &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <div class="modal-body" *ngIf = 'enterpriseacc'>
    <h2>You cannot deactivate enterprise account</h2>
  </div>
  <br />
  <div class="modal-footer" *ngIf = '!enterpriseacc'>
    <button type="button" class="btn btn-success" (click)="Deactiveuser()" (click)="d('Cross click')"
      style="cursor: pointer">
      Ok
    </button>
    <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="color: black; cursor: pointer">
      Cancel
    </button>
  </div>
  <br />
</ng-template>
<ng-template #deleteuser let-d="dismiss" let-c="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete User</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>Are your sure? Do you want delete &nbsp;{{ DeleteName }}!!</h2>
  </div>
  <br />
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="DeleteUserRow()" (click)="d('Cross click')"
      style="cursor: pointer">
      Ok
    </button>
    <button type="button" class="btn btn-danger" (click)="d('Cross click')" style="color: black; cursor: pointer">
      Cancel
    </button>
  </div>
  <br />
</ng-template>



<div class="modal fade" id="Levels"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="margin-left: 32%; margin-top: 4%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
        Levels of Department
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="col-md-12">
            <div>
              <div *ngFor="let a of LevelList let i = index">
                <p>Level {{i + 1}} - {{a}}</p>
               </div>
            
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button"  data-dismiss="modal" class="btn btn-primary">
          OK
        </button>
      
      </div>
    </div>
  </div>
</div>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div class="alert alert-info" style="
        background-color: #545353;
        box-shadow: none;
        left: 145px;
        position: fixed;
        top: 480px;
        width: 350px;
      ">
      <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closealert()">
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white; margin-top: 6px">
        <b style="text-transform: capitalize; font-size: 14px">
          {{ notify }}</b></span>
    </div>
  </div>
</div>