import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { InternationalForm } from 'src/app/ViewModels/InternationalForm';
import { IndividualForm } from 'src/app/ViewModels/IndividualForm';
import { CorparateForm } from 'src/app/ViewModels/CorparateForm';
import { GovernmentForm } from 'src/app/ViewModels/GovernmentForm';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkFlowService } from 'src/app/Shared/WorkFlow/work-flow.service';
import { CountryService } from 'src/app/Shared/Country/country.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.css']
})
export class ClientRegistrationComponent implements OnInit {
  individual = new FormGroup({
    firstname: new FormControl(),
    lastname: new FormControl(),
    address: new FormControl(),
    pincode: new FormControl(),
    email: new FormControl(),
    mobile: new FormControl(),
    landline: new FormControl(),
    occupation: new FormControl(),
    qualification: new FormControl(),
    voterId: new FormControl(),
    authpersonname: new FormControl(),
    authpersonmobile: new FormControl(),
    authpersonemail: new FormControl(),
    authpersonpostaladdress: new FormControl(),
  });
  Corporate = new FormGroup({
    companyName: new FormControl(),
    eshtablishedDate: new FormControl(),
    companyCategory: new FormControl(),
    website: new FormControl(),
    officialEmailId: new FormControl(),
    CorporateofficialConntactNumber: new FormControl(),
    corporateDetailsaddress: new FormControl(),
    corporateDetailsbranch: new FormControl(),
    corporateDetailsemployeeStrength: new FormControl(),
    CorporatecontactPersonName: new FormControl(),
    CorporatecontactPersonMobileNumber: new FormControl(),
    CorporatecontactPersonEmail: new FormControl(),
    corporateDetailscontactPersonDesignation: new FormControl(),
    corporateDetailsEmpoweredName: new FormControl(),
    corporateDetailsEmpoweredDesignation: new FormControl(),
    corporateDetailssource: new FormControl(),
  });
  Government = new FormGroup({
    governmentCustomereshtablishedDate: new FormControl(),
    governmentCustomerwebsite: new FormControl(),
    GovernmentofficialEmail: new FormControl(),
    GovernmentofficialContactNumber: new FormControl(),
    governmentCustomercourtname: new FormControl(),
    governmentCustomeraddress: new FormControl(),
    governmentCustomerbranch: new FormControl(),
    governmentCustomerparties: new FormControl(),
    governmentCustomeremployeeStrength: new FormControl(),
    GovernmentcontactPersonName: new FormControl(),
    GovernmentcontactPersonMobileNumber: new FormControl(),
    governmentCustomercontactPersonEmail: new FormControl(),
    governmentCustomercontactPersonDesignation: new FormControl(),
    governmentCustomerEmpoweredName: new FormControl(),
    governmentCustomerEmpoweredDesignation: new FormControl(),
    governmentCustomersource: new FormControl(),
  });
  International = new FormGroup({
    InternationalfirstName: new FormControl(),
    InternationallastName: new FormControl(),
    Internationaladdress: new FormControl(),
    Internationalpincode: new FormControl(),
    Internationalemail: new FormControl(),
    Internationalmobile: new FormControl(),
    internationalcustomeroccupation: new FormControl(),
    internationalcustomerqualification: new FormControl(),
    internationalcustomersource: new FormControl(),

  });
  public dismissalert = false;
  notify: string
  eshtablishedDate: any
  companyName: any
  companyCategory: any
  website: any
  officialEmailId: any
  CorporateofficialConntactNumber: any
  corporateDetailsaddress: any
  corporateDetailsbranch: any
  corporateDetailsemployeeStrength: any
  CorporatecontactPersonName: any
  CorporatecontactPersonMobileNumber: any
  CorporatecontactPersonEmail: any
  corporateDetailscontactPersonDesignation: any
  corporateDetailsEmpoweredName: any
  corporateDetailsEmpoweredDesignation: any
  corporateDetailssource: any
  governmentCustomereshtablishedDate: any
  governmentCustomerwebsite: any
  GovernmentofficialEmail: any
  GovernmentofficialContactNumber: any
  governmentCustomercourtname: any
  governmentCustomeraddress: any
  governmentCustomerbranch: any
  governmentCustomerparties: any
  governmentCustomeremployeeStrength: any
  GovernmentcontactPersonName: any
  GovernmentcontactPersonMobileNumber: any
  governmentCustomercontactPersonEmail: any
  governmentCustomercontactPersonDesignation: any
  governmentCustomerEmpoweredName: any
  governmentCustomerEmpoweredDesignation: any
  governmentCustomersource: any
  InternationalfirstName: any
  Internationaladdress: any
  Internationalpincode: any
  Internationalemail: any
  Internationalmobile: any
  internationalcustomeroccupation: any
  internationalcustomerqualification: any
  internationalcustomersource: any
  InternationallastName: any
  firstname: any
  lastname: any
  address: any
  pincode: any
  email: any
  mobile: any
  landline: any
  occupation: any
  qualification: any
  voterId: any
  authpersonname: any
  authpersonmobile: any
  authpersonemail: any
  authpersonpostaladdress: any
  Genders: any;
  Subcategories: any;
  CorporateSubcategories: any;
  submitted:any=false;
  CountryList: any;
  GenderId: any;
  SelectedGenderId: any;
  SelectedCountryId: any;
  SelectedSubcategoryId: any;
  selectedGovtId: any;
  selectedCorporateId: any;
  displaybranch: boolean=false
  gcusparties: string;
    get i() { return this.individual.controls; }
  get c() { return this.Corporate.controls; }
  get g() { return this.Government.controls; }
  get f() { return this.International.controls; }
  constructor(private formBuilder: FormBuilder,private spinner:NgxSpinnerService,private workflowservice:WorkFlowService,
    private countryService:CountryService) {
    this.individual = formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      landline: ['', Validators.required],
      occupation: ['', Validators.required],
      qualification: ['', Validators.required],
      voterId: ['', Validators.required],
      authpersonpostaladdress: ['', Validators.required],
      authpersonmobile: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      authpersonname: ['', Validators.required],
      authpersonemail: ['', [Validators.required, Validators.email]],

    })
    this.Corporate = formBuilder.group({
      companyName: ['', Validators.required],
      eshtablishedDate: ['', Validators.required],
      companyCategory: ['', Validators.required],
      website: ['', Validators.required],
      officialEmailId: ['', [Validators.required, Validators.email]],
      CorporateofficialConntactNumber: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      corporateDetailsaddress: ['', Validators.required],
      corporateDetailsbranch: ['', Validators.required],
      corporateDetailsemployeeStrength: ['', Validators.required],
      CorporatecontactPersonName: ['', Validators.required],
      CorporatecontactPersonMobileNumber: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      CorporatecontactPersonEmail: ['', [Validators.required, Validators.email]],
      corporateDetailscontactPersonDesignation: ['', Validators.required],
      corporateDetailsEmpoweredName: ['', Validators.required],
      corporateDetailsEmpoweredDesignation: ['', Validators.required],
      corporateDetailssource: ['', Validators.required]
    })
    this.Government = formBuilder.group({
      governmentCustomereshtablishedDate: ['', Validators.required],
      governmentCustomerwebsite: ['', Validators.required],
      GovernmentofficialEmail: ['', [Validators.required, Validators.email]],
      GovernmentofficialContactNumber: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      governmentCustomercourtname: [''],
      governmentCustomeraddress: [''],
      governmentCustomerbranch: [''],
      governmentCustomerparties: [''],
      governmentCustomeremployeeStrength: ['', Validators.required],
      GovernmentcontactPersonName: ['', Validators.required],
      GovernmentcontactPersonMobileNumber: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      governmentCustomercontactPersonEmail: ['', [Validators.required, Validators.email]],
      governmentCustomercontactPersonDesignation: ['', Validators.required],
      governmentCustomerEmpoweredName: ['', Validators.required],
      governmentCustomerEmpoweredDesignation: ['', Validators.required],
      governmentCustomersource: ['', Validators.required],
    })
    this.International = formBuilder.group({
      InternationalfirstName: ['', Validators.required],
      Internationaladdress: ['', Validators.required],
      Internationalpincode: ['', Validators.required],
      Internationalemail: ['', [Validators.required, Validators.email]],
      Internationalmobile: ['', [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern(/^[6-9]\d{9}$/)]],
      internationalcustomerqualification: ['', Validators.required],
      internationalcustomeroccupation: ['', Validators.required],
      internationalcustomersource: ['', Validators.required],
      InternationallastName: ['', Validators.required],

    })
  }

  ngOnInit(): void {
    this.GetGenders();
    this.GetCorporateSubcategories();
    this.GetSubcategories();
    this.Countries();
  }
  GetGenders(){
    this.spinner.show();
    this.workflowservice.getGenders().subscribe((response: any) => {
      this.Genders = response;
      this.spinner.hide();
    })
  }
  GetCorporateSubcategories(){
    this.spinner.show();
    this.workflowservice.getCorporateSubcategories().subscribe((response: any) => {
      this.CorporateSubcategories = response;
      this.spinner.hide();
    })
  }
  GetSubcategories(){
    this.spinner.show();
    this.workflowservice.getSubcategories().subscribe((response: any) => {
      this.Subcategories = response;
      this.spinner.hide();
    })
  }
  Countries() {
    this.spinner.show();
    this.countryService.getAllCountries().subscribe((Data: any) => {
      this.CountryList = Data;
      this.spinner.hide();

    })
  }
  PostInternationalCustomer() {
    this.submitted = true;
    if (this.International.invalid ){
      return;
    }
    else{
    const data: InternationalForm = {
      Address: this.International.controls["Internationaladdress"].value,
      Email: this.International.controls["Internationalemail"].value,
      FirstName: this.International.controls["InternationalfirstName"].value,
      LastName: this.International.controls["InternationallastName"].value,
      Mobile: this.International.controls["Internationalmobile"].value,
      PinCode : this.International.controls["Internationalpincode"].value,
      Occupation: this.International.controls["internationalcustomeroccupation"].value,
      Qualification: this.International.controls["internationalcustomerqualification"].value,
      Source: this.International.controls["internationalcustomersource"].value,
      Gender:this.SelectedGenderId
    }
    this.spinner.show();
    this.workflowservice.SaveInternationalCustomer(data).subscribe((response: any) => {
      if (response == true) {
        this.notify = "Registered Successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
      this.spinner.hide();
    })
  }
  }
  PostIndividualCustomer() {
    this.submitted = true;
    if (this.individual.invalid ){
      return;
    }
    else{
    const data: IndividualForm = {
      FirstName: this.individual.controls["firstname"].value,
      LastName: this.individual.controls["lastname"].value,
      LandLineNumber: this.individual.controls["landline"].value,
      Mobile: this.individual.controls["mobile"].value,
      Address: this.individual.controls["address"].value,
      PinCode: this.individual.controls["pincode"].value,
      Occupation: this.individual.controls["occupation"].value,
      Qualification: this.individual.controls["qualification"].value,
      Email: this.individual.controls["email"].value,
      VoterId: this.individual.controls["voterId"].value,
      AuthorisedPersonEmailId: this.individual.controls["authpersonemail"].value,
      AuthorisedPersonMobile: this.individual.controls["authpersonmobile"].value,
      AuthorisedPersonName: this.individual.controls["authpersonname"].value,
      AuthorisedPersonPostalAddress: this.individual.controls["authpersonpostaladdress"].value,
      Gender:this.SelectedGenderId

    }
    this.spinner.show();
    this.workflowservice.SaveIndividualCustomer(data).subscribe((response: any) => {
      if (response == true) {
        this.notify = "Registered Successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
      this.spinner.hide();
    })
  }
  }
  PostCustomerCorporateDetails() {
    this.submitted = true;
    if (this.Corporate.invalid ){
      return;
    }
    else{
    const data: CorparateForm = {
      EshtablishedDate: this.Corporate.controls["eshtablishedDate"].value,
      CompanyName: this.Corporate.controls["companyName"].value,
      CompanyCategory: this.Corporate.controls["companyCategory"].value,
      Website: this.Corporate.controls["website"].value,
      OfficialEmail: this.Corporate.controls["officialEmailId"].value,
      Address: this.Corporate.controls["corporateDetailsaddress"].value,
      Branch: this.Corporate.controls["corporateDetailsbranch"].value,
      ContactPersonDesignation: this.Corporate.controls["corporateDetailscontactPersonDesignation"].value,
      EmployeeStrength: this.Corporate.controls["corporateDetailsemployeeStrength"].value,
      EmpoweredDesignation: this.Corporate.controls["corporateDetailsEmpoweredDesignation"].value,
      EmpoweredName: this.Corporate.controls["corporateDetailsEmpoweredName"].value,
      Source: this.Corporate.controls["corporateDetailssource"].value,
      ContactPersonEmail: this.Corporate.controls["CorporatecontactPersonEmail"].value,
      ContactPersonMobileNumber: this.Corporate.controls["CorporatecontactPersonMobileNumber"].value,
      ContactPersonName: this.Corporate.controls["CorporatecontactPersonName"].value,
      OfficialConntactNumber: this.Corporate.controls["CorporateofficialConntactNumber"].value,
      CorporateSubCategory: this.selectedCorporateId
    }
    this.spinner.show();
    this.workflowservice.SaveCorporateCustomer(data).subscribe((response: any) => {
      if (response == true) {
        this.notify = "Registered Successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
      this.spinner.hide();
    })
  }
  }
  PostCustomerGovernmentDetails() { 
    this.submitted = true;
    if (this.Government.invalid ){
      return;
    }
    if(this.Government.controls["governmentCustomerparties"].value==undefined){
      this.gcusparties="";
    }
    else{
    const data: GovernmentForm = {
      EmpoweredDesignation: this.Government.controls["governmentCustomerEmpoweredDesignation"].value,
      EmpoweredName: this.Government.controls["governmentCustomerEmpoweredName"].value,
      Address: this.Government.controls["governmentCustomeraddress"].value,
      Branch: this.Government.controls["governmentCustomerbranch"].value,
      ContactPersonDesignation: this.Government.controls["governmentCustomercontactPersonDesignation"].value,
      ContactPersonEmail: this.Government.controls["governmentCustomercontactPersonEmail"].value,
      CourtName: this.Government.controls["governmentCustomercourtname"].value,
      EmployeeStrength: this.Government.controls["governmentCustomeremployeeStrength"].value,
      EshtablishedDate: this.Government.controls["governmentCustomereshtablishedDate"].value,
      PartiesName: this.gcusparties,
      Source: this.Government.controls["governmentCustomersource"].value,
      Website: this.Government.controls["governmentCustomerwebsite"].value,
      ContactPersonMobileNumber: this.Government.controls["GovernmentcontactPersonMobileNumber"].value,
      ContactPersonName: this.Government.controls["GovernmentcontactPersonName"].value,
      OfficialContactNumber: this.Government.controls["GovernmentofficialContactNumber"].value,
      OfficialEmail: this.Government.controls["GovernmentofficialEmail"].value,
      SubCategory:this.SelectedSubcategoryId
    }
    this.spinner.show();
    this.workflowservice.SaveGovernmentCustomer(data).subscribe((response: any) => {
      if (response == true) {
        this.notify = "Registered Successfully"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      } else {
        this.notify = "Something Went Wrong. Try again.!!"
        setTimeout(() => this.dismissalert = true, 100);
        setTimeout(function () {
          this.dismissalert = false;
        }.bind(this), 3000);
        this.spinner.hide();
      }
    })
  }
  }
  OnSelectCountry(id) {
      this.SelectedCountryId=id
  }
  OnselectGender(id) {
    this.SelectedGenderId=id
  }
  OnselectGenderCorporate(id) {
    this.SelectedGenderId=id

  }
  changewebsitelink(id){
    this.SelectedSubcategoryId=id
    if(this.SelectedSubcategoryId=='2' || this.SelectedSubcategoryId=='3'){
          this.displaybranch=true;

    }

  }
  OnselectGovernmentType(id){
    this.selectedGovtId=id
  }
  OnselectCorporate(id){
    this.selectedCorporateId=id

  }
  closealert(){
    this.dismissalert=false
  }
}
