<app-nav-bar></app-nav-bar>
<!-- <app-sidebar *ngIf="roleid=='1' || roleid=='7' || roleid=='8'"></app-sidebar> -->
<div class="register">
    <h2 style="text-align: center">Approved Projects</h2>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <div style="overflow-x: visible; height: 300px">
                    <table class="table table-responsive" style="border-collapse: collapse">
                        <tr class="thead-dark" style="font-size: 17px">
                            <th class="text-center">SI No</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Description</th>
                            <th class="text-center">Purpose</th>
                            <th class="text-center">Priority</th>
                            <th class="text-center">Project Code</th>
                            <th class="text-center">Lead</th>
                            <th class="text-center">StartDate</th>
                            <th class="text-center">Objectives</th>
                            <th class="text-center">Estimsted Time(in Hrs)</th>
                            <th class="text-center">Estimated Cost(in Rs)</th>
                            <th class="text-center">Client</th>
                            <th>View Documents</th>
                            <th>History</th>
                        </tr>
                        <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                        <tr *ngFor="
                let aa of ProjectList
                  | paginate: { itemsPerPage: 5, currentPage: p };
                let i = index
              " style="
                font-size: 16px;
                color: rgb(0, 0, 0);
                font-family: DINNextRoundedLTPro;
              ">
                            <td>{{ 5 * (p - 1) + (i + 1) }}</td>
                            <td>{{ aa.projectName }}</td>
                            <td>{{ aa.projectDescription }}</td>
                            <td>{{ aa.purposeOfProject }}</td>
                            <td>{{ aa.priority }}</td>
                            <td>{{ aa.projectCode }}</td>
                            <td>{{ GetUser(aa.projectLead) }}</td>
                            <td>{{ aa.startDate | date: "short" }}</td>
                            <td>{{ aa.objectives }}</td>
                            <td>{{ aa.estimstedTime }}</td>
                            <td>{{ aa.estimatedCost }}</td>
                            <td>{{ GetClient(aa.client) }}</td>
                            <td>
                                <button class="btn btn-info" Info pageScroll href="#showfiles" (click)="ViewProjectFiles(aa)" style="width: 101%">
                  View Objects
                </button>
                            </td>
                            <td>
                                <button class="btn btn-primary" (click)="History(aa)">
                  History
                </button>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
                    <br /><br />
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
</div>

<br /><br />
<div class="form-group" *ngIf="ViewDoc">
    <div class="col-md-12 row">
        <div class="col-md-4"></div>
        <div class="col-md-8">
            <h2>Documents For Reference</h2>
            <br /><br />
            <table class="table table-responsive" id="showfiles">
                <tr class="thead-dark">
                    <th style="display: none">ID</th>
                    <th>Document</th>
                    <th>Download</th>
                </tr>
                <tr *ngFor="let ret of ProjectFileList; let i = index">
                    <td>
                        <div *ngIf="
                ret.documentType == 'image/jpeg' ||
                ret.documentType == 'image/png' ||
                ret.documentType == 'image/jpg'
              ">
                            <img [src]="ret.file" style="
                  width: 50px;
                  height: 50px;
                  margin-left: 15px;
                  cursor: pointer;
                " (click)="gotopdfViewForAssignTask(ret)" />
                            <span style="cursor: pointer">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
                        </div>
                        <div *ngIf="
                ret.documentType == 'application/pdf' ||
                ret.documentType == 'pdf'
              ">
                            <img src="./assets/img/pdficon.png" style="
                  width: 35px;
                  height: 34px;
                  cursor: pointer;
                  margin-left: 15px;
                " (click)="gotopdfViewForAssignTask(ret)" />
                            <span style="cursor: pointer">
                &nbsp;&nbsp;{{ ret.documentName }}
              </span>
                        </div>
                        <div *ngIf="
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                ret.documentType == 'xlsx' ||
                ret.documentType == 'xls' ||
                ret.documentType == 'application/vnd.ms-excel'
              " (click)="gotopdfViewForAssignTask(ret)">
                            <img src="./assets/img/xls.png" style="width: 35px; height: 34px; cursor: pointer" />
                            <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
                        </div>
                        <div *ngIf="
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                ret.documentType == 'ppt' ||
                ret.documentType == 'pptx'
              " (click)="gotopdfViewForAssignTask(ret)">
                            <img src="./assets/img/ppticon2.png" style="width: 35px; height: 34px; cursor: pointer" />
                            <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
                        </div>

                        <div *ngIf="
                ret.documentType ==
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                ret.documentType == 'docx'
              " (click)="gotopdfViewForAssignTask(ret)">
                            <img src="./assets/img/docx.png" style="width: 35px; height: 34px; cursor: pointer" />
                            <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
                        </div>
                        <div *ngIf="ret.documentType == 'text/plain'">
                            <img src="./assets/img/txt.png" style="width: 35px; height: 34px; cursor: pointer" (click)="gotopdfViewForAssignTask(ret)" />

                            <span style="cursor: pointer">
                {{ ret.documentName }}
              </span>
                        </div>
                    </td>
                    <td>
                        <a (click)="DownloadFile(ret)" download><button class="btn btn-primary">
                <i class="fa fa-download"></i>Download
              </button></a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<br /><br />