<!-- -------------------------footer-------------- -->
<header class="masthead1 xyz" id="footer" style="min-height: auto;color: white;">
    <footer class="footer" style="z-index: 3">
        <div class="container">
            <div class="row">
                <div class="col-lg-3" style="color: white">
                    <div class="footer__widget" style="color: white">
                        <a href="#" class="brand">
                            <!--...... <img src="../assets/img/blocdriverz.png" class="logo" alt="Smooth" width="160px" height="100px" /> -->
                            <img src="../assets/img/blocdrive1footer.svg" loading="lazy" class="logo" alt="Smooth" width="118px" height="65px" />
                        </a>
                        <br /><br />
                        <p style="color: white;">Blocdrive Private Limited</p>
                 
                    </div>
                    <ul class="footer__widget-network">
                     
                        <li>
                            <a href="https://twitter.com/Blocdrive1" class="footer__widget-network-link" aria-label="Twitter login"><i class="fa fa-twitter"></i
              ></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/blocdrive-blockchain-a8710b1b8/" aria-label="linkedin login" class="footer__widget-network-link"><i class="fa fa-linkedin"></i
              ></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/srichid.technology/ " aria-label="facebook login" class="footer__widget-network-link"><i class="fa fa-facebook"></i
              ></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/bloc_drive/" aria-label="instagram login" class="footer__widget-network-link"><i class="fa fa-instagram"></i
              ></a>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-lg-2 col-sm-3"></div>
                <div class="col-6 col-lg-2 col-sm-3">  <div class="footer__widget">
                    <!--...... <h4 class="footer__widget-title"></h4> -->
                    <ul class="footer__widget-linklist">
                        <li id="abt" style="cursor: pointer;">Contact Us</li>
                        <!--...... <div class="footer__widget-contact">
                            <i class="fa fa-phone"></i>
                            <p style="color: white">+91-80-26850251</p>
                        </div>
                        <div class="footer__widget-contact">
                            <i class="fa fa-envelope"></i>
                            <p style="color: white">support@blocdrive.com</p>
                        </div> ......-->

                        <li class="fa fa-phone"  style="color: white;">+91-80-26850251</li>

                        <li class="fa fa-envelope" style="color: white;">support@blocdrive.com</li>

                     
                    </ul>
                </div></div>
                <div class="col-6 col-lg-2 col-sm-3"></div>
                <div class="col-6 col-lg-2 col-sm-3">
                    <div class="footer__widget">
                        <!--...... <h4 class="footer__widget-title"></h4> -->
                        <ul class="footer__widget-linklist">
                            <li><a id="abtt" [routerLink]="['/aboutus']" style="cursor: pointer;">About us</a></li>
                            <!--...... <li><a id="abt" [routerLink]="['/aboutus']" style="cursor: pointer">About us</a></li> -->

                            <li><a [routerLink]="['/privacy']" >Privacy</a></li>
                            <li>
                                <a [routerLink]="['/termsofservice']" >Terms of Service</a>
                            </li>
                            <li><a [routerLink]="['/howto']" >Support</a></li>
                            <li><a [routerLink]="['/faq']" >FAQ</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="footer__subfooter">
                        <div class="row">
                            <div class="col-md-6 text-left">
                                <ul class="footer__subfooter-liststyle">
                                    <li style="color: white;">Blocdrive Private Limited © 2024
                  </li>
                </ul>
                <!-- <p>© 2020 <a [routerLink]="['']" style="cursor: pointer;">Srichid Blockchain Services Pvt. Ltd.</a> All Rights Reserved.</p> -->
                            </div>
                            <div class="col-md-6 text-right text-white">
                                <ul class="footer__subfooter-liststyle">
                                    <li >All Rights Reserved</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</header>