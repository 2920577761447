<app-nav-bar></app-nav-bar>

<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<app-home-sidebar *ngIf="mobileview"></app-home-sidebar>

<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100">
            <div class="login100-pic js-tilt">
                <img src="../assets/img/img-01.png" aTilt alt="IMG">
            </div>

            <form class="login100-form" [formGroup]="loginForm">
                <span class="login100-form-title">
                    Member Login
                </span>

                <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                    <input  formControlName="email"  [(ngModel)]="email"  class="input100" type="text" name="email" placeholder="Email">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                </div>

                <div class="wrap-input100 validate-input">
                    <input formControlName="password"  [(ngModel)]="password"  class="input100" type="password" name="pass" placeholder="Password">
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                        <i class="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>

                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" (click)="OnSubmit()">
                        Login
                    </button>
                </div>
                <div>&nbsp;</div>
                <div *ngIf="isLoginError">
                    <h5 style="color: red;font-size: 18px;">Incorrect Username or Password!!</h5>
                </div>

                <div class="text-center p-t-136">
                    <a class="txt2" href="#/registration">
                        Create your Account
                        <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>