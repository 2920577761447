import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppGlobals } from 'src/app/app.global';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient, private _urls: AppGlobals) { }
  getprojetlist(id:any){
    return this.http.get(this._urls.ANAURL+"ProjectCreation/Get/"+id)
  }
  savenewproject(data: any) {
    return this.http.post(this._urls.ANAURL + "ProjectCreation/Post_projectCreation", data);
  }
  getFiles(id:any){
    return this.http.get(this._urls.ANAURL+"fileUpload/Get/"+id)
  }
  excelUpload(id:any){
    return this.http.get(this._urls.ANAURL+"excelUpload/PostInsertExcelRecords/"+id)
  }
  getCsvfiles(id:any){
    return this.http.get(this._urls.ANAURL+"fileUpload/GetCsvFiles/"+id)
  }
  getColumnName(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetColumnNames/"+id)
  }
  getValue(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetTableValue/"+id)
  }
  getColumnName1(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetColumnNames1/"+id)
  }
  getValue1(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetTableValue1/"+id)
  }
  getColumnName2(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetColumnNames2/"+id)
  }
  getValue2(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetTableValue2/"+id)
  }
  getColumnName3(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetColumnNames3/"+id)
  }
  getValue3(id:any){
    return this.http.get(this._urls.ANAURL+"dataView/GetTableValue3/"+id)
  }

  deleteProject(projectId:any) {
    return this.http.delete(this._urls.ANAURL + 'AuRoleMaster/Deleteprojectcreation/' + projectId);
  }
}
