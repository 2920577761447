import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientapprovalService } from '../Shared/ClientCreation/clientapproval.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';
import { AddNewFolder } from '../ViewModels/NewFolder';
import { OtpViewModelClient } from '../ViewModels/OtpViewModel';
import { Signup } from '../ViewModels/Signup';

@Component({
  selector: 'app-clientapproval',
  templateUrl: './clientapproval.component.html',
  styleUrls: ['./clientapproval.component.css']
})
export class ClientapprovalComponent implements OnInit {

    spinner: any;
    onlyphone: any;
    phonetwo: any;
    phonev: any;
    regexp: any;
    checkemail: any;
    otp: any;
    SelectedFiles: any;
    UserDetails: any;
  
  
    role: any;
    confirm: any;
    expired: boolean;
    userrole: number;
    Email: string;
    clientdatalist: any;
    clientName: any;
    clientEmail: any;
    clientdetails: any;
    Id: any;
    i: any;
    Password: string;
    otpp: any;
    EnterPriseId: string;
  msg: string;
  GUID: string;
  roleid: string;
  uid: string;
  userid: any;
  Unregister: boolean = false;
  registered: boolean = false;
  data: any;
  username: any;
   
  
    constructor(private clientapproval: ClientapprovalService,private userservice: UserDetailsService,private http: HttpClient,private router: ActivatedRoute,private route: Router) {
      this.router.params.subscribe(params => {
        if (params["id"]) {
          // localStorage.folderid = params['id'];
          this.Getallclients(params["id"]);
          this.Getcheckusers(params["id"])
          this.EnterPriseId = localStorage.getItem("EnterPriseId");
          this.uid = localStorage.getItem("userId");
          this.roleid = localStorage.getItem("role"); 
          this.GUID=localStorage.getItem("GUID");
        }
      });
     }
  
    ngOnInit(): void {
  
     this.GetALLClientsDetails();
  
    }
  
  
    resendotp(form: NgForm) {
      this.otp = null;
      this.expired = false;
      this.OnSubmit();
    }
  
    Getcheckusers(id){
      this.clientapproval.getchechusers(id).subscribe((response: any) => {
        this.data = response;
        if(this.data == "1"){
          this.registered = true;
          this.Unregister = false;
        }
        else{
          this.Unregister = true;
          this.registered = false;
        }

      });
    }
  
    Getallclients(id) {
      this.clientapproval.getAllClients(id).subscribe((response: any) => {
        this.clientdatalist = response;
        console.log(this.clientdatalist);
          this.clientName = this.clientdatalist.clientName;
          this.clientEmail = this.clientdatalist.clientEmail;
          this.userid = this.clientdatalist.fromUserId;
          this.username = this.clientdatalist.userName;
      })
    }
  
    savenewClient() {
      var offset = new Date().getTimezoneOffset();
      var nd = 0.0;
      nd = -(parseFloat(offset.toString()));
        const inputRequest: AddNewFolder = {
          Id: 0,
          FolderID: "0",
          RoleId: this.roleid,
          FolderName: this.clientName,
          UserId: this.userid,
          GUID:null,
          Code: "Client",
          ParentID: "0",
          Click: null,
          date: nd
        }
        this.userservice.addclient(inputRequest).subscribe((dat: any) => {
          if (dat == 1) {
            alert("Added your client default folders successfully");
            this.route.navigate(['/home']);
          }
          else {
            alert("Something went wrong! Please Try Again")
          }
   
          this.msg = "";
        });
      
    }
  
  
  GetALLClientsDetails() {
   // this.spinner.show();
    this.clientapproval.getclientdetails().subscribe((respose: any) => {
      this.clientdatalist = respose;
      this.spinner.hide();
  
    });
  }
  
    OnSubmit() {
     if (!this.onlyphone) {
        this.checkemail = this.clientEmail;
      }
  
      if (this.otp) {
        this.OnSubmitOtp();
        // this.spinner.hide();
      }
  
      else {
        if (this.clientName == null || this.clientName == "") {
         // this.spinner.hide();
          alert("Please enter your UserName");
  
        }
        else if (this.Password == null || this.Password == "") {
          // this.spinner.hide();
          alert("Please enter your Password");
        }
        // else if (form.controls["phone"].value == undefined || form.controls["phone"].value == "" || form.controls["phone"].value == null) {
        //   this.spinner.hide();
        //   alert("Please enter phone number");
        // }
        else if (!this.onlyphone && !this.checkemail) {
          alert('Please enter valid Email');
        }
        else if (this.onlyphone && !this.phonetwo) {
          alert('Please enter valid Mobile Number');
        }
        // else if (this.SelectedFiles == null || this.SelectedFiles == undefined) {
        //   alert('Please Upload Your Picture');
        // }
        else {
          if (!this.onlyphone) {
  
            const inputRequest1: Signup = {
              Id: 0,
              FirstName: this.clientName,
              LastName: null,
              UserName: null,
              Email:this.clientEmail,
              // Email: this.Email,
              PhoneNumber: null,
              Password: this.Password,
              ConfirmPassword: this.Password,
              ProvinceId: null,
              PlanId: +localStorage.ref
            }
            this.UserDetails = inputRequest1;
  
  
  
          }
          else {
  
  
            const inputRequest1: Signup = {
              Id: 0,
              FirstName: this.clientName,
              LastName: null,
              UserName: null,
              Email: null,
              PhoneNumber:null,
              Password: this.Password,
              ConfirmPassword: this.Password,
              ProvinceId: null,
              PlanId: +localStorage.ref
            }
            this.UserDetails = inputRequest1;
  
          }
        //  this.spinner.show();
          this.clientapproval.saveDetailsClient(this.UserDetails).subscribe((data: any) => {
            console.log(data)
            if (data == "1") {
              if (this.onlyphone) {
                alert("Otp has been sent to your Registered Phone Number..!!! Please Enter the otp to complete registration process");
              }
              else {
                alert("Otp has been sent to your Registered Email..!!! Please Enter the otp to complete registration process");
  
              }
              this.otp = true;
            }
            else if (data == "0") {
              if (this.onlyphone) {
                alert("Phone Number already exists");
              }
              else {
                alert("Email Id already exists");
  
              }
              location.reload();
            }
            else {
              alert(data);
            }
           // this.spinner.hide();
          });
  
        }
      }
    }
    OnSubmitOtp() {
      this.userrole = 1;
      if(this.confirm==true){
        this.userrole = 9;
      }
  
      if (!this.onlyphone) {
        if (this.otp == null || this.otpp == "") {
         // this.spinner.hide();
          alert("Please enter your Otp");
        }
        else if (this.otpp<= 5 || this.otpp > 10) {
        //  this.spinner.hide();
          alert("Please enter valid Otp");
        }
        else {
          const inputRequest: OtpViewModelClient = {
            Id: 0,
            Email: this.clientEmail,
            EnterpriseId:this.EnterPriseId,
            Otp: this.otp,
            FirstName: this.UserDetails.FirstName,
            LastName: null,
            UserName: null,
            PhoneNumber: this.UserDetails.PhoneNumber,
            Password: this.UserDetails.Password,
            ConfirmPassword: this.UserDetails.ConfirmPassword,
            ProvinceId: this.role,
            PlanId: 0,
            Auditor: this.confirm,
            AccessToken: null
          }
         // this.spinner.show();
          const frmData = new FormData();
          frmData.append("credentials", JSON.stringify(inputRequest));
          frmData.append("picture", this.SelectedFiles);
         // this.spinner.show();
  
          this.http.post('https://blocdrive.in/blocdriveapi/api/ClientRegistration/OTPValidationClient/', frmData).subscribe(
  
            // this.userService.postVerifyOTP(inputRequest).subscribe(
            (data: any) => {
              if (data == "0") {
                alert("Registered successfully");
                this.savenewClient() 
                this.route.navigate(['/login']);
              }
              else if (data == "1") {
                alert("Incorrect OTP");
              }
              else if (data == "2") {
                alert("OTP Expired");
                this.expired = true;
              }
              else {
                alert("Something Went Wrong. Try again.!!");
              }
             // this.spinner.hide();
            });
  
        }
      }
      else {
        if (this.otpp == null || this.otpp == "") {
          //this.spinner.hide();
          alert("Please Enter Your Otp");
        }
        else if (this.otpp <= 5 || this.otpp > 10) {
         // this.spinner.hide();
          alert("Please Enter valid Otp");
        }
        else {
          const inputRequest: OtpViewModelClient = {
            Id: 0,
            Email: this.UserDetails.clientEmail,
            EnterpriseId:this.EnterPriseId,
            Otp: this.otpp,
            FirstName: this.UserDetails.FirstName,
            LastName: null,
            UserName: null,
            Auditor: this.confirm,
            PhoneNumber: this.UserDetails.PhoneNumber,
            Password: this.UserDetails.Password,
            ConfirmPassword: this.UserDetails.ConfirmPassword,
            ProvinceId:  this.role,
            PlanId:0,
            AccessToken: null
          }
          const frmData = new FormData();
          frmData.append("credentials", JSON.stringify(inputRequest));
          frmData.append("picture", this.SelectedFiles);
        //  this.spinner.show();
  
          this.http.post('https://blocdrive.in/blocdriveapi/api/ClientRegistration/OTPValidationClient/', frmData).subscribe(
  
            // this.userService.postVerifyOTP(inputRequest).subscribe(
            (data: any) => {
              if (data == "0") {
                alert("Registered successfully");
               // this.savenewClient();
               // this.router.navigate(['/login']);
              }
              else if (data == "1") {
                alert("Incorrect OTP");
              }
              else if (data == "2") {
                alert("OTP Expired");
              }
              else {
                alert("Something Went Wrong. Try again.!!");
              }
            //  this.spinner.hide();
            });
        }
      }
  
  
    }

    
  }
