<app-nav-bar></app-nav-bar>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row" id="four">
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-12 ">&nbsp;</div>
    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-4">
        <img src="./assets/img/backimage.png" style="cursor: pointer;" title="Back" height="21px" (click)=goBack() />
    </div>
</div>
<div class="col-md-12 row">
    <div class="col-md-8"></div>
    <div class="col-md-2">
        <button class="btn btn-primary" (click)="DownloadForm()">Download</button>
    </div>
    <div class="col-md-2">
        <button class="btn btn-info" (click)="sndpdftoprint()">Print</button>
    </div>
</div>
<div id="print-section">
    <div class="col-md-12">&nbsp;</div>
<h2 style="text-align: center;"> INWARD DOCUMENT</h2>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Object Number:</label>&nbsp;{{GetFilePlan(File) }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">Folder:</label>&nbsp;{{Folderid }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Registered By:</label>&nbsp;{{ReceivedFrom }}

    </div>
</div>
<div class="col-md-12 row ">&nbsp;</div>
<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Exhibits:</label>&nbsp;{{Subject }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Inward Date:</label>&nbsp;{{InwardDate }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel"> Inward No:</label>&nbsp;{{InwardNumber }}
    </div>
</div>
<div class="col-md-12">&nbsp;</div>

<div class="col-md-12 row">
    <div class="col-md-2 row">&nbsp;</div>
    <div class="col-md-3 row" style="display: flex;">
        <label id="emaillabel">Document Type:</label>&nbsp;{{GetDocumentTypeName(DocumentId) }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel ">Circulation Group:</label>&nbsp;{{CirculationGroup }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel">Inward Type:</label>&nbsp;{{GetInwardTypeName(InwardType) }}

    </div>
</div>
<div class="col-md-12 row ">&nbsp;</div>
<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Priority:</label>&nbsp;{{GetPriorityName(Sensitivity) }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email" id="emaillabel">Note(s):</label>&nbsp;{{Note }}

    </div>
    <div class="col-md-1 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label for="Email " id="emaillabel"> Workflow Stage:</label>&nbsp;{{GetWorkflowStageName(Workflowstage) }}
    </div>
</div>
<div class="col-md-12">&nbsp;</div>

<div class="col-md-12 row ">
    <div class="col-md-2 row ">&nbsp;</div>
    <div class="col-md-3 row ">
        <label id="emaillabel">Pages:</label>&nbsp;{{Pages }}
    </div>
    <div class="col-md-1 row ">&nbsp;</div>
   
</div>

<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div><div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
</div>
