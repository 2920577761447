
<app-nav-bar></app-nav-bar>




<div class="row">
    <div class="col-lg-12">
        <div class="box">
            <!--mail inbox start-->
            <div class="mail-box">
                <aside class="sm-side">
                    <div class="user-head">

                        <div class="user-name">
                            <h5><a href="#">NAME</a></h5>
                            <span><a href="#">EMAIL</a></span>
                        </div>

                    </div>
                    <div class="inbox-body">
                        <a class="btn btn-compose" data-toggle="modal" (click)="composemail()" data-original-title="" title="">
                        Compose
                    </a>


                    </div>

                    <ul class="inbox-nav inbox-divider">
                        <li class="active">
                            <a (click)="inbox()"><i class="fa fa-inbox"></i> Inbox </a>
                        </li>
                        <li>
                            <a (click)="sentitems()"><i class="fa fa-envelope-o"></i> Sent Mail</a>
                        </li>

                        <!-- <li>
                            <a (click)="archiveitems()"><i  class="fas fa-download"></i> Archive</a>
                        </li> -->
                        <li>
                            <a (click)="deleteitems()"><i class=" fa fa-trash-o"></i> Trash</a>
                        </li>
                    </ul>


                </aside>
                <aside class="lg-side">
                    <div class="inbox-head">
                        <h3>Deleted Mails</h3>
                        <!-- <form class="pull-right position" action="#">
                            <div class="input-append">
                                <input type="text" placeholder="Search Mail" class="sr-input">
                                <button type="button" class="btn sr-btn" data-original-title="" title=""><i class="fa fa-search"></i></button>
                            </div>
                        </form> -->
                    </div>
                    <div class="inbox-body">
                    
                        <table class="table table-inbox table-hover">
                            <tbody>
                                <tr class="unread" id="{{ret.Id}}" style="cursor: pointer;" *ngFor="let ret of deleteListItems">
                    
                                    <!-- <td class="inbox-small-cells">
                                        <input type="checkbox" class="mail-checkbox">
                                    </td> -->
                    
                                    <td class="view-message  dont-show" (click)="ReadMessage(ret)">
                                        {{ret.from}}
                                    </td>
                    
                                    <td class="view-message " (click)="ReadMessage(ret)">
                                        {{ret.subject}}
                                    </td>
                                    <td class="view-message " (click)="ReadMessage(ret)">
                                        {{ret.sortdate}}
                                    </td>
                    
                    
                                    <td>
                                        <!-- <div>
                                            <a class="nav-link dropdown" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span style="font-family: Thanom regular;color:white;font-size: 15px;"><i
                                                        class="fas fa-ellipsis-h" style="color: #4F44C5"></i></span>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink" id="dropdownstyle">
                    
                    
                                             <a class="dropdown-item" style="cursor: pointer;" id="dropdowndetails" (click)="singlearchive(ret)">Archive</a>
                                                <a class="dropdown-item" style="cursor: pointer;" id="dropdowndetails" (click)="singleDelete(ret)">Delete</a>
                    
                                            </div>
                                        </div> -->
                                    </td>
                                </tr>
                    
                            </tbody>
                        </table>
                    </div>
                </aside>
            </div>
            <!--mail inbox end-->
        </div>
    </div>
</div>

